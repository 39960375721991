/*
 * Given a string input, returns it back with problematic characters removed.
 * Should be used for sanitizing user-provided text input (eg, in form controls).
 */
export function sanitize(input) {
    // Replace "tab" spaces with a single space
    input = input.replace(/\t/g, ' ');
    return input;
}

export function sanitizeUrl(input) {
    input = input.replace('https://', '');
    input = input.replace('http://', '');

    return '//' + input;
}

export default sanitize;
