import randomId from 'util/numbers/random-id';
import {wsUrl} from 'util/data/env';
import authManager from 'managers/auth-manager';
import RequestPool from 'util/network/request-pool';
import publish from 'legacy/util/api/publish';
import dialogModel from 'models/dialog-model';
import appModel from 'models/app-model';
import store from 'util/data/store';
import analytics from 'util/data/analytics';
import constants from 'util/data/constants';
import authModel from 'models/auth-model';
import offlineManager from 'managers/offline-manager';
import {datadogLogs} from '@datadog/browser-logs';

const AUTH_ERROR_CODE = 4001;

class SocketModel {

    constructor() {
        this.socket = undefined;
        
        this.state = {
            isActive: false
        };
        
        this.expectedCount = 0;
        this.actualCount = 0;
        
        this.onReconnectMessage = this._onReconnectMessage.bind(this);
        this.onOpen = this._onOpen.bind(this);
        this.onClose = this._onClose.bind(this);
        this.onMessage = this._onMessage.bind(this);
        this.sendInPool = this._sendInPool.bind(this);
        this.send = this.sendMessage.bind(this);

        this.get = (path, opts) => this.sendRequest('get', path, opts);
        this.put = (path, opts) => this.sendRequest('put', path, opts);
        this.post = (path, opts) => this.sendRequest('post', path, opts);
        this.patch = (path, opts) => this.sendRequest('patch', path, opts);
        this.delete = (path, opts) => this.sendRequest('delete', path, opts);

        this.connectionMsgId = undefined;
        this.countMsgId = undefined;

        this.requests = {};
        this.requestPool = new RequestPool(5, 200, this._sendRaw.bind(this));
        this.requestQueue = [];

        return this;
    }

    get isConnected() {
        return this.socket && this.socket.readyState === WebSocket.OPEN; 
    }

    get isConnectedAndOnline() {
        return this.isConnected && !offlineManager.isOffline;
    }

    _sendRaw(data) {
        if (authManager.tokenIsExpired()) {
            datadogLogs.logger.error('token_was_expired_before_socket_message', { data, userId: appModel.user.userId });
            this.socket.close();
            this._sendInPool(data);
            return authManager.refreshToken();
        }
        if (!this.socket) {
            // Something went wrong if we hit this, so log the issue & log the user out to prevent further problems.
            this.handleExpiredSession();
            return datadogLogs.logger.error('socket_wasnt_open_at_message_send', { data, userId: appModel.user.userId });
        }
        if (data.sessionId !== this.socket.sessionId) {
            data.sessionId = this.socket.sessionId;
        }
        return this.socket.send(JSON.stringify(data));
    }

    _sendInPool(data) {
        if (this.isConnectedAndOnline) {
            this.requestPool.addRequest(data);
        } else if (data.type === 'request') {
            this.requestQueue.push(() => {
                data.sessionId = this.socket.sessionId;
                this._sendRaw(data);
            });
            
        }
    }

    sendMessage(type, opts = {}) {
        const msgId = this.formatRequest(opts.resolve, opts.reject, opts.ignoreErrors, opts.ignoreClearPending);
        if (!this.socket) {
            this.logSocketError('socket not connected, reconnecting before send');
            authManager.showErrorToastMessage(true);
            authManager.onSocketDisconnect();
        }
        this.sendInPool({
            type,
            msgId,
            sessionId: this.socket.sessionId,
            payload: opts.data
        });
        return () => {
            delete this.requests[msgId];
            this.requestPool.clearQueued(msgId);
        };
    }

    sendRequest(verb, path, opts = {}) {
        const msgId = this.formatRequest(opts.resolve, opts.reject, opts.ignoreErrors);
        this.sendInPool({
            msgId,
            sessionId: this.socket.sessionId,
            type: 'request',
            payload: {
                verb,
                path,
                type: 'http',
                body: opts.data,
                args: opts.args,
                platform: 'web',
                version: constants.apiVersion,
                analytics: verb === 'get' ? undefined : analytics.fetch()
            }
        });
    }

    get isActive() {
        return this.state.isActive;
    }

    get sessionId() {
        return this.socket && this.socket.sessionId;
    }

    _onOpen() {
        const token = authManager.getToken();
        const msgId = this.connectionMsgId = randomId();

        const data = JSON.stringify({
            type: 'connect',
            msgId,
            token
        });
        this.socket.send(data);
    }

    _onClose(e) {
        if (this.state.isActive) {
            this.logSocketError(e);
            authManager.onSocketDisconnect();
        } else {
            this.socket.removeEventListener('close', this.onClose);
            this.socket = undefined;
        }
    }

    _onReconnectMessage(e) {
        const messageData = JSON.parse(e.data);
        if (messageData.code === AUTH_ERROR_CODE) {
            datadogLogs.logger.error('3_auth_error_received_on_reconnect_message', { messageData });
            return authModel.onAuthFailure(true);
        }
        // It's possible for a change notification to
        // arrive before the connection
        if (!messageData.payload || messageData.payload.changes || messageData.payload.tokens || messageData.requestId !== this.connectionMsgId) {
            return;
        }
        this.socket.sessionId = messageData.payload.sessionId;

        this.socket.removeEventListener('message', this.onReconnectMessage);
        this.socket.removeEventListener('open', this.onOpen);
        this.socket.addEventListener('message', this.onMessage);

        this.state.isActive = true;

        delete this.connectionMsgId;

        this.runQueuedFns();
    }

    runQueuedFns() {
        this.requestQueue.forEach(fn => fn());
        this.requestQueue = [];
    }

    _onMessage(e) {
        const data = JSON.parse(e.data);

        const id = data.requestId || data.msgId;
        this.requestPool.unlockRequest(id);

        if (data.code === AUTH_ERROR_CODE) {
            console.error('4a socket auth error on message', data);
            datadogLogs.logger.error('4a_socket_auth_error_on_message', { data });
            authManager.onSocketDisconnect();            
        }

        if ((data.type === 'response' || data.type === 'rpc-results') && this.requests[id]) {
            const request = this.requests[id];
            if (!data.payload || data.payload.error || data.success === false || data.payload.errorCount) {
                if ((data.code === 4040 || data.code === 4001) && !request.ignoreErrors) {
                    datadogLogs.logger.error('4b_socket_auth_error_on_message', { data });
                    this.handleExpiredSession();
                } else if (data.payload && data.payload.results && data.payload.results.find(result => result.error === 'Not Found')) {
                    this.handleSiteNotFound();
                } else if (request.ignoreErrors) {
                    console.warn(data);
                    if (request.reject) {
                        request.reject(data);
                    }
                } else {
                    this.processError(JSON.stringify(data));
                    if (request.reject) {
                        request.reject(data);
                    }
                }
            } else {
                if (request.resolve) {
                    request.resolve(data.payload.body || data.payload.results || data.payload.refreshTokens);
                }
            }

            delete this.requests[id];

        } else if (data.type === 'publish') {
            if (data.payload.changes) {
                data.payload.changes.forEach(publish);
            } else {
                publish(data.payload);
            }

        }

    }

    start() {
        return new Promise((resolve, reject) => {
            try {
                const socket = this.socket = new WebSocket(wsUrl);
                socket.addEventListener('message', this.onReconnectMessage);
                socket.addEventListener('open', this.onOpen);
                socket.addEventListener('close', this.onClose);
                resolve();
            } catch (e) {
                reject(e);
            }
        });
    }

    // For debugging
    mimicServerClose() {
        this.socket.close();
    }

    close() {
        this.state.isActive = false;
        if (this.socket) {
            this.socket.removeEventListener('message', this.onReconnectMessage);
            this.socket.removeEventListener('open', this.onOpen);
            this.socket.close();
        }
    }

    handleSiteNotFound() {
        appModel.changeProject(store.account.attributes.metaProjectId);

        dialogModel.open({
            headline: `Unable to load the requested ${appModel.toolbox.siteTermSingular}`,
            text: `The ${appModel.toolbox.siteTermSingular} was not found or you do not have access.`
        });
    }

    handleExpiredSession() {
        dialogModel.open({
            warning: true,
            text: 'Your session has expired. Please log in again to continue.',
            onOkay: () => authManager.signOut()
        });
    }

    processError(msg) {
        if (this.socket.readyState === WebSocket.OPEN) {
            authManager.showErrorToastMessage();
            this.logSocketError(msg);
        } else {
            this.socket.removeEventListener('message', this.onReconnectMessage);
            this.socket.removeEventListener('open', this.onOpen);
            this.state.isActive = false;
            authManager.onSocketDisconnect();
        }
    }

    logSocketError(e = {}) {
        const code = e.code;
        const data = JSON.stringify(e, ['code', 'wasClean', 'type', 'timestamp']);
        if (code === 1006) {
            console.warn('Socket Closed: ', data);
        } else {
            console.error('Socket Error: ', data);
        }
    }

    formatRequest(resolve, reject, ignoreErrors, ignoreClearPending) {
        const msgId = randomId();
        this.requests[msgId] = {
            resolve,
            reject,
            ignoreErrors,
            ignoreClearPending
        };
        return msgId;
    }

    clearRequests() {
        Object.keys(this.requests).forEach(key => {
            if (!this.requests[key].ignoreClearPending) {
                delete this.requests[key];
            }
        });
    
        this.requestPool.clearRequests();
    }
}

export default SocketModel;
