import AuthFieldModel from 'models/auth-field-model';

const AuthField = {
    oninit: ({attrs: {type}, state}) => {
        Object.assign(state, AuthFieldModel.init(type));
    },

    onupdate: ({attrs, state}) => {
        if (attrs.matchValue) {
            Object.assign(state, AuthFieldModel.checkMatch(attrs, state, state.value));
        }
    },

    view: ({attrs, children, state}) => {
        const {label, showValidationError, type, forceErrorCss} = attrs;
        return <fieldset class={`${showValidationError 
        && !state.isValidated 
        || forceErrorCss ? 'error' : ''} validated`}>
            <label>{label || ''}</label>
            <input
                onblur={(e) => Object.assign(state, AuthFieldModel.blur(attrs, state, e))}
                oninput={(e) => Object.assign(state, AuthFieldModel.input(attrs, state, e))}
                autocomplete={type === 'password' ? 'on' : undefined}
                type={state.type || type } autofocus/>
            {showValidationError
            && !state.isValidated
            && <div class="error-msg">{state.errorMsg || state.validate.errorMsg}</div>}
            {children}
        </fieldset>;
    }
};

export default AuthField;
