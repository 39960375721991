import debounce from 'util/events/debounce';

class DragAndDrop {
    oncreate({
        attrs: {
            handleDragIn, handleDragOut, handleDrag, handleDrop
        },
        state
    }) {
        const debrouncedRedraw = debounce(() => {
            m.redraw();
        }, 200);

        const dropElement = document.getElementById('drop');
        dropElement.addEventListener('dragenter', function enter(e) {
            e.dataTransfer.dropEffect = 'move';
            e.stopPropagation();
            e.preventDefault();
            state.hoverCssClass = ' hover';
            if (handleDragIn) {
                handleDragIn(e, this);
            }
        });
        dropElement.addEventListener('dragleave', function leave(e) {
            e.stopPropagation();
            e.preventDefault();
            state.hoverCssClass = undefined;
            m.redraw();
            if (handleDragOut) {
                handleDragOut(e, this);
            }
        });
        dropElement.addEventListener('dragover', function over(e) {
            e.stopPropagation();
            e.preventDefault();
            state.hoverCssClass = ' hover';
            debrouncedRedraw();
            if (handleDrag) {
                handleDrag(e, this);
            }
        });
        dropElement.addEventListener('drop', function drop(e) {
            e.stopPropagation();
            e.preventDefault();
            if (handleDrop) {
                handleDrop(e, this);
            }
        });
    }

    view({
        state: {
            hoverCssClass
        },
        attrs: {
            handleClick,
            description,
            headline,
            inputId
        }
    }) {
        inputId = inputId || 'fileElement';
        return <form class={'file-form uploader-box-wrapper' + (hoverCssClass || '')}>
            <input className="hidden" 
                id={inputId}
                type="file" 
                multiple={true}
                accept={'*'} 
                onchange={function f() {
                    handleClick(this);
                }}/>
            <div id="drop"
                class="drag-and-drop upload-input-placeholder">
                <label className="fill" id={inputId} for={inputId}>
                    <img src="/images/file-icons-kml-zip-tiff-jpg.svg" class="upload-input-placeholder-image"/>
                    <div class="upload-help-input-text">{headline}</div>
                    <div class="upload-help-input-subtext">
                        {description}
                    </div>
                </label>
            </div>
        </form>;
    }
}

export default DragAndDrop;
