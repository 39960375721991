const getValue = (v) => v;

const simpleSearch = ({
    text,
    list,
    split,
    maxResults,
    getValueFn
}) => {
    split = split || false;
    maxResults = maxResults || 100;
    getValueFn = getValueFn || getValue;
    if (text === '' || text === undefined) {
        return list;
    }
    text = text.toLowerCase();
    const results = [];
    for (let i = 0; i < list.length; i++) {
        const str = getValueFn(list[i]).toLowerCase();
        if (split !== false) {
            const parts = text.split(split);
            for (let j = 0; j < parts.length; j++ ) {
                const part = parts[j];
                if (str.includes(part)) {
                    results.push(list[i]);
                    maxResults--;
                    break;
                }
            }
        } else if (str.includes(text)) {
            results.push(list[i]);
            maxResults--;
        }

        if (maxResults === 0) {
            return results;
        }
    }
    return results;
};

export default simpleSearch;
