import popupModel from 'models/popover-model';
import tableModel from 'models/table/table-model';
import formModel from 'models/form-model';
import Cell from 'views/table/cells/cell';
import CellID from 'views/table/cells/cell-id';
import CellCommon from 'views/table/cells/cell-common';
import siteModel from 'models/site-model';
import Table from 'views/table/table';
import AssetRowModel from 'models/table/asset-row-model';
import store from 'util/data/store';
import appModel from 'models/app-model';
import requestBatches from 'util/network/request-batches/request-batches';
import batchSelectModel from 'models/batch-select-model';
import popup from 'util/popup';
import assetListManager from 'managers/asset-list-manager';

class TableRow {

    oninit({state, attrs: {assetId}}) {
        const asset = store.assets[assetId];
        if (asset) {
            tableModel.assetRows[assetId] = new AssetRowModel(assetId);
        } else {
            requestBatches.add('listContent', assetId, (_asset) => {
                assetListManager.addToStore(_asset);
                tableModel.assetRows[assetId] = new AssetRowModel(assetId);
            }, () => {
                state.isInvalid = true;
            });
        }
    }

    onremove({attrs: {assetId}}) {
        delete tableModel.assetRows[assetId];
    }

    view({attrs: {assetId, index, label}}) {
        const assetRow = tableModel.assetRows[assetId];
        if (!assetRow) {
            return <div class="mock-table-row" />;
        }
        assetRow.rowIndex = index;

        let columnIndex = 0;
        const activeClass = tableModel.activeCell.isActive && tableModel.activeCell.row === assetRow.rowIndex ? 'active-row' : 'inactive-row';
        const selectedClass = batchSelectModel.selectedAssets[assetRow.assetId] ? 'selected-row' : 'unselected-row';

        return <div class={`asset-row ${activeClass} ${selectedClass} ${assetRow.rowSortingCss}`} onclick={(e) => {
            if (popup.isOpen()) {
                popup.remove();
            }
            if (e.metaKey || e.ctrlKey) {
                if (batchSelectModel.selectedAssets[assetRow.assetId]) {
                    batchSelectModel.deselectSingleAsset(assetRow.assetId);
                } else {
                    batchSelectModel.selectSingleAsset(assetRow.assetId);
                }
                return;
            }

            if (popupModel.isOpen) {
                popupModel.close();
                return;
            }
            if (!tableModel.inEditMode) {
                const asset = store.assets[assetId];
                const tool = appModel.toolbox.tools[asset.attributes.toolId];
                if (tool && tool.featureTypes.find(ft => ft.attributes.interface === 'project')) {
                    tableModel.viewProject(assetId);
                } else {
                    const tab = appModel.isOnTab('Links') ? 'Links' : 'Properties';
                    formModel.viewAsset(assetId, tab);
                }
            }
        }}>
            {label || ''}
            <CellID assetRow={assetRow} cellKey={'name'} controlType={assetRow.nameControl} columnNA={false} columnIndex={columnIndex} />
            <ul class="asset-properties">
                {tableModel.visibleHeaders.unearthId && <CellCommon assetRow={assetRow} columnIndex={++columnIndex} cellKey='unearthId' />}
                {tableModel.visibleHeaders.category && <CellCommon assetRow={assetRow} cellKey='category' columnIndex={++columnIndex} />}
                {tableModel.visibleHeaders.type && <CellCommon assetRow={assetRow} cellKey='type' columnIndex={++columnIndex} />}
                {tableModel.visibleHeaders.addedDateTime && <CellCommon assetRow={assetRow} columnIndex={++columnIndex} cellKey='addedDateTime' />}
                {tableModel.visibleHeaders.addedBy && <CellCommon assetRow={assetRow} columnIndex={++columnIndex} cellKey='addedBy' />}
                {tableModel.visibleHeaders.lastUpdated && <CellCommon assetRow={assetRow} columnIndex={++columnIndex} cellKey='lastUpdated' />}
                {tableModel.visibleHeaders.lastUpdatedBy && <CellCommon assetRow={assetRow} columnIndex={++columnIndex} cellKey='lastUpdatedBy' />}
                {/* TODO Uncomment after UE-2025 {tableModel.visibleHeaders.links && <CellCommon assetRow={assetRow} columnIndex={++columnIndex} cellKey='links'/> } */}
                {tableModel.getVisibleSharedColumnHeaders().map((sharedColumnName, i) => {
                    if (i >= tableModel.loadRange.startCol && i <= tableModel.loadRange.endCol) {
                        return <Cell assetRow={assetRow} columnIndex={++columnIndex} key={sharedColumnName + assetRow.assetId + assetRow.rowIndex + columnIndex}
                            cellKey={sharedColumnName} sharedColumnName={sharedColumnName} controlType={tableModel.getSharedColumn(sharedColumnName).controlType} columnNA={!tableModel.getSharedColumn(sharedColumnName).assetTypeIds.includes(assetRow.assetTypeId)} />;
                    }
                    columnIndex++;
                    return <div key={sharedColumnName + assetRow.assetId + assetRow.rowIndex + columnIndex} class="mock-table-cell" />;
                })}
                {tableModel.getVisibleTableHeaders().map((header, i) => {
                    const currentIndex = columnIndex + i;
                    if (tableModel.tableMode === 'list-left'
                        || siteModel.sidebar !== Table
                        || currentIndex >= tableModel.loadRange.startCol
                        && currentIndex <= tableModel.loadRange.endCol) {
                        return <Cell
                            key={assetRow.generateCellKey(columnIndex, header)}
                            assetRow={assetRow}
                            columnIndex={currentIndex + 1}
                            cellKey={header.assetType.assetTypeId + header.controlType.fieldName}
                            controlType={header.controlType}
                            columnNA={header.assetType.assetTypeId !== assetRow.assetTypeId} />;
                    }
                    return <div key={assetRow.generateCellKey(columnIndex, header)} class="mock-table-cell" />;
                })}
            </ul>
        </div>;
    }
}

export default TableRow;
