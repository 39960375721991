import router from 'uav-router';
import constants from 'util/data/constants';
import dialogModel from 'models/dialog-model';
import siteModel from 'models/site-model';
import layerModel from 'models/layer-model';
import randomId from 'util/numbers/random-id';
import {appUrl} from 'util/data/env';
import appModel from 'models/app-model';
import libraryModel from 'models/library-model';
import modalModel from './modal-model';
import initializer from 'util/initializer';
import filterUtil from 'util/table/filter-util';
import userManager from 'managers/user-manager';
import { USER_PROCORE_PREFERENCES } from 'constants/managers/user-constants';
import uploadHelpFlow from 'flows/upload-help/upload-help-flow';

const flowStatuses = {
    PROCESSING: 1,
    DONE: 2,
    READY: 3,
    UPLOADING: 4,
    CANCELLED: 5
};

const DEFAULT_CSS_POSITION = 'top: auto; right: 60px; left: auto; bottom: 0;';

class OneUpModel {
    constructor() {

        this.cssPosition = DEFAULT_CSS_POSITION;
        this.cssClass = 'hidden';
        this.anchorElement = undefined; // Only for use in portable one-up instances.

        this.requestCallbacks = {};
        this.promises = {};
        this.callbackOnEachItem = {};

        this.setIframeSrc();
        this.setCommunicationFunctions();

        initializer.addOnInit('project', () => this.reset(), 'oneUpModel close()');
    }

    /**
     * Clear anything open, run cleanup, and hide viewer. */
    close() {
        this.clearFlows();
        this.reset();
    }

    reset() {
        this.anchorElement = undefined;
        this.onAssetSelection = undefined;
        this.hideViewer();
    }

    hideViewer() {
        this.cssClass = 'hidden';
        appModel.setState('isActiveInOneUp', false);
        m.redraw();
    }

    parentCancel(flowId) {
        this.clearFlowIdPromise(flowId);
        this.clearFlowIdCallback(flowId);
        this.hideViewer();
        this.window.postMessage({function: 'parentQuitLibrary', args: {flowId}}, constants.oneUpOrigin);
    }

    get isIframeReady() {
        return !!this.window;
    }

    get hasAnchorElement() {
        return !!this.anchorElement;
    }

    setIframeSrc() {
        const userTokenString = constants.oneUpOrigin === window.location.origin ? '' : `userId=${localStorage.getItem('user')}&ueToken=${localStorage.getItem('ue_token')}`;
        this.src = `${constants.oneUpOrigin}/one-up/#/?${userTokenString}&parentOrigin=${window.location.origin}`;
        m.redraw();
    }

    getCssClass() {
        if (libraryModel.isPorted || this.type) {
            if (this.hasAnchorElement) {
                return `one-up-portable ${this.cssClass} ${this.type || ''}`;
            }
            return 'one-up-portable hidden';
        }
        return `one-up-main ${this.cssClass}`;
    }

    setRequestCallback(flowId, fn) {
        this.requestCallbacks[flowId] = fn;
    }

    setPromise(flowId, fn) {
        this.promises[flowId] = fn;
    }

    clearFlowIdPromise(flowId) {
        delete this.promises[flowId];
    }

    clearFlowIdCallback(flowId) {
        delete this.requestCallbacks[flowId];
    }

    setIframeWindow(contentWindow) {
        this.window = contentWindow;
        m.redraw();
    }

    setProject(projectId) {
        this.window.postMessage({function: 'setProject', args: projectId}, constants.oneUpOrigin);
    }

    setAnchorElement(element, type) {
        this.type = type;
        this.anchorElement = element;
        this.setAnchorPosition();
    }

    setAnchorPosition() {
        if (this.anchorElement) {
            const boundingBox = this.anchorElement.getBoundingClientRect();
            if (this.type === 'simple-uploader') {
                this.cssPosition = `top: ${boundingBox.top}px; right: auto; bottom: auto; left: ${boundingBox.left - 1}px;`;
            } else if (window.innerHeight - 64 < boundingBox.bottom + 250) {
                this.cssPosition = `top: ${boundingBox.top - 250}px; right: auto; bottom: auto; left: ${boundingBox.left - 1}px;`;
            } else {
                this.cssPosition = `top: ${boundingBox.bottom}px; right: auto; bottom: auto; left: ${boundingBox.left - 1}px;`;
            }
        } else {
            this.cssPosition = DEFAULT_CSS_POSITION;
        }
        m.redraw();
    }

    setCommunicationFunctions() {
        const functions = {
            setProcorePreferences: ({ procorePreferences }) => {
                /*
                    @procorePreferences {
                        companyId: string!
                        projectId: string!
                    }
                */
                const { siteId } = router.params;
                userManager.mergePreferences({
                    sitePreferences: {
                        [siteId]: {
                            procorePreferences
                        }
                    }
                });
            },
            requestFlows: ({requestId, ...args}) => {
                if (this.requestCallbacks[requestId]) {
                    this.requestCallbacks[requestId]({...args});
                    delete this.requestCallbacks[requestId];
                }
            },
            redrawParent: () => m.redraw(),
            resolve: ({flowId, args}) => {
                this.promises[flowId].resolve(args);
                delete this.promises[flowId];
                delete this.callbackOnEachItem[flowId];
            },
            resolveItemInFlow: ({flowId, args}) => {
                const fn = this.callbackOnEachItem[flowId];
                if (fn) {
                    fn(flowId, args);
                }
            },
            error: ({flowId, args}) => {
                this.promises[flowId].error(args);
                delete this.promises[flowId];
                delete this.callbackOnEachItem[flowId];
            },
            close: ({flowId, shouldShow}) => {
                if (!shouldShow) {
                    this.hideViewer();
                }

                // If oneup requests to close, we can assume the flow is safe to quit.
                const promises = this.promises[flowId];
                if (promises && promises.close) {
                    promises.close();
                }
                delete this.promises[flowId];
                delete this.callbackOnEachItem[flowId];
                m.redraw();
            },
            setCss: ({cssClass}) => {
                const isActive = cssClass !== 'hidden';
                if (!isActive && !uploadHelpFlow.isActive) {
                    this.clearPromises();
                }
                appModel.setState('isActiveInOneUp', isActive);
                this.cssClass = cssClass;
                m.redraw();
            },
            setSize: ({size}) => {
                this.cssClass = `${this.cssClass ? this.cssClass : ''} ${size}`;
                m.redraw();
            },
            assetSelected: ({assetId}) => {
                if (this.onAssetSelection) {
                    this.onAssetSelection(assetId);
                }
            },
            init: () => delete this.loadLocal
        };

        window.addEventListener('message', message => {
            if (message.origin !== constants.oneUpOrigin && constants.isDeployed) {
                return;
            }
            const {data} = message;
            if (data && data.function && functions[data.function]) {
                functions[data.function](data);
            }
        }, false);
    }

    /**
     * addUploadFlow: The main entry for starting an upload using one-up.
     *
     * flowOpts:
     *          accept: The accepted mimeTypes.
     *          acceptedAssetTypeIds: Array of assetTypeIds to include in library list (if not specified, all assetTypes from project's toolkit will display)
     *          maxFiles: Max number of allowed files.
     *          projectId: The id of the project to associate the upload with.
     *          name: The name on the upload. Shows in the headers in one-up.
     *          flowId: The id associated with the upload. Useful for callbacks or when running multiple uploads at once.
     *          isLink: Determines if the library should show assets or just media. Defaults to just media.
     *          close: If the download is cancelled the close callback is called.
     *          iModel: indicates that we want to transfer a Bentley iModel
     *          isAccountAdmin: boolean indicating if a user is an admin or owner level of unearth account.
     *          disableProcore: returns true if current account is subscribed to Field plan UE-3795
     *          procorePreferences: User preference's procore settings by opened site, if any.
     * 
     * Returned: Promise([mediaRecord]).
     */
    addUploadFlow(flowOpts = {}) {
        flowOpts.isAccountAdmin = appModel.user.isAccountAdmin;
        flowOpts.procorePreferences = userManager.getUserSitePreferences({ siteId: router.params.siteId, preferenceKey: USER_PROCORE_PREFERENCES }) || {};
        libraryModel.quit(); // Clear any library-specific settings if left on
        return this._addUploadFlow(flowOpts);
    }

    processFileUploads(flowOpts = {}) {
        return this._processFileUploads(flowOpts);
    }

    _processFileUploads(flowOpts) {
        delete flowOpts.close;
        this.window.postMessage({function: 'processFileUploads', args: {flowId: flowOpts.flowId, flowData: flowOpts}}, constants.oneUpOrigin);        
        return new Promise((resolve, error) => {
            this.setPromise(flowOpts.flowId, {resolve, error});
        });
    }

    addLibraryFlow(flowOpts) {
        return this._addUploadFlow(flowOpts);
    }

    _addUploadFlow(flowOpts = {}) {
        if (!flowOpts.acceptedAssetTypeIds) {
            flowOpts.acceptedAssetTypeIds = [...filterUtil.getAssetTypeIdInDefault()];
        }

        if (appModel.project.isMetaProject && appModel.user.projectIds) {
            flowOpts.projectIdsAccessList = [...appModel.user.projectIds];
        }

        const flowData = Object.assign({
            flowId: randomId(),
            projectId: appModel.project.projectId
        }, flowOpts);
        const close = flowData.close;
        delete flowData.close;

        this.callbackOnEachItem[flowData.flowId] = flowData.callbackOnEachItem;
        flowData.callbackOnEachItem = flowData.callbackOnEachItem ? true : false;

        this.cssClass = 'visible';
        layerModel.closeLayerPanel();
        m.redraw();

        this.addFlow(flowData.flowId, flowData);
        this.changeProject();


        return new Promise((resolve, error) => {
            this.setPromise(flowData.flowId, {resolve, error, close});
        });
    }

    /**
     *
     * @param {flowIdToCheck} flowIdToCheck to check status of — if not provided, will return true for any in progress upload found.
     * @returns resolved promise & boolean
     */
    hasAnInProgressUpload(flowIdToCheck) {
        return new Promise(resolve => {
            this.requestFlows(router.params.projectId, ({flow}) => {
                const flows = Object.values(flow);
                for (let i = 0; i < flows.length; i++) {
                    const _flow = flows[i];
                    if (flowIdToCheck) {
                        if (_flow.flowId === flowIdToCheck) {
                            return resolve(_flow.status === flowStatuses.PROCESSING || _flow.status === flowStatuses.UPLOADING);
                        }
                    } else if (_flow.status === flowStatuses.PROCESSING || _flow.status === flowStatuses.UPLOADING) {
                        return resolve(true);
                    }
                }
                // Any other status indicates the upload had not yet started or was complete/cancelled.
                return resolve(false);
            });
        });
    }

    canNavigate(retry) {
        const promises = Object.keys(this.promises);
        if (promises.length > 0) {
            this.beforeNavigateRetry(retry);
            return false;
        }
        return true;
    }

    async beforeNavigateRetry(retry) {
        if (await this.hasAnInProgressUpload()) {
            return this.showCanNavigateDialog(retry);
        }
        this.clearPromises();
        this.clearFlows();
        if (retry) {
            retry();
        }
    }

    showCanNavigateDialog(retry) {
        modalModel.setZindex('1000');
        dialogModel.open({
            headline: 'Leave before upload is complete?',
            text: `One or more files are still uploading. Leaving ${siteModel.name} will cancel those uploads. Do you still want to leave?`,
            onYes: () => {
                this.clearPromises();
                this.clearFlows();
                modalModel.setZindex('100');
                if (retry) {
                    retry();
                }
            },
            yesClass: 'btn btn-pill btn-red',
            noClass: 'btn btn-pill btn-secondary',
            yesText: 'Leave',
            noText: 'Stay',
            onNo: () => modalModel.setZindex('100')
        });
    }

    showBeforeUnload() {
        const promises = Object.keys(this.promises);
        return promises.length > 0;
    }

    hasFlow(flowId) {
        return !!this.promises[flowId];
    }

    changeProject() {
        const {projectId} = router.params;
        if (projectId && this.window) {
            this.setProject(projectId);
        }
    }

    requestFlows(projectId, callback) {
        const requestId = randomId();
        this.setRequestCallback(requestId, (...args) => callback(...args));
        this.window.postMessage({function: 'requestFlows', args: {requestId}}, constants.oneUpOrigin);
    }

    addFlow(flowId, flowData) {
        this.window.postMessage({function: 'addFlow', args: {flowId, flowData}}, constants.oneUpOrigin);
    }

    clearActiveFlow() {
        this.window.postMessage({function: 'clearActiveFlow'}, constants.oneUpOrigin);
    }

    clearFlows() {
        this.window.postMessage({function: 'clearFlows'}, constants.oneUpOrigin);
    }

    clearPromises() {
        this.promises = {};
    }

    updateUrlIfLocalhost() {
        if (constants.oneUpOrigin === 'http://localhost:8003') {
            this.loadLocal = () => {
                constants.oneUpOrigin = appUrl;
                // Reset iframe src bc we're running local instance of web app
                this.setIframeSrc();
            };
            if (!constants.isDeployed) {
                setTimeout(() => {
                    if (this.loadLocal) {
                        this.loadLocal();
                    }
                }, 5000);
            }
        }
    }
}

export default new OneUpModel();
