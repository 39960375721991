import onBodyClick from 'legacy/util/dom/on-body-click';

// Generic popup model component that can be used with any view
const popupModel = {
    cssClass: '',
    isOpen: false,
    open: (element, opts) => {
        if (popup.isOpen) {
            popup.close();
        }
        const rect = element.getBoundingClientRect();
        Object.assign(popup, {
            view: opts.view,
            onClose: opts.onClose,
            isOpen: true,
            cssClass: 'visible',
            coordinates: `top:${rect.top}px; left:${rect.left}px;`
        });
        onBodyClick.once((e) => popup.close(e));
        m.redraw();
    },
    close: (e) => {
        if (e) {
            e.stopPropagation();
        }
        if (popup.onClose) {
            popup.onClose();
        }
        Object.assign(popup, {
            isOpen: false,
            view: undefined,
            args: undefined,
            cssClass: ''
        });
        requestAnimationFrame(() => {
            onBodyClick.clear();
        });
        m.redraw();
    }
};

const popup = popupModel;

export default popup;
