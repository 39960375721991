import Login from 'views/auth/login';
import router from 'uav-router';
import message from 'views/toast-message/toast-message';
import authManager from 'managers/auth-manager';

const messages = {
    'authorization_error': {
        displayText: 'The information you entered does not match our records. Please try again.',
        style: 'error'
    },
    generic: {
        displayText: 'Something went wrong. Please try again or contact support for assistance.',
        style: 'error'
    }
};

const AuthorizationMessage = {
    oninit: () => {
        const messageType = messages[router.params.message] || messages.generic;
        message.show(messageType.displayText, messageType.style);
        authManager.reset();
        router.url.remove('message');
    },
    view: () => <Login />
};

export default AuthorizationMessage;
