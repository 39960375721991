import appModel from 'models/app-model';
import savedViewsModel from 'models/saved-views-model';

const SavedViewsProjectSelector = {
    oninit: ({state}) => {
        state.selectedProjectId = 0;
    },

    view: ({state, attrs: {projectViewId}}) => {
        const projectView =  savedViewsModel.getProjectView(projectViewId);
        state.projectIdList = projectView ? projectView.projectIds.items || [] : [];
        const countMinusCurrentProject = state.projectIdList.length - 1;
        const projects = savedViewsModel.getProjectIdsByProjectViewId(projectViewId);
        return countMinusCurrentProject > 0 ? <div class={`saved-views-project-selector${!projects ? ' is-loading' : ''}`}>
            <span class="side-nav-popup-subtitle">{countMinusCurrentProject} other {countMinusCurrentProject === 1 ? appModel.toolbox.siteTermSingular : appModel.toolbox.siteTermPlural} with this Saved View<i class="spinner teal"/></span>
            <div class="select-wrapper">
                <select disabled={!projects} onchange={e => {
                    state.selectedProjectId = e.target.value;
                }}>
                    <option value={0} disabled selected={!state.selectedProjectId}>Select a {appModel.toolbox.siteTermSingular}</option>
                    {projects && projects.map(project => project.projectId !== appModel.project.projectId 
                        ? <option value={project.projectId}>{project.name}</option>
                        : '')}
                </select></div>
            <div class="btn-wrapper"><span class={`btn btn-smaller btn-pill btn-primary${!state.selectedProjectId || !projects ? ' disabled' : ''}`} onclick={() => savedViewsModel.selectProject(state.selectedProjectId, projectViewId)}>Go</span></div>
        </div> : '';
    }
};

export default SavedViewsProjectSelector;
