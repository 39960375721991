import modalModel from 'models/modal-model';
import Dialog from 'views/dialog/dialog';

const dialogModel = {
    handleYes: (e) => {
        if (dialogModel.onYes) {
            dialogModel.onYes(e);
        }
        dialogModel.quit();
    },

    handleNo: (e) => {
        if (dialogModel.onNo) {
            dialogModel.onNo(e);
        }
        dialogModel.quit();
    },

    handleOkay: (e) => {
        if (dialogModel.onOkay) {
            dialogModel.onOkay(e);
        }
        dialogModel.quit();
    },

    // Given the id of an dom element, creates the view as a child of that element.
    // Use if modal view is unavailable for container, for example.
    create: (attachToId, opts) => {
        const elem = document.getElementById('app');
        dialogModel.init(opts);
        dialogModel.cssClass = `${dialogModel.cssClass} position-absolute no-modal`;
        dialogModel.quit = () => {
            dialogModel.isOpen = false;
        };
        m.render(elem, m(Dialog));
    },

    // Open modal and show dialog in it. Main method for displaying dialog.
    open: (opts) => {
        dialogModel.init(opts);
        dialogModel.quit = dialogModel.close;
        modalModel.open({
            view: Dialog
        });
    },

    // Append dialog to view(s) already in modal (positioned absolutely)
    append: (opts) => {
        dialogModel.init(opts);
        // Rather than closing entire modal, to remove, just pop this dialog's view
        dialogModel.quit = dialogModel.pop;
        dialogModel.cssClass = `${dialogModel.cssClass} position-absolute`;
        modalModel.appendView(Dialog);
    },

    // Remove dialog by closing entire modal
    close: () => {
        dialogModel.isOpen = false;
        modalModel.close();
    },

    // Remove dialog by removing latest addition of views in modal
    pop: () => {
        dialogModel.isOpen = false;
        modalModel.popView();
    },

    init: (opts) => {
        Object.assign(dialogModel, opts, {
            cssClass: opts.cssClass || '',
            headline: opts.headline || '',
            text: opts.text,
            innerView: opts.innerView,
            warning: opts.warning || false,
            onYes: opts.onYes,
            yesText: opts.yesText || 'Yes',
            yesClass: opts.yesClass || 'btn btn-pill dialog-yes btn-primary',
            onNo: opts.onNo,
            noText: opts.noText || 'No',
            noClass: opts.noClass || 'btn btn-pill dialog-no btn-secondary',
            onOkay: opts.onOkay,
            okayText: opts.okayText || 'Okay',
            okayClass: !opts.onYes && !opts.onNo ? 'show-okay' : '',
            quitClass: opts.quitClass || 'hidden',
            noButtons: opts.noButtons || false,
            isOpen: true
        });
    }

};

export default dialogModel;
