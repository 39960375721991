import store from 'util/data/store';
import formModel from 'models/form-model';
import router from 'uav-router';
import formatDate from 'legacy/util/date/format-date';

const surveyStatuses = {
    created: 'Processing',
    loaded: 'Processing',
    processing: 'Processing',
    ready: 'Complete'
};

export default function(control, assetId) {
    const asset = store.assets[assetId],
        surveyId = asset.properties[control.fieldName],
        survey = store.surveys[surveyId];
    return <div class="survey-control">
        {survey && survey.status === 'ready'
            ? <div>
                <div class="control-data-row"><span class="row-label">Title</span><span class="row-value">{survey.title}</span></div>
                <div class="control-data-row"><span class="row-label">Date</span><span class="row-value">{formatDate.formatDay(survey.surveyDateTime, true)}</span></div>
                <div class="control-data-row"><span class="row-label">Status</span><span class="row-value">{surveyStatuses[survey.status] || survey.status}</span></div>
                <div class="layer-actions clear">
                    {!formModel.isReadOnlyControl(asset.assetTypeId, control) &&
                        <div class="btn btn-secondary btn-pill btn-smallest left show-on-large" onclick={() =>
                            router.merge({view: 'survey', survey: surveyId, skipInitializer: true})
                        }><span class="btn-label">Edit Survey Layer</span></div>}
                    <div class="btn btn-secondary btn-pill btn-smallest right" onclick={() => formModel.viewSurveyFiles(surveyId)}>
                        <span class="btn-label">View Source Files</span>
                    </div>
                </div>
            </div>
            : <div>
                <p><i class="spinner spinning"/> Creating survey...</p>
                <p>This can take minutes or hours depending on the type and size of the imagery.</p>
                <div class="layer-actions">
                    <div class="btn btn-secondary btn-pill btn-smallest" onclick={() => formModel.viewSurveyFiles(surveyId)}>
                        <span class="btn-label">View Source Files</span>
                    </div>
                </div>
            </div>
        }
    </div>;
}
