const MAGNIFIER_OUTER_IMG = './images/staking/reticule.png';
const MAGNIFIER_OUTER_IMG_TEAL = './images/staking/reticule-teal.png';

const GLASS_RADIUS = 86;
const GLASS_DIAMETER = 2 * GLASS_RADIUS;
const GLASS_WIDTH_EXTRA = 0; 
// Magnifier image width is a little bigger than height

const MAGNIFIER_WIDTH = 181;
const MAGNIFIER_HEIGHT = 217;
const MAGNIFIER_BORDER_OFFSET = 4;
const MAGNIFIER_HEIGHT_OF_BOTTOM = 42;

class MagnifierModel {

    constructor(map, useLightBg) {
        this._map = map;
        this.reticule = document.createElement('canvas');
        this.ctx = this.reticule.getContext('2d');
        this.reticule.width = this._map.getCanvas().width;
        this.reticule.height = this._map.getCanvas().height;
        this.devicePixelRatio = window.devicePixelRatio || window.screen.deviceXDPI / window.screen.logicalXDPI; // For handling retina screens
        this.timeout;
        this.bgFillColor = useLightBg ? '#fafcff' : '#313841';
        this.getOuterFrameImage(useLightBg).then(() => {
            this.reticule.classList.add('magnifying-glass');
            this.reticule.classList.add('hidden');
        });
    }

    /**
     * Loads the outer frame image and stores it in a variable for later.
     */
    getOuterFrameImage(useLightBg) {
        return new Promise(resolve => {
            this.reticuleFrame = new Image();
            this.reticuleFrame.onload = () => {
                resolve(this.reticuleFrame);
            };
            this.reticuleFrame.src = useLightBg ? MAGNIFIER_OUTER_IMG_TEAL : MAGNIFIER_OUTER_IMG;
        });
    }

    /**
     * Given a mouse point, magnifies the data at that position and draws the result to the 2d map context.
     */
    render(point) {
        this.calcPositions(point);

        if (this.timeout) {
            cancelAnimationFrame(this.timeout);
        }
        this.timeout = requestAnimationFrame(() => {
            this.magnify();
        });
    }

    /**
     * Given a mouse point, recalculates all the positions for the magnifier and sets them accordingly.
     */
    calcPositions(point) {
        const ratio = this.devicePixelRatio;
        if (point) {
            this.mouseX = point.x;
            this.mouseY = point.y;
        }
        this.originX = this.mouseX * ratio - Math.floor(MAGNIFIER_WIDTH / 2);
        this.originY = this.mouseY * ratio - MAGNIFIER_HEIGHT_OF_BOTTOM * 2;

        this.originX += 4; // A couple extra pixels to account for the drop shadow of the pointer
        this.originY -= 2 * ratio;

        this.magnifierCurrentX = this.mouseX - Math.floor(MAGNIFIER_WIDTH / 2);
        this.magnifierCurrentY = this.mouseY - MAGNIFIER_HEIGHT;
    }

    /**
     * Grabs the image data from the current originX and originY positions, magnifies the data,
     * and clips it within a magnifier frame. Draws it to the map's 2d context.
     */
    magnify() {
        // Clear only the portion of the canvas that had the reticule drawn to it:
        this.ctx.clearRect(this.magnifierLastX, this.magnifierLastY, MAGNIFIER_WIDTH, MAGNIFIER_HEIGHT);

        // Magnify the data
        let source = this._map.ctx2d.getImageData(this.originX, this.originY, GLASS_DIAMETER, GLASS_DIAMETER);

        // For retina displays (2x pixels), the canvas is already scaled (no magnification needed)
        if (this.devicePixelRatio <= 1) {
            source = this.magnifyImageData(source);
        }

        // Draw magnified image and clip it by circle
        this.ctx.drawImage(this.createMagCircle(source),
            this.magnifierCurrentX + MAGNIFIER_BORDER_OFFSET,
            this.magnifierCurrentY + MAGNIFIER_BORDER_OFFSET);

        this.ctx.drawImage(this.reticuleFrame,
            this.magnifierCurrentX,
            this.magnifierCurrentY,
            MAGNIFIER_WIDTH,
            MAGNIFIER_HEIGHT);

        // Update lastX and lastY for clearing on next magnify() call
        this.magnifierLastX = this.magnifierCurrentX;
        this.magnifierLastY = this.magnifierCurrentY;
    }

    /**
     * Given an ImageData object, magnifies its pixels and returns it.
     */
    magnifyImageData(source) {
        const sourceData = source.data;
        const dest = this._map.ctx2d.createImageData(GLASS_DIAMETER, GLASS_DIAMETER);
        const destData = dest.data;

        for (let j = 0; j < GLASS_DIAMETER; ++j) {
            for (let i = 0; i < GLASS_DIAMETER; ++i) {
                const dI = i - GLASS_RADIUS;
                const dJ = j - GLASS_RADIUS;
                const dist = Math.sqrt(dI * dI + dJ * dJ);
                let sourceI = i;
                let sourceJ = j;
                if (dist < GLASS_RADIUS) {
                    sourceI = Math.round(GLASS_RADIUS + dI / 2);
                    sourceJ = Math.round(GLASS_RADIUS + dJ / 2);
                }
                const destOffset = (j * GLASS_DIAMETER + i) * 4;
                const sourceOffset = (sourceJ * GLASS_DIAMETER + sourceI) * 4;
                destData[destOffset] = sourceData[sourceOffset];
                destData[destOffset + 1] = sourceData[sourceOffset + 1];
                destData[destOffset + 2] = sourceData[sourceOffset + 2];
                destData[destOffset + 3] = sourceData[sourceOffset + 3];
            }
        }
        return dest;
    }

    /**
     * Given an ImageData object, clips it within a circle and returns it.
     */
    createMagCircle(imageData) {
        const magImg = document.createElement('canvas');
        const magCircle = document.createElement('canvas');

        magImg.width = magCircle.width = GLASS_DIAMETER + GLASS_WIDTH_EXTRA;
        magImg.height = magCircle.height = GLASS_DIAMETER;

        const imgCtx = magImg.getContext('2d');
        imgCtx.putImageData(imageData, 0, 0);

        const ctx = magCircle.getContext('2d');
        ctx.beginPath();
        ctx.arc(GLASS_RADIUS, GLASS_RADIUS, GLASS_RADIUS, 0, 2 * Math.PI);
        ctx.fillStyle = this.bgFillColor;
        ctx.fill();
        // clip canvas
        ctx.clip();
        ctx.drawImage(magImg, 0, 0);
        return magCircle;
    }

    /**
     * Special handling for when the magnifier is active and the map is zoomed via mousewheel event.
     */
    renderZoom() {
        // 1) magnify the content at the point
        // 2) update the *stake object* to the projected lat/lng (but same point) based on new zoom.
        const newLngLat = this.stake._map.unproject([this.mouseX, this.mouseY]); // Current stake is passed in from mapModel
        this.stake.setLngLat(newLngLat);
        this.magnify();
    }

    /**
     * Clean up after drag ends.
     */
    cleanup() {
        this.reticule.classList.add('hidden');
        this.stake = null;
        this.timeout = null;
    }

}

export default MagnifierModel;
