import store from 'util/data/store';
import appModel from 'models/app-model';
import libraryModel from 'models/library-model';
import formModel from 'models/form-model';
import {TinyCard} from 'views/asset-card/asset-card';
import {tinyAssetPopup} from 'views/asset-options';

export default function(control, assetId) {
    const linkIds = store.assets[assetId].properties[control.fieldName];
    const maxFiles = control.attributes && control.attributes.limit ? control.attributes.limit : undefined;
    const projectId = appModel.state.editingProjectId;
    const acceptedAssetTypeIds = control.attributes && control.attributes.acceptedAssetTypeIds ? control.attributes.acceptedAssetTypeIds : undefined;
    const styleClass = `asset-control${libraryModel.getCssClassForKey(control.fieldName)}`;
    return <div class={styleClass} onclick={() => formModel.handleLinkControlClick(control, {projectId, maxFiles, acceptedAssetTypeIds})}>
        {libraryModel.renderPort(control.fieldName)}
        <div class="asset-control-padding">{linkIds && linkIds.length
            ? <div class="links-list">
                {linkIds.map(contentId => <TinyCard
                    key={contentId}
                    assetId={contentId} 
                    popupContent={() => tinyAssetPopup(contentId, control)} onClick={(e) => {
                        e.stopPropagation();
                        formModel.viewAsset(contentId);
                    }}/>)}
            </div> 
            : <span class="placeholder">Tap to find and link ...</span> }</div>
    </div>;
}
