function displayPhoneNumber(phone) {
    // remove non-numeric characters
    phone = phone.replace(/[^\d]/g, '');

    // If it's a US phone number, strip the 1 and format it with hyphens
    if (phone.length === 11 && phone[0] === '1') {
        return phone.substring(1).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
    return phone;
}

function apiPhoneNumber(phone) {
    // remove non-numeric characters
    phone = phone.replace(/\D/g, '');
    return '+' + phone;
}

function validPhoneNumber(phone) {
    // just check that there are no letters, all special chars will be stripped out before saving
    const letters = /^[A-Za-z]+$/;
    return !phone.match(letters);
}

export {displayPhoneNumber, apiPhoneNumber, validPhoneNumber};
