import LayerItemPlan from 'views/layer-picker/layer-item-plan';
import Folder from 'views/folder/folder';

const emptyMessage = (folder) => `This Group is empty. ${folder.isRoot ? 'Add new Plan Layers using the + Menu.' : 'Drag & drop items here to add to it.'}`;

const FolderContentItem = {  

    view: ({attrs: {folderItem}}) => {
        const isFolder = folderItem.recordType === 'folder';
        return <>
            <div class={`drop-zone${folderItem.isDraggingOverDropZone ? ' dragging-over' : ''}`}></div>
            <i class="icon-drag-reorder drag-handle" /> 
            <div class="folder-content-item">
                {isFolder
                    ? <Folder folder={folderItem} />
                    : <LayerItemPlan plan={folderItem}/> }
            </div>
            
        </>;
    }
};

const FolderContents = {
    view: (vnode) => {
        const folder = vnode && vnode.attrs && vnode.attrs.folder;
        return <div class="folder-contents">
            {folder.zOrder.map(urn => {
                const folderItem = folder.getByUrn(urn);
                return folderItem ? <div 
                    class={folderItem.draggingStateCssClasses}
                    id={urn}
                    key={urn}
                    draggable
                    ondragstart={(e) => folderItem.onDragStart(e)}
                    ondragenter={(e) => folderItem.onDragEnter(e)}
                ><FolderContentItem folderItem={folderItem}/></div> : <span key={urn}/>;
            })}
            {!folder.zOrder.length ? <div class="empty-folder-message">{emptyMessage(folder)}</div> : ''}
        </div>;
    }

};

export default FolderContents;
