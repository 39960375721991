import adaptiveRound from 'util/numbers/adaptive-round';

function prettyNumber(value) {

    if (isNaN(value)) {
        return '';
    }

    value = adaptiveRound(value);

    const abs = Math.abs(value);

    if (abs >= 1000) {

        const suffixes = ['', 'k', 'm', 'b', 't'];

        const suffixNum = Math.floor(abs.toString().length / 3);

        let shortValue = '';

        for (let precision = 2; precision >= 1; precision--) {

            shortValue = parseFloat(suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision);

            const dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');

            if (dotLessShortValue.length <= 2) { 

                break;

            }

        }

        value = shortValue + suffixes[suffixNum];

    } else if (abs < 1) {

        value = value.toString().replace('0', '');

    }
    
    return value;

}

export default prettyNumber;
