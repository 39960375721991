import constants from 'util/data/constants';
import peopleModel from 'models/people/people-model';
import {Avatar, AccountInfo, ContactInfo, PersonMenu} from 'views/people/profile/components';
import panelModel from 'models/panel-model';
import popupModel from 'models/popover-model';
import LockableToggleSwitch from 'views/lockable-toggle-switch/lockable-toggle-switch';

const MISSING_DATA = '--';

class ProfilePreview {
    
    view({attrs: {person, handleClick, isPermissionToggled, editingLockedMessage}}) {
        const role = constants.accountUserRoles[person.role].display;
        return <div class={`user-profile preview ${person.status} ${person.savingClass}`}>
            <Avatar person={person} size={'small'}/>
            <LockableToggleSwitch handleClick={handleClick} isToggled={isPermissionToggled} lockedMessage={editingLockedMessage}/>
            <i class='icon-ellipsis toggle-option-menu'
                onclick={function (e) {
                    e.stopPropagation();
                    peopleModel.currentlyViewing = person;
                    popupModel.open(this, {
                        view: PersonMenu,
                        noWidth: true,
                        wrapClass: 'profile-preview-card-menu'
                    });
                }}/>

            <div class="user-full-name"><span>{person.displayNameOr('New User')}{person.isSelf && ' (You)'}</span></div>
            <div class="user-role"><div class="profile-field two-col row">
                <span class="label">Company</span>
                <span class="user-data">{person.company || MISSING_DATA}</span>
            </div></div>
            <div class="user-role"><div class="profile-field two-col row">
                <span class="label">Role</span>
                <span class="user-data role-pill" style={'margin-left: 2px'}>{role}</span>
            </div></div>
        </div>;
    }
}

const closeMenu = (state) => {
    state.menuOpen = false;
};

class ProfileCard {
    oninit({state}) {
        state.menuOpen = false;
    }

    view({state, attrs: {person}}) {
        return <div class={`user-profile card ${person.status} ${person.savingClass} ${state.menuOpen ? 'menu-open' : ''}`}>
            {person.savingClass ?
                <span className="teal spinner spinning"></span>
                : <i class='icon-ellipsis toggle-option-menu'
                    onclick={function (e) {
                        e.stopPropagation();
                        peopleModel.currentlyViewing = person;
                        state.menuOpen = true;
                        panelModel.popup.open(this, {
                            view: PersonMenu,
                            onClose: () => closeMenu(state)
                        });
                    }}/> }
            <div class="user-header decorated">
                <Avatar person={person}/>
                <span class="user-full-name"><span>{person.displayNameOr('New User')}{person.isSelf && ' (You)'}</span>
                </span>
            </div>
            <div class="user-body">
                <ContactInfo person={person} isEditable={false} />
                <AccountInfo person={person} includeDescription={false} isEditable={false} />
            </div>
        </div>;
    }
}

export {ProfilePreview, ProfileCard};

