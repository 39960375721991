import drawPaletteModel from 'models/draw-palette-model';

const BorderPicker = {
    view: ({ attrs: { propKey }}) => {
        const value = drawPaletteModel.activeFeature.properties[propKey];
        return <div class="line-style-set border-opts control-group">
            <div class={'draw-style-btn' + (value ? '' : ' active')}
                onclick={() => drawPaletteModel.handleInput(propKey, false)}>
                <span class="line-style-icon solid-line" />
                <span class="line-style-label">solid</span>
            </div>
            <div class={'draw-style-btn' + (value ? ' active' : '')}
                onclick={() =>drawPaletteModel.handleInput(propKey, true)}>
                <span class="line-style-icon dashed-line" />
                <span class="line-style-label">dashed</span>
            </div>
        </div>;
    }
};

export default BorderPicker;
