const MIN_LONG_TICK = 1300;
let events = [],
    tickClicked,
    touchStartCallback,
    touchEndCallback;

function mapLongPress(map, callback) {
    if (events.length === 0) {
        touchStartCallback = () => {
            tickClicked = Date.now();
        };
        touchEndCallback = (e) => {
            if (Date.now() - tickClicked >= MIN_LONG_TICK) {
                for (const fn of events) {
                    fn(e);
                }
            }
        };
        map.on('touchstart', touchStartCallback);
        map.on('touchend', touchEndCallback);
    }
    events.push(callback);
}

function removeLongPress(map, callback) {
    events = events.filter((fn) => fn === callback);
    if (events.length === 0) {
        map.off('touchstart', touchStartCallback);
        map.off('touchend', touchEndCallback);
    }
}

export {
    removeLongPress,
    mapLongPress
};

