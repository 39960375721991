import drawPaletteModel from 'models/draw-palette-model';
import ColorPicker from 'views/color-picker/color-picker';

const SWATCH_PADDING_OFFSET_TOP = 12;
const SWATCH_PADDING_OFFSET_BOTTOM = 20;

const ColorSelector = {
    oncreate({ state, attrs: {propKey} }) {
        state.toggle = (element) => {
            if (!drawPaletteModel.isColorPickerOpenOn(propKey)) {
                drawPaletteModel.openColorPickerOn(propKey, element);
            } else {
                drawPaletteModel.openColorPickerOn(null);
            }
        };
    },
    view: ({ state, attrs: { propKey } }) => {
        const value = drawPaletteModel.activeFeature.properties[propKey];
        return <div class={`control-group color-group ${drawPaletteModel.isColorPickerOpenOn(propKey) ? 'color-picker-open' : 'color-picker-closed'}`}>
            <div class="color-picker-toggle" onclick={function(e) {
                e.stopPropagation();
                state.toggle(this);
            }} style={drawPaletteModel.isColorPickerOpenOn(propKey) ? `top: ${drawPaletteModel.colorPickerTopPosition - SWATCH_PADDING_OFFSET_TOP || 0}px;` : ''}>
                <div class="color-picker-select" style={`background-color:${value};`} />
            </div>
            {drawPaletteModel.isColorPickerOpenOn(propKey) && <div class="color-picker-position-wrapper" style={`top: ${drawPaletteModel.colorPickerTopPosition + SWATCH_PADDING_OFFSET_BOTTOM || SWATCH_PADDING_OFFSET_BOTTOM}px;`}><ColorPicker
                containerId="color-picker-wrap-draw"
                starterColor={value}
                setColorFn={(hex) => drawPaletteModel.handleInput(propKey, hex)}
            /></div>}
        </div>;
    }
};

export default ColorSelector;
