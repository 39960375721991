import router from 'uav-router';
import store from 'util/data/store';
import constants from 'util/data/constants';
import permissionsManager from 'managers/permissions-manager';
import {accessTypeToText} from 'constants/managers/permissions-constants';
import appModel from 'models/app-model';

const PermissionsTab = {

    oninit: ({state}) => {
        const asset = store.assets[router.params.assetId];
        state.assetTypeId = asset.assetTypeId;
        state.rolesToDisplay = appModel.user.isAccountAdmin ? constants.visibleRoles : [appModel.user.role];
    },

    view: ({state: {assetTypeId, rolesToDisplay}}) => <div class="permissions-inner">
        <div class="role-cards"> 
            {rolesToDisplay.map(role => {
                const hasCustomPermissions = permissionsManager.assetTypes[assetTypeId] && permissionsManager.assetTypes[assetTypeId][role];
                return <div class="card role-card">
                    <div class="role-name-row"><i class="icon-permission"></i><div class="role-name">{constants.accountUserRoles[role].display}</div></div>
                    <div class={`custom-permissions-indicator ${hasCustomPermissions ? 'type-override' : 'platform-default'}`}><span>{hasCustomPermissions ? 'Custom Permissions for this Type' : 'Default Permissions for this Type'}</span></div>
                    <div class="permissions-rows">
                        {Object.keys(accessTypeToText).map((access) => {
                            let hasAccess, accessLevelToText, cssClass, footnote;
                            if ((access === 'EDIT' || access === 'DELETE')
                                && role !== 'admin' && role !== 'owner' 
                                && !permissionsManager.getRestrictionByAction(assetTypeId, role, 'CREATE')) {
                                hasAccess = false;
                                accessLevelToText = 'none';
                                cssClass = 'access-none';
                                footnote = '';
                            } else {
                                hasAccess = permissionsManager.getRestrictionByAction(assetTypeId, role, access);
                                accessLevelToText = permissionsManager.getRestrictionTypeText(hasAccess, role, access);
                                cssClass = permissionsManager.getRestrictionTypeCssClass(accessLevelToText);
                                footnote = permissionsManager.getFootnoteForAction(role, access, hasAccess);
                            }
                            return <div class={`permissions-row ${cssClass}`}>
                                <div class="permissions-row-main-content">
                                    <span class="permissions-row-label">{accessTypeToText[access]}</span>
                                    <span class={`segmented-control segmented-control-small not-segmented permissions-row-indicator ${cssClass}`}><span class="segmented-control-btn"><i class="icon-permission"/><span class="btn-text">{accessLevelToText}</span></span></span>
                                </div>
                                {footnote ? <div class="footnote">{footnote}</div> : ''}
                            </div>;
                        })}
                    </div>
                </div>;
            })}
        </div>
    </div>
    //                 <div class="not-supported-tip">Add New, Edit, and Delete are not supported for the Viewer Role.</div>
    //     <div class="only-theirs-footnote">*only theirs: the value of a UserControl = CurrentUser or (when Create is true) Added by = CurrentUser</div>
};

export default PermissionsTab;
