export const TOOLKIT_DISABLE_MODIFY_FLAG = 'Disable Toolkit Modifications';
export const TOOLKIT_DISABLE_EXPORTS_FLAG = 'Disable Data Exports';

export const FILE_TOOL_GROUP_NAME = 'Files';


export const LINK_TOOLS = {
    video: true,
    file: true,
    image: true,
    audio: true
};

export const FILEPICKER_DESCRIPTION_CONTROL_LABEL = 'Description';

