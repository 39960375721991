import PlaceSearchModel from 'models/place-search-model';
import PlaceList from 'views/place-search/place-list';
import stopPropagation from 'util/events/stop-propagation';
import appModel from 'models/app-model';
import store from 'util/data/store';

const PlaceSearch = {
    oninit: ({state, attrs}) => {
        state.model = new PlaceSearchModel(attrs);
        state.noPlacesDefined = Object.values(store.places).length < 1;
    },

    onupdate: ({state: {model}}) => {
        if (model.active && model.firstFocus) {
            model.firstFocus = false;
            const input = document.getElementById('place-search');
            input.focus();
        }
    },

    view: ({state, attrs: {selectedItems, openUp, boundaryFilter}}) => {
        const {model} = state;
        if (state && state.noPlacesDefined) {
            return <div className="place-search"><div class="no-options-exist">No Places have been added to this {appModel.toolbox.siteTermSingular} yet.</div> </div>;
        }
        const places = model.searchedPlaces || model.places.filter(({placeId}) => !model.selectedMap[placeId]);
        return <div className="place-search" onclick={stopPropagation}>
            <div class="place-container"
                onclick={model.toggleActive}>
                <div className="icon-place-wrap">
                    <i className="icon-place"/>
                </div>
                {selectedItems.length === 0 ?
                    <><div className="text-wrap"><span class="placeholder-text">Tap for list. </span>
                    </div>
                    </>
                    : selectedItems.map((placeId) => {
                        const place = store.places[placeId];
                        return <div class="place-tag">
                            <span class="tag-text">{place.name}</span>
                            <i className="icon-close" onclick={(e) =>
                                model.onDelete(e, place)
                            }/>
                        </div>;
                    })}
            </div>
            {model.active && <PlaceList
                boundaryFilter={boundaryFilter}
                openUp={openUp}
                model={model}
                places={places}
            />}
        </div>;
    }
};

export default PlaceSearch;
