import pointMenu from 'legacy/components/point-menu';
import convertMithril from 'util/dom/convert-mithril';
import mediaModel from 'models/media-model';
import siteModel from 'models/site-model';
import assetListManager from 'managers/asset-list-manager';
import formModel from 'models/form-model';
import AssetForm from 'views/asset-form/asset-form';
import mediaViewerModel from 'models/media-viewer-model';
import store from 'util/data/store';
import appModel from 'models/app-model';
import dialogModel from 'models/dialog-model';
import mediaListManager from 'managers/media-list-manager';


const popup = (media, isFullScreen, control, isInvalid) => new Promise(resolve =>
    assetListManager.fetch(media.contentId || formModel.assetId)
        .then(asset => {
            const mediaId = media.mediaId,
                feature = mediaModel.getFeature(mediaId, asset.contentId),
                canCreateAssetType = !isInvalid && appModel.user.permissions.canEditContent(asset.contentId),
                isInfoPanelOpen = siteModel.sidebar === AssetForm;
            const element = convertMithril.toDom(<div>
                {!isInfoPanelOpen && <div className="menu-option" onclick={() => {
                    formModel.viewAsset(asset.contentId, 'Properties');
                    pointMenu.close();
                    mediaModel.close();
                }}>
                    <i className="icon-asset-form" /> View Properties
                </div>}
                {isInvalid || isFullScreen
                    ? null
                    : <div class="menu-option" onclick={() => {
                        const formAsset = store.assets[formModel.assetId];
                        let thumbnailIndex = formAsset.mediaIds.indexOf(mediaId);
                        if (thumbnailIndex === -1) {
                            formAsset.mediaIds.push(mediaId);
                            thumbnailIndex = formAsset.mediaIds.length - 1;
                        }
                        mediaViewerModel.open(formAsset.mediaIds, formAsset.mediaIds.indexOf(mediaId));
                        pointMenu.close();
                    }}><i class="icon-expand" /> View Media</div>}
                {!isInvalid && <div class="menu-option" onclick={() => {
                    media.download();
                    pointMenu.close();
                }}><i class="icon-download" /> Download
                </div> }
                {!isInvalid && <div class="menu-option" onclick={() => {
                    media.openInNewTab();
                    pointMenu.close();
                }}><i class="icon-window" /> Open in New Tab
                </div> }
                {!isInvalid && !isFullScreen && feature
                    ? <div class="menu-option" onclick={() => {
                        siteModel.view = null;
                        assetListManager.centerOnMap(asset.contentId);
                        pointMenu.close();
                    }}><i class="icon-center-on-map" /> Center on Map</div>
                    : null}
                {!isInvalid && !isFullScreen && appModel.user.isContentEditable(asset.contentId) && assetListManager.supportsFileFeatures(asset.contentId)
                    ? <div class="menu-option" onclick={() => {
                        mediaModel.editFeature(feature, mediaId);
                        pointMenu.close();
                    }}><i class="icon-marker-outline" /> {feature ? 'Edit' : 'Add'} Location</div>
                    : null}
            </div>);

            if (canCreateAssetType
                && media.canCreateLayer) {

                element.appendChild(convertMithril.toDom(
                    <div class="menu-option show-on-large" onclick={() => mediaModel.createLayer(mediaId, asset.contentId).then(() => pointMenu.close())}><i class="icon-add-layer" /> Create New Map Layer</div>
                ));

            }

            !!control && appModel.user.isContentEditable(formModel.assetId) && element.appendChild(convertMithril.toDom(
                <div class="menu-option delete-attachment remove-link" onclick={() =>
                    dialogModel.open({
                        headline: 'Delete this attachment?',
                        text: 'Please note that this operation cannot be undone.',
                        yesText: 'Delete',
                        onYes: () => {
                            mediaModel.deleteMediaAttachment(formModel.assetId, mediaId, control);
                            pointMenu.close();
                        },
                        yesClass: 'btn btn-pill btn-red',
                        noText: 'Cancel',
                        noClass: 'btn btn-pill btn-secondary'
                    })}>
                    <i class="icon-trash" /> Delete
                </div>));

            resolve(element);

        }));

const MediaOptions = {
    view: ({attrs: {isInvalid, mediaId, isFullScreen, control, contentId}}) => {
        let media = mediaListManager.getMedia(mediaId);
        if (!media && !isInvalid || mediaViewerModel.inStandaloneMode) {
            return '';
        }
        
        if (isInvalid) {
            media = {contentId: contentId || formModel.assetId, mediaId}; // if the media was invalid, just send in an object with the contentId so we can use in the popup on the next line
        }
        return <i class="icon-ellipsis" onclick={e => pointMenu({
            e,
            content: popup(media, isFullScreen, control, isInvalid),
            cssClass: 'link-list',
            forceDesktop: true,
            noLine: true
        })} />;
    }
};

export default MediaOptions;
