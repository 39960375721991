import layerModel from 'models/layer-model';
import planModel from 'models/plan-model';
import Folder from 'views/folder/folder';
import { FolderOptionsEllipsisMenu } from 'views/folder/folder-options-menu';

export const CenterOnMapIcon = {
    oninit: ({state}) => {
        state.handleClick = (e, planId) => {
            e.stopPropagation();
            planModel.centerOnMap(planId);
        };
        
    },
    view: ({state, attrs: {planId}}) => {
        return <div class="center-on-map-button" onclick={(e) => state.handleClick(e, planId)}><i class="icon-center-on-map UFtracker-center-on-map" /></div>;
    }
};

const PlansLayerPicker = {
    view: () => {
        const isCreatingNewGroup = layerModel.folders.state.creatingNewFolder;
        const showMoreOptions = layerModel.folders.zOrder.length && Object.values(layerModel.folders.items).length;
        return <div class={layerModel.folders.state.isExpanded ? 'expanded' : ''}>
            <div class="layer-picker-item layer-picker-item-plans">
                <i class={`toggle-switch toggle-is-${layerModel.folders.state.isToggledOn ? 'on' : 'off'}`} onclick={(e) => layerModel.folders.toggleVisibility(e)}/>
                <span class="layer-picker-item-name" onclick={(e) => layerModel.folders.menuOpenOn || layerModel.folders.colorPickerOn ? layerModel.folders.closePopups() : layerModel.folders.toggleVisibility(e)}><i class="icon-layers" /> Map Layers</span>
                <span class={`btn btn-secondary new-group-button UFtracker-new-group btn-pill btn-smallest${isCreatingNewGroup ? ' disabled' : ''}`} onclick={(e) => {
                    e.stopPropagation;
                    if (!isCreatingNewGroup) {
                        layerModel.createFolder();
                    }
                }}>{isCreatingNewGroup ? <i class="spinner spinning teal"/> : ''}<span class="plus">+</span> New Group</span>
                {showMoreOptions ? <i class="icon-down icon" onclick={e => layerModel.folders.toggleState('isExpanded', e)} /> : '' }
                {showMoreOptions ? <FolderOptionsEllipsisMenu folder={layerModel.folders}/> : ''}
                <div class="top-level-items-plans">
                    {layerModel.showPlansSpinner ? <i class="spinner spinning teal"/> : <Folder folder={layerModel.folders}/>}
                </div>
            </div>
        </div>;
    }
};

export default PlansLayerPicker;
