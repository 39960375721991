import createLayerFlow from 'flows/create-layer/create-layer-flow';
import {PlanEditorHeader} from 'views/plan/plan-editor/plan-editor';
import LoadingSpinnerWithText from 'views/loader/loading-spinner-with-text';
import PageList from 'views/plan/pages/page-list';

const SelectPage = {
    view: () => {
        const pageCount = createLayerFlow.sortedPages && createLayerFlow.sortedPages.length || 0;
        return  <div class="plan-editor select-page select-page-outer-wrap">
            <PlanEditorHeader titleIconClass="icon-document" planName={createLayerFlow.mediaLabel} mediaLabel={'Source File for new Map Layer'}/>
            {pageCount ? 
                <PageList/> 
                : <LoadingSpinnerWithText iconClass="icon-document" text="Analyzing File" outerClass="color-teal1" spinnerColor="teal"/>}
        </div>;
    }
};

export default SelectPage;
