import FilterTypeModel from 'models/table/filter-types/filter-type-model';
import tableModel from 'models/table/table-model';
import debounce from 'util/events/debounce';
 
const CUSTOM_TEXT_CONTAINS = 3;

class TextFilterModel extends FilterTypeModel {

    init() {
        super.init({type: 'text', specialProperties: ['selectedRadioIndex']});
        this.debouncedFilterUpdate = debounce(this.onFilterUpdate.bind(this));
    }

    selectRadio(i) {
        if (i === this.state.selectedRadioIndex) {
            return;
        }
        this.state.selectedRadioIndex = i;
        if (i === CUSTOM_TEXT_CONTAINS) {
            if (this.state.queryParam !== undefined && this.state.queryParam.length > 0) {
                tableModel.filtersChanged();
            }
            return;
        }
        this.onFilterUpdate();
    }

    setQueryParam(e) {
        const target = e.target;
        this.state.queryParam = target.value;
        this.debouncedFilterUpdate();
    }
}

export default new TextFilterModel();
