import api from 'legacy/util/api';
import store from 'util/data/store';
import router from 'uav-router';
import randomId from 'util/numbers/random-id';
import initializer from 'util/initializer';
import debouncedAPICall from 'util/network/debounced-api-call';
import formModel from 'models/form-model';
import constants from 'util/data/constants';
import siteModel from 'models/site-model';
import appModel from 'models/app-model';
import screenHelper from 'legacy/util/device/screen-helper';
import getToolInterface from 'util/interfaces/get-tool-interface';
import helpers from 'legacy/util/api/helpers';
import deepCloneObject from 'util/data/deep-clone-object';
import tableModel from 'models/table/table-model';
import {bounds, latLngsToLngLats} from 'util/geo';
import centroid from '@turf/centroid';
import Filepicker from 'util/interfaces/filepicker';
import placeModel from 'models/place-model';
import mediaModel from 'models/media-model';
import featureToControl from 'util/interfaces/feature-to-control';
import {asset1IsNewer} from 'util/data/asset-1-is-newer';
import batchSelectModel from 'models/batch-select-model';
import AssetOptionsModel from 'models/asset/asset-options-model';
import featureListManager from 'managers/feature-list-manager';
import mediaListManager from 'managers/media-list-manager';
import requestBatches from 'util/network/request-batches/request-batches';
import AssetModel from 'models/asset/asset-model';
import {assetIsImageType, assetIsSurveyType} from 'util/data/helpers';

class AssetListManager {
    constructor() {
        this.init();
    
        initializer.add(() => assetListManager.init(), 'assetListManager');
    }
    
    init() {
        this.reset();
        this.hasUnsavedChanges = {}; // Tracking the assetIds that require an additional autosave after publishing
    }
    
    reset() {
        this.all = {};
        this.names = {}; 
        this._shouldShowCustomAssetIcon = {};
    }

    assetNotInStore(asset) {
        return !asset || !asset.updatedDateTime;
    }

    getById(assetId, withBackgroundFetch = false, onFetchCallback) {
        const asset = store.assets[assetId] || this.all[assetId]; // TODO FIXME once we've fully moved away from the store, we can remove the conditional
        if (withBackgroundFetch && this.assetNotInStore(asset)) {
            assetListManager.backgroundFetch(assetId, true, onFetchCallback);
        }
        return asset; 
    }

    getToolFromFeatureType(featureTypeId) {
        const featureType = appModel.toolbox.featureTypes[featureTypeId];
        return featureType.tool.toolId;
    }

    getAssetTypeFromFeatureType(featureTypeId) {
        const featureType = appModel.toolbox.featureTypes[featureTypeId];
        return featureType.assetTypeId;
    }

    /**
     * Only show the map feature card icon as a thumbnail size if it's an image type
     */
    showThumbnailSizeIcon(assetId) {
        const asset = assetListManager.getById(assetId);
        if (!asset || appModel.project.isMetaProject) {
            return false;
        }

        if (assetIsImageType(asset) && asset.media) {
            const mediaIds = helpers.list(asset.mediaIds);
            const mediaId = mediaIds && mediaIds[0];
            if (mediaId) {
                const thumbnailData = mediaListManager.getThumbnailData(mediaId);
                return !!thumbnailData.isImage;
            }
        }
        return false;

    }

    addFromFeature(featureRecord) {
        if (!featureRecord.properties || !featureRecord.properties.assetId) {
            return;
        }

        const assetId = featureRecord.properties.assetId;
        if (!this.all[assetId]) {
            this.all[assetId] = new AssetModel(featureRecord);
        }
    }
    
    resetName(assetId) {
        delete assetListManager.names[assetId];
        m.redraw();
    }

    // Cache asset name so we can call repeatedly from within views & also keep it updated
    getAssetName(assetId) {
        if (!assetListManager.names[assetId]) {
            const asset = assetListManager.getById(assetId);
            if (!asset || !asset.assetTypeId) {
                assetListManager.backgroundFetch(assetId);
                return '';
            }
            const nameControlLabel = store.assetTypeIdToNameControl[asset.assetTypeId];
            const assetTypeName = store.assetTypes[asset.assetTypeId] ? store.assetTypes[asset.assetTypeId].name : '';
            assetListManager.names[assetId] = asset.properties[nameControlLabel] || assetTypeName;
        }
        return assetListManager.names[assetId];
    }

    getShouldShowCustomAssetIcon(assetTypeId) {
        if (this._shouldShowCustomAssetIcon[assetTypeId] === undefined) {
            this._shouldShowCustomAssetIcon[assetTypeId] = assetIsImageType({assetTypeId}) || assetIsSurveyType({assetTypeId});
        }
        return this._shouldShowCustomAssetIcon[assetTypeId];
    }

    getAssetType(assetId) {
        const asset = assetListManager.getById(assetId);
        if (!asset) {
            assetListManager.backgroundFetch(assetId);
            return '';
        }
        return store.assetTypes[asset.assetTypeId];
    }

    getStaticIconUrl(assetId) {
        const asset = assetListManager.getById(assetId);
        if (!asset) {
            assetListManager.backgroundFetch(assetId);
            return '';
        }
        const assetType = store.assetTypes[asset.assetTypeId];
        if (!assetType) {
            return '';
        }
        return constants.staticMediaURL + assetType.attributes.icon.mediaId;
    }

    getIconUrl(assetId) {
        let customIconUrl = undefined;

        const assetType = assetListManager.getAssetType(assetId);
        const assetTypeId = assetType ? assetType.assetTypeId : undefined;
        
        if (assetTypeId && assetListManager.getShouldShowCustomAssetIcon(assetTypeId)) {
            const asset = assetListManager.getById(assetId);
            if (!asset || !asset.updatedDateTime) {
                assetListManager.backgroundFetch(assetId);
                if (!asset) {
                    return '';
                }
            }
            const mediaIds = helpers.list(asset.mediaIds);
            const media = mediaListManager.getMedia(mediaIds[0]);
            customIconUrl = media ? media.getIconSourceUrl() : undefined;
        }

        return customIconUrl || assetListManager.getStaticIconUrl(assetId);
    }
    
    setPageTitle(assetId) {
        if (siteModel.isInfoPanelOpen()) {
            const pageTitle = assetListManager.getAssetName(assetId);
            appModel.setPageTitle(pageTitle); 
        }
    }

    clearAssetNames() {
        assetListManager.names = {};
        m.redraw();
    }

    backgroundFetch(contentId, forceSync = false, onFetchCallback) {
        if (!contentId) {
            return Promise.resolve();
        }

        const asset = store.assets[contentId];
        if (asset && !forceSync) {
            return Promise.resolve(asset);
        }

        return requestBatches.add('listContent', contentId, (_asset) => {
            if (!asset || asset1IsNewer(_asset, asset)) {
                assetListManager.addToStore(_asset, forceSync);
            }
            if (onFetchCallback) {
                onFetchCallback();
            }
            return _asset;
        });
    }

    
    fetch(contentId, forceSync = false) {
        if (!contentId) {
            return Promise.resolve();
        }

        const asset = store.assets[contentId];
        if (asset && !forceSync) {
            return Promise.resolve(asset);
        }

        // Pause batches until this fetch returns so we can prioritize this asset
        requestBatches.pauseTimer('listContent');

        return api.rpc.request([['listContent', {
            include: ['media'],
            contentId,
            projectId: appModel.project.projectId,
            limit: 1
        }]]).then(([_asset]) => {
            // Now resume batches that might have queued while on pause
            requestBatches.unpauseTimer('listContent');
            // TODO TODOAMY as per table-model await requestBatches.send('listContent');

            if (_asset) {
                if (!asset || asset1IsNewer(_asset, asset)) {
                    assetListManager.addToStore(_asset, forceSync);
                }
            }
            return _asset;
        });

    }
    addToStore(asset, doUpdate) {
        if (doUpdate || !store.assets[asset.contentId]) {
            asset.placeIds = helpers.list(asset.placeIds);
            asset.levelIds = helpers.list(asset.levelIds);
            asset.mediaIds = helpers.list(asset.mediaIds);
            asset.media = helpers.list(asset.media).filter(media => !media.isVisible);
            asset.media.forEach((media) => mediaListManager.addMedia(media));
            store.assets[asset.contentId] = new AssetModel(asset);
            assetListManager.resetName(asset.contentId);
        }
        return store.assets[asset.contentId];
    }

    autosave(assetId, includeAttributes = false) {
        const asset = store.assets[assetId];
        if (!asset.updatedDateTime) { // asset isn't saved
            formModel.saving = {};
            assetListManager.hasUnsavedChanges[assetId] = new Date();           
            return Promise.resolve();
        }
        return debouncedAPICall('modifyAsset' + assetId, ['modifyContent', {
            contentId: assetId,
            properties: asset.properties,
            mediaIds: asset.mediaIds,
            isVisible: true,
            attributes: includeAttributes ? asset.attributes : undefined
        }]).then(() => {
            formModel.saving = {};
            delete assetListManager.hasUnsavedChanges[assetId];            
            tableModel.activeCell.onAssetSave(assetId);
            formModel.triggerEval(undefined, true);
            m.redraw();
        });

    }

    supportsFileFeatures(assetId) {
        const asset = store.assets[assetId],
            tool = appModel.toolbox.tools[asset.attributes.toolId],
            fileControlTypeId = constants.controlTypeNameToId.file;

        const fileControlLabels = {};

        tool.assetForm.controls.forEach(control => {

            if (control.controlTypeId === fileControlTypeId) {

                fileControlLabels[control.fieldName] = true;

            }

        });

        // To create a feature, we need a feature type.
        // We can pick a suitable feature type for media
        // by making sure it meets these criteria:
        // - has geometry
        // - uses the filepicker interface
        // - is linked to a file control
        return tool.featureTypes.find(f => f.geometry
            && f.attributes.interface === 'filepicker'
            && f.attributes.linkedControls
            && f.attributes.linkedControls.find(label => fileControlLabels[label])
        );
    }

    supportsGeometry(assetId) {

        const asset = store.assets[assetId],
            tool = appModel.toolbox.tools[asset.attributes.toolId];

        return tool.featureTypes.find(featureType => featureType.geometry && featureType.attributes.interface !== 'action');

    }

    supportsCloning(assetId) {

        const asset = store.assets[assetId];

        if (asset.contentType !== 'Plan' && asset.contentType !== 'Survey') {

            const assetFormId = store.assetTypeToFormId[asset.assetTypeId],
                assetForm = store.assetForms[assetFormId],
                projectControlId = constants.controlTypeNameToId.project;

            if (!assetForm.controls.find(c => c.controlTypeId === projectControlId)) {
                return true;
            }

        }
    }

    getLayers(assetId, layerType) {
        const asset = this.getById(assetId);
        if (asset) {
            return asset.getLayers(layerType);
        }
        return null;
    }

    getSurveyId(assetId) {

        const asset = store.assets[assetId],
            tool = appModel.toolbox.tools[asset.attributes.toolId],
            surveyControlTypeId = constants.controlTypeNameToId.survey,
            surveyControl = tool.assetForm.controls.find(c => c.controlTypeId === surveyControlTypeId);

        if (surveyControl) {

            return asset.properties[surveyControl.fieldName];

        }
    }

    getCompleteLayerId(assetId, layerType) {
        const layers = assetListManager.getLayers(assetId, layerType);
        if (!layers) {
            return null;
        }
        const completeLayer = layers.find(layer => layer.status === 'complete' || layer.status === 'ready');
        return completeLayer ? completeLayer.planId || completeLayer.surveyId : null;
    }

    getLayerBounds(layerId, layerType) {

        const layer = store[layerType + 's'][layerId];

        const tilesetId = layerType === 'survey'
            ? layer.visibleTilesetId
            : layer.tilesetId;

        const tileset = layer && store.tilesets[tilesetId];

        return tileset && latLngsToLngLats(tileset.bounds);
    }

    centerOnMap(assetId) {
        const offset = siteModel.map.tableOffset;
        assetListManager.panToAsset(assetId, offset);
    }

    selectAsset(assetId) {
        if (AssetOptionsModel.hasFeatures(assetId)) {
            formModel.getAssetFeatures(assetId).then(features => {
                features.forEach((feature) => {
                    this.feature = feature;
                    this.type = feature.geometry.type;
                });
                const id = assetId;
                switch (this.type) {
                case 'Point':
                    const latLngs = features.map((feature) => centroid(feature).geometry.coordinates);
                    siteModel.mapModel.selectPointAsset(id, latLngs);
                    break;
                case 'Polygon':
                    const coords = features.map((feature) => feature.geometry.coordinates[0]);
                    batchSelectModel.selectedAssetsFeatures[assetId] = coords;
                    siteModel.mapModel.selectPolygonAsset(id, coords);
                    break;
                case 'LineString':
                    const coordinates = features.map((feature) => {
                        return feature.geometry.coordinates;
                    });
                    batchSelectModel.selectedAssetsFeatures[assetId] = coordinates;
                    siteModel.mapModel.selectLineAsset(id, coordinates);
                    break;
                }
            });
        }
    }

    deselectAsset(assetId) {
        if (siteModel.mapModel.getLayer(assetId + '_line')) {
            siteModel.mapModel.removeLayer(assetId + '_line');
        }
        siteModel.mapModel.safeRemoveSource(assetId);
    }

    panToAsset(assetId, offset) {

        if (!appModel.user.permissions.canReadContent(assetId)) {
            router.set({projectId: router.params.projectId, siteId: router.params.siteId});
            return appModel.user.permissions.displayMessage({
                action: 'READ', 
                recordType: 'content', 
                xid: assetId,
                role: appModel.user.role
            });
        }
        
        formModel.getAssetFeatures(assetId).then(features => {

            if (features.length) {
                if (!(formModel.isNew && appModel.project.isMetaProject)) {
                    siteModel.map.panToFeatures(features, offset);
                }

                if (assetId === this.openAssetId) {

                    return;

                }

                this.openAssetId = assetId;

                const callbackId = randomId();

                const timedCallback = () => {
                    const latLngs = features.map((feature) => centroid(feature).geometry.coordinates);
                    if (!formModel.isNew) {
                        siteModel.leftClick(features, latLngs[0])
                            .then((featurePopup) => {
                                if (featurePopup && !formModel.editingFeatureId) {
                                    const id = 'pan-to-asset';
                                    siteModel.mapModel.addColorCircle(id, latLngs, {
                                        opacity: 0.6,
                                        radius: 40
                                    });
                                    featurePopup.once('close', () => {
                                        // Make sure we havent torn down the map when trying to remove this source
                                        if (siteModel.isMapReady) {
                                            siteModel.mapModel.safeRemoveSource(id);
                                        }
                                        delete this.openAssetId;
                                    });
                                }
                            });
                    }
                };
                siteModel.timedCallbacks[callbackId] = timedCallback;


                // Only execute the callback if the sitemodel hasnt been torn down to open a new project
                setTimeout(() => {
                    if (siteModel.timedCallbacks[callbackId]) {
                        siteModel.timedCallbacks[callbackId]();
                        delete siteModel.timedCallbacks[callbackId];
                    }
                }, 500);

            } else {

                const planId = assetListManager.getCompleteLayerId(assetId, 'plan');
                const plan = store.plans[planId];

                let layerBounds = plan && plan.status !== 'assembling' && bounds(assetListManager.getLayerBounds(plan.planId, 'plan'));

                if (!layerBounds) {

                    const surveyId = assetListManager.getCompleteLayerId(assetId, 'survey');

                    if (surveyId) {

                        const _bounds = assetListManager.getLayerBounds(surveyId, 'survey');
                        layerBounds = bounds(_bounds);

                    }

                    const asset = store.assets[assetId];

                    if (asset && asset.placeIds && asset.placeIds.length) {

                        placeModel.panToPlaces(asset.placeIds);

                    }

                }

                if (layerBounds) {

                    formModel.turnOnLayer(assetId);

                    siteModel.map.focusOnBounds(layerBounds);

                }

            }

        });

    }

    /**
     * Given an assetId, checks if the user has editing abilities on it and if the asset is mappable.
     */
    canAddToMap(assetId) {
        if (formModel.editingFeatureId || !appModel.user.isContentEditable(assetId)) {

            return false;

        }

        return assetListManager.supportsMapping(assetId);
    }

    supportsMapping(assetId) {

        if (assetListManager.supportsGeometry(assetId)) {

            return true;

        }

        const asset = store.assets[assetId],
            tool = appModel.toolbox.tools[asset.attributes.toolId];
        const isAction = tool.featureTypes.find(featureType => featureType.attributes.interface === 'action');

        return !assetListManager.getCompleteLayerId(assetId) && screenHelper.canEditLayers() && !assetListManager.getSurveyId(assetId) && !isAction;

    }

    /**
     * Given an asset, clone it and return the clone
     * (currently does not support cloning of features)
     */
    createClone(assetId) {

        // Create starter asset of correct type
        const asset = store.assets[assetId],
            tool = appModel.toolbox.tools[asset.attributes.toolId],
            assetClone = store.assets[getToolInterface(tool).assetId];

        // Update name of clone
        const name = 'Copy of ' + assetListManager.getAssetName(assetId);

        // Copy asset properties & attributes
        assetClone.properties = Object.assign({}, asset.properties);
        assetClone.attributes = asset.attributes;

        // Update name control, and check for unclonable controls
        tool.assetForm.controls.forEach(control => {
            if (control.controlTypeId === constants.controlTypeNameToId.name) {
                assetClone.properties[control.fieldName] = name;
            }
            if (control.attributes.resetOnClone) {
                if (control.attributes.default === undefined) {
                    delete assetClone.properties[control.fieldName];
                } else {
                    assetClone.properties[control.fieldName] = deepCloneObject(control.attributes.default);
                }
            }
        });

        // Find any attached media and clone them
        const allPromises = [];

        assetClone.mediaIds = asset.mediaIds;

        tool.assetForm.controls.forEach(control => {

            const controlValue = asset.properties[control.fieldName];

            // Don't clone user assignments
            if (control.controlTypeId === constants.controlTypeNameToId.user && controlValue) {

                delete assetClone.properties[control.fieldName];

            }

            // COMMENTED OUT UNTIL WE GET https://unearth.atlassian.net/browse/API-579

            // if (control.controlTypeId === constants.controlTypeNameToId.file && controlValue) {

            //     allPromises.push(

            //         Promise.all(controlValue.map(mediaModel.getMedia)).then(mediaRecords =>

            //             Promise.all(mediaRecords.map(mediaRecord => mediaModel.createClone(mediaRecord)))

            //                 .then(clonedMediaRecords => {

            //                     const clonedMediaIds = clonedMediaRecords.map(media => media.mediaId);

            //                     assetClone.mediaIds.push(...clonedMediaIds);

            //                     assetClone.properties[control.fieldName] = clonedMediaIds;

            //                 })

            //         ));

            // }

        });

        // Copy linked asset references.
        assetClone.linkIds = asset.linkIds || [];
        const linkedAssetUpdates = [];

        // Add clone to linked assets link list (skip assets not found in store)
        assetClone.linkIds.forEach((linkedAssetId) => {
            const linkedAsset = store.assets[linkedAssetId];
            if (linkedAsset) {
                const linkIds = linkedAsset.linkIds || [];
                linkIds.push(assetClone.contentId);
                linkedAssetUpdates.push(['modifyContent', {
                    contentId: linkedAssetId,
                    linkIds
                }]);
            }
        });

        // Publish clone and associated changes to DB
        Promise.all(allPromises)
            .then(() => api.rpc.create('Content', api.apiSafeContent(assetClone)))
            .then(() => api.rpc.requests(linkedAssetUpdates));

        return assetClone;
    }

    isProjectAsset(asset) {
        const {assetTypeId} = asset;
        const assetFormId = store.assetTypeToFormId[assetTypeId],
            assetForm = store.assetForms[assetFormId];
        const index = assetForm.controls.findIndex((control) => control.controlTypeId === constants.controlTypeNameToId.project);
        return index !== -1;
    }

    isLink(assetId) {
        return formModel.assetId && assetId !== formModel.assetId;
    }

    isLayer(assetId) {
        const asset = store.assets[assetId];
        if (!asset || !asset.attributes || !asset.attributes.toolId) {
            return;
        }
        const tool = appModel.toolbox.tools[asset.attributes.toolId];
        return tool.hasPlanControl() || tool.hasSurveyControl();
    }

    createAssetFromMediaRecord(mediaRecord) {
        const coordinates = Filepicker.getMediaCoordinates(mediaRecord),
            tool = mediaModel.getLinkTool(mediaRecord),
            featureType = tool.featureTypes[0],
            contentId = randomId(),
            featureIds = [];

        if (coordinates) {
            const feature = {
                type: 'Feature',
                id: randomId(),
                featureTypeId: featureType.featureTypeId,
                geometry: {
                    type: 'Point',
                    coordinates: coordinates
                },
                properties: Object.assign({}, featureType.getDefaultProperties(), {
                    assetId: contentId,
                    mediaId: mediaRecord.mediaId,
                    featureTypeId: featureType.featureTypeId
                })
            };
            featureListManager.addFeatures([feature], true);
            featureIds.push(feature.id);
        }

        const newAsset = {
            contentId,
            toolId: tool.toolId,
            linkIds: [],
            projectId: router.params.projectId,
            recipientIds: [appModel.user.userId],
            featureIds,
            properties: {},
            mediaId: mediaRecord.mediaId,
            mediaIds: [mediaRecord.mediaId],
            attributes: {
                toolId: tool.toolId,
                toolGroupIds: []
            }
        };
        assetListManager.addToStore(newAsset);
        featureToControl.sync('filepicker', [mediaRecord], contentId, featureType);

        return newAsset;
    }

    syncFeaturesToControl(assetOrContentId, controlLabel) {
        if (typeof assetOrContentId === 'string') {
            assetOrContentId = store.assets[assetOrContentId];
        }
        if (!assetOrContentId) {
            return;
        }
        assetOrContentId.featureIds && assetOrContentId.featureIds.forEach(featureId => {
            const feature = featureListManager.getById(featureId);
            if (feature) {
                feature.syncFeatureToAssetControlProperty(controlLabel, assetOrContentId.properties);
            }
        });
    }


}

const assetListManager = new AssetListManager();

export default assetListManager;
