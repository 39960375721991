import { STEPS, HIGHEST_EXTENSION_SCORE, SCORES_BY_EXTENSIONS } from 'constants/flows/upload-help-constants';
import { canAutomateExtension, canAcceptExtension, getExtensionForFileObject, getLocationFromMedia, getLocationFromPlanLayer, getToolsPerMimeType, getToolsByInterface } from 'flows/upload-help/upload-help-utils';
import logConstants from 'constants/managers/log-constants';
import { datadogRum } from '@datadog/browser-rum';
import router from 'uav-router';
import Flow from 'flows/flow-model';
import { lngLatsToLatLngs, lngLatsToBounds } from 'util/geo';
import modalModel from 'models/modal-model';
import store from 'util/data/store';
import api from 'legacy/util/api';
import randomId from 'util/numbers/random-id';
import validate from 'util/geo/validate';
import ajax from 'legacy/util/api/ajax';
import cookie from 'util/data/cookie';
import publish from 'legacy/util/api/publish';
import getToolInterface from 'util/interfaces/get-tool-interface';
import featureToControl from 'util/interfaces/feature-to-control';
import featureListManager from 'managers/feature-list-manager';
import appModel from 'models/app-model';
import formModel from 'models/form-model';
import oneUpModel from 'models/one-up-model';
import UploadHelp from 'views/upload-help';
import siteModel from 'models/site-model';
import layerModel from 'models/layer-model';
import Step from 'flows/step-model';
import message from 'views/toast-message/toast-message';
import { appUrl } from 'util/data/env';
import mediaListManager from 'managers/media-list-manager';
import constants from 'util/data/constants';
import mapConstants from 'constants/util/map-constants';
import {assetTypeIsPlanType, assetIsSurveyType} from 'util/data/helpers';
import assetListManager from 'managers/asset-list-manager';
import createLayerFlow from '../create-layer/create-layer-flow';

const SITE_BOUNDS_ID = mapConstants.SITE_BOUNDS_ID;

const SPECIAL_SHP_FILE_MESSAGE = 'We\'re unable to map files with the SHP extension. Please try uploading a ZIP package containing a SHP file or contact our support team for assistance.';

class UploadHelpFlow extends Flow {
    constructor() {
        super();

        this.reset();

        this.initStepFunctions = {
            [STEPS.START]: this._initStart.bind(this),
            [STEPS.PROMPT]: this._initPrompt.bind(this),
            [STEPS.SELECT_TOOL]: this._initSelectTools.bind(this),
            [STEPS.SELECT_ADDRESS]: null,
            [STEPS.PROCESS]: this._initProcess.bind(this),
            [STEPS.DONE]: null,
            [STEPS.HUBSPOT_PROCESS]: this._initHubspotProcess.bind(this),
            [STEPS.HUBSPOT_DONE]: null
        };

        this.steps = {};
        Object.values(STEPS).forEach(step => {
            this.steps[step] = new Step(step, this.initStepFunctions[step]);
        });
    }

    reset() {
        super.reset();
    
        this.currentFile = null;
        this.currentMedia = null;
        this.automatedFiles = []; // To be handled in-app
        this.manualFiles = []; // To be sent to hubspot
        this.toolChoices = [];
        this.filesByToolId = {};
        this.locationData = null;
        this.childToolboxId = undefined;
        this.toolsForToolboxId = {};
        this.selectedPrimaryTool = null;
        this.childProjectAsset = {};
        this.firstMedia = {};
        this.childProjectTool = undefined;
        this.noAutomatedLocation = undefined;

        this.statusText = [];
        this.hubspotForm = null;

        if (appModel && !this.keepActiveToolOpen) { // Keep active tool open if we are going from the modal directly to the asset form for editing.
            appModel.toolbox.closeActiveTool();
        }

        this.keepActiveToolOpen = false;
    }

    get allFiles() {
        return [...this.automatedFiles, ...this.manualFiles];
    }

    get isDone() {
        return this.onStep === STEPS.DONE || this.onStep === STEPS.HUBSPOT_DONE;
    }

    get isDoneMapping() {
        return this.onStep === STEPS.DONE;
    }

    addProcessingMessage(inProgressText, doneText, cssClass) {
        this.statusText.push({ inProgressText, doneText, cssClass });
        m.redraw();
    }

    async createDocument(projectId = appModel.project.projectId) {
        const documentId = randomId();
        this.currentDocument = { documentId };
        const promise = new Promise((resolve, reject) => {
            publish.await({
                changeType: 'modified',
                recordType: 'document',
                test: change => change.documentId === documentId,
                callback: (change) => {
                    resolve(change);
                }
            });
            publish.await({
                changeType: 'failed',
                recordType: 'document',
                test: change => change.documentId === documentId,
                callback: () => {
                    reject();
                }
            });
        });
        api.rpc.create('Document', {
            documentId: documentId,
            mediaId: this.currentMedia.mediaId,
            projectId
        });
        return promise;
    }

    createChildProject() {
        return appModel.toolbox.toolInterface.launch(true).then(childProjectAssets => {
            if (childProjectAssets) {
                this.childProjectAsset = childProjectAssets[0];
                return api.rpc.requests(childProjectAssets.map(childProjectAsset => ['createContent', api.apiSafeContent(childProjectAsset)]));
            }
            return Promise.resolve(false);
        });
    }
    
    setProjectLocationFromSearch(coordsGeoJSON, projectName) {
        uploadHelpFlow.projectName = projectName;
        uploadHelpFlow.locationData = coordsGeoJSON.coordinates;
        this.onAddressComplete();
    }

    createChildProjectFromSearch(coordsGeoJSON, projectName) {
        uploadHelpFlow.projectName = projectName;
        uploadHelpFlow.locationData = coordsGeoJSON.coordinates;
        appModel.toolbox.launchToolInterfaceTool(appModel.toolbox.toolInterface, true);
        message.show(`Creating ${appModel.toolbox.toolInterface.tool.name}...`);
    }

    async loadChildProject() {
        appModel.changeProject(formModel.childProjectId);
        appModel.setState('isInitted', false);
        await appModel.waitUntilInit();
    }

    async uploadMediaRecords(projectId = formModel.childProjectId, files = [this.currentFile]) {
        const uploadFlowId = randomId();
        let newMediaRecords = await oneUpModel.processFileUploads({
            flowId: uploadFlowId,
            projectId,
            files
        });
        newMediaRecords = newMediaRecords.filter(mediaRecord => {
            if (mediaRecord.status === 'failed') {
                return false;
            }
            return true;
        });

        if (newMediaRecords && newMediaRecords.length) {
            return Promise.resolve(newMediaRecords);
        }
        return Promise.reject();
    }

    selectPrimaryTool(tool) {
        this.selectedPrimaryTool = tool;
    }

    selectSecondaryTools(allToolOptions) {
        const otherFiles = this.automatedFiles.filter(file => file !== this.currentFile);
        otherFiles.push(...this.manualFiles);
        for (let i = 0; i < otherFiles.length; i++) {
            const file = otherFiles[i];
            const toolChoices = getToolsPerMimeType(allToolOptions, file);
            if (!toolChoices || !toolChoices.length) {
                break;
            }
            const choice = toolChoices[0];
            this.filesByToolId[choice.toolId] = this.filesByToolId[choice.toolId] || [];
            this.filesByToolId[choice.toolId].push(file);
        }
    }

    async createKmz() {
        let awaitingNewFolderId = undefined;
        let layersResponse = undefined;

        const promise = new Promise(resolve => {
            publish.await({
                changeType: 'modified',
                recordType: 'layerFolder',
                persist: false,
                test: _layerFolder => awaitingNewFolderId === _layerFolder.folderId,
                callback: async () => resolve(layersResponse)
            });
        });

        layersResponse = await api.rpc.request([['createLayers', {projectId: router.params.projectId, mediaId: this.currentMedia.mediaId}]]);
        awaitingNewFolderId = layersResponse.parent;   

        return promise;
    }
    
    async createPlanAssetWithoutLocation(tool, files) {
        if (files) {
            [this.currentMedia] = await this.uploadMediaRecords(appModel.project.projectId, files);
        }
        mediaListManager.addMedia(this.currentMedia);
        const toolInterface = getToolInterface(tool);

        const [planAsset] = await toolInterface.launch(true).then(_assets => api.rpc.requests(_assets.map(_asset => {
            const apiAsset = api.apiSafeContent(_asset);
            apiAsset.projectId = appModel.project.projectId;
            apiAsset.mediaIds = [this.currentMedia.mediaId];
            return ['createContent', apiAsset];
        })));
       
       
        const mediaId = this.currentMedia.mediaId;
        planAsset.media = [this.currentMedia];
        planAsset.mediaIds = [mediaId];
        planAsset.mediaId = mediaId;
       
        assetListManager.addToStore(planAsset, true);

        createLayerFlow.start({mediaId, assetId: planAsset.contentId});
        return planAsset;
    }

    async createPlanAsset(tool, files, noExceptionHandling = false) {
        let singlePlan = undefined;
        let layersResponse = undefined;

        if (files) {
            [this.currentMedia] = await this.uploadMediaRecords(appModel.project.projectId, files);
        }
        const toolInterface = getToolInterface(tool);

        const receivedCompletedPlan = new Promise(resolve => {
            return publish.await({
                changeType: 'modified',
                recordType: 'plan',
                test: _plan => _plan.status === 'assembling' || _plan.status === 'complete',
                callback: _plan => {
                    store.plans[_plan.planId] = _plan;
                    this.newPlan = _plan;
                    resolve();
                }
            });
        });
        const mediaRecord = mediaListManager.all[this.currentMedia.mediaId];
        const extension = mediaRecord.getExtension();
        if (extension === 'kml' || extension === 'kmz') {
            layersResponse = await this.createKmz(tool, files, noExceptionHandling);
        } else {
            const receivedModifiedDocumentWithPages = new Promise(resolve => {
                publish.await({
                    changeType: 'modified',
                    recordType: 'document',
                    persist: true,
                    test: document => document.documentId === this.currentDocument.documentId && document.pageIds,
                    callback: document => {
                        this.currentDocument = document;
                        resolve();
                    }
                });
            });
            try {
                this.currentDocument = await this.createDocument(appModel.project.projectId);
            } catch (e) {
                if (noExceptionHandling) {
                    return e;
                }
                this.handleUnableToAutomate();
            }

            await receivedModifiedDocumentWithPages;

            const pageIds = (this.currentDocument.pageIds && this.currentDocument.pageIds.items ? this.currentDocument.pageIds.items : this.currentDocument.pageIds) || [];

            singlePlan = await api.rpc.create('Plan', {
                pageId: pageIds[0],
                // pdfs can have multiple pages & we need to show the select page view
                // jpegs, jpgs, and pngs also only have a single page // this might work as-is for those types.
                projectId: router.params.projectId
            });

            store.plans[singlePlan.planId] = singlePlan;
        }

        await receivedCompletedPlan;

        const [asset] = await toolInterface.launch(true).then(_assets => api.rpc.requests(_assets.map(_asset => {
            const apiAsset = api.apiSafeContent(_asset);
            apiAsset.projectId = appModel.project.projectId;
            return ['createContent', apiAsset];
        })));

        const featureType = appModel.toolbox.featureTypes[tool.featureTypes[0].featureTypeId];

        if (asset && asset.attributes) {
            if (singlePlan) {
                featureToControl.sync('plan', singlePlan, asset.contentId, featureType);
            } else if (layersResponse && layersResponse.plans) {
                featureToControl.syncPlanLayersAndFolder(layersResponse.plans, this.currentMedia.label, asset.contentId, featureType);
            }
            const updatedAsset = await api.rpc.request([['modifyContent', {
                contentId: asset.contentId,
                properties: asset.properties,
                mediaIds: [this.currentMedia.mediaId]
            }]]);

            assetListManager.addToStore(updatedAsset, true);

            const anyPlans = Object.values(store.plans);
            anyPlans.forEach(_plan => layerModel.showPlan(_plan));
        }
    } 

    async createSecondaryAssets() {
        const toolIds = Object.keys(this.filesByToolId);
        const allBaseToolOptions = this.toolsForToolboxId[this.childToolboxId];
        for (let i = 0; i < toolIds.length; i++) {
            const toolId = toolIds[i];
            const baseTool = allBaseToolOptions.find(tool => tool.toolId === toolId);
            const baseAssetTypeId = baseTool.assetForm.assetType.base ? baseTool.assetForm.assetType.base.assetTypeId : baseTool.assetForm.assetType.assetTypeId;

            const tool = Object.values(appModel.toolbox.tools).find(t => t.assetForm.assetType.base ? t.assetForm.assetType.base.assetTypeId === baseAssetTypeId : t.assetForm.assetType.assetTypeId === baseAssetTypeId);
            const toolInterface = getToolInterface(tool);
            const allMedia = this.filesByToolId[toolId];
            const assetTypeId = tool.assetForm.assetType.assetTypeId;

            if (assetTypeIsPlanType({assetType: tool.assetForm.assetType})) {
                for (let j = 0; j < allMedia.length; j++) {
                    const extension = getExtensionForFileObject(allMedia[j]).toLowerCase();
                    if (extension === 'zip') {
                        const zipPlan = await this.createPlanAsset(tool, [allMedia[j]], true);
                        // eslint-disable-next-line max-depth
                        if (!zipPlan) {
                            return Promise.reject(new Error('PlanAssetFailed'));
                        }
                    } else {
                        const plan = await this.createPlanAssetWithoutLocation(tool, [allMedia[j]], true);
                        // eslint-disable-next-line max-depth
                        if (!plan) {
                            return Promise.reject(new Error('PlanAssetFailed'));
                        }
                    }

                }
            } else if (assetIsSurveyType({assetTypeId})) {
                for (let j = 0; j < allMedia.length; j++) {
                    const mediaRecords = await this.uploadMediaRecords(appModel.project.projectId, [allMedia[j]]);
                    await toolInterface.launch(mediaRecords, true).then(_assets => api.rpc.requests(_assets.map(_asset => {
                        const asset = api.apiSafeContent(_asset);
                        asset.projectId = appModel.project.projectId;
                        return ['createContent', asset];
                    })));
                }
            } else {
                for (let j = 0; j < allMedia.length; j++) {
                    const mediaRecords = await this.uploadMediaRecords(appModel.project.projectId, [allMedia[j]]);
                    await toolInterface.launch(mediaRecords).then(_assets => {
                        return api.rpc.requests(_assets.map(_asset => {
                            const asset = api.apiSafeContent(_asset);
                            asset.projectId = appModel.project.projectId;
                            return ['createContent', asset];
                        }
                        ));
                    });
                }
            }
        }
    }

    async createFirstAsset(selectedPrimaryTool = this.selectedPrimaryTool, media = this.currentMedia) {

        this.firstMedia = mediaListManager.addMedia(media);
        const tool = Object.values(appModel.toolbox.tools).find(t => t.name === selectedPrimaryTool.name); // TODO fix this to be more specific
        const mediaRecord = mediaListManager.all[media.mediaId];
        const extension = mediaRecord.getExtension();
        if (assetTypeIsPlanType({assetType: tool.assetForm.assetType}) && extension === 'zip') { //this should be shp files
            this.firstAssetIsPlanLayer = true;
            return this.createPlanAsset(tool);
        }
        if (assetTypeIsPlanType({assetType: tool.assetForm.assetType})) { // pdfs or jpgs with no location data
            return this.createPlanAssetWithoutLocation(tool);
        }
       
        const toolInterface = getToolInterface(tool);
        return toolInterface.launch([media], true).then((assets) => {
            return api.rpc.requests(assets.map(_asset => {
                const asset = api.apiSafeContent(_asset);
                asset.projectId = appModel.project.projectId;
                return ['createContent', asset];
            }));
        });

    }

    async modifyProjectBoundsToUploadedFile() {
        let locationData;
        if (this.firstAssetIsPlanLayer) {
            locationData = await getLocationFromPlanLayer(this.newPlan);
        } else {
            locationData = getLocationFromMedia(this.currentMedia);
        }

        if (!locationData) {
            return;
        }

        if (!validate(locationData)) {
            // Send to hubspot
            return this.handleUnableToAutomate();
        }

        return this.modifyProjectBoundsToLocationData(locationData);
    }

    async modifyProjectBoundsToLocationData(locationData) {
        const asset = this.childProjectAsset;
        let feature = featureListManager.getById(asset.featureIds[0]);
        if (!feature) {
            [feature] = await api.rpc.request([['listFeatures', { featureId: asset.featureIds[0], limit: 1 }]]);
        }

        feature.geometry = locationData;

        const [lngLats] = locationData.coordinates;
        let bounds = lngLatsToBounds(lngLats);

        if (this.childProjectId === router.params.projectId) {
            siteModel.boundary = {
                type: 'Polygon',
                coordinates: [lngLats]
            };
            siteModel.map.safeFitBounds(bounds, {
                animate: false
            });
        }

        bounds = [
            [bounds.getNorth(), bounds.getWest()],
            [bounds.getSouth(), bounds.getEast()]
        ];
        const siteBounds = Object.assign({}, store.siteBounds);
        if (siteBounds) {
            siteBounds.boundary = siteModel.boundary;
            store.setObject(SITE_BOUNDS_ID, {
                type: 'Feature',
                id: SITE_BOUNDS_ID,
                geometry: siteModel.boundary
            });
        }
        return api.put.site({
            projectId: this.childProjectId,
            siteId: this.childProjectSiteId,
            bounds,
            boundary: {
                type: 'Polygon',
                coordinates: [lngLatsToLatLngs(lngLats)]
            }
        }).then(() => {
            return api.rpc.request([['modifyFeature', Object.assign(api.apiSafeFeature(feature, true))]]);
        });
    }

    openProjectToolLauncher(e) {
        const tool = Object.values(appModel.toolbox.tools).find(_tool => _tool.featureTypes.find(featureType => featureType.attributes
            && featureType.attributes.interface
            && featureType.attributes.interface === 'project'
        ));
        const toolGroup = tool.toolGroup;
        return appModel.toolbox.selectTool(toolGroup, tool, e);
    }

    async verifyHasLocationData() {
        [this.currentMedia] = await this.uploadMediaRecords(appModel.project.projectId);
        const mimeType = getExtensionForFileObject(this.currentFile).toLowerCase();
        if (assetTypeIsPlanType({assetType: this.selectedPrimaryTool.assetForm.assetType}) && mimeType === 'zip') {
            // Attempt to create document, and if it fails, send to hubspot.
            return this.createDocument(appModel.project.projectId);
        }

        const mediaHasLocationData = getLocationFromMedia(this.currentMedia);

        if (mediaHasLocationData) {
            return true;
        }

        return false;
    }

    async sendToHubspot(mediaRecords) {
        const fields = [{
            'objectTypeId': '0-1',
            'name': 'email',
            'value': appModel.user.emailAddress
        }];
        mediaRecords.forEach(mediaRecord => {
            fields.push({
                'objectTypeId': '0-1',
                'name': 'ssu_files___url',
                'value': `${mediaRecord.label} | ${constants.mediaURL}${mediaRecord.mediaId}`
            });
            fields.push({
                'objectTypeId': '0-1',
                'name': 'ssu_spreadsheet',
                'value': mediaRecord.url
            });
        });

        const hubspotCookie = cookie.readCookie('hubspotutk', false);
        return ajax('https://api.hsforms.com/submissions/v3/integration/submit/4405732/ca576f09-6467-4f06-b435-2089f842d8e3', {
            method: 'POST',
            resolve: () => Promise.resolve(),
            reject: (res) => {
                datadogRum.addError(new Error(logConstants.errorLogMessages.HUBSPOT_FAILURES), res);
                return Promise.resolve();
            },
            data: {
                fields,
                'context': {
                    'hutk': hubspotCookie,
                    'pageUri': appUrl,
                    'pageName': 'SSU Modal'
                }
            }
        });
    }

    async suggest(value) {
        await uploadHelpFlow.steps[STEPS.PROMPT].waitUntilInit();
        return appModel.toolbox.toolInterface.suggest(value);
    }

    /**
     * Determine the project tool to use, get its child toolboxId, and use that to fetch
     * all the tools we'll have available on the child projects created with it. 
     */
    async fetchChildProjectTools() {
        await appModel.waitUntilInit();

        this.childProjectTool = appModel.toolbox.setProjectToolAsInterface();

        if (this.childProjectTool) {
            this.childToolboxId = await appModel.toolbox.getChildToolboxId(this.childProjectTool);
        }

        if (!this.childToolboxId) {
            // This should only happen for internal users who are testing using the "viewAs=oneplace-field" flag on accounts that are not supported.
            console.warn('Unable to automate project creation from the project tools in this account.');
            return modalModel.close();
        }

        appModel.toolbox.toolInterface.toolboxId = this.childToolboxId;
        appModel.toolbox.toolInterface.initGoogle();

        // Fetch all tools from the child project's toolbox
        const tools = await appModel.toolbox.getToolsForToolboxId(this.childToolboxId);
        this.toolsForToolboxId[this.childToolboxId] = tools;

        m.redraw();
    }

    handleFileInput(e) {
        const files = e.files;
        for (let i = 0; i < files.length; i++) {
            const currentFile = files[i];
            const extension = getExtensionForFileObject(currentFile).toLowerCase();
            if (canAcceptExtension(extension)) {
                if (canAutomateExtension(extension)) {
                    this.automatedFiles.push(currentFile);
                } else {
                    this.manualFiles.push(currentFile);
                }
            } else {
                let messageText;
                if (extension === 'shp') {
                    messageText = SPECIAL_SHP_FILE_MESSAGE;
                } else {
                    messageText = `We're currently unable to automatically map files with${extension ? ` ${extension}` : 'out'} extensions. Please try another file format or contact our support team for assistance.`;
                }
                message.show(messageText, 'warning');
            }
        }
        this.selectBestFile();
        m.redraw();
    }

    /**
     * If a user attaches multiple files, for now we're just going to select the one with the 
     * optimal automated upload experience — the rest will be handled as secondary assets.
     */
    selectBestFile() {
        if (this.automatedFiles.length) {
            let currentFile = this.currentFile;
            if (currentFile) {
                const currentExtension = getExtensionForFileObject(currentFile);
                const currentMimeTypeScore = SCORES_BY_EXTENSIONS[currentExtension] || 0;
                if (currentMimeTypeScore >= HIGHEST_EXTENSION_SCORE) {
                    return; // Already have the best (geotiff or tiff)
                }
            }
            let bestFile = this.automatedFiles[0];
            for (let i = 0; i < this.automatedFiles.length; i++) {
                currentFile = this.automatedFiles[i];
                const currentExtension = getExtensionForFileObject(currentFile);
                const mimeTypeScore = SCORES_BY_EXTENSIONS[currentExtension];

                if (mimeTypeScore >= HIGHEST_EXTENSION_SCORE) {
                    bestFile = currentFile;
                    break;
                } else if (mimeTypeScore >= SCORES_BY_EXTENSIONS.zip) { // Shape files (zip) are second best
                    bestFile = currentFile;
                }
            }
            this.currentFile = bestFile;

        }
    }

    handleUnableToAutomate() {
        this.manualFiles.push(...this.automatedFiles);
        this.goToStep(STEPS.HUBSPOT_PROCESS);
    }

    /**
     * Init functions per step
     */

    _initStart() {
        this.reset();
        modalModel.open({ view: UploadHelp });
        this.isActive = true;
        this.nextStep();
    }

    async _initPrompt() {
        if (appModel.project.isMetaProject) {
            await this.fetchChildProjectTools();
        }
    }

    get noAutomatedToolsFound() {
        return !this.automatedFiles.length || !this.toolChoices || !this.toolChoices.length;
    }

    get toolInterfaceString() {
        return this.selectedPrimaryTool && this.selectedPrimaryTool.featureTypes.length && this.selectedPrimaryTool.featureTypes[0].attributes.interface;
    }

    get primaryFileExtension() {
        const media = this.firstMedia;
        return media && media.getExtension();
    }
 
    async _initSelectTools() {
        await this.steps[STEPS.PROMPT].waitUntilInit(); // Ensure we've finished loading the child project tools

        const displayFileText = `File${this.allFiles.length > 1 ? 's' : ''}`;
        this.addProcessingMessage(`Analyzing your ${displayFileText}`, 'Analysis complete', 'analyze-step');
        m.redraw();
    
        const allToolOptions = this.toolsForToolboxId[this.childToolboxId];
        this.toolChoices = getToolsPerMimeType(allToolOptions, this.currentFile);

        if (this.noAutomatedToolsFound) {
            this.goToStep(STEPS.HUBSPOT_PROCESS);
        } else {
            this.selectSecondaryTools(allToolOptions);
            this.selectPrimaryTool(this.toolChoices[0]);
        }
        this.noAutomatedAddress = await this.checkForAutomatedLocation();
        if (this.noAutomatedAddress) {
            return this.nextStep();
        }
        this.goToStep(STEPS.PROCESS);
        return m.redraw();
    }

    resetToolToPlan() {
        const allToolOptions = this.toolsForToolboxId[this.childToolboxId];
        const tool = getToolsByInterface(allToolOptions, 'plan');
        this.selectPrimaryTool(tool[0]);
    }

    async checkForAutomatedLocation() {
        const mimeType = getExtensionForFileObject(this.currentFile).toLowerCase();
        if (mimeType === 'jpeg' || mimeType === 'jpg') {
            const hasLocation =  await this.verifyHasLocationData();
            if (!hasLocation) {
                this.resetToolToPlan();
                return true;
            }
        }
        return mimeType === 'pdf' || mimeType === 'png';
    }

    async _initProcess() {
        const mimeType = getExtensionForFileObject(this.currentFile).toLowerCase();
        if (mimeType === 'zip') {
            const hasLocation =  await this.verifyHasLocationData();
            if (!hasLocation) {
                this.handleUnableToAutomate();
            }
        }
        modalModel.styleClass = 'processing-upload';
        this.addProcessingMessage(`Creating new ${appModel.toolbox.siteTermSingularCapitalized}`, `${appModel.toolbox.siteTermSingularCapitalized} created`, 'create-project-step');
        try {
            const childProjectAssets = await this.createChildProject();

            modalModel.zIndex = 1002; // Setting the processing dialog above the site loader
            this.childProjectAsset = childProjectAssets[0];
            
            [this.currentMedia] = await this.uploadMediaRecords();

            await this.loadChildProject();

            this.addProcessingMessage(`Adding Data to ${appModel.toolbox.siteTermSingularCapitalized}`, 'Data added', 'add-assets-step');
        
            await this.createFirstAsset();
        
            await this.modifyProjectBoundsToUploadedFile();

            try {
                await this.createSecondaryAssets();
            } catch (e) {
                const mediaRecords = await this.uploadMediaRecords(appModel.project.projectId, this.allFiles);
                await this.sendToHubspot(mediaRecords); // To limit scope, just send all files to hubspot at this point
                this.statusFootnote = '* One or more assets are still being processed. We\'ll email you when those assets are ready.';
                m.redraw();
            }
            modalModel.styleClass = 'processing-done';

            this.goToStep(STEPS.DONE);
        } catch {
            this.handleUnableToAutomate();
        }
    }

    onAddressComplete = () => {
        this.nextStep();
    };

    async _initHubspotProcess() {
        if (this.manualFiles && this.manualFiles.length) {
            const displayFileText = `file${this.allFiles.length > 1 ? 's' : ''}`;
            if (!this.statusText.length) {
                this.addProcessingMessage(`Analyzing your ${displayFileText}`, 'Analysis complete', 'analyze-step');
            }
            const mediaRecords = await this.uploadMediaRecords(appModel.project.projectId, this.manualFiles);
            await this.sendToHubspot(mediaRecords);
            return this.nextStep();
        }
    }
}

const uploadHelpFlow = new UploadHelpFlow();

export default uploadHelpFlow;
