export const styleControls = {
    'LINE_COLOR': '_lineColor', 
    'LINE_OPACITY': '_lineOpacity',
    'LINE_WIDTH_PX': '_lineWidthPx',
    'LINE_WIDTH_METERS': '_lineWidthMeters',
    'LINE_DASH_ARRAY': '_lineDasharray',
    'ICON_IMAGE': '_iconImage',
    'ICON_SIZE_PX': '_iconSizePx',
    'ICON_SIZE_METERS': '_iconSizeMeters',
    'FILL_COLOR': '_fillColor',
    'FILL_OPACITY': '_fillOpacity',
    'ROTATION': '_rotation',
    'TEXT_FIELD': '_textField',
    'TEXT_COLOR': '_textColor',
    'TEXT_HALO_COLOR': '_textHaloColor',
    'TEXT_SIZE_PX': '_textSizePx',
    'TEXT_SIZE_METERS': '_textSizeMeters',
    'TEXT_HALO_WIDTH_PX': '_textHaloWidthPx',
    'TEXT_HALO_WIDTH_METERS': '_textHaloWidthMeters',
    'TEXT_OFFSET': '_textOffset'
};

export const unitsDisplay = {
    metersShort: 'm',
    yardsShort: 'yds',
    pixelsShort: 'px',
    degreesShort: 'deg',
    percentShort: '%'
};

export const rangeSettings = {
    stepFractional: 0.1,
    stepWhole: 1
    
};
