const styleModel = {

    stylesheet: {
        version: 8,
        glyphs: location.origin + '/images/glyphs/{fontstack}/{range}.pbf',
        sources: {},
        layers: []
    },

    reset() {

        Object.assign(styleModel.stylesheet, {
            sources: {
                places: {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: []
                    }
                }
            },
            layers: []
        });

        return styleModel.stylesheet;

    }

};

export default styleModel;
