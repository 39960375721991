import store from 'util/data/store';
import modalModel from 'models/modal-model';
import accountManager from 'managers/account-manager';
import AccountSelector from 'views/account-selector/account-selector';

const accountMenu = {
    oninit: ({state}) => {
        state.showAccounts = false;
        accountManager.updateActiveMenuItem();
    },

    closeModal: (e) => {
        e.stopPropagation();
        accountManager.menu.resetActive();
        modalModel.close();
    },

    toggleAccountList: (e, state) => {
        e.stopPropagation();
        state.showAccounts = !state.showAccounts;
    },

    view: ({state}) => {
        return <div class="account-menu vertical-menu side-nav-popup">
            <div class="padding-wrap">
                {!accountManager.isWhiteLabelAccount && <div class="side-nav-popup-subtitle">Unearth's OnePlace<span class="unearth-trademark">TM</span> for</div>}
                <div class="side-nav-popup-title">{store.account.name}</div>
                <ul class="side-nav-popup-links menu-links">
                    {accountManager.menu && accountManager.menu.items.map((item, index) =>
                        <li key={index} class={`menu-item side-nav-popup-item ${item.itemId}`}>
                            <a class={`${accountManager.menu.activeItemId === item.itemId ? 'active' : 'inactive'}`} onclick={(e) => accountManager.menu.selectItem(e, index)}>{item.text}</a></li> )}
                </ul>
            </div>
            <div class="account-selector-wrap UFtracker-account-selector">
                <div onclick={(e) => state.toggleAccountList(e, state)} class={`account-selector select ${state.showAccounts ? 'open' : 'closed'}`}>
                    <div class="padding-wrap"><div class="select-title account-option">Switch Account <i class="spinner"/></div></div>
                </div>
                {state.showAccounts && <AccountSelector /> }
            </div>
        </div>;
    }

};

export default accountMenu;
