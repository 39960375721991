import AuthField from 'views/auth/auth-field/auth-field';
import Auth from 'views/auth/auth';
import router from 'uav-router';
import authModel from 'models/auth-model';
import ShowHidePassword from 'views/auth/auth-field/show-hide-password';
import FormButton from 'views/form-button/form-button';
import ForgotPassword from 'views/auth/forgot-password/forgot-password.js';
import AuthForm from 'views/auth/auth-form/auth-form';
import message from 'views/toast-message/toast-message';
import OAuthOptions from 'views/auth/oauth-options';
import authManager from '../../../managers/auth-manager';

const Login = {
    oninit: ({ state }) => {
        state.valid = {};
        state.passwordType = 'password';
        if (authModel.loggedOut && !authModel.authFailed) {
            state.notice = {
                type: 'success',
                text: 'You have been signed out.'
            };
        }
        state.oathSpinning = false;
    },

    view: ({ state }) => {
        return <Auth>
            <AuthForm onSubmit={() => {
                if (state.doLogin && !state.valid.password || !state.valid.email) {
                    state.showValidationError = true;
                    return;
                }
                if (!state.doLogin) {
                    return;
                }
                authModel.login(state.email, state.password)
                    .catch(() => {
                        state.notice = {
                            type: 'error',
                            text: 'The information you entered does not match our records. Please try again.'
                        };
                        authModel.isLoggingIn = false;
                        m.redraw();
                    });
            }}>
                <div class="form-msg"><h1>Sign in</h1></div>
                {state.notice && message.show(state.notice.text, state.notice.type)}
                <div class="form sign-in-form new-form">
                    <AuthField
                        label="Email Address"
                        onInput={(e, isValid) => {
                            state.email = e.target.value;
                            state.valid.email = isValid;
                        }}
                        type="email"
                        showValidationError={state.showValidationError} />
                    <div className={`${authManager.authenticationFlow !== 'PASSWORD_FLOW' ? 'hide-password' : ''}`}>
                        <AuthField
                            label="Password"
                            onInput={(e, isValid) => {
                                state.password = e.target.value;
                                state.valid.password = isValid;
                            }}
                            type={state.passwordType}
                            showValidationError={state.showValidationError}>
                            <a class="forgot-link" onclick={() => {
                                ForgotPassword.onCancelRouterOpts = undefined;
                                router.set({ view: 'forgotpassword' });
                            }}>
                            Forgot Password?
                            </a>
                            <ShowHidePassword onToggle={(type) => {
                                state.passwordType = type;
                            }} />
                        </AuthField>
                    </div>
                    {authManager.authenticationFlow === 'PASSWORD_FLOW' ?
                        <FormButton
                            isDisabled={state.showValidationError && (!state.valid.password || !state.valid.email)}
                            label="Sign in"
                            spinning={authModel.isLoggingIn}
                            onClick={() => {
                                if (state.notice) {
                                    delete state.notice;
                                    message.hide();
                                }
                                if (!state.valid.password || !state.valid.email) {
                                    state.showValidationError = true;
                                }
                                state.doLogin = true;
                            }}/>  : <button
                            className={`btn btn-primary btn-pill ${!state.valid.email ? 'disabled' : ''}`}
                            onclick={async () => {
                                state.doLogin = false;
                                await authManager.setAuthenticationFlow(state.email);
                                if (authManager.authenticationFlow === 'OAUTH_FLOW' || authManager.authenticationFlow === 'SAML_FLOW') {
                                    return authManager.redirectSSOUsers();
                                }
                                m.redraw();
                            }}>Continue</button>}
                    <OAuthOptions />
                </div>
            </AuthForm>
        </Auth>;
    }
};

export default Login;
