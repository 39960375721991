import constants from 'util/data/constants';
import swap from 'legacy/util/swap';

const {awsProfile, appSocket, wsSocket} = constants;

const wsSubdomain = swap(awsProfile, {
    development: () => 'ws-dev',
    staging: () => 'ws-staging',
    production: () => 'ws'
});

let wsUrl = `wss://${wsSubdomain}.unearthlabs.com:443`;
if (awsProfile === 'local') {
    wsUrl = wsSocket;
}

const appSubdomain = {
    development: 'app-dev',
    staging: 'app-staging',
    production: 'app'
}[awsProfile];

let appUrl = `https://${appSubdomain}.unearthlabs.com`;

if (awsProfile === 'local') {
    appUrl = appSocket;
}

const tilesSubdomain = swap(awsProfile, {
    development: () => 'tiles-dev',
    staging: () => 'tiles-staging',
    production: () => 'tiles'
});

let tilesUrl = `https://${tilesSubdomain}.unearthlabs.com`;
if (awsProfile === 'local') {
    tilesUrl = appSocket;
}
const dashboardURL = swap(awsProfile, {
    development: () => 'https://devdashboards.unearthlabs.com',
    staging: () => 'https://stagingdashboards.unearthlabs.com',
    production: () => 'https://stagingdashboards.unearthlabs.com'
});

const apiUrl = swap(awsProfile, {
    local: () => 'http://localhost:8084',
    default: () => `https://${appSubdomain}.unearthlabs.com`
});

const authUrl = swap(awsProfile, {
    local: () => 'http://localhost:8010',
    development: () => 'https://auth-dev.unearthlabs.com',
    staging: () => 'https://auth-staging.unearthlabs.com',
    production: () => 'https://auth.unearthlabs.com'
});

let dashURL = dashboardURL;

if (awsProfile === 'local') {
    dashURL = appSocket;
}

const blueConduitUrl = {
    development: 'https://blueconduit-dev.unearthlabs.com',
    staging: 'https://blueconduit-staging.unearthlabs.com',
    production: 'https://blueconduit.unearthlabs.com'
}[awsProfile];

let whiteLabelAccount;

if (constants.isDeployed) {
    whiteLabelAccount = window.location.href.startsWith(blueConduitUrl) ? 'blueconduit' : null;
}

const setWhiteLabelEnvTo = (newValue) => {
    whiteLabelAccount = newValue;
};

export {
    wsUrl,
    appUrl,
    tilesUrl,
    dashURL,
    authUrl,
    apiUrl,
    whiteLabelAccount,
    setWhiteLabelEnvTo
};
