import popup from 'util/popup';

/**
 *  headerEl: Any element to be displayed as the header.
 *  options: [{
 *      label: The display name.
 *      icon: An optional icon.
 *      callback: callback for option.
 *  }]
 */

class PointMenu {

    static open(options, lngLat) {
        popup.remove();
        popup.add({
            className: 'feature-menu-popup',
            content: <PointMenu options={options}/>,
            lngLat,
            maxWidth: 'none'
        });
    }

    view({attrs: {options, headerEl}}) {
        return <div className="map-point-menu">
            {headerEl}
            <div className="link-list">
                <ul>
                    {options.map(({label, icon, callback}) =>
                        <li className="menu-option" onclick={callback}>
                            {icon && <i className={icon}/>}
                            {label}
                        </li>
                    )}
                </ul>
            </div>
        </div>;
    }
}

export default PointMenu;
