/**
 * Determine whether the input is a usable number 
 * usable number - not string, not NaN, not Inf, not other objects
 * @param {any} d - element to be determined if it is a usable number
 */
function isNumber(d) {
    return typeof d === 'number' && isFinite(d);
}

export default isNumber;
