import popup from 'models/popup-model';
import modalModel from 'models/modal-model';
import Panel from 'views/panel/panel';
import mapPopup from 'util/popup';
import appModel from 'models/app-model';
import siteModel from 'models/site-model';
import layerModel from 'models/layer-model';

// Generic component that can be used to handle panel wrapper around specific view
const panelModel = {
    view: undefined,
    onclose: undefined,
    cssClass: '',
    isOpen: false,
    popup,
    onscroll: () => {
        if (popup.isOpen) {
            popup.close();
        }
    },
    /**
     * view: view component to open within panel
     * handleDone: callback to execute if 'done' button is selected. if none provided, defaults to close()
     * onclose: additional logic to execute if panel is closed
     * styleClass: optional additional css class for special styling, e.g. 'form-style' applies styling from asset-form.scss
     */
    open: (opts) => {
        appModel.toolbox.closeActiveTool();
        if (opts.view && opts.view.view) {
            panelModel.view = opts.view;
        }
        panelModel.onclose = opts.onclose;
        panelModel.handleDone = opts.handleDone ? opts.handleDone : panelModel.close;
        panelModel.doneText = opts.doneText ? opts.doneText : 'Done';
        panelModel.disableDoneBtn = opts.disableDoneBtn;
        panelModel.secondaryButtonText = opts.secondaryButtonText;
        panelModel.secondaryButtonClick = opts.secondaryButtonClick;
        panelModel.visibilityClass = 'visible';
        panelModel.styleClass = `${opts.styleClass || 'default-style'}${opts.overSidebar ? ' over-sidebar-style' : ''}`;
        panelModel.footerContent = opts.footerContent || undefined;
        // Close popup if open on map
        if (mapPopup.isOpen) {
            mapPopup.remove();
        }
        if (layerModel.isOpen) {
            layerModel.togglePicker();
        }
        // Dismiss sidebar if necessary
        if (opts.overSidebar) {
            siteModel.dismissSidebar();
        }
        
        panelModel.isOpen = true;
        m.redraw();
    },
    // Variant of open() which opens panel within a modal; modal will close if panel closed. Same opts as open()
    openInModal: (opts, modalOpts = {}) => {
        panelModel.open({...opts, onclose: () => modalModel.close()});
        modalModel.open({view: Panel, ...modalOpts});
    },
    close: () => {
        popup.close();
        siteModel.recallSidebar();
        if (panelModel.onclose) {
            panelModel.onclose();
        }
        panelModel.view
            = panelModel.handleDone
            = panelModel.onclose
            = panelModel.disableDoneBtn
            = undefined;
        panelModel.visibilityClass = '';
        panelModel.isOpen = false;
        m.redraw();

    }
};

export default panelModel;
