import tableModel from 'models/table/table-model';
import onBodyClick from 'legacy/util/dom/on-body-click';

const LayoutControl = {
    oninit: ({state}) => {
        state.isOpen = false;
    },

    view: ({state}) => {
        return state.isOpen || tableModel.tableMode !== 'list-left' ? <div class={`layout-control${tableModel.tableMode === 'list-left' ? ' as-popout' : ''}`}>
            <i class="icon-layout-table-bottom UFtracker-table-bottom" onclick={() => tableModel.setMode('table-bottom', 2000)}/>
            <i class="icon-layout-table-full UFtracker-table-full" onclick={() => tableModel.setMode('table-full', 2000)}/>
            <i class="icon-layout-list-left UFtracker-list-left" onclick={() => tableModel.setMode('list-left', 2000)}/>
        </div> : <div class="layout-control layout-control-toggle" onclick={() => {
            state.isOpen = true;
            onBodyClick.once(() => {
                state.isOpen = false;
                m.redraw();
            });
        }}><i class="icon-layout-list-left"/></div>;
    }
};

export default LayoutControl;
