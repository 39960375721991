const ShortcutMenu = {
    view: ({attrs: {onClick, filterValue}}) => {
        return <div class="filter-menu-inner shortcut-filter">
            <div class="filter-contents">
                <div class="shortcut-filter-tip"><i class="icon-configure"/>Shortcut</div>
                <div class="filter-option" onclick={onClick}>{filterValue} only
                </div>
            </div>
        </div>;
    }
};

export default ShortcutMenu;
