import appModel from 'models/app-model';
import definedOptionsModel from 'models/table/filter-types/defined-options-model';
import numberRangeModel from 'models/table/filter-types/number-range-model';
import booleanFilterModel from 'models/table/filter-types/boolean-filter-model';
import textFilterModel from 'models/table/filter-types/text-filter-model';
import linkedAssetFilterModel from 'models/table/filter-types/linked-asset-filter-model';
import dateRangeModel from 'models/table/filter-types/date-range-model';
import placesFilterModel from 'models/table/filter-types/places-filter-model';
import Radio from 'views/radio/radio';
import constants from 'util/data/constants';
import DateRange from 'views/date-range/date-range';
import ConfigMenu from 'views/table/filter-menu/config-menu/config-menu';
import tableModel from 'models/table/table-model';
import stopPropagation from 'util/events/stop-propagation';
import popupModel from 'models/popover-model';
import peopleModel from 'models/people/people-model';
import SharedColumnMessage from 'views/table/filter-menu/shared-column-message';
import PlaceSearch from 'views/place-search/place-search';
import filterConstants from 'constants/models/table/filter-constants';
import BoundaryMenu from 'views/boundary-menu/boundary-menu';

const MIN_SELECT_ALL = 2;

const SPECIAL_RADIO_OPT = <div class="special-radio-row"><span class="radio-opt active"><span class="radio-circle"></span><span class="radio-opt-label">type-specific filter settings</span></span></div>;

export const checkOptionToCssClass = {
    true: 'icon-checkbox-on',
    mixed: 'icon-checkbox-mixed',
    false: 'icon-checkbox-off',
    undefined: 'icon-checkbox-on' // no value is "on" by default
};

const CommonHeader = {
    view: ({attrs: {name}}) => {
        const sortKey = name;
        return <Header styles={tableModel.styles.getCommonColumnHeaderStyles(name)}
            hideSort={appModel.projectView.common.isSortDisabled(name)} name={appModel.projectView.common.displayName(name)}
            onSortToggle={() => appModel.projectView.filters.toggleCommonSortField(sortKey, filterConstants.nameToViewKey[name])} />;
    }
};

const Header = {
    view: ({attrs: {styles, onSortToggle, hideSort, name}}) => {
        styles = styles || tableModel.styles.currentHeader;
        onSortToggle = onSortToggle || (() => tableModel.projectView.filters.toggleSortField());
        return <div className={`filter-header ${styles}`} onclick={() => popupModel.close()}>
            <span className="filter-name">{name || tableModel.projectView.filters.currentFilterDisplayName}</span>
            {!hideSort && <span className="sort-control" onclick={(e) => {
                e.stopPropagation();
                onSortToggle();
            }}> <i className="icon-arrow-up" /><i className="icon-arrow-down" />
            </span>}
        </div>;
    }
};

export const SelectAll = {
    view: ({attrs: {selectAllFn, switchAllChecksFn}}) => {
        const {isAllSelected, isAllDeselected} = selectAllFn();
        const selectAllCssClass = `select-all${isAllSelected ? ' disabled' : ''}`;
        const deselectAllCssClass = `select-all${isAllDeselected ? ' disabled' : ''}`;
        return <div className="bulk-selection-links">
            <span>
                <div className={selectAllCssClass} onclick={() => switchAllChecksFn(true)}>
                    Select All
                </div>
                &nbsp;|&nbsp;
                <div className={deselectAllCssClass} onclick={() => switchAllChecksFn(false)}>Deselect All</div>
            </span>
        </div>;
    }

};

const filterMenus = {

    /**
     *  Control type Filters
     */

    definedOptions: {
        oninit: () => {
            definedOptionsModel.init();
        },
        view: () => {
            const options = definedOptionsModel.options;
            const selectedControlName = tableModel.projectView.filters.opts.selectedControlName;
            return <div class="filter-menu-inner">
                <Header />
                <div class="filter-contents">
                    <SharedColumnMessage />
                    {!selectedControlName && MIN_SELECT_ALL <= options.length && <SelectAll selectAllFn={() => definedOptionsModel.isAllSelected()} switchAllChecksFn={(isOn) => definedOptionsModel.switchAllChecks(isOn)} />}
                    {selectedControlName ?
                        <div class='filter-option-row'>
                            <div class="filter-option">
                                <div onclick={() => definedOptionsModel.toggleOption(selectedControlName)}>
                                    <i className={checkOptionToCssClass[definedOptionsModel.state.checkedOptions[selectedControlName]]} />{selectedControlName}
                                </div>
                                <div className="only" onclick={(e) => {
                                    e.stopPropagation();
                                    definedOptionsModel.turnOffAllExcept(selectedControlName);
                                }}>only
                                </div>
                            </div>
                        </div>
                        : options.map((option, i) => {
                            return <div class={`filter-option-row ${definedOptionsModel.state.checkedOptions[option] === 'mixed' ? 'mixed' : ''}`}>
                                <div class="filter-option" >
                                    <div onclick={() => definedOptionsModel.toggleOption(option)}>
                                        <i className={checkOptionToCssClass[definedOptionsModel.state.checkedOptions[option]]}/><span
                                            className="filter-option-label">{definedOptionsModel.isUserObjType ? peopleModel.displayNameOrEmail(option) : option}</span>
                                    </div>
                                    <div className="only" onclick={(e) => {
                                        e.stopPropagation();
                                        definedOptionsModel.turnOffAllExcept(option);
                                    }}>only
                                    </div>
                                </div>
                            </div>;
                        })}
                    {!selectedControlName &&
                        //!selectedControlName && controlType.attributes.required && (Not all required controls have fields in them so we need isEmpty for all).
                        <div class={`filter-option-row ${definedOptionsModel.state.isEmptyChecked === 'mixed' ? 'mixed' : ''}`}>
                            <div class="filter-option" >
                                <div onclick={() => definedOptionsModel.toggleEmptyState()}>
                                    <i className={checkOptionToCssClass[definedOptionsModel.state.isEmptyChecked]}/><span
                                        className="filter-option-label">is empty</span>
                                </div>
                                <div className="only" onclick={(e) => {
                                    e.stopPropagation();
                                    definedOptionsModel.switchAllChecks(false);
                                    definedOptionsModel.toggleEmptyState();
                                }}>only
                                </div>
                            </div>
                        </div>}
                    {definedOptionsModel.hasAnyMixedOptions() &&
                        <div class="differing-filters-exist-message">* Type-specific filters differ. Current results include only Types that meet this condition.</div>}
                </div>
            </div>;
        }
    },

    dateFilter: {
        oninit: () => {
            dateRangeModel.init();
        },
        customRange: () => {
            return {
                view: () => {
                    return <div>custom range
                        <DateRange
                            hideDone={true}
                            onDateChange={() => dateRangeModel.rangeUpdated()}
                            model={dateRangeModel.state.rangeModel} />
                    </div>;
                }
            };
        },
        options: () => ['all', 'today', 'last 7 days', 'last 14 days', 'last 30 days', filterMenus.dateFilter.customRange],
        view: () => {
            const combinedFiltersAreMixed = dateRangeModel.state.selectedRadioIndex === -1;
            const options = filterMenus.dateFilter.options();
            return <div class={`filter-menu-inner${combinedFiltersAreMixed ? ' mixed-state' : ''}`}>
                <Header />
                <div class="filter-contents">
                    <SharedColumnMessage />
                    {combinedFiltersAreMixed && SPECIAL_RADIO_OPT}
                    <div><Radio key={tableModel.projectView.filters.filterColumnKey} defaultOption={options[dateRangeModel.state.selectedRadioIndex]} options={options} onchange={(value, i) => dateRangeModel.selectDateRangeRadio(i)} /></div>
                </div>
            </div>;
        }
    },

    linkedAssetFilter: {
        oninit: () => {
            linkedAssetFilterModel.init();
        },
        options: () => ['all', 'is empty', 'is not empty'],
        view: () => {
            const combinedFiltersAreMixed = linkedAssetFilterModel.state.selectedRadioIndex === -1;
            return <div class={`filter-menu-inner${combinedFiltersAreMixed ? ' mixed-state' : ''}`}>
                <Header hideSort={true} />
                <div class="filter-contents">
                    <SharedColumnMessage />
                    {combinedFiltersAreMixed && SPECIAL_RADIO_OPT}
                    <div><Radio key={tableModel.projectView.filters.filterColumnKey} defaultOption={filterMenus.textFilter.options()[linkedAssetFilterModel.state.selectedRadioIndex]} options={filterMenus.linkedAssetFilter.options()} onchange={(value, i) => linkedAssetFilterModel.selectRadio(i)} /></div>
                </div>
            </div>;
        }

    },

    textFilter: {
        oninit: () => {
            textFilterModel.init();
        },
        customString: () => {
            return {
                view: () => {
                    return <div>
                        <label className="sublabel">contains</label>
                        <input type="text" value={textFilterModel.state.queryParam} oninput={e => textFilterModel.setQueryParam(e)} />
                    </div>;
                }
            };
        },
        options: () => {
            const currentControl = tableModel.projectView.filters.opts.controlType;
            return currentControl && currentControl.controlTypeId === constants.controlTypeNameToId.file ? ['all', 'is empty', 'is not empty'] : ['all', 'is empty', 'is not empty', filterMenus.textFilter.customString];
        },
        view: () => {
            const combinedFiltersAreMixed = textFilterModel.state.selectedRadioIndex === -1;
            const currentControl = tableModel.projectView.filters.opts.controlType;
            const hideSort = currentControl && currentControl.controlTypeId === constants.controlTypeNameToId.URL || currentControl.controlTypeId === constants.controlTypeNameToId.file;
            return <div class={`filter-menu-inner${combinedFiltersAreMixed ? ' mixed-state' : ''}`}>
                <Header hideSort={hideSort}/>
                <div class="filter-contents">
                    <SharedColumnMessage />
                    {combinedFiltersAreMixed && SPECIAL_RADIO_OPT}
                    <div><Radio key={tableModel.projectView.filters.filterColumnKey} defaultOption={filterMenus.textFilter.options()[textFilterModel.state.selectedRadioIndex]} options={filterMenus.textFilter.options()} onchange={(value, i) => textFilterModel.selectRadio(i)} /></div>
                </div>
            </div>;
        }
    },

    numberFilter: {
        oninit: () => {
            numberRangeModel.init();
        },
        allValues: () => {
            return {
                view: () => {
                    const sign = numberRangeModel.state.selectedSign;
                    const enabledCssClass = numberRangeModel.state.selectedRadioIndex === 2 ? 'number-input' : 'number-input disabled';
                    return <div class="number-filter-equation">all values
                        <select class="operator-select" onchange={stopPropagation(e => {
                            numberRangeModel.selectSign(e.target.value);
                        })} onclick={stopPropagation}>
                            <option selected={sign === 'gte'} value={'gte'} key={'>='}>{'≥'}</option>
                            <option selected={sign === 'lte'} value={'lte'} key={'<='}>{'≤'}</option>
                            <option selected={sign === 'eq'} value={'eq'} key={'=='}>{'='}</option>
                        </select>
                        <input type="number" className={enabledCssClass} value={numberRangeModel.state.range} step="any" oninput={e =>
                            numberRangeModel.setRange(e.target.value)
                        } />
                    </div>;
                }
            };
        },
        customRange: () => {
            return {
                view: () => {
                    const enabledCssClass = numberRangeModel.state.selectedRadioIndex === 3 ? 'number-input' : 'number-input disabled';
                    return <div class="number-filter-custom"><span class="range-label">custom range</span>
                        <div class="range-inputs"><input type="number" className={enabledCssClass} value={numberRangeModel.state.rangeLow} step="any" oninput={e =>
                            numberRangeModel.setRangeLow(e.target.value)
                        } />
                        <span class="range-to">to</span>
                        <input type="number" className={enabledCssClass} value={numberRangeModel.state.rangeHigh} step="any" oninput={e => numberRangeModel.setRangeHigh(e.target.value)} />
                        </div>
                    </div>;
                }
            };
        },
        options: () => ['all', 'is empty', filterMenus.numberFilter.allValues, filterMenus.numberFilter.customRange],
        view: () => {
            const combinedFiltersAreMixed = numberRangeModel.state.selectedRadioIndex === -1;
            const options = filterMenus.numberFilter.options();
            return <div class={`filter-menu-inner${combinedFiltersAreMixed ? ' mixed-state' : ''}`}>
                <Header />
                <div class="filter-contents">
                    <SharedColumnMessage />
                    {combinedFiltersAreMixed && SPECIAL_RADIO_OPT}
                    <div><Radio key={tableModel.projectView.filters.filterColumnKey} defaultOption={options[numberRangeModel.state.selectedRadioIndex]} options={options} onchange={(value, i) => numberRangeModel.selectRadio(i)} /></div>
                </div>
            </div>;
        }
    },

    booleanFilter: {
        oninit() {
            booleanFilterModel.init();
        },
        options: () => ['any', 'on', 'off'],
        view: () => {
            const combinedFiltersAreMixed = booleanFilterModel.state.selectedRadioIndex === -1;
            return <div class={`filter-menu-inner${combinedFiltersAreMixed ? ' mixed-state' : ''}`}>
                <Header />
                <div class="filter-contents">
                    <SharedColumnMessage />
                    {combinedFiltersAreMixed && SPECIAL_RADIO_OPT}
                    <div><Radio key={tableModel.projectView.filters.filterColumnKey} defaultOption={filterMenus.booleanFilter.options()[booleanFilterModel.state.selectedRadioIndex]} options={filterMenus.booleanFilter.options()} onchange={(value, i) => booleanFilterModel.selectRadio(i)} /></div>
                </div>
            </div>;
        }
    },

    placesFilter: {
        oninit: () => {
            placesFilterModel.init();
        },
        options: () => ['any', 'none', filterMenus.placesFilter.placeSearch],
        placeSearch: {
            view: () =>
                <div className="place-search-filter">
                    <label>includes</label>
                    <PlaceSearch
                        selectedItems={placesFilterModel.state.selectedItems}
                        onSelect={(place) => placesFilterModel.onSelectPlace(place)}
                        onDelete={(place) => placesFilterModel.onDeletePlace(place)}
                        openUp={tableModel.tableMode === 'table-bottom'}
                    />
                </div>
        },
        view: () => {
            const combinedFiltersAreMixed = false;
            return <div class="filter-menu place-search">
                <div class={`filter-menu-inner${combinedFiltersAreMixed ? ' mixed-state' : ''}`}>
                    <Header hideSort={true} />
                    <div class="filter-contents visible">
                        <SharedColumnMessage />
                        {combinedFiltersAreMixed && SPECIAL_RADIO_OPT}
                        <div>
                            <Radio
                                key={appModel.projectView.filters.filterColumnKey}
                                defaultOption={filterMenus.placesFilter.options()[placesFilterModel.state.selectedRadioIndex] || filterMenus.placesFilter.options()[0]}
                                options={filterMenus.placesFilter.options()}
                                onchange={(value, i) => placesFilterModel.selectRadio(i)} />
                        </div>
                    </div>
                </div>
            </div>;
        }
    },

    /**
     *  Common filters
     */

    dateCreatedFilter: {
        customRange: () => {
            return {
                view: () => {
                    return <div>custom range
                        <DateRange
                            hideDone={true}
                            onDateChange={() => appModel.projectView.common.rangeUpdated()}
                            model={appModel.projectView.common.addedDateRange} />
                    </div>;
                }
            };
        },
        options: () => ['all', 'today', 'last 7 days', 'last 14 days', 'last 30 days', filterMenus.dateCreatedFilter.customRange],
        view: ({attrs: {hideHeader}}) => {
            return <div class="filter-menu-inner added-date-filter">
                {!hideHeader && <CommonHeader name='createdDateTime' />}
                <div class="filter-contents">
                    <Radio defaultOption={filterMenus.dateCreatedFilter.options()[appModel.projectView.common.selectedDateRangeIndex]} options={filterMenus.dateCreatedFilter.options()} onchange={(value, i) => appModel.projectView.common.selectDateRangeRadio(i)} />
                </div>
            </div>;
        }
    },

    lastUpdatedFilter: {
        customRange: () => {
            return {
                view: () => {
                    return <div>custom range
                        <DateRange
                            hideDone={true}
                            onDateChange={() => appModel.projectView.common.rangeUpdated()}
                            model={appModel.projectView.common.updatedDateRange} />
                    </div>;
                }
            };
        },
        options: () => ['all', 'today', 'last 7 days', 'last 14 days', 'last 30 days', filterMenus.lastUpdatedFilter.customRange],
        view: ({attrs: {hideHeader}}) => {
            return <div class="filter-menu-inner added-date-filter">
                {!hideHeader && <CommonHeader name='updatedDateTime' />}
                <div class="filter-contents">
                    <Radio defaultOption={filterMenus.lastUpdatedFilter.options()[appModel.projectView.common.updatedDateRangeIndex]} options={filterMenus.lastUpdatedFilter.options()} onchange={(value, i) => appModel.projectView.common.selectUpdatedRange(i)} />
                </div>
            </div>;
        }
    },

    addedByFilter: {
        view({attrs: {hideHeader}}) {
            const projectView = appModel.projectView;
            const allUsers = Object.keys(projectView.common.checkedUsers);
            // const selectedControlName = tableModel.projectView.filters && tableModel.projectView.filters.opts ? tableModel.projectView.filters.opts.selectedControlName : '';
            // const selectedUser = selectedControlName ? Object.values(tableModel.addedByCounts).find((user) => user.name === selectedControlName) : Object.values(tableModel.addedByCounts);
            return <div class="filter-menu-inner">
                {!hideHeader && <CommonHeader name='addedBy' />}
                <div class="filter-contents with-count">
                    {allUsers.length >= MIN_SELECT_ALL && <SelectAll selectAllFn={() => projectView.common.areAllUserIdsChecked()} switchAllChecksFn={(isOn) => projectView.common.switchAllAddedBy(isOn)} />}
                    {allUsers.map((userId, i) => {
                        const name = peopleModel.displayNameOrEmail(userId);
                        const addedByCount = tableModel.addedByCounts[userId] ? tableModel.addedByCounts[userId].count : 0;
                        return <div class="filter-option-row">
                            <div class={`filter-option ${addedByCount ? 'has-assets' : 'no-assets'}`}>
                                <div  onclick={() => projectView.common.toggleCheckedUsers(userId)}><i
                                    className={checkOptionToCssClass[projectView.common.checkedUsers[userId]]}/><span
                                    className="filter-option-label">{name}</span></div>
                                <div className="only" onclick={(e) => {
                                    e.stopPropagation();
                                    projectView.common.turnOffAllAddedByExcept(userId);
                                }}>only</div>
                                <span class="filter-option-count">{addedByCount || '0'}</span>
                            </div>
                        </div>;
                    })}
                </div>
            </div>;
        }
    },

    lastUpdatedByFilter: {
        view({attrs: {hideHeader}}) {
            const projectView = appModel.projectView;
            const allUsers = Object.keys(projectView.common.checkedUsersUpdatedBy);
            return <div class="filter-menu-inner">
                {!hideHeader && <CommonHeader name='lastUpdatedBy' />}
                <div class="filter-contents">
                    {allUsers.length >= MIN_SELECT_ALL && <SelectAll selectAllFn={() => projectView.common.areAllUserIdsChecked()} switchAllChecksFn={(isOn) => projectView.common.switchAllUpdatedBy(isOn)} />}
                    {allUsers.map((userId, i) => {
                        const name = peopleModel.displayNameOrEmail(userId);
                        const updatedByCount = tableModel.updatedByCounts[userId] ? tableModel.updatedByCounts[userId].count : 0;
                        return <div class="filter-option-row">
                            <div class={`filter-option ${updatedByCount ? 'has-assets' : 'no-assets'}`}>
                                <div onclick={() => projectView.common.toggleCheckedUsersUpdatedBy(userId)}>
                                    <i className={checkOptionToCssClass[projectView.common.checkedUsersUpdatedBy[userId]]} />
                                    <span class="filter-option-label">{name}</span>
                                </div>
                                <div className="only" onclick={(e) => {
                                    e.stopPropagation();
                                    appModel.projectView.common.turnOffAllUpdatedByExcept(userId);
                                }}>only
                                </div>
                                <span class="filter-option-count">{updatedByCount || '0'}</span>
                            </div>
                        </div>;
                    })}
                </div>
            </div>;
        }
    },

    categoryFilter: {
        view({attrs: {hideHeader}}) {
            return <div class="filter-menu-inner">
                {!hideHeader && <CommonHeader name='category' />}
                <div class="filter-contents with-count">
                    {appModel.projectView.common.toolGroups.length >= MIN_SELECT_ALL && <SelectAll selectAllFn={() => appModel.projectView.common.areAllAssetCategoriesChecked()} switchAllChecksFn={(isOn) => appModel.projectView.common.switchAllAssetTypes(isOn)} />}
                    {appModel.projectView.common.toolGroups.map((toolGroup) => {
                        const toolGroupId = toolGroup.toolGroupId;
                        const categoryCount = tableModel.categoryCounts[toolGroup.toolGroupId];
                        const assetTypes = appModel.projectView.common.getToolGroupAssetTypes(toolGroupId);
                        if (!assetTypes || assetTypes.length === 0) {
                            return undefined;
                        }
                        return <div class="filter-option-row">
                            <div class={`filter-option ${categoryCount ? 'has-assets' : 'no-assets'}`} onclick={() => appModel.projectView.common.toggleCheckedToolGroups(toolGroupId)}>
                                <i className={checkOptionToCssClass[appModel.projectView.common.checkedToolGroups[toolGroupId]]} />
                                <span class="filter-option-label">{toolGroup.name}</span>
                                <div className="only" onclick={(e) => {
                                    e.stopPropagation();
                                    appModel.projectView.common.turnOffAllToolGroupsExcept(toolGroupId);
                                }}>only
                                </div>
                                <span class="filter-option-count">{categoryCount || '0'}</span>
                            </div>
                        </div>;
                    })}
                </div>
            </div>;
        }
    },

    assetTypeFilter: {
        menuGroup: (toolGroup, assetTypes) => {
            return <div class="filter-section">
                <div class="filter-section-title">{assetTypes.length > 0 && toolGroup.name}</div>
                {assetTypes.map((assetType) => {
                    const assetTypeId = assetType.assetTypeId;
                    const assetCount = tableModel.assetTypeCounts[assetTypeId];
                    return <div class="filter-option-row">
                        <div onclick={() => appModel.projectView.common.toggleAssetType(assetTypeId)} class={`filter-option ${assetCount ? 'has-assets' : 'no-assets'}`}><i className={checkOptionToCssClass[appModel.projectView.common.checkedAssetTypes[assetTypeId]]} />
                            <span class="filter-option-label">{assetType.name}</span>
                            <div className="only" onclick={(e) => {
                                e.stopPropagation();
                                appModel.projectView.common.turnOffAllAssetTypesExcept(assetTypeId);
                            }}>only
                            </div>
                            <span class="filter-option-count">{assetCount || '0'}</span>
                        </div>
                    </div>;
                })}
            </div>;
        },
        view({attrs: {hideHeader}}) {
            const toolGroups = appModel.projectView.common.toolGroups;
            return <div class="filter-menu-inner">
                {!hideHeader && <CommonHeader name='contentType' />}
                <div class="filter-contents  with-count">
                    <SelectAll selectAllFn={() => appModel.projectView.common.areAllAssetTypesChecked()} switchAllChecksFn={(isOn) => appModel.projectView.common.switchAllAssetTypes(isOn)} />
                    {toolGroups.map((toolGroup) => {
                        const assetTypes = appModel.projectView.common.getToolGroupAssetTypes(toolGroup.toolGroupId);
                        return assetTypes.length > 0 ? filterMenus.assetTypeFilter.menuGroup(toolGroup, assetTypes) : <div />;
                    })
                    }
                </div>
            </div>;
        }
    },

    nameFilter: {
        view: ({attrs: {hideHeader}}) => {
            return <div class="filter-menu-inner primary-label-filter-inner">
                {!hideHeader && <CommonHeader name='name' displayName='Name' />}
                <div class="filter-contents">
                    <label className="sublabel">contains</label>
                    <input type="text" defaultValue={appModel.projectView.common.nameQueryString} oninput={e => appModel.projectView.common.setNameQueryString(e.target.value)} />
                </div>
            </div>;
        }
    },

    unearthIdFilter: {
        view: () => {
            return <div class="filter-menu">
                <div class="filter-menu-inner">
                    <CommonHeader name='unearthId' />
                    <div class="filter-contents">
                        <label class="sublabel">ID</label>
                        {appModel.projectView.common.assetIdMatch &&
                            appModel.projectView.common.assetIdMatch.length !== 20 &&
                            <label class="sublabel error">The Unearth ID must be 20 characters long</label>
                        }
                        <input type="text"
                            value={appModel.projectView.common.assetIdMatch || ''}
                            oninput={e => appModel.projectView.common.assetIdInput(e.target.value)} />
                    </div>
                </div>
            </div>;
        }
    },

    linksFilter: {
        view: () => {
            const options = ['all', '0 links', '1 or more links'];
            return <div class="filter-menu-wrap">
                <div class="filter-menu-inner">
                    <CommonHeader name='links' />
                    <div class="filter-contents">
                        <Radio defaultOption={options[appModel.projectView.common.selectedLinksIndex]}
                            options={options} onchange={(value, i) => appModel.projectView.common.selectLinksRadio(i)} />
                    </div>
                </div>
            </div>;
        }
    },

    multiSelectFilter: {
        oninit: () => {
            definedOptionsModel.init('multiselect');
        },
        view: () => {
            const options = definedOptionsModel.options;
            const selectedControlName = tableModel.projectView.filters.opts.selectedControlName;
            return <div class="filter-menu-inner">
                <Header />
                <div class="filter-contents">
                    <SharedColumnMessage />
                    {!selectedControlName && MIN_SELECT_ALL <= options.length && <SelectAll selectAllFn={() => definedOptionsModel.isAllSelected()} switchAllChecksFn={(isOn) => definedOptionsModel.switchAllChecks(isOn)} />}
                    {selectedControlName ?
                        <div class='filter-option-row'>
                            <div class="filter-option" >
                                <div onclick={() => definedOptionsModel.toggleOption(selectedControlName)}>
                                    <i className={checkOptionToCssClass[definedOptionsModel.state.checkedOptions[selectedControlName]]} />{selectedControlName}
                                </div>
                                <div className="only" onclick={(e) => {
                                    e.stopPropagation();
                                    definedOptionsModel.turnOffAllExcept(selectedControlName);
                                }}>only
                                </div>
                            </div>
                        </div>
                        : options.map((option, i) => {
                            return <div class={`filter-option-row ${definedOptionsModel.state.checkedOptions[option] === 'mixed' ? 'mixed' : ''}`}>
                                <div class="filter-option">
                                    <div onclick={() => definedOptionsModel.toggleOption(option)}>
                                        <i className={checkOptionToCssClass[definedOptionsModel.state.checkedOptions[option]]}/><span
                                            className="filter-option-label">{definedOptionsModel.isUserObjType ? peopleModel.displayNameOrEmail(option) : option}</span>
                                    </div>
                                    <div className="only" onclick={(e) => {
                                        e.stopPropagation();
                                        definedOptionsModel.turnOffAllExcept(option);
                                    }}>only
                                    </div>
                                </div>
                            </div>;
                        })}
                    {!selectedControlName &&
                            //!selectedControlName && controlType.attributes.required && (Not all required controls have fields in them so we need isEmpty for all).
                            <div class={`filter-option-row ${definedOptionsModel.state.isEmptyChecked === 'mixed' ? 'mixed' : ''}`}>
                                <div class="filter-option" >
                                    <div onclick={() => definedOptionsModel.toggleEmptyState()}><i
                                        className={checkOptionToCssClass[definedOptionsModel.state.isEmptyChecked]}/><span
                                        className="filter-option-label">is empty</span>
                                    </div>
                                    <div className="only" onclick={(e) => {
                                        e.stopPropagation();
                                        definedOptionsModel.switchAllChecks(false);
                                        definedOptionsModel.toggleEmptyState();
                                    }}>only
                                    </div>
                                </div>
                            </div>}
                    {definedOptionsModel.hasAnyMixedOptions() &&
                            <div class="differing-filters-exist-message">* Type-specific filters differ. Current results include only Types that meet this condition.</div>}
                </div>
            </div>;
        }
    },

    commonFilters: {
        oninit: ({state}) => {
            state.openFilterMenus = {
                assetTypeFilter: true
            };
        },
        view: ({state}) => <div className="collapsible-filter-menu filter-menu">
            <div class="filter-menu-inner">
                <div className="config-menu-header" onclick={() => popupModel.close()}>
                    <div class="config-menu-tab">
                        <span class="config-menu-tab-btn"><i className='icon-configure' /></span>
                        <span className="config-menu-tab-label">Filters</span>
                    </div>
                </div>
                <div class="filter-menu-contents">
                    <CollapsibleFilterSection filterKey={'categoryFilter'} sectionTitle="Category" openFilterMenus={state.openFilterMenus} />
                    <CollapsibleFilterSection filterKey={'assetTypeFilter'} sectionTitle="Type" openFilterMenus={state.openFilterMenus} />
                    <CollapsibleFilterSection filterKey={'dateCreatedFilter'} sectionTitle="Added Date/Time" openFilterMenus={state.openFilterMenus} />
                    <CollapsibleFilterSection filterKey={'lastUpdatedFilter'} sectionTitle="Last Updated" openFilterMenus={state.openFilterMenus} />
                    <CollapsibleFilterSection filterKey={'addedByFilter'} sectionTitle="Added By" openFilterMenus={state.openFilterMenus} />
                    <CollapsibleFilterSection filterKey={'lastUpdatedByFilter'} sectionTitle="Last Updated By" openFilterMenus={state.openFilterMenus} />
                </div>
            </div>
        </div>
    }

};

const CollapsibleFilterSection = {
    oninit: ({attrs: {openFilterMenus, filterKey}}) => {
        openFilterMenus[filterKey] = openFilterMenus[filterKey] || false;
    },
    view: ({attrs: {openFilterMenus, filterKey, sectionTitle}}) => <div class="filter-menu-section">

        <div className="filter-header" onclick={() => {
            openFilterMenus[filterKey] = !openFilterMenus[filterKey];
        }}>
            <span className="filter-name">{sectionTitle}</span>
            <div className="arrow-wrap">
                <span className={openFilterMenus[filterKey] ? 'icon-up' : 'icon-down'} />
            </div>
        </div>
        {openFilterMenus[filterKey] ? m(filterMenus[filterKey], {hideHeader: true}) : ''}
    </div>
};


const controlTypeNameToId = constants.controlTypeNameToId;

export default {
    [controlTypeNameToId.name]: filterMenus.textFilter,
    [controlTypeNameToId.text]: filterMenus.textFilter,
    [controlTypeNameToId.paragraph]: filterMenus.textFilter,
    [controlTypeNameToId.dropdown]: filterMenus.definedOptions,
    [controlTypeNameToId.radio]: filterMenus.definedOptions,
    [controlTypeNameToId.length]: filterMenus.numberFilter,
    [controlTypeNameToId.area]: filterMenus.numberFilter,
    [controlTypeNameToId.volume]: filterMenus.numberFilter,
    [controlTypeNameToId.number]: filterMenus.numberFilter,
    [controlTypeNameToId.date]: filterMenus.dateFilter,
    [controlTypeNameToId.dateTime]: filterMenus.dateFilter,
    [controlTypeNameToId.multiselect]: filterMenus.multiSelectFilter,
    [controlTypeNameToId.multitext]: filterMenus.textFilter,
    [controlTypeNameToId.user]: filterMenus.definedOptions,
    [controlTypeNameToId.toggle]: filterMenus.booleanFilter,
    [controlTypeNameToId.place]: filterMenus.placesFilter,
    [controlTypeNameToId.asset]: filterMenus.linkedAssetFilter,
    [controlTypeNameToId.URL]: filterMenus.textFilter,
    [controlTypeNameToId.file]: filterMenus.textFilter,
    definedOptions: filterMenus.definedOptions,
    assetTypeFilter: filterMenus.assetTypeFilter,
    addedByFilter: filterMenus.addedByFilter,
    lastUpdatedByFilter: filterMenus.lastUpdatedByFilter,
    categoryFilter: filterMenus.categoryFilter,
    numberFilter: filterMenus.numberFilter,
    dateCreatedFilter: filterMenus.dateCreatedFilter,
    lastUpdatedFilter: filterMenus.lastUpdatedFilter,
    textFilter: filterMenus.textFilter,
    nameFilter: filterMenus.nameFilter,
    unearthIdFilter: filterMenus.unearthIdFilter,
    linksFilter: filterMenus.linksFilter,
    boundaryFilter: BoundaryMenu,
    configMenu: ConfigMenu,
    commonFilters: filterMenus.commonFilters
};
