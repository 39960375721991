import createLayerFlow from 'flows/create-layer/create-layer-flow';
import { NumericInput, PlanEditorHeader } from 'views/plan/plan-editor/plan-editor';
import Page from 'views/plan/pages/page';
import mediaViewerModel from 'models/media-viewer-model';

const SetTilesetDefaults = {
    oninit: ({state}) => {
        state.mediaLabel = 'Source File: ' + createLayerFlow.mediaLabel;
        state.pageId = createLayerFlow.sourcePageId;
        state.sourceMediaId = createLayerFlow.sourceMedia.mediaId;
        state.sourcePageNumber =  createLayerFlow.sourcePage.number;
        if (!document.body.classList.contains('setting-tileset-defaults')) {
            document.body.classList.add('setting-tileset-defaults');
        }
    },

    onremove: () => {
        document.body.classList.remove('setting-tileset-defaults');
        mediaViewerModel.close();
    },

    oncreate: ({state: {sourceMediaId, sourcePageNumber}}) => {
        mediaViewerModel.open([sourceMediaId], undefined, {hideMediaOptions: true, cssClass: 'plan-editor-pdf-enlarge', pdfOpts: `#page=${sourcePageNumber}&navpanes=0&scrollbar=0`});
    },

    view: ({state: {mediaLabel, pageId, sourcePageNumber}}) => {
        return <div class="plan-editor set-tileset-defaults">
            <PlanEditorHeader 
                titleIconClass="icon-layers" 
                planName={createLayerFlow.layerName} 
                mediaLabel={mediaLabel}
            />
            <Page 
                pageId={pageId} 
                index={sourcePageNumber - 1}
            />
            <div class="section-title">Layer Name</div>
            <input type="text" value={createLayerFlow.layerName} oninput={(e) => {
                createLayerFlow.layerName = e.target.value;
            }}/>
            <div class="section-title">Initial Transformation Settings</div>
            <div class="subtext">To start, we'll place the new Map Layer in the center of the Project Bounds, but you'll be able to move, resize, rotate, and crop it once it is on the Map.</div>
            <div class="scale-rotate-row">
                <div class="scale-row">
                    <label>Scale</label>
                    <NumericInput label="" 
                        unitOpts={createLayerFlow.scaleUnitOptsNumerator} 
                        unitDefault={createLayerFlow.scaleNumeratorUnitsSet} 
                        value={createLayerFlow.scaleNumeratorSet} min={1} max={1000} step={1}
                        onChange={(e) => createLayerFlow.setValue('scaleNumeratorSet', e.target.value)}
                        onUnitChange={(e) => createLayerFlow.setValue('scaleNumeratorUnitsSet', e.target.value)}
                    />
                    <NumericInput label="" 
                        unitOpts={createLayerFlow.scaleUnitOptsDenominator} 
                        unitDefault={createLayerFlow.scaleDenominatorUnitsSet} 
                        value={createLayerFlow.scaleDenominatorSet} min={1} max={5280} step={1}
                        onChange={(e) => createLayerFlow.setValue('scaleDenominatorSet', e.target.value)}
                        onUnitChange={(e) => createLayerFlow.setValue('scaleDenominatorUnitsSet', e.target.value)}
                    />
                </div>
                <div class="rotate-row">
                    <NumericInput label="Rotation" 
                        unitDefault="deg" 
                        value={createLayerFlow.rotationDegreesSet} min={0} max={360} step={1}
                        onChange={(e) => createLayerFlow.setValue('rotationDegreesSet', e.target.value)}
                    />
                </div>
            </div>
        </div>;
    }
};

export default SetTilesetDefaults;
