import store from 'util/data/store';
import formModel from 'models/form-model';

export default function(control, assetId) {
    const value = store.assets[assetId].properties[control.fieldName];
    return <div class="length-control">
        <input data-focus-id="1"  type="number" class="number-input" value={value} step="any" oninput={e => {
            formModel.handleNumber(e, control, assetId);
        }}/>
    </div>;
}
