import savedViewsModel from 'models/saved-views-model';
import SavedViewsProjectSelector from 'views/saved-views/saved-views-project-selector';
import SavedViewsListItem from 'views/saved-views/list/saved-views-list-item';

const SavedViewsNowViewing = {
    view: () => {
        return savedViewsModel.activeSavedViewId ? <div class="saved-views-now-viewing">
            <span class="side-nav-popup-subtitle">Now viewing</span>
            <SavedViewsListItem projectViewId={savedViewsModel.activeSavedViewId} hideProjectSwitcher={true} disableOnclickEvent={true}/>
            <SavedViewsProjectSelector projectViewId={savedViewsModel.activeSavedViewId}/>
        </div> : '';
    }
};

export default SavedViewsNowViewing;
