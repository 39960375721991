import api from 'legacy/util/api/api';
import router from 'uav-router';
import appModel from 'models/app-model';
import constants from 'util/data/constants';
import helpers from 'legacy/util/api/helpers';
import store from 'util/data/store';
import siteModel from 'models/site-model';
import formModel from 'models/form-model';
import placeModel from 'models/place-model';
import latLngsToLngLats from 'util/geo/latlngs-to-lnglats';
import deepCloneObject from 'util/data/deep-clone-object';
import queryFeatures from 'util/geo/query-features';
import popup from 'util/popup';
import PlaceFeaturesMenu from 'views/places-tab/place-features-menu';
import AddLevelPopup from 'views/places-tab/add-level-popup';
import measure from 'legacy/util/numbers/measure';
import dialogModel from 'models/dialog-model';
import popoverModel from 'models/popover-model';
import roundIfNLong from 'util/numbers/round-if-n-long';
import placeEditorModel from './place-editor-model';
import PlaceEditor from 'views/place-editor';
import layerModel from 'models/layer-model';

const TEAL_HEX = constants.colors.nameToHex.teal;

class PlacesTabModel {

    constructor() {
        this.placeSource = '_place_source';
        this.placeLayer = '_place_layer';
        this.siteFeatureId = undefined;
        this.places = {};
    }

    async init() {
        this.projectId = formModel.childProjectId;
        this.assetId = formModel.assetId;
        this.places = {};
        this.isLoading = true;
        this.plansAreLoading = true;
        this.placesToggledOn = {};
        const feature = await placeModel.getFeature(this.asset.featureIds[0]);
        if (feature) {
            this.safeAddPlaceLayer(feature);
            this.feature = feature;
            this.siteFeatureId = feature.id;
            this.placesToggledOn[this.siteFeatureId] = true;
            await this.setupPlacesFeatures(feature);
        }
       
        siteModel.changeMapClick((e) => this.mapClick(e));
        layerModel.folders.state.subFoldersLoaded = false;
        this.loadPlans();
        this.isLoading = false;
        
    }

    async loadPlans() {
        await layerModel.initFolders();
        await appModel.project.loadProjectPlans(appModel.state.editingProjectId);
        layerModel.addPlansToFolders();
        this.plansAreLoading = false;
    }

    showChildProjectPlansAndPlaces() {
        return appModel.isOnTab('Places/Levels') && !!router.params.assetId 
        || router.params.view === 'placeEditor';
    }

    safeAddPlaceLayer(feature) {
        const source = this.getPlaceSource();
        if (source) {
            return;
        }
        this.addLayer(this.placeSource, this.placeLayer, this.getLayerColor(feature));
        this.source = siteModel.map.getSource(this.placeSource);
    }

    /**
     * Add a temp layer for viewing places on a feature.
     */
    addLayer(sourceId, layerId, color, features = []) {
        const map = siteModel.map;
        map.addSource(sourceId, {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: features
            }
        });
        this.layer = map.addLayer({
            'id': layerId,
            'source': sourceId,
            'type': 'line',
            'paint': {
                'line-color': color,
                'line-width': 2,
                'line-dasharray': [
                    2,
                    1
                ]
            }
        });
    }

    get asset() {
        return store.assets[this.assetId];
    }

    mapClick({ point, lngLat }) {
        if (!appModel.isDrawing) {
            const features = queryFeatures(point).filter((f) => this.places[f.properties._placeId]);
            const projectFeature = queryFeatures(point).filter((f) => this.feature.id === f.id);
            if (projectFeature && features.length === 0) {
                siteModel.leftClick(projectFeature, lngLat);
            }
            if (features.length > 0) {
                PlaceFeaturesMenu.open(features, lngLat);
            } else {
                popup.remove();
            }
        }
    }

    /**
     *  setupPlacesFeatures: Grabs all places on a project.
     *                          Creates features to match the look of the project feature.
     */

    setupPlacesFeatures(feature) {
        const { properties } = feature;
        return api.rpc.request([['listPlaces',
            api.rpc.visible({
                projectId: this.projectId,
                limit: 0
            })]
        ]).then((places) => {
            this.placeFeatures = {};
            places.forEach((place) => this.addPlaceToTabList(place, properties));
            this.showProjectPlaces();
            m.redraw();
        });
    }

    addPlaceToTabList(place, properties = this.feature.properties) {
        this.places[place.placeId] = place;
        this.createPlaceFeature(place, properties);
        const placeLevels = place.levels && place.levels.items ? place.levels.items : undefined;
        if (placeLevels) {
            place.levels.items = place.levels.items.filter((p) => p.isVisible);
        }
    }

    /**
     * Creates a new feature from a place and properties.
     */

    createPlaceFeature(place, properties) {
        const geometry = deepCloneObject(place.boundary);
        geometry.coordinates[0] = latLngsToLngLats(place.boundary.coordinates[0]);
        const { placeId } = place;
        this.placeFeatures[placeId] = {
            geometry,
            type: 'Feature',
            id: placeId,
            properties: Object.assign({}, properties, { _id: placeId, _placeId: placeId })
        };
    }

    showProjectPlaces(placeFeatures = Object.values(this.placeFeatures)) {
        const source = this.getPlaceSource();
        source._data.features = [];
        source._data.features.push(...placeFeatures);
        source.setData(source._data);
        placeFeatures.forEach(feature => {
            placesTabModel.placesToggledOn[feature.properties._placeId] = true; 
        });
        if (!placesTabModel.placesToggledOn[placesTabModel.siteFeatureId]) {
            placesTabModel.showProjectPlace(placesTabModel.siteFeatureId);
        }
        m.redraw();
    }

    hideProjectPlaces() {
        const source = this.getPlaceSource();
        source._data.features = [];
        source.setData(source._data);
        placesTabModel.placesToggledOn = {};
        placesTabModel.hideProjectPlace(placesTabModel.siteFeatureId);
        m.redraw();
    }

    hideProjectPlace(placeId) {
        let source;
        if (placeId === placesTabModel.siteFeatureId) {
            source = placesTabModel.feature.featureType.source;
        } else {
            source = placesTabModel.getPlaceSource();
        }
        const features = source._data.features;
        const index = features.findIndex(feature => feature.id === placeId);
        features.splice(index, 1);
        source.setData(source._data);
        delete placesTabModel.placesToggledOn[placeId];
        popup.remove();
        m.redraw();
    }

    showProjectPlace(placeId) {
        let source, featureToAdd;
        if (placeId !== placesTabModel.siteFeatureId) {
            source = placesTabModel.getPlaceSource();
            featureToAdd = Object.values(placesTabModel.placeFeatures).find(feature => feature.id === placeId);
        } else {
            return;
        }
        const features = source._data.features;
        features.push(featureToAdd);
        source.setData(source._data);
        placesTabModel.placesToggledOn[placeId] = true;
    }

    getPlaceSource() {
        return siteModel.map.getSource(this.placeSource);
    }

    getLayerColor(feature) {
        const featureTypeId = feature.properties.featureTypeId;
        const featureStyles = appModel.toolbox.featureTypes[featureTypeId].featureStyles;
        const featureStyle = featureStyles && Object.values(featureStyles)[0];
       
        if (featureStyle && featureStyle.style) {
            const style = featureStyle.style;
            if (style.paint && style.paint['fill-color']) {
                return style.paint['fill-color'];
            }
        }
        // Default color if the project style doesnt have a fill.
        return TEAL_HEX;
    }

    /**
     * Removes the places features from the map.
     */
    cleanUp() {
        this.cleanUpFeatures();
        // Reset the map click events.
        popup.remove();
        siteModel.removeSiteMapClick();
        siteModel.siteMapClick();
        if (layerModel.isOpen) {
            layerModel.togglePicker();
            layerModel.layerControl._container.classList.remove('active');
        }
        layerModel.initFoldersForMetaProject();
        if (router.params.projectId === appModel.project.projectId) {
            appModel.project.loadProjectPlans(appModel.project.projectId);
        }

        this.plansAreLoading = false;
        m.redraw();
    }

    cleanUpFeatures() {
        const { map } = siteModel;
        if (map.getLayer(this.placeLayer)) {
            map.removeLayer(this.placeLayer);
        }
        if (map.getSource(this.placeSource)) {
            map.removeSource(this.placeSource);
        }
        if (!placesTabModel.placesToggledOn[placesTabModel.siteFeatureId]) {
            placesTabModel.showProjectPlace(placesTabModel.siteFeatureId);
        }
    }

    /**
     * openPlaceForEditing: Opens the place editor.
     *
     * @param placeId (Optional)
     */
    openPlaceForEditing(projectId, placeId) {
        placeEditorModel.init(projectId, this.assetId, placeId);
        siteModel.sidebar = PlaceEditor;
        popoverModel.close();
        m.redraw();
    }

    editPlaceBounds() {
        formModel.editFeature(this.feature);
        formModel.selectTab({ name: 'Properties' });
        popup.remove();
    }

    deletePlace(place) {
        dialogModel.open({
            text: `Delete ${place.name || 'this place'}?`,
            warning: false,
            onYes: () => {
                api.rpc.delete('Place', place.placeId);
                if (this.places[place.placeId]) {
                    delete this.places[place.placeId];
                    this.removeFeatureFromMap(place.placeId);
                }
            }
        });
    }

    removeFeatureFromMap(id) {
        const asset = store.assets[formModel.assetId];
        if (asset && asset.featureIds && asset.featureIds[0]) {
            placeModel
                .getFeature(asset.featureIds[0])
                .then(() => {
                    const source = this.getPlaceSource();
                    source._data.features = source._data.features.filter((f) => !(f.properties._placeId && f.properties._placeId === id));
                    source.setData(source._data);
                });
        }
    }

    deleteLevel(levelId) {
        api.rpc.request([['deleteLevel', {
            levelId
        }]]).then((level) => {
            this.removeLevelFromPlace(this.places[level.placeId], level.levelId);
            m.redraw();
        });
    }

    openNewLevelEditor(element, place) {
        this.initNewLevel(place);
        popoverModel.open(element, {
            view: AddLevelPopup,
            yPadding: 137
        });
    }

    openModifyLevelEditor(element, level) {
        this.activeLevelId = level.levelId;
        this.initModifyLevel(level);
        popoverModel.open(element, {
            view: AddLevelPopup,
            yPadding: 145
        });
    }

    addLevelInOrder(place, level) {
        const placeLevels = place.levels.items;
        let index = 0;
        while (placeLevels[index] && level.elevation > placeLevels[index].elevation) {
            index++;
        }
        placeLevels.splice(index, 0, level);
        m.redraw();
    }

    saveNewLevel() {
        const { place, name, elevation } = this.editingLevel;
        api.rpc.request([['createLevel', {
            placeId: place.placeId,
            shortName: name || 'New Level',
            elevation: measure.feetToMeters(Number(elevation))
        }]]).then((level) => this.addLevelInOrder(place, level));
    }

    removeLevelFromPlace(place, levelId) {
        const levels = place.levels && place.levels.items ? place.levels.items : undefined;
        if (levels) {
            const index = levels.findIndex((l) => l.levelId === levelId);
            if (index !== -1) {
                levels.splice(index, 1);
            }
        }
    }

    saveModifyLevel() {
        const { name, elevation, level } = this.editingLevel;
        api.rpc.request([['modifyLevel', {
            levelId: level.levelId,
            shortName: name || 'New Level',
            elevation: measure.feetToMeters(Number(elevation))
        }]]).then((modifiedLevel) => {
            const place = this.places[modifiedLevel.placeId];
            this.removeLevelFromPlace(place, modifiedLevel.levelId);
            if (place) {
                this.addLevelInOrder(place, modifiedLevel);
            }
        });
    }

    initNewLevel(place) {
        const placeLevels = place.levels && place.levels.items ? place.levels.items : undefined;
        const elevation = placeLevels && placeLevels.length > 0 ? measure.metersToFeet(placeLevels[placeLevels.length - 1].elevation || 0) + 10 : 10;
        this.editingLevel = {
            elevation,
            place,
            isNew: true
        };
    }

    initModifyLevel(level) {
        this.editingLevel = {
            level,
            isNew: false,
            elevation: measure.metersToFeet(level.elevation),
            name: level.shortName
        };
    }

    addLevelElevation(levels) {
        let total = 0;
        let all = levels.length;
        levels.forEach((level) => {
            if (level.elevation) {
                total += level.elevation;
            } else {
                all--;
            }
        });
        return { total, all };
    }

    getAverageElevation(levels) {
        const { total, all } = this.addLevelElevation(levels);
        return all === 0 ? '-' : `${roundIfNLong(measure.metersToFeet(total / all), 3)} ft ASL`;
    }

    getTotalAverageElevation(places = Object.values(this.places)) {
        let totalTotal = 0;
        let allTotal = 0;
        places.forEach((place) => {
            const levels = helpers.list(place.levels);
            const { total, all } = this.addLevelElevation(levels);
            if (all > 0) {
                allTotal += all;
                totalTotal += total;
            }
        });

        return allTotal === 0 ? '-' : `${roundIfNLong(measure.metersToFeet(totalTotal / allTotal), 3)} ft ASL`;
    }

    togglePlaces() {
        if (placesTabModel.arePlacesToggledOn()) {
            placesTabModel.hideProjectPlaces();
        } else {
            placesTabModel.showProjectPlaces();
        }
    }

    togglePlace(place) {
        if (placesTabModel.placesToggledOn[place.placeId]) {
            placesTabModel.hideProjectPlace(place.placeId);
        } else {
            placesTabModel.showProjectPlace(place.placeId);
        }
    }

    isPlaceToggledOn(placeId) {
        return !!placesTabModel.placesToggledOn[placeId];
    }

    arePlacesToggledOn() {
        return Object.values(placesTabModel.placesToggledOn).length === Object.values(placesTabModel.places).length + 1;
    }
}

const placesTabModel = new PlacesTabModel();

export default placesTabModel;
