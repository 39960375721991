import uav from 'uav';
import getEventXY from 'legacy/util/device/get-event-xy';
import screenHelper from 'legacy/util/device/screen-helper';
import initializer from 'util/initializer';
import debounce from 'util/events/debounce';

function desktop(opts) {

    const line = uav.component('<div class="point-menu-line"></div>');

    pointMenu.hideStem = () => line.remove();

    const padding = Object.assign({
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
    }, opts.padding);


    const target = opts.e.target;

    const targetBounds = target.getBoundingClientRect();

    let content = null;

    if (opts.content) {

        if (typeof opts.content === 'function') {

            content = opts.content();

        } else if (typeof opts.content.then === 'function') {

            opts.content.then(c => {

                component.content = c;

                setPosition();

            });

        } else {

            content = opts.content;

        }

    }

    function setPosition() {

        const el = component._el;

        const xy = getEventXY(opts.e);

        const elementIsLargerThanTarget = el.offsetHeight * el.offsetWidth > targetBounds.height * targetBounds.width;

        const roomOnTop = targetBounds.top > el.offsetHeight + padding.top + padding.top;

        const roomOnBottom = targetBounds.bottom + el.offsetHeight + padding.bottom + padding.bottom < window.innerHeight;

        const topHalfWasClicked = xy.y <= targetBounds.top + targetBounds.height / 2;

        const leftHalfWasClicked = xy.x <= targetBounds.left + targetBounds.width / 2;

        const roomOnLeft = targetBounds.left > el.offsetWidth + padding.left + padding.left;

        const roomOnRight = targetBounds.right + el.offsetWidth + padding.right + padding.right < window.innerWidth;

        let pointerY = xy.y - el.offsetHeight / 2;

        if (pointerY + el.offsetHeight + padding.bottom > window.innerHeight) {

            pointerY = window.innerHeight - el.offsetHeight - padding.bottom;

        }

        if (pointerY < padding.top) {

            pointerY = padding.top;

        }

        let pointerX = xy.x - el.offsetWidth / 2;

        if (pointerX + el.offsetWidth + padding.right > window.innerWidth) {

            pointerX = window.innerWidth - el.offsetWidth - padding.right;

        }

        if (pointerX < padding.left) {

            pointerX = padding.left;

        }

        let top,
            left;

        if (roomOnTop) {

            top = targetBounds.top - el.offsetHeight - padding.top;

            if (!elementIsLargerThanTarget && !topHalfWasClicked) {

                if (roomOnLeft || roomOnRight) {

                    top = pointerY;

                } else if (roomOnBottom) {

                    top = targetBounds.bottom + padding.bottom;

                }

            }

        } else {

            if (!roomOnLeft && !roomOnRight && roomOnBottom) {

                top = targetBounds.bottom + padding.bottom;

            } else {

                top = pointerY;

            }

        }

        left = pointerX;

        if (!roomOnTop || !elementIsLargerThanTarget && !topHalfWasClicked) {

            if (roomOnLeft && roomOnRight) {

                if (leftHalfWasClicked) {

                    left = targetBounds.left - el.offsetWidth - padding.left;

                } else {

                    left = targetBounds.right + padding.right;

                }

            } else if (roomOnLeft) {

                left = targetBounds.left - el.offsetWidth - padding.left;

            } else if (roomOnRight) {

                left = targetBounds.right + padding.right;

            }

        }

        component.top = top;

        component.left = left;

        const center = {
            x: left + el.offsetWidth / 2,
            y: top + el.offsetHeight / 2
        };

        const length = Math.sqrt(Math.pow(xy.x - center.x, 2) + Math.pow(xy.y - center.y, 2));

        const angle = Math.atan2(center.y - xy.y, center.x - xy.x) * 180 / Math.PI;

        line.style.left = xy.x + 'px';
        line.style.top = xy.y + 'px';
        line.style.width = length + 'px';
        line.style.transform = `rotate(${angle}deg)`;
        line.style.opacity = '1';

        el.classList.add('visible');

    }

    const component = uav.component(`
    <div class="point-menu has-close ${opts.cssClass || ''} desktop-point-menu" u-style="left:{left}px;top:{top}px;" ${opts.closeOnClick ? '' : 'u-onmousedown="{stop}" u-ontouchstart="{stop}"'}>
        {content}
    </div>`, {
        left: 0,
        top: 0,
        content,
        stop: _e => {

            _e.stopPropagation();

            if (_e.target.classList.contains('pop-btn') || _e.target.parentNode.classList.contains('pop-btn')) {

                setTimeout(close, 500);

            }

        }
    }, () => component.content && setTimeout(setPosition, 10));

    function close(e) {

        if (e && e.target && e.target.classList && !e.target.classList.contains('menu-option') && component._el.contains(e.target)) {

            return;

        }

        window.removeEventListener('mousedown', close);
        window.removeEventListener('touchstart', close);

        component._el.classList.remove('visible');

        line.style.width = '0';
        line.style.opacity = '0';

        setTimeout(() => {

            line.remove();

            component._el.remove();

        }, 250);

        if (opts.onclose) {

            opts.onclose();

        }

    }

    window.addEventListener('mousedown', close);
    window.addEventListener('touchstart', close);

    component._el._close = close;

    document.body.appendChild(component._el);

    if (opts.onopen) {
        opts.onopen();
    }

    if (!opts.noLine) {

        document.body.appendChild(line);

    }

    pointMenu.close = close;

}

function mobile(opts) {

    let header;

    const component = uav.component(`
    <div class="point-menu has-close ${opts.cssClass} mobile-point-menu" ${opts.closeOnClick ? '' : 'u-onmousedown="{stop}" u-ontouchstart="{stop}"'}>
        {content}
    </div>`, {
        content: opts.content,
        stop: _e => {

            _e.stopPropagation();

            if (_e.target.classList.contains('pop-btn')) {

                setTimeout(close, 50);

            }

        }
    }, el => setTimeout(() => {

        el.classList.add('visible');

    }, 10));

    function close(e) {

        if (e && e.target) {

            if (component._el.contains(e.target) || header && header._el.contains(e.target)) {

                return;

            }

        }

        window.removeEventListener('mousedown', close);
        window.removeEventListener('touchstart', close);

        component._el.classList.remove('visible');

        if (header) {

            header._el.classList.remove('visible');

        }

        setTimeout(() => {

            component._el.remove();

            if (header) {

                header._el.remove();

            }

        }, 400);

        if (opts.onclose) {

            opts.onclose();

        }

    }

    window.addEventListener('mousedown', close);
    window.addEventListener('touchstart', close);

    component._el._close = close;

    document.body.appendChild(component._el);

    if (!opts.noMobileHeader) {

        header = uav.component(`
        <div class="header point-menu-header">
            <i class="icon-close" u-onclick="{close}"></i>
            <div class="header-center">1 item selected</div>
        </div>`, {
            close: () => close()
        }, el => setTimeout(() => el.classList.add('visible'), 10));

        document.body.appendChild(header._el);

    }

    pointMenu.close = close;

}

function pointMenu(opts) {

    if (opts.e && opts.e.stopPropagation) {

        opts.e.stopPropagation();

    }

    pointMenu.close(opts.e);

    if (screenHelper.large() || opts.forceDesktop) {

        desktop(opts);

    } else {

        mobile(opts);

    }

}

pointMenu.hideStem = new Function();

pointMenu.close = new Function();

pointMenu.onScroll = debounce(() => pointMenu.close());

initializer.add(() => pointMenu.close(), 'pointMenu');

export default pointMenu;
