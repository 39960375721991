import importModel from 'models/import-model';

const GeometryJoiner = {
    view: () => {
        const geoMedia = importModel.geo.media;
        const geoColumnNames = importModel.geo.columnNames;
        let joiner;
        // Sorry for the crazy logic here.
        // At least I removed the nested ternaries.
        if (geoMedia) {
            if (geoColumnNames) {
                if (importModel.geometryMatches) {
                    joiner = <>
                        <label>Match field from '{geoMedia.label}':</label>
                        <div class="select-wrapper">
                            <select value={importModel.geo.match} onchange={e => {
                                importModel.geo.match = e.target.value;
                                importModel.validateGeometry();
                                importModel.getValidCount();
                            }}>
                                {geoColumnNames.map(col => <option key={col} value={col}>{col}</option>)}
                            </select>
                        </div>
                        <label>to field from '{importModel.media.label}':</label>
                        <div class="select-wrapper">
                            <select value={importModel.joinOn} onchange={e => {
                                importModel.joinOn = e.target.value;
                                importModel.validateGeometry();
                                importModel.getValidCount();
                            }}>
                                {importModel.columns.map(col => <option value={col}>{col}</option>)}
                            </select>
                        </div>
                    </>;
                }
            } else {
                joiner = <i class="teal spinner spinning" />;
            }
        }
        return <div class="geometry-joiner">
            {importModel.geo.media
                ? <div>{importModel.geo.media.label} (<a onclick={() => importModel.clearGeoMedia()}>Remove</a>)</div>
                : <div class="btn btn-primary btn-pill btn-small" onclick={() => importModel.uploadGeometry()}>
                    Upload File
                </div>}
            {joiner}
        </div>;
    }
};

export default GeometryJoiner;
