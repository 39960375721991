const ShowHidePassword = {
    oninit: ({state}) => {
        state.type = 'password';
    },
    view: ({state, attrs: {onToggle}}) => {
        return <span class="pwd-toggle" onclick={() => {
            state.type = state.type === 'password'
                ? 'text'
                : 'password';
            onToggle(state.type);
        }}>
            {state.type === 'password' ? 'show' : 'hide'}
        </span>;
    }
};

export default ShowHidePassword;
