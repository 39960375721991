import { length, pixelsToMeters } from 'util/geo';
import round from 'util/numbers/round';
import siteModel from 'models/site-model';
import drawPaletteModel from 'models/draw-palette-model';

function isUpsideDown(coordinates, properties) {
    const metersPerEm = properties._textSizeMeters || pixelsToMeters(properties._textSizePx || 0, properties._latitude, siteModel.map.getZoom());
    const offset = properties._textOffset[0] * metersPerEm;
    const halfLength = length(coordinates, 'meters') / 2;

    let tally = 0,
        segment;
    // Find the segment which contains the centerpoint of the line
    coordinates.find((point, i) => {
        segment = [point, coordinates[i + 1]];
        tally += length(segment, 'meters');
        return tally - offset >= halfLength;
    });

    // If the first point in the center segment is east
    // of the second point, then the segment is "upside-down"
    if (!segment) {
        return false;
    }

    return segment[0][0] > segment[1][0];
}


const DPad = {
    oninit({ state, attrs: { propKey, feature = drawPaletteModel.activeFeature} }) {
        let interval,
            timeout;
        state.move = (index, change) => {
            feature.properties[propKey] = feature.properties[propKey] ? Array.from(feature.properties[propKey]) : [0, 0];
            if (feature.geometry.type === 'LineString' && isUpsideDown(feature.geometry.coordinates, feature.properties)) {
                change = change * -1;
            }
            const move = () => {
                const newValue = feature.properties[propKey];
                newValue[index] = round(feature.properties[propKey][index] + change, 10);
                drawPaletteModel.handleInput(propKey, newValue);
            };
            timeout = setTimeout(() => {
                interval = setInterval(move, 10);
            }, 250);
            move();
        };
        state.stop = () => {
            clearInterval(interval);
            clearTimeout(timeout);
        };
    },
    view: ({ state: { move, stop }, attrs: { propKey, feature = drawPaletteModel.activeFeature} }) => {
        const title = feature.properties[propKey] ? feature.properties[propKey].join(', ') : '0, 0';
        return <div class="control-group d-pad" title={title}>
            <i class="icon-arrow-up" onmousedown={() => move(1, -0.1)} onmouseup={stop} onmouseleave={stop} />
            <i class="icon-arrow-down" onmousedown={() => move(1, 0.1)} onmouseup={stop} onmouseleave={stop} />
            <i class="icon-arrow-left" onmousedown={() => move(0, -0.1)} onmouseup={stop} onmouseleave={stop} />
            <i class="icon-arrow-right" onmousedown={() => move(0, 0.1)} onmouseup={stop} onmouseleave={stop} />
            <i class="icon-text" />
        </div>;
    }
};


export default DPad;
