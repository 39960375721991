import PDFExporter from 'views/pdf-exporter';
import ExportPrompt from 'views/pdf-exporter/export-prompt/export-prompt';
import ExportPromptContentCard from 'views/pdf-exporter/export-prompt-content-card/export-prompt-content-card';
import siteModel from 'models/site-model';
import debounce from 'util/events/debounce';
import initializer from 'util/initializer';
import authManager from 'managers/auth-manager';
import appModel from 'models/app-model';
import randomId from 'util/numbers/random-id';
import publish from 'legacy/util/api/publish';
import dialogModel from 'models/dialog-model';
import api from 'legacy/util/api';
import message from 'views/toast-message/toast-message';
import isMetric from 'util/numbers/is-metric';
import constants from 'util/data/constants';
import {appUrl} from 'util/data/env';
import Table from 'views/table/table';
import screenHelper from 'legacy/util/device/screen-helper';
import swap from 'legacy/util/swap';
import store from 'util/data/store';
import router from 'uav-router';
import formatDate from 'legacy/util/date/format-date';
import timeCache from 'util/data/time-cache';
import {cellValueToText} from 'util/data/cell-value-to-text';
import tableModel from 'models/table/table-model';

const SIDE_NAV_SELECTOR = '.side-nav',
    PDF_BOX_SELECTOR = '.pdf-box',
    EXPORT_PROMPT = '.export-prompt',
    DPI = 72;

const HORIZONTAL = 0;
const VERTICAL = 1;

class ExportPDFModel {

    constructor() {
        this.legendContainerId = 'legendContainerId';
        this.compassId = 'compassId';
        this.legendAssetTypesContainerId = 'legendAssetTypesContainerId';
        this.legendProjectContainerId = 'legendProjectContainerId';
        this.legendPositionId = 'legendPositionId';
        this.legendTitleId = 'legendTitleId';

        this.resize = debounce(() => {
            this.formatToScreen();
            m.redraw();
            this.forcePostRender();
        });

        this.HORIZONTAL = HORIZONTAL;
        this.VERTICAL = VERTICAL;

        this.positionOptions = [
            'top',
            'right',
            'bottom',
            'left'
        ];

        // paper size in inches.
        this.options = [
            {
                width: 8.5,
                height: 11
            },
            {
                width: 8.5,
                height: 14
            },
            {
                width: 11,
                height: 17
            },
            {
                width: 24,
                height: 36
            }
        ];
        this.debouncedPreviewChange = debounce(() => this.formatToScreen());
        this.startSpinning = this.startSpinning.bind(this);
        this.exportFailure = this.exportFailure.bind(this);
    }

    // For removing loading class.
    isDoneLoading() {
        if (router.params.view !== 'pdf') {
            return true;
        }

        return this.isReadyForScreenshot;
    }


    startSpinning() {
        this.spinning = true;
        m.redraw();
    }

    getHeaderValues() {
        let headerValues;
        if (exportPDFModel.pdfHeaderValues) {
            headerValues = Object.keys(exportPDFModel.pdfHeaderValues);
        }
        return headerValues;
    }

    formatFullScreen() {
        let windowHeight = window.innerHeight,
            windowWidth = window.innerWidth;

        if (router.params.view === 'pdf' && router.params.type === 'contentCard') {
            windowHeight = 600;
            windowWidth = 770;
        }
        this.option = {width: windowWidth / DPI, height: windowHeight / DPI};

        this.format({
            scaledWidth: windowWidth,
            scaledHeight: windowHeight,
            windowWidth,
            windowHeight,
            widthOffset: 0,
            windowPadding: 0,
            heightOffset: 0
        });
    }

    formatSmallScreen() {

        const option = this.getOption(),
            aspectRatio = option.width / option.height,
            exportPrompt = document.querySelector(EXPORT_PROMPT),
            navEl = document.querySelector(SIDE_NAV_SELECTOR),
            bottomNavHeight = navEl.offsetHeight,
            padding = 12 * 2,
            exportPromptHeight = exportPrompt.offsetHeight + padding * 2,
            windowHeight = window.innerHeight - bottomNavHeight - exportPromptHeight - padding,
            windowWidth = window.innerWidth - padding,
            scaledWidth = aspectRatio * windowHeight,
            scaledHeight = windowWidth / aspectRatio;

        this.format({
            scaledWidth: scaledWidth,
            scaledHeight: scaledHeight,
            windowWidth,
            windowHeight,
            widthOffset: padding,
            windowPadding: padding,
            heightOffset: exportPromptHeight,
            bottomNavHeight
        });
    }

    formatToScreen() {

        if (screenHelper.small()) {
            this.formatSmallScreen();
            return;
        }

        this.spinning = true;
        const option = this.getOption();
        const aspectRatio = option.width / option.height,
            windowPadding = 24 * 2,
            exportPrompt = document.querySelector(EXPORT_PROMPT),
            navEl = document.querySelector(SIDE_NAV_SELECTOR),
            sideNavWidth = navEl.offsetWidth,
            widthOffset = sideNavWidth + exportPrompt.offsetWidth + windowPadding,
            heightOffset = windowPadding,
            windowHeight = Math.min(window.innerHeight - heightOffset, option.height * DPI),
            windowWidth = Math.min(window.innerWidth - widthOffset, option.width * DPI),
            scaledWidth = aspectRatio * windowHeight,
            scaledHeight = windowWidth / aspectRatio;

        this.format({
            scaledWidth,
            windowWidth,
            windowHeight,
            scaledHeight,
            widthOffset,
            windowPadding,
            heightOffset: 0
        });

        this.spinning = false;
    }

    async initPDF() {
        if (!document.body.classList.contains('loading')) {
            document.body.classList.add('loading');
            m.redraw();
        }
        if (this.isExportingContentCardFor) {
            const app = document.getElementById('app');
            app.style.backgroundColor = 'white';
        }
        const {position} = router.params;
        this.isLoading = true;
        this.orientation = VERTICAL;
        await this.initProjectAssetInfo();

        this.position = position;
        this.formatFullScreen();
        this.isLoading = false;
        this.forcePostRender();
        requestAnimationFrame(() => {
            this.isReadyForScreenshot = true;
            m.redraw();
            siteModel.removeLoadingClass();
        });
    }

    async init() {
        this.isLoading = true;
        this.option = this.options[0];

        window.addEventListener('resize', this.resize);

        this.orientation = VERTICAL;
        this.position = this.positionOptions[0];

        siteModel.map.on('movestart', this.startSpinning);
        siteModel.map.on('moveend', this.debouncedPreviewChange);

        await this.initProjectAssetInfo();

        this.formatToScreen();

        this.isLoading = false;
        m.redraw();

        this.forcePostRender();
    }

    async getProjectAssetInfo() {
        const parentProjectId = store.project.accountAttributes.metaProjectId;
        // Keep the meta project info available for 4 minutes.
        return timeCache(() => Promise.all([api.rpc.request(
            [['listContent', {
                projectId: parentProjectId,
                'properties._projectId': {
                    eq: appModel.project.projectId
                },
                limit: 1
            }]]
        ),
        api.rpc.request([['listProjectToolboxes', {
            projectId: parentProjectId
        }]])]
        ), `pdf-${parentProjectId}`, 1000 * 60 * 4);
    }

    async initProjectAssetInfo() {
        const [[projectAsset], [projectToolbox]] = await this.getProjectAssetInfo();

        // headerValueCount starts at 3 because of Date, Account, and Date.
        this.headerValueCount = 3;
        this.pdfHeaderValues = {};
        if (appModel.user.givenName && appModel.user.familyName) {
            this.pdfHeaderValues['Created in Unearth By'] = `${appModel.user.givenName} ${appModel.user.familyName}`;
        }
        this.pdfHeaderValues.Account = store.account.name;
        this.pdfHeaderValues.Date = formatDate.formatDay(new Date(), true);

        if (!projectAsset) {
            return;
        }

        const {group: {groups}} = projectToolbox;
        let projectAssetForm;

        groups.find((group) => {
            const {tools} = group;
            return tools.find((tool) => {
                const {assetForm: {assetType: {assetTypeId}}} = tool;
                if (assetTypeId === projectAsset.assetTypeId) {
                    projectAssetForm = tool.assetForm;
                    return true;
                }
                return false;
            });
        });

        projectAssetForm.controls.filter((c) => c.attributes.isPDFControl && projectAsset.properties[c.fieldName]).forEach(control => {
            this.pdfHeaderValues[control.fieldName] = cellValueToText(projectAsset.properties[control.fieldName], control.controlTypeId);
            this.headerValueCount++;
        });

        return projectAsset;
    }

    open() {
        this.isExportingContentCardFor = false;
        appModel.toolbox.closeActiveTool();
        siteModel.sidebar = ExportPrompt;
        siteModel.view = PDFExporter;
    }

    openContentCard(assetId) {
        this.isExportingContentCardFor = assetId;
        appModel.toolbox.closeActiveTool();
        siteModel.sidebar = ExportPromptContentCard;
        siteModel.view = PDFExporter;
        m.redraw();
    }

    close() {
        siteModel.sidebar = Table;
        siteModel.view = null;
        this.isExportingContentCardFor = false;
        m.redraw();
    }

    getOption() {
        return this.orientation === VERTICAL ? this.option : {height: this.option.width, width: this.option.height};
    }

    format({
        scaledWidth,
        windowWidth,
        windowHeight,
        scaledHeight,
        widthOffset,
        windowPadding,
        heightOffset
    }) {
        const option = this.getOption();
        let pixelWidth, pixelHeight;
        if (scaledWidth <= windowWidth) {
            pixelWidth = scaledWidth;
            pixelHeight = windowHeight;
        } else {
            pixelWidth = windowWidth;
            pixelHeight = scaledHeight;
        }

        // Center the pdf box in the middle of the working area.
        let leftPosition = widthOffset - windowPadding / 2 + (window.innerWidth - widthOffset) / 2 - pixelWidth / 2;
        // Center the pdf box vertically.
        let topPosition;
        if (heightOffset) {
            topPosition = heightOffset - windowPadding / 2 + windowHeight / 2 - pixelHeight / 2;
        } else {
            topPosition = windowPadding / 2 + (window.innerHeight - windowPadding) / 2 - pixelHeight / 2;
        }

        if (router.params.view === 'pdf' && router.params.type === 'contentCard') {
            leftPosition = 0;
            topPosition = 0;
        }

        this.PDFPositionStyle = `width: ${pixelWidth}px;
                            height: ${pixelHeight}px;
                            left: ${leftPosition}px;
                            top: ${topPosition}px;`;

        const pdfWidth = option.width * DPI;
        const scale = pixelWidth / pdfWidth;

        this.assetTypeMap = this.queryAssetTypes(
            leftPosition,
            topPosition,
            pixelWidth + leftPosition,
            pixelHeight + topPosition
        );

        m.redraw();

        const columnPad = 12 * scale,
            columnWidth = this.position === 'top' || this.position === 'bottom' ? 150 * scale : 188 * scale,
            fontSizeAssetTypeValue = 10 * scale,
            fontSizeAssetValue = 12 * scale,
            fontSizeAssetLabel = 10 * scale,
            headerFontSize = 16 * scale,
            pad = 4 * scale,
            iconSize = 14 * scale,
            compassScale = 33 * scale,
            fontSizeCompass = 12 * scale,
            compassPadding = 8 * scale,
            compassIconSize = 20 * scale,
            borderPad = 8 * scale;


        const renderArgs = {
            pad,
            fontSizeAssetLabel,
            headerFontSize,
            fontSizeAssetTypeValue,
            scale,
            fontSizeAssetValue,
            iconSize,
            columnWidth,
            columnPad,
            borderPad,
            pixelWidth
        };

        this.PDFBoxInnerStyle = `width: ${pixelWidth - borderPad * 2}px;
                        height: ${pixelHeight - borderPad * 2}px;
                        left: ${leftPosition + borderPad}px;
                        top: ${topPosition + borderPad}px;`;

        swap(this.position, {
            top: () => {
                this.buildTopBottomStyle(renderArgs);
                this.legendPositionStyle = `
                    max-height: ${pixelHeight - borderPad * 2}px;
                    overflow: hidden;
                    display: block;
                    padding-top: ${pad}px;
                `;

                // Passed into post render
                this.postRenderArgs = {columnWidth, columnPad: columnPad, scale};
            },
            left: () => {
                this.buildLeftRightStyle(renderArgs);
                this.legendPositionStyle = `
                    max-height: ${pixelHeight - columnPad * 2}px;
                    overflow: hidden;
                    padding: ${pad}px;
                `;

                this.postRenderArgs = {
                    columnWidth,
                    pixelWidth: pixelWidth,
                    compassPad: borderPad * 2,
                    scale
                };
            },
            right: () => {
                this.buildLeftRightStyle(renderArgs);
                this.legendPositionStyle = `
                    max-height: ${pixelHeight - borderPad * 2}px;
                    overflow: hidden;
                    display: block;
                    padding-top: ${pad}px;
                `;

                this.postRenderArgs = {
                    columnWidth,
                    left: leftPosition + pixelWidth - borderPad,
                    pixelWidth: pixelWidth,
                    compassPad: compassPadding * 2,
                    scale
                };
            },
            bottom: () => {
                this.postRenderArgs = {columnWidth, columnPad: columnPad, scale};
                this.buildTopBottomStyle(renderArgs);

                this.legendPositionStyle = `
                    max-height: ${pixelHeight - borderPad * 2}px;
                    overflow: hidden;
                    display: block;
                    padding-top: ${pad}px;
                `;
            }
        });

        this.compassStyle = `
                    height: ${compassScale}px;
                    width: ${compassScale}px;
                    font-size: ${fontSizeCompass}px;
                    padding: ${compassPadding}px;
                `;

        this.compassIconStyle = `
                    font-size: ${compassIconSize}px;
                    top: ${2 * scale}px;
                    left: ${scale}px;
                `;

    }

    buildTopBottomStyle({
        pad,
        fontSizeAssetLabel,
        headerFontSize,
        fontSizeAssetTypeValue,
        scale,
        fontSizeAssetValue,
        iconSize,
        columnWidth,
        columnPad,
        pixelWidth
    }) {
        const maxColumns = Math.floor((pixelWidth - columnPad * 2) / columnWidth);
        // Two items per header item.
        const itemsPerCol = this.headerValueCount === 0 ? 1 : Math.ceil(this.headerValueCount * 1.5);
        const assetTypeCount = Object.values(this.assetTypeMap).length;
        const fullColumns = assetTypeCount / itemsPerCol;
        this.columnLengths = [];

        /**
         * Fill the columns up to the height of the legend until all columns are full.
         * Once full start adding one to each column until there are no assetTypes left.
         * This causes the legend to grow horizontally first and then grow vertically after.
         */
        if (fullColumns >= maxColumns) {
            let assetTypesLeft = assetTypeCount - maxColumns * itemsPerCol;
            for (let i = 0; i < maxColumns; i++) {
                this.columnLengths[i] = itemsPerCol;
            }
            let i = 0;
            while (assetTypesLeft > 0) {
                assetTypesLeft--;
                this.columnLengths[i]++;
                i++;
                if (i >= this.columnLengths.length) {
                    i = 0;
                }
            }
        } else {
            let assetTypesLeft = assetTypeCount;
            let i = 0;
            while (assetTypesLeft > 0) {
                assetTypesLeft--;
                this.columnLengths[i] = this.columnLengths[i] === undefined ? 1 : this.columnLengths[i] + 1;
                if (this.columnLengths[i] >= itemsPerCol) {
                    i++;
                }
            }
        }

        this.legendTitleStyle = `
                    font-size: ${headerFontSize}px;
                    padding: ${pad}px;
                    padding-left: ${pad * 2}px;
                    padding-right: ${pad * 2}px;
                `;
        this.legendSectionTitleStyle = `
                    font-size: ${headerFontSize}px;
                    padding: ${pad}px;
                    left: ${pad * 2}px;
                `;
        this.assetTypeValueStyle = `
                    font-size: ${fontSizeAssetTypeValue}px;
                    line-height: ${fontSizeAssetTypeValue}px;
                `;
        this.assetTypeContainerStyle = `
                    margin-top: ${0}px;
                    margin-bottom: ${pad}px;
                `;
        this.legendTitleStyle = `
                    font-size: ${headerFontSize}px;
                    padding: ${pad}px;
                    padding-left: ${pad * 2}px;
                    padding-right: ${pad * 2}px;
                    white-space: nowrap;
                `;
        this.legendProjectValueStyle = `
                    padding: ${pad}px;
                    padding-left: ${pad * 2}px;
                    padding-right: ${pad * 2}px;
                    font-size: ${fontSizeAssetLabel}px;
                    line-height: ${fontSizeAssetLabel}px;
                `;
        this.legendFieldLabelStyle = `
                    font-size: ${10 * scale}px;
                    line-height: ${fontSizeAssetLabel}px;
                `;
        this.legendFieldValueStyle = `
                    font-size: ${fontSizeAssetValue}px;
                    line-height: ${fontSizeAssetValue}px;
                    padding-top: ${pad}px;
                `;
        this.assetTypeIconStyle = `
                    width: ${iconSize}px;
                    height: ${iconSize}px;
                    top: ${pad}px;
                    margin-right: ${pad}px;
                `;

        this.legendProjectContainerStyle = `
                    float: left;
                    max-width: ${columnWidth}px;
                    height: 100%;
                    margin-bottom: ${pad}px;
                `;

        this.legendAssetTypesStyle = `
                    margin: 0 ${pad * 2}px ${pad * 2}px ${pad * 2}px;
                    display: inline-block;
                    vertical-align: top;
                    height: 100%;
                    line-height: ${fontSizeAssetLabel}px;
                    white-space: nowrap;
                `;
    }

    buildLeftRightStyle({
        pad,
        fontSizeAssetLabel,
        headerFontSize,
        fontSizeAssetTypeValue,
        scale,
        fontSizeAssetValue,
        iconSize
    }) {
        this.legendAssetTypesStyle = `
                    margin: ${pad * 2}px;
                    line-height: ${fontSizeAssetLabel}px;
                `;
        this.legendSectionTitleStyle = `
                    font-size: ${headerFontSize}px;
                    line-height: ${headerFontSize}px;
                    padding: ${pad * 2}px 0;
                `;
        this.assetTypeValueStyle = `
                    font-size: ${fontSizeAssetTypeValue}px;
                    line-height: ${fontSizeAssetTypeValue}px;
                `;
        this.legendTitleStyle = `
                    font-size: ${headerFontSize}px;
                    padding: ${pad}px;
                    padding-left: ${pad * 2}px;
                    padding-right: ${pad * 2}px;
                    white-space: nowrap;
                `;
        this.assetTypeContainerStyle = `
                    margin-top: ${0}px;
                    margin-bottom: ${pad}px;
                `;
        this.legendProjectValueStyle = `
                    padding: ${pad}px;
                    padding-left: ${pad * 2}px;
                    padding-right: ${pad * 2}px;
                    font-size: ${fontSizeAssetLabel}px;
                    line-height: ${fontSizeAssetLabel}px;
                `;
        this.legendFieldLabelStyle = `
                    font-size: ${10 * scale}px;
                    line-height: ${fontSizeAssetLabel}px;
                `;
        this.legendFieldValueStyle = `
                    font-size: ${fontSizeAssetValue}px;
                    line-height: ${fontSizeAssetValue}px;
                    padding-top: ${pad}px;
                `;
        this.assetTypeIconStyle = `
                    width: ${iconSize}px;
                    height: ${iconSize}px;
                    top: ${pad / 2}px;
                    margin-right: ${pad}px;
                `;
    }

    postRenderTopBottom() {
        if (!this.postRenderArgs) {
            return;
        }
        const {columnWidth, scale} = this.postRenderArgs;
        const legendProjectContainer = document.getElementById(this.legendProjectContainerId);
        const columnWidths = this._adjustColumnSizes(scale, columnWidth);
        this._adjustLegendWidth(columnWidths, legendProjectContainer);
        this._alignAssetTypes(scale);
        this._adjustLegendTitle(scale);
    }

    postRenderLeftRight() {
        if (!this.postRenderArgs) {
            return;
        }
        const {scale, left} = this.postRenderArgs;
        let legendPositionEl;
        if (this.position === 'right') {
            legendPositionEl = document.getElementById(exportPDFModel.legendPositionId);
            if (legendPositionEl) {
                const positionWidth = legendPositionEl.offsetWidth;
                legendPositionEl.style.left = `${left - positionWidth}px`;
            }
        }

        this._adjustLegendTitle(scale);
    }

    _adjustLegendWidth(columnWidths, legendProjectContainer) {
        let legendHeaderWidth = legendProjectContainer.offsetWidth;
        const {marginLeft, marginRight} = legendProjectContainer.style.marginLeft;

        if (marginLeft) {
            legendHeaderWidth += parseFloat(marginLeft);
        }

        if (marginRight) {
            legendHeaderWidth += parseFloat(marginRight);
        }

        const legendPositionEl = document.getElementById(this.legendPositionId);
        legendPositionEl.style.width = `${Math.ceil(legendHeaderWidth + columnWidths + 1)}px`;
    }

    _adjustColumnSizes(scale, columnWidth) {
        const columns = [];
        let columnWidths = 0;
        for (let i = 0; i < this.columnLengths.length; i++) {
            const column = document.getElementById(`column-${i}`);
            if (!column) {
                return;
            }
            columns.push(column);
            const children = column.children;
            // Make room for "Legend"
            let maxWidth = 53 * scale;
            for (let j = 0; j < children.length; j++) {
                maxWidth = Math.min(Math.max(children[j].offsetWidth, maxWidth), columnWidth);
            }
            column.style.width = `${maxWidth}px`;
            // Only wrap if the line is too long. Prefer no wrap.
            if (maxWidth === columnWidth) {
                column.style.whiteSpace = 'normal';
            }
            columnWidths += maxWidth + parseFloat(column.style.marginLeft) + parseFloat(column.style.marginRight);
        }
        return columnWidths;
    }


    _alignAssetTypes(scale) {
        // If the header has values align the assetType items with the header values.
        if (this.headerValueCount > 0) {
            const legendTextEl = document.getElementById(this.legendContainerId);
            const legendTitleEl = document.getElementById(this.legendTitleId);
            if (legendTitleEl && legendTextEl) {
                const titleHeight = legendTitleEl.offsetHeight - 8 * scale;
                legendTextEl.style.height = `${titleHeight}px`;
            }
        }
    }

    /**
     * Determinds the width of the element with id=legendProjectContainerId
     *
     * Scale range between 105 * scale and 120 * scale.
     * Picks the perfect width if that width is in the range.
     */
    _adjustLegendTitle(scale) {
        const legendProjectContainer = document.getElementById(this.legendProjectContainerId);
        const legendTitle = document.getElementById(this.legendTitleId);
        if (legendTitle && legendProjectContainer) {
            legendProjectContainer.style.width = `${Math.min(120 * scale, Math.max(legendTitle.offsetWidth, 105 * scale))}px`;
            legendTitle.style.whiteSpace = 'normal';
        }
    }


    queryAssetTypes(x, y, x2, y2) {
        const assetTypeMap = {};
        siteModel.map.queryRenderedFeatures([[x, y], [x2, y2]], {
            validate: false
        }).filter(feature => feature.properties._id && feature.properties.featureTypeId).forEach((mbFeature) => {
            const featureTypeId = mbFeature.properties.featureTypeId;
            const featureType = appModel.toolbox.featureTypes[featureTypeId];
            if (featureType && featureType.assetTypeId) {
                const assetTypeId = featureType.assetTypeId;
                assetTypeMap[assetTypeId] = store.assetTypes[assetTypeId];
            }
        });
        return assetTypeMap;
    }

    cleanup() {

        if (siteModel.map) {
            siteModel.map.dragRotate.enable();
            siteModel.map.off('movestart', this.startSpinning);
            siteModel.map.off('moveend', this.debouncedPreviewChange);
        }

        window.removeEventListener('resize', this.resize);

        this.isLoading = false;
        this.isWaiting = false;
        this.isReadyForScreenshot = false;
        delete this.postRenderArgs;
        delete this.pdfHeaderValues;
        delete this.columnLengths;
    }

    exportFailure() {
        this.isWaiting = false;
        message.show('Export failed.', 'error');
        m.redraw();
    }

    send() {

        this.isWaiting = true;

        const selectedOption = this.orientation === VERTICAL ? this.option : {height: this.option.width, width: this.option.height},
            width = selectedOption.width * DPI,
            height = selectedOption.height * DPI,
            siteName = siteModel.name || 'Untitled_Site',
            date = new Date(),
            map = siteModel.map,
            token = authManager.getToken(),
            refreshToken = authManager.getRefreshToken(),
            box = document.querySelector(PDF_BOX_SELECTOR),
            pos = box.getBoundingClientRect(),
            westX = pos.x - document.querySelector(SIDE_NAV_SELECTOR).offsetWidth,
            northY = pos.y,
            nw = map.unproject({
                x: westX,
                y: northY
            }),
            se = map.unproject({
                x: westX + pos.width,
                y: northY + pos.height
            }),
            projectViewId = tableModel.projectView.projectViewId;
            

        const url = [
            `${appUrl}/${location.hash}`,
            `&position=${this.position}`,
            '&view=pdf',
            `${this.isExportingContentCardFor ? '&type=contentCard' : ''}`,
            `${this.isExportingContentCardFor ? `&contentId=${this.isExportingContentCardFor}` : ''}`,
            `&metric=${isMetric() ? 'true' : ''}`,
            `&nw=${nw.lng},${nw.lat}`,
            `&se=${se.lng},${se.lat}`,
            `&projectViewId=${projectViewId}`,
            `&bearing=${siteModel.map.getBearing()}`,
            `&pitch=${siteModel.map.getPitch()}`
        ].join('');

        publish.await({
            changeType: 'failed',
            recordType: publish.err.ExportFailure,
            test: change => change.url === url,
            callback: this.exportFailure
        });

        publish.await({
            changeType: 'new',
            recordType: 'exportResponse',
            test: change => change.url === url,
            callback: (result) => {
        
                api.rpc.create('Export', {
                    projectId: result.projectId,
                    label: result.name,
                    exportType: result.type,
                    location: result.location,
                    sizeBytes: result.size
                });

                publish.clearCallbacks('failed', publish.err.ExportFailure);

            }
        });
        

        authManager.socket.send('export', {
            data: {
                type: 'PDF',
                payload: {
                    version: constants.apiVersion,
                    urls: [url],
                    token,
                    refreshToken,
                    userId: appModel.user.userId,
                    emailAddress: appModel.user.emailAddress || 'backend@unearthlabs.com',
                    sessionId: authManager.socket.sessionId,
                    title: `${siteName.replace(/ /g, '_').replace(/\//g, '-')}-${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}_${randomId(5)}.pdf`,
                    projectId: appModel.project.projectId,
                    options: {
                        width: this.isExportingContentCardFor ? height : width,
                        height: this.isExportingContentCardFor ? width : height
                    }
                }
            },
            reject: this.exportFailure
        });

        box.classList.add('export-flash');

        setTimeout(() => {
            box.classList.add('dim');
            setTimeout(() => {
                dialogModel.open({
                    headline: 'Your report is being generated.',
                    text: 'We’ll email you when it’s ready for review. Leaving this page won’t affect processing.',
                    okayText: 'Okay',
                    onOkay: () => {
                        exportPDFModel.close();
                    }
                });
            }, 800);
        }, 50);

    }

    forcePostRender() {
        requestAnimationFrame(() => {
            if (this.position === 'top' || this.position === 'bottom') {
                this.postRenderTopBottom();
            } else {
                this.postRenderLeftRight();
            }
        });
    }

    selectPaperSize(option) {
        this.option = option;
        this.formatToScreen();
        this.forcePostRender();
    }

    selectOrientation(orientation) {
        this.orientation = orientation;
        this.formatToScreen();
        this.forcePostRender();
    }

    selectLegendPosition(position) {
        this.position = position;
        this.formatToScreen();
        this.forcePostRender();
    }
}

const exportPDFModel = new ExportPDFModel();

initializer.add(() => exportPDFModel.cleanup(), 'exportPDFModel');

export default exportPDFModel;
