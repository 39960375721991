import authManager from 'managers/auth-manager';
import router from 'uav-router';
import analytics from 'util/data/analytics';
import rpcCache from 'legacy/util/api/rpc-cache';
import constants from 'util/data/constants';

const READ_REQUEST_REGEX = /^(get|list|count)/;

function requests(rpc, ignoreErrors, ignoreClearPending) {
    if (rpc.length) {
        const isWriteRequest = rpc.find(req => !req[0].match(READ_REQUEST_REGEX));
        return new Promise((resolve, reject) => authManager.socket.send('request', {
            resolve,
            reject,
            ignoreErrors,
            ignoreClearPending,
            data: {
                version: constants.apiVersion,
                platform: 'web',
                rpc,
                analytics: isWriteRequest ? analytics.fetch() : undefined
            }
        }));
    }
    return Promise.resolve();
}

const request = (...args) => requests.apply(undefined, args).then(results => results[0]);

const cancelableRequest = (rpc, callback) => {
    return authManager.socket.send('request', {
        resolve: callback,
        data: {
            version: constants.apiVersion,
            platform: 'web',
            rpc
        }
    });
};

function visible(args) {

    return Object.assign({}, args, {
        isVisible: args.isVisible !== false
    });

}

const rpc = {

    // --- CREATE --- //

    create: (type, data) => request([['create' + type, data]]),

    // --- DELETE --- //

    delete: (type, id) => request([['delete' + type, {
        [type.toLowerCase() + 'Id']: id
    }]]),

    deleteMedia: (mediaId) => request([['deleteMediaItem', {mediaId}]]),

    // --- GET --- //

    get: (type, id, args = {}) => request([['get' + type, Object.assign(args, {
        [type.toLowerCase() + 'Id']: id
    })]]),

    inviteUser: (args) => request([['inviteUser', args]]),


    // --- LIST --- //

    list: (type, args = {}, addSiteId, addIsVisible = true) => {

        if (addSiteId) {

            Object.assign(args, {
                projectId: router.params.projectId,
                siteId: router.params.siteId
            });

        }

        return request([['list' + type, addIsVisible ? visible(args) : args]]);

    },

    copyProjects: (params, ignoreClearPending) => request([['copyProjects', params]], false, ignoreClearPending),

    // Project user role should be the same as the account user role going forward. Original API method still requires the role arg here, though:
    addProjectUser: (projectId, userId, role = 'general') => request([['addProjectUser', {projectId: projectId, userId: userId, role: role}]]),

    modifyAccount: (payload) => request([['modifyAccount', payload]]),

    modifyAccountUser: (payload, ignoreErrors) => request([['modifyAccountUser', payload]], ignoreErrors),

    refreshAccountInfo: (accountId) => request([['refreshAccountInfo', {accountId}]]),

    modifyProject: (payload) => request([['modifyProject', payload]]),

    modifyProjectUser: (payload) => request([['modifyProjectUser', payload]]),

    modifyProjectSite: (payload) => request([['modifyProjectSite', payload]]),

    removeAccountUser: (accountId, userId) => request([['removeAccountUser', {accountId: accountId, userId: userId}]]),

    transferProject: (payload) => request([['transferProject', payload]]),

    removeProjectUser: (projectId, userId) => request([['removeProjectUser', {projectId: projectId, userId: userId}]]),

    // --- MODIFY --- //

    modify: (type, data) => request([['modify' + type, data]]),

    batchModify: (data) => request( [['batchModifyContent', data]]),

    updateUserNotificationPreferences: (data) => request([['updateUserNotificationPreferences', data]]),

    // --- COUNT --- //

    count: (type, args) => {

        delete args.limit;
        delete args.offset;
        delete args.order;

        Object.assign(args, {
            projectId: args.projectId ? args.projectId : router.params.projectId,
            siteId: args.siteId ? args.siteId : router.params.siteId
        });

        return request([['count' + type, args]]);

    },

    // --- OTHER --- //

    request,

    requests,

    cancelableRequest,

    cache: rpcCache,

    visible

};

export default rpc;
