import formModel from 'models/form-model';
import FormControl from 'views/form-control';
import Comments from 'views/comments';
import Links from 'views/links';
import ProjectPeopleTab from 'views/people/project-people-tab';
import PermissionsTab from 'views/permissions/permissions-tab/permissions-tab';
import GeometryDetail from 'views/geometry-detail';
import AssetOptions from 'views/asset-options';
import appModel from 'models/app-model';
import SidebarToggle from 'views/sidebar-toggle';
import store from 'util/data/store';
import pointMenu from 'legacy/components/point-menu/point-menu';
import PlacesTab from 'views/places-tab';
import m from 'mithril';
import router from 'uav-router';
import Embed from 'views/asset-form/embed';
import constants from 'util/data/constants';
import {AssetMetaData} from 'views/asset-card/asset-card';
import libraryModel from 'models/library-model';
import AssetOptionsModel from 'models/asset/asset-options-model';
import capitalize from 'util/data/capitalize';
import EmptyReadOnly from 'views/form-control/empty-read-only';
import PermissionsIndicator from 'views/permissions/permissions-indicator';
import AssetIcon from 'views/asset-icon/asset-icon';
import ControlLabel from 'views/control-label/control-label';
import popoverModel from 'models/popover-model';

const tabs = {
    Properties: {
        oninit({state}) {
            state.onScroll = e => {
                e.redraw = false;
                popoverModel.close();
                pointMenu.onScroll();
            };
            state.hidePlace = Object.values(store.places).length <= 1;
        },

        onremove: () => {
            libraryModel.quit();
        },

        view: ({state}) => {
            const asset = store.assets[formModel.assetId];
            return <div class="tab-content info-content" onscroll={state.onScroll}>
                <AssetMetaData assetId={formModel.assetId} isCollapsible={true} />
                {formModel.controls.map(control => {
                    if (formModel.isEmbedControlType(control)) {
                        return <div class="asset-form-control clear" key={control.fieldName}><Embed control={control} /></div>;
                    }
                    let readOnlyValue;
                    if (formModel.isReadOnlyControl(asset.assetTypeId, control) || control.attributes && control.attributes.eval) {
                        readOnlyValue = asset.properties[control.fieldName];
                        if (readOnlyValue === undefined || readOnlyValue === null
                            || formModel.propertyIsEmptyCoordinate(control, readOnlyValue)
                            || formModel.propertyIsEmptyUrlControl(control, readOnlyValue)) {
                            return <EmptyReadOnly key={control.fieldName} control={control}/>;
                        }
                        readOnlyValue = formModel.getReadOnlyValue(readOnlyValue, control);
                    }
                    if (!FormControl.hasView(control.controlTypeId) || control.controlTypeId === constants.controlTypeNameToId.place && state.hidePlace) {
                        return <div key={control.fieldName} />;
                    }
                    return <div key={control.fieldName}
                        class={`asset-form-control clear ${formModel.saving[control.fieldName] ? ' saving' : ''}${formModel.invalid[control.fieldName] ? ' error' : ''} control-type-${constants.controlTypeIdToName[control.controlTypeId]}-wrap`}>
                        <div class={`clear ${control.attributes.hideLabel ? 'label-hidden' : ''}`}>
                            <ControlLabel control={control}/>
                            <i class="spinner spinning" />
                        </div>
                        {readOnlyValue === undefined ? <FormControl control={control} /> : <div class="eval-value">{readOnlyValue}</div>}
                        {control.attributes.eval && <div class="eval-msg"><span class="eval-icon"></span><span class="eval-text">{control.attributes.eval.description || 'Derived Value'}</span></div>}
                        {formModel.styledControlDescriptors[control.fieldName] && <div class="styled-control-msg"><span class="icon-map-square"></span><span class="styled-control-text">Value of this Property determines {formModel.styledControlDescriptors[control.fieldName]}.</span></div>}
                        {formModel.invalid[control.fieldName] ? <div class="error-msg relative">Please complete this field.</div> : null}
                    </div>;
                })}
                {asset.featureIds && asset.featureIds.map(featureId =>
                    <GeometryDetail key={featureId} featureId={featureId} />
                )}
            </div>;
        }
    },
    Comments,
    'Places/Levels': PlacesTab,
    Links,
    People: ProjectPeopleTab,
    Permissions: PermissionsTab
};

const AssetForm = {

    oninit: () => {
        document.body.classList.add('asset-form-open');
    },

    onremove: () => {
        formModel.cleanup();
        document.body.classList.remove('asset-form-open');
        appModel.setPageTitle();
    },

    tab: tab =>
        <div class={`tab ${tab.cssWrapClass}${appModel.isOnTab(tab.name) ? ' active' : ''}`} onclick={() => formModel.selectTab(tab)} key={tab.name}>
            <i class={`icon-${tab.icon} icon`} />
            <span class="tab-name">{tab.displayName || tab.name}</span>
        </div>,

    view: () => {
        const tab = router.params.tab;
        const Tab = tabs[tab];
        const nameControl = formModel.nameControl;
        const asset = store.assets[formModel.assetId];
        const assetType = store.assetTypes[asset.assetTypeId];
        const showArrows = !formModel.isNew && Object.values(store.assets).length > 1;
        if (!asset || !appModel.user.permissions.canReadContent(router.params.assetId)) {
            return <div class="asset-form loading"><i class="spinner spinning teal"/></div>;
        }
        return <div>
            {showArrows && <div class="asset-form-arrow-wrap">
                <span class="asset-form-arrow UFtracker-asset-form-arrow-previous" onclick={() => formModel.validateThenRun(() => formModel.next(true))}><i className="icon-arrow-up" /></span>
                <span class="asset-form-arrow UFtracker-asset-form-arrow-next" onclick={() => formModel.validateThenRun(() => formModel.next(false))}><i className="icon-arrow-down" /></span>
            </div>}
            <div class={`asset-form ${formModel.visible ? ' visible' : ''} ${formModel.isNew ? 'is-new' : ''}${libraryModel.getOpenCssClass()} ${formModel.assetFormClass}`}>
                <div class="asset-header-wrap">
                    <AssetIcon assetId={asset.contentId}/>
                    <div class="asset-title">
                        {nameControl && asset.properties[nameControl.fieldName] || assetType.name}
                    </div>
                    <AssetOptions assetId={asset.contentId} />
                    <i class="icon-close asset-close UFtracker-form-close-x" onclick={() => formModel.validateThenRun(() => formModel.close())} />
                </div>
                <SidebarToggle onclick={() => formModel.toggleVisibility()} />
                <div class="asset-form-wrap">
                    {formModel.childProjectId && <div class="asset-project-wrap">
                        <div class="btn btn-pill btn-secondary btn-small" onclick={() => appModel.changeProject(formModel.childProjectId)}>
                            <span class="btn-label">Go to {capitalize(appModel.toolbox.siteTermSingular)}</span>
                        </div>
                    </div>}
                    <div class={'tabset tabcount-' + formModel.tabCount}>
                        {AssetForm.tab({name: 'Properties', icon: 'asset-form', cssWrapClass: 'UFtracker-properties-tab'})}
                        {formModel.hasLinksTab ? AssetForm.tab({name: 'Links', icon: 'link', cssWrapClass: 'UFtracker-links-tab', displayName: formModel.getLinksDisplayName(asset)}) : m.fragment({key: 'links'})}
                        {formModel.hasCommentTab ? AssetForm.tab({name: 'Comments', icon: 'comment', cssWrapClass: 'UFtracker-comments-tab'}) : m.fragment({key: 'comments'})}
                        {AssetOptionsModel.showPeopleMenuItem() ? AssetForm.tab({name: 'People', icon: 'user', cssWrapClass: 'UFtracker-people-tab'}) : m.fragment({key: 'people'})}
                        {AssetOptionsModel.hasPlacesEditAccess() ? AssetForm.tab({name: 'Places/Levels', icon: 'place-with-levels', cssWrapClass: 'UFtracker-places-tab'}) : m.fragment({key: 'places'})}
                        {AssetForm.tab({name: 'Permissions', icon: 'permission', cssWrapClass: 'UFtracker-permissions-tab'})}
                    </div>
                    {!!Tab && <><Tab key={asset.contentId} /></>}
                    <div class="asset-form-footer">
                        {AssetOptionsModel.showAddToMapOption(asset.contentId)
                            && <div class={`btn btn-secondary btn-pill btn-small btn-addtomap UFtracker-form-addtomap-button left ${AssetOptionsModel.editFeatureClass(asset.contentId)}`} onclick={() => AssetOptionsModel.handleClickAddToMap(asset.contentId)}>
                                <PermissionsIndicator lockedMessage={AssetOptionsModel.editFeatureMessage(asset.contentId)} />
                                <span class="btn-label">Add to Map</span>
                            </div>}
                        <div class="btn btn-primary btn-pill btn-small right UFtracker-form-done-button" onclick={() => formModel.validateThenRun(() => formModel.close())}>
                            <span class="btn-label">Done</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>;

    }
};

export default AssetForm;
