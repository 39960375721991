import appModel from 'models/app-model';
import capitalize from 'util/data/capitalize';
import screenHelper from 'legacy/util/device/screen-helper';
import Tool from 'views/toolbox/tool/tool';
import sideNavModel from 'models/side-nav-model';
import peopleModel from 'models/people/people-model';
import dataCenterModel from 'models/data-center-model';
import formModel from 'models/form-model';
import drawPaletteModel from 'models/draw-palette-model';
import popupModel from 'models/popover-model';
import constants from 'util/data/constants';

const onClickStyle = (e, toolGroup, tool, styleId) => {
    drawPaletteModel.useCustomStyle(styleId);
    formModel.validateThenRun(() => appModel.toolbox.selectTool(toolGroup, tool, e));
    m.redraw();
};

const CustomStyleOptions = {
    view: ({attrs: {toolStyleId}}) => <div class="custom-style-options-menu">
        <div class="menu-option delete-option" onclick={() => {
            popupModel.close();
            drawPaletteModel.deleteSavedStyle(toolStyleId);
        }}><i className="icon-trash"/>Delete</div>
    </div>
};

const EmptySavedStylesMessage = {
    oninit: ({state, attrs: {toolGroupId}}) => {
        state.name = appModel.toolbox.toolGroups[toolGroupId].name;
    },

    view: ({state: {name}}) => <div class="empty-saved-styles">Select a {name} Tool to add a Style Shortcut.</div>
};

const SavedStyles = {  
    view: ({attrs: {toolGroupId}}) => {
        const styleIds = appModel.toolbox.savedStylesByToolGroupId[toolGroupId] || [];
        return <div class="toolbox-group custom-styles">
            <div class="custom-styles-header"><span class="custom-styles-header-text">Style Shortcuts</span></div>
            <div class="toolbox-tools UFtracker-style-shortcuts-in-plus-menu">
                {styleIds.length ? styleIds.map(styleId => {
                    const style = appModel.toolbox.savedStyles[styleId];
                    const tool = appModel.toolbox.tools[style.attributes.toolId];
                    const toolGroup =  appModel.toolbox.toolGroups[tool.toolGroupId];
                    const imgSrc = `${constants.staticMediaURL}${tool.attributes.icon
        ? tool.attributes.icon.mediaId
        : tool.assetForm.assetType.attributes.icon.mediaId}`;

                    return <div class="toolbox-tool-wrap">
                        <div class="toolbox-tool" onclick={(e) => onClickStyle(e, toolGroup, tool, styleId)}>
                            <img class="toolbox-icon" src={imgSrc} />
                            <div class="toolbox-tool-name-wrap">{style.name}
                            </div>
                        </div>
                        <div class="tool-point-menu-wrap">
                            <i class="icon-ellipsis" onclick={function() {
                                popupModel.open(this, {
                                    view: CustomStyleOptions,
                                    wrapClass: 'custom-style-point-menu',
                                    keepUIOpen: true,
                                    disableCloseOnce: true,
                                    args: {toolStyleId: styleId}
                                });
                                popupModel.disableCloseFor(100);
                            }}/>
                        </div>
                    </div>;
                }) : <EmptySavedStylesMessage toolGroupId={toolGroupId}/>}
            </div>
        </div>;
    }
};

const Toolbox = {
    oninit: ({state}) => {
        state.headerLabel = `Add to Your ${appModel.project.isMetaProject ? 'Account' : capitalize(appModel.toolbox.siteTermSingular)}`;
        state.canImport = document.body.offsetWidth > 1024;
    },

    onremove({state}) {
        if (state.removeResize !== undefined) {
            state.removeResize();
        }
    },

    oncreate: function ({state}) {
        state.removeResize = screenHelper.onResize(() => {
            const prevState = state.canImport;
            state.canImport = document.body.offsetWidth > 1024;
            if (state.canImport !== prevState) {
                m.redraw();
            }
        });
        state.headerLabel = `Add to Your ${appModel.project.isMetaProject ? 'Account' : capitalize(appModel.toolbox.siteTermSingular)}`;
    },

    view: ({state}) => {
        return <div class="toolbox side-nav-popup" onclick={(e) => {
            e.stopPropagation();
            popupModel.close();
        }}>
            <div class="side-nav-popup-title">
                {state.headerLabel}
                {!appModel.project.isMetaProject && <div class="toolbox-mode" onclick={() => appModel.toolbox.toggleMode()}>
                    <div class={`toolbox-mode-opt ${appModel.toolbox.importMode ? '' : 'active'}`}>Create</div>
                    {state.canImport &&
                        <div class={`toolbox-mode-opt ${appModel.toolbox.importMode ? 'active' : ''}`}>Import</div>}
                </div>}
            </div>
            <div class="padding-wrap">
                {appModel.user.permissions.canEditToolbox() &&
                    <div class="data-center-cta-wrap">
                        <div class="data-center-cta-message">Modify our Data Templates to meet your specs.</div>
                        <span className={'btn btn-smallest btn-pill btn-primary UFtracker-enter-DC-from-plus-menu'} onclick={() => {
                            dataCenterModel.editToolkit();
                        }}><span class="button-beta-flag">BETA</span><span class="btn-label">Try it out!</span></span>
                    </div>
                }
                {Object.values(appModel.toolbox.toolGroups).map(toolGroup => {
                    return !!toolGroup.tools.length &&
                        <div class={'toolbox-group' + (toolGroup.isCollapsed ? ' collapsed' : '')}>
                            <div class="toolbox-group-name UFtracker-toolgroup-in-plus-menu" onclick={e => toolGroup.toggleExpandCollapse(e)}>
                                {toolGroup.name}
                                <i class={`icon-${toolGroup.isCollapsed ? 'down' : 'up'} right`} />
                            </div>
                            <div class="toolbox-tools"
                                style={`max-height: ${Math.ceil(toolGroup.tools.length / 3) * 72 + 200}px`}>
                                {toolGroup.tools.map(tool => <Tool tool={tool} toolGroup={toolGroup} />)}
                                {toolGroup.hasSavedStylesEnabled && <SavedStyles toolGroupId={toolGroup.toolGroupId}/>}
                            </div>
                        </div>;
                })}
                {appModel.user.isAccountAdmin && appModel.project.isMetaProject && <><div class="toolbox-group-name no-click">People</div>
                    <div class="toolbox-tool UFtracker-add-person-plus-menu" onclick={() => {
                        sideNavModel.close();
                        peopleModel.openNewPerson();
                    }}>
                        <i class="icon-user toolbox-icon" />
                        <div class="toolbox-tool-name-wrap">
                            <span class="toolbox-tool-name">Person</span>
                        </div>
                    </div>
                </>}
            </div>
        </div>;
    }

};

export default Toolbox;
