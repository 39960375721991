
/**
 * Given two text values, trims the whitespace from each, and returns false if they are now different strings.
 * Will also return false if newText does not exist, unless optional param is passed to accept this case.
 */
export const didTextInputChange = (originalText = '', newText = '', rejectEmptyInput = true) => {
    newText = newText.trim();
    originalText = originalText.trim();

    if (rejectEmptyInput && !newText) {
        return false;
    }
    return newText !== originalText;
};
