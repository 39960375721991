import Auth from 'views/auth/auth';
import AuthField from 'views/auth/auth-field/auth-field';
import authModel from 'models/auth-model';
import router from 'uav-router';
import AuthForm from 'views/auth/auth-form/auth-form';
import FormButton from 'views/form-button/form-button';

const ForgotPassword = {
    oninit: ({state}) => {
        state.sent = false;
    },
    view: ({state}) => {
        return <Auth cssClass="forgot-password">
            <AuthForm
                onSubmit={() => {
                    authModel.sendResetPassword(state.email);
                    state.sent = true;
                }}>
                <div class="form-msg"><h1>Forgot your password?</h1></div>
                {!state.sent
                    ? <div class="form new-form">
                        <p>No problem. Enter your email address and we'll send you instructions to reset
                        your password.</p>
                        <AuthField
                            type='email'
                            label='Email Address'
                            onInput={(e, isValid) => {
                                state.isEmailValid = isValid;
                                state.email = e.target.value;
                            }}/>
                        <FormButton label="Send">
                            <div class="login-footer">
                                <a class="cancel-btn" onclick={() => {
                                    router.set(state.onCancelRouterOpts);
                                    state.onCancelRouterOpts = undefined;
                                }}>Cancel</a>
                            </div>
                        </FormButton>
                    </div>
                    : <div class="form">
                        <h3>We've sent reset instructions.</h3>
                        <p>Check your email for a link to set your new password.</p>
                        <div class="form-bottom no-link">
                            <a class="btn btn-primary btn-pill" onclick={() => router.set() }><span
                                class="btn-label">Return to Sign In</span></a>
                        </div>
                    </div>}
            </AuthForm>
        </Auth>;
    }
};

export default ForgotPassword;
