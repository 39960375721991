import appModel from 'models/app-model';
import PermissionsIndicator from 'views/permissions/permissions-indicator/permissions-indicator';
import restrictions from 'util/permissions/restriction-message';
import formModel from 'models/form-model';
import uploadHelpFlow from 'flows/upload-help/upload-help-flow';
import constants from 'util/data/constants';

const Tool = {
    view: ({ attrs: { tool, toolGroup, isUploadHelperTool }}) => {
        const canCreate = tool.assetForm && appModel.user.permissions.canCreateAssetType(tool.assetForm.assetType.assetTypeId) || isUploadHelperTool;
        const onToolClick = (e) => {
            if (isUploadHelperTool) {
                uploadHelpFlow.selectTool(tool);
            } else if (canCreate) {
                formModel.validateThenRun(() => appModel.toolbox.selectTool(toolGroup, tool, e));
            }
        };
        const imgSrc = `${constants.staticMediaURL}${tool.attributes.icon
                ? tool.attributes.icon.mediaId
                : tool.assetForm.assetType.attributes.icon.mediaId}`;

        return <div
            class={`toolbox-tool UFtracker-tool-in-plus-menu ${canCreate ? 'active' : 'disabled'}`}
            onclick={onToolClick}
            key={tool.toolId}
            title={tool.name}
        >
            <img class="toolbox-icon" src={imgSrc} />
            <div class="toolbox-tool-name-wrap">
                <span class="toolbox-tool-name">{appModel.toolbox.getShortToolName(tool) }</span>
            </div>
            <PermissionsIndicator lockedMessage={canCreate ? '' : restrictions.message(appModel.user, 'create')} />
        </div>;
    }
};

export default Tool;
