const deep = (object, operation = Object.freeze) => {
    const propNames = Object.getOwnPropertyNames(object);
    propNames.forEach((name) => {
        if (object[name] && Object.getPrototypeOf(object[name]) === Object.prototype) {
            deep(object[name], operation);
        }
    });
    return operation(object);
};

const freeze = (object) => deep(object);
const preventExtension = (object) => deep(object, Object.preventExtensions);

export default {freeze, preventExtension};
