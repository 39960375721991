/* 

    The Component class provides an alternative to the uav rendering library.
    We have started using this class for all components because classical
    inheritance works well for theming. 

############################################################################
# Example
############################################################################

    class MyClass extends Component {

        click(e) {
            this._el.classList.add('clicked');
            alert(this.message);
        }

        render(html) {
            html`<button onclick="${this.click}">Click to show message</button>`;
        }

    }

    new MyClass({
        message: 'Hello, World'
    });

############################################################################
# Props
############################################################################

    To pass data to a Component, provide an object to the constructor. Properties
    on the object will be available to the instance as properties of "this."

    new MyClass({
        message: 'Hello, World' // becomes this.message
    });

############################################################################
# html
############################################################################

    Components have an "html" method. This is a template tag function that can be used
    to convert an HTML string into a DOM node. For background on tagged templates, see
    https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals.

    When using "this.html", template variables can be any of the following:
    - Functions (for event handlers)
    - DOM elements
    - Other Components
    - UAV Components
    - Strings or numbers (will be html-escaped)
    - undefined
    - Arrays of other elements, components, strings, or numbers.

    const el = this.html`<div onclick="${this.onclick}">${childComponent}</div>`;

############################################################################
# The render method
############################################################################

    If you implement a "render" method, it will be called automatically when
    the component is constructed, and will be passed the "html" method. Calling
    "html" within "render" will set "this._el" to the template's root node.
    "this._el" must be set in order to embed one component within another.

############################################################################
# Refs
############################################################################

    A ref (à la React) is simply a reference to a specific DOM nodes within a
    Component's template. You can create a ref using the "ref" attribute:

    render(html) {
    
        html`<div class="parent">
            <div class="child" ref="myElement"></div>
        </div>`;

        console.log(this.myElement); // Logs div.child

    }

*/

// Some HTML elements can only be initialized inside certain parent elements.
const parentTags = {
    tr: 'table',
    tbody: 'table',
    thead: 'table',
    tfoot: 'table',
    th: 'tr',
    td: 'tr',
    figcaption: 'figure',
    li: 'ul',
    source: 'picture',
    param: 'object',
    dd: 'dl',
    col: 'colgroup',
    summary: 'details',
    template: 'body'
};

const tagRegex = /\s*<([a-zA-Z]+)/;

const escape = value => value.toString().replace(/</g, '&lt;');

function handleChildNode(value, childElements) {

    value = value._el || value;

    childElements.push(value);

    return `<${value.tagName} class="_tmp"></${value.tagName}>`;

}

function html(strings, ...values) {

    const childElements = [];

    const eventHandlers = [];

    const innerHTML = strings.map((string, i) => {

        let value = values[i];

        if (value === undefined || value === null) {

            value = '';

        } else if (value._el || value.tagName) {

            value = handleChildNode(value, childElements);

        } else if (typeof value === 'function') {

            eventHandlers.push({
                type: string.match(/ ([a-zA-Z_]+)=['"]?$/)[1],
                fn: value
            });

            value = '_tmp';

        } else if (Array.isArray(value)) {

            let newValue = '';

            value.forEach(val => {

                if (val !== undefined) {

                    if (val._el || val.tagName) {

                        newValue += handleChildNode(val, childElements);

                    } else {

                        newValue += escape(val);

                    }

                }

            });

            value = newValue;

        } else {

            value = escape(value);

        }

        return string + value;

    }).join('');

    const tag = strings[0].match(tagRegex)[1];

    const el = document.createElement(parentTags[tag] || 'div');

    el.innerHTML = innerHTML;

    if (childElements.length) {

        const tempNodes = Array.from(el.getElementsByClassName('_tmp'));

        childElements.forEach((child, i) => {

            tempNodes[i].parentNode.replaceChild(child, tempNodes[i]);

        });

    }

    if (eventHandlers.length) {

        eventHandlers.forEach(handler => {

            const node = el.querySelector(`[${handler.type}="_tmp"]`);

            node.removeAttribute(handler.type);

            node[handler.type] = handler.fn.bind(this);

        });

    }

    const refs = el.querySelectorAll('[ref]');

    for (let i = 0; i < refs.length; i++) {

        const ref = refs[i];

        const value = ref.getAttribute('ref');

        this[value] = ref;

        ref.removeAttribute('ref');

    }

    if (el.firstElementChild.nextElementSibling) {
        
        console.error('Template must have 1 root node:', innerHTML);
    
    }

    return el.firstElementChild;

}

// this.insert(element).into(otherElement)
class Insertable {

    constructor(child) {

        this.child = child._el || child;

    }

    inPlaceOf(element) {

        element = element._el || element;

        if (element.parentNode) {

            element.parentNode.replaceChild(this.child, element);

        }

    }

    into(parent) {

        parent = parent._el || parent;

        parent.innerHTML = '';

        parent.appendChild(this.child);

    }

    before(sibling) {

        sibling = sibling._el || sibling;

        if (sibling.parentNode) {

            sibling.parentNode.insertBefore(this.child, sibling);

        }

    }

    after(sibling) {

        sibling = sibling._el || sibling;

        if (sibling.parentNode) {

            sibling.parentNode.insertBefore(this.child, sibling.nextElementSibling);

        }

    }

}

class Component {

    remove() {

        Array.from(this._el.children).forEach(el => {

            if (el._component && el._component.remove) {

                el._component.remove();

            }

        });

        if (this.onRemove) {

            this.onRemove();

        }

        this._el.remove();

    }

    $(selector, callback) {

        if (callback) {
      
            const els = Array.from(this._el.querySelectorAll(selector));

            els.forEach(callback);

            return els;

        }

        return this._el.querySelector(selector) || document.createElement('div');

    }

    insert(child) {

        return new Insertable(child);

    }

    stopPropagation(e) {

        e.stopPropagation();

    }

    preventDefault(e) {

        e.preventDefault();

    }

    constructor(props) {

        Object.assign(this, props);

        this.html = (...args) => {

            this._el = html.apply(this, args);

            return this._el;

        };

        if (this.render) {

            const ret = this.render(this.html);

            this._el = ret ? ret._el || ret : this._el;

        }

        this.html = (...args) => html.apply(this, args);

    }

}

export default Component;
