import api from 'legacy/util/api';
import router from 'uav-router';
import pointMenu from 'legacy/components/point-menu/point-menu';
import {STEPS, SCALE_FACTORS} from 'constants/flows/create-layer-constants';
import {canAutomateLayerCreation, isSinglePage} from 'flows/create-layer/create-layer-utils';
import Flow from 'flows/flow-model';
import Step from 'flows/step-model';
import appModel from 'models/app-model';
import pluralize from 'util/data/pluralize';
import randomId from 'util/numbers/random-id';
import mediaListManager from 'managers/media-list-manager';
import panelModel from 'models/panel-model';
import SelectPage from 'views/plan/select/select-page';
import {EditPlanFooter, PlanEditor} from 'views/plan/plan-editor/plan-editor';
import SetTilesetDefaults from 'views/plan/set-tileset-defaults/set-tileset-defaults';
import publish from 'legacy/util/api/publish';
import helpers from 'legacy/util/api/helpers';
import planModel from 'models/plan-model';
import store from 'util/data/store';
import message from 'views/toast-message/toast-message';
import formModel from 'models/form-model';
import featureToControl from 'util/interfaces/feature-to-control';
import positionLayerFlow from 'flows/create-layer/position-layer/position-layer-flow';
import GeneratingLayer from 'views/plan/generating-layer/generating-layer';
import isMetric from 'util/numbers/is-metric';
import round from 'util/numbers/round';
import assetListManager from 'managers/asset-list-manager';
import { datadogRum } from '@datadog/browser-rum';
import logConstants from 'constants/managers/log-constants';
import uploadHelpFlow from '../upload-help/upload-help-flow';
class CreateLayerFlow extends Flow {
    constructor() {
        super();
        
        this.reset();

        this.initStepFunctions = {
            [STEPS.START]: this._initStart.bind(this),
            [STEPS.SELECT_PAGE]: this._initSelectPage.bind(this),
            [STEPS.SET_TILESET_DEFAULTS]: this._initSetTilesetDefaults.bind(this),
            [STEPS.SYNC_CONTENT]: this._initSyncContent.bind(this),
            [STEPS.POSITION_LAYER]: this._initPositionLayer.bind(this),
            [STEPS.DONE]: this._initDone.bind(this),
            [STEPS.AUTOMATED_PROCESS]: this._initAutomatedProcess.bind(this),
            [STEPS.AUTOMATED_DONE]: null
        };

        this.steps = {};
        Object.values(STEPS).forEach(step => {
            this.steps[step] = new Step(step, this.initStepFunctions[step]);
        });
   
    }

    reset() {
        super.reset();

        this.assetId = undefined;
        this.plan = undefined;

        this.sourceMedia = undefined;
        this.sourceDocument = undefined;
    
        this.sourcePage = undefined;
        this.sourcePageIndex = undefined;
        this.sourcePageId =  undefined; 
    
        this.pages = {};
        this.sortedPages = [];

        this.pageSelected = null;
        this.creatingPlan = false;
        this.isSelectingPages = false;

        this.scaleNumeratorSet = 1;
        this.scaleDenominatorSet = 40;
        this.scaleNumeratorUnitsSet = 'px';
        this.scaleDenominatorUnitsSet = 'ft';
        this.rotationDegreesSet = 0;

        this.scaleUnitOptsNumerator = ['px'];
        this.scaleUnitOptsDenominator = ['in', 'ft', 'yds', 'cm', 'm', 'km', 'mi'];
    }

    resetSelectPageSettings() {
        this.sourcePageId = undefined;
        this.sourcePageIndex = undefined;
        this.sourcePage = undefined;
        this.layerName = '';
    }

    resetTilesetDefaultSettings() {
        let layer = null;
        const assetId = router.params.assetId;
        const layers = assetListManager.getLayers(assetId);
        // Find the most recent visible layer
        if (layers) {
            let index = 0;
            while (layer === null) {
                const lastLayer = layers[index];
                if (lastLayer.isVisible || index === layers.length - 1) {
                    layer = lastLayer;
                }
                index = index - 1;
            }
        }
        
        this.setScaleDefaults();
        this.rotationDegreesSet = layer ? layer.rotation || 0 : 0;
    }

    get planName() {
        return this.plan ? this.plan.title : '';
    }

    get mediaLabel() {
        let label  = this.sourceMedia && this.sourceMedia.label;
        if (!label) {
            label = this.plan && this.plan.document && this.plan.document.title;
        }
        return label || '';
    }

    setScaleDefaults() {
        const metric = isMetric();
        this.scaleDenominatorUnitsSet = metric ? 'm' : 'ft';
        if (this.isPdf) {
            this.scaleUnitOptsNumerator = ['in', 'ft', 'yds', 'cm', 'm'];
            this.scaleUnitOptsDenominator = ['ft', 'yds', 'cm', 'm', 'km'];
            this.scaleNumeratorUnitsSet = metric ? 'cm' : 'in';
            this.scaleNumeratorSet = 1;
            this.scaleDenominatorSet = 40;
        } else {
            try {
                const scaleBox = document.getElementsByClassName('mapboxgl-ctrl-scale')[0];
                const numerator = scaleBox.offsetWidth;

                const text = scaleBox.innerText;
                const denominator = text.match(/^[0-9]+/)[0];
                const units = text.match(/[a-zA-Z]+/)[0];

                let normalizedNumerator = 1;
                let normalizedDenominator = denominator / numerator;
                while (normalizedDenominator < 1) {
                    normalizedDenominator = normalizedDenominator * 100;
                    normalizedNumerator = normalizedNumerator * 100;
                }

                this.scaleNumeratorSet = normalizedNumerator;
                this.scaleDenominatorSet = round(normalizedDenominator, 100);
            
                if (this.scaleUnitOptsDenominator.find(unit => unit === units)) { 
                    this.scaleDenominatorUnitsSet = units;
                }

            } catch {
                this.scaleNumeratorSet = 1;
                this.scaleDenominatorSet = 40;
                this.scaleDenominatorUnitsSet = metric ? 'm' : 'ft';
            }
        }
    }
    // Step 0
    async _initStart({mediaId, assetId}) {
        this.reset();
        super.isActive = true;
        this.assetId = assetId;
        this.sourceMedia = await mediaListManager.getMediaAsync(mediaId);
        this.setScaleDefaults();
        pointMenu.close();

        this.pages = {};
        this.isSelectingPages = true;

        if (canAutomateLayerCreation(this.sourceMedia)) {
            return this.goToStep(STEPS.AUTOMATED_PROCESS);
        }

        return this.nextStep();
    }

    // Step 1
    async _initSelectPage() {
        this.resetSelectPageSettings();

        if (!this.isActive) {
            return;
        }

        panelModel.open({
            view: SelectPage,
            overSidebar: true,
            styleClass: 'default-style plan-editor-panel show-curtain select-page-panel',
            secondaryButtonText: 'Cancel',
            secondaryButtonClick: () => createLayerFlow.close(),
            disableDoneBtn: true
        });

        this.pageSelectionTitle = 'Analyzing Document...';
        m.redraw();
    
        const documents = await api.rpc.request([['listDocuments', {
            projectId: appModel.project.projectId, 
            mediaId: this.sourceMedia.mediaId
        }]]);

        // Some mediaId & projectId combos may come back with multiple documents that match.
        // Make sure the one we choose is valid.  
        this.sourceDocument = documents.find(document => document.isVisible && document.pageIds && helpers.list(document.pageIds).length);

        if (!this.sourceDocument) {
            this.sourceDocument = await this.createDocument(this.sourceMedia);
        }

        this.sourceDocument.pageIds = helpers.list(this.sourceDocument.pageIds);
        
        if (isSinglePage(this.sourceDocument)) {
            return this.fetchPages(this.sourceDocument.documentId, true).then(() => this.selectPage(0));
        }
        
        return this.openPageSelector(this.sourceDocument.documentId, this.assetId);
    }

    // Step 2
    async _initSetTilesetDefaults() {
        if (this.sourceMedia && this.sourceMedia.mimeType === 'application/zip') {
            // Skip setting up tileset defaults for shp files
            return this.nextStep();
        }

        this.resetTilesetDefaultSettings();

        let secondaryButtonText = 'Back';
        let secondaryButtonClick = () => createLayerFlow.previousStep();
        
        // If only single page, we dont want to send them to the select a page step (bc theyll just be rerouted back here)
        if (this.sortedPages.length === 1) {
            secondaryButtonText = 'Cancel';
            secondaryButtonClick = () => createLayerFlow.close();
        }

        panelModel.open({
            view: SetTilesetDefaults,
            overSidebar: true,
            styleClass: 'default-style plan-editor-panel set-tileset-defaults show-curtain',
            secondaryButtonText,
            secondaryButtonClick,
            doneText: 'Next',
            handleDone: () => createLayerFlow.nextStep()
        });    
    }

    // Step 3
    async _initSyncContent() {
        const backBtnAction = this.sourceMedia.mimeType === 'application/zip' ? () => createLayerFlow.close() : () => createLayerFlow.previousStep();
        panelModel.open({
            view: GeneratingLayer,
            overSidebar: true,
            styleClass: 'default-style plan-editor-panel generating-layer show-curtain',
            secondaryButtonText: 'Back',
            secondaryButtonClick: backBtnAction,
            doneText: 'Next',
            handleDone: () => true
        });    

        this.plan = await this.createPlan();

        await this.syncContentToPlan();

        let tileset = this.plan && this.plan.tilesetId ? store.tilesets[this.plan.tilesetId] : undefined;

        if (!tileset) {
            [tileset] = await api.rpc.request([['listTilesets', {tilesetId: this.plan.tilesetId, limit: 1}]]);
        }

        if (tileset && !planModel.supportsStaking(tileset)) {
            return this.skipPositionLayerStep();
        }

        this.nextStep();
    }

    // Step 4
    async _initPositionLayer() {
        panelModel.open({
            view: PlanEditor,
            overSidebar: true,
            styleClass: 'default-style plan-editor-panel',
            footerContent: EditPlanFooter
        });

        positionLayerFlow.start({isNew: true});
    }


    startPositionLayer({assetId, plan}) {
        pointMenu.close();
        this.reset();
    
        this.assetId = assetId;
        this.plan = plan;

        this.goToStep(STEPS.POSITION_LAYER);
    }

    // Step 5
    _initDone() {
        panelModel.close();
        if (uploadHelpFlow.noAutomatedAddress) { //will indicate we came from the ssu flow where a user entered an address and was taken to the create layer flow
            formModel.viewAsset(createLayerFlow.assetId, 'Properties', false); //from there open the asset and reset the upload flow help
            uploadHelpFlow.reset();
        }
        publish.clearCallbacks('deleted', 'document');
        publish.clearCallbacks('modified', 'document');
        publish.clearCallbacks('modified', 'plan');
        this.reset();
        m.redraw();
    }
    
    // Step 98
    async _initAutomatedProcess() {   
        const count = this.sourceMedia.attributes.layerMediaIds.length;
        this.pageSelectionTitle = `Creating ${pluralize(count, 'Layer')}...`;

        this.layerName = `${this.mediaLabel} Layer${count > 1 ? `s: ${count}` : ''}`;
        panelModel.open({
            view: GeneratingLayer,
            overSidebar: true,
            styleClass: 'default-style plan-editor-panel generating-layer show-curtain',
            secondaryButtonText: 'Close',
            secondaryButtonClick: () => createLayerFlow.close(),
            doneText: '',
            handleDone: () => true
        });    

        return planModel.createLayers(this.sourceMedia.mediaId, this.assetId).then(() => this.skipPositionLayerStep());
    } 
    
    async createDocument(media = this.sourceMedia) {
        const mediaId = media.mediaId;
        const documentId = randomId();
        let document = undefined;

        const promise = new Promise((resolve) => {
            publish.await({
                changeType: 'modified',
                recordType: 'document',
                persist: false,
                test: _document => _document.documentId === documentId && _document.pageIds && _document.pageIds.items && _document.pageIds.items.length,
                callback: (_document) => {
                    document = _document;
                    resolve(document);
                }
            });
        });

        try {
            await api.rpc.create('Document', {
                projectId: appModel.project.projectId,
                documentId,
                mediaId
            });
        } catch (e) {
            datadogRum.addError(logConstants.errorLogMessages.CREATE_DOCUMENT_ERROR, {userId: appModel.user.userId, documentId: this.sourceDocument ? this.sourceDocument.documentId : 'missing', mediaId: this.sourceMedia.mediaId, projectId: appModel.project.projectId, error: e});
            message.show('Something went wrong analyzing your file. Please try again or contact support if this continues.', 'error');
            this.close();
        }
        return promise;
    }

    async fetchPages(documentId, justFirstPage = false) {

        const _pages = await api.rpc.list('Pages', {documentId, limit: justFirstPage ? 1 : 0});

        const thumbnailMediaIds = [];
        Object.values(_pages).forEach(p => thumbnailMediaIds.push(p.thumbnailImageId));

        // Fetch these outside of our typical media requests because they don't include the projectId, but all our batching requests do.
        const thumbnails = await api.rpc.request([['listMedia', {mediaIdIn: thumbnailMediaIds, limit: thumbnailMediaIds.length}]]);
        thumbnails.forEach(thumbnail => mediaListManager.addMedia(thumbnail));

        Object.values(_pages).forEach(p => this.addPage(p));

        m.redraw();
    }

    async openPageSelector(documentId) {  
        this.pageSelectionTitle = 'Select a page to create a Plan Layer';
        m.redraw();

        return this.fetchPages(documentId);
    }

    addPage(page, andRedraw = false) {
        this.pages[page.pageId] = page;
        this.sortedPages[page.number - 1] = page.pageId;
        if (andRedraw) {
            m.redraw();
        }
    }


    selectPage(pageIndex, pageId) {
        if (this.sourcePageId) {
            // In this case the fn was hit more than once, ignore.
            return;
        }
        this.sourcePageId = pageId || this.sortedPages[pageIndex];
        this.sourcePageIndex = pageIndex;
        m.redraw();

        this.sourcePage = this.pages[pageId || this.sourcePageId];
        this.layerName = `${this.mediaLabel}${this.sourceDocument.pageIds.length > 1 ? ` (Page ${pageIndex + 1})` : ''}`;

        return this.nextStep();
    }

    async createPlan(pageId = this.sourcePageId) {
        let planId = undefined;
        const promise = new Promise((resolve) => {
            publish.await({
                changeType: 'modified',
                recordType: 'plan',
                persist: false,
                test: _plan => _plan.planId === planId && _plan.status === 'complete',
                callback: _plan => {
                    store.plans[_plan.planId] = _plan;
                    resolve(_plan);
                }
            }); 
        });
        try {
            const plan = await api.rpc.create('Plan', {
                scale: Number(this.scaleSet),
                rotation: Number(this.rotationDegreesSet),
                title: this.layerName,
                pageId,
                projectId: appModel.project.projectId});
            planId = plan.planId;
        } catch (e) {
            datadogRum.addError(logConstants.errorLogMessages.CREATE_PLAN_FAILURE, {userId: appModel.user.userId, documentId: this.sourceDocument.documentId, pageId, projectId: appModel.project.projectId, error: e});
            message.show('Something went wrong creating your Layer. Please try again or contact support if this continues.', 'error');
            this.close();
        }
        return promise;
    }

    async syncContentToPlan() {
        const plan = this.plan;
        const asset = store.assets[this.assetId];
        if (asset && asset.attributes) {
            const tool = appModel.toolbox.tools[asset.attributes.toolId],
                featureType = tool.featureTypes.find(f => f.attributes.interface === 'plan');
            if (!featureType) {
                return Promise.resolve();
            }
            featureToControl.sync('plan', plan, asset.contentId, featureType);
            await api.rpc.request([['modifyContent', {
                contentId: asset.contentId,
                properties: asset.properties
            }]]);
        }
    }

    skipPositionLayerStep() {
        const isNewFalse = false;  // This 3rd param should stay "false" to prevent the "new plan asset" message from showing after closing the asset form. We're going to show the message right now instead. 
        formModel.viewAsset(createLayerFlow.assetId, 'Properties', isNewFalse);
        this.close();
        message.show('New Map Layer created.');
    }

    close() {
        this.goToStep(STEPS.DONE);
    }

    get scaleSet() {
        if (this.isPdf) {
            const numerator = this.scaleNumeratorSet;
            const denominator = this.scaleDenominatorSet;
            const factor = SCALE_FACTORS[`${this.scaleNumeratorUnitsSet}${this.scaleDenominatorUnitsSet}`];
            const denominatorInNumeratorUnits = denominator * factor;
            return denominatorInNumeratorUnits / numerator;
        } 

        let denominatorInMeters = 0;
        const factor = SCALE_FACTORS[`${this.scaleDenominatorUnitsSet}m`];
        if (factor) {
            denominatorInMeters = this.scaleDenominatorSet / factor;
        }
        return denominatorInMeters / this.scaleNumeratorSet;
        
    }

    scaleSetDenominatorUnit(unit) {
        const oldUnit = this.scaleDenominatorUnitsSet;
        const conversionFactor = SCALE_FACTORS[oldUnit + unit];

        this.scaleDenominatorUnitsSet = unit;
        this.scaleDenominatorSet = this.scaleDenominatorSet * conversionFactor;
        m.redraw();
    }

    setValue(key, value) {
        this[key] = value;
        m.redraw();
    }

    get isPdf() {
        return this.sourceMedia && this.sourceMedia.mimeType === 'application/pdf';
    }
    
    get showPageNumbers() {
        return this.sourceMedia && this.isPdf;
    }

}
const createLayerFlow = new CreateLayerFlow();

export default createLayerFlow;
