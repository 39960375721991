function addCommas(num) {

    if (isNaN(num)) {

        return '0';

    }

    const parts = num.toString().split('.');

    num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    if (parts[1]) {

        num += '.' + parts[1];

    }

    return num;

}

export default addCommas;
