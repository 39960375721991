import FilterTypeModel from 'models/table/filter-types/filter-type-model';
import debounce from 'util/events/debounce';

const RANGE_RADIO_INDEX = 2;
const RANGE_HIGH_LOW_INDEX = 3;

class NumberRangeModel extends FilterTypeModel {

    constructor() {
        super();
        this.debouncedFilterChange = debounce(() => {
            this.onFilterUpdate();
        }, 400);
    }

    init() {
        super.init({type: 'numberRange', specialProperties: ['selectedRadioIndex', 'selectedSign', 'numberType', 'range', 'rangeLow', 'rangeHigh']});     
        
        // Previously saved states may not have the number type stored yet
        if (!this.state.numberType) {
            this.state.numberType = this.field.type;
        }
    }

    setRangeLow(rangeLow) {
        const range = parseFloat(rangeLow);
        if (range === this.state.rangeLow) {
            return;
        }
        this.state.rangeLow = range;
        if (this.state.selectedRadioIndex === RANGE_HIGH_LOW_INDEX) {
            this.debouncedFilterChange();
        }
    }

    setRangeHigh(rangeHigh) {
        const range = parseFloat(rangeHigh);
        if (range === this.state.rangeHigh) {
            return;
        }
        this.state.rangeHigh = range;
        if (this.state.selectedRadioIndex === RANGE_HIGH_LOW_INDEX) {
            this.debouncedFilterChange();
        }
    }

    setRange(rangeString) {
        const range = parseFloat(rangeString);
        if (range === this.state.range) {
            return;
        }
        this.state.range = range;
        if (this.state.selectedRadioIndex === RANGE_RADIO_INDEX) {
            this.debouncedFilterChange();
        }
    }

    selectSign(value) {
        this.state.selectedSign = value;
        if (this.state.selectedRadioIndex === RANGE_RADIO_INDEX) {
            this.onFilterUpdate();
        }
    }
}

export default new NumberRangeModel();
