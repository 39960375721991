import panelModel from 'models/panel-model';

// Wrapper for views that should appear in the left panel on desktop and size down responsively
const Panel = {
    onremove: () => {
        panelModel.close();
    },

    view: () => {
        return panelModel.isOpen ? <div class={`side-panel ${panelModel.visibilityClass} ${panelModel.styleClass}`}>
            <div class={`side-panel-wrap ${panelModel.styleClass}-wrap`}>
                <div class="vertical-scroll" onscroll={panelModel.onscroll}>
                    {panelModel.view ? m(panelModel.view) : ''}
                </div>
                <div class={`side-panel-footer ${panelModel.styleClass}-footer`}>
                    {panelModel.footerContent ? panelModel.footerContent() : <>
                        {panelModel.secondaryButtonText ? 
                            <button class="btn btn-secondary btn-pill left btn-small btn-close" onclick={() => panelModel.secondaryButtonClick()}>
                                <span class="btn-label">{panelModel.secondaryButtonText}</span></button> : ''}

                        <button class={`btn btn-primary btn-pill btn-small right btn-close ${panelModel.disableDoneBtn ? 'disabled' : ''}`} onclick={panelModel.disableDoneBtn ? null : () => panelModel.handleDone()}><span class="btn-label">{panelModel.doneText}</span>
                        </button>
                    </> }
                </div>
            </div>
            {panelModel.popup.isOpen &&
            <div class={`side-panel-popup ${panelModel.popup.cssClass}`} style={panelModel.popup.coordinates}>{panelModel.popup.view ? m(panelModel.popup.view) : ''}
            </div>}
        </div> : <div class='side-panel-wrap'></div>;
    }
};

export default Panel;
