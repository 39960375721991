import router from 'uav-router';
import peopleModel from 'models/people/people-model';
import constants from 'util/data/constants';
import appModel from 'models/app-model';
import accountManager from 'managers/account-manager';
import {permissionLevel} from 'util/users/permissions';

/* Functional components for displaying user profile data from PersonModel. */

const propertyToLabel = {
    'givenName': 'First Name',
    'familyName': 'Last Name',
    'phoneNumber': 'Phone Number',
    'emailAddress': 'Email Address',
    'role': 'Role',
    'company': 'Company'
};

export const Avatar = {
    view: ({attrs: {userId, person, size}}) => <div class={`user-avatar icon-initials ${size || ''}`}>{person ? person.initials() : peopleModel.getPerson(userId).initials()}</div>
};

export const ViewProfileButton = {
    view: ({attrs: {person}}) =>  <div class="menu-option menu-open" onclick={() => peopleModel.open('profile', person ? person.userId : peopleModel.currentlyViewing.userId)}><i class="icon-user"/> View Profile</div>
};

export const ManageNotificationsButton = {
    view: ({attrs: {smallerView, person}}) =>  <div class="menu-option menu-open" onclick={() => peopleModel.open('notifications', person.userId || peopleModel.currentlyViewing.userId)}><i class="icon-bell"/>{smallerView ? '' : 'Manage '}Notification Preferences</div>
};

export const RemoveAccessButton = {
    view: ({attrs: {person}})  => <div class="menu-option menu-delete" onclick={(e) => {
        e.stopPropagation();
        peopleModel.deletePerson(person.userId);
    }}><i class="icon-circle-slash"/> Remove from Account</div>
};

export const PersonMenu = {
    view: () => {
        const person = peopleModel.currentlyViewing;
        const viewingProjectAsset = !!router.params.assetId;
        const hasAdminPrivileges = peopleModel.hasAdminPrivileges(appModel.user);
        return <div class="user-options">
            <div class="person-menu">
                <ViewProfileButton person={person} />
                {hasAdminPrivileges && <ManageNotificationsButton smallerView={viewingProjectAsset} person={person}/>}
                {hasAdminPrivileges && !viewingProjectAsset && <RemoveAccessButton person={person}/>}
            </div>
        </div>;
    }
};

export const TextField = {
    view: ({attrs: {person, property, editable, inputType, isRequired}}) => {
        const isMissing = isRequired && (!person[property] || !person[property].length);
        return <>
            {editable ? <div class="profile-field one-col row">
                <span class="label">{propertyToLabel[property]}{isRequired && <span class='teal'>*</span>}</span>
                <input type={inputType || 'text'} oninput={(e) => person.autosave(e.target.value, property)} class={`text-input ${person.formState.highlightMissing && isMissing ? 'missing-field' : ''}`} defaultValue={person[property]}/>
            </div>
                : <div class="profile-field two-col row">
                    <span class="label">{propertyToLabel[property]}</span>
                    <span class="user-data">{person.displayProperty(property)}</span>
                </div>}
        </>;
    }
};


export const SelectRole = {
    oninit({state, attrs: {person, property}}) {
        this.setSelectedRole(person[property], state);
    },

    onupdate({state, attrs: {person, property}}) {
        if (!state.role || person[property] !== state.role.key) {
            this.setSelectedRole(person[property], state);
            m.redraw();
        }
    },

    setSelectedRole(role, state) {
        state.role = constants.accountUserRoles[role || 'viewer'];
        state.descriptions = peopleModel.getRoleDescriptions(state.role.key);
    },

    view: ({state, attrs: {person, property, editable, includeDescription}}) => {
        let options = appModel.user.rolesUserCanAssign;
        if (!accountManager.hasSeatsAvailable && (person.isNewUser || permissionLevel(person.role) <= 10)) {
            // If no seats are available:
            // If this is a new user, or if this user exists but is view-only, we can't assign them to a non-view-only seat:
            options = [constants.accountUserRoles.viewer];
        }
        const selectIsDisabled = person.formState.autoSaving[property] || person.savingClass === 'creating';
        return <>
            {editable ?
                <div class="profile-field two-col row">
                    <span class="label">{propertyToLabel[property]}<span class='teal'>*</span></span>
                    <div class="select-wrapper">
                        <select disabled={selectIsDisabled} value={state.role.key || ''} oninput={(e) => person.autosave(e.target.value, property)}>
                            {options.map((role, index) =>
                                <option key={index} value={role.key}>{role.display || ''}</option>)}
                        </select>
                    </div>
                </div>
                : <div class="profile-field two-col row">
                    <span class="label">{propertyToLabel[property]}</span>
                    <div class="user-data role-pill">{state.role.display}</div>
                </div>}
            {!!includeDescription && <div class="profile-field one-col row">
                <span class="label access-permissions">Access and Permissions</span>
                <ul class="role-descriptions">{state.descriptions.map(description =>
                    <li>{description}</li>)}</ul>
            </div> }
        </>;
    }
};

export const ContactInfo = {
    view: ({attrs: {person, isEditable}}) => {
        return <div class="contact-info">
            {person.isNewUser ? <div class='profile-fields-new-user'>
                <TextField person={person} isRequired={true} type='email' property={'emailAddress'} editable={true}/>
                <div class="two-col">
                    <TextField type={'tel'} isRequired={true} person={person} property={'phoneNumber'} editable={true}/>
                    <TextField type={'text'} isRequired={true} person={person} property={'company'} editable={true}/>
                </div>
            </div> : <div>
                {isEditable && person.userCanEdit() && <span class="profile-form-message">Please <a href="mailto:unearthsupport@procore.com">contact our support team</a> if you need to update your Email Address, Phone Number, or Company.</span>}
                <div class='profile-fields-existing-user'>
                    <TextField type={'text'} isRequired={true} person={person} property={'emailAddress'} editable={false}/>
                    <TextField type={'text'} isRequired={true} person={person} property={'phoneNumber'} editable={false}/>
                    <TextField type={'text'} isRequired={false} person={person} property={'company'} editable={false}/>
                </div>
            </div>}
        </div>;
    }
};

export const AccountInfo = {
    view: ({attrs: {person, includeDescription, isEditable}}) => {
        return <div class="account-info">
            <SelectRole person={person} property={'role'}
                editable={isEditable && person.userCanEdit('role')}
                includeDescription={includeDescription}/></div>;
    }
};
