import message from 'views/toast-message/toast-message';

export function copyToClipboard(value) {
    const el = document.createElement('textarea');
    el.value = value;
    el.style.position = 'absolute';
    el.style.zIndex = '-100';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    message.show(`Copied ${el.value} to clipboard.`, 'success wrap-word-span');
}
