import tableModel from 'models/table/table-model';
import {combinedPlacesFilterType, combinedTaggedRadio, combineFilterSelections} from './helpers';
import appModel from 'models/app-model';
/**
 * Determines handling of filter behaviors dependent upon shared column vs individual filter types
 */
class FilterTypeModel {

    init(opts) {
        this.type = opts.type;
        const index = tableModel.projectView.filters.opts.assetType.fields.findIndex((field) => tableModel.projectView.filters.opts.controlType.fieldName === field.name);
        this.field = tableModel.projectView.filters.opts.assetType.fields[index];
        const sharedColumnName = tableModel.projectView.filters.opts.sharedColumnName;

        if (sharedColumnName) {
            this.sharedColumn = tableModel.getSharedColumn(sharedColumnName);
        } else {
            this.sharedColumn = false;
        }

        this.state = tableModel.projectView.filters.getState(sharedColumnName);
        if (!this.state) {
            const name = this.type === 'placesFilter' ? 'Place' : this.type === 'dateRange' && !this.field ? 'Capture Date' : this.field.name;
            this.state = {
                fieldName: name,
                type: this.type
            };
            if (opts.specialProperties) {
                opts.specialProperties.forEach(property => {
                    this.state[property] = this.defaultValueForProperty(property);
                });
                if (sharedColumnName && this.state.hasOwnProperty('checkedOptions')) {
                    const combined = combineFilterSelections(this.state, this.sharedColumn);
                    this.state = combined;
                }
                if (sharedColumnName && this.state.type === 'placesFilter') {
                    this.state = combinedPlacesFilterType(this.state, this.sharedColumn);
                }
            }
            tableModel.projectView.filters.setState(this.state, sharedColumnName);
        }
    }

    get fieldName() {
        return this.state.fieldName;
    }

    hasAnyMixedOptions(state = this.state) {
        return state.selectedRadioIndex === -1;
    }

    get isUserObjType() {
        return this.field.type && this.field.type.objType === 'user';
    }

    getDefaultRadioIndex() {
        return this.sharedColumn ? combinedTaggedRadio() : 0;
    }

    getDefaultCheckedOptions() {
        // Default to all checks "on"
        const checkedOptions = {};
        this.options.forEach(opt => {
            checkedOptions[opt] = true;
        });
        return checkedOptions;
    }

    onFilterUpdate(singleOption) {
        if (tableModel.projectView.filters.opts.sharedColumnName) {// If the shared/combined column is the current controller, update the others:
            if (singleOption) {
                return tableModel.projectView.filters.syncTaggedStatesSingleOption(singleOption, this);
            }
            return tableModel.projectView.filters.syncFilterStates(this);
        }
        if (tableModel.projectView.filters.opts.assetType.assetTypeId && appModel.projectView.common.areAllAssetTypesChecked().isAllSelected) {
            appModel.projectView.common.turnOffAllAssetTypesExcept(tableModel.projectView.filters.opts.assetType.assetTypeId, true);
        } else if (!appModel.projectView.common.checkedAssetTypes[tableModel.projectView.filters.opts.assetType.assetTypeId]) {
            appModel.projectView.common.toggleAssetType(tableModel.projectView.filters.opts.assetType.assetTypeId, true);
        }
        tableModel.filtersChanged();
    }

    defaultValueForProperty(property) {
        switch (property) {
        case 'selectedRadioIndex':
            return this.getDefaultRadioIndex();
        case 'checkedOptions':
            return this.getDefaultCheckedOptions();
        case 'isEmptyChecked':
            return true;
        case 'numberType':
            return this.field.type;
        case 'selectedSign':
            return 'lte';
        case 'range':
            return 50;
        case 'rangeLow':
            return 10;
        case 'rangeHigh':
            return 75;
        case 'multiselect':
            return true;
        default:
            return {};
        }
    }

    selectRadio(i) {
        if (i === this.state.selectedRadioIndex) {
            return;
        }
        this.state.selectedRadioIndex = i;
        this.onFilterUpdate();
    }

}

export default FilterTypeModel;
