import Auth from 'views/auth/auth';
import AuthField from 'views/auth/auth-field/auth-field';
import FormButton from 'views/form-button/form-button';
import AuthForm from 'views/auth/auth-form/auth-form';
import ShowHidePassword from 'views/auth/auth-field/show-hide-password';
import router from 'uav-router';
import authModel from 'models/auth-model';

const ChangePassword = {
    oninit: ({state}) => {
        Object.assign(state, {
            changePassword: {type: 'password'},
            newPassword: {type: 'password'},
            confirmPassword: {type: 'password'}
        });
    },

    view: ({state}) => {
        const validated = state.changePassword.isValid
            && state.newPassword.isValid
            && state.confirmPassword.isValid;
        return <Auth cssClass="change-password">
            <AuthForm
                onSubmit={()=> {
                    state.showValidationError = true;
                    if (validated) {
                        state.spinning = true;
                        authModel.changePassword(state.changePassword.value, state.newPassword.value)
                            .then(() => {
                                router.remove('view');
                            }).finally(() => {
                                state.spinning = false;
                                m.redraw();
                            });
                    }
                }}>
                <div class="form-msg"><h1>Change Password</h1></div>
                <div class="form new-form change-password">
                    <p>You will be prompted to sign in after setting your new password.</p>
                    <AuthField
                        showValidationError={state.showValidationError}
                        type={state.changePassword.type}
                        label='Current Password'
                        onInput={(e, isValid) => {
                            state.changePassword.isValid = isValid;
                            state.changePassword.value = e.target.value;
                        }}>
                        <ShowHidePassword onToggle={(type) => {
                            state.changePassword.type = type;
                        }}/>
                    </AuthField>
                    <AuthField
                        showValidationError={state.showValidationError}
                        matchError={'Passwords do not match'}
                        matchValue={state.confirmPassword.value}
                        type={state.newPassword.type}
                        label='New Password'
                        onInput={(e, isValid) => {
                            state.newPassword.isValid = isValid;
                            state.newPassword.value = e.target.value;
                        }}>
                        <ShowHidePassword onToggle={(type) => {
                            state.newPassword.type = type;
                        }}/>
                    </AuthField>
                    <AuthField
                        showValidationError={state.showValidationError}
                        matchError={'Passwords do not match'}
                        matchValue={state.newPassword.value}
                        type={state.confirmPassword.type}
                        label='Confirm New Password'
                        onInput={(e, isValid) => {
                            state.confirmPassword.isValid = isValid;
                            state.confirmPassword.value = e.target.value;
                        }}>
                        <ShowHidePassword onToggle={(type) => {
                            state.confirmPassword.type = type;
                        }}/>
                    </AuthField>
                    <FormButton
                        isDisabled={!validated}
                        label="Change Password"
                        spinning={state.spinning}>
                        <div class="login-footer">
                            <a class="cancel-btn" onclick={() => router.set()}>Cancel</a>
                        </div>
                    </FormButton>
                </div>
            </AuthForm>
        </Auth>;
    }

};

export default ChangePassword;
