import App from 'views/app';
import authManager from 'managers/auth-manager';
import appModel from 'models/app-model';
import formModel from 'models/form-model';
import peopleModel from 'models/people/people-model';

export default {

    view: () => {
        return <div class="vertical-menu me-menu side-nav-popup">
            <div class="padding-wrap">
                {appModel.user.givenName ? <div class="side-nav-popup-title menu-nav-title">Hi, {appModel.user.givenName}.</div> : ''}
                <ul class="side-nav-popup-links menu-links">
                    <li class="menu-item profile">
                        <a class='side-nav-popup-item menu-item profile UFtracker-me-menu-profile' onclick={() => peopleModel.open('profile')}>Profile</a></li>
                    <li class="menu-item notifications">
                        <a class='side-nav-popup-item menu-item profile UFtracker-me-menu-notification-prefs' onclick={() => peopleModel.open('notifications')}>Notification Preferences</a></li>
                    <li class="menu-item password">
                        <a class='side-nav-popup-item menu-item profile UFtracker-me-menu-change-password' onclick={() => peopleModel.open('password')}>Password</a></li>
                </ul>
                <div class="btn btn-pill btn-secondary btn-small UFtracker-me-menu-sign-out" onclick={() => formModel.validateThenRun(() => App.onNextRender(authManager.signOut))}><span class="btn-label">Sign Out</span></div>
            </div>
        </div>;
    }

};
