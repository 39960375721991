import modalModel from 'models/modal-model';

const Modal = {
    view: () => {
        return <div style={`z-index: ${modalModel.zIndex}`} class={`main-modal ${modalModel.cssClass} ${modalModel.styleClass}-style`}>
            {modalModel.views && modalModel.views.map(modalView => m(modalView, {...modalModel.attrs}))}
        </div>;
    }
};

export default Modal;
