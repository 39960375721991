import constants from 'util/data/constants';
import capitalize from 'util/data/capitalize';
import layerModel from 'models/layer-model';
import stopPropagation from 'util/events/stop-propagation';
import router from 'uav-router';
import store from 'util/data/store';
import pointMenu from 'legacy/components/point-menu';
import convertMithril from 'util/dom/convert-mithril';
import modalModel from 'models/modal-model';
import appModel from 'models/app-model';
import api from 'legacy/util/api';
import stakeableModel from 'models/stakeable-model';
import tableModel from 'models/table/table-model';
import formModel from 'models/form-model';
import formatDate from 'legacy/util/date/format-date';
import PermissionsIndicator from 'views/permissions/permissions-indicator';
import restrictions from 'util/permissions/restriction-message';
import PlansLayerPicker from 'views/layer-picker/plans-layer-picker';
import PlacesLayerPicker from 'views/layer-picker/places-layer-picker';
import deepCloneObject from 'util/data/deep-clone-object';


const LinksLayer = {
    view: () => <div class={`layer-picker-item layer-picker-links UFtracker-show-links-on-map${tableModel.loadingTable ? ' disable loading-table' : ''}`} onclick={() => layerModel.toggleLinks()}>
        <i class={`toggle-switch toggle-is-${layerModel.state.doShowLinks ? 'on' : 'off'}`} />
        <span class="layer-picker-item-name"><i class="icon-link" /> Links <i class="spinner teal"/></span>
        <div class="layer-picker-info">Show assets linked to the current dataset on the map.</div>
    </div>
};

const RenameSurvey = {
    view: ({attrs: {survey}}) => {
        return <div class="block-wrap dialog-wrap rename-survey">
            <div class="content-wrap">
                <div class="dialog-head">Rename Survey Layer</div>
                <div class="dialog-text">
                    <input type="text" value={survey.title || formatDate.formatDay(survey.surveyDateTime, true)} oninput={e => {
                        survey.title = e.target.value;
                    }} />
                </div>
                <div class="dialog-btns"> 
                    <div class="btn-pair">
                        <a class="dialog-option-btn btn btn-pill btn-secondary" onclick={() => modalModel.close()}>Cancel</a>
                        <a class="dialog-option-btn btn btn-pill btn-primary" onclick={() => {
                            api.rpc.modify('Survey', {
                                surveyId: survey.surveyId,
                                title: survey.title
                            });
                            if (survey.surveyId === router.params.survey) {
                                stakeableModel.recordTitle = survey.title || formatDate.formatDay(survey.surveyDateTime, true);
                            }
                            m.redraw();
                            modalModel.close();
                        }}>Save</a>
                    </div>
                </div>
            </div>
        </div>;
    }
};

const SurveyPopover = {

    view: ({attrs: {survey, canEdit}}) => 
        <div class="link-list">
            {canEdit ? <div class="menu-option" onclick={() => modalModel.open({ view: RenameSurvey, attrs: {survey: deepCloneObject(survey)} })}>
                <i class="icon-edit" />
                Rename Survey Layer
            </div> : null}
            <div class="menu-option show-on-large" onclick={() => formModel.validateThenRun(() => router.merge({view: 'survey', survey: layerModel.state.surveyId}))}>
                <i class="icon-layers" />
                Edit Survey Layer
            </div>
            {canEdit ? <div class="menu-option remove-link" onclick={() => layerModel.deleteSurveyDialog(survey)}>
                <i class="icon-trash" />
                Delete Survey Layer
            </div> : null}
        </div>

};

const SurveyMenu = {

    view: () => {

        if (!layerModel.state.surveyId) {
            return null;
        }

        const survey = store.surveys[layerModel.state.surveyId];
        const canEditSurvey = survey ? appModel.user.permissions.canEditRecord(survey) : false;
        if (canEditSurvey && window.innerWidth >= 1024) {

            return <i class="icon-ellipsis" onclick={e => pointMenu({
                e,
                content: convertMithril.toDom(<SurveyPopover survey={survey} canEdit={canEditSurvey} />),
                forceDesktop: true,
                noLine: true
            })} />;

        }

        return <PermissionsIndicator lockedMessage={restrictions.message(appModel.user, 'geocorrect', 'survey')}/>;

    }

};

const SurveyPicker = {
    view: () =>
        <div class="clear">
            <div class={'select-wrapper select-survey' + (layerModel.state.surveyId ? ' active' : '')}>
                <select class="layer-picker-survey-select" onchange={e => layerModel.setSurvey(e.target.value)} value={layerModel.state.surveyId || ''}>
                    <option value="">No Survey</option>
                    {Object.values(store.surveys).map(survey => survey.status === 'ready'
                        ? <option value={survey.surveyId}>{survey.title || formatDate.formatDay(survey.surveyDateTime, true)}</option>
                        : null
                    )}
                </select>
            </div>
            <SurveyMenu />
        </div>
};

const LayerPicker = {
    
    onremove: () => {

        layerModel.focusedPlan = null;

    },

    view: ({attrs}) =>
        <div class={`layer-picker ${Object.keys(store.surveys).length ? 'has-surveys' : 'no-surveys'}`} id="layer-picker-outer-wrap" onclick={stopPropagation()}>
            <div class="layer-picker-head"><i class="icon-layers" />Map Layers</div>
            <div class="layer-picker-layers" onscroll={() => layerModel.folders.onScroll()} onclick={() => layerModel.folders.closePopups()}>
                {layerModel.showPlansLayerPicker ? <PlansLayerPicker /> : ''}
                <PlacesLayerPicker />
                {!attrs.hideLinks && <LinksLayer />}
            </div>
            <div class="layer-picker-basemaps">
                {Object.keys(store.surveys).length ? <SurveyPicker /> : null}
                <div class="select-wrapper select-basemap UFtracker-select-basemap">
                    <select class="layer-picker-basemap-select" onchange={e => layerModel.setBasemap(e.target.value)} value={layerModel.state.basemapId}>
                        {Object.keys(constants.basemaps).map(basemapId =>
                            <option key={basemapId} value={basemapId}>{capitalize(basemapId)} Basemap</option>
                        )}
                        <option value="0">No Basemap</option>
                    </select>
                </div>
            </div>
        </div>
};

export default LayerPicker;
