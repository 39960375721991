import store from 'util/data/store';
import {CenterOnMapIcon} from 'views/layer-picker/plans-layer-picker';
import layerModel from 'models/layer-model';
import layerColorModel from 'models/layer-color-model';
import planModel from 'models/plan-model';
import { FolderOptionsEllipsisMenu } from 'views/folder/folder-options-menu';
import stopPropagation from 'util/events/stop-propagation';
import PermissionsIndicator from 'views/permissions/permissions-indicator/permissions-indicator';
import { IncompletePlanWarning } from 'views/form-control/plan';

const LayerItemPlan = {

    oninit: ({state, attrs: {plan}}) => {
        state.planFolderItem = plan;
        state.urn = plan.urn;
        state.root = plan.root;
        state.planFolderItem.name = store.plans[plan.id] ? store.plans[plan.id].title : '';
    },

    view: ({state, attrs: {plan}}) => {

        const {root, urn, planFolderItem} = state;
        plan = store.plans[planFolderItem.id];

        if (!plan) {
            return '';
        }
        const tileset = store.tilesets[plan.tilesetId];
        const starterHex = tileset && tileset.color ? tileset.color.toLowerCase() : null;
        layerColorModel.tilesetId = tileset ? tileset.tilesetId : undefined;

        const onItemClick = (e) => root.menuOpenOn || root.colorPickerOn ? root.closePopups() : plan.status === 'complete' ? layerModel.togglePlan(plan, e) : null;
        const editLockedMessage = planFolderItem.getRestrictionMessage('edit', 'color');

        const planIcon = layerModel.getPlanIcon(plan);

        return <div class={`folder-plan-content ${planFolderItem.state.highlight ? 'highlight' : ''}`} onclick={stopPropagation(() => onItemClick())}>
            {planIcon ? <i class={'toggle-switch ' + layerModel.getPlanIcon(plan)}/> : <IncompletePlanWarning status={plan.status}/>}
            {planFolderItem.root.editingNameOn === planFolderItem.urn
                ? <div class="title-button-wrap"><div class="input-button-wrap">
                    <input value={planFolderItem.name} type="text" onkeydown={(e) => planFolderItem.handleNameInputKeyDown(e)} onclick={stopPropagation()} ondrag={stopPropagation()} oninput={(e) => planFolderItem.handleNameInput(e.target.value)}/>
                    <span class="btn btn-primary btn-pill btn-smallest" onclick={stopPropagation(() => planFolderItem.saveNameIfChanged())}><i class="spinner"/>Done</span> 
                </div>
                <span class="cancel-text-button" onclick={(e) => planFolderItem.stopEditingName(e)}>cancel</span>
                </div>
                : <div class="title-button-wrap">
                    <span class="folder-name">{planFolderItem.name}</span>
                    {plan.status === 'complete' ? <CenterOnMapIcon planId={plan.planId}/> : ''}
                    {starterHex && planModel.supportsColorPicker(tileset) && <div class={`color-swatch ${tileset.color === 'None' ? 'layer-color-off' : ''}`} style={{ backgroundColor: `${starterHex}`}} onclick={(e) => {
                        e.stopPropagation();
                        if (planFolderItem.isEditableByUser) {
                            planFolderItem.currentHex = starterHex === 'none' ? '#fffff1' : starterHex;
                            root.toggleColorPickerOn(urn, e.target);
                        } else {
                            state.showPermissionsBadge = true;
                            m.redraw();
                            setTimeout(() => {
                                state.showPermissionsBadge = false;
                                m.redraw();
                            }, 1000);
                        }
                    }}>{state.showPermissionsBadge ? <PermissionsIndicator lockedMessage={editLockedMessage} showByDefaultFor={1000}/> : ''}</div>                }
                    <FolderOptionsEllipsisMenu folder={planFolderItem}/>
                </div> }
        </div>;

    }
};

export default LayerItemPlan;
