import constants from 'util/data/constants';
import swap from 'legacy/util/swap';
import router from 'uav-router';
import api from 'legacy/util/api/api';
import message from 'views/toast-message/toast-message';
import formatPhone from 'legacy/util/numbers/format-phone';
import appModel from 'models/app-model';
import store from 'util/data/store';
import {appUrl, wsUrl} from 'util/data/env';
import ajax from 'legacy/util/api/ajax';
import initializer from 'util/initializer';
import cache from 'legacy/util/data/cache';
import {initialize} from '@procore/procore-iframe-helpers';
import cookie from 'util/data/cookie';
import authManager from 'managers/auth-manager';
class AuthModel {

    login(email, password, isTrial) {
        this.isLoggingIn = true;
        this.loggedOut = false;
        this.authFailed = false;
        appModel.account.isInvalid = false;
        message.hide();
        m.redraw();
        return authManager.signIn({
            username: email.toLowerCase()
                .trim(),
            password: password.trim()
        }).then(() => {
            if (isTrial) {
                router.set();
            } else {
                router.load();
            }
            this.isLoggingIn = false;
            m.redraw();
        });
    }

    sendResetPassword(email) {
        const resetSocket = new WebSocket(wsUrl);
        resetSocket.addEventListener('open', () => {
            if (resetSocket.readyState === WebSocket.OPEN) {
                resetSocket.send(JSON.stringify({
                    type: 'resetPassword',
                    payload: {
                        userId: email
                    }
                }));
            }
        });
    }

    sendSetPassword(password) {
        ajax(appUrl + `/api/v/3.9/invitation/${router.params.invitationId}`, {
            method: 'PATCH',
            resolve: () => {
                router.set();
            },
            reject: () => {
                console.error('Failed to set user password.');
                message.show('There was an error setting your password. Try again by resetting your password or contact support.', 'warning', false, undefined, 16000);
            },
            data: {
                'secret': router.params.secret,
                'password': password
            }
        });
    }

    logout() {
        this.loggedOut = true;
        appModel.user.reset();
        store.cleanup();
        initializer.runAll();
    }

    showSignUpError(data) {
        if (data &&
            data.payload
            && data.payload.error
            && (data.payload.error.indexOf('UsernameExistsException') !== -1
            || data.payload.error.indexOf('Email address and phone number belong to different') !== -1)) {
            router.set();
            setTimeout(() => message.show('An account with that contact information already exists. Please sign in or contact support.', 'warning'), 2000);
        } else {
            authManager.showErrorToastMessage();
        }
    }

    signUp(userInfo) {
        return new Promise((resolve, reject) => {
            const isTrial = !(router.params.invitationId
                && router.params.secret
                && router.params.projectId
                || router.params.signup
                && router.params.signUpCode);

            userInfo.phoneNumber = formatPhone(userInfo.phoneNumber);

            if (!isTrial) {
                this.promoteUser(userInfo);
                resolve();
                return;
            }

            authManager.socket.connect({anonymous: true}, () => {

                const {
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                    password,
                    companyName
                } = userInfo;

                //Create new user
                api.post.user({
                    password,
                    phoneNumber,
                    emailAddress: email,
                    givenName: firstName,
                    familyName: lastName,
                    chargebeeCustomerId: router.params.customerid,
                    accountName: companyName,
                    baseToolboxId: constants.baseToolboxes.Gas.toolboxId
                },
                (data) => {
                    this.showSignUpError(data);
                    reject();
                })
                    .then(user => {
                        authManager.socket.reset();
                        swap(user && user.status, {
                            trial: () =>
                                this.login(email, password, true)
                                    .finally(() => resolve()),
                            active: () =>
                                this.login(email, password, true)
                                    .finally(() => resolve()),
                            invited: () => {
                                if (router.params.invitationId
                                    && router.params.secret
                                    && router.params.projectId) {
                                    this.promoteUser(userInfo);
                                    resolve();
                                } else {
                                    reject();
                                    router.set();
                                    message('If you were sent an invite link, please use it to sign up.', 'warning');
                                }
                            },
                            default: () => {
                                reject();
                                this.logout();
                                router.set();
                                setTimeout(() => message.show('An account with that contact information already exists. Please sign in or contact support.', 'warning'), 2000);
                            }
                        });
                    });
            });
        });
    }

    showPromoteUserError(msg) {
        if (msg) {
            const lower = msg.toLowerCase();
            if (lower.indexOf('user does not exist') !== -1 || lower.indexOf('failed to reset password') !== -1) {
                msg = 'We\'ve encountered an issue with your account. Please contact unearthsupport@procore.com, and we\'ll get it fixed.';
            } else if (lower.indexOf('usernameexistsexception') !== -1 || lower.indexOf('email address and phone number belong to different users') !== -1) {
                msg = 'An account with that contact information already exists.';
            } else {
                msg = undefined;
            }
        }

        authManager.socket.reset();
        message.show(
            <span>{msg || 'Something went wrong.'}</span>,
            'error',
            true
        );
    }

    promoteUser({
        firstName,
        lastName,
        email,
        phoneNumber,
        password
    }) {
        authManager.socket.connect(null, () => {
            api.patch.user({
                phoneNumber,
                password,
                userId: appModel.user.userId,
                emailAddress: email,
                givenName: firstName,
                familyName: lastName
            }).then(user => {
                appModel.user.setCurrent(user);
                router.set({
                    projectId: router.params.projectId,
                    siteId: router.params.siteId,
                    threadId: router.params.threadId,
                    overlays: router.params.overlays,
                    layers: router.params.layers
                });
            }).catch(data => {
                this.showPromoteUserError(data.payload && data.payload.error);
            });

        }, this.showPromoteUserError);
    }

    changePassword(password, newPassword) {
        return new Promise((resolve, reject) => {
            authManager.socket.send('changePassword', {
                ignoreErrors: true,
                data: {
                    username: appModel.user.userId,
                    password,
                    newPassword,
                    ignoreErrors: true
                },
                reject: data => {
                    if (data.code === 4001) {
                        message.show('Please check your password and try again.', 'error');
                    } else {
                        message.show('Something went wrong. Please try again or contact support.', 'error');
                    }
                    reject();
                },
                resolve
            });
        });
    }

    resetPassword(newPassword) {
        return new Promise((resolve, reject) => {
            const ws = new WebSocket(wsUrl);
            ws.addEventListener('open', () => {
                if (ws.readyState === WebSocket.OPEN) {
                    ws.send(JSON.stringify({
                        type: 'confirmresetpassword',
                        payload: {
                            code: router.params.code,
                            userId: router.params.userId,
                            newPassword
                        }
                    }));
                }
            });

            ws.addEventListener('message', msg => {
                msg = JSON.parse(msg.data);
                if (msg.success) {
                    router.set();
                    resolve();
                } else {
                    message.show(msg.errorMsg || 'Invalid or expired reset code.', 'error');
                    reject();
                }
            });

            ws.addEventListener('error', () => {
                message.show('Something went wrong. Please try again or contact support.', 'error');
                reject();
            });
        });
    }

    onAuthFailure(showMessage = true) {
        this.authFailed = true;
        this.isLoggingIn = false;
        authManager.signOut();
        if (showMessage) {
            message.show('We\'ve encountered an issue signing into your account. Please try again or contact support.', 'error');
        }
        m.redraw();
    }

    /**
    * For Procore oauth via iframe embed: If we're authed within a child window, 
    * notify the parent that auth was successful.  
    */
    notifyParentIfInChildWindow() {
        const context = initialize();
        if (context.window !== context.parentWindow) {
            const ueToken = cache.get('ue_token') || cookie.readCookie('token');
            const ueRefresh = cache.get('ue_refresh') || cookie.readCookie('refreshToken');
            const accessTokenProxy = cookie.readCookie('procore_access_token', true);
            const refreshTokenProxy = cookie.readCookie('procore_refresh_token', true);

            if (ueRefresh) {
                context.authentication.notifySuccess({ueToken, ueRefresh, accessTokenProxy, refreshTokenProxy});
            }
        }
    }

}

export default new AuthModel();
