import formatDate from 'legacy/util/date/format-date';
import DateModel from 'models/date-model';

function getDayOptions(date, min, max, required) {

    const selectedDay = date && date.getDate();

    const options = required ? [] : [<option value="" selected={!selectedDay}></option>];

    for (let i = 1; i <= 31; i++) {

        options.push(<option value={i} selected={i === selectedDay}>{i}</option>);

    }

    return options;

}

function getMonthOptions(date, min, max, required) {

    const selectedMonth = date && date.getMonth();

    const options = required ? [] : [<option value="" selected={!selectedMonth && selectedMonth !== 0}></option>];

    for (let i = 0; i <= 11; i++) {

        options.push(<option value={i} selected={i === selectedMonth}>{formatDate.months[i]}</option>);

    }

    return options;

}

function getYearOptions(date, min, max, required) {

    const minYear = min.getFullYear(),
        maxYear = max.getFullYear(),
        selectedYear = date && date.getFullYear(),
        options = required ? [] : [<option value="" selected={!selectedYear}></option>];

    for (let i = minYear; i <= maxYear; i++) {

        options.push(<option value={i} selected={i === selectedYear}>{i}</option>);

    }

    return options;

}

const DatePicker = {
    oninit({state, attrs: {date, defaultDate, onchange, min, max}}) {
        state.model = new DateModel(defaultDate || date, min, max, onchange);
        state.constrainMessage = '';
        if (min && max) {
            state.constrainMessage = `Date must be between ${formatDate.ddMonthYYY(min)} and ${formatDate.ddMonthYYY(max)}.`;
        } else if (min) {
            state.constrainMessage = `Date must be on or after ${formatDate.ddMonthYYY(min)}.`;
        } else if (max) {
            state.constrainMessage = `Date must be on or before ${formatDate.ddMonthYYY(max)}.`;
        }
    },

    view: ({state: {constrainMessage, model}, attrs: {required, onchange}}) => {
        model.onchange = onchange;
        return <div class="select-date-opts-row">
            <div class="date-input date-input-day">
                <label>Day</label>
                <i class="icon-circle-down dropdown">
                    <select value={model.date ? model.date.getDate() : ''} onchange={e => model.updateDay(e)} class="datepicker">{getDayOptions(model.date, model.min, model.max, required)}</select>
                </i>
            </div>
            <div class="date-input date-input-month">
                <label>Month</label>
                <i class="icon-circle-down dropdown">
                    <select value={model.date ? model.date.getMonth() : ''} onchange={e => model.updateMonth(e)} class="datepicker">{getMonthOptions(model.date, model.min, model.max, required)}</select>
                </i>
            </div>
            <div class="date-input date-input-year">
                <label>Year</label>
                <i class="icon-circle-down dropdown">
                    <select value={model.date ? model.date.getFullYear() : ''} onchange={e => model.updateYear(e)} class="datepicker">{getYearOptions(model.date, model.min, model.max, required)}</select>
                </i>
            </div>
            {model.showConstrainMessage ? <div class="error">{constrainMessage}<span></span></div> : ''}
        </div>;
    }
};

export default DatePicker;
