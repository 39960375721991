import ajax from 'legacy/util/api/ajax';
import constants from 'util/data/constants';
import siteModel from 'models/site-model';
import iModelViewer from 'views/imodel-viewer';

class BentleyModel {

    constructor() {
    }

    isAuthenticated() {
        const localStorageKey = `oidc.user:https://ims.bentley.com:${constants.bentleyClientId}`;
        const oidcData = localStorage.getItem(localStorageKey);
        if (oidcData) {
            const parsedData = JSON.parse(oidcData);
            const expires = parsedData.expires_at * 1000;
            if (expires <= Date.now()) {
                localStorage.removeItem(localStorageKey);
                return null;
            }
            return !!parsedData.access_token;
        }
        return null;
    }

    getThumbnail(iModelId) {
        return new Promise(resolve => ajax(`https://api.bentley.com/imodels/${iModelId}/thumbnail?size=large`, {
            method: 'GET',
            responseType: 'blob',
            headers: {
                authorization: `Bearer ${this.token}`
            },
            resolve: data => {
                resolve(URL.createObjectURL(data));
            },
            reject: error => {
                console.error(error);
            }
        }));
    }

    openInNewTab() {
        window.open(`${constants.iModelViewerPath}?iModelId=${this.iModelId}&bentleyProjectId=${this.bentleyProjectId}`);
        this.closeIModel();
    }

    viewIModel(iModelId = this.iModelId, bentleyProjectId = this.bentleyProjectId) {
        this.iModelId = iModelId;
        this.bentleyProjectId = bentleyProjectId;
        siteModel.view = iModelViewer;
        m.redraw();
    }

    closeIModel() {
        this.iModelId = null;
        this.bentleyProjectId = null;
        siteModel.view = null;
        m.redraw();
    }
}

export default new BentleyModel();
