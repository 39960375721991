import stakeableModel from 'models/stakeable-model';
import {StakeableTitle, LockPinsButton, UndoButton, ClearButton} from 'views/stakeable/header-components';
import CloseButton from 'views/close-button';

// Continue to next step in staking flow
const DoneButton = {
    view: () => {
        if (stakeableModel.state.minStakesReached) {
            return <button class="btn btn-small btn-primary btn-pill btn-done" type="submit" onclick={() => stakeableModel.handleDone()}>
                <span class="btn-label">Done</span>
            </button>;
        } return <button class="btn btn-small btn-primary btn-pill btn-inactive btn-done">
            <span class="btn-label">Done</span>
        </button>;
    }
};


class SurveyHeader {

    view() {

        return <div>
            <div class="stakeable-menu-controls layer-controls-close-view">
                <CloseButton onclick={() => stakeableModel.close()} />
            </div>
            <div class="stakeable-menu-controls layer-controls-layer-title">
                <StakeableTitle />
            </div>
            <div class="stakeable-menu-controls layer-controls-primary-ctas">
                <LockPinsButton />
                <ClearButton />
                <UndoButton />
                <DoneButton />
            </div>
        </div>;

    }

}

export default SurveyHeader;
