import {FILEPICKER_DESCRIPTION_CONTROL_LABEL} from 'constants/managers/toolbox-constants.js';
import randomId from 'util/numbers/random-id';
import constants from 'util/data/constants';
import store from 'util/data/store';
import featureToControl from 'util/interfaces/feature-to-control';
import featureListManager from 'managers/feature-list-manager';
import ToolInterface from 'util/interfaces/tool-interface';
import isMetric from 'util/numbers/is-metric';
import Point from 'util/draw/point';
import siteModel from 'models/site-model';
import formModel from 'models/form-model';
import deepCloneObject from 'util/data/deep-clone-object';
import controlToFeature from 'util/interfaces/control-to-feature';
import assetListManager from 'managers/asset-list-manager';
import oneUpModel from 'models/one-up-model';
import router from 'uav-router';
import message from 'views/toast-message/toast-message';
import popup from 'util/popup';
import mediaListManager from 'managers/media-list-manager';
import toolboxManager from 'managers/toolbox-manager';
import helpers from 'legacy/util/api/helpers';

const COORDINATES_REGEX = /[+-][0-9]+\.[0-9]+/g;

function getMediaCoordinates(media) {

    if (!media || !media.info) {
        return;
    }
    
    const info = media.info;

    if (info.GPS) {
        const gps = media.info.GPS,
            longitude = gps && gps.GPSLongitude,
            latitude = gps && gps.GPSLatitude;

        if (longitude && latitude) {
            return [longitude, latitude];
        }
        
    } else if (info.format && info.format.tags && info.format.tags.location) {
        const gpsLocation = info.format.tags.location;
        try {
            const [[lat], [long]] = gpsLocation.matchAll(COORDINATES_REGEX);
            const coords = [Number(long), Number(lat)];
            return coords;
        } catch {
            return;
        }
    }

}

function getControlOptions(assetId, control = {}) {

    const asset = store.assets[assetId],
        assetExists = asset.updatedDateTime,
        attributes = control.attributes || {},
        maxFiles = assetExists || attributes.maxFiles > 1 ? attributes.maxFiles || 1000 : 1000,
        minFiles = assetExists || attributes.minFiles > 1 ? attributes.minFiles || 1 : 1;

    let accept = null,
        multipleFilesPerAsset = false;

    if (control) {

        if (attributes.maxFiles > 1 || attributes.minFiles > 1) {

            multipleFilesPerAsset = true;

        }

        accept = attributes.accept || accept;

    }

    return {
        uploadOpts: {
            accept,
            maxFiles,
            minFiles
        },
        multipleFilesPerAsset
    };

}


// Special case for media imported through procore integration
function syncProcoreDescriptionToAsset(media, featureType, asset) {
    const tool = featureType.tool;
    const controls = tool.assetForm.controls;
    const controlLabel = FILEPICKER_DESCRIPTION_CONTROL_LABEL;
    if (!media.mediaId || !media.procoreDescription || !controls.find(c => c.fieldName === controlLabel)) {
        return;
    }
  
    const procoreDescription = media.procoreDescription;
    if (asset.properties[controlLabel] !== procoreDescription) {
        asset.properties[controlLabel] = procoreDescription || undefined;
        assetListManager.autosave(asset.contentId, controlLabel);
    }
}

class FilepickerInterface extends ToolInterface {

    constructor(...args) {

        super(...args);

        this.type = 'filepicker';

        this.completedAssets = {}; // Key by flowId
    }

    createAsset(media) {

        const contentId = randomId();

        const coordinates = getMediaCoordinates(media);

        let feature;

        if (coordinates) {

            feature = {
                type: 'Feature',
                id: randomId(),
                geometry: {
                    type: 'Point',
                    coordinates
                },
                properties: Object.assign({}, this.feature.properties, {
                    assetId: contentId,
                    mediaId: media.mediaId
                })
            };

            featureListManager.addFeatures([feature], true);

        }


        const newAsset = deepCloneObject(store.assets[this.assetId]);
        newAsset.properties = {};

        Object.assign(newAsset, {
            featureIds: feature ? [feature.id] : [],
            contentId,
            media: [media],
            mediaIds: [media.mediaId],
            threadId: randomId()
        });

        assetListManager.addToStore(newAsset);

        // Special case for media imported through procore integration
        syncProcoreDescriptionToAsset(media, this.featureType, newAsset);

        featureToControl.sync('filepicker', [media], contentId, this.featureType);

        if (feature) {
            controlToFeature.syncAllFeatureProperties(contentId, feature.id);
        }

        return newAsset;

    }

    callbackOnEachItem(flowId, mediaRecord) {
        const completeRecord = mediaRecord.status === 'active'; // Otherwise, media failed or was cancelled
        if (completeRecord) {
            mediaListManager.addMedia(mediaRecord);
            const asset = this.createAsset(mediaRecord);
            toolboxManager.saveNewAssets([asset]);
            this.completedAssets[flowId].push(asset);
        }
    }

    async launch(mediaSelected) {

        popup.remove();

        let controlOpts;

        if (this.featureType.attributes.linkedControls) {

            const fileControlTypeId = constants.controlTypeNameToId.file,
                fileControl = this.tool.assetForm.controls.find(c => c.controlTypeId === fileControlTypeId);

            if (this.linkedControls.indexOf(fileControl.fieldName) !== -1) {

                controlOpts = getControlOptions(this.assetId, fileControl);

            }

        }

        controlOpts = controlOpts || getControlOptions(this.assetId);
        this.uploadFlowId = randomId();
        this.completedAssets[this.uploadFlowId] = [];

        // Don't run the create asset callback on each item if the the control settings allows for more than one media per asset
        const callbackOnEachItem = controlOpts.multipleFilesPerAsset ? null : (flowId, media) => this.callbackOnEachItem(flowId, media);

        let mediaRecords;
    
        if (mediaSelected) {
            const firstMedia = mediaSelected.shift();
            assets = [this.createAsset(firstMedia)];
            mediaSelected.forEach(media => {
                assets.push(this.createAsset(media));
            });
            return Promise.resolve(assets);
 
        }

        try {
            mediaRecords = await oneUpModel.addUploadFlow({
                flowId: this.uploadFlowId,
                projectId: router.params.projectId,
                name: this.tool.name,
                callbackOnEachItem,
                close: () => this.close(),
                accept: controlOpts.uploadOpts.accept
            });

        } catch (e) {
            message.show(e, 'error');
            this.close();
        }
        

        let assets = [];

        // Special handling if multiple files per asset is allowed
        if (controlOpts.multipleFilesPerAsset && mediaRecords && mediaRecords.length) {
            // filter out failed uploads to handle some files uploading successfully and others not
            mediaRecords = mediaRecords && mediaRecords.length ? mediaRecords.filter(mediaRecord => {
                if (mediaRecord.status === 1) { // failed status in one up upload-manager
                    return false;
                }
                return true;
            }) : [];

            mediaRecords.forEach(media => mediaListManager.addMedia(media));
            const asset = store.assets[this.assetId];
            if (mediaRecords.length > 1) {
                const features = [];
                asset.media = [mediaRecords[0]];
                mediaRecords.forEach(media => {
                    asset.mediaIds = helpers.list(asset.mediaIds);
                    if (!asset.mediaIds.find(mediaId => mediaId === media.mediaId)) {
                        asset.mediaIds.push(media.mediaId);
                    }
                    const coordinates = getMediaCoordinates(media);
                    if (coordinates) {
                        const id = randomId();
                        asset.featureIds = helpers.list(asset.featureIds);
                        asset.featureIds.push(id);
                        features.push({
                            id,
                            geometry: {
                                type: 'Point',
                                coordinates: coordinates
                            },
                            properties: Object.assign({}, this.feature.properties, {
                                mediaId: media.mediaId
                            })
                        });
                    }
                });
                featureListManager.addFeatures(features);
                featureToControl.sync('filepicker', mediaRecords, this.assetId, this.featureType);
                features.forEach(feature => {
                    controlToFeature.syncAllFeatureProperties(this.assetId, feature.featureId || feature.id);
                });
                assets = [asset];
            } else {
                const firstMedia = mediaRecords.shift();
                if (firstMedia) {
                    assets = [this.createAsset(firstMedia)];
                    mediaRecords.forEach(media => {
                        assets.push(this.createAsset(media));
                    });
                }
        
            }
        }

        if (!callbackOnEachItem) {
            return Promise.resolve(assets);
        }

        // If a callback was sent per each item in the flow, we need to customize the resolution so we don't duplicate the assets that were already created
        const completedAssets = this.completedAssets[this.uploadFlowId] || [];
        if (completedAssets.length === 1) {
            formModel.viewAsset(completedAssets[0].contentId, 'Properties', true);
        } else if (completedAssets.length > 1) {
            if (toolboxManager.toolInterface) {
                toolboxManager.toolInterface.close();
            }
            const asset = completedAssets[0];
            const assetTypeName = asset ? ` ${store.assetTypes[asset.assetTypeId].name}` : '';
            
            const messageElement = <div>{completedAssets.length} new{assetTypeName} assets added.</div>;
            message.show(messageElement);
        }

        return Promise.resolve();
    
    }

    edit(_feature) {
        const map = siteModel.map;
        this.feature = featureListManager.getById(_feature.id);
        
        if (!this.feature) {
            return;
        }

        const source = this.feature.featureType.source;

        this.draw = new Point({
            map,
            source,
            metric: isMetric()
        }).edit(this.feature);

        this.draw.onVertexChanged = () => {

            featureListManager.addLatitudeToFeature(this.feature);

            mediaListManager.getMediaAsync(this.feature.properties.mediaId).then(media => {

                featureToControl.sync('filepicker', [media], this.assetId, this.featureType);

                controlToFeature.syncAllFeatureProperties(this.assetId, this.feature.featureId || this.feature.id);

                source.setData(source._data);

                m.redraw();

            });

            this.autosave(this.feature);

        };

        this.draw.onStop = formModel.onEditStop;

    }

}

FilepickerInterface.getMediaCoordinates = getMediaCoordinates;
FilepickerInterface.getControlOptions = getControlOptions;

export default FilepickerInterface;
