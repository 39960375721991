import FilterTypeModel from 'models/table/filter-types/filter-type-model';
import filterMenus from 'views/table/filter-menu/filter-menus';

const CUSTOM_DATE_RANGE_INDEX = 5;

class DateRangeModel extends FilterTypeModel {

    init() {
        super.init({type: 'dateRange', specialProperties: ['selectedRadioIndex', 'rangeModel']});

        if (!this.options) {
            this.options = ['Any Value', 'is null', filterMenus.numberFilter.allValues, filterMenus.numberFilter.customRange];
        }
    }

    selectDateRangeRadio(i) {
        super.selectRadio(i);
    }

    rangeUpdated() {
        if (this.state.selectedRadioIndex === CUSTOM_DATE_RANGE_INDEX) {
            this.onFilterUpdate();
        }
    }
}

export default new DateRangeModel();
