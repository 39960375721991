import store from 'util/data/store';
import constants from 'util/data/constants';
import appModel from 'models/app-model';

export function assetIdToProjectId(assetId) {
    const asset = store.assets[assetId];
    if (!appModel.project.isMetaProject || !asset) {
        return undefined;
    }
    const assetFormId = store.assetTypeToFormId[asset.assetTypeId],
        assetForm = store.assetForms[assetFormId],
        projectControlTypeId = constants.controlTypeNameToId.project,
        projectControl = assetForm.controls.find(control => control.controlTypeId === projectControlTypeId);
    return asset.properties[projectControl.fieldName];
}
