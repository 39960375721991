import accountManager from 'managers/account-manager';

export default {
    view: () => <div class="side-nav-popup help-box">
        <div class="side-nav-popup-title">Support Options</div>
        <div class="support-links left">
            <div class="side-nav-popup-row"><span class="support-link-label">Wiki</span> <a href={'https://' +  accountManager.accountWiki} target="_blank" rel="noopener noreferrer" class="UFtracker-help-menu-wiki">{accountManager.accountWiki}</a></div>
            {!accountManager.isWhiteLabelAccount ? <div class="side-nav-popup-row"><span class="support-link-label">Call</span> <a href={'tel:' + accountManager.accountPhone} class="UFtracker-help-menu-call">{accountManager.accountDisplayPhone}</a></div> : <div></div>}
            <div class="side-nav-popup-row"><span class="support-link-label">Email</span> <a href={'mailto:' + accountManager.accountEmail} class="UFtracker-help-menu-email">{accountManager.accountEmail}</a></div>
        </div>
    </div>

};
