function deepCloneObject(obj) {

    if (obj && Object.getPrototypeOf(obj) === Object.prototype) {

        const newObj = {};

        Object.keys(obj).forEach(key => {

            newObj[key] = deepCloneObject(obj[key]);
            
        });

        return newObj;

    }

    if (Array.isArray(obj)) {

        return obj.map(deepCloneObject);

    }

    return obj;

}

export default deepCloneObject;
