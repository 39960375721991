import bentleyModel from 'models/bentley-model';
import constants from 'util/data/constants';

const iModelViewer = {
    view: () => <div class="media-viewer imodel-viewer">
        <div class="media-viewer-stage">
            <i class="icon-window" onclick={() => bentleyModel.openInNewTab()} />
            <i class="icon-close" onclick={() => bentleyModel.closeIModel()} />
            <div class="file-viewer">
                <div id="file-viewer-stage" key="file">
                    <iframe src={`${constants.iModelViewerPath}?iModelId=${bentleyModel.iModelId}&bentleyProjectId=${bentleyModel.bentleyProjectId}`} />
                </div>
            </div>
        </div>
    </div>
};

export default iModelViewer;
