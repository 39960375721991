import router from 'uav-router';
import userManager from 'managers/user-manager';

function findLanguage() {

    if (navigator.languages !== undefined) {

        return navigator.languages[0];

    }
    if (navigator.language !== undefined) {

        return navigator.language;

    }
    if (navigator.browserLanguage !== undefined) {

        return navigator.browserLanguage;

    }
    if (navigator.systemLanguage !== undefined) {

        return navigator.systemLanguage;

    }
    if (navigator.userLanguage !== undefined) {

        return navigator.userLanguage;

    }

    return 'en-US';
}

const isMetric = !{
    'en': true,
    'en-us': true,
    'my': true
}[findLanguage().toLowerCase()];

export default () => router.params.metric === 'true' || userManager && userManager.measurementSystem === 'metric' || isMetric;
