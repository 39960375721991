import initializer from 'util/initializer';
import Modal from 'views/modal';
import Loader from 'views/loader';
import ToastMessage from 'views/toast-message/toast-message';
import MobileLauncher from 'views/mobile-launcher';
import OneUp from 'views/one-up';
import Tip from 'views/tip';
import authManager from 'managers/auth-manager';
import appModel from 'models/app-model';
import {datadogRum} from '@datadog/browser-rum';
import {datadogLogs} from '@datadog/browser-logs';

let updateCallbacks = [];

initializer.add(() => {

    updateCallbacks = [];

}, 'app');

const App = {

    oncreate: () => {
        // If there are any unanswered requests in the pool, log an error w/that data
        window.addEventListener('beforeunload', (event) => {
            if (authManager.socket && authManager.socket.requestPool && Object.values(authManager.socket.requestPool.pool).length) {
            // logs to datadog "ux monitoring" session data
                datadogRum.addError(new Error('Unanswered_requests_in_pool_before_unload'), {
                    event,
                    data: authManager.socket.requestPool.pool
                });
                // logs to datadog "logs"
                datadogLogs.logger.error('Unanswered_requests_in_pool_before_unload', { data: authManager.socket.requestPool.pool });
            }
        });
    },

    onupdate: () => {
        updateCallbacks.forEach(fn => fn());
        updateCallbacks = [];
    },

    view: () => {
        return <>
            <Tip />
            <ToastMessage/>
            {m(appModel.view)}
            <Modal />
            <Loader />
            <MobileLauncher />
            <OneUp />
        </>;
    }

};

// Add a callback using App.onNextRender to run some code after
// the next Mithril redraw
App.onNextRender = callback => updateCallbacks.push(callback);

export default App;
