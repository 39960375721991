// View Keys
const DATE_VIEW_KEY = 'dateCreatedFilter';
const LAST_UPDATED_VIEW_KEY = 'lastUpdatedFilter';
const NAME_VIEW_KEY = 'nameFilter';
const CATEGORY_VIEW_KEY = 'categoryFilter';
const ASSET_TYPE_VIEW_KEY = 'assetTypeFilter';
const ADDED_BY_VIEW_KEY = 'addedByFilter';
const LAST_UPDATED_BY_VIEW_KEY = 'lastUpdatedByFilter';
const PLACES_AND_LEVELS_VIEW_KEY = 'boundaryFilter';
const BOUNDARY = 'boundaryFilter';
const UNEARTH_ID_KEY = 'unearthIdFilter';
const LINKS_VIEW_KEY = 'linksFilter';

// Field Names
const NAME = 'name';
const CATEGORY = 'category';
const DATE = 'createdDateTime';
const UPDATED = 'updatedDateTime';
const TYPE = 'contentType';
const ADDED_BY = 'addedBy';
const LAST_UPDATED_BY = 'lastUpdatedBy';
const PLACES = 'location';
const UNEARTH_ID = 'unearthId';
const LINKS = 'links';


const filterConstants = {

    daysByIndex: [undefined, 1, 7, 14, 30],

    // View Keys
    DATE_VIEW_KEY,
    LAST_UPDATED_VIEW_KEY,
    NAME_VIEW_KEY,
    CATEGORY_VIEW_KEY,
    ASSET_TYPE_VIEW_KEY,
    ADDED_BY_VIEW_KEY,
    LAST_UPDATED_BY_VIEW_KEY,
    PLACES_AND_LEVELS_VIEW_KEY,
    UNEARTH_ID_KEY,
    LINKS_VIEW_KEY,
    BOUNDARY,

    // Field Names
    NAME,
    CATEGORY,
    DATE,
    UPDATED,
    TYPE,
    ADDED_BY,
    LAST_UPDATED_BY,
    PLACES,
    UNEARTH_ID,
    LINKS,

    nameToViewKey: {
        [NAME]: NAME_VIEW_KEY,
        [CATEGORY]: CATEGORY_VIEW_KEY,
        [DATE]: DATE_VIEW_KEY,
        [UPDATED]: LAST_UPDATED_VIEW_KEY,
        [TYPE]: ASSET_TYPE_VIEW_KEY,
        [ADDED_BY]: ADDED_BY_VIEW_KEY,
        [LAST_UPDATED_BY]: LAST_UPDATED_BY_VIEW_KEY,
        [PLACES]: PLACES_AND_LEVELS_VIEW_KEY,
        [UNEARTH_ID]: UNEARTH_ID_KEY,
        [LINKS]: LINKS_VIEW_KEY
    },

    ASC: 'asc',
    DESC: 'desc',
    DEFAULT_SORT: {order: 'desc', isCommon: true, value: 'updatedDateTime', type: 'lastUpdatedFilter'},

    mapFilterOpts: {
        'NONE': 0,
        'IN_VIEW': 1,
        'DRAW_BOUNDARY': 2,
        'MAPPED': 3,
        'UNMAPPED': 4,
        'TAGGED_PLACE': 5
    }
    
};

export default filterConstants;
