/*
 * Provides an alternative syntax for switch functions:
 * let result = switch(value, {
 *   key1: () => handleKey1('arg')
 *   key2: handleKey2
 * });
 */
export default (key, functions) => {

    if (functions[key]) {

        return functions[key]();

    } else if (functions.default) {

        return functions.default();

    }
    
};
