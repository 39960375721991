import { styleControls } from 'constants/models/draw-palette-constants';
import drawPaletteModel from 'models/draw-palette-model';


const Range = {

    oninit({ state, attrs: { propKey } }) {
        state.default = drawPaletteModel.transformToDisplay(drawPaletteModel.defaultValue(propKey));
    },

    oncreate({ state, dom, attrs }) {
        const range = dom.firstElementChild;
        const propKey = attrs.propKey;
        range.value = drawPaletteModel.activeFeature.properties[propKey] || state.default;
        range.nextElementSibling.value = range.value;
    },

    view: vnode => {
        const { state, attrs: { units, propKey, min = 0, max = 1, step = 0.1 } } = vnode;
        const transformToDisplayFactor = propKey === styleControls.LINE_OPACITY || propKey === styleControls.FILL_OPACITY ? 100 : undefined;
        const handleInput = e => {
            const value = Math.max(Math.min(Number(e.target.value), max), min);
            drawPaletteModel.handleInput(propKey, drawPaletteModel.transformToSave(value, units, transformToDisplayFactor));
            e.redraw = false;
        };
        
        const value = drawPaletteModel.activeFeature.properties.hasOwnProperty(propKey) ? drawPaletteModel.activeFeature.properties[propKey] : state.default;
        return <div class="control-group range-control">
            <div class="range-input-wrap">
                <input class="range-text" type="number" step={step} value={drawPaletteModel.transformToDisplay(value, units, step, transformToDisplayFactor)} oninput={e => handleInput(e)} onblur={handleInput} />
                {units && <span class="number-unit">{units}</span>}
            </div>
            <div class="range-slider-wrap">
                <input class="range-input" type="range" min={min} max={max} step={step} value={drawPaletteModel.transformToDisplay(value, units, step, transformToDisplayFactor)} oninput={handleInput} />
                <span class="min-max-row">
                    <span class="min">{min}</span>
                    <span class="max">{max}</span>
                </span>
            </div>
        </div>;
    }
};

export default Range;
