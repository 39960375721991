import siteModel from 'models/site-model';

const Sidebar = {
    view: () =>
        <div class="sidebar">
            {m(siteModel.sidebar)}
        </div>
};

export default Sidebar;
