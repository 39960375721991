import onBodyClick from 'legacy/util/dom/on-body-click';
import initializer from 'util/initializer';
import Component from 'legacy/util/component';

function getPosition(el) {

    const box = el.getBoundingClientRect();

    const body = document.body;

    const docEl = document.documentElement;

    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;

    const clientTop = docEl.clientTop || body.clientTop || 0;

    const top = Math.round(box.top + scrollTop - clientTop);

    return {
        top,
        bottom: top + el.offsetHeight,
        right: box.right,
        left: box.left
    };

}

/*
 * "Popups" are a leaflet construct, and are
 * associated  with specific layers on the map.
 * "Popovers," by contrast, are the dropdown
 * bubbles that we use in our header and toolbar.
 */

let popover;

class Popover extends Component {

    hide() {

        popover = null;

        this._el.classList.remove('active');

        setTimeout(() => this._el.remove(), 200);

        if (this.onclose) {

            this.onclose();

        }

    }

    realign(customOffset) {

        if ('top' in customOffset) {

            const top = customOffset.top;

            this._el.style.top = top + 'px';

        }

        if ('left' in customOffset) {

            if (this.left) {

                const width = this.content._el ? this.content._el.offsetWidth : this.content.offsetWidth;

                this._el.style.left = -width + customOffset.left + 'px';

            } else {

                this._el.style.left = customOffset.left + 'px';

            }

        }

    }

    render(html) {

        this.e.stopPropagation();

        if (popover) {

            popover.hide();

        }

        const customOffset = {
            top: 0,
            left: 0
        };

        Object.assign(customOffset, this.offset);

        this.content = typeof this.content === 'function' ? this.content() : this.content;

        const position = getPosition(this.target || this.e.currentTarget);

        const offset = parseInt((position.right - position.left) / 2);

        this.top = this.verticalAlign ? position.bottom + 6 + customOffset.top : undefined;

        html`
        <div class="popover active has-close ${this.cssClass}" ontouchstart="${this.stopPropagation}" onmousedown="${this.stopPropagation}">
            ${this.content}
        </div>`;

        this._el._close = Popover.hide;

        popover = this;

        document.getElementById('app').appendChild(this._el);

        this._el.style.top = this.top + 'px';

        if (this.limitHeight) {

            this._el.style.maxHeight = `calc(100% - ${this.top - 64}px`;

        }

        if (this.left) {

            const width = this.content._el ? this.content._el.offsetWidth : this.content.offsetWidth;

            this._el.style.left = position.left - width + customOffset.left + 'px';

        } else {

            this._el.style.left = position.left + offset + customOffset.left + 'px';

        }

        onBodyClick.once(() => this.hide());

    }

}

function forceClose(e) {

    if (popover) {

        popover.hide();

    }

    if (e) {

        e.target.removeEventListener('mouseup', forceClose);

        e.target.removeEventListener('touchend', forceClose);

    }

}

Popover.hide = e => {

    if (popover) {

        /**
         * If the clicked element is an inactive popover toggle (or a child of one),
         * that means the popover needs to move instead of closing,
         * so we shouldn't run the closing animation.
         */
        if (e) {

            if (e.target.matches('.popover ' + e.target.tagName)) {

                e.target.addEventListener('mouseup', forceClose);

                e.target.addEventListener('touchend', forceClose);

                return;

            }

            if (e.target.classList.contains('.popover-toggle') || e.target.matches('.popover-toggle ' + e.target.tagName)) {

                const popoverToggle = e.target.closest('.popover-toggle');

                if (popoverToggle && !popoverToggle.classList.contains('active')) {

                    return;

                }

            }

        }

        popover.hide();

    }

};

Popover.realign = (customOffset) => {

    if (popover) {

        popover.realign(customOffset);

    }

};

initializer.add(Popover.hide, 'popover');

export default Popover;
