const SubmitButton = {
    view({state, attrs: {disabled, onclick, text}}) {
        return <div class={`submit-button ${state.spinning ? 'spinning' : ''}`}>
            <button class={`btn btn-primary btn-pill ${disabled ? 'disabled' : ''} ${state.pressed ? 'pressed' : ''}`} onclick={() => {
                state.spinning = true;
                onclick();
            }} ontouchstart={() => {
                state.pressed = !state.pressed;
            }} ontouchend={() => {
                state.pressed = !state.pressed;
            }}>
                <div class="spinner"></div>
                <span class="btn-label">{text}</span>
            </button>
        </div>;
    }
};

export default SubmitButton;
