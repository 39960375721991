import MediaOptions from 'views/media-options';

const VideoViewer = {
    view: ({attrs: {media}}) => 
        <div class="video-viewer">
            <MediaOptions mediaId={media.mediaId} isFullScreen={true} key="opts" />
            <video controls class="video-viewer-stage" src={media.videoViewerSrcUrl} key={media.mediaId} />
        </div>
};

export default VideoViewer;
