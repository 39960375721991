import debounce from 'util/events/debounce';
import AutoCompleteModel from 'models/auto-complete-model';
import {getNormalizedKey} from 'util/events/get-normalized-key';

class FilterSelectModel extends AutoCompleteModel {

    constructor(types, onselect, selected) {
        super(types, onselect, selected);
        this.input = undefined; // Element passed in from view
        this.filterSelectOptions = debounce(this.filterSelectOptions.bind(this));    
    }

    resetInput() {
        if (this.input) {
            this.input.value = this.selectedOption ? this.selectedOptionsText : '';
        }
        super.resetInput();
    }

    _handleKeyDown(e) {
        const key = getNormalizedKey(e.key);
        switch (key) {
        case 'ArrowDown':
            e.preventDefault(); // Dont arrow through text input
            return this.onArrowDownKey();
        case 'ArrowUp':
            e.preventDefault();
            return this.onArrowUpKey();
        case 'Enter':
            return this.onEnterKey();
        default:
            if (!this.optionsVisible) {
                this.showOptions(); // Make sure options are visible if user is typing
            }
            return this.filterSelectOptions(e);
        }
    }

    onEnterKey() {
        // If the options aren't open, just open it.
        if (!this.optionsVisible) {
            return this.showOptions();
        }

        // User can clear selection by either selecting the "clear" option or deleting all the input
        if (this.focusedIndex === -1 || !this.input.value && this.selectedOption) {
            return this.clearSelection();
        }

        // Only run select callback if a valid option is in focus AND is not already selected.
        const currentOption = this.focusedOption.value;

        if (currentOption && currentOption !== this.selectedOption) {
            if (this.focusedOption.isDisabled) {
                return super.selectedDisabledOption(null, this.focusedOption.value);
            }
            this.selectedOption = currentOption;
            this.handleSelect(currentOption);
        }
        this.resetInput();
    }

    clearSelection() {
        if (this.selectedOption) {
            this.selectedOption = undefined;
            this.handleSelect(undefined);
        }
        this.resetInput();
    }

    filterSelectOptions(e) {
        const filterTerm = e.target.value.trim().toLowerCase();

        let options = this.allOptions;
        if (filterTerm) {
            const queries = filterTerm.toLowerCase().split(' ');
            // Only searching for the term at the beginning of the word
            options = this.allOptions.filter(option => option.terms.find(term => queries.find(query => term.startsWith(query))));
        }
        this.cachedFocusedIndex = null;
        this.filteredOptions = options;
        m.redraw();
    }

}

export default FilterSelectModel;
