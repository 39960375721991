export const mapboxStylePropertyToText = {
    'line-color': 'map feature color', 
    'line-opacity': 'map feature opacity',
    'line-width': 'map feature width',
    'line-dash-array': 'map feature border style',
    'icon-image': 'map feature image',
    'icon-size': 'map feature image size',
    'icon-rotate': 'map feature rotation',
    'fill-color': 'map feature color',
    'fill-opacity': 'map feature opacity',
    'rotation': 'map feature rotation',
    'text-field': 'text label',
    'text-color': 'text label color',
    'text-halo-color': 'text label stroke color',
    'text-size': 'text label size',
    'text-stroke-width': 'text label stroke width',
    'text-offset': 'text label offset'
};
