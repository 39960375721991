import Cached from 'util/data/cached';
import tableModel from 'models/table/table-model';
import store from 'util/data/store';
import filterConstants from 'constants/models/table/filter-constants';

const HEADER = 'hd_';

/**
 * Logic and cache for table styles related to columns & rows based on filtering and sorting states.
 */
class TableStylesModel {

    constructor() {
        this.resetCache();
    }

    resetCache() {
        this.cache = new Cached();
    }

    /**
     * Returns styles for current filter in filterModel.opts
     */
    get currentHeader() {
        if (!tableModel.projectView.filters.opts || !tableModel.projectView.filters.opts.controlType) {
            return '';
        }
        if (tableModel.projectView.filters.opts.sharedColumnName) {
            return this.getSharedColumnHeaderStyles(tableModel.projectView.filters.opts.sharedColumnName);
        }
        return this.getTypeSpecificHeaderStyles(tableModel.projectView.filters.opts.assetTypeId, tableModel.projectView.filters.opts.controlType.fieldName);
    }

    getTypeSpecificHeaderStyles(assetTypeId, controlTypeName) {
        const cacheKey = HEADER + assetTypeId + controlTypeName;
        return this.cache.get(cacheKey, () => {
            const isFiltered = tableModel.projectView.isFilterInUse(assetTypeId, controlTypeName);
            const isSorted = this.getColumnSortingCss(assetTypeId, controlTypeName);
            const isVisible = tableModel.isColumnVisible({assetTypeId, controlTypeName});

            return this.headerStatesToStyleClasses(isFiltered, isSorted, isVisible);
        });
    }

    getSharedColumnHeaderStyles(sharedColumnName) {
        const cacheKey = HEADER + 'shared' + sharedColumnName;
        return this.cache.get(cacheKey, () => {
            const sharedColumn = tableModel.getSharedColumn(sharedColumnName);
            const isFiltered = sharedColumn.isFiltered;
            const isSorted = this.getColumnSortingCss(null, sharedColumn.controlType.fieldName, sharedColumnName);
            const isVisible = tableModel.isColumnVisible({sharedColumnName});

            return this.headerStatesToStyleClasses(isFiltered, isSorted, isVisible);
        });
    }

    getCommonColumnHeaderStyles(fieldName) {
        if (tableModel.loadingTable || tableModel.loadingFresh) {
            return '';
        }
        const cacheKey = HEADER + 'common' + fieldName;
        return this.cache.get(cacheKey, () => {
            const isFiltered = tableModel.projectView.common.isFiltered(fieldName);
            const viewKey = filterConstants.nameToViewKey[fieldName];
            const isSorted = tableModel.projectView.common.isSortDisabled(fieldName) ? '' : tableModel.projectView.filters.isCommonColumnSorted(viewKey);

            return this.headerStatesToStyleClasses(isFiltered, isSorted, true);
        });
    }

    get allCommonColumnHeaderStyles() {
        return {
            name: this.getCommonColumnHeaderStyles('name'),
            category: this.getCommonColumnHeaderStyles('category'),
            type: this.getCommonColumnHeaderStyles('contentType'),
            addedDateTime: this.getCommonColumnHeaderStyles('createdDateTime'),
            lastUpdated: this.getCommonColumnHeaderStyles('updatedDateTime'),
            addedBy: this.getCommonColumnHeaderStyles('addedBy'),
            lastUpdatedBy: this.getCommonColumnHeaderStyles('lastUpdatedBy'),
            places: this.getCommonColumnHeaderStyles('location'),
            unearthId: this.getCommonColumnHeaderStyles('unearthId'),
            links: this.getCommonColumnHeaderStyles('links'),
            boundary: this.getCommonColumnHeaderStyles('boundary')
        };
    }

    headerStatesToStyleClasses(isFiltered, isSorted, isVisible) {
        return `${isFiltered ? `is-filtered ${!isVisible ? ' allow-toggle-on ' : ''}` : ''}${isSorted ? `is-sorted ${tableModel.projectView.filters.sortField.order}` : ''}`;
    }

    getColumnSortingCss(assetTypeId = tableModel.projectView.filters.opts.assetType.assetTypeId, controlTypeName, sharedColumnName) {
        if (!tableModel.projectView.filters.sortField  || tableModel.projectView.filters.sortField.isCommon) {
            return '';
        }
        if (!controlTypeName) {
            if (!tableModel.projectView.filters.opts || !tableModel.projectView.filters.opts.controlType) {
                return '';
            }
            controlTypeName = tableModel.projectView.filters.opts.controlType.fieldName;
        }
        // For tag columns combining multiple assetTypes
        const sortedColumnName = tableModel.projectView.filters.sortField.sharedColumnName;
        if (sortedColumnName && sharedColumnName === sortedColumnName && assetTypeId === null) {
            return `is-sorted ${tableModel.projectView.filters.sortField.order}`;
        }
        if (!sortedColumnName && tableModel.projectView.filters.sortField.assetTypeId === assetTypeId && tableModel.projectView.filters.sortField.controlName === controlTypeName) {
            return `is-sorted ${tableModel.projectView.filters.sortField.order}`;
        }
        return '';
    }

    getRowSortingCss(assetId) {
        if (!tableModel.projectView.filters.sortField) {
            // No sorting is applied to any row:
            return 'unsorted';
        }
        const asset = store.assets[assetId];
        const assetTypeId = asset.assetTypeId;
        const assetTypeIds = tableModel.projectView.filters.opts && tableModel.projectView.filters.opts.assetTypeIds ? tableModel.projectView.filters.opts.assetTypeIds : [];
        const sortCss = `is-sorted ${tableModel.projectView.filters.sortField.order}`;

        // Only sorted if assetType includes sorted control AND the asset has a value for it:
        if (tableModel.projectView.filters.sortField.isCommon) {
            return 'common-sort-field';
        } else if (assetTypeId === tableModel.projectView.filters.sortField.assetTypeId && tableModel.projectView.filters.sortField.controlName === 'Capture Date') {
            return 'common-sort-field';
        } else if (tableModel.projectView.filters.sortField.sharedColumnName && assetTypeIds.includes(assetTypeId) && asset.properties[tableModel.projectView.filters.sortField.controlName]) {
            return sortCss + ' custom-sort-field shared-sort-field';
        } else if (assetTypeId === tableModel.projectView.filters.sortField.assetTypeId && asset.properties.hasOwnProperty(tableModel.projectView.filters.sortField.controlName) && asset.properties[tableModel.projectView.filters.sortField.controlName] !== null) {
            return sortCss + ' custom-sort-field';
        }
        return 'no-sort-style';
    }
}

export default TableStylesModel;
