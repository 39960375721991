import savedViewsModel from 'models/saved-views-model';

const Details = {

    view: ({attrs: {projectView}}) => {
        if (projectView) {
            const configAsText = savedViewsModel.configToText(projectView);
            return <div class="saved-view-details">
                <label>Details:</label>
                {configAsText.warningDetailText && <div class="warning-detail">{configAsText.warningDetailText}</div>}
                {configAsText.warningBoundaryText && <div class="warning-detail">{configAsText.warningBoundaryText}</div>}
                <div class="saved-view-details-overview">
                    <div class="detail-row"><i class="icon-properties-table"/><span>{configAsText.visiblePropsCount} of {configAsText.totalColumnCount} Properties are visible</span></div>
                    <div class="detail-row"><i class="icon-configure"/><span>{configAsText.propsFilteredCount} of {configAsText.totalColumnCount} Properties have filters applied</span></div>
                </div>
                <div class="saved-view-details-itemized">
                    {configAsText.propertiesFilters.map(filter =>
                        <div class="detail-row">
                            <span class="label">{filter.label}: </span>
                            <span class="values">{filter.values.map((value, i) => <span class="value">
                                {i > 0 ? <span class="value-or"> or </span> : ''}<span class={`${value.cssClass || ''}`}>{value.text}</span>
                            </span>)}
                            </span>
                        </div>
                    )}
                </div>
                {configAsText.hiddenFilteredPropertyText && <div class="hidden-filters-footnote">{configAsText.hiddenFilteredPropertyText}</div>}
            </div>;
        }
        return '';
    }

};

export default Details;
