import store from 'util/data/store';
import appModel from 'models/app-model';
import planModel from 'models/plan-model';
import PermissionsIndicator from 'views/permissions/permissions-indicator/permissions-indicator';
import createLayerFlow from 'flows/create-layer/create-layer-flow';
import restrictions from 'util/permissions/restriction-message';
import router from 'uav-router';

const LayerOptionsMenu = {
    view: ({state, attrs: {assetId, layerId}}) => {
        const plan = store.plans[layerId];
        const asset = store.assets[assetId];
        
        let editLockedMessage, deleteLockedMessage;

        const layer = store.plans[layerId];
        const tileset = store.tilesets[layer.tilesetId];
        const isVector = tileset && tileset.tilesetType === 'external_vector';

        if (!appModel.user.permissions.canEditContent(asset)) {
            editLockedMessage = restrictions.message(appModel.user, 'edit', asset.assetTypeId);
        }
        if (!appModel.user.permissions.canDeleteContent(asset)) {
            deleteLockedMessage = restrictions.message(appModel.user, 'delete', asset.assetTypeId);
        }

        
        const editMapLayerText = plan.status !== 'complete' ? 'Add Layer to Map' : plan.isRigid ? 'Edit Map Layer' : 'Edit Match Points';
        const editMapLayerIcon = plan.status !== 'complete' || plan.isRigid ? 'icon-layers' : 'icon-marker2';

        return <div key={layerId} class="layer-options-menu">
            {isVector ? '' : <div class={`menu-option${state.isActive ? ' active' : ''}${editLockedMessage ? ' disabled' : ''}`} onclick={() => editLockedMessage ? null : plan.isRigid ? createLayerFlow.startPositionLayer({assetId, plan}) : router.set({projectId: router.params.projectId, siteId: router.params.siteId, view: 'layer', planId: plan.planId})}>
                <span><i class={editMapLayerIcon}/>{editMapLayerText}<PermissionsIndicator lockedMessage={editLockedMessage}/></span>
            </div>}
            <div class={`menu-option remove-link${deleteLockedMessage ? ' disabled' : ''}`} onclick={deleteLockedMessage ? null : () => planModel.handleDeletePlan(layerId)}>
                <i class="icon-trash"/>Delete Map Layer<PermissionsIndicator lockedMessage={deleteLockedMessage}/></div>
        </div>;
    }
};

export default LayerOptionsMenu;
