import savedViewsModel from 'models/saved-views-model';
import appModel from 'models/app-model';
import capitalize from 'util/data/capitalize';

const ChildProjectSelector = {
    oninit: ({state}) => {
        state.selectedProjectId = 0;
        state.selectedProjectViewId = 0;
    },

    view: ({state}) =>  {
        const projects = savedViewsModel.childProjects;
        const projectViews = savedViewsModel.getProjectViewsByProjectId(state.selectedProjectId);
        return <div class="child-project-selector">
            <div className="saved-views-child-project-search">
                <ChildProjectSearchBox/>
            </div>
            <ul class={`child-project-list${state.selectedProjectId !== 0 ? ' child-project-selected' : ''}`} onscroll={savedViewsModel.paginator.onscroll}>
                {/* TODO - Need accountId arg for full count, not yet supported by API <span class="side-nav-popup-subtitle"> {savedViewsModel.viewCount} Saved Project Filter Sets</span> */}
                {projects.map(project =>
                    <li class={`select-option${state.selectedProjectId === project.projectId ? ' selected-project' : ''}`} onclick={() => {
                        state.selectedProjectId = state.selectedProjectId === project.projectId ? 0 : project.projectId;
                        state.selectedProjectViewId = 0;
                    }}>{project.name} {savedViewsModel.isSearchingProjects || state.selectedProjectId === project.projectId && <span class="child-project-id">ID: {project.projectId}</span>}
                        {state.selectedProjectId === project.projectId ? <div onclick={e => e.stopPropagation()}className="select-wrapper">
                            <select onchange={e => {
                                e.stopPropagation();
                                state.selectedProjectViewId = e.target.value;
                            }}>
                                <option value={0} disabled selected={state.selectedProjectViewId === 0}>Select a Saved View</option>
                                <option value={''}>Default [Last Viewed]</option>
                                {projectViews && Object.keys(projectViews).length && 
                                    projectViews.map(projectView => <option value={projectView.projectViewId}>{projectView.name}</option>)}
                            </select>
                            <div class="btn-wrapper"><span class={`btn btn-smaller btn-pill btn-primary${state.selectedProjectViewId === 0 ? ' disabled' : ''}`} onclick={() => savedViewsModel.selectProject(state.selectedProjectId, state.selectedProjectViewId ? state.selectedProjectViewId : undefined)}>Go</span></div>
                        </div> : ''}
                    </li>
                )}
            </ul>
        </div>;
    }
};

const ChildProjectSearchBox = {
    oncreate: ({dom}) => {
        savedViewsModel.projectSearchInput = dom.firstElementChild;
    },

    view: () => {
        return <div class="child-projects-search-box" onclick={(e) => e.stopPropagation()}>
            <input type="text" oninput={(e) => savedViewsModel.searchChildProjects(e.target.value)} placeholder="Type to filter ..."/>
            {savedViewsModel.isSearchingProjects && (savedViewsModel.childProjects.length || savedViewsModel.paginator.isComplete ? <button class="clear-search-btn btn-pill btn-secondary btn btn-smallest" onclick={() => savedViewsModel.clearChildProjectSearch()}>Clear</button>
                : <i class="spinning teal spinner"/>)}
        </div>;
    }
};

const SavedViewsMetaProjectSelector = {
    oninit: ({state}) => {
        savedViewsModel.loadProjects();
        state.selectedProjectId = 0;
        state.selectedProjectViewId = 0;
        state.showProjects = false;
        state.siteTermSingular = capitalize(appModel.toolbox.siteTermSingular);
    },

    onremove: () => {
        savedViewsModel.clearChildProjectSearch();
    },
    
    toggleProjectList: (e, state) => {
        e.stopPropagation();
        state.showProjects = !state.showProjects;
    },

    view: ({state}) => {
        return <div class="saved-views-meta-project-selector" onclick={(e) => e.stopPropagation()}>
            <div class="project-selector-wrap">
                <div onclick={(e) => state.toggleProjectList(e, state)} class={`project-options-toggle select ${state.showProjects ? 'open' : 'closed'}`}>
                    <div class="select-title project-option side-nav-popup-subtitle">Saved Views by {state.siteTermSingular} <i class="spinner"/></div>
                </div>
                {state.showProjects && <ChildProjectSelector /> }
            </div>
        </div>;
    }
};

export default SavedViewsMetaProjectSelector;
