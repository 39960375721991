import CropModel from 'models/crop-model';

// Navigate back to pinning view.
const CropCloseButton = {
    view: () => {
        const isLoaded = CropModel.state.isLoaded;
        return <button class={`btn close-button btn-circle ${!isLoaded ? ' btn-inactive' : ''}`} type='submit'
            onclick={isLoaded && (() => CropModel.quit())}><span class="btn-label"><i class='icon-close'/></span></button>;
    }
};

// Reset all changes
const CropResetButton = {
    view: () =>  {
        const isResetable = CropModel.state.isResetable;
        return <button className={`btn btn-pill btn-small btn-not-primary btn-reset ${!isResetable ? ' btn-inactive' : ''}`} type='submit'
            onclick={isResetable && (() => CropModel.handleReset())}><span class="btn-label">Reset</span></button>;
    }
};

// Save and return to staking view if unstaked, color view if staked.
const CropDoneButton = {
    view: () => {
        const isCroppable = CropModel.state.isCroppable;
        return <button className={`btn btn-pill btn-small btn-primary btn-done ${!isCroppable ? ' btn-inactive' : ''}`} type='submit'
            onclick={isCroppable && (() => CropModel.saveCrop())}><span class="btn-label">Done</span></button>;
    }
};

// Undo the last step
const CropUndoButton = {
    view: () => {
        const isUndoable = CropModel.state.isUndoable;
        return <button class={`btn btn-pill btn-small btn-not-primary btn-undo ${!isUndoable ? ' btn-inactive' : ''}`} type='submit' onclick={isUndoable && (() => CropModel.handleUndo())}><span class="btn-label">Undo</span></button>;
    }
};

// Displays the record title with a crop icon
const CropRecordTitle = {
    view: ({attrs: {hideCropControls}}) =>
        <div className='stakeable-title-wrap crop-title'>
            {hideCropControls ? <i className="icon-layers"/> : <i className='icon-crop'/>}
            <span className='stakeable-title-text'>{CropModel.title}</span>
        </div>
};

class CropHeader {

    view({attrs: {hideCropControls}}) {
        return <div>
            <div className='stakeable-menu-controls layer-controls-close-view'>
                <CropCloseButton/>
            </div>
            <div class='stakeable-menu-controls layer-controls-layer-title'>
                <CropRecordTitle hideCropControls={hideCropControls} />
            </div>
            <div class='stakeable-menu-controls layer-controls-primary-ctas'>
                {hideCropControls ? '' : <CropUndoButton />}
                {hideCropControls ? '' : <CropResetButton />}
                {hideCropControls ? '' : <CropDoneButton />}
            </div>
        </div>;
    }

}

export default CropHeader;
