import router from 'uav-router';
import peopleModel from 'models/people/people-model';
import PermissionsIndicator from 'views/permissions/permissions-indicator';
import AssetOptionsModel from 'models/asset/asset-options-model';
/**
 Required args:
 * @param options - Array of options available for the select dropdown
 * @param onselect - Function to run when an option has been selected
 Optional args:
 * @param displayOptionAs - Callback to format option data for display, e.g, userId displays as user's name
 * @param disabledOptionFn - Callback to check if option is disabled, e.g, if a user's role blocks them from viewing an asset
 * @param selected - Currently selected option
 */
const AutoComplete = {

    oninit: ({state, attrs: {model}}) => {
        state.model = model;
    },

    view: ({state, attrs: {selected, wrapClass}}) => {
        const model = state.model;
        return <div class={`auto-complete-wrap ${wrapClass || ''}`} onclick={(e) => e.stopPropagation()}>
            {model && model.optionsVisible && <div class='auto-complete' style={`top: ${model.offsetTop}px`}>
                {model.titleText && <span class="auto-complete-title-tip">{model.titleText}</span>}
                <div class="options">
                    {selected && model.filteredOptions.length ?
                        <div class={'option option-clear' + (model.focusedIndex < 0 ? ' focus' : '')}
                            onmousedown={() => model.clearSelection()}
                            ontouchstart={() => model.clearSelection()}>Clear selection</div> : null}
                    {model.filteredOptions.map((option, index) => {
                        return <div class={`option${model.focusedIndex === index ? ' focus' : ''}${option.isDisabled ? ' disabled' : ''}`}
                            onmousedown={option.isDisabled ? (e) => model.selectedDisabledOption(e) : () => model.selectIndex(index)}
                            ontouchstart={option.isDisabled ? (e) => model.selectedDisabledOption(e) : () => model.selectIndex(index)}>
                            <PermissionsIndicator cssWrap={model.highlightedOptions[option.value] ? 'highlight' : ''} lockedMessage={AssetOptionsModel.personViewContentMessage(router.params.assetId, option.value)} />
                            <div class="option-inner">
                                <div class='user-avatar icon-initials small'>{peopleModel.getPerson(option.value).initials()}</div>
                                <div class="inner-text user-name">{option.text}</div>
                            </div>
                        </div>;
                    })}
                </div>
            </div>}
        </div>;
    }

};

export default AutoComplete;
