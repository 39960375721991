import siteModel from 'models/site-model';
import Polygon from 'util/draw/polygon';
import isMetric from 'util/numbers/is-metric';
import measure from 'legacy/util/numbers/measure';
import loadExternal from 'legacy/util/data/load-external';
import api from 'legacy/util/api';
import constants from 'util/data/constants';
import latLngsToLngLats from 'util/geo/latlngs-to-lnglats';
import lngLatsToLatLngs from 'util/geo/lnglats-to-latlngs';
import lngLatsToBounds from 'util/geo/lnglats-to-bounds';
import helpers from 'legacy/util/api/helpers';
import formModel from 'models/form-model';
import panelModel from 'models/panel-model';
import placesTabModel from './places-tab-model';
import appModel from './app-model';
import popup from 'util/popup';
import GeometryDetail from 'views/geometry-detail/geometry-detail';
import roundIfNLong from 'util/numbers/round-if-n-long';
import debounce from 'util/events/debounce';
import oneUpModel from 'models/one-up-model';
import router from 'uav-router';
import assetListManager from 'managers/asset-list-manager';

const TEMP_EDITING_PLACE = '_temp_editing_place';

class PlaceEditorModel {

    constructor() {
        this.autosavePlace = debounce(this._autosavePlace.bind(this), 400);
        this.autosaveLevel = debounce(this._autosaveLevel.bind(this), 400);
        this.levelCountChange = debounce(this._levelCountChange.bind(this), 400);
    }

    /**
     * placeId param is optional. if none provided, will init create new place flow
     */
    init(projectId, projectAssetId, placeId) {
        this.reset();
        router.url.mergeReplace({view: 'placeEditor'});
        this.projectId = projectId;
        this.placeId = placeId || '';
        this.projectName = assetListManager.getAssetName(projectAssetId);
        if (placeId) {
            this._initEditPlace(placeId);
        } else {
            this._initCreatePlace(projectId, projectAssetId);
        }

        // Close any popups
        popup.remove();
        panelModel.close();
        oneUpModel.clearActiveFlow();
        appModel.startDrawing(); // Blocks normal mapclick popups from showing while user is trying to edit this place
        m.redraw();
    }

    reset() {
        this.defaultElevation = 0;
        this.placeName = '';
        this.levels = [];
        this.saving = {};
        this.placeholderMockLevels = [];
        this.showPlaceForm = false;
        this.editingLayerHex = constants.colors.nameToHex.teal;
    }

    cleanup() {
        if (this.draw) {
            this.draw.stop();
            placesTabModel.cleanUp();
            this.map.safeRemoveSource(TEMP_EDITING_PLACE);
            this.draw = null;
            router.url.remove('view');
            appModel.stopDrawing();
        }
    }

    close() {
        this.cleanup();
        const assetId = placesTabModel.assetId;
        formModel.viewAsset(assetId, 'Places/Levels');
        this.reset();
        m.redraw();
    }

    _initCreatePlace() {
        this.createFeature();
        this.startDrawing();
    }

    _initEditPlace(placeId) {
        placesTabModel.hideProjectPlace(placeId);
        placesTabModel.placesToggledOn[placeId] = true;
        m.redraw();
        const place = placesTabModel.places[placeId];
        if (place) {
            this.placeName = place.name;
            this.levels = helpers.list(place.levels).map(l => {
                l.name = l.shortName;
                l._elevationFeet = roundIfNLong(measure.metersToFeet(l.elevation), 3);
                return l;
            });
            const feature = placesTabModel.placeFeatures[placeId];
            this.createFeature(feature);
            siteModel.map.fitBounds(lngLatsToBounds(feature.geometry.coordinates[0]), {
                padding: {
                    top: 100,
                    bottom: 100,
                    left: 50,
                    right: 350
                },
                animate: true
            });
            const lngLat = siteModel.map.getCenter();
            this.setDefaultElevation([lngLat.lng, lngLat.lat]);
            this.startEditBoundary(feature);
        } else {
            this._initCreatePlace();
        }
    }

    /* ------ Drawing Config --------- */

    createFeature(feature) {
        this.map = siteModel.map;
        placesTabModel.addLayer(TEMP_EDITING_PLACE, TEMP_EDITING_PLACE, this.editingLayerHex, feature ? [feature] : []);
    }

    startDrawing() {
        this.draw = new Polygon({
            metric: isMetric(),
            map: this.map,
            color: this.editingLayerHex,
            source: this.map.getSource(TEMP_EDITING_PLACE)
        });
        this.draw.onVertexAdded = feature => {
            if (feature.geometry.type === 'LineString' && feature.geometry.coordinates.length < 3) {
                this.setDefaultElevation(feature.geometry.coordinates[0]);
                this.draw.onVertexAdded =  null;
            }
        };
        this.draw.onComplete = feature => {
            this.saveNewPlace().then(() => this.startEditBoundary(feature, true));
        };
        this.draw.create();
    }

    startEditBoundary(feature, isNew = false) {
        if (!isNew) {
            this.draw = new Polygon({
                metric: isMetric(),
                map: this.map,
                color: this.editingLayerHex,
                source: this.map.getSource(TEMP_EDITING_PLACE)
            });
        }
        this.draw.edit(feature);

        this.draw.onVertexChanged = () => {
            this.autosavePlace('boundary');
            GeometryDetail.update();
        };
        this.showPlaceForm = true;
        m.redraw();
    }

    /* --------- Handling User Interation --------- */

    editLevelName(i, newName) {
        this.levels[i].name = newName;
        return this.autosaveLevel(i, 'modify');
    }

    editElevationValue(i, newValueFeet) {
        this.levels[i]._elevationFeet = newValueFeet;
        this.levels[i].elevation = measure.feetToMeters(newValueFeet);
        return this.autosaveLevel(i, 'modify');
    }

    _levelCountChange(e) {
        const input = e.target;
        let levelCount = parseInt(e.target.value) || 0;

        if (levelCount > 200) {
            input.value = 200;
            levelCount = 200;
        }

        if (levelCount < this.levels.length) {
            this.batchRemoveLevels(levelCount);
        } else {
            this.batchAddLevels(levelCount);
        }
    }

    /* ------ Saving changes to DB --------- */

    batchRemoveLevels(newLevelCount) {
        this.saving.levels = true;
        const deletedLevels = [...this.levels.splice(newLevelCount, this.levels.length)];
        m.redraw();
        return api.rpc.request(deletedLevels.map((level) => ['deleteLevel', {levelId: level.levelId}])).then(() => {
            delete this.saving.levels;
            m.redraw();
        });
    }

    batchAddLevels(numberToAdd) {
        let levelsToCreate = numberToAdd - this.levels.length;
        if (levelsToCreate > 0) {
            const createRequests = [];
            this.saving.levels = true;
            m.redraw();
            const tenFeetInMeters = measure.feetToMeters(10);
            const incrementNameCount = 1 + this.placeholderMockLevels.length;
            let incrementedElevation = (this.levels[this.levels.length - 1] ? this.levels[this.levels.length - 1].elevation : this.defaultElevation) + tenFeetInMeters;
            while (levelsToCreate > 0) {
                const name = 'Level ' + (this.levels.length + incrementNameCount + createRequests.length);
                createRequests.push(['createLevel', {
                    placeId: this.placeId,
                    shortName: name,
                    elevation: incrementedElevation
                }]);
                incrementedElevation += tenFeetInMeters;
                this.placeholderMockLevels.push(name);
                levelsToCreate--;
            }
            m.redraw();
            return api.rpc.requests(createRequests).then((response) => {
                response.forEach(level => {
                    this.levels.push({
                        placeId: level.placeId,
                        levelId: level.levelId,
                        name: level.shortName,
                        elevation: level.elevation,
                        _elevationFeet: roundIfNLong(measure.metersToFeet(level.elevation), 3)
                    });
                });
                delete this.saving.levels;
                this.placeholderMockLevels = [];
                m.redraw();
            });
        }
    }

    _autosaveLevel(levelIndex) {
        this.saving['level' + levelIndex] = true;
        m.redraw();

        const level = this.levels[levelIndex];
        return api.rpc.modify('Level', {
            levelId: level.levelId || undefined,
            shortName: level.name || 'Level ' + (levelIndex + 1),
            elevation: Number(level.elevation || 0)
        }).then(() => {
            delete this.saving['level' + levelIndex];
            m.redraw();
        });
    }

    _autosavePlace(idForSpinner) {
        this.saving[idForSpinner] = true;
        m.redraw();
        return api.rpc.modify('Place', {
            boundary: {
                type: 'Polygon',
                coordinates: [lngLatsToLatLngs(placeEditorModel.draw.feature.geometry.coordinates[0])]
            },
            name: this.placeName || undefined,
            placeId: this.placeId
        }).then(() => {
            delete this.saving[idForSpinner];
            m.redraw();
        });
    }

    saveNewPlace() {
        this.isSaving = true;
        m.redraw();
        return api.rpc.create('Place', {
            boundary: {
                type: 'Polygon',
                coordinates: [lngLatsToLatLngs(this.draw.feature.geometry.coordinates[0])]},
            name: 'New Place',
            projectId: this.projectId,
            placeId: undefined
        }).then((place) =>{
            this.isSaving = false;
            this.placeId = place.placeId;
            m.redraw();
        });
    }

    /* ---------- Helper Functions ---------- */

    get mockFeature() {
        return {
            geometry: {
                coordinates: [latLngsToLngLats(this.draw.feature.geometry.coordinates[0])],
                type: 'Polygon'
            },
            type: 'Feature',
            properties: {
                _textSizePx: 18
            }
        };
    }

    setDefaultElevation(lngLat) {
        loadExternal.loadGoogle().then(() => {
            new window.google.maps.ElevationService().getElevationForLocations({
                locations: [{
                    lat: lngLat[1],
                    lng: lngLat[0]
                }]
            }, results => {
                if (results && results[0] && results[0].elevation) {
                    this.defaultElevation = results[0].elevation;
                    this.defaultElevationFeet = roundIfNLong(measure.metersToFeet(results[0].elevation + 10), 3);
                    const firstLevel = this.levels[0];
                    if (firstLevel && !firstLevel.elevation) {
                        firstLevel.elevation = this.defaultElevation;
                        m.redraw();
                    }
                }
            });
        });
    }

}

const placeEditorModel = new PlaceEditorModel();

export default placeEditorModel;
