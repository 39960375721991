import assetListManager from 'managers/asset-list-manager';
import appModel from 'models/app-model';
import constants from 'util/data/constants';
import store from 'util/data/store';
import featureListManager from 'managers/feature-list-manager';
class AssetModel {

    constructor(apiRecord) {
        this._layersByControlType = {};
            
        if (apiRecord.type === 'Feature') {
            this.initFromFeature(apiRecord);
        } else {
            this.initFromAsset(apiRecord);
        }
    }

    initFromFeature(apiRecord) {
        apiRecord.properties = apiRecord.properties || {};
        this.assetId = apiRecord.properties.assetId;
        this.properties = Object.assign({}, apiRecord.asset ? apiRecord.asset.properties : {});
        this.assetTypeId = assetListManager.getAssetTypeFromFeatureType(apiRecord.properties.featureTypeId);
        this.featureIds = [apiRecord.featureId];
    
        this.attributes = {
            toolId: assetListManager.getToolFromFeatureType(apiRecord.properties.featureTypeId)
        };


        this.mediaIds = [];
    }

    initFromAsset(apiRecord) {
        Object.assign(this, apiRecord);
        this.initLayers();
    }

    initLayers() {
        this._isSurvey = !!appModel.toolbox.assetTypesWithSurveyControls[this.assetTypeId];
        this._isPlan = !!appModel.toolbox.assetTypesWithPlanControls[this.assetTypeId];
        
        if (!this._isSurvey && !this._isPlan) {
            return;
        }
            
        for (const control of this._controls) {

            let layerIdOrIds = (control.controlTypeId === constants.controlTypeNameToId.plan ||
                    control.controlTypeId === constants.controlTypeNameToId.survey)
                    && this.properties[control.fieldName];

            if (layerIdOrIds) {
                layerIdOrIds = Array.isArray(layerIdOrIds) ? layerIdOrIds : [layerIdOrIds];                    
                for (let i = 0; i < layerIdOrIds.length; i++) {
                    const layerId = layerIdOrIds[i];
                    const layer = store.surveys && store.surveys[layerId] || store.plans && store.plans[layerId];
                    if (layer) {
                        this._layersByControlType[control.controlTypeId] = this._layersByControlType[control.controlTypeId] || [];
                        const existingLayer = this._layersByControlType[control.controlTypeId].find(_layer => _layer.planId && _layer.planId === layer.planId || _layer.surveyId && _layer.surveyId === layer.surveyId);
                        // eslint-disable-next-line max-depth
                        if (!existingLayer) {
                            this._layersByControlType[control.controlTypeId].push(layer);
                        }
                    }
                }
            }
        }

    }

    get _toolId() {
        return store.assetTypeIdToToolId[this.assetTypeId];
    }

    get _tool() {
        return appModel.toolbox.tools[this._toolId];
    }

    get _assetType() {
        return store.assetTypes[this.assetTypeId];
    }

    get _assetForm() {
        return store.assetForms[store.assetTypeToFormId[this.assetTypeId]];
    }

    get _features() {
        return this.featureIds ? this.featureIds.map(featureId => featureListManager.getById(featureId)) : null;
    }

    get _controls() {
        return appModel.toolbox.tools[this._toolId].assetForm.controls;
    }

    getLayers(controlTypeName) {
        if (controlTypeName) {
            const byControlType = this._layersByControlType[constants.controlTypeNameToId[controlTypeName]];
            return byControlType;
        }
        const firstControl = Object.values(this._layersByControlType)[0]; // just grab the first one
        return firstControl;
    }
}

export default AssetModel;
