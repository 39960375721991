import accountManager from 'managers/account-manager';
import store from 'util/data/store';
import appModel from 'models/app-model';

const AccountSearchBox = {
    oncreate: ({dom}) => {
        accountManager.searchInput = dom.firstElementChild;
    },

    view: () =>
        <div class="account-search-box padding-wrap" onclick={(e) => e.stopPropagation()}>
            <input type="text" oninput={(e) => accountManager.search(e.target.value)} placeholder="Search Accounts ..."/>
            {accountManager.isSearching && (accountManager.accounts.length || accountManager.loadedAll ? <button class="clear-search-btn btn-pill btn-secondary btn btn-smallest" onclick={() => accountManager.clearSearch()}>Clear</button>
                : <i class="spinning teal spinner"/>)}
        </div>
};

const AccountSelector = {

    oninit: () => {
        if (!accountManager.paginator.isStarted) {
            accountManager.paginator.next();
        }
    },

    onremove: () => {
        accountManager.clearSearch();
    },

    view: () => {
        return <div class="account-selector-wrap" onclick={(e) => e.stopPropagation()}>
            <AccountSearchBox/>
            {accountManager.accounts.length
                ? <>
                    <ul class="account-list" onscroll={accountManager.paginator.onscroll}>
                        {accountManager.accounts.map(account => account.accountId === store.account.accountId
                            ? <li class="select-option active">
                                {account.name} (Current) {accountManager.isSearching && <span class="account-id">ID: {account.accountId}</span>}
                            </li>
                            : <li onclick={() => appModel.changeAccount(account)} class="select-option">
                                {account.name} {accountManager.isSearching && <span class="account-id">ID: {account.accountId}</span>}
                            </li>)
                        } {!accountManager.loadedAll && <li class="loading-more"><i class="spinner spinning"/></li>}
                    </ul>
                </>
                : <div class="loading-message">{accountManager.loadedAll
                    ? <span>No accounts found.</span>
                    : <span>Loading... <i class="spinner spinning"/></span>}
                </div>}
        </div>;
    }

};

export default AccountSelector;
