import initializer from 'util/initializer';
import MediaRecordModel from 'models/media-record-model';
import requestBatches from 'util/network/request-batches/request-batches';
import constants from 'util/data/constants';
import api from 'legacy/util/api';
import router from 'uav-router';
import mediaViewerModel from 'models/media-viewer-model';

/**
 * Manages fetching & proper modeling of media records from api.
 * To ensure consistent shape & freshness of media records, media should be accessed
 * through this manager using `addMedia`, `getMedia` or `getMediaAsync` methods, rather than
 * adding or retrieving directly from api or `mediaLisManager.all[mediaId]`.
 */
class MediaListManager {

    constructor() {
        initializer.add(() => mediaListManager._cleanup(), 'mediaListManager');
        this.backgroundFetching = {};
        this.all = {};
    }
    
    /**
     * Takes a raw media record, model it, and add it to local cache.
     */
    addMedia(apiMediaObj) {
        if (apiMediaObj.isVisible) {
            this.all[apiMediaObj.mediaId] = new MediaRecordModel(apiMediaObj);
            return this.all[apiMediaObj.mediaId];
        } 
        this.invalid[apiMediaObj.mediaId] = true;
        return false;
    }

    /**
    * Async: Will refetch if media not yet stored locally or expired.
    **/
    async getMediaAsync(mediaId) {
        if (!mediaId) {
            return null;
        }
        let media = mediaListManager.all[mediaId];
        if (!media || media.isExpired()) {
            [media] = await api.rpc.request([['listMedia', {mediaId, limit: 1, isVisible: true}]]);
            if (media) {
                mediaListManager.all[mediaId] = new MediaRecordModel(media);
            }
        }
        return mediaListManager.all[mediaId] || null;
    }

    /**
    * Synchronous: Will return null if media not yet in cache or is expired. 
    * If null, will fetch in the background.
    * Accepts optional callbacks: opts.onSuccess and opts.onInvalid 
    **/
    getMedia(mediaId, opts = {}) {
        if (!mediaId) {
            return null;
        }

        const media = this.all[mediaId];
        if (!media || media.isExpired()) {
            this._backgroundFetchMedia(mediaId, opts);
            return null;
        }

        if (opts.onSuccess) {
            opts.onSuccess(media);
        }
        return media;
    }

    async openInMediaViewer(mediaId) {
        const media = await this.getMediaAsync(mediaId);
        if (media && media.status === 'active') {
            mediaViewerModel.open([media.mediaId]);
            return media;
        }
        return null;
    }

    async sendToMedia(mediaId) {
        const media = await this.getMediaAsync(mediaId);
        if (media && media.status === 'active') {
            return window.location.assign(`${constants.mediaURL}${mediaId}`);
        }
        // Media not found, remove param:
        router.url.remove('mediaId');
        return Promise.resolve();
    }

    removeInvalidMediaIds(mediaIds) {
        return mediaIds.filter(mediaId => this.invalid[mediaId]);
    }

    getThumbnailData(mediaId) {
        const media = this.getMedia(mediaId);
      
        if (media) {
            return media.thumbnailData;
        }
        return {
            mediaId,
            src: constants.mediaURL + mediaId, 
            isImage: false,
            name: ''
        };
    }
    
    /* -------- Private Methods --------- */

    _cleanup() {
        this.all = {};
        this.invalid = {};
    }

    _backgroundFetchMedia(mediaId, opts = {}) {
        requestBatches.add('listMedia', mediaId, (media) => {
            this.all[mediaId] = new MediaRecordModel(media);
            delete this.backgroundFetching[mediaId];
            if (opts.onSuccess) {
                opts.onSuccess(this.all[mediaId]);
            }
            m.redraw();
        }, () => {
            if (opts.onInvalid) {
                opts.onInvalid();
            }
        });  
    }

}

const mediaListManager = new MediaListManager();

export default mediaListManager;
