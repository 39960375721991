import Auth from 'views/auth/auth';
import AuthForm from 'views/auth/auth-form/auth-form';
import AuthField from 'views/auth/auth-field/auth-field';
import FormButton from 'views/form-button/form-button';
import authModel from 'models/auth-model';
import router from 'uav-router';
import ForgotPassword from 'views/auth/forgot-password/forgot-password.js';
import ShowHidePassword from '../auth-field/show-hide-password';

const NewPassword = {
    oninit: ({state}) => {
        Object.assign(state, {
            password: {type: 'password'},
            confirmPassword: {type: 'password'}
        });
    },
    view: ({state}) => {
        const validated = state.isValidPassword
            && state.confirmPassword.isValid;
        const doesMatch = state.password.value === state.confirmPassword.value;
        return <Auth cssClass="forgot-password">
            <AuthForm>
                <div class="form-msg"><h1>Set Password</h1></div>
                <div class="form new-form new-password">
                    <p>To finish creating your account, please set a password.</p>
                    <AuthField
                        showValidationError={state.showValidationError}
                        matchError={'Passwords do not match'}
                        matchValue={state.confirmPassword.value}
                        type={state.password.type}
                        label='Password'
                        onInput={(e, isValid) => {
                            state.isValidPassword = isValid;
                            state.password.value = e.target.value;
                        }}>
                        <span className="pwd-toggle"
                            onclick={() => {
                                state.password.type = state.password.type === 'password' ? 'name' : 'password';
                            }}>
                            {state.password.type === 'password' ? 'show' : 'hide'}
                        </span>
                        <div className="form-prompt margin-bottom-mini">
                            Minimum of 8 characters, including 1 number and 1 uppercase letter.
                        </div>
                    </AuthField>
                    <AuthField
                        showValidationError={state.showValidationError}
                        type={state.confirmPassword.type}
                        label='Confirm Password'
                        onInput={(e, isValid) => {
                            state.confirmPassword.isValid = isValid;
                            state.confirmPassword.value = e.target.value;
                        }}>
                        <ShowHidePassword onToggle={(type) => {
                            state.confirmPassword.type = type;
                        }}/>
                        <a className="forgot-link" onclick={() => {
                            const params = window.location.hash.substring(1).split('&');
                            let invitationId = params.find(p => p.includes('invitationId'));
                            let secret = params.find(p => p.includes('secret'));
                            if (invitationId) {
                                invitationId = invitationId.substring(13);
                            }
                            if (secret) {
                                secret = secret.substring(7);
                            }
                            ForgotPassword.onCancelRouterOpts = {
                                invitationId,
                                secret,
                                view: 'newpassword'
                            };
                            router.set({view: 'forgotpassword'});
                        }}>
                            ... Or Reset Your Password
                        </a>
                    </AuthField>
                    <FormButton
                        shouldSpin={state.sent}
                        onClick={() => {
                            state.showValidationError = true;
                            if (validated && doesMatch) {
                                authModel.sendSetPassword(state.password.value);
                                state.sent = true;
                            }
                        }}
                        label="Set Password"
                        isDisabled={!validated}>
                    </FormButton>
                </div>
            </AuthForm>
        </Auth>;
    }
};

export default NewPassword;
