import savedViewsModel from 'models/saved-views-model';
import Radio from 'views/radio/radio';

const SavedViewsDeleteOptions = {
    view: () => <div class="saving-options">
        {savedViewsModel.showDeleteOptions ? <><label>Delete this view for</label>
            <div><Radio 
                defaultIndex={1} 
                options={savedViewsModel.savingOptions} 
                onchange={(value, i) => savedViewsModel.selectSavingOption(i)}/></div></> : ''}
        <span class="warning-text">Please note, this operation cannot be undone.</span>
    </div>
};

export default SavedViewsDeleteOptions;
