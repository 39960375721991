import initializer from 'util/initializer';
import assetListManager from 'managers/asset-list-manager';
import store from 'util/data/store';
import AssetOptionsModel from 'models/asset/asset-options-model';
import siteModel from 'models/site-model';
import tableModel from 'models/table/table-model';
import popoverModel from 'models/popover-model';
import api from 'legacy/util/api';
import debounce from 'util/events/debounce';
import formModel from 'models/form-model';
import appModel from 'models/app-model';
import {getNormalizedKey} from 'util/events/get-normalized-key';
import activeCellModel from 'models/table/active-cell-model';
import deleteBatchContent from '../legacy/util/data/delete-batch-content';
import { BATCH_SIZE } from 'constants/models/batch-select-constants';
import logConstants from 'constants/managers/log-constants';
import {datadogRum} from '@datadog/browser-rum';
import message from 'views/toast-message/toast-message';
class BatchSelectModel {

    constructor() {
        this.init();
        this.batchAutosave = debounce(this._batchAutosave.bind(this), 400);
    }

    init() {
        this.selectedAssets = {};
        this.assetsToModify = {};
        this.assetsToDelete = {};
        this.originalAssetValues = {};
        this.selectedAssetsFeatures = {};
        this.currentControlOpen = undefined;
    }

    areNoAssetsSelected() {
        return Object.keys(this.selectedAssets).length === 0;
    }
    
    removeFilteredOutAssets() { 
        const currentlySelectedAssets = Object.keys(this.selectedAssets);
        currentlySelectedAssets.forEach(assetId =>{ 
            if (!tableModel.assetIds.includes(assetId)) { 
                this.deselectSingleAsset(assetId);
            } 
        });
    }

    editAllMode(toMode = false) {
        this.editingAll = toMode;
    }
    
    deleteableAssets() {
        Object.keys(this.selectedAssets).forEach(assetId => {
            if (AssetOptionsModel.canDeleteContent(assetId)) {
                this.assetsToDelete[assetId] = true;
            }
        });
    }

    deselectAllAssets() {
        if (Object.keys(this.selectedAssets).length !== 0) {
            Object.keys(this.selectedAssets).map(assetId => {
                delete this.selectedAssetsFeatures[assetId];
                return assetListManager.deselectAsset(assetId);
            });
            siteModel.map.safeRemoveSource('selectedBoundingBox');
            this.selectedAssets = {};
            this.assetsToModify = {};
            this.assetsToDelete = {};
        }
        window.removeEventListener('keydown', this.keyDelete);
    }

    keyDelete(e) {
        const key = getNormalizedKey(e.key);
        if (popoverModel.isOpen ||  e.target.tagName.toUpperCase() === 'INPUT') {
            return;
        }
        if (key === 'Delete' || key === 'Backspace') {
            batchSelectModel.deleteAssets();
        }
    }

    deselectBatchAssets(assetIds) {
        assetIds.forEach(assetId => {
            delete this.selectedAssets[assetId];
            delete this.selectedAssetsFeatures[assetId];
            delete this.assetsToModify[assetId];
            delete this.assetsToDelete[assetId];
            if (!this.assetsToModify[activeCellModel.assetId]) {
                this.editAllMode(false);
            }
        });

        siteModel.map.getBoundCoords();
        this.deleteableAssets();
        if (Object.keys(this.selectedAssets).length === 0) {
            window.removeEventListener('keydown', this.keyDelete);
        }
        m.redraw();
    }

    deselectSingleAsset(assetId) {
        delete this.selectedAssets[assetId];
        delete this.selectedAssetsFeatures[assetId];
        siteModel.map.getBoundCoords();
        assetListManager.deselectAsset(assetId);
        if (this.assetsToModify[assetId]) {
            delete this.assetsToModify[assetId];
        }
        if (this.assetsToDelete[assetId]) {
            delete this.assetsToDelete[assetId];
        }
        this.deleteableAssets();
        if (Object.keys(this.selectedAssets).length === 0) {
            window.removeEventListener('keydown', this.keyDelete);
        }
        if (!this.assetsToModify[activeCellModel.assetId]) {
            this.editAllMode(false);
        }
        m.redraw();
    }

    selectBatchAssets(assetIds) {
        if (!this.selectedAssets || !Object.keys(this.selectedAssets).length) {
            window.addEventListener('keydown', this.keyDelete);
        }
        assetIds.forEach(assetId => {
            this.selectedAssets[assetId] = true;
            assetListManager.selectAsset(assetId);
            if (Object.keys(this.selectedAssets).length > 1 && !!this.currentControlOpen) {
                this.updateBatchModifyAssetList(assetId, this.currentControlOpen);
            }
        });
        m.redraw();
        this.deleteableAssets();
        m.redraw();
    }

    selectSingleAsset(assetId) {
        if (!this.selectedAssets || !Object.keys(this.selectedAssets).length) {
            window.addEventListener('keydown', this.keyDelete);
        } 
        this.selectedAssets[assetId] = true;
        if (Object.keys(this.selectedAssets).length > 1 && !!this.currentControlOpen) {
            this.updateBatchModifyAssetList(assetId, this.currentControlOpen);
        }
        m.redraw();

        assetListManager.selectAsset(assetId);
        this.deleteableAssets();
    }

    async deleteAssets() {
        const assets = Object.keys(this.assetsToDelete);
        const batches = [];
        for (let i = 0; i < assets.length; i += BATCH_SIZE) {
            batches.push(assets.slice(i, i + BATCH_SIZE));
        }
        for (const batch of batches) {
            await deleteBatchContent(batch);
        }
    }

    updateBatchModifyAssetList(currentAssetId, currentControl) {
        batchSelectModel.assetsToModify = {};
        batchSelectModel.currentControlOpen = currentControl;
        if (!this.areNoAssetsSelected() && batchSelectModel.selectedAssets[currentAssetId]) {
            Object.keys(batchSelectModel.selectedAssets).forEach(assetId => {
                if (appModel.user.permissions.canEditContent(assetId)) {
                    const currentAsset = store.assets[currentAssetId];
                    const asset = store.assets[assetId];
                    const assetFormId = store.assetTypeToFormId[asset.assetTypeId],
                        assetForm = store.assetForms[assetFormId];
                    if (tableModel.activeCell.isInCombinedColumn()) {
                        assetForm.controls.forEach(control => {
                            if (control.attributes.sharedColumn === currentControl.attributes.sharedColumn) {
                                batchSelectModel.assetsToModify[assetId] = {
                                    'id': asset.contentId,
                                    'field': control.fieldName,
                                    'value': asset.properties[control.fieldName]
                                };
                            }
                        });
                    } else {
                        assetForm.controls.forEach(control => {
                            if (asset.assetTypeId === currentAsset.assetTypeId && control.fieldName === currentControl.fieldName) {
                                batchSelectModel.assetsToModify[assetId] = {
                                    'id': asset.contentId,
                                    'field': control.fieldName,
                                    'value': asset.properties[control.fieldName]
                                };
                            }
                        });
                    }
                }
            });
            if (Object.keys(this.assetsToModify).length > 1) {
                batchSelectModel.editAllMode(true);
            }
        }
    }

    async _batchAutosave(control, newPropertyValue) {
        Object.keys(this.assetsToModify).forEach(assetId => {
            this.assetsToModify[assetId].value = newPropertyValue || this.originalAssetValues[assetId];
        });
        const assets = Object.values(this.assetsToModify);
        const batches = [];
        for (let i = 0; i < assets.length; i += BATCH_SIZE) {
            batches.push(assets.slice(i, i + BATCH_SIZE));
        }
        for (const batch of batches) {
            await this.modifyBatch(batch, control);
        }
        formModel.saving[control.fieldName] = false;
        tableModel.activeCell.onBatchAssetSave();
        m.redraw();
    }

    resetAssetValuesFor(controlType, assetsToUndo) {
        assetsToUndo.forEach(assetId => {
            const asset = store.assets[assetId];
            const value = batchSelectModel.originalAssetValues[assetId];
            if (controlType.controlTypeId) {
                if (value && Array.isArray(value)) {
                    asset.properties[controlType.fieldName] = [...value];
                } else {
                    asset.properties[controlType.fieldName] = value;
                }
            }
            assetListManager.syncFeaturesToControl(asset, controlType.fieldName);
        });
        batchSelectModel.batchAutosave(controlType);
        m.redraw();
    }

    modifyBatch(batch, control) {
        this.assetsToUndo = [];
        return api.rpc.request([['batchModify', {
            content: batch
        }]]).then((assetsFailed) => {
            if (assetsFailed) {
                message.show(`We were unable to edit ${control.label} for 1 or more selections.`, 'error');
                datadogRum.addError(new Error(logConstants.errorLogMessages.BATCH_MODIFY_FAILURES), assetsFailed);
                assetsFailed.forEach(asset => {
                    const assetId = Object.values(asset)[0];
                    this.assetsToUndo.push(assetId);
                });
                this.resetAssetValuesFor(control, this.assetsToUndo);
            }
        });
    }
}

const batchSelectModel = new BatchSelectModel();

initializer.add(() => batchSelectModel.init(), 'batchSelectModel');

export default batchSelectModel;
