const MENTION_REGEX = /<@(.*?)>/g;

class Token {
    constructor(start, withTags, value) {
        this.start = start;
        this.end = start + withTags.length - 1;
        this.value = value;
    }
}

export const tokenizer = (text) => {
    const tokens = {
        mentions: []
    };

    let result = MENTION_REGEX.exec(text);
    while (result !== null) {
        const match = result[0];
        const userId = result[1];
        const index = result.index;
        result = MENTION_REGEX.exec(text);
        tokens.mentions.push(new Token(index, match, userId));
    }

    return tokens;
};
