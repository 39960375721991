import layerModel from 'models/layer-model';
import LayerPicker from 'views/layer-picker';
import planModel from '../../../models/plan-model';
import ReviewHeader from './review-header';

class Review {
    oncreate() {
        planModel.colorInit();
    }

    view() {
        return  <>
            <ReviewHeader />
            <div id='plan-review' className={'review ' + planModel.reviewAnimateClass}>
                {layerModel.isOpen ? <LayerPicker  hideLinks={true} /> : null}
                <div id='mapbox-review' class='mapbox-plan-review mapbox'></div>
            </div>
        </>;
    }
}

export default Review;
