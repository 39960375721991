export const STEPS = {
    START: 0,
    PROMPT: 1,
    SELECT_TOOL: 2,
    SELECT_ADDRESS: 3,
    PROCESS: 4,
    DONE: 5,
    HUBSPOT_PROCESS: 98,
    HUBSPOT_DONE: 99
};

export const EXTENSION_TO_MIME_TYPES = {
    zip: 'application/zip'
};

export const AUTOMATED_EXTENSIONS = {
    tiff: true,
    geotiff: true,
    jpeg: true,
    jpg: true,
    zip: true,
    kml: true,
    kmz: true,
    pdf: true,
    png: true,
    geojson: false,
    csv: false,
    xlsx: false,
    tsv: false,
    txt: false
};

export const HIGHEST_EXTENSION_SCORE = 10;

export const SCORES_BY_EXTENSIONS = {
    tiff: 10,
    geotiff: 10,
    kml: 10,
    kmz: 10, 
    zip: 8, // 'shape' file
    jpeg: 5,
    jpg: 5,
    pdf: 1, 
    png: 1,
    geojson: 1, 
    csv: 1,
    xlsx: 1
};

export const INTERFACES_BY_EXTENSION = {
    geotiff: ['survey'],
    tiff: ['survey'],
    jpeg: ['filepicker'],
    jpg: ['filepicker'],
    zip: ['plan'],
    shp: ['plan'],
    kml: ['plan'], 
    kmz: ['plan'], 
    pdf: ['plan'],
    png: ['plan'],
    csv: ['filepicker'],
    xlsx: ['filepicker']
};
