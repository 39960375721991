import mapboxgl from 'mapbox-gl';

function lngLatsToBounds(coordinates) {

    return coordinates.reduce((bounds, coord) => {

        return bounds.extend(coord);

    }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

}

export default lngLatsToBounds;
