// Simple model for logic related to clickable menus
class MenuModel {
    constructor(opts) {
        this.items = opts.items;
        this.onAllMenuClicks = opts.onAllMenuClicks;
        this.activeItemId = opts.activeItemId || undefined;
    }

    selectItem(e, index) {
        const itemClicked = this.items[index];
        this.activeItemId = itemClicked.itemId;
        itemClicked.onClick(e);
        this.onAllMenuClicks(e);
    }

    setActiveItem(index) {
        const itemClicked = this.items[index];
        this.activeItemId = itemClicked.itemId;
        m.redraw();
    }

    getActiveItem() {
        return this.activeItemId ? this.getItemById(this.activeItemId) : undefined;
    }

    getItemById(itemId) {
        return this.items.find(item => item.itemId === itemId);
    }

    resetActive() {
        this.activeItemId = undefined;
        m.redraw();
    }
}

export default MenuModel;
