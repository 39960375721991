import createLayerFlow from 'flows/create-layer/create-layer-flow';
import Page from 'views/plan/pages/page';

const PageList = {
    view: () => {
        const pageCount = createLayerFlow.sortedPages && createLayerFlow.sortedPages.length || 0;
        const pageCountText = `${pageCount} Page${pageCount > 1 ? 's' : ''}`;
        return  <div class="select-page-inner-wrap">
            <div class="page-list-title">Select a Page <span class="page-count">{pageCountText}</span></div>
            <div class="page-list">
                {createLayerFlow.sortedPages.map((pageId, i) => <Page pageId={pageId} index={i}/>)}
            </div> 
        </div>;
    }
};

export default PageList;
