import onBodyClick from 'legacy/util/dom/on-body-click';
import FolderModel from 'models/folder/folder-model';
import popupModel from 'models/popover-model';
import FolderOptionsMenu from 'views/folder/folder-options-menu';

/**
 * Root of a nested folder structure, which can contain FolderModels or FolderItemModels (recursive)
 * Contents is defined by this.zOrder. The entire contents (including of all subfolders) is maintained in this.items 
 * on the root (this) and keyed by the urn.
 */
class RootFolderModel extends FolderModel {
    constructor(apiObject = {id: 'root',
        name: 'root',
        parentId: undefined,
        zOrder: []}) {
        super(apiObject);

        this.items = {}; // by urn
        
        this.menuOpenOn = null;
        
        this.dragImage = new Image();
        this.dragImage.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs='; // transparent img so we dont see the "ghost" image following the mouse

        return this;
    }

    get isRoot() {
        return true;
    }

    get root() {
        return this;
    }

    get isDraggingOverDropZone() {
        return this.state.isDraggingOverDropZone;
    }

    get mouseOffsetX() {
        return this.state.mouseOffsetX;
    }

    get itemWithColorPickerOn() {
        return this.getByUrn(this.colorPickerOn);
    }

    get itemEditingName() {
        return this.getByUrn(this.editingNameOn);
    }

    get cssWrapClass() {
        const standardClass = super.cssWrapClass;
        return `${standardClass}${this.draggingItem ? ' sorting' : ''}`;
    }

    isMenuOpenOn(urn) {
        return !!this.menuOpenOn && this.menuOpenOn === urn;
    }
    
    setDraggingItem(item) {
        this.draggingItem = item;
        m.redraw();
    }

    setDraggingOver(item, opts = {}) {
        this.draggingOverItem = item;
        this.state.isDraggingOverDropZone = opts.isDraggingOverDropZone;
        this.state.elementX = opts.elementX;
        m.redraw();
    }

    onScroll() {
        this.closePopups();
    }

    closePopups() {
        if (this.editingNameOn) {
            this.toggleEditingNameOn(null);
        }
        if (this.colorPickerOn) {
            this.toggleColorPickerOn(null);
        }
        if (this.menuOpenOn) {
            this.toggleMenuOn(null);
        }
    }

    toggleEditingNameOn(urn) {
        if (!urn || this.editingNameOn === urn) {
            this.editingNameOn = null;
        } else {
            this.closePopups();
            this.editingNameOn = urn;
        }
        m.redraw();
    }

    toggleColorPickerOn(urn, element) {
        if (!urn || this.colorPickerOn === urn) {
            this.colorPickerOn = null;
        } else if (urn) {
            this.closePopups();
            this.colorPickerOn = urn;
            let offsetY = 0;
            const outerElem = document.getElementById('layer-picker-outer-wrap');
            if (outerElem) {
                offsetY = outerElem.getBoundingClientRect().top;
            }
            const rect = element.getBoundingClientRect();
            this.colorPickerX = rect.x;
            this.colorPickerY = rect.y - offsetY;
            onBodyClick.once(() => this.toggleColorPickerOn(null));
        }
        m.redraw();
    }

    toggleMenuOn(urn, e) {
        if (e) {
            e.stopPropagation();
        }
        if (!urn || this.menuOpenOn === urn) {
            this.menuOpenOn = null;
            popupModel.close();
        } else if (urn) {
            this.closePopups();
            this.menuOpenOn = urn;
            popupModel.open(e.target, {
                view: FolderOptionsMenu,
                args: {item: this.getByUrn(urn)},
                wrapClass: 'point-menu folder-options-menu link-list',
                alignRight: true
            });
        }
        m.redraw();
    }    

}

export default RootFolderModel;
