import featureToControl from 'util/interfaces/feature-to-control';
import controlToFeature from 'util/interfaces/control-to-feature';
import siteModel from 'models/site-model';
import LineString from 'util/draw/linestring';
import isMetric from 'util/numbers/is-metric';
import store from 'util/data/store';
import formModel from 'models/form-model';
import ToolInterface from 'util/interfaces/tool-interface';
import GeometryDetail from 'views/geometry-detail';
import volumeModel from 'models/volume-model';
import helpers from 'legacy/util/api/helpers';
import featureListManager from 'managers/feature-list-manager';
import uploadHelpFlow from 'flows/upload-help/upload-help-flow';

class PolylineInterface extends ToolInterface {

    init() {
        const defaultProperties = this.featureType.getDefaultProperties();
        this.color = defaultProperties._lineColor || defaultProperties._fillColor;

        if (!this.color) {

            const colorStyles = ['line-color', 'fill-color', 'fill-outline-color'];

            this.tool.featureStyles.find(f => {

                const paint = f.style.paint;

                if (paint) {

                    const colorStyle = colorStyles.find(c => typeof paint[c] === 'string');

                    if (colorStyle) {

                        this.color = paint[colorStyle];

                        return true;

                    }

                }

                return false;

            });

        }

    }

    constructor(...args) {

        super(...args);

        this.type = 'polyline';

        this.DrawClass = LineString;

        this.init();

    }

    close() {

        if (this.draw) {

            this.draw.stop();

        }

        if (!this.isSaved) {
            if (this.feature && this.feature.featureType) {
                const source = this.feature.featureType.source;
                source.setData(source._data);
                featureListManager.removeFeature(this.feature, source);
            }
        }

        this.onClose();

    }

    setPrompt() {
        this.prompt = { view: () => 'Tap on the map to draw the ' + this.tool.name };
    }

    updatePrompt() {

        if (this.feature.geometry.coordinates.length > 2) {

            this.prompt = { view: () => 'Tap the last point to complete the ' + this.tool.name };

        }
    }

    launch(hidePrompt = false) {
        if (!hidePrompt) {
            this.setPrompt();
            this.showToolLauncher();
        }

        return new Promise(resolve => {

            const asset = store.assets[this.assetId];
            const saveAndClose = () => {
                this.isSaved = true;
                this.close();
                resolve([asset]);
            };

            this.done = () => {
                if (this.verifyDone && !this.verifyDone()) {
                    return;
                }
                if (this.beforeDone && !hidePrompt) {
                    this.beforeDone().then(() => saveAndClose());
                } else {
                    saveAndClose();
                }
            };
            this.onProjectComplete = () => {
                if (this.createProjectFromBounds) {
                    this.createProjectFromBounds().then(() => saveAndClose());
                }
            };

            this.feature.setupFeatureProperties(this.feature).then(() => {

                const source = this.feature.featureType.source;

                this.draw = new this.DrawClass({
                    map: siteModel.map,
                    source,
                    metric: isMetric(),
                    color: this.color
                }).create(this.feature.properties);

                this.draw.onVertexAdded = _feature => {

                    this.feature = _feature;

                    featureListManager.addLatitudeToFeature(_feature);

                    if (this.updatePrompt) {

                        this.updatePrompt();

                    }

                    m.redraw(); // hide "skip mapping" button

                };

                this.draw.onComplete = _feature => {
                    this.feature = _feature;
                    featureListManager.all[_feature.id] = _feature;

                    asset.featureIds = helpers.list(asset.featureIds);
                    asset.featureIds.push(_feature.id);

                    featureToControl.sync(this.type, _feature, this.assetId);

                    controlToFeature.syncAllFeatureProperties(this.assetId, this.feature.featureId || this.feature.id);

                    this.done();
                };

                // Specific to create from tool flow
                if (hidePrompt && this.setGeoJSONData) {
                    this.setGeoJSONData({
                        type: 'Polygon',
                        coordinates: uploadHelpFlow.locationData || [
                            [
                                [0, 1],
                                [1, 1],
                                [1, 0],
                                [0, 1]
                            ]
                        ]}, uploadHelpFlow.projectName);
                }

            });

        });

    }

    edit(_feature) {

        this.isSaved = true;

        const map = siteModel.map;
        this.feature = featureListManager.getById(_feature.id);
        
        if (!this.feature) {
            return;
        }

        const source = this.feature.featureType.source;

        this.draw = new this.DrawClass({
            map,
            source,
            metric: isMetric(),
            color: this.color
        }).edit(this.feature);

        this.draw.onVertexChanged = () => {

            featureListManager.addLatitudeToFeature(this.feature);

            featureToControl.sync(this.type, this.feature, this.assetId);

            controlToFeature.syncAllFeatureProperties(this.assetId, this.feature.featureId || this.feature.id);

            source.setData(source._data);

            GeometryDetail.update();

            volumeModel.vertexChanged();

            this.autosave(this.feature);

        };

        this.draw.onStop = formModel.onEditStop;

        m.redraw();

    }

    completeFeature() {
        this.draw.completeFeature();
    }

}

export default PolylineInterface;
