import api from 'legacy/util/api';
import store from 'util/data/store';
import formModel from 'models/form-model';
import siteModel from 'models/site-model';
import appModel from 'models/app-model';
import screenHelper from 'legacy/util/device/screen-helper';
import controlToFeature from 'util/interfaces/control-to-feature';
import featureListManager from 'managers/feature-list-manager';
import createLayerFlow from 'flows/create-layer/create-layer-flow';
import {supported, imageMimeTypes} from 'constants/managers/media-constants';
import mediaListManager from 'managers/media-list-manager';

const mediaModel = {

    view: null,

    close() {
        siteModel.view = null;
    },

    isImage: mimeType => imageMimeTypes[mimeType],

    isSupportedFileType(media) {
        const extension = media.label.split('.').pop().toLowerCase();
        const mimeType = media.mimeType;
        return supported.extensions[extension] || supported.mimeTypes[mimeType];
    },

    getLinkTool(media) {
        const mimeType = media.mimeType && media.mimeType.split('/')[0];
        return appModel.toolbox.linkTools[mimeType] || appModel.toolbox.linkTools.file;
    },

    /**
     * Given a list of mediaIds, filters out invisible and returns them. Updates the local store with any new media objects that needed to be fetched in the process.
     * @param {*} mediaIds Array of mediaIds
     * @returns Array of mediaIds with mediaItems marked as "isVisible: false" removed
     */
    filterOutInvisibleMedia: (mediaIds) => {
        const visibleMediaIds = [];
        const fetchFromApi = [];
        mediaIds.forEach(mediaId => {
            // Prefer cached media over separate rpc request if available
            const mediaStoredInMemory = mediaListManager.getMedia(mediaId);
            if (mediaStoredInMemory) {
                mediaStoredInMemory.isVisible ? visibleMediaIds.push(mediaId) : null;
            } else {
                fetchFromApi.push(mediaId);
            }
        });
        if (!fetchFromApi.length) {
            return visibleMediaIds;
        }
        return api.rpc.list('Media', {
            mediaIdIn: fetchFromApi,
            isVisible: true
        }).then(result => {
            result.forEach(media => {
                visibleMediaIds.push(media.mediaId);
                media.attributes = media.attributes || {};
                mediaListManager.addMedia(media);
            });
            return visibleMediaIds;
        });
    },

    getMax360PhotoSize() {
        if (mediaModel.max360PhotoSize !== undefined) {
            return mediaModel.max360PhotoSize;
        }

        if (window.WebGLRenderingContext) {
            try {
                let canvas = document.createElement('canvas');
                const gl = canvas.getContext('webgl');

                const textureSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);
                canvas = null;
                mediaModel.max360PhotoSize = textureSize;

            } catch (e) {
                mediaModel.max360PhotoSize = 0;
            }

        } else {
            mediaModel.max360PhotoSize = 0;
        }

        return mediaModel.max360PhotoSize;

    },

    async createLayer(mediaId, assetId) {
        if (screenHelper.small()) {
            return;
        }

        siteModel.view = null;
        return createLayerFlow.start({mediaId, assetId});
    },

    getFeature: (mediaId, assetId) => {
        const asset = store.assets[assetId];
        const featureId = asset.featureIds && asset.featureIds.find(id => {
            const feature = featureListManager.getById(id);
            return feature && feature.properties.mediaId === mediaId;
        });
        return featureListManager.getById(featureId);

    },

    editFeature(feature, mediaId) {
        siteModel.view = null;

        if (feature) {
            siteModel.map.panToFeatures([feature]);
            formModel.editFeature(feature);

        } else {
            formModel.addMediaFeature(mediaId);
        }

    },

    async deleteMediaAttachment(assetId, mediaId, control) {
        const asset = store.assets[assetId];

        // Delete from media store in memory
        const mediaToDelete = mediaListManager.getMedia(mediaId);
        if (mediaToDelete) {
            delete mediaListManager.all[mediaId];
            mediaListManager.invalid[mediaId] = true;
        }

        // Delete from asset properties in memory
        let propertyValue = asset.properties[control.fieldName] || [];
        propertyValue = propertyValue.filter(id => mediaId !== id);
        store.assets[assetId].properties[control.fieldName] = propertyValue.length ? propertyValue : undefined;
        
        // Save to DB & sync asset
        controlToFeature.updateAssetFeatures(control, assetId);
        if (mediaId) {
            await api.rpc.deleteMedia(mediaId);
        }
    }

};

export default mediaModel;
