import FolderContents from 'views/folder/folder-contents';
import FolderHeader from 'views/folder/folder-header';
import ColorPicker from 'views/color-picker/color-picker';
import stopPropagation from 'util/events/stop-propagation';
import layerColorModel from 'models/layer-color-model';
import ToggleColorSwitch from 'views/color-picker/toggle-color-switch';

const ColorPickerHeader = {
    view: ({attrs: {root}}) => {
        const activeItem = root.itemWithColorPickerOn;
        if (activeItem.recordType === 'plan') {
            const tileset = root.itemWithColorPickerOn.tileset;
            return <div class="color-picker-header">
                <span class="header-text"><ToggleColorSwitch
                    tileset={tileset}
                    hideText={true}
                    colorOn={tileset.color !== 'None'}
                    toggleFn={() => layerColorModel.toggleColor(tileset.color, tileset.tilesetId)}/>
                    Layer Color</span>
                <div class="color-swatch-wrap" onclick={() => root.toggleColorPickerOn(null)}>
                    <div class={`color-swatch ${tileset.color === 'None' ? 'layer-color-off' : ''}`} style={{ backgroundColor: `${activeItem.currentHex}`}}/>
                </div>
            </div>;
        }
        return <div class="color-picker-header"><span class="header-text">Set Color for Group</span><span class="cancel-text-button" onclick={() => root.toggleColorPickerOn(null)}>cancel</span>
        </div>;
    }
};

const Folder = {
    onremove: ({attrs: {folder}}) => {
        if (folder.isRoot) {
            folder.closePopups();
        }
    },
    view: ({attrs: {folder}}) => {
        const cssClasses = `folder ${folder.isRoot ? 'root-folder' : 'sub-folder'}${folder.state.isNew ? ' is-unsaved' : ''} ${folder.state.isToggledOn ? 'toggled-on' : 'toggled-off'} ${folder.expandCollapseCssClasses} ${folder.cssWrapClass}`;
        return <div class={cssClasses} onclick={stopPropagation(() => folder.root.closePopups())}>
            {folder.isRoot && folder.colorPickerOn ? <div class="color-picker-position-wrapper-folder" style={`top: ${folder.colorPickerY}px;`} onclick={stopPropagation()}>
                <ColorPicker
                    header={<ColorPickerHeader root={folder}/>}
                    showOpacity={true}
                    containerId="unearth-color-picker-layer-menu"
                    starterColor={folder.itemWithColorPickerOn.currentHex}
                    starterOpacity={folder.itemWithColorPickerOn.currentOpacity}
                    addCssClass={`folder-color-picker ${folder.itemWithColorPickerOn.recordType === 'folder' ? 'set-group' : 'set-layer'}`}
                    setColorFn={(hex, opacity) => folder.itemWithColorPickerOn.setColor(hex, opacity)}
                /></div> : ''}
            {folder.isRoot ? '' : <FolderHeader folder={folder}/>}
            {folder.state.subFoldersLoaded ? <FolderContents folder={folder}/> : <i class="spinner spinning teal"/>}
        </div>;
    }
};
    
export default Folder;

