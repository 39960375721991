import constants from 'util/data/constants';
import store from 'util/data/store';
import appModel from 'models/app-model';
import formatDate from 'legacy/util/date/format-date';
import formModel from 'models/form-model';
import dialogModel from 'models/dialog-model';
import popupModel from 'models/popover-model';
import {cellValueToText, toggleValueToCellText, linkedAssetsToCellText, mediaIdsToCellText} from 'util/data/cell-value-to-text';
import tableModel from 'models/table/table-model';
import peopleModel from 'models/people/people-model';
import {assetIsImageType} from 'util/data/helpers';
import mediaListManager from 'managers/media-list-manager';

/**
 * Handles all data for a single asset row in table view.
 */
class AssetRowModel {

    constructor(assetId) {
        const asset = store.assets[assetId];
        if (!asset || !asset.assetTypeId) {
            return 0;
        }

        this.rowIndex = -1;
        this.assetId = assetId;
        this.isEditable = appModel.user.isContentEditable(assetId);

        this.assetTypeId = asset.assetTypeId;
        this.assetFormId = store.assetTypeToFormId[asset.assetTypeId];
        this.toolGroupId = store.assetTypeIdToToolGroupId[asset.assetTypeId];
        this.hasFeatures = asset.featureIds && asset.featureIds.length;

        const assetForm = store.assetForms[this.assetFormId];
        this.nameControl = assetForm && assetForm.controls.find(control => control.controlTypeId === constants.controlTypeNameToId.name);

        const toolGroup = appModel.toolbox.toolGroups[this.toolGroupId];

        this.category = toolGroup ? toolGroup.name : 'Null';
        this.addedDate = formatDate.dateAndTime(new Date(asset.createdDateTime));
        this.updatedDate = formatDate.dateAndTime(new Date(asset.updatedDateTime));
        this.author = peopleModel.getPerson(asset.authorId) || {authorId: asset.authorId};
        this.modifier = peopleModel.getPerson(asset.modifierId) || {modifierId: asset.modifierId};

        this.rowSortingCss = tableModel.styles.getRowSortingCss(this.assetId);

        this.cells = {};
    }

    getCell(columnIndex) {
        const cellKey = tableModel.getColumnIndexToCellMap()[columnIndex];
        return cellKey ? this.cells[cellKey] : 0;
    }

    getCellValue(controlType) {
        if (constants.controlTypeIdToName[controlType.controlTypeId] === 'toggle') {
            return toggleValueToCellText(this.assetId, controlType);
        }
        if (constants.controlTypeIdToName[controlType.controlTypeId] === 'asset') {
            return linkedAssetsToCellText(this.assetId, controlType);
        }
        if (constants.controlTypeIdToName[controlType.controlTypeId] === 'file') {
            return mediaIdsToCellText(this.assetId, controlType);
        }
        if (constants.controlTypeIdToName[controlType.controlTypeId] === 'coordinates') {
            const asset = store.assets[this.assetId];
            const value = asset.properties[controlType.fieldName];
            if (value && value.coordinates && value.coordinates.length) {
                return value.coordinates.join(', ');
            }
            return null;
        }
        const asset = store.assets[this.assetId];
        const value = asset.properties[controlType.fieldName];
        if (controlType.fieldName === 'Capture Date' && assetIsImageType({assetTypeId: asset.assetTypeId})) {
            
            let captureDate = asset.captureDateTime ? formatDate.dateAndTime(new Date(asset.captureDateTime)) : '';
            if (asset.mediaIds && asset.mediaIds.length) {
                const media = mediaListManager.getMedia(asset.mediaIds[0]);
                if (media && media.captureDateTime) {
                    captureDate = formatDate.dateAndTime(new Date(media.captureDateTime));
                }
            }
            return captureDate;
        }

        return value === undefined || value === null ? null : cellValueToText(value, controlType.controlTypeId);
    }

    addCell(cell) {
        const valueEditable = !!cell.controlType && cell.controlType.controlTypeId !== constants.controlTypeNameToId.asset && cell.controlType.fieldName !== 'Capture Date' || cell.key === 'places';
        const controlIsRestricted = cell.controlType && cell.controlType.fieldName ? formModel.controlIsRestricted(this.assetTypeId, cell.controlType) || cell.controlType.attributes.eval : undefined;
        const userLimited = this.isEditable && !controlIsRestricted ? '' : 'disabled';

        const readOnly = !this.isEditable || !valueEditable || controlIsRestricted || this.controlIsReadOnly(cell.controlType) ? 'readonly' : '';
        const derivedField = readOnly && this.controlIsDerivedField(cell.controlType) ? 'derived' : '';
        const staticID = readOnly && !derivedField && cell.cellKey === 'name' ? 'id' : '';

        let editableClass;

        if (valueEditable || staticID) {
            editableClass = readOnly ? 'disabled-cell' : 'editable-cell';
        } else {
            editableClass = 'non-editable-cell';
        }

        Object.assign(cell, {
            permissionsErrorType: derivedField || userLimited || staticID || readOnly,
            hasPopup: valueEditable || staticID,
            wrapClass: `${editableClass}`,
            activeClass: 'inactive-cell'
        });
    }

    addNaCell(cell) {
        Object.assign(cell, {
            wrapClass: 'na-cell non-editable-cell',
            activeClass: 'inactive-cell'
        });
    }

    controlIsDerivedField(controlType) {
        return controlType && controlType.controlTypeId && !!controlType.attributes.eval;
    }

    controlIsReadOnly(controlType) {
        return controlType.controlTypeId && controlType.attributes.readOnly;
    }

    generateCellKey(columnIndex, header) {
        return this.assetId + this.rowIndex + header.assetType.assetTypeId + header.controlType.fieldName + columnIndex;
    }

    getPlacesAndLevels(assetId) {
        const asset = store.assets[assetId];
        const placeIds = asset.placeIds;
        const levelIds = asset.levelIds;
        if (!placeIds && !levelIds) {
            return null;
        }

        const placeMap = {};
        const levelsMap = {};

        if (placeIds.length) {
            placeIds.forEach(id => {
                placeMap[id] = true;
            });
            if (levelIds) {
                levelIds.forEach(id => {
                    levelsMap[id] = true;
                });
            }
        }
        return {placeMap, levelsMap};
    }

    /**
     * Checks if specific control is valid and if not, displays warning message.
     * Otherwise, run callback function.
     */
    validateThenClose(assetId, control) {
        if (control === 'places') {
            return popupModel.close();
        }
        const isInvalid = formModel.controlIsInvalid(control, assetId);
        if (isInvalid) {
            dialogModel.open({
                headline: 'This field is incomplete.',
                text: 'This is a required field. Would you like to continue or return to editing?',
                cssClass: 'incomplete-form-warning',
                yesText: 'Return to Editing',
                yesClass: 'btn btn-pill btn-primary btn-return-to-form',
                noText: 'Continue',
                noClass: 'btn btn-pill btn-secondary',
                onYes: () => {
                    // Return to editing control.
                    popupModel.disableCloseOnce = true;
                },
                onNo: () => {
                    // Close popup and return to table.
                    popupModel.disableCloseOnce = false;
                    popupModel.close();
                }
            });
        } else {
            popupModel.disableCloseOnce = false;
            popupModel.close();
        }
    }

}

export default AssetRowModel;
