import Radio from 'views/radio';
import importModel from 'models/import-model';
import GeometryJoiner from 'views/data-import/geometry-joiner';
import pluralize from 'util/data/pluralize';

const CannotImport = {
    view: () => <>
        <div class="data-import-status left">
            {importModel.validCount !== null
                ? <>
                    <div class="data-import-status-num">{importModel.validCount} {pluralize(importModel.validCount, 'record')}</div>
                    <div>{importModel.validCount ? 'will be ignored' : 'will be imported'}</div>
                    <a onclick={() => importModel.downloadAll()}>Download</a>
                </>
                : <i class="teal spinner spinning left" />
            }
        </div>
        <div class="data-import-status left">
            <div class="data-import-error">
                <i class="icon-error" /> Geometry type of {importModel.media.label} does NOT match the target asset type: {importModel.tool.name} ({importModel.toolGeomType}). <a onclick={() => importModel.upload()}>Choose another file</a>
            </div>
        </div>
    </>
};

const CanImport = {
    view: () => {
        // validCount = number of rows that have valid geometry
        // invalidCount = number of rows that have geometry but it's invalid
        // unMatchedCount = number of rows that don't have geometry
        const geometryMatches = importModel.geometryMatches;
        // const hasValidCount = importModel.validCount !== null;
        // const invalidCount = importModel.invalidCount || 0;
        // let unMatchedCount;
        // if (hasValidCount && importModel.geometryStrategy !== importModel.NO_GEOMETRY) {
        //     unMatchedCount = Math.max(0, importModel.totalCount - importModel.validCount - invalidCount);
        // }
        const toolGeomType = importModel.toolGeomType;
        const noGeometry = toolGeomType === 'NoGeometry';
        return <>
            {/* UE-5129 Leave out until api is fixed
            <div class="data-import-status left">
                {hasValidCount
                    ? <>
                        <div class="data-import-status-num">{importModel.totalCount} {pluralize(importModel.totalCount, 'record')}</div>
                            queued for import
                        {!!unMatchedCount && <div class="non-matches">
                            {unMatchedCount} {pluralize(unMatchedCount, 'record')} in {importModel.media.label} could not be matched{importModel.geo.media ? ` with any records in ${importModel.geo.media.label}` : ''}.
                            <div>
                                {importModel.isExporting === 'unmatched'
                                    ? <i class="gray spinner spinning left" />
                                    : <a onclick={() => importModel.downloadUnmatched()}>Download</a>}
                            </div>
                        </div>}
                    </>
                    : <i class="teal spinner spinning left" />
                }
            </div> */}
            <div class="data-import-status left">
                {geometryMatches || noGeometry
                    ? <div class="data-import-success">
                        <i class="icon-check" /> {noGeometry
                            ? 'All records can be imported'
                            : `Geometry type (${toolGeomType}) matches the target asset type: ${importModel.tool.name}`}
                    </div>
                    : <>
                        <div class="data-import-warning">
                            <i class="icon-warning" /> No {toolGeomType} geometry found in {importModel.media.label} {
                                importModel.geo.columnNames
                                    ? `or ${importModel.geo.media.label}. Please try uploading a file with ${toolGeomType} geometry.`
                                    : ''}
                        </div>
                        <Radio defaultOption={importModel.geometryStrategy} options={importModel.getStrategyOpts()} onchange={opt => {
                            importModel.clearGeoMedia();
                            importModel.geometryStrategy = opt;
                            importModel.getValidCount();
                        }}/>
                    </>}
                {importModel.geometryStrategy === importModel.JOIN_GEOMETRY && !(geometryMatches && !importModel.geo.media)
                    ? <GeometryJoiner />
                    : null}
            </div>
        </>;
    }
};

const ConfirmRecordsStep = {
    name: 'Confirm Records',
    view: () => <>
        <div class="data-status-wrap">
            <div class="clear">
                {/* <div class="data-import-status-txt left">Record Count and Status</div> UE-5129 */}
                <div class="data-import-status-txt left">Analysis</div>
            </div>
            <div class="data-import-section clear">
                {!importModel.geometryMatches && importModel.hasGeometry
                    ? <CannotImport />
                    : <CanImport />}
            </div>
        </div>
    </>
};

export default ConfirmRecordsStep;
