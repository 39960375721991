import appModel from 'models/app-model';
import savedViewsModel from 'models/saved-views-model';
import Radio from 'views/radio/radio';

const Header = {

    view: ({attrs: {formField, projectView}}) => <>
        {projectView.isSaved ? <div class="modal-header-row">
            <div class="modal-title saved-view-details-title"><span class="title-icon"><i class="icon-bookmark"/></span>{projectView.name}</div></div> 
            : <div class="modal-header-row saving-new-view">
                <label>Save this view as: <i class={`teal spinner ${savedViewsModel.isSaving ? 'spinning' : ''}`}/></label>
                <div class={`title-icon-wrap form-field ${formField.cssClass}`}>
                    <span class="title-icon"><i class="icon-bookmark"/></span>
                    <input type="text" oninput={(e) => {
                        formField.value = e.target.value;
                        formField.cssClass = false;
                    }} class="text-input" placeholder="Enter a name for this view"/>
                    {!!formField.isRequired && <span class="error-message">Please enter a name for this Saved View.</span>}
                </div>
            </div> }
        {projectView.isSaved || appModel.project.isMetaProject ? '' : <div class="saving-options">
            <label>Save this view for:</label>
            <div><Radio 
                defaultIndex={0} 
                options={savedViewsModel.savingOptions} 
                onchange={(value, i) => savedViewsModel.selectSavingOption(i)}/></div>
        </div>
        }
    </>
    
};

export default Header;
