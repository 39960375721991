import siteModel from 'models/site-model';

class BoundaryMenuModel {
    constructor() {
        this.mapMoveHandler = this.mapMoveHandler.bind(this);
        this.hasMoved = true;
    }

    selectBoundary(i) {
        if (i === 1) {
            this.addMapMoveHandler();
            this.hasMoved = false;
        } else {
            this.removeMapMoveHandler();
        }
    }

    mapMoveHandler() {
        const moved = this.hasMoved;
        this.hasMoved = true;
        if (moved !== true) {
            m.redraw();
        }
    }

    addMapMoveHandler(map = siteModel.map) {
        map.on('moveend', this.mapMoveHandler);
    }

    removeMapMoveHandler(map = siteModel.map) {
        map.off('moveend', this.mapMoveHandler);
    }
}

export default new BoundaryMenuModel();
