class Cached {

    // If key doesn't exist, assign value from passed function
    // If already exists, just return.
    get(key, fn) {
        if (!this.hasOwnProperty(key) && fn) {
            this.set(key, fn());
        }
        return this[key];
    }

    set(key, value) {
        this[key] = value;
    }

    clear(key) {
        delete this[key];
    }

}

export default Cached;
