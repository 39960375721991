import stakeableModel from 'models/stakeable-model';
import tip from 'views/tip'; 
import onBodyClick from 'legacy/util/dom/on-body-click';

// Displays the asset title and info popup message
export const StakeableTitle = {
    view: () =>
        <div class="stakeable-title-wrap">
            <span class="stakeable-title-text">{stakeableModel.recordTitle}</span>
            <i onclick={() => stakeableModel.showPopupMessage('how-to-pin-popup', true)} class="icon-help"></i>
        </div>
};

// Undo the last step
export const UndoButton = {
    view: () => {
        const isUndoable = stakeableModel.state.isUndoable;
        return <button type="submit" class={`btn btn-pill btn-small btn-not-primary btn-undo ${!isUndoable ? ' btn-inactive' : ''}`}
            onclick={isUndoable && (() => stakeableModel.undo())}><span class="btn-label">Undo</span></button>;
    }
};

// Button to lock the pins in relation to one another
export const LockPinsButton = {
    view: () => {
        const doneLoading = !stakeableModel.state.isLoading;
        const isTranslating = stakeableModel.state.isTranslating;
        return <div 
            onclick={doneLoading && (() => stakeableModel.toggleTranslate())}
            onmouseenter={function() {
                tip.show(this, {tipText: 'If checked, you can move one Match Point and the others on that side will maintain their relative positions.'});
                onBodyClick.once(() => tip.hide());
            }} 
            onmouseleave={() => tip.hide()}
            class={`checkbox-wrap-lock-pins ${!doneLoading ? ' btn-inactive' : ''} ${isTranslating ? 'translating-pins' : 'placing-pins'}`}>
            <i class={`icon-checkbox-${isTranslating ? 'on' : 'off'}`} />
            <label class="btn-label">Move Match Points Together</label>
        </div>;
    }
};

// Clear all stakes
export const ClearButton = {
    view: () => {
        const isClearable = stakeableModel.state.isClearable;
        return <button type="submit" class={`btn btn-pill btn-small btn-not-primary btn-clear-all ${!isClearable ? ' btn-inactive' : ''}`}
            onclick={isClearable && (() => stakeableModel.clearAll())}><span class="btn-label">Clear All Match Points</span></button>;
    }
};

