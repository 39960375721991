
class StepModel {
    constructor(index, initFunction) {
        this.index = index;
        this.afterInitted = [];
        this.isInitted = false;
        this.initFunction = initFunction;
    }

    async init(args) {
        if (this.initFunction) {
            await this.initFunction(args);
        }
        this.markAsInitted();
    }

    async waitUntilInit() {
        return new Promise(resolve => {
            if (this.isInitted) {
                return resolve();
            }
            this.afterInitted.push(resolve);
        });
    }

    markAsInitted() {
        this.isInitted = true;
        this.afterInitted.forEach(resolvePromise => resolvePromise());
        this.afterInitted = [];
    }
}

export default StepModel;
