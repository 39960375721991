import createLayerFlow from 'flows/create-layer/create-layer-flow';
import MediaThumbnailAsync from 'views/media-thumbnail/media-thumbnail-async';

const Page = {
    oninit: ({state, attrs: {index}}) => {
        state.pageNumber = index + 1;
    },

    view: ({state, attrs: {pageId, index, onClick}}) => {        
        const page = pageId ? createLayerFlow.pages[pageId] : {};
        return <div class={`page-wrap${createLayerFlow.sourcePageId === pageId ? ' selected' : ''}`}>
            <div class="page" onclick={() => onClick ? onClick() : createLayerFlow.selectPage(index)}>
                {page.thumbnailImageId ? <MediaThumbnailAsync mediaId={page.thumbnailImageId}/> : <div class="img-wrap"><span class="loader-v2 full-width"/></div>}
                {createLayerFlow.showPageNumbers ? <div class="page-num">Page {state.pageNumber}</div> : ''}
            </div>
        </div>;
    }
};

export default Page;

