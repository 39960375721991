import findAndRemove from 'util/data/find-and-remove';
import tableModel from './table/table-model';
import layerModel from './layer-model';

class PlaceBoundaryFilterModel {

    constructor() {
        this.selectedItems = [];
    }

    reset() {
        this.selectedItems = [];
    }

    onSelectPlace(place) {
        this.selectedItems.push(place.placeId);
        layerModel.turnOnPlace(place);
        tableModel.projectView.common.filtersChanged();
    }

    onDeletePlace(place) {
        this.selectedItems = findAndRemove(this.selectedItems, (placeInList) => {
            return placeInList === place.placeId;
        });
        m.redraw();
        tableModel.projectView.common.searchArea = undefined;
        tableModel.projectView.common.filtersChanged();
    }

}

export default new PlaceBoundaryFilterModel();
