import escape from 'util/dom/escape';

function element(strings, ...values) {

    const html = strings.map((string, i) => string + escape(values[i])).join('');

    const el = document.createElement('div');

    el.innerHTML = html;

    return el.firstElementChild;

}

export default element;
