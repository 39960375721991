import * as geo from 'util/geo';
import siteModel from 'models/site-model';
import VolumeDiff from 'views/volume';
import constants from 'util/data/constants';
import deepCloneObject from 'util/data/deep-clone-object';
import featureListManager from 'managers/feature-list-manager';
import TrimbleTip from 'views/trimble-tip/trimble-tip';

const states = new Set();

function getMeasurement(state, measurement, feature) {

    let display = state[measurement];

    if (!display) {

        display = state[measurement] = geo['getDisplay' + measurement](feature);

    }

    return `${display.string} ${display.metric}`;

}

function getVolume(state, measurement, feature) {

    if (!state.Volume) {

        geo.getDisplayVolume(feature).then(result => {

            state.Volume = result;

            m.redraw();

        });

        state.Volume = {
            cut: {},
            fill: {}
        };

    }

    return `${state.Volume[measurement].string || '...'} ${state.Volume[measurement].metric || ''}`;

}

const Point = {
    onremove: ({state}) => states.delete(state),
    oninit: ({state}) => states.add(state),
    view: ({attrs: {feature, onclick, header}}) => <div>
        {header ? header : <label>Point Details</label>}
        <div class="geometry-detail clear" onclick={onclick}>
            <div class="left half">Latitude</div>
            <div class="left half">{feature.geometry.coordinates[1]}</div>
            <div class="left half">Longitude</div>
            <div class="left half">{feature.geometry.coordinates[0]}</div>
        </div>
    </div>
};

const LineString = {
    onremove: ({state}) => states.delete(state),
    oninit: ({state}) => states.add(state),
    view: ({attrs: {feature, onclick, header}, state}) => <div>
        {header ? header : <label>Line Details</label>}
        <div class="geometry-detail clear" onclick={onclick}>
            <div class="left half">Length</div>
            <div class="left half">{getMeasurement(state, 'Length', feature)}</div>
        </div>
    </div>
};

const Polygon = {
    onremove: ({state}) => states.delete(state),
    oninit: ({state}) => states.add(state),
    view: ({attrs: {feature, onclick, header, customElement}, state}) => <div>
        {header ? header : <label>Polygon Details</label>}
        <div class="geometry-detail clear" onclick={onclick}>
            <div class="left half">Boundary</div>
            <div class="left half">{getMeasurement(state, 'Perimeter', feature)}</div>
            <div class="left half">Area</div>
            <div class="left half">{getMeasurement(state, 'Area', feature)}</div>
            {customElement}
            {siteModel.hasElevationData(feature)
                ? <><a onclick={() => {
                    state.volumeDiff = true;
                }} class={'vol-links' + (state.Volume ? '' : 'disabled')}>
                    <div class="left half cut">Cut</div>
                    <div class="left half cut">{getVolume(state, 'cut', feature)}</div>
                    <div class="left half fill">Fill</div>
                    <div class="left half fill">{getVolume(state, 'fill', feature)}</div>
                </a>
                <div class="clear"></div>
                <div class="geometry-detail-more-details"><span class="btn btn-smallest btn-pill btn-secondary" onclick={() => {
                    state.volumeDiff = true;
                }}>More Details</span></div>
                </>
                : null
            }
        </div>
        {state.volumeDiff && <VolumeDiff feature={feature} hide={() => {
            state.volumeDiff = null;
        }}/>}
    </div>
};

function multi(GeometryType) {
    return {
        view: ({attrs}) => {
            let feature = attrs.feature;
            const views = [];
            const nonMultiType = feature.geometry.type.replace('Multi', '');
            feature.geometry.coordinates.forEach(coordinates => {
                feature = deepCloneObject(feature);
                feature.geometry = {
                    type: nonMultiType,
                    coordinates
                };
                const featureAttrs = Object.assign({}, attrs, {feature});
                views.push(<GeometryType {...featureAttrs} />);
            });
            return views;
        }
    };
}

const geometryDetailTypes = {
    Polygon,
    LineString,
    // Point,
    MultiPolygon: multi(Polygon),
    MultiLineString: multi(LineString),
    MultiPoint: multi(Point)
};

const GeometryDetail = {

    update: () => states.forEach(state => {

        Object.assign(state, {
            Perimeter: undefined,
            Area: undefined,
            Volume: undefined,
            Length: undefined
        });

        m.redraw();

    }),

    view: ({attrs: {featureId, mockFeature, header, customElement}}) => {
        const feature = featureListManager.getById(featureId) || mockFeature;

        if (feature) {

            const Detail = geometryDetailTypes[feature.geometry.type];

            if (Detail) {

                const elements = [];

                elements.push(<Detail feature={feature} header={header} customElement={customElement}/>);

                if (feature.properties[constants.trimbleDataKey]) {
                    elements.push(<TrimbleTip feature={feature}/>);
                }

                return elements;

            }

        }

    }
};

export default GeometryDetail;
