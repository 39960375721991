import formModel from 'models/form-model';
import AssetOptionsModel from 'models/asset/asset-options-model';
import pointMenu from 'legacy/components/point-menu';

const AssetFormShortcuts = {
    view: ({attrs: {assetId, onClickHandler}}) => {
        return <div class="form-tabs-shortcuts">    
            <span class="form-tab-shortcut-btn shortcut-properties" onclick={(e) => onClickHandler ? onClickHandler(e, 'Properties') : formModel.viewAsset(assetId, 'Properties')}>
                <i class="icon-asset-form"/><span class="form-tab-shortcut-btn-text">Properties</span> 
            </span>
            <span class="form-tab-shortcut-btn shortcut-links" onclick={(e) => onClickHandler ? onClickHandler(e, 'Links') : formModel.viewAsset(assetId, 'Links')}>
                <i class="icon-link"/><span class="form-tab-shortcut-btn-text">Links</span>
            </span>
            <span class="form-tab-shortcut-btn shortcut-comments" onclick={(e) => onClickHandler ? onClickHandler(e, 'Comments') : formModel.viewAsset(assetId, 'Comments')}>
                <i class="icon-comment"/><span class="form-tab-shortcut-btn-text">Comments</span>
            </span>
            {AssetOptionsModel.showPeopleMenuItem() &&
            <>
                <span className="form-tab-shortcut-btn shortcut-people" onclick={(e) => {
                    onClickHandler ? onClickHandler(e, 'People') : formModel.viewAsset(assetId, 'People');
                    pointMenu.close();
                }}>
                    <i className="icon-user" /><span class="form-tab-shortcut-btn-text">People</span>
                </span>
            </>}
            {AssetOptionsModel.showPlacesMenuItem() &&
            <>
                <span className="form-tab-shortcut-btn shortcut-places" onclick={(e) => {
                    onClickHandler ? onClickHandler(e, 'Places/Levels') : formModel.viewAsset(assetId, 'Places/Levels');
                    pointMenu.close();
                }}>
                    <i className="icon-place-with-levels" /><span class="form-tab-shortcut-btn-text">Places/Levels</span>
                </span>
            </>}
            <span class="form-tab-shortcut-btn shortcut-permissions" onclick={(e) => {
                onClickHandler ? onClickHandler(e, 'Permissions') : formModel.viewAsset(assetId, 'Permissions');
            }}>
                <i class="icon-permission"/><span class="form-tab-shortcut-btn-text">Permissions</span>
            </span>
        </div>;
    }
};

export default AssetFormShortcuts;

