import volumeModel from 'models/volume-model';
import isMetric from 'util/numbers/is-metric';

const VolumeLegend = {
    view: () => {
        if (volumeModel.hasHeatMap) {
            const tickCountMinus1 = volumeModel.legendTickCount - 1;
            return <div class="volume-legend clear">
                <div class="volume-legend-metric">Δ {isMetric() ? 'm' : 'yd'}</div>
                <div class="volume-legend-bar" />
                <div class="volume-legend-ticks">
                    {new Array(volumeModel.legendTickCount).fill().map((_, i) => {
                        i -= tickCountMinus1 / 2;
                        return <div class="volume-legend-stop" style={`height: calc(50vh / ${tickCountMinus1});`}>
                            {volumeModel.legendInterval * -i}
                        </div>;
                    })}
                </div>
            </div>;
        }
        return null;
    }
};

export default VolumeLegend;
