import drawPaletteModel from 'models/draw-palette-model';

const Textarea = {
    view: ({ attrs: { propKey} }) => {
        const value = drawPaletteModel.activeFeature.properties[propKey]; 
        return <div class="control-group">
            <textarea oninput={e => {
                e.stopPropagation();
                drawPaletteModel.handleInput(propKey, e.target.value);
            }} value={value} />
        </div>;
    }
};

export default Textarea;
