import App from 'views/app';
import authManager from 'managers/auth-manager';
import {doShowChangePassword, doShowNotifications} from 'util/users/permissions';
import peopleModel from 'models/people/people-model';
import modalModel from 'models/modal-model';
import {Avatar} from 'views/person/components';
import CloseButton from 'views/close-button';
import Preferences from 'views/notifications/preferences';
import Profile from 'views/person/profile';
import ChangePassword from 'views/person/change-password';
import notificationsModel from 'models/notifications-model';

const Sidebar = {
    view: ({attrs: {person}}) => {
        return <> <Avatar person={person} size={'large'}/>
            <div class="menu-title">{person.displayNameOr(person.emailAddress)}</div>
            <ul class="menu-links">
                <li class="menu-item profile">
                    <a class={`${peopleModel.activeMenuItem === 'profile' ? 'active' : 'inactive'}`} onclick={() => {
                        peopleModel.resetSavingValues();
                        peopleModel.selectMenuItem('profile');
                    }}>Profile</a>
                </li>
                {doShowNotifications(person) && <li class="menu-item notifications">
                    <a class={`${peopleModel.activeMenuItem === 'notifications' ? 'active' : 'inactive'}`} onclick={() => {
                        peopleModel.resetSavingValues();
                        peopleModel.selectMenuItem('notifications');
                    }}>Notification Preferences</a>
                </li>}
                {doShowChangePassword(person) && <li class="menu-item password">
                    <a class={`${peopleModel.activeMenuItem === 'password' ? 'active' : 'inactive'}`} onclick={() => peopleModel.selectMenuItem('password')}>Password</a>
                </li>}
            </ul>
            {person.isSelf && <div class="btn btn-pill btn-secondary btn-small" onclick={() => App.onNextRender(authManager.signOut)}><span class="btn-label">Sign Out</span></div> }
        </>; 
    }
};

const content = {
    profile: Profile,
    notifications: Preferences,
    password: ChangePassword
};

const PersonModule = {
    oninit: ({state}) => {
        state.person = peopleModel.viewingPerson;
        state.person.didSaveValue = false;
        notificationsModel.didSaveValue = false;
    },

    view: ({state}) => {
        const shouldShowFooter = peopleModel.activeMenuItem === 'profile' || peopleModel.activeMenuItem === 'notifications';
        const View = content[peopleModel.activeMenuItem];
        return <div class="person-module modal-wrap dark-theme">
            <div class="person-module-sidebar me-menu vertical-menu">
                <Sidebar person={state.person}/>
            </div>
            <div class="person-module-content">
                <div class="modal-vertical-fixed"><CloseButton onclick={() => modalModel.close()}/></div>
                <div class="modal-vertical-scroll">
                    <View person={state.person}/>
                </div>
                {shouldShowFooter &&
                    <div className="profile-footer">
                        <span>
                            {state.person.isNewUser ?
                                <button
                                    className={`btn btn-pill btn-small btn-primary UFtracker-add-person-to-account ${state.person.formState.isSavingNew ? 'saving' : ''}`}
                                    type="submit" onclick={() => peopleModel.submitNewPersonForm(state.person)}>
                                    <span className="button-text">Add to Account</span></button> :
                                <>
                                    {state.person.formState.isSaving || notificationsModel.isSavingValue ?
                                        <div className={`${state.person.formState.isSaving ? ' saving autosave-status-text status-changed' : 'autosave-status-text status-changed'}`}>
                                            <i className="spinner spinning teal profile-spinner"/><span
                                                class="has-spinner">Saving</span>
                                        </div> : <>
                                            {state.person.didSaveValue || notificationsModel.didSaveValue ?
                                                <div className="autosave-status-text status-changed">Saved</div> :
                                                <div class="autosave-status-text">Unchanged</div>
                                            }
                                        </>
                                    }
                                    <button
                                        className={`btn btn-pill btn-small btn-primary ${state.person.formState.isSavingNew ? 'saving' : ''}`}
                                        type="submit" onclick={() => modalModel.close()}>
                                        <span className="spinner"></span><span className="button-text">Done</span>
                                    </button>
                                </>
                            }
                        </span>
                    </div>
                }
            </div>
        </div>;
    }
};

export default PersonModule;
