import {styleControls, unitsDisplay, rangeSettings} from 'constants/models/draw-palette-constants';
import BorderPicker from 'views/draw-palette/controls/border-picker';
import ColorSelector from 'views/draw-palette/controls/color-selector';
import DPad from 'views/draw-palette/controls/dpad';
import Icon from 'views/draw-palette/controls/icon';
import Range from 'views/draw-palette/controls/range';
import RotationToggle from 'views/draw-palette/controls/rotation-toggle';
import Textarea from 'views/draw-palette/controls/textarea';
import drawPaletteModel from 'models/draw-palette-model';

export const styleControlOpts = {
    [styleControls.LINE_COLOR]: {
        label: () => 'Color',
        ui: () => <ColorSelector propKey={styleControls.LINE_COLOR} />
    },
    [styleControls.LINE_DASH_ARRAY]: {
        label: () => 'Pattern',
        ui: () => <BorderPicker propKey={styleControls.LINE_DASH_ARRAY}/>
    },
    [styleControls.LINE_WIDTH_PX]: {
        label: () => 'Width',
        ui: () => <Range propKey={styleControls.LINE_WIDTH_PX} min={rangeSettings.stepWhole} max={100} step={rangeSettings.stepWhole} units={unitsDisplay.pixelsShort}/>
    },
    [styleControls.LINE_WIDTH_METERS]: {
        label: () => 'Width',
        ui: () => <Range propKey={styleControls.LINE_WIDTH_METERS} min={0} max={100} step={rangeSettings.stepFractional} units={drawPaletteModel.units} />
    },
    [styleControls.LINE_OPACITY]: {
        label: () => 'Opacity',
        ui: () => <Range propKey={styleControls.LINE_OPACITY} min={0} step={10} max={100} units={unitsDisplay.percentShort}/>
    },
    [styleControls.ICON_IMAGE]: {
        label: () => 'Icon',
        ui: () => <Icon propKey={styleControls.ICON_IMAGE} />
    },
    [styleControls.ICON_SIZE_PX]: {
        label: () => 'Width',
        ui: () => <Range propKey={styleControls.ICON_SIZE_PX} min={rangeSettings.stepWhole} max={100} step={rangeSettings.stepWhole} units={unitsDisplay.pixelsShort} />
    },
    [styleControls.ICON_SIZE_METERS]: {
        label: () =>  'Width',
        ui: () => <Range propKey={styleControls.ICON_SIZE_METERS} min={rangeSettings.stepFractional} max={100} step={rangeSettings.stepFractional} units={drawPaletteModel.units} />
    },
    [styleControls.FILL_COLOR]: {
        label: () => 'Color',
        ui: () => <ColorSelector propKey={styleControls.FILL_COLOR} />
    },
    [styleControls.FILL_OPACITY]: {
        label: () => 'Opacity',
        ui: () => <Range propKey={styleControls.FILL_OPACITY} min={0} max={100} step={10} units={unitsDisplay.percentShort}/>
    },
    [styleControls.TEXT_COLOR]: {
        label: () => 'Text Color',
        ui: () => <ColorSelector propKey={styleControls.TEXT_COLOR} />
    },
    [styleControls.TEXT_SIZE_PX]: {
        label: () => 'Text Size',
        ui: () => <Range propKey={styleControls.TEXT_SIZE_PX} min={rangeSettings.stepWhole} max={200} step={rangeSettings.stepWhole} units={unitsDisplay.pixelsShort}/>
    },
    [styleControls.TEXT_SIZE_METERS]: {
        label: () => 'Text Size',
        ui: () => <Range propKey={styleControls.TEXT_SIZE_METERS} min={0} max={200} step={rangeSettings.stepFractional} units={drawPaletteModel.units} />
    },
    [styleControls.TEXT_HALO_COLOR]: {
        label: () => 'Stroke Color',
        ui: () => <ColorSelector propKey={styleControls.TEXT_HALO_COLOR}/>
    },
    [styleControls.TEXT_HALO_WIDTH_PX]: {
        label: () => 'Stroke Size',
        ui: () => <Range propKey={styleControls.TEXT_HALO_WIDTH_PX} min={0} max={20} step={rangeSettings.stepWhole} units={unitsDisplay.pixelsShort} />
    },
    [styleControls.TEXT_HALO_WIDTH_METERS]: {
        label: () => 'Stroke Size',
        ui: () => <Range propKey={styleControls.TEXT_HALO_WIDTH_METERS} min="0" max="20" step={rangeSettings.stepFractional} units={drawPaletteModel.units} />
    },
    [styleControls.ROTATION]: {
        label: () => `${drawPaletteModel.activeFeature && drawPaletteModel.activeFeature.geometry.type === 'LineString' ? 'Flip Icon' : drawPaletteModel.isFeatureAnIcon() ? 'Rotation' : 'Rotation'}`,
        ui: () =>
            drawPaletteModel.activeFeature && drawPaletteModel.activeFeature.geometry.type === 'LineString'
                ? <RotationToggle propKey={styleControls.ROTATION} />
                : <Range propKey={styleControls.ROTATION} min="0" max="359" step={rangeSettings.stepWhole} units={unitsDisplay.degreesShort} />
    },
    [styleControls.TEXT_FIELD]: {
        label: () => 'Text Value',
        ui: () => <Textarea propKey={styleControls.TEXT_FIELD} />
    },
    [styleControls.TEXT_OFFSET]: {
        label: () => 'Text Position',
        ui: () => <DPad propKey={styleControls.TEXT_OFFSET} />
    }
};

const StyleControl = {

    view: ({attrs: {styleControlKey}}) => {
        const styleControl = styleControlOpts[styleControlKey];
        return <fieldset key={styleControlKey} class={`control${styleControlKey}`}>
            <label>{styleControl.label()}</label>
            {styleControl.ui()}
        </fieldset>;
    }
};

export default StyleControl;
