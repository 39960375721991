import appModel from 'models/app-model';
import reverseLoop from 'util/data/reverse-loop';

/*
 * Returns true if toolbox has a project tool.
 */
export const isMetaToolbox = (toolbox) => {
    return !!toolbox.group.groups.find(toolGroup => toolGroup.tools.find(tool => tool.featureTypes && tool.featureTypes.find(featureType => featureType.attributes && featureType.attributes.interface === 'project')));
};

/*
 * Sorts toolgroups and tools according to zOrders. Unless specified otherwise, also removes tools marked as "hidden" or restricted by user's role.
 */
export const sortAndCleanToolbox = (toolbox, removeHidden = true, removeRestricted = true) => {
    const toolGroupOrder = toolbox.attributes.toolGroupOrder || [];
    const sortedToolGroups = new Array(toolGroupOrder.length);
    const toolZOrder = toolbox.attributes.toolZOrder || [];
    
    toolbox.group.groups.forEach(toolGroup => {
        const toolOrder = toolGroup.attributes.toolOrder || [];
        const groupIndex = toolGroupOrder.indexOf(toolGroup.name);
        if (groupIndex === -1) {
            sortedToolGroups.push(toolGroup);
        } else {                
            sortedToolGroups[groupIndex] = toolGroup;
        }

        // looping in reverse makes it safe to remove items
        // while we continue iterating
        reverseLoop(toolGroup.tools, (tool, i) => {
            tool.zOrder = toolZOrder.indexOf(tool.name);
            tool.uiOrder = toolOrder.indexOf(tool.name);
            if (removeHidden && tool.attributes.hidden) {
                toolGroup.tools.splice(i, 1);
            } else if (removeRestricted && tool.attributes.restrictions) {
                const restrictions = tool.attributes.restrictions;
                const restrictionsForRole = restrictions[appModel.user.role];
                if (!appModel.user.isSuperAdmin && restrictionsForRole && restrictionsForRole.READ === false) {
                    toolGroup.tools.splice(i, 1);
                }
            }
        });
        toolGroup.tools.sort((a, b) => a.uiOrder - b.uiOrder);
    });
    toolbox.group.groups = sortedToolGroups;

    return toolbox;
};

/*
 * Returns either the provided toolbox plus an initted attributes object or a toolbox skeleton.
 */
export const normalizeToolboxObject = (toolbox) => {
    if (typeof toolbox !== 'object') {
        toolbox = {
            attributes: {},
            group: {
                groups: []
            }
        };
    }

    if (toolbox) {
        toolbox.attributes = toolbox.attributes || {};
    }
    return toolbox;
};
