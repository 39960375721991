import pointMenu from 'legacy/components/point-menu';
import convertMithril from 'util/dom/convert-mithril';
import formModel from 'models/form-model';
import assetListManager from 'managers/asset-list-manager';
import mediaViewerModel from 'models/media-viewer-model';
import screenHelper from 'legacy/util/device/screen-helper';
import tableModel from 'models/table/table-model';
import store from 'util/data/store';
import popupModel from 'models/popover-model';
import appModel from 'models/app-model';
import LinksModel from 'models/links-model';
import PermissionsIndicator from 'views/permissions/permissions-indicator';
import model from 'models/asset/asset-options-model';
import AssetOptionsModel from 'models/asset/asset-options-model';
import router from 'uav-router';
import AssetFormShortcuts from 'views/asset-options/asset-form-shortcuts';
import siteModel from 'models/site-model';
import exportPDFModel from 'models/export-pdf-model';

export function tinyAssetPopup(assetId, control) {
    const asset = store.assets[assetId];
    const isLink = assetListManager.isLink(assetId);
    const isMapped = asset.featureIds && asset.featureIds.length || assetListManager.getCompleteLayerId(assetId);
    // Unlink function depends upon whether it's a named link (ie, assigned to a control) or a general link we're unlinking
    const canUnlink = isLink && appModel.user.permissions.canEditContent(router.params.assetId);
    const unlinkFn = control ? () => formModel.removeNamedLink(assetId, control.fieldName) : () => LinksModel.unlink(assetId, formModel.assetId);
    const unlinkText = control ? 'Remove' : 'Unlink';
    return convertMithril.toDom(<div class="link-asset-options">
        <div class="link-menu">
            <AssetFormShortcuts assetId={assetId} />
            {<div class={`menu-option ${AssetOptionsModel.editContentClass(router.params.assetId)}`} onclick={canUnlink ? unlinkFn : null}>
                <PermissionsIndicator lockedMessage={AssetOptionsModel.editContentMessage(router.params.assetId)} />
                <span class="menu-option-inner"><i class="icon-unlink" /> {unlinkText}</span>
            </div>}
            {isMapped && <div class="menu-option" onclick={() => {
                assetListManager.centerOnMap(assetId);
                pointMenu.close();
            }}><i class="icon-center-on-map UFtracker-center-on-map" /> Center on Map</div>}
        </div>
    </div>);
}

function assetPopup(assetId) {
    const asset = store.assets[assetId];
    const tool = appModel.toolbox.tools[asset.attributes.toolId];
    const hasFeatures = model.hasFeatures(assetId);
    const showUnlinkOption = AssetOptionsModel.showUnlinkOption(assetId);

    // Check for potential named link property changes
    let namedLinks = LinksModel.getNamedLinks(formModel.assetId)[assetId];
    let primaryAssetId = formModel.assetId;
    let namedLinkId = assetId;
    if (showUnlinkOption && !namedLinks) {
        // Check if a named link exists in the other direction
        namedLinks = LinksModel.getNamedLinks(assetId)[formModel.assetId];
        primaryAssetId = assetId;
        namedLinkId = formModel.assetId;
    }
    return convertMithril.toDom(<div>
        {(formModel.assetId !== assetId || siteModel.isTableActive()) && <AssetFormShortcuts assetId={assetId}/>}
        {showUnlinkOption && <div className="menu-option" onclick={() => {
            namedLinks ? formModel.viewAsset(primaryAssetId, 'Properties') : LinksModel.unlink(primaryAssetId, namedLinkId);
            pointMenu.close();
        }}>
            <i className="icon-unlink" /> {namedLinks ? `Edit ${namedLinks.length > 1 ? 'Linked Properties' : namedLinks[0] + ' Property'}` : 'Unlink'}
        </div>}
        {model.showMediaViewOption(assetId) &&
            <div className="menu-option" onclick={() => {
                mediaViewerModel.open(asset.mediaIds);
                pointMenu.close();
            }}>
                <span class="menu-option-inner"><i class="icon-expand" /> View Media</span>
            </div>}
        {hasFeatures || assetListManager.getCompleteLayerId(assetId)
            ? <div class="menu-option map-status" onclick={() => {
                assetListManager.centerOnMap(assetId);
                pointMenu.close();

                if (screenHelper.small()) {
                    formModel.visible = false;
                    if (!tableModel.isCollapsed) {
                        tableModel.sideBarToggle();
                    }
                    m.redraw();
                }
            }}>
                <span class="menu-option-inner"><i class="icon-center-on-map" /> Center on Map</span>
            </div>
            : model.showAddToMapOption(assetId) && <div class={`menu-option map-status ${model.editFeatureClass(assetId)}`} onclick={() => model.handleClickAddToMap(assetId)}>
                <span class="tip-text">This asset is unmapped.</span>
                <PermissionsIndicator lockedMessage={model.editFeatureMessage(assetId)} />
                <span class="menu-option-inner"><i class="icon-add-to-map" /> Add to Map</span>
            </div>
        }
        {model.showExportContentPDFOption(assetId) && <div class={'menu-option'} onclick={() => {
            pointMenu.close();
            exportPDFModel.openContentCard(assetId);
        }}>
            <span class="menu-option-inner"><i class="icon-download" /> Export PDF Card</span>
        </div>}
        {appModel.project.isMetaProject && 
          <div class="menu-option" onclick={() => model.handleCopyProjectLink(assetId)}>
              <span class="menu-option-inner"><i class="icon-duplicate" /> Copy Project link</span>
          </div>}
        {appModel.project.isMetaProject && 
          <div class="menu-option" onclick={() => model.handleOpenProjectInNewTab(assetId)}>
              <span class="menu-option-inner"><i class="icon-window" /> Open Project in new tab</span>
          </div>}
        {model.showRemoveFromMapOption(asset, hasFeatures, tool) &&
            <div class={`menu-option ${model.editFeatureClass(assetId)}`} onclick={() => model.handleClickRemoveFromMap(asset.contentId)}>
                <PermissionsIndicator lockedMessage={model.editFeatureMessage(assetId)} />
                <span class="menu-option-inner"><i class="icon-remove-from-map" /> Remove from Map</span>
            </div>
        }
        {model.showSupportsCloningOption(assetId) && <div class={`menu-option ${model.duplicateClass(assetId)}`} onclick={() => model.handleClickDuplicate(assetId)}>
            <PermissionsIndicator lockedMessage={model.duplicateMessage(assetId)} />
            <span class="menu-option-inner"><i class="icon-duplicate" /> Duplicate</span>
        </div>}
        <div class={`menu-option remove-link ${model.deleteClass(assetId)}`} onclick={() => model.handleClickDelete(assetId)}>
            <PermissionsIndicator lockedMessage={model.deleteMessage(assetId)} />
            <span class="menu-option-inner"><i class="icon-trash" /> Delete</span>
        </div>
    </div>);

}

const AssetOptions = {
    view: ({attrs: {onOpen, onClose, assetId, popupContent}}) => {
        return model.showAssetOptions(assetId) ?
            <i class="icon-ellipsis asset-options UFtracker-open-instance3dotsmenu" onclick={e => {
                popupModel.close();
                return pointMenu({
                    e,
                    content: popupContent ? popupContent : () => assetPopup(assetId),
                    cssClass: 'link-list asset-options',
                    forceDesktop: true,
                    noLine: true,
                    onopen: onOpen,
                    onclose: onClose
                });
            }} /> : null;
    }
};
export default AssetOptions;
