import stopPropagation from 'util/events/stop-propagation';

const PlaceList = {
    oninit: ({attrs: {model}}) => {
        model.index = 0;
    },

    view: ({state, attrs: {model, places, openUp, boundaryFilter}}) => {
        state.sortedPlaces = places.sort((place1, place2) => place1.name.toLowerCase() > place2.name.toLowerCase() ? 1 : -1);
        const areAllItemsSelected = model.places.length === Object.values(model.selectedMap).length;
        return <div className={`place-list-wrap ${openUp || model.openUp ? 'up' : ''} ${model.openAbove ? 'open-above' : 'open-below'}`}>
            <div className={`place-list ${boundaryFilter ? 'boundary-filter' : ''}`}>
                {!openUp && <input className="place-search-input"
                    id="place-search"
                    oninput={model.onInput}
                    onkeydown={model.onKeyDown}
                    onclick={stopPropagation()}
                />}
                {areAllItemsSelected && <span class="all-added-note">All available tags have been added.</span>}
                {places.length === 0 && !areAllItemsSelected ?
                    'No matches found.' :
                    state.sortedPlaces.map((place, i) =>
                        <div className={`place-item${i === model.index ? ' active' : ''}`}
                            onclick={(e) => model.onSelect(e, place)}>
                            {place.name}
                        </div>
                    )}
                {openUp && 
                    <><div className="place-input"><input className="place-search-input"
                        id="place-search"
                        oninput={model.onInput}
                        onkeydown={model.onKeyDown}
                        onclick={stopPropagation()}
                    />
                    <i className="icon-search"></i></div>
                    </>}
            </div>
        </div>;
    }
};

export default PlaceList;
