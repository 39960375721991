import Polygon from 'util/draw/polygon';
import PolylineInterface from 'util/interfaces/polyline';

class PolygonInterface extends PolylineInterface {

    constructor(...args) {

        super(...args);

        this.type = 'polygon';

        this.DrawClass = Polygon;

        this.init();

    }

    updatePrompt() {

        if (this.feature.geometry.coordinates.length > 3) {

            this.prompt = {view: () => 'Tap the first point to complete the ' + this.tool.name};

        }

    }

}

export default PolygonInterface;
