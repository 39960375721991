import initializer from 'util/initializer';
import getToolInterface from 'util/interfaces/get-tool-interface';
import api from 'legacy/util/api';
import router from 'uav-router';
import formModel from 'models/form-model';
import constants from 'util/data/constants';
import store from 'util/data/store';
import siteModel from 'models/site-model';
import message from 'views/toast-message/toast-message';
import previewToolbox from 'util/data/preview-toolbox';
import Table from 'views/table/table';
import assetListManager from 'managers/asset-list-manager';
import panelModel from 'models/panel-model';
import appModel from 'models/app-model';
import {normalizeToolboxObject} from 'util/data/toolbox-helpers';
import {datadogRum} from '@datadog/browser-rum';
import isMetric from 'util/numbers/is-metric';
import measure from 'legacy/util/numbers/measure';
import permissionsManager from 'managers/permissions-manager';
import ToolGroup from 'models/toolbox/toolgroup/toolgroup-model';
import capitalize from 'util/data/capitalize';
import {FIELD_PLAN_ID, FIELD_PLAN_ID_ANNUAL} from 'constants/managers/account-constants.js';
import {TOOLKIT_DISABLE_MODIFY_FLAG, TOOLKIT_DISABLE_EXPORTS_FLAG, LINK_TOOLS, FILE_TOOL_GROUP_NAME} from 'constants/managers/toolbox-constants.js';
import toolboxSelector from 'views/toolbox-selector';
import planModel from 'models/plan-model';
import publish from 'legacy/util/api/publish';
import stakeableModel from 'models/stakeable-model';
import drawPaletteModel from 'models/draw-palette-model';
import AssetModel from 'models/asset/asset-model';
import tableModel from 'models/table/table-model';
import addIfNotInList from '../util/data/add-if-not-in-list';

export const VECTOR_FORCE_MISSOURI_TOOLBOX_ID_PROD = 'QBKtjbQjZw_aTRuIRgYf';
export const VECTOR_FORCE_MISSOURI_TOOLBOX_ID_STAGING_CLONE = 'pGqq-Oo3ImJXegoMlcQF';
export const VECTOR_FORCE_MISSOURI_TOOLBOX_ID_DEV_CLONE = 'CsrxcgnLX1soe3ZhjcXK';

class ToolboxManager {
    constructor() {
        this.isMetric = true; // default to true, but we will check isMetric() on reset
        this.reset();
        initializer.addOnInit('toolbox', () => appModel.toolbox.reset());
    }

    reset() {
        this.linkTools = LINK_TOOLS;
        this.toolboxId = undefined;
        this.tool = null;
        this.toolInterface = null;
        this.geometryFeatures = [];
        this.imageLoadingPromises = [];
        this.attributes = {};
        this.customExports =  {};
        this.projectTools = {};
        this.assetTypesWithUserControls = {};
        this.assetTypesWithLinkControls = [];
        this.assetTypesWithSurveyControls = {};
        this.assetTypesWithPlanControls = {};
        this.siteTermSingular = 'site';
        this.siteTermPlural = 'sites';
        this.siteTermSingularCapitalized = 'Site';
        this.siteTermPluralCapitalized = 'Sites';
        this.importMode = false;
        this.shortToolNames = {};
        this.showImportPrompt = false;
        this.hasAtLeastOneTool = false;
        this.featureTypeUnitConversion = {};
        this.isMetric = isMetric();
        this._hasMultipleGroups = null;

        this.toolGroups = {};
        this.tools = {};
        this.featureTypes = {};
        this.featureStyles = {};
        this.hiddenTools = {};
        this.savedStyles = {};
        this.savedStylesByToolGroupId = {};
        this.savedStylesByToolId = {};
    }

    init() {
        return (appModel.inPreviewMode ? previewToolbox() : api.rpc.requests([
            ['listProjectToolboxes', {projectId: router.params.projectId}]
        ])).then(async ([[_toolbox]]) => {
            initializer.initManager('toolbox');
            _toolbox = window.mockToolbox || normalizeToolboxObject(_toolbox);
            store.toolboxId = this.toolboxId = _toolbox.toolboxId;
            // Set toolbox attributes to manager object
            this.baseToolboxId = _toolbox.base ? _toolbox.base.toolboxId : this.toolboxId;
            this._setProgressDashID(_toolbox);
            this._setFeatureFlags(_toolbox);
            this._setCustomExports(_toolbox);
            this._setSiteTerms(_toolbox);
            this._sortAndLoadTools(_toolbox);
            this._addDataDog(_toolbox);


            if (!appModel.project.isMetaProject) {
                toolboxManager.showImportPrompt = true;
                toolboxManager.loadMetaToolboxOverrides();
            }

            this._initToolLevelPermissions();
            this._cleanUpAcceptedAssetTypeIds();

            await this._loadSavedStyles();
            
            const isViewingAsFieldTrial =  !router.params.assetId && (appModel.user.isViewingAs(FIELD_PLAN_ID) || appModel.user.isViewingAs(FIELD_PLAN_ID_ANNUAL)); 
            if (appModel.project.isMetaProject && appModel.account.isFieldTrial && appModel.user.isFirstVisit
                || isViewingAsFieldTrial) {
                appModel.flows.uploadHelp.start();
            }
            if (this.isEditableToolbox()) {
                this.awaitChanges();
            }
            m.redraw();
            return Promise.all(this.imageLoadingPromises);
        });
    }

    async _loadSavedStyles() {
        const savedStyles = await this.fetchSavedStyles(this.toolboxId);
        savedStyles.forEach(style => {
            const toolGroupId = style.attributes.toolGroupId; 
            const toolId = style.attributes.toolId;
            const assetTypeId = this.tools[toolId] && this.tools[toolId].assetForm.assetType.assetTypeId;
            if (assetTypeId && appModel.user.permissions.canCreateAssetType(assetTypeId)) {
                if (this.savedStylesByToolGroupId.hasOwnProperty(toolGroupId)) {
                    this.savedStyles[style.toolStyleId] = style;
                    addIfNotInList(this.savedStylesByToolGroupId[toolGroupId], style.toolStyleId);
                    this.savedStylesByToolId[toolId] = this.savedStylesByToolId[toolId] || [];
                    addIfNotInList(this.savedStylesByToolId[toolId], style.toolStyleId);
                }
            }
        });    
    }

    async fetchSavedStyles(toolboxId) {
        return api.rpc.request([['listToolStyles', {
            toolboxId,
            creatorId: appModel.user.userId,
            type: 'shortcut',
            order: 'createdDateTime desc',
            isVisible: true
        }]]);
    }

    get hasMultipleGroups() {
        if (this._hasMultipleGroups === null) {
            this._hasMultipleGroups = Object.values(toolboxManager.toolGroups).filter(group => group.tools.length).length > 1;
        }
        return this._hasMultipleGroups;
    }

    get areExportsEnabled() {
        return !this.toolboxFeatures || !this.toolboxFeatures[TOOLKIT_DISABLE_EXPORTS_FLAG];
    }

    isEditableToolbox() {
        const features = toolboxManager.toolboxFeatures || {};
        return !features[TOOLKIT_DISABLE_MODIFY_FLAG] && !this.hasCustomReports() && !siteModel.canBatchModify;
    }

    hasCustomReports() {
        return Object.values(this.customExports) && Object.values(this.customExports).length > 0;
    }

    _initToolLevelPermissions() {
        permissionsManager.initToolPermissions();
    }

    _setFeatureFlags(_toolbox) {
        toolboxManager.toolboxFeatures = _toolbox.attributes.featureFlags;
    }

    _setCustomExports(_toolbox) {
        toolboxManager.customExports = {};
        if (_toolbox.attributes.customExports) {
            _toolbox.attributes.customExports.forEach(exp => {
                if (exp.name && exp.id) {
                    toolboxManager.customExports[exp.name] = exp.id;
                }
            });
        }
    }

    _setSiteTerms(_toolbox) {
        if (_toolbox.attributes.projectSingular) {
            toolboxManager.siteTermSingular = _toolbox.attributes.projectSingular;
            toolboxManager.siteTermSingularCapitalized = capitalize(_toolbox.attributes.projectSingular);
        }
        if (_toolbox.attributes.projectPlural) {
            toolboxManager.siteTermPlural = _toolbox.attributes.projectPlural;
            toolboxManager.siteTermPluralCapitalized = capitalize(_toolbox.attributes.projectPlural);
        }
    }

    _sortAndLoadTools(_toolbox) {
        const toolZOrder = _toolbox.attributes.toolZOrder || [],
            toolGroupOrder = _toolbox.attributes.toolGroupOrder || [],
            sortedToolGroups = new Array(toolGroupOrder.length);

        _toolbox.group.groups.forEach(toolGroup => {
            const _toolGroup = new ToolGroup(toolGroup, toolZOrder);
            if (_toolGroup.hasSavedStylesEnabled) {
                this.savedStylesByToolGroupId[toolGroup.toolGroupId] = [];
            }
            const groupIndex = toolGroupOrder.indexOf(_toolGroup.name);
            
            if (groupIndex === -1) {
                sortedToolGroups.push(_toolGroup);
            } else {
                sortedToolGroups[groupIndex] = _toolGroup;
            }

        });

        sortedToolGroups.forEach(toolGroup => {
            if (toolGroup.tools && toolGroup.tools.length) {
                toolboxManager.toolGroups[toolGroup.toolGroupId] = toolGroup;
            }
        });
    }

    _cleanUpAcceptedAssetTypeIds() {
        this.assetTypesWithLinkControls.forEach(assetTypeId => {
            const assetForm = store.assetForms[store.assetTypeToFormId[assetTypeId]];
            const linkControls = assetForm.controls.filter(c => c.controlTypeId === constants.controlTypeNameToId.asset);
            linkControls.forEach(control => {
                if (control.attributes && control.attributes.acceptedAssetTypeIds) {
                    const newAcceptedAssetTypeIds = [];
                    control.attributes.acceptedAssetTypeIds.forEach(id => {
                        const baseAssetTypeTool = Object.values(toolboxManager.tools).find(tool => tool.assetForm.assetType.base && tool.assetForm.assetType.base.assetTypeId === id || tool.assetForm.assetType.assetTypeId === id);
                        if (baseAssetTypeTool && !appModel.user.permissions.hiddenAssetTypes[baseAssetTypeTool.assetForm.assetType.assetTypeId]) {
                            newAcceptedAssetTypeIds.push(id);
                        }
                    });
                    control.attributes.acceptedAssetTypeIds = newAcceptedAssetTypeIds;
                }            
            });
        });
    }

    setMetricConversionFn(control, featureTypeId) {
        if (this.isMetric) {
            // no conversion needed if the user is using metric already
            return;
        }
        let metricConversionFn;
        switch (control.controlTypeId) {
        case constants.controlTypeNameToId.length:
            metricConversionFn = measure.metersToFeet;
            break;
        case constants.controlTypeNameToId.area:
            metricConversionFn = measure.squareMetersToSquareFeet;
            break;
        case constants.controlTypeNameToId.volume:
            metricConversionFn = measure.cubicMetersToCubicYards;
            break;
        default:
            break;
        }
        if (metricConversionFn) {
            this.featureTypeUnitConversion[featureTypeId] =
            this.featureTypeUnitConversion[featureTypeId] || {};
            this.featureTypeUnitConversion[featureTypeId][control.fieldName] = metricConversionFn;
        }
    }

    isProjectTool(tool) {
        return !!tool.assetForm.controls.find(c => c.controlTypeId === constants.controlTypeNameToId.project);
    }

    toggleMode() {
        this.importMode = !this.importMode;
    }

    _setProgressDashID(_toolbox) {
        this.progressDashID = _toolbox.attributes.progressDashID;
    }

    // In case any overrides were set by the meta toolbox, apply them now.
    loadMetaToolboxOverrides() {
        const metaProjectId = store.account.attributes.metaProjectId;
        return api.rpc.requests([
            ['listProjectToolboxes', {projectId: metaProjectId}]
        ]).then(([[metaToolbox]]) => {
            if (metaToolbox && metaToolbox.attributes) {
                if (metaToolbox.attributes.projectSingular) {
                    toolboxManager.siteTermSingular = metaToolbox.attributes.projectSingular;
                }

                if (metaToolbox.attributes.projectPlural) {
                    toolboxManager.siteTermPlural = metaToolbox.attributes.projectPlural;
                }
            }
        });
    }

    closeActiveTool() {
        if (toolboxManager.toolInterface) {
            toolboxManager.toolInterface.close();
        }
        if (formModel.toolInterface) {
            formModel.close();
        }
    }

    launchToolWithoutPrompt(toolInterface) {
        panelModel.close();
        siteModel.view = null;
        siteModel.sidebar = Table; // return to the feed if tool launcher is closed

        toolboxManager.closeActiveTool();
        this.launchToolInterfaceTool(toolInterface, true);
    }
    
    saveNewAssets(assets) {
        if (assets && assets.length) {
            api.rpc.requests(assets.map(asset => {
                assetListManager.addToStore(asset);
                return ['createContent', api.apiSafeContent(asset)];
            })).then(([asset]) => {
                // If we have the asset locally but it didn't have an updatedDateTime yet, attach the updatedDateTime now,
                // but dont override any other changes the user might've made UE-4669
                const assetFromStore = store.assets[asset.contentId];
                if (assetFromStore && !assetFromStore.updatedDateTime) {
                    assetFromStore.updatedDateTime = asset.updatedDateTime;
                    store.assets[assetFromStore.contentId] = new AssetModel(assetFromStore);
                }
                if (assetListManager.hasUnsavedChanges[asset.contentId]) {
                    assetListManager.autosave(asset.contentId);
                }
            });
        }
    }

    launchToolInterfaceTool(toolInterface, hidePrompt = false) {
        toolInterface.launch(hidePrompt).then(assets => {
        
            if (assets) {
                toolboxManager.saveNewAssets(assets);
            }

            if (assets && assets.length === 1 && !planModel.isSelectingPages) {
                formModel.viewAsset(assets[0].contentId, 'Properties', true);
            } else if (assets && assets.length > 1) {
                if (toolboxManager.toolInterface) {
                    toolboxManager.toolInterface.close();
                }

                const assetTypeName = store.assetTypes[assets[0].assetTypeId].name;
                const messageElement = <div>{assets.length} new {assetTypeName} assets added.</div>;
                message.show(messageElement);

            } else if (toolboxManager.toolInterface) {
                toolboxManager.toolInterface.close();
            }
        });
    }

    selectTool(toolGroup, tool, e) {
        if (e) {
            e.stopPropagation();

        }

        panelModel.close();
        siteModel.view = null;
        siteModel.sidebar = Table; // return to the feed if tool launcher is closed

        toolboxManager.closeActiveTool();

        toolboxManager.activeGroupId = toolGroup.toolGroupId;

        const toolInterface = getToolInterface(tool);

        if (toolboxManager.importMode && toolGroup.name !== FILE_TOOL_GROUP_NAME) {
            return toolInterface.showToolLauncher();
        }
        this.launchToolInterfaceTool(toolInterface, false);
    }

    getShortToolName(tool) {
        // If the truncated version has not been generated yet, create it and cache it for reuse.
        if (!this.shortToolNames[tool.toolId]) {
            this.shortToolNames[tool.toolId] = tool.name.length > 25 ? `${tool.name.substring(0, 25)}...` : tool.name;
        }
        return this.shortToolNames[tool.toolId];
    }

    _addDataDog(toolbox) {
        // Add datadog and hubspot
        if (constants.isDeployed) {
            datadogRum.addRumGlobalContext('toolbox', {
                id: toolbox.toolboxId,
                name: toolbox.name,
                baseId: toolbox.base ? toolbox.base.toolboxId : undefined
            });
        }
    }

    async getToolsForToolboxId(toolboxId) {
        const [toolbox] = await api.rpc.request([['listToolboxes', {toolboxId, limit: 1}]]);
        const allTools = toolbox.group.groups.map(group => group.tools).flat();
        const tools = allTools.filter(tool => !tool.attributes || !tool.attributes.hidden);
        return tools;
    }

    /*
    * Finds the first project tool that has a predefined toolboxId and sets it as the current tool interface.
    */
    setProjectToolAsInterface() {
        const projectTool = Object.values(appModel.toolbox.projectTools).find(pTool => pTool.featureTypes && pTool.featureTypes.length && pTool.featureTypes[0].attributes && pTool.featureTypes[0].attributes.toolbox);
        if (!projectTool) {
            return null;
        }
        const toolInterface = getToolInterface(projectTool);
        appModel.toolbox.toolInterface = toolInterface;
        return projectTool;
    }

    /**
     * The toolboxId attached to the project tool may be a base toolboxId, 
     * in which case we may need to use it to find the corresponding 
     * variant toolbox provisioned to this account
    */
    async getChildToolboxId(projectTool) {
        const baseToolboxId = projectTool.featureTypes[0].attributes.toolbox;

        if (!baseToolboxId) {
            return null;
        }
        
        if (!toolboxSelector.accountToolboxes) {
            await toolboxSelector.fetchAccountToolboxes();
        }
    
        const accountToolboxes = toolboxSelector.accountToolboxes;  
        let toolboxId, matchingToolbox;
    
        matchingToolbox = accountToolboxes.find(toolbox => toolbox.base && toolbox.base.toolboxId === baseToolboxId || toolbox.toolboxId === baseToolboxId);
        if (matchingToolbox) {
            toolboxId = matchingToolbox.toolboxId;
        } else {
            const toolName = projectTool && projectTool.name;
            matchingToolbox = accountToolboxes.find(toolbox => toolbox.name === toolName);
            if (matchingToolbox) {
                toolboxId = matchingToolbox.toolboxId;
            } else {
                toolboxId = accountToolboxes[0].toolboxId;
            }
        }
    
        return toolboxId;
    }

    shouldRefreshToolbox(toolboxId) {
        // here we need to check that the change is on the current toolbox, the user is not drawing on the map or in the staking flow.
        return toolboxId === this.baseToolboxId && !formModel.editingFeatureId && !drawPaletteModel.activeFeature
&& !stakeableModel.origin;
    }

    awaitChanges() {
        publish.await({
            changeType: 'modified',
            recordType: 'toolbox',
            test: change => this.shouldRefreshToolbox(change.toolboxId),
            callback: async () => {
                await this.init();
                formModel.resetControls();
                tableModel.reinitTableHeaders();
                assetListManager.clearAssetNames();
            },
            persist: true
        });
    }
}

const toolboxManager = new ToolboxManager();

export default toolboxManager;
