import router from 'uav-router';
import constants from 'util/data/constants';
import {whiteLabelAccount} from 'util/data/env';
import accountManager from '../../managers/account-manager';

const Auth = {
    oninit: async ({state}) => {
        if (whiteLabelAccount) {
            await accountManager.setWhiteLabelAccountData();
        }
        state.isInited = true;
        m.redraw();
    },

    view: ({state, children, attrs: {cssClass}} ) => {
        if (state.isInited) {
            return <div class={`auth ${cssClass}`}>
                <header class="header">
                    <div class="header-left" onclick={() => router.set()}>
                        {whiteLabelAccount ?
                            <img className="whitelabel" src={accountManager.whiteLabelData ? accountManager.whiteLabelData.logos.logo : ''}/> :
                            <a class="home"/>}
                    </div>
                    {!whiteLabelAccount &&
                    <div class="header-right">
                        <div class="login-prompt">No account yet?</div>
                        <a class="btn btn-small btn-outline-arctic btn-pill" onclick={() => {
                            if (constants.awsProfile === 'staging') {
                                window.location.href = 'https://unearthlabs.com/free-trial-staging';
                            } else if (constants.awsProfile === 'development') {
                                window.location.href = 'https://unearthlabs.com/free-trial-dev';
                            } else {
                                window.location.href = 'https://unearthlabs.com/free-trial';
                            }
                        }}>Start your trial</a>
                    </div>}
                </header>
                <div class="auth-wrapper">
                    <div class="map-dots">
                        <span class="map-dot red-dot"/>
                        <span class="map-dot teal-dot"/>
                        <span class="map-dot red-dot"/>
                        <span class="map-dot red-dot"/>
                        <span class="map-dot red-dot"/>
                        <span class="map-dot teal-dot"/>
                        <span class="map-dot teal-dot"/>
                        <span class="map-dot teal-dot"/>
                    </div>
                    {children}
                    <div class="auth-form-links">
                        <span className="unearth-help-link" onclick={() => window.open(`mailto:${constants.defaultSupportEmail}`)}>
                            <a>Ask Support</a>
                                &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                        </span>
                        <a onclick={() => window.open(whiteLabelAccount ? accountManager.whiteLabelData.privacyPolicy : constants.defaultPrivacyPolicy)}>Privacy Policy</a> 
                    </div>
                </div>
            </div>;
        }
    }
};

export default Auth;
