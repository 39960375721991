import dataCenterModel from 'models/data-center-model';

const DataCenter = {
    oncreate({dom}) {
        dataCenterModel.setIframeWindow(dom.children[0].contentWindow);
    },

    view: () => {
        return  <div>
            <div class="data-center">
                <div>
                    <iframe
                        src={dataCenterModel.src}
                        marginHeight="0"
                        marginWidth="0"
                        frameBorder="0"
                    />
                </div>
            </div>
        </div>;
    }

};

export default DataCenter;
