import SavedViewNowViewing from 'views/saved-views/saved-views-now-viewing';
import SavedViewsList from 'views/saved-views/list/saved-views-list';
import popupModel from 'models/popover-model';
import capitalize from 'util/data/capitalize';
import savedViewsModel from 'models/saved-views-model';
import appModel from 'models/app-model';
import MetaProjectSelector from 'views/saved-views/saved-views-meta-project-selector';

const NoSavedViewsMessage = {
    oninit: ({state}) => {
        state.siteTerm = appModel.project.isMetaProject ? 'your Portfolio' : `this ${capitalize(appModel.toolbox.siteTermSingular)}`;
    },

    view: ({state}) => {
        return <div class="empty-state-message"><img src="/images/no-saved-views.png" class="empty-state-image" /><span class="message-intro">No Saved Views for {state.siteTerm} yet.</span> Tap the <i class="icon-bookmark" /> button in the table or list header to save your current view.</div>;
    }
};

const SavedViewsMenu = {
    oninit: () => {
        savedViewsModel.init();
    },

    view: () => {
        const noProjectViews = savedViewsModel.projectViewsCount === 0;
        const moreProjectViews = !noProjectViews && (savedViewsModel.projectViewsCount > 1 || !savedViewsModel.activeSavedViewId);
        return <div class={`saved-views-menu side-nav-popup list-length-${savedViewsModel.projectViewsCount}`}>
            <div class="side-nav-popup-title">Saved Views</div>

            <div class="vertical-scroll" onclick={(e) => {
                e.stopPropagation();
                popupModel.close();
            }}>
                <SavedViewNowViewing/>
                {savedViewsModel.isInitted ? <>
                    {noProjectViews ? <NoSavedViewsMessage/> : ''}
                    {moreProjectViews ? <SavedViewsList/> : ''}
                </> : <i class="spinner spinning teal"/>}
            </div>
            {appModel.project.isMetaProject && <MetaProjectSelector/>}
        </div>;
    }
};

export default SavedViewsMenu;
