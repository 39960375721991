import store from 'util/data/store';

export const assetIsSurveyType = ({assetTypeId}) => {
    const assetTypeById = store.assetTypes[assetTypeId];

    const layerField = assetTypeById.fields.find(field => field.name === 'Layer Details');
    if (!layerField || !layerField.type || !layerField.type.objType || layerField.type.objType !== 'survey') {
        return false;
    }

    return true;
};

export const assetTypeIsPlanType = ({assetType}) => {
    const layerField = assetType.fields.find(field => field.name === 'Layer Details');
    if (!layerField || !layerField.type || !layerField.type.objType || layerField.type.objType !== 'plan') {
        return false;
    }

    return true;
};

export const assetIsPlanType = ({assetTypeId}) => {
    return assetTypeIsPlanType({assetType: store.assetTypes[assetTypeId]});
};

export const assetIsImageType = ({assetTypeId}) => {

    const assetTypeById = store.assetTypes[assetTypeId];

    if (assetTypeById.name !== 'Image') {
        return false;
    }

    const imageField = assetTypeById.fields.find(field => field.name === 'Image');
    if (!imageField || !imageField.type || !imageField.type.items || imageField.type.items.objType !== 'media') {
        return false;
    }

    return true;
};

export const toolIsImageType = ({toolObject}) => {
    
    const {assetForm: {assetType}, name: toolName, toolGroup: {name: toolGroupName}} = toolObject;

    if (toolName !== 'Image' || toolGroupName !== 'Files' || assetType.name !== 'Image') {
        return false;
    }

    const imageField = assetType.fields.find(field => field.name === 'Image');
    if (!imageField || !imageField.type || !imageField.type.items || imageField.type.items.objType !== 'media') {
        return false;
    }

    return true;
};

export const normalizeUtcTimestamp = (timestamp) => {
    if (timestamp[timestamp.length - 1] !== 'Z') {
        timestamp = timestamp + 'Z';
    }
    return timestamp;
};
