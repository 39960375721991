import Point from 'util/draw/point';
import siteModel from 'models/site-model';
import featureToControl from 'util/interfaces/feature-to-control';
import isMetric from 'util/numbers/is-metric';
import store from 'util/data/store';
import formModel from 'models/form-model';
import ToolInterface from 'util/interfaces/tool-interface';
import controlToFeature from 'util/interfaces/control-to-feature';
import helpers from 'legacy/util/api/helpers';
import featureListManager from 'managers/feature-list-manager';

class SymbolInterface extends ToolInterface {

    constructor(...args) {

        super(...args);

        this.type = 'symbol';

    }

    launch() {

        const map = siteModel.map;

        this.prompt = {view: () => `Tap on the map to place the ${this.featureType.name}.`};

        this.showToolLauncher();

        return new Promise(resolve => {

            const asset = store.assets[this.assetId];

            this.done = () => {

                if (this.draw) {

                    this.draw.stop();

                }

                resolve([asset]);

            };

            this.feature.setupFeatureProperties(this.feature).then(() => {

                this.draw = new Point({
                    map,
                    source: map.getSource(this.featureType.featureTypeId),
                    metric: isMetric()
                }).create(this.feature.properties);

                this.draw.onVertexAdded = _feature => featureListManager.addLatitudeToFeature(_feature);

                this.draw.onComplete = _feature => {

                    this.feature = _feature;
                    asset.featureIds = helpers.list(asset.featureIds);
                    asset.featureIds.push(_feature.id);

                    featureListManager.all[_feature.id] = _feature;
                    
                    featureToControl.sync(this.type, _feature, this.assetId);

                    controlToFeature.syncAllFeatureProperties(this.assetId, this.feature.featureId || this.feature.id);

                    this.done();
                };

            });

        });

    }

    edit(_feature) {

        const map = siteModel.map;
        this.feature = featureListManager.getById(_feature.id);
    
        if (!this.feature) {
            return;
        }

        const source = this.feature.featureType.source;

        this.draw = new Point({
            map,
            source,
            metric: isMetric()
        }).edit(this.feature);

        this.draw.onVertexChanged = () => {

            featureListManager.addLatitudeToFeature(this.feature);

            featureToControl.sync(this.type, this.feature, this.assetId);

            controlToFeature.syncAllFeatureProperties(this.assetId, this.feature.featureId || this.feature.id);

            source.setData(source._data);

            m.redraw();

            this.autosave(this.feature);

        };

        this.draw.onStop = formModel.onEditStop;

    }

}

export default SymbolInterface;
