import filterConstants from 'constants/models/table/filter-constants';
import tableModel from 'models/table/table-model';

const BoundaryMenuButton = {
    view: () => {
        const viewKey = filterConstants.BOUNDARY;
        const styles = tableModel.styles;
        const boundaryStyle = styles.getCommonColumnHeaderStyles('boundary');
        return <div className={'boundary-menu-button UFtracker-open-map-filter'}>
            <div onclick={function () {
                tableModel.projectView.filters.open(this.parentElement, {viewKey});
            }}>
                <div className={'config-menu-tab'}>
                    <span className={`config-menu-tab-btn ${boundaryStyle}`}><i className='icon-map-square'/></span>
                </div>
            </div>
        </div>;
    }
};

export default BoundaryMenuButton;
