import tableModel from 'models/table/table-model';

const SavedViewButton = {
    view: () => {
        return <div className={'saved-view-menu-button'}>
            <div onclick={function () {
                tableModel.openActiveProjectView();
            }}>
                <div className={'config-menu-tab'}>
                    <span className={`config-menu-tab-btn UFtracker-saved-view-btn ${tableModel.projectView.isSaved ? 'is-active' : ''}`}><i className='icon-bookmark'/></span>
                </div>
            </div>
        </div>;
    }
};

export default SavedViewButton;
