import screenHelper from 'legacy/util/device/screen-helper';
import drawPaletteModel from 'models/draw-palette-model';
import volumeModel from 'models/volume-model';
import StyleControl from 'views/draw-palette/controls/style-control';
import SidebarToggle from 'views/sidebar-toggle';
import popupModel from 'models/popover-model';

const CustomStyleOptions = {
    oninit: ({state}) => {
        const stylesList = drawPaletteModel.getStylesList() || [];
        state.showDeleteAll = drawPaletteModel.isUsingCustomStyle ? stylesList.length > 1 : stylesList.length > 0;
    },

    view: ({state: {showDeleteAll}, attrs: {toolStyleId}}) => <div class="custom-style-options-menu">
        {toolStyleId ? <div class="menu-option delete-option UFtracker-delete-selected-style-shortcut" onclick={() => {
            popupModel.close();
            drawPaletteModel.deleteSavedStyle(toolStyleId);
        }}><i className="icon-trash"/>Delete Selected</div> : ''}
        {showDeleteAll ? <div class="menu-option delete-option UFtracker-delete-all-style-shortcuts" onclick={() => {
            popupModel.close();
            drawPaletteModel.deleteAllSavedStyles();
        }}><i className="icon-trash"/>Delete All</div> : ''}
    </div>
};

const SavedStyles = {
    oninit: ({state}) => {
        state.popupOpen = false;
    },
    onupdate: ({state, dom}) => {
        if (drawPaletteModel.isAddingStyle && state.needsFocus) {
            const input = dom.querySelector('input');
            input.focus();
            state.needsFocus = false;
        }
    },

    view: ({state}) => {
        const styles = drawPaletteModel.getStylesList();
        return drawPaletteModel.isAddingStyle ? <div class="create-new-style">
            <div class="style-shortcuts-header">
                <label>Style Shortcuts<i class="spinner teal"/></label>
            </div>
            <div class="new-saved-style-form-wrap">
                <input type="text" placeholder="New Style Shortcut" class="saved-style-name" onkeydown={(e) => drawPaletteModel.handleStyleNameInput(e)} oninput={(e) => drawPaletteModel.handleStyleNameInput(e)}/>
                <div tabindex="0" class="save-button-outer"><span class="btn btn-primary btn-pill btn-smallest right" onclick={() => drawPaletteModel.saveStyles()}>Done</span></div>
                <div class="cancel-new-style UFtracker-cancel-style-shortcut" onclick={() => {
                    drawPaletteModel.isAddingStyle = false;
                    state.needsFocus = true;
                }}>cancel</div>
            </div>
        </div> 
            : <div class="custom-style-selector">
                <div class="style-shortcuts-header">
                    <label>Style Shortcuts<i class="spinner teal"/></label>
                    <div class="add-button btn-primary btn-smallest btn-pill btn UFtracker-add-style-shortcut" onclick={() => {
                        drawPaletteModel.isAddingStyle = true;
                        drawPaletteModel.toolStyleName = ''; // clear existing custom style name bc we're adding new
                        state.needsFocus = true;
                    }}>Add</div>
                </div> 
                {styles && styles.length ? <div class="style-shortcuts-row">
                    <div class="select-wrapper UFtracker-select-style-shortcut">
                        <select onchange={e => drawPaletteModel.handleCustomStyleSelect(e.target.value)}>
                            <option value={0} selected={!drawPaletteModel.isUsingCustomStyle} disabled>None applied</option>
                            {styles.map((styleId) => {
                                const style = drawPaletteModel.getStylesById(styleId);
                                return <option value={style.toolStyleId} selected={drawPaletteModel.toolStyleId === style.toolStyleId}>{style.name}</option>;
                            })}
                        </select>
                    </div>
                    <i class="icon-ellipsis" onclick={function() {
                        popupModel.open(this, {
                            view: CustomStyleOptions,
                            wrapClass: 'custom-style-point-menu',
                            keepUIOpen: true,
                            disableCloseOnce: true,
                            args: {toolStyleId: drawPaletteModel.toolStyleId}
                        });
                        popupModel.disableCloseFor(100);
                    }}/>
                </div> : '' }
            </div>;
    }
};

const ResetButton = {
    view: () => drawPaletteModel.showReset() ? <span class="reset-styles-button right">
        <a onclick={() => drawPaletteModel.resetStyles()} class="restore-link">Reset to Default</a>
    </span> : ''
};

const DrawPalette = {

    oninit({ state }) {
        state.visible = !screenHelper.small();
    },

    view: ({ state }) => {
        const styleControlKeys = drawPaletteModel.styleControlKeys;
        return styleControlKeys.length && !volumeModel.hasHeatMap
            ? <div class={`draw-palette ${state.visible ? 'visible' : ''} ${drawPaletteModel.isSaving ? ' saving' : ''}`} onclick={() => drawPaletteModel.openColorPickerOn(null)}>
                <div class="draw-palette-header"><span class="draw-palette-tool-title">{drawPaletteModel.toolName}</span> Style <ResetButton/></div>
                <div class="draw-palette-scroll" onscroll={() => drawPaletteModel.openColorPickerOn(null)}>
                    {drawPaletteModel.hasSavedStylesEnabled && <SavedStyles/>}
                    <div class="feature-style-controls">
                        {styleControlKeys.map(key => <StyleControl styleControlKey={key}/> )}
                    </div>
                </div>
                <SidebarToggle class="show-on-small" onclick={() => {
                    state.visible = !state.visible;
                }} />
            </div>
            : null;
    }
};

export default DrawPalette;
