import Auth from 'views/auth/auth';
import AuthField from 'views/auth/auth-field/auth-field';
import FormButton from 'views/form-button/form-button';
import authModel from 'models/auth-model';
import router from 'uav-router';
import ShowHidePassword from 'views/auth/auth-field/show-hide-password';
import AuthForm from 'views/auth/auth-form/auth-form';

const ResetPassword = {
    oninit: ({state}) => {
        state.newPassword = {type: 'password'};
        state.confirmPassword = {type: 'password'};
    },

    view: ({state}) => {
        const isValidated = state.newPassword.isValid
        && state.confirmPassword.isValid;
        return <Auth cssClass="reset-password">
            <AuthForm
                onSubmit={() => {
                    state.spinning = true;
                    authModel.resetPassword(state.newPassword.value).finally(() => {
                        state.spinning = false;
                        m.redraw();
                    });
                }}>
                <div class="form-msg"><h1>Reset Password</h1></div>
                <div class="form new-form reset-password">
                    <p>You will be prompted to sign in after setting your new password.</p>
                    <AuthField
                        label="New Password"
                        type={state.newPassword.type}
                        showValidationError={state.newPassword.showValidationError}
                        forceErrorCss={state.passwordMustMatch}
                        onBlur={() => {
                            state.newPassword.showValidationError = true;
                        }}
                        onInput={(e, isValid) => {
                            state.newPassword.isValid = isValid;
                            state.newPassword.value = e.target.value;
                        }}>
                        <ShowHidePassword onToggle={(value) => {
                            state.newPassword.type = value;
                        }}/>
                        {state.passwordMustMatch && <div class="error-msg">Passwords must match</div>}
                    </AuthField>
                    <AuthField
                        label="Confirm New Password"
                        type={state.confirmPassword.type}
                        showValidationError={state.confirmPassword.showValidationError}
                        forceErrorCss={state.passwordMustMatch}
                        matchError={'Passwords do not match'}
                        matchValue={state.newPassword.value}
                        onBlur={() => {
                            state.confirmPassword.showValidationError = true;
                        }}
                        onInput={(e, isValid) => {
                            state.confirmPassword.isValid = isValid;
                            state.confirmPassword.value = e.target.value;
                        }}>
                        <ShowHidePassword onToggle={(value) => {
                            state.confirmPassword.type = value;
                        }}/>
                    </AuthField>
                    <FormButton
                        isDisabled={!isValidated}
                        spinning={state.spinning}
                        label="Change Password">
                        <div class="login-footer">
                            <a class="cancel-btn" onclick={()=> router.set()}>Cancel</a>
                        </div>
                    </FormButton>
                </div>
            </AuthForm>
        </Auth>;
    }

};

export default ResetPassword;
