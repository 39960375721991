import store from 'util/data/store';
import controlToFeature from 'util/interfaces/control-to-feature';

const updateAssetFeatures = (control, assetId) => controlToFeature.updateAssetFeatures(control, assetId);

export default function(control, assetId) {
    const asset = store.assets[assetId],
        selectedValue = asset.properties[control.fieldName] || '',
        field = store.assetTypeFields[asset.assetTypeId][control.fieldName];
    return <div class="dropdown-control select-wrapper">
        <select data-focus-id="1" value={selectedValue} onkeydown={(e) => {
            return e.stopPropagation();
        }} onchange={e => {
            store.assets[assetId].properties[control.fieldName] = e.target.value || null;
            updateAssetFeatures(control, assetId);
            e.target.blur();
        }}>
            <option value="">Select an option</option>
            {field && field.type.values.map(value =>
                <option key={value} value={value}>{value}</option>
            )}
        </select>
    </div>;
}
