import positionLayerFlow from 'flows/create-layer/position-layer/position-layer-flow';
import MediaThumbnailAsync from 'views/media-thumbnail/media-thumbnail-async';
import Button from 'views/button/button';
import modalModel from 'models/modal-model';
import Crop from 'views/plan/crop/crop';
import ColorPicker from 'views/color-picker/color-picker';
import ToggleColorSwitch from 'views/color-picker/toggle-color-switch';
import onBodyClick from 'legacy/util/dom/on-body-click';
import EditableText from 'views/editable-text/editable-text';
import tip from 'views/tip';
import createLayerFlow from 'flows/create-layer/create-layer-flow';

const LNG_RANGE = 180;
const LAT_RANGE = 90;

const LNG_INDEX = 0;
const LAT_INDEX = 1;

function openEnlargedView() {
    return modalModel.open({view: Crop, attrs: {tileset: positionLayerFlow.cropTileset, hideCropControls: true}, styleClass: 'stakeable plan default enlarged-view hide-crop-controls'});
}

const LayerImage = {
    view: ({attrs: {mediaId}}) => {
        return <div class="layer-thumbnail-image">
            <div class="section-title">Layer Image</div>
            <div class="thumbnail-click-target" onclick={() => openEnlargedView()}>
                {mediaId ? <MediaThumbnailAsync mediaId={mediaId}/> : <div class="img-wrap"><span class="loader-v2 full-width"/></div>}
            </div>
        </div>;
    }
};

const Colorize = {
    view: ({state}) => {
        return <div class={`colorize-row ${positionLayerFlow.state.colorTilesetLoading ? 'loading' : ''}`}>
            <div class="section-title">
                {positionLayerFlow.tileset ? <ToggleColorSwitch
                    hideText={true}
                    clickElementClass="UFtracker-layer-editor-colorize-toggle"
                    tileset={positionLayerFlow.tileset}
                    colorOn={positionLayerFlow.layerColor !== 'None'}
                    toggleFn={() => positionLayerFlow.toggleColor()}/> : ''}
                <span class="colorize-title-text">Colorize</span>
            </div>
            <div class="color-swatch-wrap" onclick={() => {
                if (!state.isOpen) {
                    state.isOpen = true;
                    onBodyClick.once(() => {
                        state.isOpen = false;
                        m.redraw();
                    });
                }
            }}><div class={`color-swatch ${positionLayerFlow.layerColor === 'None' ? 'layer-color-off' : ''}`} style={{ backgroundColor: `${positionLayerFlow.layerColor}`}}/>
                {state.isOpen ? <ColorPicker
                    showOpacity={true}
                    containerId="unearth-color-picker-plan-editor"
                    starterColor={positionLayerFlow.layerColor}
                    starterOpacity={positionLayerFlow.layerOpacity}
                    addCssClass="plan-editor-color-picker"
                    setColorFn={(hex, opacity) => positionLayerFlow.setColor(hex, opacity)}
                /> : ''}
            </div>
            <div class="spinner-wrap">{positionLayerFlow.state.colorTilesetLoading ? <i class="spinner spinning teal"/> : ''}</div>
        </div>;
    }
};

const CropButton = {
    view: () => {
        return <Button text="Crop"
            onclick={() => modalModel.open({view: Crop, attrs: {tileset: positionLayerFlow.cropTileset}, styleClass: 'stakeable plan default'})} 
            iconClass="icon-crop"
            cssClasses="btn-secondary btn-pill UFtracker-layer-editor-crop-layer-image"/>;
    }
};

const EnlargeButton = {
    view: () => {
        return <Button text="Enlarge"
            onclick={() => openEnlargedView()} 
            iconClass="icon-expand"
            cssClasses="btn-secondary btn-pill UFtracker-layer-editor-enlarge-layer-image"/>;
    }
};


const TabbedContent = {
    oninit: ({state, attrs: {activeTabIndex}}) => {
        state.activeTabIndex = activeTabIndex || 0;
    },

    view: ({state, attrs: {tabs}}) => {
        return <div class="tabbed-content">
            <div class="tab-header">
                {tabs.map((tab, index) => { 
                    const activeClass = index === state.activeTabIndex ? ' active' : '';
                    return  <div class={`${tab.trackerClass} tab-header-item${activeClass}`} onclick={() => {
                        state.activeTabIndex = index;
                    }}>{tab.title}</div>;
                })}
            </div>
            <div class="tabbed-content-row">{tabs[state.activeTabIndex].content}</div>
        </div>;
    }
};

const Select = {
    oncreate: ({dom, attrs: {unitDefault}}) => {
        dom.value = unitDefault;
    },

    view: ({attrs: {opts, onSelect}}) => {
        return <select class="right-unit short-unit unit" onchange={(e) => onSelect(e)}>
            {opts.map(opt => {
                return <option value={opt}>{opt}</option>;
            })}
        </select>;
    }
};

export const NumericInput = {
    view: ({attrs: {label, value, unitDefault, unitOpts, min, max, step, onChange, onUnitChange}}) => {
        return <div class="control-group range-control">
            {label ? <label>{label}</label> : ''}
            <div class="range-input-wrap">
                <input onfocus={() => positionLayerFlow.pauseNudgeKeys()} 
                    onblur={() => positionLayerFlow.resumeNudgeKeys()}
                    oninput={(e) => onChange(e)}
                    value={value} type="number" min={min} max={max} step={step} class="range-text"/>
                {unitOpts && unitOpts.length > 1 ? <div class="select-wrapper"><Select opts={unitOpts} unitDefault={unitDefault} onSelect={(e) => onUnitChange(e)} default={unitOpts}/></div> : <span class="right-unit short-unit unit">{unitDefault}</span>}
            </div>
            <div class="range-slider-wrap">
                <input oninput={(e) => onChange(e)}
                    value={value} type="range" min={min} max={max} step={step} class="range-input"/>
                <span class="min-max-row">
                    <span class="min">{min}</span>
                    <span class="max">{max}</span>
                </span>
            </div>
        </div>;
    }
};

const LngLatInput = {
    view: ({attrs: {title, titleTip, lngLat, onInput, validateInput, showLngLatErrorLng, showLngLatErrorLat}}) => {
        return <div class="lnglat-input">
            {title ? <div class="section-title">{title}{titleTip || ''}</div> : ''}
            <div class="latitude-error-wrap">
                <div class='latitude-row'>
                    <input type="text" value={lngLat.lat}
                        oninput={e => validateInput(e, LAT_INDEX)}
                        onfocus={() => positionLayerFlow.pauseNudgeKeys()} 
                        onblur={() => positionLayerFlow.resumeNudgeKeys()}
                        onchange={e => onInput(e, LAT_INDEX) }/>
                    <span class="left-unit long-unit unit">Latitude</span>
                </div>
                {showLngLatErrorLat ? <div class="error">Latitude must be between -{LAT_RANGE} and +{LAT_RANGE}.</div> : ''}
            </div>
            <div class="longitude-error-wrap">
                <div class='longitude-row'>
                    <input type="text" value={lngLat.lng}
                        oninput={e => validateInput(e, LNG_INDEX)}
                        onfocus={() => positionLayerFlow.pauseNudgeKeys()} 
                        onblur={() => positionLayerFlow.resumeNudgeKeys()}
                        onchange={e => onInput(e, LNG_INDEX) }/>
                    <span class="left-unit long-unit unit">Longitude</span>
                </div>
                {showLngLatErrorLng ? <div class="error">Longitude must be between -{LNG_RANGE} and +{LNG_RANGE}.</div> : ''}
            </div>
        </div>;
    }
};

const TransformPlan = {
    view: () => {
        const scaleValue = positionLayerFlow.isInitted ? positionLayerFlow.scalePercentageSet : '';
        const rotationValue = positionLayerFlow.isInitted ? positionLayerFlow.rotationDegreesSet : '';
        const lngLatValue = positionLayerFlow.isInitted ? positionLayerFlow.centerPointSet : {lng: '', lat: ''};

        return <div class="transform-plan-wrap">
            <div class="scale-rotate-row">
                <NumericInput label="Scale" unitDefault="%" value={scaleValue} min={1} max={300} step={1}
                    onChange={(e) => positionLayerFlow.setScaleInput(e.target.value)}
                />
                <NumericInput label="Rotation" unitDefault="deg" value={rotationValue} min={0} max={360} step={1}
                    onChange={(e) => positionLayerFlow.setRotateInput(e.target.value)}
                />
            </div>
            <div class="lnglat-row">
                <LngLatInput title="Center Point" lngLat={lngLatValue} 
                    titleTip={<div
                        onmouseenter={function() {
                            tip.show(this, {tipText: 'Try nudging the Map Layer using the keyboard arrow keys.'});
                            onBodyClick.once(() => tip.hide());
                        }} 
                        onmouseleave={() => tip.hide()}
                        class="info-tip-wrap">
                        <i class="icon-help"/>
                    </div>}
                    showLngLatErrorLng={positionLayerFlow.showLngLatErrorLng}
                    showLngLatErrorLat={positionLayerFlow.showLngLatErrorLat}
                    onInput={(e, index) => positionLayerFlow.handleLngLatInput(e.target.value, index)} 
                    validateInput={(e, index) => positionLayerFlow.validateLngLatInput(e.target.value, index)}/>
            </div>
            <div class="recenter-tileset-row">
                <div class="hide-mapped-content" onclick={() => positionLayerFlow.toggleMappedContent()}>
                    <i class={`icon-checkbox-${positionLayerFlow.isShowingMapLayersOnly ? 'on' : 'off'}`} />
                    <label>Show Map Layers only</label>
                </div>
                <div class="hide-mapped-content hide-frame" onclick={() => positionLayerFlow.toggleFrame()}>
                    <i class={`icon-checkbox-${positionLayerFlow.isShowingFrame ? 'on' : 'off'}`} />
                    <label>Show Frame</label>
                </div>
                {positionLayerFlow.state.isOutOfBounds ? 
                    <Button text="Center on Map"
                        onclick={() => positionLayerFlow.centerOnMap()}
                        iconClass="icon-center-on-map"
                        cssClasses="btn-secondary btn-pill btn-small"/> 
                    : ''}
            </div>
        </div>;
    }
};

const WarpPlan = {
    oninit: ({state}) => {
        state.matchPoints = positionLayerFlow.matchPoints;
    },

    view: ({state: {matchPoints}}) => {
        return <div class="warp-plan-wrap disabled">
            <div class="warp-plan-title-row">
                <label>Match Points: {matchPoints.length}</label>
                <Button text="Adjust Match Points" 
                    cssClasses="btn btn-smallest btn-pill btn-secondary UFtracker-layer-editor-adjust-match-points" 
                    onclick={() => positionLayerFlow.handleAddMatchPoint()}
                />
            </div>
            {matchPoints.map((point, i) => <div class="lnglat-row">
                <i class="icon-marker2" /><LngLatInput title={`Match Point ${i + 1}`} lngLat={point} 
                    showLngLatErrorLng={positionLayerFlow.showLngLatErrorLng}
                    showLngLatErrorLat={positionLayerFlow.showLngLatErrorLat}
                    onInput={() => true /* not supported yet */}
                    validateInput={() => true /* not supported yet */}/>
            </div>)}
        </div>;
    }
};

const EditablePlanName = {
    view: () => <EditableText text={positionLayerFlow.planName}
        handleFocus={() => positionLayerFlow.pauseNudgeKeys()} 
        handleBlur={() => positionLayerFlow.resumeNudgeKeys()}
        save={(name, resolve) => positionLayerFlow.savePlanName(name, resolve)}
        parentSelector={'.plan-editor'} />
};

export const PlanEditorHeader = {
    view: ({attrs: {planName, planNameIsEditable, mediaLabel, titleIconClass}}) => {
        return <div class="plan-editor-header">
            <div class="plan-editor-title">
                <i class={titleIconClass || 'icon-layers'}/>{planName ? <div class="plan-title">{planNameIsEditable ? <EditablePlanName/> : planName}</div> : ''}
            </div>
            <div class="plan-editor-subtitle">
                <i class="icon-link"/> {mediaLabel}
            </div> 
            <i class="icon-close panel-close" onclick={() => createLayerFlow.close()} />
        </div>;
    }
};

export const EditPlanFooter = () => {
    const footerStatusMessage = positionLayerFlow.footerStatusMessage;
    return <><div class="saving-status-footer">
        {positionLayerFlow.isSaving
            ? <span class="saving">Saving...<i class="spinner spinning teal"/></span>
            : <span class="last-save">{footerStatusMessage}</span>}
    </div>
    <button class="btn btn-primary btn-pill btn-small right btn-close" onclick={() => createLayerFlow.close()}>
        <span class="btn-label">Done</span>
    </button>

    </>;
};

export const PlanEditor = {

    onremove: () => {
        positionLayerFlow.onClose();
    },

    view: () => {
        return <div class={`plan-editor ${positionLayerFlow.isInitted ? 'ready' : 'initting'}`}>
            <PlanEditorHeader planName={positionLayerFlow.planName} planNameIsEditable={true} mediaLabel={'Source File: ' + positionLayerFlow.mediaLabel}/>
            <LayerImage mediaId={positionLayerFlow.mediaId}/>
            <div class="button-row">
                <CropButton/>
                <EnlargeButton mediaId={positionLayerFlow.mediaId}/>
            </div>
            <Colorize/>
            <TabbedContent
                tabs={[
                    {title: 'Transform', content: <TransformPlan/>, trackerClass: 'UFtracker-layer-editor-transform-tab'},
                    {title: 'Warp', content: <WarpPlan/>, trackerClass: 'UFtracker-layer-editor-warp-tab'}
                ]}/>
        </div>;
    }
}; 

export default PlanEditor;
