export default function(e) {

    e = e.touches && e.touches.length ? e.touches[0] : e;

    return {
        x: e.pageX,
        y: e.pageY
    };

}
