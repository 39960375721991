import store from 'util/data/store';
import controlToFeature from 'util/interfaces/control-to-feature';
import DatePicker from 'views/date-picker';

const updateAssetFeatures = (control, assetId) => controlToFeature.updateAssetFeatures(control, assetId);

export default function(control, assetId) {
    let date = undefined;
    if (store.assets[assetId].properties[control.fieldName]) {
        date = store.assets[assetId].properties[control.fieldName];
    } else {
        if (control.attributes.defaultToToday) {
            date = Date.now();
        }
        if (control.attributes.default) {
            date = control.attributes.default;
        }
    }
    return <DatePicker
        date={date && new Date(date)}
        required={control.attributes.required}
        min={control.attributes.min && new Date(control.attributes.min)}
        max={control.attributes.max && new Date(control.attributes.max)}
        onchange={newDate => {
            const properties = store.assets[assetId].properties;
            if (newDate) {
                properties[control.fieldName] = newDate.getTime();
            } else {
                properties[control.fieldName] = null;
            }
            updateAssetFeatures(control, assetId);
        }}/>;
}
