import loadExternal from 'legacy/util/data/load-external';
import api from 'legacy/util/api';
import router from 'uav-router';
import store from 'util/data/store';

function chargebee(opts = {}) {

    return loadExternal.loadChargebee().then((shouldInit) => {

        return new Promise((resolve, reject) => {

            const cbInstance = shouldInit
                ? window.Chargebee.init({site: 'unearth'})
                : window.Chargebee.getInstance();

            const portal = cbInstance.createChargebeePortal();

            portal.open({
                close: () => {

                    api.rpc.refreshAccountInfo(opts.accountId || store.account.accountId).then(account => {

                        if (opts.reload) {

                            router.load();

                        }

                        resolve(account);

                    }).catch((e) => {
                        reject(e);
                    });
                }

            });

        });

    });

}

export default chargebee;
