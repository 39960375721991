export default fn => e => {

    e.stopPropagation();

    if (fn) {

        fn(e);

    }

};

