class XidResponseList {
    constructor(xid) {
        this.xid = xid;
        this.onResponseFns = [];
        this.onInvalidFns = [];
    }

    addResponseFns(onResponse, onInvalid) {
        this.onResponseFns.push(onResponse);
        this.onInvalidFns.push(onInvalid);
    }

    runOnResponseFns(response) {
        this.onResponseFns.forEach(callback => {
            if (callback) {
                callback(response);
            }
        });
        this.onResponseFns = [];
    }

    markAsInvalid() {
        this.onInvalidFns.forEach(callback => {
            if (callback) {
                callback();
            }
        });
        this.onInvalidFns = [];
    }
}

export default XidResponseList;
