import store from 'util/data/store';
import router from 'uav-router';
import appModel from 'models/app-model';
import planModel from 'models/plan-model';
import PermissionsIndicator from 'views/permissions/permissions-indicator/permissions-indicator';
import formModel from 'models/form-model';
import createLayerFlow from 'flows/create-layer/create-layer-flow';

const openPlanToEdit = async (plan, state) => {
    if (appModel.user.permissions.canEditRecord(plan)) {
        return formModel.validateThenRun(async () => {
            state.isActive = true;
            m.redraw();
            let project = store.project;
            if (plan.planId !== appModel.state.editingProjectId) {
                project = await appModel.project.getChildProject(plan.projectId);
            }
            const site = project.sites.items[0];
            router.set({projectId: project.projectId, siteId: site.siteId, view: 'layer', planId: plan.planId});
        });
    }
};


export const FolderOptionsEllipsisMenu = {
    view: ({attrs: {folder}}) => {
        return <div class={`folder-header-menu-button${folder.recordType === 'folder' && folder.root.colorPickerOn === folder.urn ? ' active' : ''}`}>
            <i class="icon-ellipsis" onclick={(e) => folder.root.toggleMenuOn(folder.urn, e)}></i>
        </div>;
    }
};

const FolderOptionsMenu = {

    oncreate: ({dom, state}) => {
        state.outermostElement = dom;
    },

    view: ({state, attrs: {item}}) => {
        const plan = store.plans[item.id];
        const tileset = plan ? store.tilesets[plan.tilesetId] : null;
        const showEditNameButton = item.recordType !== 'folder' || !item.isRoot;
        const showEditButton = item.recordType === 'plan' ? planModel.supportsStaking(tileset) : item.hasPlansInDescendants;
        let descriptor = item.recordType === 'folder' ? 'Group' : 'Map Layer';
        if (item.isRoot) {
            descriptor = 'All';
        }
        let editButtonIcon = 'icon-marker2';
        let editButtonText = 'Edit Match Points'; 
        let editButtonAction = () => item.isEditableByUser ? openPlanToEdit(plan, state) : null;
        if (item.recordType === 'folder') {
            editButtonIcon = 'icon-color-picker';
            editButtonText = `Set Color for ${descriptor}`;
            editButtonAction = (element) => item.isEditableByUser ? item.startEditingColor(element) : null;
        } else if (plan.status !== 'complete' || plan.isRigid) {
            editButtonIcon = 'icon-layers';
            editButtonText = 'Edit Map Layer';
            editButtonAction = () => item.isEditableByUser ? createLayerFlow.startPositionLayer({assetId: undefined, plan}) : null;
        }
      
        const deleteLockedMessage = item.getRestrictionMessage('delete');
        const editNameLockedMessage = item.getRestrictionMessage('edit', 'name');
        const editLockedMessage = item.getRestrictionMessage('edit', 'color');
        return <div key={item.id} class="folder-options-menu-inner">
            {showEditNameButton ? <div class={`menu-option${editNameLockedMessage ? ' disabled' : ''}`} onclick={editNameLockedMessage ? null : () => item.startEditingName()}>
                <i class="icon-edit"/>Rename {descriptor}<PermissionsIndicator lockedMessage={editNameLockedMessage}/>
            </div> : ''}
            {showEditButton ? <div class={`menu-option${state.isActive ? ' active' : ''}${editLockedMessage ? ' disabled' : ''}`} onclick={() => editButtonAction(state.outermostElement)}>
                <span><i class={editButtonIcon}/>{editButtonText}<PermissionsIndicator lockedMessage={editLockedMessage}/></span>
            </div> : ''}
            <div class={`menu-option remove-link${deleteLockedMessage ? ' disabled' : ''}`} onclick={deleteLockedMessage ? null : () => item.handleDelete(descriptor)}>
                <i class="icon-trash"/>Delete {descriptor}<PermissionsIndicator lockedMessage={deleteLockedMessage}/></div>
        </div>;
    }
};

export default FolderOptionsMenu;
