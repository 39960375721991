import store from 'util/data/store';
import appModel from 'models/app-model';
import accountManager from 'managers/account-manager';
import api from 'legacy/util/api';
import formatDate from 'legacy/util/date/format-date';
import CloseButton from 'views/close-button';
import EditableText from 'views/editable-text';
import AccountMenu from 'views/side-nav/account-menu';
import constants from 'util/data/constants';

const AccountInfo = {
    oninit: ({state}) => {
        state.accountType = store.account.status === 'trial' ? 'Pro Plan: Free Trial' : `${store.account.seats} License${store.account.seats === 1 ? '' : 's'}`;
        state.isTrialAccount = store.account.status === 'trial';
        state.showUpgradeBanner = !store.account.seats;
        state.showUserWarning = store.account.seats && accountManager.usedLicenseCount / store.account.seats >= 0.8;
    },


    upgradeBanner() {
        return <div className="account-upsell-message">Unlock the power of Unearth for your team.
            <a href={`mailto:${constants.defaultSupportEmail}`}>Contact CS</a>
        </div>;
    },

    userWarning() {
        const isOverLimit = store.account.users.length >= store.account.seats;
        return <div className="account-issue-warning">
            <i class="icon-warning"></i>{isOverLimit ? "You've reached" : "You're about to hit"} your license limit. <a onclick={AccountInfo.showHubspot}>Add Seats</a>
        </div>;
    },

    view: ({state}) => <div class="account-info-modal">
        <CloseButton onclick={(e) => AccountMenu.closeModal(e)} />
        <div class="account-info-title">Account Info</div>
        <div class="padding-wrap">
            <div class="account-info-label">Account Name:</div>
            <div className="account-info-value">{appModel.user.isAccountAdmin
                ? <EditableText text={store.account.name}
                    save={(name, resolve) => {
                        Promise.all([
                            api.rpc.modifyAccount({
                                accountId: store.account.accountId,
                                name
                            }),
                            store.account.attributes.metaProjectId
                                ? api.rpc.modify('Project', {
                                    projectId: store.account.attributes.metaProjectId,
                                    name
                                })
                                : null
                        ]).then(resolve);
                    }}
                    parentSelector={'.account-info-modal'} />
                : store.account.name } </div>
            <div class="account-info-label">Plan:</div>
            <div class="account-info-value">{state.accountType} ({accountManager.usedLicenseCount} in use)</div>
            <div class="account-info-label">Created Date:</div>
            <div class="account-info-value">{formatDate.ddmmyyyy(store.account.createdDateTime)}</div>
        </div>
    </div>
};

export default AccountInfo;
