import LineString from './linestring';
import Draw from './draw';

class Polygon extends LineString {

    lastVertexClick(e) {

        if (e) {

            e.stopPropagation();

        }

        const coordinates = this.getCoordinates();

        // Remove line segment that follows the mouse cursor
        if (coordinates.length > this.vertices.length) {

            coordinates.pop();

        }

        coordinates.push(coordinates[0]);

        this.feature.geometry.type = 'Polygon';

        this.feature.geometry.coordinates = [coordinates];

        this.map.removeLayer(this.layerId);

        const source = this.map.getSource(this.originalSource.id);

        source._data.features.push(this.feature);

        this.map.removeSource(this.sourceId);

        this.source = source;

        this.render();

        this.stop();

        if (this.onComplete) {

            this.onComplete(this.feature);

        }

    }

    getCoordinates() {
        if (this.feature.geometry.type === 'Polygon') {
            return this.feature.geometry.coordinates[0];
        }
        return this.feature.geometry.coordinates;
    }

    _addVertex(e, index) {

        const lngLat = e.lngLat.toArray(),
            vertex = this.makeVertex(lngLat, index)
                .on('dragend', () => {

                    if (this.onVertexChanged) {

                        this.onVertexChanged(this.feature);

                    }

                });

        this.updateFeatureOnVertexDrag(vertex);

        this.getCoordinates().push(lngLat);

        if (vertex.index === 0) {

            this.getCoordinates().push(lngLat);

            this.source._data.features.push(this.feature);

            this.map.on('mousemove', this.onMouseMove);

        } else if (vertex.index === 2) {

            const firstVertexEl = this.vertices[0]._element;

            firstVertexEl.classList.add('click-to-close');

            firstVertexEl.onmousedown = this.lastVertexClick.bind(this);

        }

        if (this.onVertexAdded) {
            this.onVertexAdded(this.feature);

        }

    }

    createLineStringLayer() {

        // Create a temporary source and layer
        // so that we can draw a LineString
        // instead of a Polygon

        this.sourceId = '_draw_poly_source';

        this.layerId = '_draw_poly_layer';

        this.originalSource = this.source;

        this.map.addSource(this.sourceId, {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            }
        });

        this.source = this.map.getSource(this.sourceId);

        this.layer = this.map.addLayer({
            id: this.layerId,
            source: this.sourceId,
            type: 'line',
            paint: {
                'line-width': 3,
                'line-color': (this.properties._fillColor ? {
                    'type': 'identity',
                    'property': '_fillColor'
                } : this.color) || '#000000',
                'line-opacity': 1
            }
        });

    }

    newFeature() {
        this.createLineStringLayer();

        return super.newFeature();
    }

    removeVertex(vertex) {

        const coordinates = this.feature.geometry.coordinates[0];

        coordinates.splice(vertex.index, 1);

        if (vertex.index === 0) {

            coordinates.pop();

            coordinates.push(coordinates[0]);

        }

    }

    syncVertexCoordinates(vertex) {

        const coordinates = this.isNewFeature
            ? this.getCoordinates()
            : this.feature.geometry.coordinates[0];

        coordinates[vertex.index] = vertex.getLngLat().toArray();

        if (vertex.index === 0) {

            coordinates[coordinates.length - 1] = coordinates[0];

        }

    }

    edit(feature) {

        this.type = 'Polygon';

        this.lastVertexOffset = 2;

        this.minVertexCount = 3;

        this.getCoordinates = includingLast => {

            const allCoordinates = this.feature.geometry.coordinates[0];

            return includingLast ? allCoordinates : allCoordinates.slice(0, -1);

        };

        return Draw.prototype.edit.call(this, feature);

    }

    stop() {
        if (this.isNewFeature) {
            if (this.map.getLayer(this.layerId)) {
                this.map.removeLayer(this.layerId);
            }

            if (this.map.getSource(this.sourceId)) {
                this.map.removeSource(this.sourceId);
            }
        }
        Draw.prototype.stop.call(this);
    }

    completeFeature() {
        if (this.isNewFeature && this.getCoordinates().length > 3) {
            const coordinates = this.getCoordinates();
            if (coordinates.length > this.vertices.length) {
                coordinates.pop();
            }
            coordinates.push(coordinates[0]);
            this.lastVertexClick();
        }
    }

}

export default Polygon;
