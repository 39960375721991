import store from 'util/data/store';
import controlToFeature from 'util/interfaces/control-to-feature';
import sanitize from 'util/data/sanitize';

const updateAssetFeatures = (control, assetId) => controlToFeature.updateAssetFeatures(control, assetId);

export default function(control, assetId) {
    const text = store.assets[assetId].properties[control.fieldName] || '';
    return <div class="paragraph-control">
        <textarea data-focus-id="1" oninput={e => {
            store.assets[assetId].properties[control.fieldName] = sanitize(e.target.value);
            updateAssetFeatures(control, assetId);
        }}>{text}</textarea>
    </div>;
}
