import initializer from 'util/initializer';

const loaderModel = {
    count: 0,
    loading: false,
    message: '',

    reset: () => {
        loader.count = 0;
        loader.loading = false;
        loader.timeout = undefined;
        loader.message = '';
    },

    load: (message) => {
        loader.loading = true;
        loader.fade = false;
        loader.count++;
        // If no message provided, just reuse the last one or default
        loader.message = message;
        if (loader.timeout) {
            clearTimeout(loader.timeout);
        }
        m.redraw();
    },

    hide: () => {
        loader.count--;
        if (loader.count < 1) {
            loader.fade = true;
            loader.timeout = setTimeout(() => {
                loader.loading = false;
                m.redraw();
            }, 500);
        }
        m.redraw();
    },

    updateMessage: (message) => {
        loader.message = message;
        m.redraw();
    }
};

const loader = loaderModel;

initializer.add(loader.reset, 'loader');

export default loaderModel;
