import store from 'util/data/store';
import formModel from 'models/form-model';
import mediaModel from 'models/media-model';
import mediaListManager from 'managers/media-list-manager';
import LayerOptionsMenu from 'views/plan/layer-options-menu/layer-options-menu';
import onBodyClick from 'legacy/util/dom/on-body-click';
import tip from 'views/tip';
import {CenterOnMapIcon} from 'views/layer-picker/plans-layer-picker';
import layerModel from 'models/layer-model';
import ThumbnailGallery from 'views/media-viewer/thumbnails/thumbnail-gallery';
import pointMenu from 'legacy/components/point-menu/point-menu';
import popupModel from 'models/popover-model';
import convertMithril from 'util/dom/convert-mithril';
import ColorPicker from 'views/color-picker/color-picker';
import layerColorModel from 'models/layer-color-model';
import router from 'uav-router';

const openLayerMenu = (e, _layerId, assetId = router.params.assetId) => {
    popupModel.close();
    return pointMenu({
        e,
        content: convertMithril.toDom(<LayerOptionsMenu assetId={assetId} layerId={_layerId}/>),
        cssClass: 'link-list asset-options',
        forceDesktop: true,
        noLine: true
    });
};

const VectorLayerDetail = {
    view: ({attrs: {layerId}}) => {
        const plan = store.plans[layerId];
        const tileset = store.tilesets[plan.tilesetId];
        const planIcon = layerModel.getPlanIcon(plan);

        return <div class="layer-row clear">
            <div class="layer-title">
                {planIcon ? <i class={'toggle-switch ' + planIcon} onclick={(e) => layerModel.togglePlan(plan, e)}/> : ''}<span>{plan.title}</span>
            </div>
            <ColorSwatch tileset={tileset}/>
            <CenterOnMapIcon planId={plan.planId}/>
            <div class="folder-header-menu-button">
                <i class="icon-ellipsis" onclick={(e) => openLayerMenu(e, layerId)}></i>
            </div>
        </div>;
    }
};

export const IncompletePlanWarning = {
    view: ({attrs: {status}}) => {
        if (status !== 'assembling') {
            return '';
        }
        return <div
            onmouseenter={function() {
                tip.show(this, {tipText: 'This Layer has not been added to the map yet.'});
                onBodyClick.once(() => tip.hide());
            }}
            onmouseleave={() => tip.hide()}
            class="warning-tip-wrap">
            <i class="icon-triangle-exclaim"/>
        </div>;
    }
};

const ColorSwatch = {
    view: ({attrs: {tileset}}) => {
        return tileset ? <div class={`color-swatch ${tileset.color === 'None' ? 'layer-color-off' : ''}`} 
            style={{ backgroundColor: `${tileset.color}`}}
            onclick={(e) => {
                popupModel.close();
                popupModel.open(e.target, {
                    view: ColorPicker,
                    noWidth: true,
                    args: {
                        containerId: 'color-picker-wrap-plan-control', 
                        showOpacity: true, 
                        starterColor: tileset.color, 
                        starterOpacity: tileset.opacity, 
                        setColorFn: (hex, opacity) => layerColorModel.setColor(hex, opacity, tileset.tilesetId)
                    }
                });
            }}></div>
            : '';
    }
};

const CompleteLayerDetail = {
    
    view: ({attrs: {plan}}) => {
        const tileset = store.tilesets[plan.tilesetId];
        const planIcon = layerModel.getPlanIcon(plan);
        return <>
            <div class="layer-title">
                {planIcon ? <i class={'toggle-switch ' + planIcon} onclick={(e) => layerModel.togglePlan(plan, e)}/> : ''}<span>{plan.title}</span>
            </div>
            <ColorSwatch tileset={tileset}/>
            <CenterOnMapIcon planId={plan.planId}/>
            <div class="folder-header-menu-button">
                <i class="icon-ellipsis" onclick={(e) => openLayerMenu(e, plan.planId)}></i>
            </div>
        </>;     
    }
};

const IncompleteLayerDetail = {
    view: ({attrs: {plan}}) => {
        return <>
            <div class="layer-title">
                <IncompletePlanWarning status={plan.status}/><span>{plan.title}</span>

            </div>
            <div class="folder-header-menu-button">
                <i class="icon-ellipsis" onclick={(e) => openLayerMenu(e, plan.planId)}></i>
            </div>
        </>;     
    }
};

const OverlayLayerDetail = { 
    view: ({attrs: {layerId}}) => {
        const plan = store.plans[layerId];
        return <div class="layer-row">
            {plan.status === 'complete' ? <CompleteLayerDetail plan={plan}/> : <IncompleteLayerDetail plan={plan}/>}
        </div>;
    }
};


const LayerDetail = {
    view: ({ attrs: {layerId, asset}}) => {
        const plan = store.plans[layerId];
        const tileset = store.tilesets[plan.tilesetId];
        if (!tileset || tileset.tilesetType !== 'external_vector') {
            return  <OverlayLayerDetail layerId={layerId} asset={asset}/>;
        }
        if (tileset.tilesetType === 'external_vector') {
            return <VectorLayerDetail layerId={layerId} asset={asset}/>;
        } 
        return ''; // Shouldn't hit this 
    }
};

const SourceFile = {
    oninit: async ({state, attrs: {mediaId}}) => {
        const media = await mediaListManager.getMediaAsync(mediaId);
        state.sourceTitle = media.label;
        m.redraw();
    },

    view: ({state, attrs: {mediaId}}) => {
        return <div class="plan-control-source-file">
            <label>Source File</label>
            <ThumbnailGallery mediaIds={[mediaId]} showOptions={true} previewMode={true}/>
            <div class="file-title">{state.sourceTitle}</div>
        </div>;
    }
};

const LayersList = {
    view: ({attrs: {layerIds, asset}}) => {
        layerIds = layerIds.filter(layerId => !!store.plans[layerId]).reverse();
        return <div class="plan-control-map-layers">
            {layerIds.length ? layerIds.map(layerId => <LayerDetail layerId={layerId} asset={asset}/>) : <div class="no-layers-message">No Map Layers here just yet. </div>}
        </div>;
    }
};

const PlanControl = {
    view: ({attrs: {control, assetId}}) => {
        const asset = store.assets[assetId];
        let layerIds = asset.properties[control.label] || [];
    
        if (typeof asset.properties[control.label] === 'string') {
            layerIds = [layerIds];
        }

        return <div class="plan-control">
            <LayersList layerIds={layerIds} asset={asset}/>
            {asset.mediaId && !formModel.isReadOnlyControl(asset.assetTypeId, control)
                ? <div class="layer-actions clear">
                    <SourceFile mediaId={asset.mediaId} control={control}/>
                    <div class="btn btn-secondary btn-pill btn-smallest show-on-large" onclick={() =>
                        mediaModel.createLayer(asset.mediaId, asset.contentId)
                    }><span class="btn-label">Create New Map Layer</span></div>
                </div>
                : null}
        </div>;
    }
};

export default function(control, assetId) {
    return <PlanControl control={control} assetId={assetId}/>;
}
