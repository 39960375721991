import peopleModel from 'models/people/people-model';
import AutoCompleteModel from 'models/auto-complete-model';
import convertMithril from 'util/dom/convert-mithril';
import debounce from 'util/events/debounce';
import onBodyClick from 'legacy/util/dom/on-body-click';
import {getNormalizedKey} from 'util/events/get-normalized-key';

const MENTION_CHAR = '@';
const MENTION_MATCH_REGEX = /\w+(\s\w+)?/g; 

const AUTO_EXPAND_PX = 40;
const ORIGINAL_HEIGHT = 64;

// Rich segment types
const MENTION = {
    tag: 'span',
    styleClass: 'user-mention'
};

const encodedText = (text) => text.replace('&amp;', '&').replace('&', '&amp;');

class RichSegmentModel {

    constructor(text, type, data, id) {
        this.text = text;
        this.type = type || 'string';
        this.data = data;
        this.id = id;
    }

    // For rendering
    getRenderedText() {
        // Currently we only have special display handling for @mentions
        switch (this.type) {
        case MENTION:
            return `<span id="${this.id}" class="${this.type.styleClass}">${encodedText(this.text)}</span>`;
        default:
            return this.text;
        }
    }

    // Depending on the type of segment, returns the format we need for the api
    getApiReadyText() {
        switch (this.type) {
        case MENTION:
            return this.data;
        default:
            return this.text;
        }
    }
}

class TextEditorModel {

    constructor() {
        this.segments = {}; // Rich segments including markup, keep track in case they are corrupted

        this.htmlString = '';
        this.matchTerm = '';
        this.focusClass = 'blurred';

        this.state = {
            insertingMention: false,
            isAutoCompleting: false,
            isEditing: false,
            isOriginalHeight: true
        };

        this.isIe = !!window.document.documentMode;
        this.lastValueIe = ''; // For Ie handling only

        this.handleInput = debounce(this._handleInput.bind(this)); 
        this.handleKeyUp = debounce(this._handleKeyUp.bind(this)); // Non-input keys (arrows, e.g.)
        // For ie (can't detect input event on contenteditable div, so use the key down instead
        this.handleKeyDown = debounce(this._handleKeyDown.bind(this));
        this.handleScroll = debounce(this._handleScroll.bind(this));
        this.handleSelect = this._handleSelect.bind(this);
        this.handlePaste = this._handlePaste.bind(this);
    }

    initAutoComplete(types) {
        this.autocompleter = new AutoCompleteModel(types, this.handleSelect);
    }

    getApiReadyContent() {
        let html = this.dom.innerHTML;
        // First gather the HTML and swap out short codes:
        Object.values(this.segments).forEach(segment => {
            html = html.replace(segment.getRenderedText(), segment.getApiReadyText());
        });
        // Then just pull out the plain text:
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.innerText;
    }

    isEmpty() {
        return !this.dom || !this.dom.innerText || !this.dom.innerText.trim().length;
    }

    setDom(element) {
        this.dom = element;
        m.redraw();
    }

    setHtml(newHTML) {
        this.dom.innerHTML = newHTML;
        m.redraw();
    }

    initExistingHTML(textString, tokens) {
        let lastCleanIndex = 0;
        let richText = '';
        if (tokens.length) {
            tokens.forEach(token => {
                if (peopleModel.isInPeopleList(token.value)) {
                
                    const apiMentionStr = this.getApiMentionStr(token.value);
                    const renderMentionStr = this.getRenderMentionStr(token.value);
                    const id = this.getUniqueId(apiMentionStr);
                    const prePlaintext = this.wrapInSpan(textString.substring(lastCleanIndex, token.start));
                    this.segments[id] = new RichSegmentModel(renderMentionStr, MENTION, apiMentionStr, id);

                    // Append all the next plaintext before mention, and append the mention
                    richText = richText + prePlaintext + this.segments[id].getRenderedText();
                    lastCleanIndex = token.end + 1;
                }
            });
            richText = richText + `<span>${textString.substring(tokens[tokens.length - 1].end + 1)}`;
        } else {
            richText = textString.length ? `<span>${textString}</span>` : '';
        }
        
        this.onNextRender = (dom) => {
            dom.innerHTML = richText;
        };
        this.syncHtml = true;
        m.redraw();
    }

    clear() {
        if (!this.state.isOriginalHeight) {
            this.transitionHeight(ORIGINAL_HEIGHT); 
        }
        this.segments = {};
        this.state = {
            insertingMention: false,
            isAutoCompleting: false,
            isEditing: false,
            isOriginalHeight: true
        };
        this.matchTerm = '';
        if (this.dom) {
            this.dom.innerHTML = '';
        }
        this.stopAutoCompleting();
      
        m.redraw();
    }

    reset() {
        this.segments = {};
        this.stopAutoCompleting();
        this.state.insertingMention = false;
    }

    // ---------- Autocompleting @ mentions ----------

    refreshMatchTerm() {
        const index = this.autocompleteIndex;
        return this.getMatchTermFrom(index, 0);
    }

    getMatchTermFrom(index, offsetStart = 0, offsetEnd = 0) {
        this.autocompleteIndex = index;
        const selection = this.selection;

        let textLength, text;
        if (selection) {
            textLength = selection.anchorNode.textContent.length;
            text = selection.anchorNode.textContent.substring(offsetStart, textLength + offsetEnd);
        } else {
            textLength = 0;
            text = '';
        }

        const terms = text.slice(index).match(MENTION_MATCH_REGEX);
        return terms && terms.length ? terms[0] : '';
    }

    findMatchesFor(term = '') {
        if (!this.state.isAutoCompleting) {
            this.startAutoCompleting();
        }
        this.autocompleteTerm = term;
        this.autocompleter.filterOptions(term);
        if (!this.autocompleter.filteredOptions.length) {
            this.stopAutoCompleting();
        }   
    }

    checkForCorruptedSegments() {
        Object.values(this.segments).forEach(segment => {
            const element = document.getElementById(segment.id);
            if (!element) {
                delete this.segments[segment.id];
            } else if (element.innerText !== segment.text) {
                element.removeAttribute('class');
                element.removeAttribute('id');
                delete this.segments[segment.id];
            }
        });
        m.redraw(); // Remove highlighting class 
    }

    // ---------- Event Handling ----------

    // Special handling for inserting a mention using the @ button (rather than via typing)
    insertMention() {
        onBodyClick.clear();
        if (!this.state.insertingMention) { 
            this.state.insertingMention = true;
            this.findMatchesFor();
            this.focusClass = 'focused';
            m.redraw();

            onBodyClick.once(() => {
                this.state.insertingMention = false;
                this.stopAutoCompleting();
            });
        }
    }
    
    appendNewMention(option, newId) {
        // Create both formats of the userId tag: one to render, one to save to API
        const segment = this.segments[newId] = new RichSegmentModel(
            this.getRenderMentionStr(option), 
            MENTION, 
            this.getApiMentionStr(option), newId);
        const newElement = convertMithril.toDom(<span id={segment.id} class="user-mention">{segment.text}</span>); 
        this.dom.appendChild(newElement);
        const spacer = convertMithril.toDom(<span>&nbsp;&nbsp;</span>);     
        this.dom.appendChild(spacer);
    }

    // Remove user input, add option and index as segment, and close the autocompleter
    _handleSelect(option) {      
        let startIndex, replaceTerm;
        const newId = this.getUniqueId(option);
        if (this.state.insertingMention) {
            this.appendNewMention(option, newId);
        } else {
            startIndex = this.autocompleteIndex - MENTION_CHAR.length;
            replaceTerm = MENTION_CHAR + this.autocompleteTerm;
      
            let nodeToUpdate, parentNode, parentElement;
            // Typical path, update the node the user is anchored to
            if (this.selection && this.selection.anchorNode) {
                nodeToUpdate = this.selection.anchorNode;
                parentNode = nodeToUpdate.parentNode;
                parentElement = nodeToUpdate.parentElement;
            } else {
                nodeToUpdate = this.dom;
            }
            
            const nodeData = nodeToUpdate && nodeToUpdate.data || '';
            // Grab the plaintext still remaining and add it to a segment:
            const preText = nodeData.substring(0, startIndex);
            const postText = nodeData.substring(startIndex + replaceTerm.length);

            // Create both formats of the userId tag: one to render, one to save to API
            const segment = this.segments[newId] = new RichSegmentModel(
                this.getRenderMentionStr(option), 
                MENTION, 
                this.getApiMentionStr(option), newId);
            const newNodeData = <div><span>{preText}&nbsp;</span><span id={segment.id} class="user-mention">{segment.text}</span><span>&nbsp;{postText.length ? postText + ' ' : ''}</span></div>;
            const newNode = convertMithril.toDom(newNodeData);

            if (!parentElement || parentElement.className.includes('text-editor')) {
                // We are in the main text editor element still, just insert the new content
                this.dom.innerHTML = newNode.innerHTML;
            } else if (parentNode) {
                // Flatten the new spans into our text editor div rather than nesting them inside the existing span:
                const first = newNode.childNodes[0];
                const second = newNode.childNodes[1];
                const third = newNode.childNodes[2];
                const grandparent = parentNode.parentNode;
                const nodeToReplace = parentNode;
                grandparent.replaceChild(third, nodeToReplace);
                grandparent.insertBefore(second, third);
                grandparent.insertBefore(first, second);
            }
        }

        this.stopAutoCompleting();
        this.syncHtml = true;

        this.onNextRender = () => this.positionCaretAfterNewNode(newId);
        this.state.insertingMention = false;

        m.redraw();
    }

    onBackspaceKey() {   
        const selection = this.selection;
        if (this.state.isAutoCompleting) {
            this.autocompleter.resetInput(true);
            // Deleted auto complete @ character
            if (selection.anchorNode.textContent.length < this.autocompleteIndex) {
                this.stopAutoCompleting();
            } else {
                // Match on the updated term:
                const term = this.getMatchTermFrom(this.autocompleteIndex);
                this.findMatchesFor(term);
            }
        } else {
            const mentionTokenIndex = selection.anchorNode.textContent.indexOf(MENTION_CHAR);
            if (mentionTokenIndex > -1) {
                const term = this.getMatchTermFrom(mentionTokenIndex + 1);
                this.findMatchesFor(term);
            }
        }
    }

    handleFocus() {
        if (this.isEmpty()) {
            return this.clear();
        }
        this.focusClass = 'focused';
        m.redraw();
    }

    handleClick() {
        this.selection = window.getSelection();
        if (this.state.isAutoCompleting) {
            this.stopAutoCompleting();
            m.redraw();
        }
    }

    _handlePaste(e) {        
        const pastedText = (e.clipboardData || window.clipboardData).getData('text');
    
        const selection = window.getSelection();
        if (!selection.rangeCount) {
            return false;
        }

        const newNodeData = <span>{pastedText}</span>;
        const newNode = convertMithril.toDom(newNodeData);
        const parentNode = selection.getRangeAt(0).startContainer.parentNode;
        const parentElement = selection.getRangeAt(0).startContainer.parentElement;
        if (parentNode.nodeName === 'DIV' && parentElement.className.includes('text-editor') ) {
            selection.deleteFromDocument();
            selection.getRangeAt(0).insertNode(newNode);
        } else {
            selection.getRangeAt(0).insertNode(document.createTextNode(pastedText));
        }

        e.preventDefault();
        m.redraw();
        this.checkForCorruptedSegments();
    }

    handleBlur() {
        this.stopAutoCompleting();
        this.focusClass = 'blurred';
        m.redraw();
    }

    _handleKeyUp(e) {
        // Reset the caret selection on key down
        this.selection = window.getSelection();

        const key = getNormalizedKey(e.key);
        if (key === 'Backspace' || key === 'Delete') {
            return this.onBackspaceKey();
        }
        if (this.state.isAutoCompleting) {
            switch (key) {
            // Let autocompleter handle arrowing up and down keys
            case 'ArrowDown':
            case 'ArrowUp':
                return;
            // Else, close the autocompleter so user can arrow around through text editor
            case 'ArrowLeft':
            case 'ArrowRight':
                return this.stopAutoCompleting();
            // On enter, if nothing is selected just close auto completer:
            case 'Enter':
                if (!this.autocompleter.focusedOption) {
                    e.stopPropagation();
                    return this.stopAutoCompleting();
                }
                return;
            default:
                break;
            }
            this.state.insertingMention = false;
        }
    }

    _handleInput(e) {
        if (e.inputType === 'insertText') {
            if (e.data === MENTION_CHAR) {
                this.autocompleteIndex = this.selection.anchorOffset; // Start autocompleting at the location of text caret (right after @ character)
                const latestTerm = this.refreshMatchTerm();
                this.findMatchesFor(latestTerm);
            } else if (this.state.isAutoCompleting) {
                const latestTerm = this.refreshMatchTerm();
                this.findMatchesFor(latestTerm);
            }
        }
        this.checkForCorruptedSegments();
    }

    _handleKeyDown(e) {
        if (this.isIe) {
            setTimeout(() => {
                const currentValue = e.target.textContent.trim();
                if (this.lastValueIe === currentValue) {
                    return;
                }

                // save the value on the input state object
                this.lastValueIe = currentValue;

                e.inputType === 'insertText';
                e.data = e.key;
                this.handleInput(e);
            });
        }
    }

    _handleScroll() {
        if (this.state.isOriginalHeight) {
            this.state.isOriginalHeight = false;
            this.transitionHeight(this.dom.offsetHeight + AUTO_EXPAND_PX); 
        }
    }

    // ---------- Positioning the caret AKA blinky text cursor thingy ----------

    positionCaretAfterNewNode(id) {
        this.selection = window.getSelection();
        let start, end;
        let node = document.getElementById(id);
        if (!node) {
            return this.positionCaretAtEndOfCurrentNode(); // Node has since been deleted.
        }
        node = node.nextSibling ? node.nextSibling : node;
        if (node.nodeName === 'SPAN') {
            // In a span
            if (node.firstChild && node.firstChild.nodeName === '#text') {
                node = node.firstChild; // set to #text node within span
            }
            if (node.data && node.data.length) {
                start = end = node.data.length;
            } else {
                start = 0;
                end = 0;
            }
            this.positionCaret(node, start, end);
        }
    }

    positionCaretAtEndOfCurrentNode() {
        this.selection = window.getSelection();
        let node = this.selection.extentNode;
        let start, end;
        if (node) {
            if (node.nodeName === 'DIV') {
                // In primary div
                if (node.lastChild && node.lastChild.nodeName === '#text') {
                    node = node.lastChild; // set to #text node
                } else if (node.lastChild && node.lastChild.nodeName === 'SPAN') {
                    node = node.lastChild; // set to span
                    if (node.lastChild && node.lastChild.nodeName === '#text') {
                        node = node.lastChild; // set to #text node within span
                    }
                }
            } else if (node.nodeName === 'SPAN') {
                // In a span
                if (node.lastChild && node.nodeName === '#text') {
                    node = node.lastChild; // set to #text span within div
                }
            }
            if (node.data && node.data.length) {
                start = end = node.data.length;
            } else {
                start = 0;
                end = 0;
            }

            this.positionCaret(node, start, end);
        }
    }

    positionCaret(node, start, end) {
        const newRange = document.createRange();
        newRange.setStart(node, start);
        newRange.setEnd(node, end);
        newRange.collapse(true); // Rather than selecting multiple chars, ensure it collapses to 1 position

        this.selection.removeAllRanges();
        this.selection.addRange(newRange);
        this.dom.focus();

        m.redraw();
    }

    // ---------- Little helpers ----------

    startAutoCompleting() {
        this.autocompleter.start(true);
        this.state.isAutoCompleting = true;
        m.redraw();
    }

    stopAutoCompleting() {
        if (this.autocompleter) {
            this.autocompleter.stop();
        }
        this.state.isAutoCompleting = false;
        m.redraw();
    }

    getUniqueId(starterString) {
        // Add a segment with the rich text and data associated to the mention
        let newId = starterString.substring(0, 6); // Cut off the "<@" and just grab the first few characters
        let idIsUnique = false;
        let i = 1;
        while (!idIsUnique) {
            if (!this.segments.hasOwnProperty(newId)) {
                idIsUnique = true;
            } else {
                newId += i;
                i++;
            }
        }
        return newId;
    }

    getRenderMentionStr(userId) {
        return `${'@' + peopleModel.displayUserControlOption(userId)}`;
    }

    getApiMentionStr(userId) {
        return `<@${userId}>`;
    }

    wrapInSpan(string) {
        return `<span>${string}</span>`;
    }

    transitionHeight(newHeight) {
        this.dom.style.transition = 'height 0.4s ease';
        this.dom.style.height = newHeight + 'px';
        setTimeout(() => {
            this.dom.style.transition = 'none';
        }, 400);
    }
}

export default TextEditorModel;
