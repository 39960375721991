const LoadingSpinnerWithText = {
    view: ({attrs: {text, iconClass, outerClass, spinnerColor}}) => {
        return <div class={`spinning-loader ${outerClass || ''}`}>
            <i class={`spinner spinning ${spinnerColor || ''}`}/>
            <div class="icon-wrap"><i class={iconClass}/></div>
            <div class="text-wrap">{text}</div>
        </div>;
    }
};

export default LoadingSpinnerWithText;
