import FilterTypeModel from 'models/table/filter-types/filter-type-model';
import debounce from 'util/events/debounce';
 
class LinkedAssetFilterModel extends FilterTypeModel {

    init() {
        super.init({type: 'linkedAsset', specialProperties: ['selectedRadioIndex']});
        this.debouncedFilterUpdate = debounce(this.onFilterUpdate.bind(this));
    }

    selectRadio(i) {
        if (i === this.state.selectedRadioIndex) {
            return;
        }
        this.state.selectedRadioIndex = i;
        this.onFilterUpdate();
    }
}

export default new LinkedAssetFilterModel();
