import store from 'util/data/store';
import formModel from 'models/form-model';
import appModel from 'models/app-model';
import ThumbnailGallery from 'views/media-viewer/thumbnails/thumbnail-gallery';

export default function(control, assetId) {
    const asset = store.assets[assetId];
    const mediaIds = asset.properties[control.fieldName] || [];
    const maxFiles = control.attributes.maxFiles;
    const userCanUpload = appModel.user.permissions.canEditContent(assetId) && !formModel.isReadOnlyControl(asset.assetTypeId, control);
    const canUpload = userCanUpload && (maxFiles === undefined || maxFiles === null || mediaIds.length < maxFiles);
    return <div class="file-control">
        {!!mediaIds.length && <ThumbnailGallery mediaIds={mediaIds} showOptions={true} previewMode={true} control={control} />}
        {canUpload && <div class="file-control-btn">
            <div class="btn btn-secondary btn-pill btn-smallest" onclick={() =>
                formModel.uploadFileAttachments(control)
            }>Upload {control.label}</div>
        </div>}
    </div>;
}
