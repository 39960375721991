import store from 'util/data/store';
import controlToFeature from 'util/interfaces/control-to-feature';
import sanitize from 'util/data/sanitize';

const updateAssetFeatures = (control, assetId) => controlToFeature.updateAssetFeatures(control, assetId);

export default function(control, assetId) {
    const getValues = () => store.assets[assetId].properties[control.fieldName];
    let properties = store.assets[assetId].properties,
        values = getValues() || [''];
    properties[control.fieldName] = values;
    const canDelete = values.length > 1 || values[0];
    return <div class="multitext">
        {values.map((value, i) => <div class="text-control">
            <input data-focus-id="1" type="text" value={value} oninput={e => {
                getValues()[i] = sanitize(e.target.value);
                updateAssetFeatures(control, assetId);
            }}/>
            {canDelete && <i class="icon-close" onclick={() => {
                properties = store.assets[assetId].properties;
                values = properties[control.fieldName];
                values.splice(i, 1);
                if (!values.length) {
                    delete properties[control.fieldName];
                }
                updateAssetFeatures(control, assetId);
            }} />}
        </div>)}
        <div class="btn btn-secondary btn-pill btn-smallest" onclick={() => getValues().push('')}><i class="icon-plus" /></div>
    </div>;
}
