import screenHelper from 'legacy/util/device/screen-helper';

// If the web page is embedded in a WKWebView (AKA in the iOS app),
// close the browser. Otherwise, call the provided callback.
function webOnly(message, fn) {

    if (screenHelper.isIosWebView()) {

        window.webkit.messageHandlers.closeweb.postMessage(message || 'closeweb');

    } else {

        fn();

    }

}

export default webOnly;
