import savedViewsModel from 'models/saved-views-model';
import Radio from 'views/radio';

const SavedViewsSortFilter = {
    view: () => {
        return <div class={`sort-view${savedViewsModel.loadingSort ? ' is-loading' : ''}`}>
            <span className="subtitle">Sort By:<i class="spinner teal"/></span>
            <div className= 'radio-padding'>
                <Radio options={['Name A-0', 'Name 0-Z', 'Created Date: Newest-Oldest', 'Created Date: Oldest-Newest']}
                    isSort = {true}
                    defaultOption={savedViewsModel.sortOrder} onchange={option => {
                        savedViewsModel.updateOrder(option);
                    }}/>
            </div>
        </div>;
    }
};
    
    
export default SavedViewsSortFilter;
