import savedViewsModel from 'models/saved-views-model';
import SavedViewsListItem from 'views/saved-views/list/saved-views-list-item';
import popupModel from 'models/popover-model';
import savedViewsSortFilter from 'views/saved-views/saved-views-sort-filter';
import appModel from 'models/app-model';
import capitalize from 'util/data/capitalize';

const ViewsSearchBox = {
    oncreate: ({dom}) => {
        savedViewsModel.viewSearchInput = dom.firstElementChild;
    },

    onremove: () => {
        savedViewsModel.clearProjectViewSearch();
    },

    view: () =>
        <div class="views-search-box padding-wrap">
            <input type="text" oninput={(e) => savedViewsModel.searchProjectViews(e.target.value)} placeholder="Type to filter ..."/>
            {savedViewsModel.isSearchingViews && (savedViewsModel.loadedAllViews ? <button class="clear-search-btn btn-pill btn-secondary btn btn-smallest" onclick={() => savedViewsModel.clearProjectViewSearch()}>Clear</button>
                : <i class="spinning teal spinner"/>)}
        </div>
};


const SavedViewsList = {
    oninit: ({state}) => {
        state.siteTermSingular = appModel.project.isMetaProject ? 'in your Portfolio' : `for this ${capitalize(appModel.toolbox.siteTermSingular)}`;
    },

    view: ({state}) => {
        state.moreCount = savedViewsModel.projectViewsCount - (savedViewsModel.activeSavedViewId ? 1 : 0);
        return <div class="saved-views-list">
            <div class="saved-views-list-header">
                {savedViewsModel.isSearchingViews ? <span className="side-nav-popup-subtitle">{savedViewsModel.loadedAllViews ? savedViewsModel.sortedIds.length : '...'} matching Saved View{savedViewsModel.sortedIds.length === 1 ? '' : 's'} found.</span>
                    : <span className="side-nav-popup-subtitle">{savedViewsModel.activeSavedViewId ? ' More ' : ' Saved Views '}{state.siteTermSingular} ({state.moreCount})</span>}
                {state.moreCount > 0 || (savedViewsModel.projectViewsCount > 0 || savedViewsModel.filterParam) ? <>
                    <div className='btn-secondary btn-pill sort-button' onclick={function(e) {
                        e.stopPropagation();
                        if (popupModel.isOpen) {
                            popupModel.close();
                        } else {
                            popupModel.open(this, {
                                view: savedViewsSortFilter,
                                wrapClass: 'saved-views-sort-filter',
                                keepUIOpen: true,
                                yPadding: -20
                            });
                        }
                    }}>
                        <i className="icon-arrow-up"/>
                        <i className="icon-arrow-down"/>
                        <span className="sort-text">0-Z</span>
                    </div>
                    <div className="saved-views-search">
                        <ViewsSearchBox/>
                    </div>
                </> : ''}
            </div>
            {savedViewsModel.sortedIds.map((projectViewId) => {
                if (projectViewId === savedViewsModel.activeSavedViewId && !savedViewsModel.filterParam) {
                    return <span key={projectViewId}/>;
                }
                return <SavedViewsListItem projectViewId={projectViewId} key={projectViewId}/>;
            })
            }
        </div>;

    }
};

export default SavedViewsList;
