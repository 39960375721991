import tableModel from 'models/table/table-model';

class SharedColumnModel {

    constructor(sharedColumnName, assetTypeId, controlType) {
        this.sharedColumnName = sharedColumnName;
        this.assetTypeIds = [assetTypeId];
        this.controlType = controlType;
        this.name = controlType.fieldName;
    }

    addAssetTypeId(assetTypeId) {
        this.assetTypeIds.push(assetTypeId);
    }

    get isFiltered() {
        let foundOneInUse = false;
        for (let i = 0; i < this.assetTypeIds.length; i++) {
            foundOneInUse = tableModel.projectView.isFilterInUse(this.assetTypeIds[i], this.controlType.fieldName);
            if (foundOneInUse) {
                return foundOneInUse;
            }
        }
        return foundOneInUse;
    }


}

export default SharedColumnModel;
