import {Popup} from 'mapbox-gl';
import siteModel from 'models/site-model';
import screenHelper from 'legacy/util/device/screen-helper';

const MapPopup = {

    add: (opts = {}) => {
        const {lngLat, content, className, attrs, maxWidth} = opts;

        MapPopup.remove();
    
        if (screenHelper.small()) {
            siteModel.map.panTo(opts.lngLat);
        }
    
        MapPopup.content = content;
        MapPopup.attrs = attrs;
        MapPopup.isOpen = true;
        
        // Leverage mapbox's built in popup functionality for positioning and map interaction, 
        // but we'll add mithril-generated dom content to it onupdate()
        MapPopup.popup = new Popup({closeButton: false,
            className,
            maxWidth
        });
        MapPopup.popup.setLngLat(lngLat)
            .addTo(siteModel.map);
        
        m.redraw();
        return MapPopup.popup;
    },

    remove: () => {
        if (MapPopup.isOpen) {
            MapPopup.popup.remove();
            MapPopup.attrs = undefined;
            MapPopup.content = undefined;  
            MapPopup.isOpen = false;
            m.redraw();
        }
    },

    onupdate: ({dom}) => {
        // Leverage mithril's vnode update cycle to reset the dom content of our popup on change 
        if (MapPopup && MapPopup.popup) {
            MapPopup.popup.setDOMContent(dom);
        }
    },

    view: ({state: {isOpen, content, attrs}}) => {
        return isOpen ? <>{m(content, attrs)}</> : '';
    }
};

export default MapPopup;
