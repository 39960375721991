import importModel from 'models/import-model';
import constants from 'util/data/constants';
import addCommas from 'util/numbers/add-commas';
import AnalyzingStep from 'views/data-import/steps/analyzing-step';
import ConfirmRecordsStep from 'views/data-import/steps/confirm-records-step';
import CreateAssetsStep from 'views/data-import/steps/create-assets-step';
import MatchColumnsStep from 'views/data-import/steps/match-columns-step';

const importSteps = [AnalyzingStep, ConfirmRecordsStep, MatchColumnsStep, CreateAssetsStep];

const DataImport = {
    onremove: () => importModel.cleanup(),
    view: () => {
        const tool = importModel.tool,
            prevStep = importSteps[importModel.step - 1],
            nextStep = importSteps[importModel.step + 1],
            matchedCols = Object.keys(importModel.args.formMappings).length;
        return <div class="data-import tool-launcher">
            <div class="asset-title-wrap">
                <img class="asset-icon" src={constants.staticMediaURL + tool.assetForm.assetType.attributes.icon.mediaId} />
                <div class="asset-title">{tool.name}</div>
                <div class="btn btn-smallest btn-secondary btn-pill asset-cancel" onclick={importModel.close}><span class="btn-label">Cancel</span></div>
            </div>
            <div class="tool-launcher-prompt">
                <div class="center">
                    <div class="data-import-steps clear">
                        {importSteps.map((step, i) =>
                            <div class={`data-import-step ${importModel.step >= i ? 'complete' : ''}`}>{step.name}</div>)}
                    </div>
                    <div class="data-import-blocks clear">
                        <div class="data-import-block">
                            <img class="left" src={constants.staticMediaURL + importModel.fileIcon} />
                            {importModel.media.label}
                        </div>
                        {!!importModel.validCount && <div class="data-import-block">Records Confirmed</div>}
                        {!!(importModel.validCount && matchedCols) && <div class="data-import-block">{addCommas(matchedCols)} Columns Matched</div>}
                    </div>
                    <div class="data-import-wrap">{m(importSteps[importModel.step])}</div>
                </div>
            </div>
            {importModel.step !== 3 && <div class="data-import-nav">
                <div class="previous-step">
                    <div class="nav-tip-text">{prevStep ? prevStep.name : importSteps[0].name}</div>
                    <div class={`btn btn-pill btn-primary ${importModel.step <= 2 ? '' : 'disabled'}`} onclick={() => importModel.back()}>
                        <span class="btn-label"><i class="icon-arrow-left" /> Back</span>
                    </div>
                </div>
                <div class="next-step">
                    <div class="nav-tip-text">{nextStep && nextStep.name}</div>
                    <div class={`btn btn-pill btn-primary ${nextStep && importModel.canMoveForward() ? '' : 'disabled'}`} onclick={() => importModel[nextStep ? 'next' : 'done']()}>
                        <span class="btn-label">{nextStep ? 'Next' : 'Done'} <i class="icon-arrow-right" /></span>
                    </div>
                </div>
            </div>}
        </div>;
    }
};

export default DataImport;
