import store from 'util/data/store';
import router from 'uav-router';
import api from 'legacy/util/api';
import modalModel from 'models/modal-model';
import appModel from 'models/app-model';
import siteModel from 'models/site-model';
import tableModel from 'models/table/table-model';
import message from 'views/toast-message/toast-message';
import capitalize from 'util/data/capitalize';
import initializer from 'util/initializer';
import dialogModel from 'models/dialog-model';
import popupModel from 'models/popover-model';
import sideNavModel from 'models/side-nav-model';
import SavedViewsCard from 'views/saved-views/card/saved-views-card';
import SavedViewsDeleteOptions from 'views/saved-views/saved-views-delete-options';
import debounce from 'util/events/debounce';
import ProjectViewModel from 'models/table/project-view-model';
import filterFormatter from 'util/data/filter-formatter';
import PaginatedFetch from 'util/network/paginated-fetch';
import formModel from 'models/form-model';

const SAVE_TO_ALL = 0;
const JUST_THIS_PROJECT = 1;
const MAX_PROJECTS_LISTS_TO_STORE = 3;

const NameASC = 'Name A-0';
const NameDESC = 'Name 0-Z';
const DateDESC = 'Created Date: Oldest-Newest';
const DateASC = 'Created Date: Newest-Oldest';

const LIST_API_REQUEST = (args = {}) => api.rpc.request([['listProjects', {...args, isVisible: true}]], false, true);

class SavedViewsModel {
    constructor() {
        initializer.add(() => this.reset());
    }

    reset() {
        this.cancelSave = undefined;
        this.projectCount = undefined;
        this.paginator = undefined;
        this.isSaving = false;
        this.isFetching = false;
        this.isInitted = false;
        this.loadingSort = false;
        this.selectedSavingOption = SAVE_TO_ALL;

        this._savingOptions = [];
        this._projectViews = {};
        this.sortedIds = [];

        this.sortArg = 'createdDateTime asc';
        this.sortOrder = 'Created Date: Newest-Oldest';
        this.isSearchingViews = false;
        this.isSearchingProjects = false;
        this.loadedAllViews = false;

        this.filterParam = '';

        this.projectsByProjectViewId = {};
        this.projectViewsByProjectId = {};

        this.filterConfigsToText = {};
        this.childProjects = [];

        this.searchChildProjects = debounce(this.searchChildProjects.bind(this), 400);
        this.searchProjectViews = debounce(this.searchProjectViews.bind(this), 400);
        this.afterInitPromises = []; // Stores promises for callers of savedViewsModel.waitUntilInit();
    }

    loadProjects() {
        const metaProjectId = store.account && store.account.attributes ? store.account.attributes.metaProjectId : undefined;
        if (!this.paginator) {
            this.paginator = new PaginatedFetch(LIST_API_REQUEST,
                {
                    args: {accountId: store.account.accountId, filters: [{projectIdNe: metaProjectId}]},
                    onResults: this.handleResults.bind(this),
                    onComplete: this.onComplete.bind(this)
                });
            this.paginator.next();
        } else if (!this.paginator.isComplete) {
            this.paginator.next();
        }
    }

    static removeProjectsWithoutProjectAssets(projects) {
        const projectsObject = {};
        const validProjects = [];
        projects.forEach(result => {
            projectsObject[result.projectId] = result;
        });
        return api.rpc.request([['listContent', {
            projectId: appModel.project.projectId,
            limit: projects.length,
            isVisible: true,
            filters: [{'properties._projectId': {in: Object.keys(projectsObject)}}]
        }]]).then((results) => {
            results.forEach(result => {
                const project = projectsObject[result.properties._projectId];
                validProjects.push(project);
            });
            return validProjects;
        });
    }

    async handleResults(results) {
        // Cleaning the project results is required until UE-4705 is resolved
        const cleanResults = await SavedViewsModel.removeProjectsWithoutProjectAssets(results);
        cleanResults.forEach((project) => this.childProjects.push(project));
        m.redraw();
    }

    onComplete() {
        this.projectsLoaded = true;
        m.redraw();
    }

    init() {
        if (this.isFetching || this.isInitted) {
            return;
        }
        return this.fetchAndLoadAllProjectViews().then(() => {
            this.isInitted = true;
            m.redraw();
        });
    }

    /*
    * Called once all required inits have completed to release any async functions waiting.
    */
    markAsInitted() {
        this.isInitted = true;
        this.afterInitPromises = [];
    }

    getProjectViewsByProjectId(projectId) {
        if (!this.projectViewsByProjectId[projectId]) {
            return this.fetchViewFromProjectId(projectId);
        }
        return this.projectViewsByProjectId[projectId];
    }

    fetchViewFromProjectId(projectId) {
        this.projectViewsByProjectId = {};
        return api.rpc.request([['listProjectViews', {
            projectId: projectId,
            creatorId: appModel.user.userId,
            platform: 'web',
            type: 'saved',
            isVisible: true
        }]]).then(results => {
            this.projectViewsByProjectId[projectId] = results;
            m.redraw();
        });
    }

    get activeSavedViewId() {
        return tableModel.projectView.savedProjectViewId;
    }

    getProjectView(projectViewId) {
        if (!this.activeSavedViewId && tableModel.lastViewedProjectViewId === projectViewId) {
            return tableModel.projectView;
        }
        return this._projectViews[projectViewId]; 
    }

    get projectViewsCount() {
        return Object.keys(this._projectViews).length;
    }

    get additionalProjectsCount() {
        return Object.keys(this.additionalProjects).length;
    }

    updateOrder(order) {
        this.sortOrder = order;
        switch (order) {
        case NameASC:
            this.applyOrderSort('name asc');
            return;
        case NameDESC:
            this.applyOrderSort('name desc');
            return;
        case DateASC:
            this.applyOrderSort('createdDateTime asc');
            return;
        case DateDESC:
            this.applyOrderSort('createdDateTime desc');
            return;
        default:
            this.applyOrderSort('createdDateTime asc');
            return;
        }
    }

    disengageActiveView() {
        sideNavModel.close();
        popupModel.close();
        modalModel.close();
        tableModel.projectView.autosave();
    }

    applyOrderSort(orderSelection = 'createdDateTime desc') {
        this.sortArg = orderSelection;
        this.loadingSort = true;
        m.redraw();
        this.fetchAndLoadAllProjectViews(true);
    }

    clearProjectViewSearch() {
        this.isSearchingViews = false;
        this.viewSearchInput.value = '';
        this.filterParam = '';
        m.redraw();
        this.fetchAndLoadAllProjectViews(true);
    }

    searchProjectViews(query) {
        if  (!query) {
            if (this.isSearchingViews) {
                return this.clearProjectViewSearch();
            }
            // Empty query provided but we didnt have an existing query, so do nothing.
            return;
        }
        this.viewSearchInput.value = query;
        this.isSearchingViews = true;
        this.loadedAllViews = false;
        this.filterParam = query;
        m.redraw();
        this.fetchAndLoadAllProjectViews(true);
    }

    clearChildProjectSearch() {
        this.isSearchingProjects = false;
        if (this.projectSearchInput) {
            this.projectSearchInput.value = '';
        }
        this.projectSearchParam = '';
        this.childProjects = [];
        if (this.paginator) {    
            this.paginator.apiRequest = LIST_API_REQUEST;
            this.paginator.args = {accountId: store.account.accountId};
            this.paginator.restart(true);
        }
        m.redraw();
    }

    searchChildProjects(query) {
        if  (!query) {
            if (this.isSearchingProjects) {
                return this.clearChildProjectSearch();
            }
            // Empty query provided but we didnt have an existing query, so do nothing.
            return;
        }
        this.childProjects = [];
        this.projectSearchInput.value = query;
        this.isSearchingProjects = true;
        this.projectSearchParam = query;
        m.redraw();
        // Restart paginator using a simple search with our passed args.
        this.paginator.apiRequest = (args) => api.simpleSearch(args);
        this.paginator.args = {
            type: 'project',
            searchType: 'search',
            filter: {accountId: store.account.accountId},
            query
        };
        this.paginator.restart();
    }

    fetchById(projectViewId) {
        // "list" is more forgiving than "get". If the id doesnt exist, it'll return 0 results. With get, it'll return an error
        return api.rpc.request([['listProjectViews', {
            projectViewId,
            limit: 1
        }]]).then((results) => results.length ? results[0] : undefined);
    }

    // ------- Side-Nav Logic // Listing Saved Views & Projects ------- //

    /**
     * Clicking on a projectView list item should result in this function:
     * @param {*} projectViewId 
     */
    applySavedViewId(projectViewId) {
        if (siteModel.isInfoPanelOpen()) {
            formModel.close();
        }
        if (tableModel.isCollapsed) {
            tableModel.sideBarToggle();
        }
        const projView = this._projectViews[projectViewId];
        sideNavModel.close();
        popupModel.close();
        modalModel.close();
        tableModel.applySavedView(projView);
        message.show(`Saved View (${projView.name}) has been applied.`, 'success', false, undefined, 1000);
    }

    /**
     * In ... menu of projectView list item
     * @param {*} projectViewId 
     */
    openSavedViewDetails(projectViewId) {
        modalModel.open({
            view: SavedViewsCard,
            attrs: {projectViewId}
        });
    }

    /**
     * In ... menu of projectView list item
     * @param {*} projectViewId 
     */
    deleteSavedView(projectViewId = this.activeSavedViewId) {
        const projectView = this.getProjectView(projectViewId);
        this.showDeleteOptions = !appModel.project.isMetaProject && projectView.projectIds.items.length > 1;
        this.selectSavingOption(this.showDeleteOptions ? JUST_THIS_PROJECT : SAVE_TO_ALL);  // Set default selected option to deleting from just from this project
        popupModel.close();
        dialogModel.open({
            headline: `Delete Saved View: ${projectView.name}?`,
            text: '',
            innerView: SavedViewsDeleteOptions,
            cssClass: 'saved-views-delete-options-dialog',
            yesText: 'Delete',
            yesClass: 'btn btn-pill btn-red',
            noText: 'Cancel',
            noClass: 'btn btn-pill btn-secondary',
            onYes: () => this.handleDelete(projectViewId),
            onNo: () => this.selectSavingOption(SAVE_TO_ALL)
        });
    }

    handleDelete(projectViewId) {
        const projectView = this.getProjectView(projectViewId);
        const projectIds = projectView.projectIds.items;
        const isActive = projectView.projectViewId === this.activeSavedViewId;

        sideNavModel.close();

        if (appModel.project.isMetaProject || this.selectedSavingOption === SAVE_TO_ALL || projectIds.length <= JUST_THIS_PROJECT) {
            // Delete from all:
            return api.rpc.request([['deleteProjectView', {
                projectViewId
            }]]).then(() => {
                delete this._projectViews[projectViewId];
                delete this.projectsByProjectViewId[projectViewId];
                this.sortedIds = this.sortedIds.filter(id => id !== projectViewId);
                if (isActive) {
                    tableModel.projectView.autosave();
                }
                m.redraw();
                message.show(`Saved View (${projectView.name}) has been deleted from all ${appModel.toolbox.siteTermPlural} of this Type: ${appModel.project.projectType}.`, 'success');
            });
        } 
        this.selectedSavingOption = SAVE_TO_ALL; // reset to default

        // Delete from just this:
        const newProjectIds = projectIds.filter(id => id !== appModel.project.projectId);
        return api.rpc.request([['modifyProjectView', {
            projectViewId,
            projectIds: newProjectIds
        }]]).then(() => {
            delete this._projectViews[projectViewId];
            delete this.projectsByProjectViewId[projectViewId];
            this.sortedIds = this.sortedIds.filter(id => id !== projectViewId);

            if (isActive) {
                tableModel.projectView.autosave();
            }
            m.redraw();
            message.show(`Saved View (${projectView.name}) has been deleted for all this ${appModel.toolbox.siteTermSingular}.`, 'success');
        });
    }

    configToText(projView) {
        if (!projView || !projView.projectViewId) {
            return null;
        }
        if (!this.filterConfigsToText[projView.projectViewId]) {
            this.filterConfigsToText[projView.projectViewId] = filterFormatter.format(projView);
        }
        return this.filterConfigsToText[projView.projectViewId];
    }

    onSavedViewChange(projectViewId) {
        // Will prompt reconfigurations on next render
        if (projectViewId) {
            delete this.filterConfigsToText[projectViewId];
        } else {
            this.filterConfigsToText = {};
        }
        m.redraw();
    }

    selectProject(projectId, projectViewId = this.activeSavedViewId) {
        sideNavModel.close();
        popupModel.close();
        this.close();
        // TODO For better performance, we should change "true" to "false" to prevent re-loading toolbox since we know it is the same toolbox. Will require multiple updates and testing to decouple initting of required project-level data from initting of toolbox.
        appModel.changeProject(projectId, {
            routerParams: {projectViewId}, 
            initToolbox: true
        });
    }
    
    // ------- Table Logic // Current Saved View & Project ------- //

    selectSavingOption(radioOpt) {
        this.selectedSavingOption = radioOpt;
    }

    saveNew(formFields) {
        this.isSaving = true;
        const name = formFields.name.value.trim();
        m.redraw();
        if (!name) {
            this.isSaving = false;
            formFields.name.cssClass = 'error';
            return m.redraw();
        }
        tableModel.projectView.getInUseLayers();
        const attributes = {...tableModel.projectView.getAttributes()};
        return this.createSavedView(name, attributes).then((result) => {
            router.url.mergeReplace({'projectViewId': result.projectViewId});
            
            const newProjectView = new ProjectViewModel(result);
            this._projectViews[result.projectViewId] = newProjectView;
            this.sortedIds.push(newProjectView.projectViewId);

            tableModel.applySavedView(newProjectView);
            this.isSaving = false;
            tableModel.highlightSidebarButton();
            modalModel.close();
            message.show(`New saved view: ${name} created for this ${appModel.toolbox.siteTermSingular}.`, 'success');
        });
    }

    fetchAndLoadAllProjectViews(justSort = false) {
        this.isFetching = true;
        const filters = this.filterParam ? [{nameIlike: `%${this.filterParam}%`}] : undefined;
        return api.rpc.request([['listProjectViews', {
            projectId: router.params.projectId,
            creatorId: appModel.user.userId,
            platform: 'web',
            type: 'saved',
            isVisible: true,
            order: this.sortArg,
            filters
        }]]).then(results => {
            this.sortedIds = [];
            results.forEach(projView => {
                this.sortedIds.push(projView.projectViewId);
                if (!justSort) {
                    this._projectViews[projView.projectViewId] = new ProjectViewModel(projView);
                }
            });
            this.isFetching = false;
            this.loadedAllViews = true;
            this.loadingSort = false;
            m.redraw();
        });
    }

    async createSavedView(name, attributes, type = 'saved') {
        const projectIds = !appModel.project.isMetaProject && this.selectedSavingOption === SAVE_TO_ALL ? undefined : [router.params.projectId];
        return api.rpc.request([['createProjectView', {
            toolboxId: store.toolboxId,
            type,
            platform: 'web',
            name,
            attributes,
            projectIds
        }]]);
    }

    /* ---- Retrieving project lists for a particular projectViewId ---- */

    getProjectIdsByProjectViewId(projectViewId) {
        if (!this.projectsByProjectViewId[projectViewId]) {
            this.fetchProjectsByProjectViewId(projectViewId);
        }
        return this.projectsByProjectViewId[projectViewId];
    }

    fetchProjectsByProjectViewId(projectViewId) {
        const projectView = this.getProjectView(projectViewId);
        if (projectView) {
            const projectIds = projectView.projectIds.items;
            return api.rpc.request([['listProjects', {
                accountId: store.account.accountId, projectIdIn: projectIds}]]).then((result) => {
                if (Object.values(this.projectsByProjectViewId).length >= MAX_PROJECTS_LISTS_TO_STORE) {
                    this.projectsByProjectViewId = {};
                }
                this.projectsByProjectViewId[projectViewId] = result;
                m.redraw();
            });
        }   
        return Promise.resolve();
    }

    /* ---- Helpers ---- */

    close() {
        modalModel.close();
    }

    get savingOptions() {
        if (!this._savingOptions.length) {
            this._savingOptions = [
                `All ${capitalize(appModel.toolbox.siteTermPlural)} of this Type: ${appModel.project.projectType}`,
                `This ${capitalize(appModel.toolbox.siteTermSingular)} only: ${siteModel.name}`
            ];
        }
        return this._savingOptions;
    }

    /* --- Links tab ---- */


    async getLinksTabSavedView() {
        if (!this._linksTabSavedView) {
            let [savedView] = await api.rpc.request([['listProjectViews', {
                projectId: appModel.project.projectId,
                creatorId: appModel.user.userId,
                platform: 'web',
                type: 'links-tab',
                isVisible: true
            }]]);
            if (!savedView) {
                const newSavedView = new ProjectViewModel();
                newSavedView.tableState.tableMode = 'list-left';
                savedView = await api.rpc.request([['createProjectView', {
                    toolboxId: store.toolboxId,
                    projectIds: [router.params.projectId],
                    platform: 'web',
                    type: 'links-tab',
                    name: 'links-tab',
                    attributes: newSavedView.getAttributes()
                }]]);
            }
            this._linksTabSavedView = savedView;
        } 
        return this._linksTabSavedView;

    }
    

}

export default new SavedViewsModel();
