import Tool from 'models/toolbox/tool/tool-model';

class ToolGroupModel {
    constructor(_apiToolGroup, toolZOrder) {
        Object.assign(this, _apiToolGroup);
        this._sortAndInitTools(_apiToolGroup, toolZOrder);
    }

    _sortAndInitTools(_apiToolGroup, toolZOrder) {
        let tools = [];
        const toolOrder = _apiToolGroup.attributes.toolOrder || [];
        _apiToolGroup.tools.forEach(tool => {
            tool.zOrder = toolZOrder.indexOf(tool.name);
            tool.uiOrder = toolOrder.indexOf(tool.name);
            tools.push(new Tool(tool, this));
        });

        tools = tools.filter(tool => !tool.isHidden);
        tools.sort((a, b) => a.uiOrder - b.uiOrder);
        this.tools = [...tools];
    }

    toggleExpandCollapse(e) {
        e.stopPropagation();
        this.isCollapsed = !this.isCollapsed;
    }
    
    get hasSavedStylesEnabled() {
        return this.attributes && this.attributes.featureFlags && this.attributes.featureFlags.toolStyles;
    }

}

export default ToolGroupModel;
