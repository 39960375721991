import api from 'legacy/util/api';
import router from 'uav-router';
import store from 'util/data/store';
import appModel from 'models/app-model';
import message from 'views/toast-message/toast-message';
import siteModel from 'models/site-model';
import layerModel from 'models/layer-model';
import stakeableModel from 'models/stakeable-model';

class SurveyModel {

    constructor() {
        this.isLoaded = false;
    }

    init() {
        this.surveyId = router.params.survey;
        return stakeableModel.init({
            storeKey: 'surveys',
            recordType: 'survey',
            recordId: this.surveyId,
            withElevation: true
        }).then(() => {
            if (!router.params || !router.params.survey) {
                return stakeableModel.exit();
            }
            stakeableModel.recordTitle = store.surveys[this.surveyId].title;
            stakeableModel.state.isLoading = false;
            stakeableModel.savePins = this.savePins;
            // Add pins once our survey is fetched and bounds are set accordingly:
            stakeableModel.origin.mapLoaded().then(() => {
                stakeableModel.origin.showSurveyForStaking().then(() => {
                    stakeableModel.preloadSavedStakes();
                });
            });
            // Turn off survey on left side map.
            layerModel.setSurvey();
            appModel.setPageTitle(stakeableModel.recordTitle);
            this.isLoaded = true;
            m.redraw();
        });
    }


    /**
     * Tear down mapbox objects and associated resources, store sitePreferences for next screen.
     */
    onRemove() {
        // Ensure survey layer is on upon exit.
        appModel.user.mergePreferences({
            sitePreferences: {
                [router.params.siteId]: {
                    surveyId: this.surveyId
                }
            }
        });
        if (stakeableModel.origin) {
            stakeableModel.origin.remove();
        }
        if (stakeableModel.target) {
            stakeableModel.target.remove();
        }
        appModel.project.isInitted = false;
    }

    /**
     * Passed to stakeable.js so we can handle it according to specific asset type.
     */
    savePins(data) {
        if (!data) {
            // If no data changes to save, just route back:
            stakeableModel.exit();
        } else {
            const surveyId = router.params.survey;
            const surveyRecord = store.surveys[router.params.survey];
            return api.post.surveyStakes(surveyId, data)
                .then(() => {
                    siteModel.processingSurvey = surveyRecord.surveyId;
                    appModel.user.mergePreferences({
                        sitePreferences: {
                            [router.params.siteId]: {
                                surveyId: surveyRecord.surveyId
                            }
                        }
                    });
                    stakeableModel.exit();
                }).catch(() => {
                    message.show('Something went wrong. Please try again or contact support.', 'error');
                });
        }
    }

}

export default new SurveyModel();
