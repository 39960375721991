import api from 'legacy/util/api/api';
import store from 'util/data/store';
import router from 'uav-router';
import findAndRemove from 'util/data/find-and-remove';
import popup from 'util/popup';
import PlacePopup from 'views/place-popup/place-popup';
import centroid from '@turf/centroid';
import constants from 'util/data/constants';
import tableModel from 'models/table/table-model';
import layerModel from 'models/layer-model';
import deepCloneObject from 'util/data/deep-clone-object';
import siteModel from 'models/site-model';
import featureListManager from 'managers/feature-list-manager';
import AssetModel from 'models/asset/asset-model';

class PlaceModel {

    constructor() {
        this.reset();
    }

    addPlaceControls() {
        const assetForms = Object.values(store.assetForms);
        const placeControlTypeId = constants.controlTypeNameToId.place;
        const nameMap = {};
        this.placeControls = [];
        for (let i = 0; i < assetForms.length; i++) {
            const assetForm = assetForms[i];
            for (let c = 0; c < assetForm.controls.length; c++) {
                const control = assetForm.controls[c];
                if (nameMap[control.fieldName]) {
                    continue;
                } else if (control.controlTypeId === placeControlTypeId) {
                    this.placeControls.push(control.fieldName);
                }
            }
        }
    }

    reset() {
        this.placesWithFeatures = {};
        this.isLoading = false;
        this.placeControls = [];
    }

    panToPlaces(placeIds, showPlacePopover = true) {
        const featureGeojson = [];
        for (let i = 0; i < placeIds.length; i++) {
            const placeId = placeIds[i];
            const place = store.places[placeId];
            if (place) {
                layerModel.turnOnPlace(place);
                const geometry = deepCloneObject(place.boundary);
                geometry.coordinates[0] = place.boundary.coordinates[0];
                featureGeojson.push({
                    geometry,
                    type: 'Feature'
                });
            }
        }

        if (featureGeojson.length) {
            siteModel.map.panToFeatures(featureGeojson);
        }

        if (showPlacePopover) {
            this.showPlacePopups(placeIds);
        }
    }

    panToPlace(place, showPlacePopover) {
        this.panToPlaces([place.placeId], showPlacePopover);
    }

    /**
     * showPlacePopups: Builds the requests to check how many assets are in each place.
     *                  The requests are built on existing table filters so if there is only 1
     *                  asset in the table the counts will reflect that.
     */
    showPlacePopups(placeIds, latLng) {
        const coords = [];
        const rpcs = [];
        for (let i = 0; i < placeIds.length; i++) {
            const placeId = placeIds[i];
            const place = store.places[placeId];
            if (place) {
                layerModel.turnOnPlace(place);
                coords.push(i === 0 && latLng
                    ? latLng
                    : centroid(place.boundary).geometry.coordinates);

                const tableArgs = tableModel.projectView.getArgs();
                const filters = tableArgs.filters;
                //changing logic here to filter results by the geo bounds of the place the user is viewing the popup for
                const placeGeo = featureListManager.getPlaceFeature(place);
                tableArgs.geometry = {
                    intersects: placeGeo.geometry
                };
                rpcs.push(['countContent',
                    Object.assign({}, tableArgs, {
                        filters: filters,
                        limit: undefined,
                        offset: undefined,
                        order: undefined,
                        include: undefined
                    })]
                );
            }
        }

        return api.rpc.requests(rpcs).then(counts => {
            const popupOpts = [];
            for ( let i = 0; i < counts.length; i++) {
                const {count} = counts[i];
                const place = store.places[placeIds[i]];
                const coordinates = coords[i];
                popupOpts.push({
                    content: <PlacePopup
                        count={count}
                        name={place.name}
                        showFiltered={tableModel.assetCount !== tableModel.totalAssetCount}
                    />,
                    lngLat: coordinates
                });
            }
            // These are stateless popups so we're using the simpler mapbox popup rather than our mithril mapbox popup to utilize the 'addAll' functionality. 
            popup.addAll(popupOpts);
        });
    }

    get places() {
        return this.placesWithFeatures ? Object.values(this.placesWithFeatures) : [];
    }

    get allPlaces() {
        return Object.values(store.places);
    }

    getFeature(featureId) {
        let feature = featureListManager.getById(featureId);
        if (feature) {
            return Promise.resolve(feature);
        }
        return api.rpc.request([['listFeatures', {
            offset: 0,
            limit: 1,
            projectId: router.params.projectId,
            featureId: featureId
        }]]).then(features => featureListManager.addFeatures(features))
            .then(() => {
                feature = featureListManager.getById(featureId);
                return feature;
            });
    }

    addPlaceToFormControl(place, assetId, controlLabel) {
        const asset = store.assets[assetId];
        let placesObj = asset.properties[controlLabel];
        if (!placesObj) {
            placesObj = store.assets[assetId].properties[controlLabel] = {
                placeIds: []
            };
        }
        if (placesObj.placeIds === undefined) {
            placesObj.placeIds = [];
        }
        if (placesObj.placeIds.findIndex((id) => id === place.placeId ) === -1) {
            placesObj.placeIds.push(place.placeId);
        }

        asset.placeIds = asset.placeIds || [];
        // This may already exist from another control.
        if (asset.placeIds.findIndex((id) => id === place.placeId ) === -1) {
            asset.placeIds.push(place.placeId);
        }
        store.assets[assetId] = new AssetModel(asset);
    }

    removePlaceToFormControl(place, assetId, controlLabel) {
        const asset = store.assets[assetId];
        let placesObj = asset.properties[controlLabel];
        if (!placesObj) {
            placesObj = store.assets[assetId].properties[controlLabel] = {
                placeIds: []
            };
        }
        placesObj.placeIds = findAndRemove(placesObj.placeIds, (id) => id === place.placeId);
        if (placesObj.placeIds.length === 0) {
            delete asset.properties[controlLabel];
        }
        store.assets[assetId] = new AssetModel(asset);
    }
}

export default new PlaceModel();
