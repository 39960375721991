const PlacePopup = {
    view: ({attrs: {count, name, showFiltered}}) => {
        return <div className="place-popup">
            <span className="place-title">
                <div className="icon-place-wrap">
                    <i className="icon-place"/>
                </div>
                <span>{name}</span>
            </span>
            <span className="asset-count">({count})</span>
            {showFiltered && <div className="icon-configure-wrap">
                <i className="icon-configure"/>
            </div>}
        </div>;
    }
};

export default PlacePopup;
