import isMetric from 'util/numbers/is-metric';
import store from 'util/data/store';
import constants from 'util/data/constants';
import formatDate from 'legacy/util/date/format-date';
import round from 'util/numbers/round';
import measure from 'legacy/util/numbers/measure';
import peopleModel from 'models/people/people-model';
import popupModel from 'models/popover-model';
import formModel from 'models/form-model';
import {TinyCard} from 'views/asset-card/asset-card';
import activeCellModel from 'models/table/active-cell-model';
import tableModel from 'models/table/table-model';
import {sanitizeUrl} from 'util/data/sanitize';
import MediaName from 'views/media-name';
import mediaViewerModel from 'models/media-viewer-model';

export function cellValueToText(value, controlTypeId) {
    const metric = isMetric();
    switch (controlTypeId) {
    case constants.controlTypeNameToId.date:
        try {
            value = Number(value);
            return `${formatDate.ddmmyyyy(value)}`;
        } catch {
            return value;
        }
    case constants.controlTypeNameToId.length:
        return `${round(metric ? value : measure.metersToFeet(value))} ${metric ? 'meters' : 'feet'}`;
    case constants.controlTypeNameToId.area:
        return `${round(metric ? value : measure.squareMetersToSquareFeet(value))} ${metric ? 'meters²' : 'feet²'}`;
    case constants.controlTypeNameToId.volume:
        return `${round(metric ? value : measure.cubicMetersToCubicYards(value))} ${metric ? 'meters³' : 'yards³'}`;
    case constants.controlTypeNameToId.user:
        return value ? peopleModel.displayUserControlOption(value) : '';
    case constants.controlTypeNameToId.toggle:
        return value;
    case constants.controlTypeNameToId.file:
        return Array.isArray(value) ? value.join(', ') : value;
    case constants.controlTypeNameToId.multitext:
    case constants.controlTypeNameToId.multiselect:
        // workaround for bad data import WEB-2156
        if (value && value.join) {
            // first clear empty values:
            const values = value.filter(_value => !!_value);
            return values.join(', ');
        }
        return value;
    case constants.controlTypeNameToId.URL:
        if (value && value.URL && value.URL.length) {
            const sanitizedUrl = sanitizeUrl(value.URL);
            // eslint-disable-next-line mithril/jsx-no-target-blank
            return <a class="clickable-link" onclick={(e) => e.stopPropagation()} href={sanitizedUrl} target="_blank" title={`${value.URL} (Opens in a new tab)`} rel="noreferrer">{value.display || value.URL}</a>;
        }
        return '';
    case constants.controlTypeNameToId.place:
        const {placeIds} = value;
        if (placeIds && placeIds.length > 0) {
            const jsx = [];
            for (let i = 0; i < placeIds.length; i++) {
                if (i === 3) {
                    break;
                }
                jsx.push(<span onclick={(e) => {
                    // Pass data up to the cell click handler.
                    e.clickData = placeIds[i];
                }}>{store.places[placeIds[i]].name} </span>);
            }
            return jsx;
        }
        return '';
    default:
        return value;
    }
}

const handleAssetMediaClick = (e, mediaId, mediaIdsToOpen) => {
    if (e) {
        e.stopPropagation();
    }
    popupModel.close();
    mediaViewerModel.open(mediaIdsToOpen, mediaIdsToOpen.indexOf(mediaId));
};

const handleAssetLinkClick = (e, assetId) => {
    e.stopPropagation();
    popupModel.close();
    formModel.viewAsset(assetId);
};

const ExpandLinksPopup = {
    onremove: () => {
        activeCellModel.expandedCell = {};
    },
    view: ({attrs: {assetIds, viewKey}}) => {
        return <div class="links-list">
            {assetIds && assetIds.length && assetIds.map(assetId => <TinyCard key={viewKey} assetId={assetId} fetchHandler={tableModel.addToQueue} 
                onClick={(e) => handleAssetLinkClick(e, assetId)} hideOptions={true}/> 
            )}
        </div>;
    }
};

const ExpandMediaPopup = {
    onremove: () => {
        activeCellModel.expandedCell = {};
    },
    view: ({attrs: {firstMediaId, mediaIds, viewKey}}) => {
        return <div class="media-list">
            {mediaIds && mediaIds.length && mediaIds.map(mediaId => <MediaName key={viewKey} mediaId={mediaId} onClick={(e) => handleAssetMediaClick(e, mediaId, [firstMediaId, ...mediaIds])}/>)}
        </div>;
    }
};

export function linkedAssetsToCellText(assetId, control) {
    const asset = store.assets[assetId];
    const linkedIds = asset.properties[control.fieldName] || [];
    if (linkedIds.length) {
        return <><TinyCard assetId={linkedIds[0]} onClick={(e) => handleAssetLinkClick(e, linkedIds[0])} hideOptions={true}/> 
            {linkedIds.length > 1 ? <span onclick={function (e) {
                const midPointY = Math.floor(document.querySelector('#app').clientHeight / 2);
                const boundingBox = this.getBoundingClientRect();
                activeCellModel.expandedCell = {assetId, controlName: control.fieldName};
                e.stopPropagation();
                popupModel.open(this, {view: ExpandLinksPopup, args: {assetIds: linkedIds.slice(1), viewKey: `${control.fieldName}${assetId}`},
                    alignBottom: midPointY >= boundingBox.top,
                    yPadding: 14,
                    wrapClass: 'expand-named-links'});
            }} class={`more-links-count${activeCellModel.isExpanded(assetId, control.fieldName) ? ' expanded' : ''}`}>+ {linkedIds.length - 1}</span> : ''}
        </>;
    }
    return null;
}

export function mediaIdsToCellText(assetId, control) {
    const asset = store.assets[assetId];
    const mediaIds = asset.properties[control.fieldName] || [];
    if (mediaIds.length) {
        return <><MediaName mediaId={mediaIds[0]} onClick={(e) => handleAssetMediaClick(e, mediaIds[0], mediaIds)} />
            {mediaIds.length > 1 ? <span onclick={function (e) {
                const midPointY = Math.floor(document.querySelector('#app').clientHeight / 2);
                const boundingBox = this.getBoundingClientRect();
                activeCellModel.expandedCell = {assetId, controlName: control.fieldName};
                e.stopPropagation();
                popupModel.open(this, {view: ExpandMediaPopup, args: {firstMediaId: mediaIds[0], mediaIds: mediaIds.slice(1), viewKey: `${control.fieldName}${assetId}`},
                    alignBottom: midPointY >= boundingBox.top,
                    yPadding: 14,
                    wrapClass: 'expand-media'});
            }} class={`more-media-count${activeCellModel.isExpanded(assetId, control.fieldName) ? ' expanded' : ''}`}>+ {mediaIds.length - 1}</span> : ''} 
        </>;
    }
    return null;
}

// Ensure "false" values display as "off" rather than empty for toggles. Undefined properties display as empty.
export function toggleValueToCellText(assetId, control) {
    const asset = store.assets[assetId];
    const value = asset.properties[control.fieldName];
    return asset.properties.hasOwnProperty(control.fieldName) ? value === false ? 'Off' : 'On' : undefined;
}
