export const STEPS = {
    START: 0,
    SELECT_PAGE: 1,
    SET_TILESET_DEFAULTS: 2,
    SYNC_CONTENT: 3,
    POSITION_LAYER: 4,
    DONE: 5,
    AUTOMATED_PROCESS: 98,
    AUTOMATED_DONE: 99
};

export const SCALE_FACTORS = {
    'inin': 1,
    'ftft': 1,
    'ydsyds': 1,
    'cmcm': 1,
    'mm': 1,
    'kmkm': 1,
    'mimi': 1,
    'inft': 12,
    'ftin': 1 / 12,
    'ftyds': 3,
    'ydsft': 1 / 3,
    'inyds': 36,
    'ydsin': 1 / 36,
    'cmm': 100,
    'mcm': 1 / 100,
    'cmkm': 100000,
    'kmcm': 1 / 100000,
    'mkm': 1000,
    'kmm': 1 / 1000,
    'cmin': 2.54,
    'incm': 1 / 2.54,
    'cmft': 30.48,
    'ftcm': 1 / 30.48,
    'cmyds': 91.44,
    'ydscm': 1 / 91.44,
    'min': 1 / 39.3701,
    'inm': 39.3701,
    'mft': 1 / 3.28084,
    'ftm': 3.28084,
    'myds': 1 / 1.09361,
    'ydsm': 1.09361,
    'kmin': 1 / 39370.1,
    'inkm': 39370.1,
    'kmft': 1 / 3280.84,
    'ftkm': 3280.84,
    'kmyds': 1 / 1093.61,
    'ydskm': 1093.61,
    'mim': 1 / 1609.34
};
