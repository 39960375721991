import AssetPropertyVisibility from 'views/table/filter-menu/asset-property-visibity';
import tableModel from 'models/table/table-model';
import tablePopupModel from 'models/popover-model';
import appModel from 'models/app-model';
import SharedColumnMessage from 'views/table/filter-menu/shared-column-message';

let projectView;

const ConfigMenu = {
    oninit(vnode) {
        if (!vnode) {
            return;
        }
        const {state} = vnode;
        state.openTools = {};
        state.sharedColumns = [];
        state.openSharedColumns = {};
        state.toolGroups = [];
        projectView = appModel.projectView;
        Object.values(appModel.toolbox.toolGroups).forEach((toolGroup) => {
            const groupId = toolGroup.toolGroupId;
            for (const tool of toolGroup.tools) {
                const {toolId} = tool;
                const controlTypes = tableModel.getControlTypes(toolId);
                if (controlTypes.length) {
                    state.openTools[toolId] = false;
                    if (state.toolGroups[groupId]) {
                        state.toolGroups[groupId].push(toolId);
                    } else {
                        state.toolGroups[groupId] = [toolId];
                    }
                }
            }
        });
        tableModel.getSharedColumnNames().forEach(sharedColumnName => {
            state.sharedColumns.push(sharedColumnName);
            state.openSharedColumns[sharedColumnName] = false;
        });
    },

    header: (onClick, isOpen, controlTypes, assetType) => {
        let isActive = false;
        const headers = tableModel.visibleHeaders[assetType.assetTypeId];
        for (const key in headers) {
            if (headers[key]) {
                isActive = true;
                break;
            }
        }
        const cssClass = isActive ? 'asset-header' : 'asset-header all-off';
        return <div className={cssClass} onclick={onClick}>
            <span className="asset-type-title">{assetType.name}
                <div className="property-count">
                    {`(${controlTypes.length} ${controlTypes.length === 1 ? 'property' : 'properties'})`}
                </div>
            </span>
            <div class="config-arrow-wrap"><i className={`icon-chevron-${isOpen ? 'up' : 'down'}`} /></div>
        </div>;
    },

    commonPropToggle(name) {
        return <div className="filter-option-row">
            <div className={`filter-option${projectView.common.isFiltered(name) ? ' is-filtered clickable' : ''}`} onclick={() => tableModel.toggleCommonHeaders(name)}>
                <i className={`toggle-switch ${tableModel.visibleHeaders[name] ? 'toggle-is-on' : 'toggle-is-off'}`} />
                <span className="filter-option-label">{projectView.common.displayName(name)} </span>
            </div>
        </div>;
    },

    sharedPropToggle(sharedColumnName, isOpen, onClick) {
        return <div className="filter-option-row shared-filter">
            <div className={`filter-option${tableModel.getSharedColumn(sharedColumnName).isFiltered ? ' is-filtered clickable' : ''}`} onclick={() => tableModel.toggleCommonHeaders(sharedColumnName)}>
                <i className={`toggle-switch ${tableModel.visibleHeaders[sharedColumnName] ? 'toggle-is-on' : 'toggle-is-off'}`} />
                <span className="filter-option-label">{tableModel.getSharedColumn(sharedColumnName).name}</span>
            </div>
            <div class="config-arrow-wrap"><i className={`icon-chevron-${isOpen ? 'up' : 'down'}`} onclick={onClick} /></div>
            {isOpen && <SharedColumnMessage sharedColumn={tableModel.getSharedColumn(sharedColumnName)} hideToggle={true} />}
        </div>;
    },

    view: ({state}) => {
        const toolGroups = state.toolGroups;
        const {isAllVisible, isAllNotVisible} = tableModel.isEntireTableVisible();
        const selectAllCssClass = `select-all${isAllVisible ? ' disabled' : ''}`;
        const deselectAllCssClass = `select-all${isAllNotVisible ? ' disabled' : ''}`;
        return <div className="config-menu">
            <div class="filter-menu-inner">
                <div className="config-menu-header" onclick={() => tablePopupModel.close()}>
                    <div class="config-menu-tab">
                        <span class="config-menu-tab-btn"><i className='icon-properties-table' /></span>
                        <span className="config-menu-tab-label">Properties</span>
                    </div>
                </div>
                <div class="config-menu-contents">
                    <div className="filter-contents">
                        <div className="type-columns-tip table-tip">Turn Property columns on/off.</div>
                        <div className="type-columns-tip list-tip">Turn Properties on/off in cards.</div>
                        <div className="bulk-selection-links">
                            <span className={selectAllCssClass} onclick={() => tableModel.switchEntireTableVisibility(true)}>All On</span>&nbsp;|&nbsp;
                            <span className={deselectAllCssClass} onclick={() => tableModel.switchEntireTableVisibility(false)}>
                                All Off</span>
                        </div>
                        <div class="group-header">
                            <span class="group-title">Common Properties
                                <div class="property-count">({projectView.common.getCommonPropertyCount()} properties)</div>
                            </span>
                        </div>
                        <div className="type-columns-inner">
                            {ConfigMenu.commonPropToggle('unearthId')}
                            {appModel.toolbox.hasMultipleGroups && ConfigMenu.commonPropToggle('category')}
                            {ConfigMenu.commonPropToggle('type')}
                            {ConfigMenu.commonPropToggle('addedDateTime')}
                            {ConfigMenu.commonPropToggle('lastUpdated')}
                            {ConfigMenu.commonPropToggle('addedBy')}
                            {ConfigMenu.commonPropToggle('lastUpdatedBy')}
                            {/* TODO Uncomment after UE-2025 {ConfigMenu.commonPropToggle('links')} */}
                            {state.sharedColumns.map(sharedColumnName =>
                                ConfigMenu.sharedPropToggle(sharedColumnName, state.openSharedColumns[sharedColumnName], () => {
                                    state.openSharedColumns[sharedColumnName] = !state.openSharedColumns[sharedColumnName];
                                })
                            )}
                        </div>
                    </div>
                    <div class="type-specific-group">
                        <div class="group-header"><span class="group-title"> Type-specific Properties</span></div>
                        {Object.keys(toolGroups).map(groupId => <div>
                            <div class="category-section-title"><span>{appModel.toolbox.toolGroups[groupId].name}</span></div>
                            {toolGroups[groupId].map((toolId) => {
                                const controlTypes = tableModel.getControlTypes(toolId);
                                if (controlTypes.length === 0) {
                                    return <div />;
                                }
                                return !state.openTools[toolId] ? ConfigMenu.header(() => {
                                    state.openTools[toolId] = true;
                                }, false, controlTypes, appModel.toolbox.tools[toolId].assetForm.assetType) :
                                    <AssetPropertyVisibility altHeaderFn={(...args) => ConfigMenu.header(() => {
                                        state.openTools[toolId] = false;
                                    }, true, ...args)} toolId={toolId} />;
                            })} </div>
                        )}
                    </div>
                </div>
            </div>
        </div>;
    }
};

export default ConfigMenu;
