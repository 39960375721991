import store from 'util/data/store';
import api from 'legacy/util/api';
import featureToControl from 'util/interfaces/feature-to-control';
import router from 'uav-router';
import publish from 'legacy/util/api/publish';
import ToolInterface from 'util/interfaces/tool-interface';
import constants from 'util/data/constants';
import message from 'views/toast-message/toast-message';
import oneUpModel from 'models/one-up-model';
import popup from 'util/popup';
import layerModel from 'models/layer-model';
import mediaListManager from 'managers/media-list-manager';

class SurveyInterface extends ToolInterface {

    constructor(...args) {

        super(...args);

        this.type = 'survey';

    }

    async launch(mediaRecords, andTurnOnLayer) {

        popup.remove();

        if (!mediaRecords) {
            mediaRecords = await oneUpModel.addUploadFlow({
                accept: constants.uploadOpts.survey.accept,
                close: this.onClose.bind(this),
                name: this.tool.name
            });
        }

        const asset = store.assets[this.assetId],
            firstMedia = mediaRecords[0],
            mediaIds = [];

        let surveyId;

        mediaRecords.forEach(media => {

            mediaListManager.addMedia(media);

            mediaIds.push(media.mediaId);

        });

        asset.mediaIds = mediaIds;

        publish.await({
            changeType: 'modified',
            recordType: 'survey',
            test: survey => survey.surveyId === surveyId,
            callback: survey => {

                store.surveys[surveyId] = survey;

                featureToControl.sync('survey', survey, this.assetId, this.featureType);

                if (andTurnOnLayer) {
                    layerModel.setSurvey(survey.surveyId);
                }

                m.redraw();

            }
        });

        return api.rpc.create('Survey', {
            surveyDateTime: new Date(firstMedia.captureDateTime || firstMedia.createdDateTime || new Date()),
            mediaIds,
            projectId: router.params.projectId
        }).then(survey => {

            surveyId = survey.surveyId;

            store.surveys[surveyId] = survey;

            featureToControl.sync('survey', survey, this.assetId, this.featureType);
        
            return [asset];

        }).catch(() => {

            message.show('Failed to upload survey. Please select a minimum of 10 images captured by a drone or 1 GeoTIFF to create a survey.', 'error', undefined, undefined, 10000);

        });

    }

}

export default SurveyInterface;
