import constants from 'util/data/constants';
import round from 'util/numbers/round';

const FIRST_LOCATION = 0;
const FORMAT_MAX = 0.5; 

/**
 * if value is less than [FORMAT_MAX] then format as cm, else M
 * FORMAT_MAX could be set at 50 cm (0.5 meter)
 */
function formatMetric(num) {
    if (num < FORMAT_MAX) {
        return  {value: round(num * 100, 1000), unit: 'cm'};
    } 
    return  {value: round(num, 1000), unit: 'm'};
}


const TrimbleTip = {
    
    oninit: ({state, attrs: {feature}}) => {
        const precisionReading = Object.values(feature.properties[constants.trimbleDataKey])[FIRST_LOCATION].hPrecision;
        const formattedReading = formatMetric(precisionReading);
        state.accuracyReading = formattedReading.value;
        state.accuracyUnit = formattedReading.unit;
    },

    view: ({state, attrs: {feature}}) => {
        return feature && feature.properties ? <div class="gps-tip trimble-tip">
            <img class="trimble" src="/images/trimble.png" /> <div class="trimble-note">Positioned with Trimble</div>
            {state.accuracyReading ? <div class="gps-accuracy">Accuracy: {state.accuracyReading} {state.accuracyUnit}</div> : ''}
        </div> : '';
    }
};

export default TrimbleTip;
