const MIN_YEAR = 1800;
const UPPER_LIMIT_YEAR_INCREMENT = 100;

function getDaysInMonth(date) {

    if (!date) {
        return 31;
    }

    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

}

function constrain(date, min, max) {
    if (date < min) {
        date = new Date(min);
    } else if (date > max) {
        date = new Date(max);
    }
    return date;
}

function isOutOfBounds(date, min, max) {
    return date < min || date > max;
}

class DateModel {

    constructor(date, min, max, onchange) {
        this.date = date;
        this.showConstrainMessage = false;
        if (!min) {
            min = new Date();
            min.setFullYear(MIN_YEAR);
        }
        this.min = min;
        if (!max) {
            max = new Date();
            max.setFullYear(max.getFullYear() + UPPER_LIMIT_YEAR_INCREMENT);
        }
        this.max = max;
        this.onchange = onchange;
    }

    doShowConstrainMessage() {
        this.showConstrainMessage = true;
        m.redraw();
        setTimeout(() => {
            this.showConstrainMessage = false;
            m.redraw();
        }, 4000);
    }

    updateDay(e, skipCallback) {
        if (e.target.value) {
            const selectedDay = Number(e.target.value);
            const newDate = this.date || new Date();
            let daysInMonth = getDaysInMonth(newDate);
            while (selectedDay > daysInMonth) {
                newDate.setMonth(newDate.getMonth() + 1);
                daysInMonth = getDaysInMonth(newDate);
            }
            newDate.setDate(selectedDay);
            const outOfBounds = isOutOfBounds(newDate, this.min, this.max);
            if (outOfBounds) {
                this.date = constrain(newDate, this.min, this.max);
                this.doShowConstrainMessage();
            } else {
                this.date = newDate;
            }
        } else {
            this.date = undefined;
        }
        e.target.blur();
        return skipCallback || this.onchange(this.date);
    }

    updateMonth(e, skipCallback) {
        if (e.target.value) {
            const selectedMonth = Number(e.target.value);
            const newDate = this.date || new Date();
            const daysInMonth = new Date(newDate.getFullYear(), selectedMonth + 1, 0).getDate();
            let selectedDay = newDate.getDate();
            selectedDay = Math.min(selectedDay, daysInMonth);
            newDate.setDate(selectedDay);
            newDate.setMonth(selectedMonth);
            const outOfBounds = isOutOfBounds(newDate, this.min, this.max);
            if (outOfBounds) {
                this.date = constrain(newDate, this.min, this.max);
                this.doShowConstrainMessage();
            } else {
                this.date = newDate;
            }
        } else {
            this.date = undefined;
        }
        e.target.blur();
        return skipCallback || this.onchange(this.date);
    }

    updateYear(e, skipCallback) {
        if (e.target.value) {
            const newDate = this.date || new Date();
            newDate.setFullYear(e.target.value);
            const outOfBounds = isOutOfBounds(newDate, this.min, this.max);
            if (outOfBounds) {
                this.date = constrain(newDate, this.min, this.max);
                this.doShowConstrainMessage();
            } else {
                this.date = newDate;
            }
        } else {
            this.date = undefined;
        }
        e.target.blur();
        return skipCallback || this.onchange(this.date);
    }

}

export default DateModel;
