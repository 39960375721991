import peopleModel from 'models/people/people-model';
import {ContactInfo, LockedField, TextField} from 'views/person/components';
import constants from 'util/data/constants';
import appModel from 'models/app-model';
import {permissionLevel} from 'util/users/permissions';
import formatDate from 'legacy/util/date/format-date';

const SelectRole = {
    oninit({state, attrs: {person, property}}) {
        this.setSelectedRole(person[property], state);
        state.options = appModel.user.rolesUserCanAssign;
    },

    onupdate({state, attrs: {person, property}}) {
        if (!state.role || person[property] !== state.role.key) {
            this.setSelectedRole(person[property], state);
            m.redraw();
        }
    },

    setSelectedRole(role, state) {
        state.role = constants.accountUserRoles[role || 'viewer'];
        state.descriptions = peopleModel.getRoleDescriptions(state.role.key);
    },

    view: ({state, attrs: {person, property, editable, includeDescription}}) => {
        let options = state.options;
        if (!appModel.account.hasSeatsAvailable && (person.isNewUser || permissionLevel(person.role) <= constants.permissionsRanks.viewer)) {
            // If no seats are available:
            // If this is a new user, or if this user exists but is view-only, we can't assign them to a non-view-only seat:
            options = [constants.accountUserRoles.viewer];
        }
        const selectIsDisabled = person.formState.autoSaving[property] || person.savingClass === 'creating';
        return <>
            {editable ?
                <div class="profile-field profile-row">
                    <span class="label">Role<span class='teal'>*</span></span>
                    <div class="select-wrapper">
                        <select disabled={selectIsDisabled} value={state.role.key || ''} oninput={(e) => person.updateRole(e.target.value, property)}>
                            {options.map((role, index) =>
                                <option key={index} value={role.key}>{role.display || ''}</option>)}
                        </select>
                    </div>
                </div>
                : <div class="profile-field profile-row">
                    <span class="label">Role</span>
                    <span class="user-data locked-from-editing">{state.role.display}</span>
                </div>}
            {!!includeDescription && <div class="profile-field profile-row has-text-only">
                <span class="label access-permissions"><i class="icon-permission" />{state.role.display || ''} Access and Permissions</span>
                <ul class="role-descriptions">{state.descriptions.map(description =>
                    <li>{description}</li>)}</ul>
                <span class="profile-form-message">This list details the platform-default Permissions for this Role. If your Account uses custom Type-level Permissions, check the Permissions tab on any instance to view the settings for each Role.</span>
            </div> }
        </>;
    }
};

const AccountInfo = {
    view: ({attrs: {person, includeDescription, isEditable}}) => {
        return <div class="account-info">
            <SelectRole person={person} property={'role'}
                editable={isEditable && person.userCanEdit('role')}
                includeDescription={includeDescription}/></div>;
    }
};

const Profile = {
    oninit({attrs: {person}}) {
        person.setUserLoginData(person.userId);
    },

    view: ({attrs: {person}}) => {
        return <div class={`user-profile person-module ${person.formState.isSavingNew ? 'freeze-editing' : ''}`}>
            <div class="modal-header-row">
                <span class="modal-title">Profile{person.isNewUser ? <span>: New Person</span> : ''} </span>
            </div>
            {person.isSelf || person.isNewUser ? <div class="user-header">
                <TextField person={person} isRequired={true} property={'givenName'} editable={person.userCanEdit()} />
                <TextField person={person} isRequired={true} property={'familyName'} editable={person.userCanEdit()} />
            </div> : 
                <><LockedField person={person} property={'givenName'} editable={false} />
                    <LockedField person={person} property={'familyName'} editable={false} />
                </>
            }
            <div class={`user-body ${person.isSelf || person.isNewUser ? 'editable-profile' : 'viewing-profile'}`}>
                <ContactInfo person={person} isEditable={true} />
            </div>
            <div class={`user-role ${person.isSelf || person.isNewUser ? 'editable-profile' : 'viewing-profile'}`}>
                <AccountInfo person={person} includeDescription={true} isEditable={true} />
            </div>
            {appModel.user.isAccountAdmin && !person.isNewUser &&
            <div className="profile-field profile-row">
                <span className="label">Last Login</span>
                {person.lastLogin ? <span className="user-data locked-from-editing">{formatDate.ddmmyyyy(person.lastLogin)}</span> :
                    <span className="user-data locked-from-editing">N/A</span>}
            </div>
            }
            {person.isSelf && <div className="profile-field profile-row measurement-preference-row">
                <span className="label">Measurement System</span>
                <div class={`measurement-preference ${appModel.user.measurementSystem}${appModel.user.isSavingMeasurementSystem ? ' saving' : ''}`} 
                    onclick={() => appModel.user.toggleMeasurementSystem()}>
                    <span class="measurement-preference-label metric">Metric</span>
                    <span class="measurement-preference-label imperial">Imperial</span>
                </div>
            </div>}
        </div>;
    }
};

export default Profile;
