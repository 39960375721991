import volumeModel from 'models/volume-model';
import SVGChart from 'views/svg-chart';
import DataTable from 'views/volume/data-table';
import formatDate from 'legacy/util/date/format-date';
import Radio from 'views/radio';

const Volume = {
    onremove: () => volumeModel.cleanup(),
    oninit({state, attrs: {feature, hide}}) {
        volumeModel.init(feature, hide);
        state.showProgress = false;
        state.showProductivity = false;
    },
    oncreate({dom, state}) {
        state.chartWidth = dom.offsetWidth - 48;
    },
    view: ({state}) =>
        <div class="volume">
            <div class="tool-section">
                <div class="volume-diff-title-wrap">
                    <div class="volume-diff-title">Volume Detail</div>
                    <i class="icon-close panel-close" onclick={volumeModel.hide}/>
                </div>
                <div class="survey-select-wrap">
                    <div class="left half compare">
                        <label>Compare</label>
                        <div class="select-wrapper">
                            <select onchange={e => volumeModel.setBaselineId(e.target.value)} value={volumeModel.baselineId}>
                                {volumeModel.surveys.map(survey =>
                                    <option key={survey.surveyId} value={survey.surveyId}>{survey.title || formatDate.formatDay(survey.surveyDateTime, true)}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div class="left half">
                        <label>To</label>
                        <div class="select-wrapper">
                            <select onchange={e => volumeModel.setComparisonId(e.target.value)} value={volumeModel.comparisonId}>
                                <option value="best-fit">Best Fit</option>
                                <option value="lowest-point">Lowest Point</option>
                                {volumeModel.surveys.map(survey =>
                                    <option key={survey.surveyId} value={survey.surveyId}>{survey.title || formatDate.formatDay(survey.surveyDateTime, true)}</option>
                                )}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="canvas-wrap table-scroll">
                <div class="canvas">
                    <label>Polygon Details</label>
                    <div class="canvas-element geometry-detail">
                        <div class="left half">Boundary</div>
                        <div class="left half">{volumeModel.perimeter}</div>
                        <div class="left half">Area</div>
                        <div class="left half">{volumeModel.area}</div>
                        {['cut', 'fill'].map(volumeType =>
                            <div>
                                <div class="left half calculated-field">{volumeType}</div>
                                <div class={`left half ${volumeType}`}>{volumeModel[volumeType]}</div>
                            </div>
                        )}
                        <div class="left half calculated-field">Net {volumeModel.netMetric}</div>
                        <div class={`left half ${volumeModel.netMetric}`}>{volumeModel.net}</div>
                    </div>
                    {volumeModel.surveys.length > 1 && volumeModel.isComparisonSurveySelected &&
                    <div onclick={() => {
                        volumeModel.showVolumeDiff = !volumeModel.showVolumeDiff;
                        if (volumeModel.showVolumeDiff) {
                            volumeModel.updateVolume();
                        }
                    }}>
                        <i class={`icon-checkbox-o${volumeModel.showVolumeDiff ? 'n' : 'ff'}`}></i>
                        <span class="toggle-label">Show volume change over time.</span>
                    </div>
                    }
                    {volumeModel.showVolumeDiff && <div>
                        <div class={`canvas-element canvas-box progress-group ${state.showProgress ? 'expanded' : ''}`}>
                            <div class="label">Cut & Fill Progress ({volumeModel.units})</div>
                            <SVGChart data={volumeModel.progressData} width={state.chartWidth} height={250} xFormat={formatDate.ddmmyy}/>
                            <DataTable data={volumeModel.progressData} xFormat={formatDate.ddmmyy}/>
                            <div class="show-more" onclick={() => {
                                state.showProgress = !state.showProgress;
                            }}>{state.showProgress ? 'Less' : 'More'} <i class="icon-down"></i></div>
                        </div>
                        <div class="label">Productivity</div>
                        <Radio options={['cut', 'fill']} defaultOption={volumeModel.productionMetric} onchange={cutOrFill => {
                            volumeModel.productionMetric = cutOrFill;
                            volumeModel.updateVolume();
                        }}/>
                        <div class="canvas-element geometry-detail">
                            <div class="left half">Total volume moved</div>
                            <div class="left half">{volumeModel.totalMoved} {volumeModel.units}</div>
                            <div class="left half">Max production rate</div>
                            <div class="left half">{volumeModel.maxProduction} {volumeModel.units}</div>
                            <div class="left half">Time span</div>
                            <div class="left half">{volumeModel.days} days</div>
                        </div>
                        <div class={`canvas-element canvas-box progress-group ${state.showProductivity ? 'expanded' : ''}`}>
                            <div class="label">Production per day ({volumeModel.units})</div>
                            <SVGChart data={volumeModel.productionData} width={state.chartWidth} height={250} xFormat={formatDate.ddmmyy}/>
                            <DataTable data={volumeModel.productionData} xFormat={formatDate.ddmmyy}/>
                            <div class="show-more" onclick={() => {
                                state.showProductivity = !state.showProductivity;
                            }}>{state.showProductivity ? 'Less' : 'More'} <i class="icon-down"></i></div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
};

export default Volume;
