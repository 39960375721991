import tableModel from 'models/table/table-model';
import Cell from 'views/table/cells/cell';
import assetListManager from 'managers/asset-list-manager';
import stopPropagation from 'util/events/stop-propagation';
import formModel from 'models/form-model';
import AssetOptions from 'views/asset-options/asset-options';
import appModel from 'models/app-model';
import popupModel from 'models/popover-model';
import screenHelper from 'legacy/util/device/screen-helper';
import batchSelectModel from 'models/batch-select-model';
import popup from 'util/popup';
import AssetOptionsModel from 'models/asset/asset-options-model';
import userManager from 'managers/user-manager';
import AssetIcon from 'views/asset-icon/asset-icon';

const MapStatusButton = {
    view: ({attrs: {assetId}}) => {
        const isLink = assetListManager.isLink(assetId) && appModel.isOnTab('Links');
        return <>
            {AssetOptionsModel.hasFeatures(assetId) || assetListManager.getCompleteLayerId(assetId)
                ? <i class="icon-center-on-map UFtracker-center-on-map"
                    onclick={stopPropagation(() => {
                        assetListManager.centerOnMap(assetId);
                        popupModel.close();
                        if (screenHelper.small()) {
                            if (!tableModel.isCollapsed) {
                                tableModel.sideBarToggle();
                            }
                            m.redraw();
                        }
                    })}/>
                : assetListManager.canAddToMap(assetId) && !isLink
                    ? <i class="icon-add-to-map" onclick={stopPropagation(() => {
                        formModel.viewAsset(assetId, 'Properties');
                        formModel.addToMap();
                        popupModel.close();
                    })}/>
                    : <i class="icon-asset-form"
                        onclick={stopPropagation(() => {
                            formModel.viewAsset(assetId, 'Properties');
                            popupModel.close();
                        })}/>
            } </>;
    }
};

class CellID extends Cell {

    oninit({state, attrs}) {
        super.oninit({attrs});
        state.menuIsOpen = false;
        state.onOpen = () => {
            state.menuIsOpen = true;
            m.redraw();
        };
        state.onClose = () => {
            state.menuIsOpen = false;
            m.redraw();
        };
    }

    view({state, attrs: {assetRow, columnIndex}}) {
        state.cssClasses = `asset-cell ${state.wrapClass || ''} ${state.activeClass || ''}`;
        state.isSelected = batchSelectModel.areNoAssetsSelected() ? false : batchSelectModel.selectedAssets[assetRow.assetId];
        return <div class='asset-primary-label'>
            <div onclick={(e) => tableModel.handleCellClick(e, assetRow.rowIndex, columnIndex)} class={state.cssClasses}>
                {!userManager.isViewOnly ?
                    <div class='selection-area' onclick={(e) => {
                        if (popup.isOpen()) {
                            popup.remove();
                        }
                        if (e.shiftKey) {
                            batchSelectModel.shiftClickSecondAssetIdIndex = assetRow.rowIndex;
                            const firstIndex = batchSelectModel.shiftClickFirstAssetIdIndex < batchSelectModel.shiftClickSecondAssetIdIndex ? batchSelectModel.shiftClickFirstAssetIdIndex + 1 : batchSelectModel.shiftClickFirstAssetIdIndex;
                            const secondIndex = batchSelectModel.shiftClickSecondAssetIdIndex > batchSelectModel.shiftClickFirstAssetIdIndex ? batchSelectModel.shiftClickSecondAssetIdIndex + 1 : batchSelectModel.shiftClickSecondAssetIdIndex;
                            const selectedAssets = firstIndex < secondIndex ? tableModel.assetIds.slice(firstIndex, secondIndex) : tableModel.assetIds.slice(secondIndex, firstIndex);
                            selectedAssets.forEach(assetId => {
                                if (state.isSelected) {
                                    batchSelectModel.deselectSingleAsset(assetId);
                                } else {
                                    batchSelectModel.selectSingleAsset(assetId);
                                }
                            });
                            m.redraw();
                        } else {
                            batchSelectModel.shiftClickFirstAssetIdIndex = assetRow.rowIndex;
                            if (state.isSelected) {
                                batchSelectModel.deselectSingleAsset(assetRow.assetId);
                            } else {
                                batchSelectModel.selectSingleAsset(assetRow.assetId);
                            }
                        }
                        e.stopPropagation();
                        state.isSelected = batchSelectModel.selectedAssets[assetRow.assetId] || false;
                    }}>
                        <span class={`${state.isSelected ? 'icon-checkbox-on UFtracker-deselect-record' : 'icon-checkbox-off UFtracker-select-record'}`}></span>
                        <span className="row-number">{assetRow.rowIndex + 1}</span>
                    </div> :  <span className="row-number view-only">{assetRow.rowIndex + 1}</span>
                }
                <span class={`asset-icon-wrap ${userManager.isViewOnly ? 'view-only' : ''}`}><AssetIcon assetId={assetRow.assetId}/>
                </span>
                <span class={`asset-title property-value ${tableModel.tableMode === 'list-left' ? 'list-title' : ''} ${userManager.isViewOnly ? 'view-only' : ''}`}>{assetListManager.getAssetName(assetRow.assetId)}</span>
            </div>
            <span class="map-status"><MapStatusButton assetId={assetRow.assetId} /></span>
            <span class={`asset-options-menu ${state.menuIsOpen ? 'is-open' : 'is-closed'}`}><AssetOptions onOpen={state.onOpen} onClose={state.onClose} assetId={assetRow.assetId}/></span>
        </div>;
    }
}

export default CellID;
