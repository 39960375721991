import Step from 'flows/step-model';

const STEPS = {
    START: 0,
    DONE: 1
};

class Flow {

    constructor() {
        this.reset();
    
        this.initStepFunctions = {};
    
        this.steps = {
            START: null,
            DONE: null
        };
    
        Object.values(STEPS).forEach(step => {
            this.steps[step] = new Step(step, this.initStepFunctions[step]);
        });
        
    }

    get currentStep() {
        return this.steps[this.onStep];
    }

    start(args) {
        this.isActive = true;
        this.currentStep.init(args);
    }

    reset() {
        this.isActive = false;
        this.onStep = STEPS.START;
    }

    previousStep() {
        this.goToStep(this.onStep - 1);
    }

    nextStep() {
        this.goToStep(this.onStep + 1);
    }

    goToStep(step) {
        this.onStep = step;
        m.redraw();
        this.currentStep.init();
    }

}

export default Flow;
