import store from 'util/data/store';
import formModel from 'models/form-model';
import measure from 'legacy/util/numbers/measure';
import isMetric from 'util/numbers/is-metric';
import round from 'util/numbers/round';

export default function(control, assetId) {
    const properties = store.assets[assetId].properties,
        metric = isMetric(),
        asset = store.assets[assetId];
    let value = properties[control.fieldName];
    value = !!value || control.attributes.required
        ? round(metric ? value : measure.squareMetersToSquareFeet(value))
        : value;
    if (formModel.isReadOnlyControl(asset.assetTypeId, control)) {
        return <div class="eval-value">{value} {metric ? 'meters²' : 'feet²'}</div>;
    }
    return <div class="area-control">
        <input data-focus-id="1"  type="number" class="number-input" value={value} step="any" oninput={e => {
            formModel.handleArea(e, control, assetId);
        }}/>
        <span class="number-metric">{metric ? 'meters²' : 'feet²'}</span>
    </div>;
}
