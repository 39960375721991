import store from 'util/data/store';
import controlToFeature from 'util/interfaces/control-to-feature';
import {sanitize, sanitizeUrl} from 'util/data/sanitize';
import onBodyClick from 'legacy/util/dom/on-body-click';
import formModel from 'models/form-model';

const updateAssetFeatures = (control, assetId) => controlToFeature.updateAssetFeatures(control, assetId);

const URL = {
    oninit({state, attrs: {isPopup, control, assetId}}) {
        const asset = store.assets[assetId];
        const assetTypeId = asset.assetTypeId;
        const propertyValue = asset.properties[control.fieldName] || {};
        state.isReadOnly = formModel.isReadOnlyControl(assetTypeId, control);
        if (isPopup) {
            // Overrides any other case
            state.inReadMode = false;
        } else {
            state.inReadMode = propertyValue.URL && propertyValue.URL.length || state.isReadOnly;
        }
    },

    handleInput: (e, property, assetId, control) => {
        e.stopPropagation();
        store.assets[assetId].properties[control.fieldName] = store.assets[assetId].properties[control.fieldName] || {};
        store.assets[assetId].properties[control.fieldName][property] = sanitize(e.target.value);
        updateAssetFeatures(control, assetId);
    },

    view({state, attrs: {isPopup, assetId, control}}) {
        const value = store.assets[assetId].properties[control.fieldName] || {};
        const switchToReadMode = () => {
            value.URL = value.URL.trim();
            if (!isPopup && value.URL && value.URL.length) {
                state.inReadMode = true;
                m.redraw();
            }
        };
        const switchToEditMode = () => {
            state.inReadMode = false;
            onBodyClick.once(() => switchToReadMode());
        };
        return <div class="url-control" onclick={e => e.stopPropagation()}> { state.inReadMode 
            ? <URL.ReadMode value={value} isReadOnly={state.isReadOnly} switchToEditMode={switchToEditMode}/> 
            : <URL.EditMode value={value} switchToReadMode={switchToReadMode} isPopup={isPopup}
                handleUrlUpdate={e => this.handleInput(e, 'URL', assetId, control)} 
                handleDisplayUpdate={e => this.handleInput(e, 'display', assetId, control)} 
            /> }
        </div>;  
    },

    ReadMode: {
        oninit: ({state, attrs: {value}}) => {
            state.sanitizedUrl = sanitizeUrl(value.URL);
        },

        view: ({state, attrs: {switchToEditMode, value, isReadOnly}}) => <div class="url-control-read-mode-wrap">
            <div class={`${isReadOnly ? 'read-only-outer' : 'clickable-outer'}`} onclick={isReadOnly ? null : switchToEditMode}></div>
            {/* eslint-disable-next-line mithril/jsx-no-target-blank */}
            <a class="clickable-link" onclick={(e) => e.stopPropagation()} href={state.sanitizedUrl} target="_blank" title={`${value.URL} (Opens in a new tab)`} rel="noreferrer">{value.display || value.URL}</a>
        </div>
    },

    EditMode: {
        view: ({attrs: {switchToReadMode, value, isPopup, handleDisplayUpdate, handleUrlUpdate}}) => <div class="url-editing-mode-wrap">
            <div class="url-input-item url">
                <label>URL</label>
                <input data-focus-id="1" type="url" placeholder="https://" value={value.URL || ''} oninput={e => handleUrlUpdate(e)}/>
            </div>
            <div class="url-input-item display">
                <label>Display Text (optional)</label>
                <input type="text" value={value.display || ''} oninput={e => handleDisplayUpdate(e)}/>
            </div>
            {!isPopup && !!value.URL && <div class="btn btn-smallest btn-primary btn-circle right" onclick={switchToReadMode}><i class="icon-check"/></div>}
        </div>
    }
};

const urlHigherOrderComponent = (control, assetId, opts) => <URL control={control} assetId={assetId} isPopup={opts && opts.isPopup} />;

export default urlHigherOrderComponent;
