import mediaViewerModel from 'models/media-viewer-model';
import MediaOptions from 'views/media-options';
import store from 'util/data/store';
import formModel from 'models/form-model';
import helpers from 'legacy/util/api/helpers';
import formatDate from 'legacy/util/date/format-date';
import mediaListManager from 'managers/media-list-manager';

const FailedMediaCard = {
    view: ({attrs: {mediaId, media, showOptions, control}}) => <span class="media-item-status-block">
        {media ? <label className="upload-label">{media.label}</label> : ''}
        <div className="status-icon-wrap"><i className="icon-cloud-alert"/></div>
        <div className="status-label failed">File Upload Failed</div>
        {!!showOptions && <MediaOptions isInvalid={true} mediaId={mediaId} control={control}/>}
    </span>
};

const PendingMediaCard = {
    view: ({attrs: {media, showOptions, control}}) => <span class="media-item-status-block"><label className="upload-label">{media.label}</label>
        <div className="status-icon-wrap"><i className="icon-cloud-open"/><i
            className="icon-upload-progress"/></div>
        <div className="status-label in-progress">Upload in Progress...</div>
        {!!showOptions && <MediaOptions isInvalid={true} mediaId={media.mediaId} control={control}/>}
    </span>
};

const ThumbnailItem = {
    oninit: ({state, attrs: {mediaId}}) => {
        state.isLoading = true;
        mediaListManager.getMedia(mediaId, {
            onSuccess: (media) => {
                state.media = media;
                state.isLoading = false;
                m.redraw();  
            }, 
            onInvalid: () => {
                state.isInvalid = true;
                state.isLoading = false;
                m.redraw();
            }});
    },

    view: ({state, attrs: {index, mediaIds, mediaId, showOptions, previewMode, control}}) => {
        if (state.isLoading) {
            return <div class='media-thumbnail-single file-gallery-item is-photo'><div class="loading"></div></div>;
        }
        if (state && state.isInvalid) {
            return <FailedMediaCard mediaId={mediaId} control={control} showOptions={showOptions}/>;
        }
        if (!state.media || !state.media.thumbnailData) {
            return <div class='media-thumbnail-single file-gallery-item is-photo'/>;
        }
        if (state.media.status === 'pending') {
            return <PendingMediaCard mediaId={mediaId} media={state.media} control={control} showOptions={showOptions}/>;
        } 
        if (state.media.status === 'failed') {
            return <FailedMediaCard mediaId={mediaId} media={state.media} control={control} showOptions={showOptions}/>;
        }
        
        const thumbnail = state.media.thumbnailData; 
        const cssClasses = `media-thumbnail-single file-gallery-item ${!previewMode && index === mediaViewerModel.state.index ? ' selected' : ''}${thumbnail.isImage ? ' is-photo' : ''}${previewMode && state.media.captureDateTime ? ' has-capture-date' : ''}`;
        return <div class="media-thumbnail-caption-wrap" key={thumbnail.mediaId + (thumbnail.src ? '1' : '')}>
            <div style={`background-image:url(${thumbnail.src})`} class={cssClasses}
                onclick={() => {
                    if (previewMode) {
                        const asset = store.assets[formModel.assetId];
                        mediaIds = mediaIds || helpers.list(asset.mediaIds);
                        let thumbnailIndex = mediaIds.indexOf(thumbnail.mediaId);
                        if (thumbnailIndex === -1) {
                            mediaIds.push(thumbnail.mediaId);
                            thumbnailIndex = mediaIds.length - 1;
                        }
                        mediaViewerModel.open(mediaIds, mediaIds.indexOf(thumbnail.mediaId));
                    } else if (mediaViewerModel.state.index !== index) {
                        mediaViewerModel.selectIndex(index);
                    }
                }}>
                {!!showOptions && <MediaOptions mediaId={thumbnail.mediaId} control={control}/>}
                <div class='file-gallery-name'>{thumbnail.name}</div>
            </div>
            {state.media && state.media.captureDateTime && previewMode && <div class="capture-date-container">
                Capture Date: {formatDate.ddmmyyyy(state.media.captureDateTime)}
            </div>}
        </div>;
    }
};

const ThumbnailGallery = {
    view: ({attrs: {mediaIds, showOptions, previewMode, control}}) => {
        const isSingle = mediaIds.length === 1;
    
        return <div class={'file-gallery' + (isSingle ? ' single' : '')} onwheel={e => {
            e.currentTarget.scrollLeft += e.deltaY;
            e.redraw = false;
        }}>
            {mediaIds.map((mediaId, i) => <ThumbnailItem mediaIds={mediaIds} mediaId={mediaId} index={i} key={mediaId} showOptions={showOptions} previewMode={previewMode} control={control}/>)}
        </div>;
       
    }
};

export default ThumbnailGallery;
