export default (fn, delay = 250) => {

    let ready = true;

    return e => {

        if (ready) {

            fn(e);

            ready = false;

            setTimeout(() => {

                ready = true;

                fn(e);

            }, delay);

        }

    };

};
