import {LngLatBounds} from 'mapbox-gl';

// Given a map, bounds, and padding size (in pixels), 
// calculates new bounds for the map extended on all
// sides by the padding amount. Returns updated bounds.
function padBounds(map, bounds, paddingSize) {
    const swPoint = map.project(bounds.getSouthWest());
    const nePoint = map.project(bounds.getNorthEast());

    const newSwPoint = {
        x: swPoint.x - paddingSize,
        y: swPoint.y + paddingSize
    };

    const newNePoint = {
        x: nePoint.x + paddingSize, 
        y: nePoint.y - paddingSize
    };

    const newSwBounds = map.unproject(newSwPoint);
    const newNeBounds = map.unproject(newNePoint);

    return new LngLatBounds(newSwBounds, newNeBounds);
}

export default padBounds;
