
export const CREATE = 'CREATE';
export const READ = 'READ';
export const EDIT = 'EDIT';
export const DELETE = 'DELETE';

export const CRED_ALL = 'CRED_ALL';
export const CRE_ALL_DELETE_OWN = 'CRE_ALL_DELETE_OWN';
export const CRED_OWN = 'CRED_OWN';
export const READ_ONLY = 'READ_ONLY';
export const HIDDEN = 'HIDDEN';

export const accessTypeToText = {
    READ: 'View',
    CREATE: 'Add New',
    EDIT: 'Edit',
    DELETE: 'Delete'
};

export const permissionSets = {
    CRED_ALL: {
        [CREATE]: true,
        [READ]: true,
        [EDIT]: true,
        [DELETE]: true
    },
    CRE_ALL_DELETE_OWN: {
        [CREATE]: true,
        [READ]: true,
        [EDIT]: true,
        [DELETE]: false  
    },
    CRED_OWN: {
        [CREATE]: true,
        [READ]: true,
        [EDIT]: false,
        [DELETE]: false
    },
    READ_ONLY: {
        [CREATE]: false,
        [READ]: true,
        [EDIT]: false,
        [DELETE]: false
    },
    HIDDEN: {
        [CREATE]: false,
        [READ]: false,
        [EDIT]: false,
        [DELETE]: false
    }
};
