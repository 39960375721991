const AuthForm = {
    view: ({children, attrs: {onSubmit, cssClass}}) => {
        return <form class={cssClass || 'login'} onsubmit={(e) => {
            if (onSubmit) {
                onSubmit();
            }
            e.preventDefault();
            return false;
        }}>
            {children}
        </form>;
    }
};

export default AuthForm;
