import FilterTypeModel from 'models/table/filter-types/filter-type-model';
import peopleModel from 'models/people/people-model';
import appModel from 'models/app-model';

class definedOptionsModel extends FilterTypeModel {

    init(specialType) {
        const specialProperties = ['checkedOptions', 'isEmptyChecked'];
        if (specialType) {
            specialProperties.push(specialType);
        }
        super.init({type: 'definedOptions', specialProperties});     
        this.isInittedFor = appModel.project.projectId;
    }

    get options() {
        if (!this.field) {
            return [];
        }
        if (this.isUserObjType) {
            return peopleModel.getControlOptionFilterList();
        }
        if (this.field.type.items) {
            return this.field.type.items.values;
        }
        return this.field.type.values;
    }

    hasAnyMixedOptions(state = this.state) {
        return Object.values(state.checkedOptions).find(opt => opt === 'mixed') || state.isEmptyChecked === 'mixed';
    }

    isAllSelected(state = this.state) {
        if (this.isInittedFor !== appModel.project.projectId) {
            return false;
        }

        let isAllSelected = true;
        let isAllDeselected = true;
        for (const option of this.options) {
            if (this.isSelectionMixed(state.checkedOptions[option])) {
                isAllDeselected = false;
                isAllSelected = false;
                break; // quit, if found one mixed then neither can be true
            } else if (state.checkedOptions[option]) {
                isAllDeselected = false;
            } else {
                isAllSelected = false;
            }
        }
        if (state.isEmptyChecked) {
            isAllDeselected = false;
        } else {
            isAllSelected = false;
        }

        return {isAllSelected, isAllDeselected};
    }

    isSelectionMixed(selection) {
        return selection === 'mixed';
    }

    switchAllChecks(isOn, ignoreFetch = false) {
        // const isRequired = filterModel.opts.controlType.attributes.required;
        const isRequired = true;

        for (const option of this.options) {
            this.state.checkedOptions[option] = isOn;
        }
        if (isRequired) {
            this.state.isEmptyChecked = isOn;
        }
        if (ignoreFetch === true) {
            return;
        }
        this.onFilterUpdate();
    }

    toggleOption(option) {
        const selection = this.state.checkedOptions[option];
        if (this.isSelectionMixed(selection)) {
            this.state.checkedOptions[option] = true;
        } else {
            this.state.checkedOptions[option] = !selection;
        }
        this.onFilterUpdate(option);
    }

    toggleEmptyState() {
        if (this.isSelectionMixed(this.state.isEmptyChecked)) {
            this.state.isEmptyChecked = true;
        } else {
            this.state.isEmptyChecked = !this.state.isEmptyChecked;
        }
        this.onFilterUpdate('isEmptyCheckedOption');
    }

    turnOffAllExcept(selectedControlName) {
        this.switchAllChecks(false, true);
        this.state.checkedOptions[selectedControlName] = true;
        this.onFilterUpdate();
    }

    turnOffAllExceptGroup(groupOfControlNames = []) {
        this.switchAllChecks(false, true);
        groupOfControlNames.forEach(name => {
            this.state.checkedOptions[name] = true;
        });
        this.onFilterUpdate();
    }

}

export default new definedOptionsModel();
