import savedViewsModel from 'models/saved-views-model';
import OptionsMenu from 'views/saved-views/saved-views-options-menu';
import Header from 'views/saved-views/card/components/header';
import Details from 'views/saved-views/card/components/details';
import Footer from 'views/saved-views/card/components/footer';
import CloseButton from 'views/close-button';
import popupModel from 'models/popover-model';

const SavedViewsCard = {
    oninit: ({state}) => {
        savedViewsModel.init();
        state.popupOpen = false;
        state.formFields = { 
            name: {
                value: '',
                cssClass: '',
                isRequired: true
            }
        };
    },

    view: ({state, attrs: {projectViewId}}) => {
        const projectView = savedViewsModel.getProjectView(projectViewId);
        if (projectView) {
            return <div class="centering-wrap"><div class={`saved-view-card modal-wrap ${projectView.isSaved ? 'is-saved' : ''} ${state.popupOpen ? 'popup-menu-open' : ''}`}>
                <div class="vertical-wrap">
                    <div class="inner-content-scrolling">
                        <Header formField={state.formFields.name} projectView={projectView}/>
                        {projectView.isSaved && <div class="options-buttons">
                            <i class='icon-ellipsis toggle-option-menu'
                                onclick={function(e) {
                                    e.stopPropagation();
                                    state.popupOpen = true;
                                    popupModel.open(this, {
                                        args: {projectViewId: projectView.projectViewId, hideViewDetails: true},
                                        wrapClass: 'saved-views-card-menu saved-views-options-menu-wrap',
                                        view: OptionsMenu,
                                        keepUIOpen: true,
                                        onClose: () => {
                                            state.popupOpen = false;
                                            m.redraw();
                                        }
                                    });
                                }}/>
                            <CloseButton onclick={() => savedViewsModel.close()} />
                        </div> }
                        <Details projectView={projectView}/>
                    </div>
                    <div class="inner-content-fixed">
                        <Footer formFields={state.formFields} projectView={projectView}/>
                    </div>
                </div>
            </div> 
            </div>;
        }
        return <div class="centering-wrap"><div class="saved-view-card modal-wrap is-loading">
            <CloseButton onclick={() => savedViewsModel.close()} />
            <i class="spinner spinning teal"/>
        </div>
        </div>;
    }
};

export default SavedViewsCard;
