import onBodyClick from 'legacy/util/dom/on-body-click';
import screenHelper from 'legacy/util/device/screen-helper';
import sideNavModel from 'models/side-nav-model';
import panelModel from 'models/panel-model';
import pointMenu from 'legacy/components/point-menu/point-menu';

class PopoverModel {

    constructor() {
        this.reset();
        this.close = this.close.bind(this);
    }

    reset() {
        this.isOpen = false;
        this.disableClose = false;
        this.disableCloseOnce = false; // Used for allowing one-time clicks on modal prompts (ie required field reminders)
        this.isFocused = false;
        this.wrapClass = '';
        this.parentClass = '';
        this.coordinates = {};
        this.args = undefined;
    }

    disableCloseFor(tick = 500) {
        if (this.disableClose === true) {
            return;
        }
        this.disableClose = true;
        setTimeout(()=>{
            this.disableClose = false;
        }, tick);
    }

    closeAllOthers() {
        pointMenu.close();
        panelModel.close();
        sideNavModel.close();
    }

    open(element, opts = {}) {
        if (this.isOpen) {
            this.close();
        }
        if (!opts.keepUIOpen) {
            this.closeAllOthers();
        }
        if (opts.forceInit && opts.view && opts.view.oninit) {
            opts.view.oninit();
        }
        this.element = element;
        this.view = opts.view;
        this.args = opts.args;
        this.alignBottom = opts.alignBottom;
        this.alignRight = opts.alignRight;

        this.wrapClass = `${opts.wrapClass} ${this.alignBottom ? 'align-bottom' : 'align-top'} ${this.alignRight ? 'align-right' : 'align-left'}`;

        this.parentClass = opts.parentClass || '';
        this.disableClose = opts.disableClose;
        this.boundingBox = opts.boundingBox || '';
        this.onBodyClickClose = opts.onBodyClickClose;
        this.onClose = opts.onClose;
        this.onPopupOpen = opts.onPopupOpen;
        this.yPadding = opts.yPadding || 0;

        const rect = element.getBoundingClientRect();
        const x = rect.left;
        const y = this.alignBottom ? rect.bottom + (this.yPadding ? this.yPadding : 0) : rect.top - (this.yPadding ? this.yPadding : 0);

        this.coordinates = {x, y, width: rect.width};

        if (opts.noWidth) {
            delete this.coordinates.width;
        }

        this.isOpen = true;
        onBodyClick.once((e) => this.close(e));
        m.redraw();

        setTimeout(() => {

            if (this.onPopupOpen) {
                this.onPopupOpen();
            }
            const el = document.querySelector('.table-popup:not(.form-control-popup)');
            if (!el) {
                return;
            }
            if (screenHelper.small() && rect.left + el.clientWidth > window.innerWidth) {
                const padding = 24;
                this.coordinates.x = window.innerWidth - el.clientWidth - padding;
                m.redraw();
            }

            const distanceOffScreen = this.coordinates.y + el.offsetHeight - window.innerHeight;

            if (distanceOffScreen > 0) {
                this.coordinates.y = window.innerHeight - el.offsetHeight - 12;
            }

            m.redraw();

        }, 25);
    }

    close(e) {
        if (this.disableClose || !this.isOpen) {
            return;
        }

        // Used for clicking on required form modal
        if (this.disableCloseOnce) {
            this.disableCloseOnce = false;
            onBodyClick.once(this.close);
            return;
        }

        if (this.onClose) {
            this.onClose();
        }

        if (e && this.onBodyClickClose) {
            this.onBodyClickClose();
        }
        this.disableCloseFor();
        onBodyClick.clear();
        this.reset();
        m.redraw();
    }
}

export default new PopoverModel();
