import store from 'util/data/store';
import controlToFeature from 'util/interfaces/control-to-feature';
import Radio from 'views/radio';

const updateAssetFeatures = (control, assetId) => controlToFeature.updateAssetFeatures(control, assetId);

export default function(control, assetId, opts = {}) {
    const reset = opts.reset;
    const asset = store.assets[assetId],
        properties = asset.properties,
        defaultOption = properties[control.fieldName],
        field = store.assetTypeFields[asset.assetTypeId][control.fieldName];
    return <Radio reset={reset} defaultOption={defaultOption} options={field.type.values} onchange={value => {
        properties[control.fieldName] = value;
        updateAssetFeatures(control, assetId);
    }}/>;
}
