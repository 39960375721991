import screenHelper from 'legacy/util/device/screen-helper';

const platform = navigator.platform,
    userAgent = navigator.userAgent;

function getOS() {
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    
    let os;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
    }

    return os;
}

function getDevice() {
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
        return 'tablet';
    }
    if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(userAgent)) {
        return 'phone';
    }
    return 'desktop';
}

function getBrowser() {
    const test = regex => regex.test(userAgent);
    switch (true) {
    case test(/edg/i): return 'edge';
    case test(/trident/i): return 'ie';
    case test(/firefox|fxios/i): return 'firefox';
    case test(/opr\//i): return 'opera';
    case test(/ucbrowser/i): return 'uc';
    case test(/samsungbrowser/i): return 'samsung';
    case test(/chrome|chromium|crios/i): return 'chrome';
    case test(/safari/i): return 'safari';
    default: return;
    }
}

function getNetwork() {
    if (navigator.onLine === false) {
        return 'none';
    }
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    if (connection) {
        if (connection.effectiveType === '4g') {
            return 'wifi';
        }
        return 'cellular';
    }
}

const osName = getOS(),
    browserName = getBrowser(),
    network = getNetwork(),
    deviceType = getDevice(),
    permissions = navigator.permissions && navigator.permissions.query ? navigator.permissions : null;

let locationPermsGrant,
    cameraPermsGrant;

if (permissions) {
    permissions.query({name: 'geolocation'}).then(perm => {
        locationPermsGrant = perm.state === 'granted';
    });
    // Firefox throws an error if you try to request camera perms
    if (browserName !== 'firefox') {
        permissions.query({name: 'camera'}).then(perm => {
            cameraPermsGrant = perm.state === 'granted';
        });
    }
}

const analytics = {
    fetch: () => ({
        deviceType,
        osName,
        // osVersion,
        browserName,
        // browserVersion,
        network,
        locationPermsGrant,
        cameraPermsGrant,
        screenWidth: screen.width,
        screenHeight: screen.height,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight
    }),
    getMobileWebOS: () => {
        if (osName === 'iOS' && !screenHelper.isIosWebView()
            || osName === 'Android' && !screenHelper.isAndroidWebView()) {
            return osName;
        }
    }
};

export default analytics;
