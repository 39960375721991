import importModel from 'models/import-model';
import constants from 'util/data/constants';
import store from 'util/data/store';
import ControlLabel from 'views/control-label/control-label';

const controlIsEvalOrHidden = (control) => {
    // We *do* want attributes.readOnly controls available for importing, per UE-5650
    return control.attributes && (control.attributes.hidden || control.attributes.eval);
};

const ImportHelp = {
    oninit({state, attrs: {tool}}) {
        state.geomType = tool.featureTypes[0].geometry.type;
        state.controlDescriptions = [];
        state.controls = [];
        tool.assetForm.controls.forEach(control => {
            if (!controlIsEvalOrHidden(control) && !importModel.hiddenControlTypes[control.controlTypeId]) {
                const field = store.assetTypeFields[tool.assetForm.assetType.assetTypeId][control.fieldName];
                if (field) {
                    state.controls.push(control);
                    state.controlDescriptions.push({
                        control,
                        description: importModel.fieldDescription(field)
                    });
                }
            }
        });
    },
    view: ({attrs: {tool}, state: {controls, controlDescriptions, geomType}}) => {
        return <div class="import-help">
            <div class="center">
                <a class="btn btn-pill btn-primary btn-small" onclick={() => {
                    importModel.importInProgress = true;
                    importModel.upload(tool, controls);
                }}>
                    <span class="btn-label">Select File for Import <i class="icon-arrow-right" /></span>
                </a>
            </div>
            <p class="import-intro">When importing a dataset, please note the following to avoid errors:</p>
            <p>For each record {geomType ? `with ${geomType} geometry` : 'in your import file'}, we will create one {tool.name} record in Unearth.</p>
            <p>We currently support importing files with up to {importModel.maxAssets} records.</p>
            {!!geomType && <>
                <p>For datasets that do not contain geometry, you will be able to:</p>
                <ul class="import-detail-list">
                    <li>Add the records without mapping</li>
                    <li>Or select a different geometry source</li>
                </ul>
            </>}
            <h3>{tool.name}: {controls.length} Propert{controls.length === 1 ? 'y' : 'ies'}</h3>
            <p>Each field in the {tool.name} Properties Form can be matched to a column in your dataset. Please check your import source for compatibility.</p>
            <table>
                <thead>
                    <td>Property Name</td>
                    <td>Matchable Column</td>
                </thead>
                <tbody>
                    {controlDescriptions.map(cd => {
                        return <tr>
                            <td>
                                <div class="property-label"><ControlLabel control={cd.control} noElementTag={true}/></div>
                                <code>{constants.controlTypeIdToName[cd.control.controlTypeId]}</code>
                            </td>
                            <td>{cd.description}</td>
                        </tr>;
                    })}
                </tbody>
            </table>
        </div>;
    }
};

export default ImportHelp;
