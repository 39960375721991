import formModel from 'models/form-model';

const EmptyReadOnly = {
    view: ({attrs: {control}}) => <div class="asset-form-control clear empty-read-only-control">
        <div class={`clear ${control.attributes.hideLabel ? 'label-hidden' : ''}`}>
            {!control.attributes.hideLabel && <label>{control.label}{formModel.controlSuffix(control)}</label>}
            <div class="empty-read-only-value">no value</div>
        </div>
    </div>
};

export default EmptyReadOnly;

