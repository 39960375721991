import store from 'util/data/store';
import router from 'uav-router';
import initializer from 'util/initializer';
import api from 'legacy/util/api/api';
import publish from 'legacy/util/api/publish';
import dialogModel from 'models/dialog-model';
import message from 'views/toast-message/toast-message';
import modalModel from 'models/modal-model';
import PersonModel from 'models/people/person-model';
import appModel from 'models/app-model';
import { apiPhoneNumber } from 'util/data/phone-number';
import helpers from 'legacy/util/api/helpers';
import ProjectPeopleModel from 'models/people/project-people-model';
import sideNavModel from 'models/side-nav-model';
import formModel from 'models/form-model';
import debouncedAPICall from 'util/network/debounced-api-call';
import PersonModule from 'views/person/person-module';
import notificationsModel from '../notifications-model';
import capitalize from 'util/data/capitalize';


const DEFAULT_API_ACCOUNT_ROLE = 'general'; // This is the default role that the BE assigns for new users. 
const UNKNOWN_USER = 'Unknown User';
const UNKNOWN_USER_NAME = 'New User';
const MAX_NUM_CACHED_PROJECTS = 5;

// Handles data and logic for list of account and project users
// (See user-manager for code related to the logged-in user)
class peopleModel {

    constructor() {
        this.reset();
        this.companyMemberCount = 0;
        initializer.add(this.awaitChanges.bind(this), 'peopleModel');
    }

    reset() {
        this.all = {}; // Mapping of visible accountUser userIds:PersonModel
        this.hidden = {}; // Mapping of hidden accountUser userIds:PersonModel
        this.projectPeople = {}; // Mapping of all account projectIds:ProjectPeopleModel
        this.viewingUserId = undefined;
        this.newUser = undefined;
        this._list = [];
    }

    // --------------- Getters ---------------

    get list() {
        return this._list;
    }

    companyCount() {
        this.list.forEach(userId => {
            const person = this.getPerson(userId);
            const userCompany = appModel.user.company || '';
            if (person.company && person.company === userCompany) {
                return this.companyMemberCount++;
            }
        });
    }

    getPerson(userId) {
        return this.all[userId] || this.hidden[userId];
    }

    displayName(user) {
        if (!user) {
            return UNKNOWN_USER;
        }
        return user.givenName && user.familyName ? `${user.givenName} ${user.familyName}` : user.givenName || UNKNOWN_USER_NAME;
    }

    displayNameOrEmail(userId) {
        const user = this.getPerson(userId);
        if (!user) {
            return UNKNOWN_USER;
        }
        return user.givenName && user.familyName ? `${user.givenName.trim()} ${user.familyName.trim()}` : user.givenName && user.givenName.trim() || user.emailAddress;
    }

    displayNameOrFetch(userId) {
        const user = this.getPerson(userId);
        if (!user && userId) {
            return api.rpc.request([['listUsers', { userId }]]).then(_users => {
                if (_users.length) {
                    return _users[0].givenName && _users[0].familyName ? `${_users[0].givenName} ${_users[0].familyName}` : _users[0].givenName || 'Unknown user';
                }
                return 'Unknown User';
            });
        }
        return Promise.resolve(user.givenName && user.familyName ? `${user.givenName} ${user.familyName}` : user.givenName || user.emailAddress);
    }

    isInPeopleList(userId) {
        return this.all[userId] || this.hidden[userId];
    }

    getControlOptions() {
        const projectId = appModel.getState('editingProjectId');

        // If the context is for the metaproject users, return the account user list. 
        if (appModel.project.isMetaProject && projectId === appModel.project.projectId) {
            return this.list;
        }

        // If the context is for the child project (ie in a child project asset), return the user list for that project
        if (this.projectPeople[projectId]) {
            return this.projectPeople[projectId].controlOptions || [];
        }
        this.getPeopleForProjectId(projectId).then(projectPeople => {
            m.redraw();
            return projectPeople.controlOptions;
        });
    }

    getControlOptionFilterList() {
        return this.getControlOptions();
    }

    displayUserControlOption(userId) {
        return this.displayNameOrEmail(userId);
    }

    // --------------- Loading initial data ---------------

    /*
     * Load each account user from store to model.
     */
    loadAccountPeople() {
        this.reset();
        const users = [...store.account.users];
        // Sort users by first name, last name when first loaded
        users.sort((a, b) => `${a.givenName} ${a.familyName}`.localeCompare(`${b.givenName} ${b.familyName}`));
        users.forEach(user => this.addAccountUser(user));
    }

    /*
     * Fetches or returns cached project people. Returns them sorted by project access level and first and last name.
     */
    getPeopleForProjectId(projectId = appModel.getState('editingProjectId')) {
        // If the user list doesn't exist for the project yet, fetch it:
        if (this.projectPeople[projectId]) {
            this.projectPeople[projectId].refreshListOrder();
            return Promise.resolve(this.projectPeople[projectId]);
        }
        return this.fetchProjectUsers(projectId).then(projectUsers => this.loadProjectUsers(projectId, projectUsers));
    }

    /*
     * Fetch users from api
     */
    async fetchProjectUsers(projectId) {
        await appModel.waitUntilInit();
        return debouncedAPICall('getProjectUsers' + projectId, ['getProject', { projectId }]).then((project) => helpers.list(project.users));
    }

    /*
     * Load project users into cache as model
     */
    loadProjectUsers(projectId, projectUserList) {
        this.clearCacheIfMaxed();
        this.projectPeople[projectId] = new ProjectPeopleModel(projectId, projectUserList, this._list);
        m.redraw();
        return this.projectPeople[projectId];
    }

    clearCacheIfMaxed() {
        if (Object.keys(this.projectPeople).length >= MAX_NUM_CACHED_PROJECTS) {
            this.projectPeople = {};
        }
    }

    // --------------- Modifying front end data ---------------

    /* Add account person to front end */
    addAccountUser(user) {
        let person;
        if (user.userId !== appModel.user.userId) {
            person = new PersonModel(user);
        } else {
            person = appModel.user; // This is the logged-in user
        }
        if (user.isVisible) {
            this.all[person.userId] = person;
            // Maintain order based on created date for now.
            this._list.push(user.userId);
        } else {
            this.addHiddenUser(person);
        }
    }

    addHiddenUser(user) {
        this.hidden[user.userId] = new PersonModel(user);
    }

    /* Remove account person from front end */
    removeAccountUser(userId) {
        Object.keys(this.projectPeople).forEach(projectId =>
            this.projectPeople[projectId].removePerson(userId));
        this._list = this._list.filter(id => id !== userId);
        delete this.all[userId];
    }

    // --------------- UI Changes ---------------

    open(menuItem, userId = appModel.user.userId) {
        this.viewingUserId = userId;
        this.activeMenuItem = menuItem;
        modalModel.open({view: PersonModule});
    }

    // For if module is already open
    selectMenuItem(menuItem) {
        this.activeMenuItem = menuItem;
        m.redraw();
    }

    resetSavingValues() {
        this.viewingPerson.didSaveValue = false;
        notificationsModel.didSaveValue = false;
    }

    get viewingPerson() {
        if (this.viewingUserId === 'new') {
            return this.newUser;
        }
        return this.getPerson(this.viewingUserId);
    }
    
    openNewPerson() {
        const newUser = new PersonModel({
            accountId: store.account.accountId,
            role: 'viewer'
        });
        this.newUser = newUser;
        this.open('profile', 'new');
    }

    routeToPeopleTab(assetId = router.params.assetId) {
        if (appModel.project.isMetaProject) {
            if (router.params.assetId) {
                router.merge({ tab: 'People', assetId });
            } else {
                formModel.viewAsset(assetId, 'People');
            }
        } else {
            sideNavModel.openMetaAsset('People');
        }
    }

    // --------------- Saving data to back end ---------------

    // If person has access to project, remove it, else, add it.
    // Update front end and save to back end.
    toggleProjectAccess(userId, projectId) {
        const person = this.getPerson(userId);
        person.savingClass = 'modifying'; // Freezes toggle access until save is complete
        this.projectPeople[projectId].toggleAccess(person).then((action) => {
            message.show(`Person ${action}.`, 'success');
            person.savingClass = '';
            m.redraw();
        }).catch(() => {
            this.projectPeople[projectId].toggleAccess(person, false);
            message.show('Something went wrong, please try again.', 'error');
            person.savingClass = '';
            m.redraw();
        });
    }

    hasAdminPrivileges(user) {
        return user.isAccountAdmin || user.isSuperAdmin;
    }

    submitNewPersonForm(person) {
        person.formState.isSavingNew = person.formState.highlightMissing = true;
        // Check if form is valid and handle accordingly:
        if (!this.userFormIsValid(person)) {
            dialogModel.append({
                headline: 'Required fields incomplete.',
                text: 'Please check that all required fields are complete and resubmit.',
                onOkay: () => {
                    person.formState.isSavingNew = false;
                }
            });
            m.redraw();
            return;
        }
        // If valid, save new person:
        this.saveNewPerson(person);
    }

    // Verify that all required fields have data
    userFormIsValid(person) {
        return person.givenName && person.familyName
            && person.emailAddress && person.phoneNumber;
    }

    // Check if the creation date difference is less than 60 seconds from now
    // TODO Remove and handle according to api response once API-691 is complete
    userAlreadyInSystem(userRecord) {
        return new Date() - new Date(userRecord.createdDateTime) > 60000;
    }

    // Sends new user data to API
    saveNewPerson(person) {
        const accountId = store.account.accountId;
        message.show('Saving new Person...', 'warning');
        api.rpc.create('User',
            {
                userBody: {
                    accounts: [{ id: accountId, addProjects: false }],
                    phoneNumber: apiPhoneNumber(person.phoneNumber),
                    emailAddress: person.emailAddress,
                    givenName: person.givenName,
                    familyName: person.familyName,
                    company: person.company
                }
            }).then(user => {
            if (this.userAlreadyInSystem(user)) {
                message.hide();
                dialogModel.append({
                    headline: 'Duplicate information found',
                    text: 'The contact information supplied matches an existing Person. Please check your form input and try again or contact support for additional assistance.',
                    onOkay: () => {
                        person.formState.isSavingNew = person.formState.highlightMissing = false;
                        m.redraw();
                    }
                });
            } else {
                person.userId = user.userId;
                person.status = user.status;

                // Save account role to API if not default
                if (person.role !== DEFAULT_API_ACCOUNT_ROLE) {
                    person.saveUserRole();
                }
                // Notify front end of complete
                this.onSaveComplete(person, accountId);
                m.redraw();
            }
        }).catch(() => {
            message.hide();
            dialogModel.append({
                headline: 'Unable to add new Person',
                text: 'There was an error trying to add the new Person. Please check your form input and try again or contact support for additional assistance.',
                onOkay: () => {
                    person.formState.isSavingNew = person.formState.highlightMissing = false;
                    m.redraw();
                }
            });
        });
    }

    /*
     * Callback to run when new user is saved successfully to API.
     * We're not going to manually add the user to the FE account list here,
     * bc it will be added via from the accountModified publish
    */
    onSaveComplete(person, accountId) {
        // Make sure we're still on the account
        if (accountId === store.account.accountId) {
            message.show('New Person added to Account.', 'success');
            this.addAccountUser(person);
            // Add to any project access lists we have cached (default to no project access)
            Object.keys(this.projectPeople).forEach(projectId =>
                this.projectPeople[projectId].addPerson(person.userId)
            );
        }
        modalModel.close();
    }

    deletePerson(userId) {
        const person = this.getPerson(userId);
        dialogModel.open({
            headline: `Remove this Person from this Account: ${store.account.name}?`,
            text: 'Please note that this operation cannot be undone. This Person will no longer be able to access any information or Projects in the Account.',
            yesClass: 'btn btn-pill btn-red',
            noText: 'Cancel',
            noClass: 'btn btn-pill btn-secondary',
            onYes: () => {
                message.show('Removing Person from Account...', 'warning');
                person.savingClass = 'removing';
                api.rpc.request([['removeAccountUser', {
                    userId,
                    accountId: store.account.accountId
                }]]).then(() => {
                    this.removeAccountUser(userId);
                    message.show('Person removed from Account.', 'success');
                    m.redraw();
                });
            }
        });
    }

    getRoleDescriptions(role) {
        return {
            viewer: [
                `Can view all Content in ${capitalize(appModel.toolbox.siteTermPlural)} they have access to.`,
                'Cannot add, edit, or delete any Content.',
                'Can view Profile information only for People in the same Company.',
                'Can add, edit, and delete their own Comments.'
            ],
            guest: [
                `Can view all Content in ${capitalize(appModel.toolbox.siteTermPlural)} to which they are invited.`,
                'Cannot add, edit, or delete any Content.',
                'Can add, edit, and delete their own Comments.'
            ],
            general: [
                `Can add new ${capitalize(appModel.toolbox.siteTermPlural)} and edit Properties for any ${capitalize(appModel.toolbox.siteTermPlural)} they added or have access to.`,
                `Can view, add, and edit Content in ${capitalize(appModel.toolbox.siteTermPlural)} they added or have access to.`,
                `Can only delete ${capitalize(appModel.toolbox.siteTermPlural)} and Content they added.`,
                'Can only view Profile information for People in the same Company.',
                'Cannot add/remove People to/from the Account or edit another Person\'s Profile information.',
                `Cannot add/remove People to/from any ${capitalize(appModel.toolbox.siteTermSingular)}.`,
                'Can add, edit, and delete their own Comments.'
            ],
            manager: [
                `Can add new ${capitalize(appModel.toolbox.siteTermPlural)} and edit Properties for any ${capitalize(appModel.toolbox.siteTermPlural)} they added or have access to.`,
                `Can view, add, and edit Content in ${capitalize(appModel.toolbox.siteTermPlural)} they added or have access to.`,
                `Can only delete ${capitalize(appModel.toolbox.siteTermPlural)} and Content they added.`,
                'Can only view Profile information for People in the same Company.',
                'Cannot add/remove People to/from the Account or edit another Person\'s Profile information.',
                `Can only add/remove People in the same Company to/from ${capitalize(appModel.toolbox.siteTermPlural)}.`,
                'Can add, edit, and delete their own Comments.'
            ],
            admin: [
                `Can view, add, edit, and delete all ${capitalize(appModel.toolbox.siteTermPlural)}, all Content, and all Comments.`,
                `Can add/remove People to/from the Account and ${capitalize(appModel.toolbox.siteTermPlural)}.`,
                'Can view Profile information for all People in the Account and can edit Company and Role for any Person.'
            ],
            owner: [
                `Can view, add, edit, and delete all ${capitalize(appModel.toolbox.siteTermPlural)}, all Content, and all Comments.`,
                `Can add/remove People to/from the Account and ${capitalize(appModel.toolbox.siteTermPlural)}.`,
                'Can view Profile information for all People in the Account and can edit Company and Role for any Person.'
            ],
            limited: [
                `Cannot add or delete ${capitalize(appModel.toolbox.siteTermPlural)}, but can edit ${capitalize(appModel.toolbox.siteTermSingular)} Properties for any ${capitalize(appModel.toolbox.siteTermPlural)} they have access to.`,
                `Can view and add Content in ${capitalize(appModel.toolbox.siteTermPlural)} they have access to.`,
                'Can only edit or delete Content they added.',
                'Can only view Profile information for People in the same Company.',
                `Cannot add/remove People to/from the Account or ${capitalize(appModel.toolbox.siteTermPlural)}; cannot edit another Person's Profile information.`,
                'Can add, edit, and delete their own Comments.'
            ],
            superadmin: []
        }[role];
    }

    // ---------- Publishing back end updates ----------

    awaitChanges() {
        // TODO: API-671 work required, new users not publishing with associated accountId.
        // publish.await({
        //     changeType: 'new',
        //     recordType: 'user',
        //     test: (change) => true,
        //     callback: user => {
        //         if (user.accountId === store.account.accountId) {
        //             this.addAccountUser(user);
        //             let projectId = router.params.projectId;
        //             // If we're viewing a project asset, add it to (the bottom of) that list as well:
        //             if (appModel.project.isMetaProject) {
        //                 projectId = assetIdToProjectId(router.params.assetId);
        //                 this.projectListOrder.push(user.userId);
        //             }
        //             if (user.projectId === projectId) {
        //                 const person = this.getPerson(user.userId);
        //                 this.addPersonToProject(user.projectId, person);
        //             }
        //         }
        //         m.redraw();
        //     },
        //     persist: true
        // });

        publish.await({
            changeType: 'modified',
            recordType: 'user',
            test: change => this.isInPeopleList(change.userId),
            callback: user => {
                const person = this.getPerson(user.userId);
                person.syncUserData(user);
                m.redraw();
            },
            persist: true
        });

        // TODO: API-671 work required, removed users not publishing.
        // publish.await({
        //     changeType: 'deleted',
        //     recordType: 'user',
        //     test: change => this.isInPeopleList(change.userId),
        //     callback: user => {
        //         this.removeAccountUser(user.userId);
        //         m.redraw();
        //     },
        //     persist: true
        // });

    }
}


export default new peopleModel();
