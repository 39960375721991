import store from 'util/data/store';
import peopleModel from 'models/people/people-model';
import appModel from 'models/app-model';
import siteModel from 'models/site-model';
import capitalize from 'util/data/capitalize';
import tableModel from 'models/table/table-model';
import debounce from 'util/events/debounce';
import formatDate from 'legacy/util/date/format-date';
import popupModel from 'models/popover-model';
import Polygon from 'util/draw/polygon';
import isMetric from 'util/numbers/is-metric';
import filterUtil from 'util/table/filter-util';
import filterConstants from 'constants/models/table/filter-constants';
import placeBoundaryFilterModel from 'models/place-boundary-filter-model';
import featureListManager from 'managers/feature-list-manager';
import message from 'views/toast-message/toast-message';
import {getNormalizedKey} from 'util/events/get-normalized-key';

/**
 * CommonFilterModel: For all common filters.
 */
const CUSTOM_DATE_RANGE_INDEX = 5;

// Field Names
const NAME = 'name';
const CATEGORY = 'category';
const DATE = 'createdDateTime';
const UPDATED = 'updatedDateTime';
const TYPE = 'type';
const CONTENT_TYPE = 'contentType';
const ADDED_BY = 'addedBy';
const LAST_UPDATED_BY = 'lastUpdatedBy';
const PLACES = 'location';
const UNEARTH_ID = 'unearthId';
const LINKS = 'links';
const BOUNDARY = 'boundary';

const {mapFilterOpts} = filterConstants;

class CommonFilterModel {

    constructor() {
        this.setNameQueryString = debounce(this._setNameQueryString.bind(this), 400);
        this.reset();
        this.listenForKeyDown = (e) => this._listenForKeyDown(e);
    }

    reset() {
        this.checkedUsers = {};
        this.checkedUsersUpdatedBy = {};
        this.checkedAssetTypes = {};
        this.checkedToolGroups = {};
        this.checkedPlaces = {};
        this.checkedLevels = {};
        this.addedDateRange = {};
        this.updatedDateRange = {};
        this.selectedBoundaryIndex = mapFilterOpts.NONE;
        this.searchArea = undefined;
        this.selectedLinksIndex = 0;
        this.selectedDateRangeIndex = 0;
        this.updatedDateRangeIndex = 0;
        this.nameQueryString = '';
        this.toolGroupAssetTypes = {};
        delete this.assetIdMatch;
        this.sortDisabled = {
            [NAME]: true,
            [CATEGORY]: true,
            [ADDED_BY]: true,
            [LAST_UPDATED_BY]: true,
            [PLACES]: true,
            [LINKS]: true
        };
        this.displayAs = {
            [NAME]: 'Name',
            [CATEGORY]: 'Category',
            [DATE]: 'Added Date/Time',
            'addedDateTime': 'Added Date/Time',
            'lastUpdated': 'Last Updated',
            'captureDateTime': 'Last Updated',
            [UPDATED]: 'Last Updated',
            [TYPE]: 'Type',
            [CONTENT_TYPE]: 'Type',
            [ADDED_BY]: 'Added By',
            [LAST_UPDATED_BY]: 'Last Updated By',
            [PLACES]: 'Location',
            'places': 'Location',
            [UNEARTH_ID]: 'Unearth ID',
            [LINKS]: 'Links'
        };
        this.isFilteredFn = {
            [NAME]: () => !!this.nameQueryString,
            [CATEGORY]: () => !this.areAllAssetCategoriesChecked().isAllSelected,
            [DATE]: () => this.selectedDateRangeIndex !== 0,
            [UPDATED]: () => this.updatedDateRangeIndex !== 0,
            [TYPE]: () => !this.areAllAssetTypesChecked().isAllSelected,
            [CONTENT_TYPE]: () => !this.areAllAssetTypesChecked().isAllSelected,
            [ADDED_BY]: () => !this.areAllUserIdsChecked().isAllSelected,
            [LAST_UPDATED_BY]: () => !this.areAllUserIdsCheckedUpdatedBy().isAllSelected,
            [BOUNDARY]: () => !!this.selectedBoundaryIndex,
            [UNEARTH_ID]: () => this.assetIdMatch && this.assetIdMatch.length > 0,
            [LINKS]: () => this.selectedLinksIndex !== 0
        };
        this._nameControlFilters = null;
        this._assetTypeIdInDefault = null;
    }

    init() {
        this.switchAllPlacesAndLevels(true, true);
        this.switchAllAssetTypes(true, true);
        this.initAddedByFilterList(true);
        this.initUpdatedByFilterList(true);
    }

    select(key, value) {
        this[key] = value;
    }

    check(listKey, option, isOn = true) {
        this[listKey][option] = isOn;
    }

    filtersChanged(skipFetch) {
        if (this.onFiltersChanged) {
            return this.onFiltersChanged(skipFetch);
        }
        return tableModel.filtersChanged(skipFetch);
    }

    isSortDisabled(fieldName) {
        return this.sortDisabled[fieldName] || false;
    }

    getState(opts = {}) {
        let searchArea = this.searchArea;
        let selectedBoundaryIndex = this.selectedBoundaryIndex > mapFilterOpts.TAGGED_PLACE ? undefined : this.selectedBoundaryIndex;
        if (selectedBoundaryIndex === mapFilterOpts.IN_VIEW && opts.normalizeBoundaryIndex) {
            selectedBoundaryIndex = mapFilterOpts.DRAW_BOUNDARY; // If requested, change the index from "in view" option to "draw search boundary" option, so that we can return back to this boundary later.
            searchArea = searchArea || {}; // init at least an empty object in case undefined;
            searchArea.geometry = siteModel.map.getBoundsPolygon();
        }
        if (selectedBoundaryIndex === mapFilterOpts.TAGGED_PLACE) {
            searchArea = searchArea || {};
        }
        return {
            nameQueryString: this.nameQueryString,
            selectedBoundaryIndex,
            selectedLinksIndex: this.selectedLinksIndex,
            checkedUsers: this.checkedUsers,
            checkedUsersUpdatedBy: this.checkedUsersUpdatedBy,
            checkedToolGroups: this.checkedToolGroups,
            checkedPlaces: this.checkedPlaces,
            checkedLevels: this.checkedLevels,
            checkedAssetTypes: this.checkedAssetTypes,
            selectedDateRangeIndex: this.selectedDateRangeIndex,
            updatedDateRangeIndex: this.updatedDateRangeIndex,
            addedDateRange: Object.assign({}, this.addedDateRange),
            updatedDateRange: Object.assign({}, this.updatedDateRange),
            assetIdMatch: this.assetIdMatch,
            searchArea
        };
    }

    resetAllFilters() {
        this.reset();
        this.removeSearchArea();
        this.init();
    }

    mergeFromSaved(settings) {
        if (settings.checkedUsers) {
            Object.keys(settings.checkedUsers).forEach(userId => {
                this.check('checkedUsers', userId, settings.checkedUsers[userId]);
            });
        }

        if (settings.checkedUsersUpdatedBy) {
            Object.keys(settings.checkedUsersUpdatedBy).forEach(userId => {
                this.check('checkedUsersUpdatedBy', userId, settings.checkedUsersUpdatedBy[userId]);
            });
        }

        if (settings.checkedAssetTypes) {
            Object.keys(settings.checkedAssetTypes).forEach(assetTypeId => {
                if (!appModel.user.permissions.hiddenAssetTypes[assetTypeId]) {
                    this.check('checkedAssetTypes', assetTypeId, settings.checkedAssetTypes[assetTypeId]);
                }
            });
        }

        if (settings.checkedToolGroups) {
            Object.keys(settings.checkedToolGroups).forEach(toolGroupId => {
                if (appModel.toolbox.toolGroups.toolGroupId) {
                    this.check('checkedToolGroups', toolGroupId, settings.checkedAssetTypes[toolGroupId]);
                }
            });
        }

        Object.keys(this.checkedToolGroups).forEach(toolGroupId => {
            let areAnyChecked = false;
            const assetTypes = this.getToolGroupAssetTypes(toolGroupId);
            for (const assetType of assetTypes) {
                if (this.checkedAssetTypes[assetType.assetTypeId]) {
                    areAnyChecked = true;
                    break;
                }
            }
            this.checkedToolGroups[toolGroupId] = areAnyChecked;
        });
        
    }

    setState(stateObject) {
        this.mergeFromSaved(stateObject);
        this.checkedPlaces = Object.assign(this.checkedPlaces, stateObject.checkedPlaces);
        this.checkedLevels = Object.assign(this.checkedLevels, stateObject.checkedLevels);
        this.addedDateRange = Object.assign({}, stateObject.addedDateRange);
        this.updatedDateRange = Object.assign({}, stateObject.updatedDateRange);
        this.searchArea = Object.assign({}, stateObject.searchArea);
        if (this.searchArea && !this.searchArea.geometry) {
            this.searchArea = undefined;
        }
        this.nameQueryString = stateObject.nameQueryString;
        this.selectedBoundaryIndex = stateObject.selectedBoundaryIndex;
        this.selectedLinksIndex = stateObject.selectedLinksIndex;
        this.selectedDateRangeIndex = stateObject.selectedDateRangeIndex;
        this.updatedDateRangeIndex = stateObject.updatedDateRangeIndex;
    }

    isFiltered(fieldName) {
        const isFilteredFn = this.isFilteredFn[fieldName];
        if (isFilteredFn) {
            return isFilteredFn();
        }
        return false;
    }

    displayName(fieldName) {
        return this.displayAs[fieldName] || capitalize(fieldName);
    }


    onNewContent(assetId) {
        const asset = store.assets[assetId];
        const assetTypeId = asset.assetTypeId;
        const authorId = asset.authorId;
        if (tableModel.assetTypeCounts[assetTypeId] === undefined) {
            tableModel.assetTypeCounts[assetTypeId] = 1;
        } else {
            tableModel.assetTypeCounts[assetTypeId] += 1;
        }
        this.addNewUser(authorId);
        tableModel.addedByCounts[authorId].count += 1;
        tableModel.calculateCategoryCounts();
    }

    onDeletedContent(assetId) {
        const authorId = store.assets[assetId].authorId;
        const assetTypeId = store.assets[assetId].assetTypeId;
        const modifierId = store.assets[assetId].modifierId;
        if (tableModel.assetTypeCounts[assetTypeId]) {
            tableModel.assetTypeCounts[assetTypeId] = tableModel.assetTypeCounts[assetTypeId] - 1;
        }
        if (tableModel.addedByCounts[authorId]) {
            tableModel.addedByCounts[authorId].count = tableModel.addedByCounts[authorId].count - 1;
        }
        if (tableModel.updatedByCounts[modifierId]) {
            tableModel.updatedByCounts[modifierId].count = tableModel.updatedByCounts[modifierId].count - 1;
        }
    }

    get toolGroups() {
        return Object.values(appModel.toolbox.toolGroups);
    }

    getToolGroupAssetTypes(toolGroupId) {
        const toolGroup = appModel.toolbox.toolGroups[toolGroupId];
        if (!toolGroup) {
            return [];
        }
        let tools = toolGroup.tools;
        // Special case for file asset types that should show on links tab of meta project but not in other tool lists
        if (appModel.project.isMetaProject && appModel.isOnTab('Links') && toolGroup.name === 'Files') {
            tools = Object.values(appModel.toolbox.linkTools);
        }
        return tools.map((tool) => tool.assetForm.assetType);
    }

    getCommonPropertyCount() {
        let i = 5;
        if (appModel.toolbox.hasMultipleGroups) {
            ++i;
        }
        i += tableModel.getSharedColumnCount();

        return i;
    }

    areAllAssetTypesChecked() {
        let isAllSelected = true;
        let isAllDeselected = true;
        for (const toolGroup of this.toolGroups) {
            for (const assetType of this.getToolGroupAssetTypes(toolGroup.toolGroupId)) {
                if (this.checkedAssetTypes[assetType.assetTypeId] || this.checkedAssetTypes[assetType.assetTypeId] === undefined) {
                    isAllDeselected = false;
                } else {
                    isAllSelected = false;
                }
            }
        }
        return {isAllSelected, isAllDeselected};
    }

    areAllAssetCategoriesChecked() {
        let isAllSelected = true;
        let isAllDeselected = true;
        for (const checked of Object.values(this.checkedToolGroups)) {
            if (checked) {
                isAllDeselected = false;
            } else {
                isAllSelected = false;
            }
        }
        return {isAllSelected, isAllDeselected};
    }

    areAllUserIdsCheckedUpdatedBy() {
        let isAllSelected = true;
        let isAllDeselected = true;
        for (const userId of Object.keys(this.checkedUsersUpdatedBy)) {
            if (this.checkedUsersUpdatedBy[userId]) {
                isAllDeselected = false;
            } else {
                isAllSelected = false;
            }
        }
        return {isAllSelected, isAllDeselected};
    }

    areAllUserIdsChecked() {
        let isAllSelected = true;
        let isAllDeselected = true;
        for (const userId of Object.keys(this.checkedUsers)) {
            if (this.checkedUsers[userId]) {
                isAllDeselected = false;
            } else {
                isAllSelected = false;
            }
        }
        return {isAllSelected, isAllDeselected};
    }

    areAllPlacesAndLevelsChecked() {
        let isAllSelected = true;
        let isAllDeselected = true;
        const places = Object.values(store.places);
        for (const place of places) {
            if (this.checkedPlaces[place.placeId]) {
                isAllDeselected = false;
            } else {
                isAllSelected = false;
            }
            const levels = place.levels.items || [];
            for (const level of levels.items || []) {
                if (this.checkedLevels[level.levelId]) {
                    isAllDeselected = false;
                } else {
                    isAllSelected = false;
                }
            }
        }
        return {isAllSelected, isAllDeselected};
    }

    _setNameQueryString(query) {
        this.nameQueryString = query;
        this.filtersChanged();
    }

    /**
     Creates an array of filters checking that control against the value passed.
     Returns the array of filters, which can then be used within a listContent request.

     example:
        generateNameFiltersForQuery({in: [null, '']})
     returned:
        [{properties.Name: {in: [null, '']}, assetTypeId: '29348324'},
         {properties.Address: {in: [null, '']}, assetTypeId: '988745465'}, ...]
    */
    generateNameFiltersForQuery(filterValue) {
        const filters = [];
        const nameControlFilters = this.getNameControlFilters();
        Object.keys(nameControlFilters).forEach(nameControlLabel => {
            const nameFilterKey = `properties.${nameControlLabel}`;
            filters.push({
                [nameFilterKey]: filterValue,
                assetTypeIdIn: nameControlFilters[nameControlLabel]
            });
        });
        return filters;
    }

    /**
     Return a mapping of all nameControls to an array of asset types that share it.
     Cache it on the model for reuse within this session.
    */
    getNameControlFilters() {
        if (!this._nameControlFilters) {
            this._nameControlFilters = {};
            const assetTypes = Object.values(store.assetTypes);
            assetTypes.forEach(assetType => {
                const nameControlLabel = store.assetTypeIdToNameControl[assetType.assetTypeId];
                if (nameControlLabel) {
                    if (this._nameControlFilters[nameControlLabel]) {
                        this._nameControlFilters[nameControlLabel].push(assetType.assetTypeId);
                    } else {
                        this._nameControlFilters[nameControlLabel] = [assetType.assetTypeId];
                    }
                }
            });
        }
        return this._nameControlFilters;
    }

    setNameQueryFilters(filters) {
        if (!this.nameQueryString) {
            return;
        }

        const query = this.nameQueryString.toLowerCase().replace(/\s+/g, '%');
        const longEnoughForStringMatch = query.length >= 2; // Ignore 1 letter queries.

        // Loop through all asset types checking for two routes to match:
        // 1) Via name control label value (map out all name controls per asset type against query)
        const assetNameFilters = this.generateNameFiltersForQuery({ilike: `%${query}%`});
        // 2) Via asset type name that matches, and no custom name value provided
        const assetTypeNameFilters = [];

        if (longEnoughForStringMatch) {
            const assetTypes = Object.values(store.assetTypes);
            assetTypes.forEach(assetType => {
                if (assetType.name.toLowerCase().includes(query)) {
                    const nameControlLabel = store.assetTypeIdToNameControl[assetType.assetTypeId];
                    if (nameControlLabel) {
                        // Only include asset type name matches if the asset's name control is empty:
                        assetTypeNameFilters.push({
                            [`properties.${nameControlLabel}`]: {eq: null},
                            assetTypeId: assetType.assetTypeId
                        }, {
                            [`properties.${nameControlLabel}`]: {eq: ''},
                            assetTypeId: assetType.assetTypeId
                        });
                    } else {
                        // And include if there is no name control for the asset type
                        assetTypeNameFilters.push({assetTypeId: assetType.assetTypeId});
                    }
                }
            });
        }
        filters.push(...assetNameFilters, ...assetTypeNameFilters);
    }

    toggleCheckedUsersUpdatedBy(userId) {
        this.check('checkedUsersUpdatedBy', userId, !this.checkedUsersUpdatedBy[userId]);
        const skipFetch = !tableModel.updatedByCounts[userId] || !tableModel.updatedByCounts[userId].count;
        this.filtersChanged(skipFetch);
    }

    toggleCheckedUsers(userId) {
        this.check('checkedUsers', userId, !this.checkedUsers[userId]);
        const skipFetch = !tableModel.addedByCounts[userId] || !tableModel.addedByCounts[userId].count;
        this.filtersChanged(skipFetch);
    }

    toggleCheckedToolGroups(toolGroupId) {
        this.check('checkedToolGroups', toolGroupId, !this.checkedToolGroups[toolGroupId]);
        const assetTypes = this.getToolGroupAssetTypes(toolGroupId);
        assetTypes.forEach(assetType => {
            this.check('checkedAssetTypes', assetType.assetTypeId, this.checkedToolGroups[toolGroupId]);
        });
        const skipFetch = !tableModel.categoryCounts[toolGroupId];
        this.filtersChanged(skipFetch);
    }

    toggleAssetType(assetTypeId, skipFetch) {
        this.check('checkedAssetTypes', assetTypeId, !this.checkedAssetTypes[assetTypeId]);
        const toolGroupId = store.assetTypeIdToToolGroupId[assetTypeId];
        if (this.checkedAssetTypes[assetTypeId]) {
            this.check('checkedToolGroups', toolGroupId, true);
        } else {
            let areAnyChecked = false;
            const assetTypes = this.getToolGroupAssetTypes(toolGroupId);
            for (const assetType of assetTypes) {
                if (this.checkedAssetTypes[assetType.assetTypeId]) {
                    areAnyChecked = true;
                    break;
                }
            }
            if (!areAnyChecked) {
                this.check('checkedToolGroups', toolGroupId, false);
            }
        }
        if (skipFetch) {
            return;
        }
        const noAssetsChanged = !tableModel.assetTypeCounts[assetTypeId];
        this.filtersChanged(noAssetsChanged);
    }

    turnOffAllAddedByExcept(authorId) {
        this.switchAllAddedBy(false, true);
        this.check('checkedUsers', authorId, true);
        this.filtersChanged();
    }

    turnOffAllUpdatedByExcept(modifierId) {
        this.switchAllUpdatedBy(false, true);
        this.check('checkedUsersUpdatedBy', modifierId, true);
        this.filtersChanged();
    }

    turnOffAllToolGroupsExcept(toolGroupId) {
        this.switchAllAssetTypes(false, true);
        this.toggleCheckedToolGroups(toolGroupId);
    }

    turnOffAllAssetTypesExcept(assetTypeId, skipFetch) {
        this.switchAllAssetTypes(false, true);
        this.toggleAssetType(assetTypeId, skipFetch);
    }

    turnOffAllAssetTypesExceptMultiple(assetTypeIds, skipFetch) {
        this.switchAllAssetTypes(false, true);
        assetTypeIds.forEach(id => {
            this.toggleAssetType(id, skipFetch);
        });
    }

    turnOffAllPlacesExcept(placeId) {
        this.switchAllPlacesAndLevels(false, true);
        this.check('checkedPlaces', placeId, true);
        this.filtersChanged();
    }

    turnOffAllLevelsExcept(levelId) {
        this.switchAllPlacesAndLevels(false, true);
        this.check('checkedLevels', levelId, true);
        this.filtersChanged();
    }

    togglePlace(placeId) {
        this.check('checkedPlaces', placeId, !this.checkedPlaces[placeId]);
        this.filtersChanged();
    }

    toggleLevel(levelId) {
        this.check('checkedLevels', levelId, !this.checkedLevels[levelId]);
        this.filtersChanged();
    }

    switchAllAssetTypes(isOn, skipFetch = false) {
        this.toolGroups.forEach(({toolGroupId}) => {
            this.getToolGroupAssetTypes(toolGroupId).forEach(({assetTypeId}) => {
                this.check('checkedAssetTypes', assetTypeId, isOn);
            });
            this.check('checkedToolGroups', toolGroupId, isOn);
        });
        if (skipFetch) {
            return;
        }
        this.filtersChanged();
    }

    async initAddedByFilterList(isOn) {
        // Added by (project users)
        const projectUsers = await peopleModel.getControlOptions() || [];
        projectUsers.forEach((userId) => {
            this.check('checkedUsers', userId, isOn);
        });
        // Added by (users who have already added assets (ie, super admin or deleted users, eg))
        Object.keys(tableModel.addedByCounts).forEach((userId) => {
            this.check('checkedUsers', userId, isOn);
        });
    }

    async initUpdatedByFilterList(isOn) {
        // Updated by (project users)
        const projectUsers = await peopleModel.getControlOptions() || [];
        projectUsers.forEach((userId) => {
            this.check('checkedUsersUpdatedBy', userId, isOn);
        });
        // Updated by (users who have already added assets (ie, super admin or deleted users, eg))
        Object.keys(tableModel.updatedByCounts).forEach((userId) => {
            this.check('checkedUsersUpdatedBy', userId, isOn);
        });
    }

    switchAllAddedBy(isOn, skipFetch = false) {
        // Added by (users who have already added assets (ie, super admin or deleted users, eg))
        Object.keys(this.checkedUsers).forEach((userId) => {
            this.check('checkedUsers', userId, isOn);
        });
        if (skipFetch) {
            return;
        }
        this.filtersChanged();
    }

    switchAllUpdatedBy(isOn, skipFetch = false) {
        // Updated by (users who have already added assets (ie, super admin or deleted users, eg))
        Object.keys(this.checkedUsersUpdatedBy).forEach((userId) => {
            this.check('checkedUsersUpdatedBy', userId, isOn);
        });
        if (skipFetch) {
            return;
        }
        this.filtersChanged();
    }

    switchAllPlacesAndLevels(isOn, skipFetch = false) {
        const places = Object.values(store.places);
        for (const place of places) {
            this.check('checkedPlaces', place.placeId, isOn);
            const levels = place.levels;
            for (const level of levels.items || []) {
                this.check('checkedLevels', level.levelId, isOn);
            }
        }
        if (skipFetch) {
            return;
        }
        this.filtersChanged();
    }

    selectDateRangeRadio(i) {
        if (this.selectedDateRangeIndex === i) {
            return;
        }
        this.select('selectedDateRangeIndex', i);
        this.filtersChanged();
    }

    selectUpdatedRange(i) {
        if (this.updatedDateRangeIndex === i) {
            return;
        }
        this.select('updatedDateRangeIndex', i);
        this.filtersChanged();
    }

    rangeUpdated() {
        if (this.selectedDateRangeIndex === CUSTOM_DATE_RANGE_INDEX || this.updatedDateRangeIndex === CUSTOM_DATE_RANGE_INDEX) {
            this.filtersChanged();
        }
    }

    removeSearchArea() {
        // Clean up the searchArea feature if the searchArea filter was deselected
        const source = siteModel.map.getSource('searchArea');
        if (source && this.selectedBoundaryIndex !== mapFilterOpts.DRAW_BOUNDARY) {
            source._data.features = [];
            source.setData(source._data);
        }
    }

    addSearchArea() {
        if (this.searchArea && this.selectedBoundaryIndex === mapFilterOpts.DRAW_BOUNDARY) {
            const source = this.addSearchSource(siteModel.map);
            source._data.features = [this.searchArea];
            source.setData(source._data);
        }
    }

    cancelDrawSearchArea() {
        message.hide();
        window.removeEventListener('keydown', this.listenForKeyDown);
        this.draw.stop();
        appModel.stopDrawing();
        this.select('selectedBoundaryIndex', 0);
        this.filtersChanged();
        if (tableModel.isCollapsed) {
            tableModel.sideBarToggle();
        }
    }

    addSearchSource(map, color = '#70bfbf') {
        let source = map.getSource('searchArea');
        if (!source) {
            map.addSource('searchArea', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: []
                }
            });
            source = map.getSource('searchArea');
            map.addLayer({
                id: 'searchArea',
                source: 'searchArea',
                type: 'line',
                paint: {
                    'line-width': 3,
                    'line-color': color
                }
            });
        }
        return source;
    }

    _listenForKeyDown(e) {
        const key = getNormalizedKey(e.key);
        if (key === 'Escape') {
            this.cancelDrawSearchArea();
        }
    }

    drawSearchArea() {
        message.show(<span>Tap the map to draw a boundary filter. <span onclick={() => this.cancelDrawSearchArea()} class="btn btn-pill btn-secondary btn-smallest">Cancel</span></span>, 'success boundary-filter-message', true);
        window.addEventListener('keydown', this.listenForKeyDown);
        appModel.startDrawing();
        popupModel.close();
        tableModel.sideBarToggle();
        const color = '#70bfbf',
            map = siteModel.map;
        const source = this.addSearchSource(map, color);
        source._data.features = [];
        source.setData(source._data);
        this.draw = new Polygon({
            map,
            source,
            metric: isMetric(),
            color
        });
        this.draw.create();
        this.draw.onComplete = feature => {
            window.removeEventListener('keydown', this.listenForKeyDown);
            message.hide();
            this.searchArea = feature;
            source._data.features = [feature];
            source.setData(source._data);
            tableModel.sideBarToggle();
            this.filtersChanged();
            appModel.stopDrawing();
        };
        this.draw.onVertexChanged = () => this.filtersChanged();
    }

    selectBoundaryRadio(i, forceRefetch = false) {
        if (i === mapFilterOpts.DRAW_BOUNDARY) {
            this.select('selectedBoundaryIndex', i);
            this.drawSearchArea();
        } else if (i === mapFilterOpts.TAGGED_PLACE) {
            this.select('selectedBoundaryIndex', i);
            if (placeBoundaryFilterModel.selectedItems.length) {
                tableModel.projectView.common.filtersChanged();
            }
        } else if (this.selectedBoundaryIndex !== i || forceRefetch) {
            this.removeSearchArea();
            this.select('selectedBoundaryIndex', i);
            this.filtersChanged();
        }
    }

    selectLinksRadio(i) {
        if (this.selectedLinksIndex !== i) {
            this.select('selectedLinksIndex', i);
            this.filtersChanged();
        }
    }

    assetIdInput(value) {
        if (value === '' || value === undefined) {
            delete this.assetIdMatch;
            this.filtersChanged();
            return;
        }
        this.assetIdMatch = value.trim();
        if (this.assetIdMatch.length === 20) {
            this.filtersChanged();
        }
    }

    addNewUser(authorId) {
        if (!tableModel.addedByCounts.hasOwnProperty(authorId)) {
            const user = peopleModel.getPerson(authorId);
            tableModel.addedByCounts[authorId] = {
                name: peopleModel.displayName(user),
                value: authorId,
                count: 0
            };
        }
        const addToggledOn = !this.isFiltered('addedBy');
        if (!this.checkedUsers.hasOwnProperty(authorId)) {
            this.check('checkedUsers', authorId, addToggledOn);
        }
        if (!this.checkedUsersUpdatedBy.hasOwnProperty(authorId)) {
            this.check('checkedUsersUpdatedBy', authorId, addToggledOn);
        }
    }

    createAssetTypeIdFilter() {
        const allAssetTypeIds = filterUtil.getAssetTypeIdInDefault();
        const assetTypeIdIn = [], assetTypeIds = {assetTypeIdIn};
    
        allAssetTypeIds.forEach((assetTypeId) => {
            if (this.checkedAssetTypes[assetTypeId] !== false) {
                assetTypeIdIn.push(assetTypeId);
            }
        });

        return assetTypeIds;
    }

    configureAddedByFilter(args) {
        let removeAuthorIdIn = true;
        args.authorIdIn = args.authorIdIn || [];
        Object.keys(this.checkedUsers).forEach(userId => {
            if (this.checkedUsers[userId]) {
                args.authorIdIn.push(userId);
            } else {
                removeAuthorIdIn = false;
            }
        });
        // No authors were specified, so remove the entire arg:
        if (removeAuthorIdIn) {
            delete args.authorIdIn;
        }
        return args;
    }

    configureUpdatedByFilter(args) {
        let removeModifierIdIn = true;
        args.modifierIdIn = args.modifierIdIn || [];
        Object.keys(this.checkedUsersUpdatedBy).forEach(userId => {
            if (this.checkedUsersUpdatedBy[userId]) {
                args.modifierIdIn.push(userId);
            } else {
                removeModifierIdIn = false;
            }
        });
        // No modifiers were specified, so remove the entire arg:
        if (removeModifierIdIn) {
            delete args.modifierIdIn;
        }
        return args;
    }

    configureDateArg(dateRangeIndex, dateType, rangeModel, args) {
        const dateGte = dateType + 'Gte',
            dateLte = dateType + 'Lte';
        if (dateRangeIndex === undefined) {
            args[dateGte] = undefined;
            args[dateLte] = undefined;
        } else if (dateRangeIndex === 0) {
            args[dateGte] = undefined;
        } else if (dateRangeIndex < filterConstants.daysByIndex.length) {
            args[dateGte] = formatDate.getDaysAgoForPython(filterConstants.daysByIndex[dateRangeIndex]);
        } else if (rangeModel.fromDate && rangeModel.toDate) {
            args[dateGte] = formatDate.forPython(new Date(rangeModel.fromDate));
            args[dateLte] = formatDate.forPython(new Date(rangeModel.toDate));
        }
    }

    // Adds the filter for when the user draws a polygon to grab assets from.
    configureGeometryFilter(args) {
        switch (this.selectedBoundaryIndex) {
        case mapFilterOpts.NONE:
            args.geometry = undefined;
            break;
        case mapFilterOpts.MAPPED:
            args.geometry = {ne: null};
            break;
        case mapFilterOpts.UNMAPPED:
            args.geometry = {eq: null};
            break;
        case mapFilterOpts.IN_VIEW:
            args.geometry = {
                intersects: siteModel.map.getBoundsPolygon()
            };
            break;
        case mapFilterOpts.DRAW_BOUNDARY:
            if (this.searchArea) {
                args.geometry = {
                    intersects: this.searchArea.geometry
                };
            }
            break;
        case mapFilterOpts.TAGGED_PLACE:
            const geo = [];
            const places = [];
            if (placeBoundaryFilterModel.selectedItems.length > 1) {
                placeBoundaryFilterModel.selectedItems.forEach(placeId => {
                    const place = store.places[placeId];
                    const placeGeo = featureListManager.getPlaceFeature(place);
                    places.push(placeId);
                    geo.push(placeGeo.geometry.coordinates);
                });
                const multipoly = {
                    'type': 'MultiPolygon',
                    'coordinates': geo
                };
                this.searchArea = {
                    type: 'Feature',
                    ids: places,
                    geometry: multipoly,
                    properties: {
                        _placeIds: [places]
                    }
                };
                args.geometry = {
                    intersects: multipoly
                };
            } else if (placeBoundaryFilterModel.selectedItems.length === 1) {
                const place = store.places[placeBoundaryFilterModel.selectedItems[0]];
                const placeGeo = featureListManager.getPlaceFeature(place);
                this.searchArea = placeGeo;
                args.geometry = {
                    intersects: placeGeo.geometry
                };
            } else if (this.searchArea) { // this is in the case of refresh and we dont have the list of placeBoundaryFilterModel.selectedItems to pull from, we will pull from the Search Area we set
                if (this.searchArea.id) {
                    placeBoundaryFilterModel.selectedItems.push(this.searchArea.id);
                } else if (this.searchArea.ids) {
                    this.searchArea.ids.forEach(id => {
                        placeBoundaryFilterModel.selectedItems.push(id);
                    });
                }
                args.geometry = {
                    intersects: this.searchArea.geometry
                };
            }
            break;
        }
        return args;
    }

    getArgs(args = {}) {
        this.configureAddedByFilter(args);
        this.configureUpdatedByFilter(args);
        this.configureDateArg(this.selectedDateRangeIndex, 'createdDateTime', this.addedDateRange, args);
        this.configureDateArg(this.updatedDateRangeIndex, 'updatedDateTime', this.updatedDateRange, args);
        this.configureGeometryFilter(args);
        return args;
    }

    getLinkCountFilter() {
        let filter;
        if (this.selectedLinksIndex === 1) {
            filter = {linkIds: null};
        } else if (this.selectedLinksIndex === 2) {
            filter = {linkIdsNe: null};
        }
        return filter;
    }

    doesMatchFilters(assetId) {
        const asset = store.assets[assetId];
        if (!asset) {
            return false;
        }

        // Tool level permissions check
        if (appModel.user.permissions.hiddenAssetTypes[asset.assetTypeId]) {
            return false;
        }

        // Type filter
        if (this.checkedAssetTypes.hasOwnProperty(asset.assetTypeId) && this.checkedAssetTypes[asset.assetTypeId] !== true) {
            return false;
        }
        // Added By filter
        if (this.checkedUsers.hasOwnProperty(asset.authorId) && this.checkedUsers[asset.authorId] !== true) {
            return false;
        }

        // Updated By filter
        if (this.checkedUsersUpdatedBy.hasOwnProperty(asset.modifierId) && this.checkedUsersUpdatedBy[asset.modifierId] !== true) {
            return false;
        }

        // Category filter
        if (asset.attributes.toolGroupIds
            && asset.attributes.toolGroupIds.length
            && this.checkedToolGroups.hasOwnProperty(asset.attributes.toolGroupIds[0]) && !this.checkedToolGroups[asset.attributes.toolGroupIds[0]]) {
            return false;
        }
        // Added Date filter
        if (!filterUtil.doesMatchDateFilter(new Date(asset.createdDateTime), this.selectedDateRangeIndex, this.addedDateRange)) {
            return false;
        }
        // Last Updated Date filter
        if (!filterUtil.doesMatchDateFilter(new Date(asset.updatedDateTime), this.updatedDateRangeIndex, this.updatedDateRange)) {
            return false;
        }

        if (this.assetIdMatch && assetId !== this.assetIdMatch) {
            return false;
        }

        // Location
        if (this.selectedBoundaryIndex) {

            const featureGeometry = asset.geometry;
            // In View filter
            if (this.selectedBoundaryIndex === mapFilterOpts.IN_VIEW) {
                if (!featureGeometry || !filterUtil.intersects(featureGeometry, siteModel.map.getBoundsPolygon())) {
                    return false;
                }
                // Draw Search Area filter
            } else if (this.searchArea) {
                if (!featureGeometry || !filterUtil.intersects(featureGeometry, this.searchArea)) {
                    return false;
                }
            }
        }
        return true;
    }

}

export default CommonFilterModel;
