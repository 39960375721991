import constants from 'util/data/constants';
import store from 'util/data/store';
import appModel from 'models/app-model';
import {displayPhoneNumber} from 'util/data/phone-number';
import debouncedAPICall from 'util/network/debounced-api-call';
import {permissionLevel} from 'util/users/permissions';
import debounce from 'util/events/debounce';
import dialogModel from 'models/dialog-model';
import api from 'legacy/util/api/api';

const MISSING_DATA = '--';

class PersonModel {

    constructor(user = {}) {
        this.syncUserData(user);
        this.role = user.role;
        this.isVisible = Object.hasOwnProperty(user.isVisible) ? user.isVisible : true;
        this.isNewUser = user.userId === undefined;
        this.status = user.status || 'new';
        this.projects = {}; // ProjectIds the user has access to
        this.formState = {
            isSaving: false,
            autoSaving: {},
            isSavingNew: false,
            highlightInvalid: false
        };
        this.lastLogin = undefined;
        this.savingClass = ''; // If we need to freeze the user for removing, modifying, updating, etc
        this.saveToApi = debounce(this.saveToApi.bind(this), 400);
    }

    get isSelf() {
        if (!this.userId) {
            return false;
        }
        return appModel.user.userId === this.userId;
    }

    setUserLoginData(id) {
        if (this.isNewUser) {
            this.lastLogin = null;
            return this.lastLogin;
        } 
        return api.rpc.request([['listUsers', {
            userId: id,
            limit: 1}]]).then(async user => {
            const result = await Object.values(user)[0];
            if (!result || !result.lastConnectedDateTime ) {
                return;
            }
            this.lastLogin = result.lastConnectedDateTime;
            m.redraw();
        });
    }

    syncUserData(user) {
        const givenName = user.givenName || this.givenName;
        const familyName = user.familyName || this.familyName;
        const emailAddress = user.emailAddress || this.emailAddress;
        const phoneNumber = user.phoneNumber || this.phoneNumber;
        const company = user.company || this.company;

        Object.assign(this, {
            userId: user.userId,
            createdDateTime: user.createdDateTime,
            updatedDateTime: user.updatedDateTime,
            givenName,
            familyName,
            emailAddress,
            phoneNumber,
            company
        });
    }

    userCanEdit(property = '', user = appModel.user) {
        const userRank = permissionLevel(user.role);
        const personRank = permissionLevel(this.role);
        // The person is currently being removed from the account, no further changes can occur
        if (this.savingClass === 'removing') {
            return false;
        }
        // Role only editable by admins or above
        if (property === 'role' || property === 'company') {
            return user.isSuperAdmin || user.isAccountAdmin ? true : false;
        }

        if (property === 'access') {
            // Cant remove project access from project owners, admins, or self (in case of managers)
            if (this.role === 'owner' || this.role === 'admin' || this.isSelf) {
                return false;
            }

            return userRank >= constants.permissionsRanks.ACCESS_WRITABLE_MIN && userRank >= personRank;
        }

        // Anything beyond access (like personal profile details) editable only by self (unless new user)
        return this.isSelf || this.isNewUser;
    }

    isContentCreator(assetId) {
        const record = store.assets[assetId];
        return this.isRecordCreator(record);
    }

    isRecordCreator(record) {
        const authorId = record ? record.authorId || record.creatorId : undefined;
        return !!authorId && authorId === this.userId;
    }

    // Given an assetId, determines if the logged in user is assigned to a user control on that asset
    isAssignedToAControl(assetIdOrRecord) {
        const record = typeof assetIdOrRecord === 'string' ? store.assets[assetIdOrRecord] : assetIdOrRecord;
        const tool = record ? appModel.toolbox.tools[record.attributes.toolId] : undefined;
        if (!record || !tool) {
            // Exceptional case
            return false;
        }
        const allControls = appModel.toolbox.tools[record.attributes.toolId].assetForm.controls;
        const userCtrlTypeId = constants.controlTypeNameToId.user;
        return !!allControls.find(ctrl => ctrl.controlTypeId === userCtrlTypeId && record.properties[ctrl.fieldName] === this.userId);
    }


    saveToApi(value, property) {
        if (property === 'role') {
            return this.saveUserRole(value);
        }
        return this.saveUserProperty(value, property);
    }

    updateRole(value, property) {
        const newRank = permissionLevel(value);
        if (this.isSelf && newRank < permissionLevel(this.role)) {
            return dialogModel.append({
                headline: 'Are you sure you want to lower your access level?',
                text: 'Please note that this operation cannot be undone.',
                yesClass: 'btn btn-pill btn-red',
                noText: 'Cancel',
                noClass: 'btn btn-pill btn-secondary',
                onYes: () => this.autosave(value, property)
            });
        }
        return this.autosave(value, property);
    }

    autosave(value, property) {
        this[property] = value;
        if (this.isNewUser) {
            m.redraw();
            return; // Don't autosave data for new users.
        }
        this.saveToApi(value, property);
    }

    // ---------- Saving data to back end -----------

    saveUserRole(value = this.role) {
        const userId = this.userId;
        // Used to show spinner and temporarily disable add'l changes until role is updated (further prevents deadlocks)
        this.formState.isSaving = true;
        this.formState.autoSaving.role = true;
        m.redraw();
        debouncedAPICall('modifyAccountUser' + userId, ['modifyAccountUser', {
            userId,
            accountId: store.account.accountId,
            role: value
        }]).then(() => {
            this.didSaveValue = true;
            this.formState.isSaving = false;
            this.formState.autoSaving.role = false;
            m.redraw();
        });
    }

    saveUserProperty(value, property) {
        const userId = this.userId;
        this.formState.isSaving = true;
        this.formState.autoSaving[property] = true;
        m.redraw();
        debouncedAPICall('modifyUser' + userId, ['modifyUser', {
            userId,
            [property]: value
        }]).then(() => {
            this.formState.isSaving = false;
            this.formState.autoSaving[property] = false;
            this.didSaveValue = true;
            m.redraw();
        });
    }

    // ---------- Displaying (formatted) user data -----------

    // Depending on data available, will return: First Last OR First OR ''
    displayName(user = appModel.user) {
        return user.givenName && user.familyName ? `${user.givenName} ${user.familyName}` : user.givenName || '';
    }

    // Depending on data available, will return: First Last OR First OR Passed arg
    displayNameOr(string = '') {
        return this.givenName && this.familyName ? `${this.givenName} ${this.familyName}` : this.givenName || string;
    }

    displayRole() {
        return this.role ? constants.accountUserRoles[this.role].display : 'Unknown';
    }

    initials() {
        return this.givenName && this.familyName ? `${this.givenName[0].toUpperCase()}${this.familyName[0].toUpperCase()}` : <i class="icon-user" />;
    }

    displayProperty(property) {
        let value = this[property];
        if (property === 'phoneNumber' && value) {
            value = displayPhoneNumber(value);
        } else if (property === 'role' && value) {
            value = constants.accountUserRoles[value].display;
        }
        return value || MISSING_DATA;
    }
}

export default PersonModel;
