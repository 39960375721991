import colorPickerModel from 'models/color-picker-model';

class ColorPicker {

    oncreate({attrs: {containerId, showOpacity, starterColor, starterOpacity, setColorFn, hideInputs}}) {
        colorPickerModel.init({containerId, showOpacity, starterColor, starterOpacity, setColorFn, hideInputs});
    }

    onremove() {
        colorPickerModel.onRemove();
    }

    view({attrs: {containerId, showOpacity, addCssClass, inlineStyle, header, dontCreateContainer}}) {
        return <div class={`color-picker-wrap unearth-color-picker-outer-wrap fade-in ${showOpacity ? 'with-opacity-control' : ''} ${addCssClass ? addCssClass : ''}`} style={inlineStyle || ''}>
            {header ? header : ''}
            {dontCreateContainer ? '' : <div id={containerId} class="color-picker unearth-color-picker"></div>}
        </div>;
    }
}

export default ColorPicker;
