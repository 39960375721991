import router from 'uav-router';
import jwtDecode from 'jwt-decode';
import ajax from 'legacy/util/api/ajax';
import cache from 'legacy/util/data/cache';
import cookie from 'util/data/cookie';
import { deUrnify } from 'util/network/urnify';
import authModel from 'models/auth-model';
import SocketModel from 'models/socket-model';
import message from 'views/toast-message/toast-message';
import offlineManager from 'managers/offline-manager';
import {authUrl} from 'util/data/env';
import ToastMessage from 'views/toast-message/toast-message';

const REFRESH_INTERVAL_DURATION = 55 * 60 * 1000; // 55 mins
class AuthManager {
    constructor() {
        this.openSocket = this._openSocket.bind(this);
        this.tokenExpiration = undefined;
        this.socket = new SocketModel();
        this.reset();
    }

    reset() {
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
        if (this.reconnectSocketInterval) {
            clearInterval(this.reconnectSocketInterval);
        }
        this.credentials = {};
        this.refreshInterval = undefined;
        this.reconnectSocketInterval = undefined;
        this.socket = new SocketModel();
        this.authenticationFlow = undefined;
        this.redirectURL = undefined;
        this.tokenExpiration = undefined;
    }

    async signIn(credentials = {}) {
        return new Promise((resolve, reject) => ajax(`${authUrl}/authenticate`, {
            method: 'POST',
            data: {
                email: credentials.username,
                password: credentials.password
            },
            resolve: (creds) => {
                if (!creds || !creds.token) {
                    authModel.onAuthFailure();
                    reject();
                }
                this.setCredentials(creds);
                resolve();
            },
            reject: (err) => {
                console.error('auth error', err);
                authModel.onAuthFailure();
                reject();
            }
        }));
    }

    async setAuthenticationFlow(email) {
        return new Promise((resolve, reject) => ajax(`${authUrl}/authenticationFlow?email_address=${email}`, {
            method: 'GET',
            resolve: (creds) => {
                if (!creds || !creds.flow) {
                    authModel.onAuthFailure();
                    reject();
                }
                if (creds.flow === 'SAML_FLOW' || creds.flow === 'OAUTH_FLOW') {
                    this.redirectURL = creds.samlURL || creds.oauthURL;
                }
                this.authenticationFlow = 'PASSWORD_FLOW';
                resolve();
            },
            reject: (err) => {
                console.error('auth error', err);
                authModel.onAuthFailure();
                reject();
            }
        }));
    }

    redirectSSOUsers() {
        window.location.href = `${this.redirectURL}`;
    }

    async reconnect() {
        const refreshToken = cache.get('ue_refresh');
        if (!refreshToken) {
            return false;
        }
        this.setCredentials({refreshToken});
        try {
            await this.refreshToken();
            return true;
        } catch {
            authModel.onAuthFailure(false);
        }
    }

    _onConnect() {
        this.openSocket();
        this.refreshOnInterval();
        // If we go offline, set a callback to reconnect once we come back online
        offlineManager.onceOnlineCallbacks = [() => {
            const success = authManager.reconnect();
            if (!success) {
                authModel.onAuthFailure(false);
            }
        }];
    }

    _openSocket() {
        if (this.socket && this.socket.socket) {
            this.socket.socket.removeEventListener('close', this.socket.onClose);
            this.socket.close();
        } 
        this.socket.start();
    }

    async onSocketDisconnect() {
        if (offlineManager.isOffline) {
            return;
        }

        ToastMessage.show('Connection lost. Attempting to reconnect...', 'error');

        try {
            if (this.reconnectSocketInterval) {
                clearInterval(this.reconnectSocketInterval);
            }
            await this.socket.start();
        } catch {
            authManager.showErrorToastMessage();
            this.reconnectSocketInterval = setInterval(() => {
                authManager.onSocketDisconnect();
            }, 10000);
        }
    }

    setCredentials(creds) {
        const token = creds.token;
        const refreshToken = creds.refreshToken;
        if (token) {
            this.credentials.token = true;
            document.cookie = `token=${creds.token}; Domain=unearthlabs.com; Secure;`; // We need the token in a cookie for auth via things like media permalink redirects
            cache.set('ue_token', creds.token);
            const decoded = jwtDecode(token);
            if (decoded && decoded.sub) {
                const urn = deUrnify(decoded.sub);
                this.tokenExpiration = decoded.exp;
                if (urn.nid === 'user') {
                    cache.set('user', urn.nss);
                    this.userId = urn.nss;
                }
            }
            this._onConnect();
        }
        if (refreshToken) {
            this.credentials.refreshToken = true;
            cache.set('ue_refresh', creds.refreshToken);
        }
    }

    tokenIsExpired() {
        if (!this.tokenExpiration) {
            return true; // Token expiration is missing
        }
        return Date.now() > this.tokenExpiration * 1000;
    }

    getToken() {
        return cache.get('ue_token');
    }

    getRefreshToken() {
        return cache.get('ue_refresh');
    }

    async refreshToken() {
        return new Promise((resolve, reject) => fetch(`${authUrl}/refresh`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.getRefreshToken()}`
            }
        }).then(response => response.text().then((creds) => {
            try {
                creds = JSON.parse(creds);
                if (!creds.token) {
                    console.error('auth response error', creds.detail);
                    authModel.onAuthFailure(false);
                    reject();
                }
                this.setCredentials({token: creds.token});
                resolve();
            } catch (e) {
                if (!offlineManager.isOffline) {
                    console.error('auth response error', e);
                    authModel.onAuthFailure(false);
                    reject();
                }
            }
        })).catch(err => {
            if (!offlineManager.isOffline) {
                console.error('auth error', err);
                reject();
                return authModel.onAuthFailure(false);
            }        
        })
        );
    }

    refreshOnInterval() {
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
        this.refreshInterval = window.setInterval(() => {
            this.refreshToken();
        }, REFRESH_INTERVAL_DURATION);
    }

    handleExpiredToken(retryFn) {
        return this.refreshToken(() => retryFn());
    }

    showErrorToastMessage = (persist) => {
        const errorMessage = <span>Something went wrong. Consider <a onclick={() => location.reload()}>refreshing</a>.</span>;
        message.show(errorMessage, 'error', persist);
    };

    signOut() {
        authModel.logout();
        cookie.removeCookie('messagesUtk');
        cookie.removeCookie('user_id');
        cookie.removeCookie('client_id');
        cookie.removeCookie('token');
        cookie.removeCookie('refreshToken');
        cookie.removeCookie('ue_token');
        cookie.removeCookie('ue_refresh');
        if (authManager.socket) {
            authManager.socket.close();
            delete authManager.socket;
        }
        authManager.reset();
        cache.clear();
        router.set();
    }
    

    /**
     * If a refreshToken is found in cookies, pull it 
     * from cookies & add to local storage.
     * This is the case for oAuthing or pdf mangonel
     */
    checkForOauthCookie() {
        const refreshToken = cookie.readCookie('refreshToken');
        if (refreshToken) {
            this.setCredentials({refreshToken});
            return refreshToken;
        }
        return false;
    }

}

const authManager = new AuthManager();
export default authManager;
