import constants from 'util/data/constants';
import {appUrl} from 'util/data/env';
import store from 'util/data/store';
import popup from '../util/popup';
import formModel from './form-model';
import appModel from './app-model';
import siteModel from './site-model';

class DataCenterModel {
    constructor() {
        this.isDataCenterOpen = false;
        this.setCommunicationFunctions();
    }

    closeViewer() {
        //when we close out in an active session and hide this view, we need to reset the base assetTypeId
        if (popup.isOpen()) {
            popup.remove();
        }
        this.baseAssetTypeId = undefined;
        this.isDataCenterOpen = false;
        if (siteModel.isInfoPanelOpen()) {
            formModel.setAssetPageTitle();
        } else {
            appModel.setPageTitle();
        }
        m.redraw();
    }

    get isIframeReady() {
        return !!this.window;
    }

    setIframeSrc() {
        const accountId = store.account.accountId;
        const metaProjectId = store.project.accountAttributes.metaProjectId;
        const toolboxId = store.toolboxId;
        const ueTokenString = !constants.isDeployed ? `&ueToken=${localStorage.getItem('ue_token')}` : '';
        if (this.baseAssetTypeId) {
            this.src = `${constants.dataCenterOrigin}/toolkit-editor/?metaProjectId=${metaProjectId}&toolboxId=${toolboxId}&accountId=${accountId}&assetTypeId=${this.baseAssetTypeId}&parentOrigin=${window.location.origin}&userId=${appModel.user.userId}${ueTokenString}`;
        } else {
            this.src = `${constants.dataCenterOrigin}/toolkit-editor/?metaProjectId=${metaProjectId}&toolboxId=${toolboxId}&accountId=${accountId}&parentOrigin=${window.location.origin}&userId=${appModel.user.userId}${ueTokenString}`;
        }
        m.redraw();
    }

    setIframeWindow(contentWindow) {
        this.window = contentWindow;
        m.redraw();
    }

    setCommunicationFunctions() {
        const functions = {
            dataCenterClose: ({shouldShow}) => {
                if (!shouldShow) {
                    this.closeViewer();
                }
            },
            init: () => delete this.loadLocal
        };

        window.addEventListener('message', message => {
            if (message.origin !== constants.dataCenterOrigin && constants.isDeployed) {
                return;
            }
            const {data} = message;
            if (data && data.function && functions[data.function]) {
                functions[data.function](data);
            }
        }, false);
    }

    editToolkit() {
        formModel.stopEdit();
        this.isDataCenterOpen = true;
        appModel.setPageTitle('Data Center');
        this.setIframeSrc();
    }

    editAssetTypeTool(assetTypeId) {
        formModel.stopEdit();
        this.isDataCenterOpen = true;
        appModel.setPageTitle('Data Center');
        this.baseAssetTypeId = assetTypeId;
        this.setIframeSrc();
    }

    updateUrlIfLocalhost() {
        if (constants.dataCenterOrigin === 'http://localhost:8085') {
            this.loadLocal = () => {
                constants.dataCenterOrigin = appUrl;
                // Reset iframe src bc we're running local instance of web app
                this.setIframeSrc();
            };
            if (!constants.isDeployed) {
                setTimeout(() => {
                    if (this.loadLocal) {
                        this.loadLocal();
                    }
                }, 5000);
            }
        }
    }
}

export default new DataCenterModel();
