
// TODO ease
const tween = (object, valueKey, from, to, time = 250, onComplete = undefined) => { // eslint-disable-line
    let lastFrameTime = Date.now();
    let totalTime = 1;
    const range = Math.abs(to - from);
    const direction = from > to;
    const doFrame = () => {
        requestAnimationFrame(() => {
            const timeNow = Date.now();
            const deltaTime = timeNow - lastFrameTime;
            lastFrameTime = timeNow;
            totalTime += deltaTime;
            const percentChange = deltaTime / time;
            const step = range * percentChange;
            object[valueKey] = direction ? object[valueKey] - step : object[valueKey] + step;
            if (totalTime >= time) {
                if (onComplete) {
                    onComplete();
                }
                return;
            }
            doFrame();
        });
    };

    doFrame();

};

// tween.easeOutExpo = easeOutExpo;
// tween.easeInAndOut = easeInAndOut;

export default tween;
