import batchModifyModel from 'models/batch-modify-model';
import constants from 'util/data/constants';
import Radio from 'views/radio';
import Toggle from 'views/toggle';
import peopleModel from 'models/people/people-model';
import DatePicker from 'views/date-picker/date-picker';
import FilterSelect from 'views/filter-select/filter-select';
import isMetric from 'util/numbers/is-metric';
import measure from 'legacy/util/numbers/measure';
import Multiselect from 'views/multiselect/multiselect';

const handleModifierUpdate = (control, value) => batchModifyModel.updateModifierField(control, value);

const BatchFormControl = {

    user(control) {
        const options = peopleModel.getControlOptions();
        return  options ?  <div class="user-control select-wrapper searchable-select"><FilterSelect
            key={control.fieldName}
            types={['users']}
            displayOptionAs={(option) => peopleModel.displayUserControlOption(option)}
            selected={batchModifyModel.getSelectedValue(control.fieldName)}
            onselect={(option) => handleModifierUpdate(control, option)} />
        </div> : options === null ?
            <div class="no-options-exist">No people have been added to this project yet.</div> :
            <div class="loading-options"><i class="spinner always-show spinning"/><input disabled type="text" class="loading-text" placeholder={'Loading...'} />
            </div>;
    },

    text(control) {
        return <div class="text-control"><input type='text' oninput={(e) => handleModifierUpdate(control, e.target.value)} placeholder="Custom value..." /></div>;
    },

    paragraph(control) {
        return <div class="paragraph-control"><textarea oninput={e => handleModifierUpdate(control, e.target.value)}></textarea></div>;
    },

    dropdown(control) {
        const options = batchModifyModel.getDefinedOptionsForControl(control);
        return <div class="dropdown-control select-wrapper"><select onkeydown={(e) => {
            return e.stopPropagation();
        }} onchange={e => handleModifierUpdate(control, e.target.value)}><option value={undefined}></option>
            {options.map(value => <option key={value} value={value}>{value}</option>)}
        </select></div>;
    },

    radio(control) {
        const options = batchModifyModel.getDefinedOptionsForControl(control);
        return <Radio options={options} onchange={(value) => handleModifierUpdate(control, value)} />;
    },

    toggle(control) {
        return <div class="toggle-text-wrap">
            <Toggle onchange={trueOrFalse => handleModifierUpdate(control, trueOrFalse)}/>
            <span class="toggle-text">{batchModifyModel.modificationsList[control.fieldName] ? 'On' : 'Off'}</span>
        </div>;
    },

    multiselect(control) {
        return <Multiselect onchange={(options) => handleModifierUpdate(control, options)} options={batchModifyModel.getDefinedOptionsForControl(control)}/>;
    },

    multitext(control) {
        const values = batchModifyModel.modificationsList[control.fieldName] || [''];
        const canDelete = values.length > 1 || values[0];
        return <div class="multitext"> {values.map((value, i) =>
            <div class="text-control">
                <input type="text" value={value} oninput={e => {
                    values[i] = e.target.value;
                    handleModifierUpdate(control, values);
                }} />
                {canDelete && <i class="icon-close" onclick={() => {
                    values.splice(i, 1);
                    handleModifierUpdate(control, values.length ? values : '');
                }} />}
            </div>
        )}
        <div className="btn btn-secondary btn-pill btn-smallest" onclick={() => values.push('')}>
            <i class="icon-plus"/></div>
        </div>;
    },

    number: (control) => {
        return <input type='number' class="number-input" step="any" placeholder="Custom value..." oninput={(e) => handleModifierUpdate(control, e.target.value)} />;
    },

    length: (control) => {
        const metric = isMetric();
        return <div class="length-control"><input type="number" class="number-input" step="any" oninput={(e) => handleModifierUpdate(control, metric ? e.target.value : measure.feetToMeters(e.target.value))} />
            <span class="number-metric">{metric ? 'meters' : 'feet'}</span></div>;
    },

    area: (control) => {
        const metric = isMetric();
        return <div class="length-control"><input type="number" class="number-input" step="any" oninput={(e) => handleModifierUpdate(control, metric ? e.target.value : measure.squareFeetToSquareMeters(e.target.value))} />
            <span className="number-metric">{metric ? 'meters²' : 'feet²'}</span>
        </div>;
    },

    volume: (control) => {
        const metric = isMetric();
        return <div class="length-control"><input type="number" class="number-input" step="any" oninput={(e) => handleModifierUpdate(control, metric ? e.target.value : measure.cubicMetersToCubicYards(e.target.value))} />
            <span class="number-metric">{metric ? 'meters³' : 'yards³'}</span>
        </div>;
    },

    date(control) {
        return <DatePicker
            date={control.fieldName && new Date(control.fieldName)}
            required={control.attributes.required}
            min={control.attributes.min && new Date(control.attributes.min)}
            max={control.attributes.max && new Date(control.attributes.max)}
        />;
    },

    view: ({attrs: {control}}) => {
        let inputDataType = constants.controlTypeIdToName[control.controlTypeId];
        // Special case where the control name is not the same as the input type.
        if (inputDataType === 'name') {
            inputDataType = 'text';
        }
        return BatchFormControl[inputDataType](control);
    }

};

export default BatchFormControl;

