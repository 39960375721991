import placeEditorModel from 'models/place-editor-model';
import SubmitButton from 'views/submit-button';
import placesTabModel from 'models/places-tab-model';
import GeometryDetail from 'views/geometry-detail/geometry-detail';

const PlaceForm = {
    view: () => <div class="place-form">
        <div className="place-meta-wrap asset-meta-wrap">
            <GeometryDetail mockFeature={placeEditorModel.mockFeature}
                header={<div
                    className={`place-geo-details${placeEditorModel.saving.boundary ? ' saving' : ''}`}>
                    <i className="spinner teal"/></div>}
                customElement={<>
                    <div className="left half">{'Avg Elevation'}</div>
                    <div
                        className="left half">{placesTabModel.getAverageElevation(placeEditorModel.levels)}</div>
                </>}/>
        </div>
        <fieldset className={placeEditorModel.saving.name ? 'saving' : ''}>
            <label className="place-name-label"><span>Place Name<i class="spinner teal"/></span></label>
            <input type="text" id="place-name" placeholder="New Place" class="place-name" autofocus oninput={e => {
                placeEditorModel.placeName = e.target.value;
                placeEditorModel.autosavePlace('name');
            }} value={placeEditorModel.placeName}/>
        </fieldset>
        <fieldset className={placeEditorModel.saving.levels ? 'saving' : ''}>
            <label className="level-count-label"><span>No. of Levels<i class="spinner teal"/></span></label>
            <input type="number" id="level-ct" defaultValue={placeEditorModel.levels.length} min="0" max="200"
                class="place-levels"
                oninput={e => placeEditorModel.levelCountChange(e)}/>
        </fieldset>
        {placeEditorModel.levels.length ? <>
            <div class="label-group">
                <label class="level-name-label"><i class="icon-level"></i> Level Name</label>
                <label class="level-elev-label">Elevation<br/>in ft ASL</label>
            </div>
            {placeEditorModel.levels.map((level, i) =>
                <fieldset class={`level-group${placeEditorModel.saving['level' + i] ? ' saving' : ''}`}>
                    <input type="text" placeholder={`Level ${i + 1}`} class="level-name" value={level.name}
                        oninput={e =>
                            placeEditorModel.editLevelName(i, e.target.value)}/>
                    <input type="number" class="level-elev" value={level._elevationFeet ? level._elevationFeet : 0}
                        oninput={e =>
                            placeEditorModel.editElevationValue(i, e.target.value)}/>
                    <i class="spinner teal"/>
                </fieldset>)}
        </> : ''}
        {placeEditorModel.placeholderMockLevels.map((placeholder, i) =>
            <fieldset key={i} class="level-group placeholder-level-group">
                <input type="text" class="level-name" placeholder={placeholder}/>
                <input type="number" class="level-elev"/>
            </fieldset>)}
    </div>
};

const NewPlacePrompt = {
    view: () => <div class="place-prompt-tip">
        <span class="place-prompt-tip-text">Tap on the map to outline a new Place in {placeEditorModel.projectName}.</span>
    </div>
};

const PlaceEditor = {
    onremove: () => placeEditorModel.cleanup(),

    view: () => {
        return <div class="place-editor-tool">
            <div class="place-editor-inner">
                <div class="place-title-wrap asset-title-wrap">
                    <div className="asset-icon"><i className="icon-place"/></div>
                    <div class="place-title asset-title">{placeEditorModel.placeName || 'New Place'}</div>
                    {!placeEditorModel.showPlaceForm &&
                            <div class="btn btn-smallest btn-secondary btn-pill asset-cancel" onclick={() => placeEditorModel.close()}><span class="btn-label">Cancel</span>
                            </div> }
                </div>
                <div class="place-editor-body vertical-scroll">
                    {placeEditorModel.showPlaceForm ? <PlaceForm /> : <NewPlacePrompt/>}
                </div>
                {placeEditorModel.showPlaceForm ? <div class="place-form-footer">
                    <SubmitButton text="Done" onclick={() => placeEditorModel.close()} />
                </div> : ''}
            </div>
        </div>;

    }
};

export default PlaceEditor;
