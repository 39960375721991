// Handles user data at the project level
import peopleModel from 'models/people/people-model';
import appModel from '../app-model';
import graphql from 'util/graphql/graphql';

const NO_ACCESS = 0;
const HAS_ACCESS = 1;

class ProjectPeopleModel {

    constructor(projectId, projectUserList, accountUserIdList) {
        this.projectId = projectId;
        this._table = {};
        this._list = [...accountUserIdList]; // Use same order as account list.
        this.userAccessCount = 0;
        this.companyCount = 0;
        this.orderChanged = false;
        this._controlOptions = [];
        this.init(projectUserList, accountUserIdList);
    }

    init(projectUserList, accountUserIdList) {
        accountUserIdList.forEach(userId => {
            // Maintain order from ordered account list, default to no access
            this._table[userId] = NO_ACCESS;
        });
        // Iterate through project users, granting access
        projectUserList.forEach(projectPerson => {
            // Ensure the person is visible and the project and exists on the list of account users.
            if (projectPerson.isVisible && this._table[projectPerson.userId] === NO_ACCESS) {
                this.grantAccess(projectPerson.userId);
            } else if (this._table[projectPerson.userId] === NO_ACCESS) {
                this._list = this._list.filter(id => id !== projectPerson.userId); // Remove them if they're hidden
            } else {
                peopleModel.addHiddenUser(projectPerson);
            }
        });
        this.refreshListOrder(true);
    }

    refreshListOrder(forceRefresh) {
        if (this.orderChanged || forceRefresh) {
            // Maintain original order except bring those with access to the top.
            this._list.sort((a, b) => this._table[b] - this._table[a]);
            this.orderChanged = false;
        }
    }

    get list() {
        return this._list;
    }

    get controlOptions() {
        if (this._controlOptions.length) {
            return this._controlOptions;
        }
        return null;
    }

    // Returns 1 if person has access, 0 or undefined if not.
    personHasAccess(userId) {
        return this._table[userId];
    }

    addPerson(userId, hasAccess = false) {
        this._table[userId] = NO_ACCESS;
        this._list.push(userId); // Ok to add to the bottom for new users
        if (hasAccess) {
            this.grantAccess(userId);
        }
    }

    removePerson(userId) {
        if (this.personHasAccess(userId)) {
            this.removeAccess(userId);
        }
        delete this._table[userId];
        this._list = this._list.filter(id => id !== userId);
    }

    toggleAccess(person, saveToApi = true) {
        const userId = person.userId;
        this.orderChanged = true;
        if (this.personHasAccess(userId)) {
            return this.removeAccess(userId, saveToApi).then(() => 'removed');
        }
        return this.grantAccess(userId, saveToApi, person.role).then(() => 'added');

    }

    grantAccess(userId, saveToApi = false, role) {
        this._table[userId] = HAS_ACCESS;
        this._controlOptions.push(userId);
        this.userAccessCount++;
        if (appModel.user.restrictPeopleListByCompany()) {
            const person = peopleModel.getPerson(userId);
            if (person.company === appModel.user.company && this.personHasAccess(userId)) {
                this.companyCount++;
            }
        }
        if (saveToApi) {
            return graphql.addProjectUser(this.projectId, role, userId);
        }
        return Promise.resolve();
    }

    removeAccess(userId, saveToApi = false) {
        this._table[userId] = NO_ACCESS;
        this._controlOptions = this._controlOptions.filter(id => id !== userId);
        this.userAccessCount--;
        if (appModel.user.role === 'manager') {
            const person = peopleModel.getPerson(userId);
            if (person.company === appModel.user.company) {
                this.companyCount--;
            }
        }
        if (saveToApi) {
            return graphql.removeProjectUser(this.projectId, userId);
        }
        return Promise.resolve();
    }
}

export default ProjectPeopleModel;
