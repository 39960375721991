import formatDate from 'legacy/util/date/format-date';
import assetListManager from 'managers/asset-list-manager';
import toolboxManager from 'managers/toolbox-manager';
import exportPDFModel from 'models/export-pdf-model';
import constants from 'util/data/constants';

/*
 
localhost:8000/#projectId=p7FYWOflp0YzEW93f65l&siteId=lmj1zYZ4DlZNL9p0QoYe&position=top&view=pdf&type=contentCard&contentId=NQZ4zlPkQYvkyIuF1mSN&metric=&nw=-116.07505319551986,46.01365187286163&se=-116.05746308616904,46.004195904006565&projectViewId=7oELmKq65pWg1Ao-hPtt&bearing=0&pitch=0 

 */

// Hardcoded for special customer case UNEARTH-109
// Aside from Address & City, other controls must be set as "includeInContentRecordPDF" in the toolkit editor to show up on the export.

const VECTOR_FORCE_LOGO_MEDIA_ID = '1Wz966vX1a5d55mK0vy5';
const SPECIAL_ADDRESS_ROW_FIELD_NAME = 'Address'; // According to spec, this should be full width.
const SPECIAL_COMMENTS_ROW_FIELD_NAME = 'If no - explain'; // According to spec, this should be full width.
const NO_VALUE = '--';

const ORDER_OF_HALF_WIDTH_PROPERTIES = [
    'City', 'Date', 'Project Number', 'Sanitary Lateral Located', 'Pre - Camera Operator', 'Pre - Camera Locator',  'Post - Camera Operator', 'Post - Camera Locator', 'Pre-Inspection Audit Status', 'Post-Inspection Audit Status'
];


const truncateTo600Characters = (value) => {
    if (value && value.length > 600) {
        return value.substring(0, 600) + '...';
    }
    return value;
};

const PDFExporterContentCard = {
    oncreate: ({state}) => {
        const assetId = exportPDFModel.isExportingContentCardFor;

        state.asset = assetListManager.getById(assetId);
        const normalizedProperties = {};
        // Some of the properties include extra white space after the dash, like in Pre - Camera Operator, and our key accessors can't pick them up.
        Object.entries(state.asset.properties).forEach(([key, value]) => {
            normalizedProperties[key.replace(/\s+/g, ' ').trim()] = value;
        });
        state.asset.properties = normalizedProperties;
        state.tool = toolboxManager.tools[state.asset.attributes.toolId];
        state.formattedUpdatedDate =  formatDate.dayMonthYear(state.asset.updatedDateTime);   
             
    },

    view: ({state: {asset, formattedUpdatedDate}}) => {
        return <div className="pdf-exporter-content-card-outer-wrap">
            <img width="300" src={`${constants.staticMediaURL}${VECTOR_FORCE_LOGO_MEDIA_ID}`} alt="Vector Force Logo" className="pdf-exporter-logo"/>
            {asset && <div className="pdf-exporter-content-card">
                <div className="form-control-row form-control-row-full-width">
                    <div className="form-control-cell form-control-label">{SPECIAL_ADDRESS_ROW_FIELD_NAME}:</div>
                    <div className="form-control-cell form-control-value">{asset.properties[SPECIAL_ADDRESS_ROW_FIELD_NAME] || NO_VALUE}</div>
                </div>
                {ORDER_OF_HALF_WIDTH_PROPERTIES.map(controlFieldName => {
                    console.log('controlFieldName', controlFieldName, asset.properties[controlFieldName]);
                    return <div className="form-control-row">
                        <div className="form-control-cell form-control-label">{controlFieldName === 'Date' ? 'Date' : controlFieldName}:</div>
                        <div className="form-control-cell form-control-value">{(controlFieldName === 'Date' ? formattedUpdatedDate : asset.properties[controlFieldName]) || NO_VALUE}</div>
                    </div>;
                })}
                <div className="form-control-row form-control-row-full-width">
                    <div className="form-control-cell form-control-label">Comments</div>
                    <div className="form-control-cell form-control-value">{truncateTo600Characters(asset.properties[SPECIAL_COMMENTS_ROW_FIELD_NAME]) || NO_VALUE}</div>
                </div>
                
            </div>}
        </div>;
    }
};

export default PDFExporterContentCard;
