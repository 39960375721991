const MapScale = {
    oninit: ({attrs: {newWrapperClass}}) => {
        // grabs map distance scale added from map-model.js and inserts into pdf window
        const scale = document.getElementsByClassName('mapboxgl-ctrl-scale')[0];
        const wrapper = document.getElementsByClassName(newWrapperClass)[0];
        wrapper.appendChild(scale);
    },
    onremove: () => {
        // returns map scale to originial position upon exiting pdf view
        const scale = document.getElementsByClassName('mapboxgl-ctrl-scale')[0];
        const wrapper = document.getElementsByClassName('mapboxgl-ctrl-bottom-right')[0];
        wrapper.appendChild(scale);
    },
    view: () => {
        return <></>;
    }
};

export default MapScale;
