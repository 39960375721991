import Page from 'views/plan/pages/page';
import LoadingSpinnerWithText from 'views/loader/loading-spinner-with-text';
import createLayerFlow from 'flows/create-layer/create-layer-flow';
import { PlanEditorHeader } from 'views/plan/plan-editor/plan-editor';

const GeneratingLayer = {
    view: () => {
        const selectedPageId = createLayerFlow.sourcePageId;
        return <div class="plan-editor generating-layer">
            <PlanEditorHeader 
                titleIconClass="icon-document" 
                planName={createLayerFlow.layerName} 
                mediaLabel={'Source File: ' + createLayerFlow.mediaLabel}
            />
            {selectedPageId ? <Page pageId={selectedPageId} index={createLayerFlow.sourcePageIndex}/> : ''}
            <LoadingSpinnerWithText iconClass="icon-layers" text="Generating Map Layer" outerClass="color-teal1" spinnerColor="teal"/>
        </div>;
    }
};

export default GeneratingLayer;
