const modalModel = {
    views: [],
    cssClass: 'hidden',
    styleClass: 'default',
    isOpen: false,
    attrs: {},
    zIndex: '100', // This can be manipulated with JS if it needs to be above something temporarily (ie one up)
    open: (opts) => {
        if (modalModel.isOpen) {
            modalModel.close();
        }
        // Handle view based on v1 or v2 component structure:
        if (opts.view && opts.view.view) {
            modalModel.appendView(opts.view); // v1
        } else if (opts.view && opts.view.prototype.view) {
            modalModel.appendView(opts.view.prototype); // v2
        }
        Object.assign(modalModel, {
            isOpen: true,
            onclose: opts.onclose,
            cssClass: 'visible',
            styleClass: opts.styleClass || 'default',
            attrs: opts.attrs || {}
        });
        m.redraw();
    },
    close: () => {
        if (modalModel.onclose) {
            modalModel.onclose();
        }
        Object.assign(modalModel, {
            isOpen: false,
            cssClass: 'hidden',
            styleClass: 'default',
            views: [],
            onclose: undefined
        });
        m.redraw();
    },
    appendView: (view) => {
        if (view) {
            modalModel.views.push(view);
            m.redraw();
        }
    },
    popView: () => {
        if (modalModel.views.length) {
            modalModel.views.pop();
            m.redraw();
        }
    },
    setZindex: (toValue) => {
        modalModel.zIndex = toValue;
        m.redraw();
    }
};

export default modalModel;
