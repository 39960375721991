import store from 'util/data/store';
import controlToFeature from 'util/interfaces/control-to-feature';
import sanitize from 'util/data/sanitize';

const updateAssetFeatures = (control, assetId) => controlToFeature.updateAssetFeatures(control, assetId);

export default function(control, assetId) {
    const asset = store.assets[assetId],
        properties = asset.properties,
        text = properties[control.fieldName] || '';
    return <div class="name-control">
        <input data-focus-id="1" type="text" value={text} oninput={e => {
            properties[control.fieldName] = sanitize(e.target.value);
            updateAssetFeatures(control, assetId);
        }}/>
    </div>;
}
