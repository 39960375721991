import peopleModel from 'models/people/people-model';
import store from 'util/data/store';
import formatDate from 'legacy/util/date/format-date';
import isMetric from 'util/numbers/is-metric';
import round from 'util/numbers/round';
import measure from 'legacy/util/numbers/measure';
import appModel from 'models/app-model';
import siteModel from 'models/site-model';
import tableModel from 'models/table/table-model';
import {lngLatsToBounds} from 'util/geo';
import {assetIsImageType} from 'util/data/helpers';
import filterConstants from 'constants/models/table/filter-constants';

const NO_OPTIONS_SELECTED = [{'text': '[None selected]'}];
const {mapFilterOpts} = filterConstants;

const getVisibilityConfig = (tableState) => {
    return tableState.tableMode === 'table-left' ? tableState._visibleHeaders.list : tableState._visibleHeaders.table;
};

const visibleProperties = (visibilityConfig) => {
    const visibleProps = {};
    const typeHeaders = [];
    tableModel.tableHeaders.filter(header => !header.controlType || !header.controlType.attributes || !header.controlType.attributes.hidden).forEach(header => {
        typeHeaders.push(header.assetType.assetTypeId);
    });
    const allHeaders = [...tableModel.commonHeaders, ...typeHeaders];

    Object.keys(visibilityConfig).forEach(key => {
        if (key === 'links') {
            return; // not in use currently
        }
        const value = visibilityConfig[key];
        if (typeof value === 'object') {
            Object.values(value).forEach(isVisible => {
                if (isVisible && allHeaders.includes(key)) {
                    visibleProps[key] = visibleProps[key] || [];
                    visibleProps[key].push(value);
                }
            });
        } else if (value && allHeaders.includes(key)) {
            visibleProps[key] = true;
        }
    });
    return visibleProps;
};

const booleanOptionToText = (filter) => {
    switch (filter.selectedRadioIndex) {
    case 0:
        return '(any)';
    case 1:
        return 'on';
    case 2:
        return 'off';
    }
};

const linkedAssetOptionsToText = (filter) => {
    switch (filter.selectedRadioIndex) {
    case 0:
        return '(any)';
    case 1:
        return 'empty';
    case 2:
        return 'not empty';
    }
};

const dateRangeOptionToText = (filter) => {
    switch (filter.selectedRadioIndex) {
    case 0:
        return '(any)'; // we shouldn't get here so shouldn't need to use this, but keeping it for completeness.
    case 1:
        return 'today';
    case 2:
        return 'within last 7 days';
    case 3:
        return 'within last 14 days';
    case 4:
        return 'within last 30 days';
    case 5:
        return `from ${formatDate.ddmmyyyy(filter.rangeModel.fromDate)} to ${formatDate.ddmmyyyy(filter.rangeModel.toDate)}`;
    }
};

const selectedSignToText = {
    lte: 'less than or equal to',
    gte: 'greater than or equal to',
    eq: 'equal to'
};


const numberTypeToUnit = {
    length: 'feet',
    area: 'feet²',
    volume: 'yards³'
};

const numberTypeToUnitMetric = {
    length: 'meters',
    area: 'meters²',
    volume: 'meters³'
};

const numberRangeOptionToText = (filter) => {
    const metric = isMetric();
    switch (filter.selectedRadioIndex) {
    case 0:
        return '(any)'; // we shouldn't get here so shouldn't need to use this, but keeping it for completeness.
    case 1:
        return 'empty';
    case 2:
        const rangeValueFormatted = filter.numberType === 'number' ? filter.range : `${round(metric ? filter.range  : measure.metersToFeet(filter.range))} ${metric ? numberTypeToUnitMetric[filter.numberType] : numberTypeToUnit[filter.numberType]}`;
        return `${selectedSignToText[filter.selectedSign]} ${rangeValueFormatted}`;
    case 3:
        const rangeLowFormatted = filter.numberType === 'number' ? filter.rangeLow : `${round(metric ? filter.rangeLow  : measure.metersToFeet(filter.rangeLow))} ${metric ? numberTypeToUnitMetric[filter.numberType] : numberTypeToUnit[filter.numberType]}`;
        const rangeHighFormatted = filter.numberType === 'number' ? filter.rangeHigh : `${round(metric ? filter.rangeHigh  : measure.metersToFeet(filter.rangeHigh))} ${metric ? numberTypeToUnitMetric[filter.numberType] : numberTypeToUnit[filter.numberType]}`;

        return `from ${rangeLowFormatted} to ${rangeHighFormatted}`;
    }
};

const textOptionToText = (filter) => {
    switch (filter.selectedRadioIndex) {
    case 0:
        return '(any)'; // we shouldn't get here so shouldn't need to use this, but keeping it for completeness.
    case 1:
        return 'empty';
    case 2:
        return 'not empty';
    case 3:
        return `"${filter.queryParam}"`;
    }
};

const getOptions = (filter) => {
    let optionsCheckedTrue = [],
        options = [];
    switch (filter.type) {
    case 'definedOptions':
        optionsCheckedTrue = Object.keys(filter.checkedOptions).filter(key => filter.checkedOptions[key]);
        if (filter.isEmptyChecked) {
            optionsCheckedTrue.push('empty');
        }
        options = [];
        optionsCheckedTrue.map(opt => 
            options.push({text: opt, cssClass: ''})
        );
        if (!options.length) {
            options = NO_OPTIONS_SELECTED;
        }
        return options;
    case 'text':
        options.push({text: textOptionToText(filter)});       
        if (filter.isEmptyChecked) {
            options.push({text: 'empty'});
        }
        return options;
    case 'dateRange':
        options.push({text: dateRangeOptionToText(filter)});       
        return options;
    case 'numberRange':
        options.push({text: numberRangeOptionToText(filter)});       
        return options;
    case 'boolean':
        options.push({text: booleanOptionToText(filter)});       
        return options;
    case 'linkedAsset':
        options.push({text: linkedAssetOptionsToText(filter)});       
        return options;
    default:
        return [];
    }
};

const getPredicateVerb = (filter) => {
    switch (filter.type) {
    case 'text':
        if (filter.selectedRadioIndex === 3) {
            return 'contains'; // ie contains "text query from user"
        }
        return 'is'; // ie is "not empty"
    default:
        return 'is';
    }
};

const visiblePropertiesCount = (visibleProps) => {
    let count = 0;
    Object.keys(visibleProps).forEach(key => {
        const value = visibleProps[key];
        if (typeof value === 'object') {
            count += visibleProps[key].length;
        } else {
            count += 1;
        }
    });
    return count;
};


const totalPropertiesCount = () => {
    let count = tableModel.commonHeaders.length;
    const typeHeaders = tableModel.tableHeaders.filter(header => !header.controlType || !header.controlType.attributes || !header.controlType.attributes.hidden);
    count += typeHeaders.length;
    count += tableModel.getSharedColumnCount();


    if (!appModel.toolbox.hasMultipleGroups) {
        count -= 1; // If only 1 category (aka group), we hide the column. Remove it from the count.
    } 
    if (!appModel.project.isMetaProject && appModel.toolbox.linkTools && appModel.toolbox.linkTools.image) {
        count += 1; // Add 1 for the "Capture Date" column on image types, which wouldn't be tallied like the other type-specific controls are
    }
    return count;
};

const getDateFiltersArgs = (selectedIndex, dateRange) => { 
    return {
        rangeModel: dateRange,
        selectedRadioIndex: selectedIndex
    };
};

const getCheckedUsersFiltersArgs = (selectedUsers) => {
    return {
        checkedOptions: selectedUsers,
        isEmptyChecked: false
    };
};

const getNonColumnConfigs = (projectView, invalidBoundary) => {
    const filtersAsText = [];
    let _cssClass = '';
    if (projectView.common.selectedBoundaryIndex > mapFilterOpts.NONE) {
        if (projectView.common.selectedBoundaryIndex === mapFilterOpts.DRAW_BOUNDARY && projectView.common.searchArea) {
            const searchAreaCoords =  projectView.common.searchArea.geometry.coordinates[0];
            const bounds = lngLatsToBounds(searchAreaCoords);
            const invalidBounds = !siteModel.bounds.contains(bounds.getCenter());
            if (invalidBounds) {
                invalidBoundary[projectView.common.searchArea.id] = true;
            }
            _cssClass = invalidBounds ? 'highlight' : '';
        }
        let textString;
        switch (projectView.common.selectedBoundaryIndex) {
        case mapFilterOpts.MAPPED:
            textString = 'mapped';
            break;
        case mapFilterOpts.UNMAPPED:   
            textString = 'unmapped';
            break;
        default:
            textString = 'within the defined boundary';
            break;
        }
        filtersAsText.push({
            label: 'Map location is',
            values: [{text: textString, cssClass: _cssClass}]
        });
    }
    if (projectView.filters.searchString) {
        filtersAsText.push({
            label: 'Content contains',
            values: [{text: `"${projectView.filters.searchString}"`}]
        });
    }
    return filtersAsText;
};

const getCommonPropertyFilters = (projectView, invalidUsers) => {
    const common = projectView.common;
    const filtersAsText = [];
    if (projectView.common.isFiltered('name')) {
        filtersAsText.push({
            label: 'Name contains',
            values: [{text: `"${common.nameQueryString}"`}]
        });
    }
    if (projectView.common.isFiltered('category')) {
        const catList = Object.keys(common.checkedToolGroups).filter(key => common.checkedToolGroups[key]);
        const catNames = catList.length ? catList.map(id => {
            return {'text': appModel.toolbox.toolGroups[id].name};
        }) : NO_OPTIONS_SELECTED;

        filtersAsText.push({
            label: 'Category is',
            values: catNames
        });
    }
    if (projectView.common.isFiltered('contentType')) {
        const typeList = Object.keys(common.checkedAssetTypes).filter(key => common.checkedAssetTypes[key]);
        const typeNames = typeList.length ? typeList.map(id => {
            return {'text': store.assetTypes[id].name};
        }) : NO_OPTIONS_SELECTED;

        filtersAsText.push({
            label: 'Type is',
            values: typeNames
        });
    }
    if (projectView.common.isFiltered('createdDateTime')) {
        const filter = getDateFiltersArgs(common.selectedDateRangeIndex, common.addedDateRange);
        const date = {'text': dateRangeOptionToText(filter)};
        filtersAsText.push({
            label: 'Date Added',
            values: [date]
        });
    }
    if (projectView.common.isFiltered('updatedDateTime')) {
        const filter = getDateFiltersArgs(common.updatedDateRangeIndex, common.updatedDateRange);
        const date = {'text': dateRangeOptionToText(filter)};
        filtersAsText.push({
            label: 'Date Updated',
            values: [date]
        });
    }
    if (projectView.common.isFiltered('unearthId')) {
        const unearthId = {'text': common.assetIdMatch};
        filtersAsText.push({
            label: 'Unearth ID',
            values: [unearthId]
        });
    }

    if (projectView.common.isFiltered('addedBy')) {
        const filter = getCheckedUsersFiltersArgs(common.checkedUsers);
        filtersAsText.push({
            label: 'Added By',
            values: getUserOptions(filter, invalidUsers)
        });
    }

    if (projectView.common.isFiltered('lastUpdatedBy')) {
        const filter = getCheckedUsersFiltersArgs(common.checkedUsersUpdatedBy);
        filtersAsText.push({
            label: 'Last Updated By',
            values: getUserOptions(filter, invalidUsers)
        });
    }

    return filtersAsText;
};

const getUserOptions = (filter, invalidUsers) => {
    const optionsCheckedTrue = Object.keys(filter.checkedOptions).filter(key => filter.checkedOptions[key]);
    if (filter.isEmptyChecked) {
        optionsCheckedTrue.push('empty');
    }
    const options = [];
    optionsCheckedTrue.map(opt => {
        let name = 'empty';
        let isOnProject = true;
        if (opt !== 'empty') {
            name = peopleModel.displayNameOrEmail(opt);
            const project = peopleModel.projectPeople[appModel.project.projectId];
            isOnProject = project ? project.personHasAccess(opt) : false;
            if (!isOnProject) {
                invalidUsers[opt] = true;
            }
        }
        return options.push({text: name, cssClass: `${isOnProject ? '' : 'highlight'}`});
    }
    );
    return options;
};

const getPlaceOptions = (filter, invalidPlaces) => {
    const placeOptions = [];
    switch (filter.selectedRadioIndex) {
    case 0:
        placeOptions.push({text: 'any'});
        break;
    case 1:
        placeOptions.push({text: 'empty'});
        break;
    case 2:
        filter.selectedItems.forEach(placeId => {
            let place = store.places[placeId];
            if (!place) {
                place = {
                    name: '[N/A]',
                    cssClass: 'highlight'
                };
                invalidPlaces[placeId] = true;
            }
            placeOptions.push({text: place.name, cssClass: place.cssClass || ''});
        });
    }
    return placeOptions;
};

const checkIfUserControl = (assetTypeId, filter) => {
    const assetType = store.assetTypes[assetTypeId];
    if (!assetType) {
        return;
    }

    const theField = filter.fieldName === 'Capture Date' && assetIsImageType({assetTypeId}) ? assetType.fields.find(field => field.name === 'Image') : assetType.fields.find(field => field.name === filter.fieldName);
    return theField && theField.type && theField.type.objType === 'user';
};

const checkIfPlaceControl = (assetTypeId, filter) => {
    if (filter.type === 'placesFilter') {
        return true;
    }
    const assetType = store.assetTypes[assetTypeId];
    if (!assetType) {
        return;
    }
};

function format(projectView) {
    const invalidUsers = {};
    const invalidPlaces = {};
    const invalidBoundary = {};
    const hiddenFilteredProperties = {};

    // Asset types
    const assetTypeFiltersText = [];
    Object.keys(projectView.filters.filterStates).forEach(assetTypeId => {
        const assetFormId = store.assetTypeToFormId[assetTypeId];
        const controls = store.assetForms[assetFormId].controls;
        const assetName = store.assetTypes[assetTypeId] ? store.assetTypes[assetTypeId].name : undefined;
        if (!assetName) {
            return;
        }
        const assetTypeFilters = projectView.getInUseFilters(assetTypeId);

        assetTypeFilters.forEach(filter => {
            const isUserControl = checkIfUserControl(assetTypeId, filter);
            const isPlaceControl = checkIfPlaceControl(assetTypeId, filter);
            const predicateVerb = getPredicateVerb(filter);
            let options;
            if (isUserControl) {
                options = getUserOptions(filter, invalidUsers);
            } else if (isPlaceControl) {
                options = getPlaceOptions(filter, invalidPlaces);
            } else {
                options = getOptions(filter);
            }

            const control = controls.find(ctrl => ctrl.fieldName === filter.fieldName);
            assetTypeFiltersText.push({
                label: `${assetName} ${control ? control.label : filter.fieldName} ${predicateVerb}`, 
                values: [...options]
            });
        });
    });

    const tableState = projectView.getAttributes().tableState;
    const visibilityConfig = getVisibilityConfig(tableState);
    const visibleProps = visibleProperties(visibilityConfig);

    const nonColumnConfigs = getNonColumnConfigs(projectView, invalidBoundary);
    const commonPropertyFilters = getCommonPropertyFilters(projectView, invalidUsers, hiddenFilteredProperties);

    const invalidUsersCount = Object.keys(invalidUsers).length;
    const invalidPlacesCount = Object.keys(invalidPlaces).length;
    const totalInvalidCount = invalidUsersCount + invalidPlacesCount;
    const hiddenFilteredPropertyCount = Object.keys(hiddenFilteredProperties).length;

    const warningDetailText = totalInvalidCount ? `${totalInvalidCount} filter value${totalInvalidCount === 1 ? ' does' : 's do'} not apply in this ${appModel.toolbox.siteTermSingular}.` : '';
    const warningBoundaryText = Object.keys(invalidBoundary).length ? `The map boundary filter falls outside the bounds of this ${appModel.toolbox.siteTermSingular}.` : '';
    const hiddenFilteredPropertyText = hiddenFilteredPropertyCount ? '*The filter is applied but the column is not currently visible' : '';

    const formatted = {
        invalidUsersCount,
        invalidPlacesCount,
        warningDetailText,
        warningBoundaryText,
        hiddenFilteredPropertyText,
        totalColumnCount: totalPropertiesCount(),
        visiblePropsCount: visiblePropertiesCount(visibleProps),
        propsFilteredCount: commonPropertyFilters.length + assetTypeFiltersText.length + nonColumnConfigs.length,
        propertiesFilters: [...nonColumnConfigs, ...commonPropertyFilters, ...assetTypeFiltersText]
    };
    return formatted;
}

export default {format};
