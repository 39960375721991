import throttle from 'util/events/throttle';

/**
 For creating paginated api fetch requests.
 Required params:
 * apiRequest = api function to run in paginated sequence,
 *     for example: (args) => api.rpc.request([['listAccounts', args]], false, true)
 Optional params (opts):
 * opts.args = object containing additional args to be sent along with limit and offset
 * opts.startIndex = offset for first initial request/1st page. Defaults to 0.
 * opts.pageLimit = limit of results per page. Defaults to 20.
 * opts.onStart = callback fn to run just before the first request is made.
 * opts.onResults = callback fn to run on receipt of each results page. Results passed as param.
 * opts.onComplete = callback fn to run when final page of results is retrieved.
 */
class PaginatedFetch {

    constructor(apiRequest, opts = {}) {
        this.apiRequest = apiRequest;
        this.args = opts.args || {}; // Optional args to be passed in addition to limit and offset.

        this.offset = opts.startIndex || 0;
        this.pageLimit = opts.pageLimit || 20;

        this.onStart = opts.onStart;
        this.onResults = opts.onResults;
        this.onComplete = opts.onComplete;

        this.isStarted = false;
        this.isComplete = false;

        this.next = this.next.bind(this);

        // Attach to a dom element to retrieve next page onscroll event.
        this.onscroll = throttle((e) => {
            if (!this.isComplete) {
                const scrollBox = e.target;
                if (scrollBox) {
                    this.scrollTop = scrollBox && scrollBox.scrollTop;
                    if (scrollBox.scrollTop && scrollBox.scrollTop + scrollBox.offsetHeight > scrollBox.scrollHeight - 100) {
                        return this.next();
                    }
                }
            }
        });
    }

    next() {
        if (!this.isStarted) {
            this.handleStart();
        }
        const promises = [
            this.apiRequest({
                limit: this.pageLimit,
                offset: this.offset,
                ...this.args
            }).then(results => this.handleResults(results))
        ];
        this.offset += this.pageLimit;
        return Promise.all(promises);
    }

    restart(offset = 0) {
        this.isComplete = false;
        this.offset = offset;
        this.next();
    }

    handleStart() {
        this.isStarted = true;
        if (this.onStart) {
            this.onStart();
        }
    }

    handleResults(results) {
        if (results.length < this.pageLimit) {
            this.isComplete = true;
            this.handleComplete();
        }
        if (this.onResults) {
            this.onResults(results);
        }
    }


    handleComplete() {
        this.isComplete = true;
        this.offset = 0;
        if (this.onComplete) {
            this.onComplete();
        }
    }

}

export default PaginatedFetch;
