import HelpBox from 'views/side-nav/help-box';
import Toolbox from 'views/toolbox';
import exportPDFModel from 'models/export-pdf-model';
import onBodyClick from 'legacy/util/dom/on-body-click';
import chargebee from 'util/chargebee';
import formatDate from 'legacy/util/date/format-date';
import ExportMenu from 'views/side-nav/export-menu';
import AccountMenu from 'views/side-nav/account-menu';
import SavedViewsMenu from 'views/side-nav/saved-views-menu';
import api from 'legacy/util/api';
import siteModel from 'models/site-model';
import publish from 'legacy/util/api/publish';
import {downloadMedia} from 'util/media/media-utils';
import store from 'util/data/store';
import popupModel from 'models/popover-model';
import initializer from 'util/initializer';
import appModel from 'models/app-model';
import constants from 'util/data/constants';
import message from 'views/toast-message/toast-message';
import getToolInterface from 'util/interfaces/get-tool-interface';
import ProjectMenu from 'views/side-nav/project-menu';
import panelModel from 'models/panel-model';
import MeMenu from 'views/side-nav/me-menu';
import libraryModel from 'models/library-model';
import tableModel from 'models/table/table-model';
import authManager from 'managers/auth-manager';
import dialogModel from 'models/dialog-model';

const popups = {
    tools: Toolbox,
    help: HelpBox,
    menu: MeMenu,
    export: ExportMenu,
    account: AccountMenu,
    project: ProjectMenu,
    savedViews: SavedViewsMenu,
    progressDashboard: ''
};

const exportFormats = ['XLS', 'CSV', 'GeoJSON', 'PDF Map', 'ESRI Shapefile', 'KML'];

const formatsToCssClass = {
    'XLS': 'UFtracker-export-xls',
    'CSV': 'UFtracker-export-csv',
    'GeoJSON': 'UFtracker-export-geojson',
    'PDF Map': 'UFtracker-export-pdf',
    'ESRI Shapefile': 'UFtracker-export-shp',
    'KML': 'UFtracker-export-kml'
};

initializer.add(() => {
    sideNavModel.close();
    sideNavModel.exportFormats = Array.from(exportFormats);
    sideNavModel.formatsToCssClass = formatsToCssClass;
}, 'sideNavModel');

const sideNavModel = {

    popupFor: id => sideNavModel.popupId === id ? m(popups[id]) : null,

    close() {

        if (sideNavModel.selected) {

            if (sideNavModel.popupId === 'progressDashboard') {
                sideNavModel.isDashboardOpen = false;
            }
            sideNavModel.selected.classList.remove('selected');

            sideNavModel.selected = null;

            sideNavModel.popupId = null;

            m.redraw();

        }

        sideNavModel.exporting = false;

        publish.clearCallbacks('modified', 'media');

    },

    toggle: popupId => e => {

        const target = e.currentTarget;

        const doOpen = sideNavModel.selected !== target;

        popupModel.close();

        libraryModel.quit();

        e.stopPropagation();

        sideNavModel.close();

        if (doOpen) {
            if (popupId === 'progressDashboard') {
                sideNavModel.isDashboardOpen = true;
            }
            target.classList.add('selected');

            sideNavModel.selected = target;

            sideNavModel.popupId = popupId;

            onBodyClick.once(sideNavModel.close);

        }

    },

    cancelExport() {

        sideNavModel.exporting = false;

        if (sideNavModel.abortExport) {

            sideNavModel.abortExport();

        }

    },

    isCustomExport(selectedFormat) {
        return !!appModel.toolbox.customExports[selectedFormat];
    },

    async createExport(selectedFormat, exportFilename) {

        const args = tableModel.projectView.getArgs();

        try {
            await api.rpc.request([['exportContent', Object.assign({
                exportFilename,
                exportFormat: selectedFormat
            }, args, {
                limit: 0,
                offset: 0,
                include: undefined,
                groupBy: undefined,
                order: undefined
            })]]);
            sideNavModel.showExportedSuccessDialog();
        } catch (e) {
            console.error(e);
            message.show('Something went wrong while preparing your export. Please try again or contact support.', 'error');
        }

    },

    showExportedSuccessDialog() {
        dialogModel.open({
            headline: 'Your report is being generated.',
            text: 'We’ll email you when it’s ready for review. Leaving this page won’t affect processing.',
            okayText: 'Okay',
            onOkay: () => {
                sideNavModel.close();
            }
        });
    },

    createFileAsset(mediaId, label) {
        const tool = appModel.toolbox.linkTools.file;
        if (tool.featureTypes) {
            const toolInterface = getToolInterface(tool);
            const asset = toolInterface.createAsset({
                mediaId,
                label
            });
            api.rpc.request([['createContent', api.apiSafeContent(asset)]]);
        }
    },

    formatStartEndDates(startDate, endDate) {
        // If start&end dates not provided, we must still format the date range bc the API requires these params. UE-7051
        if (!startDate) {
            startDate = new Date(store.project.createdDateTime); // Starting at project creation
        }
        if (!endDate) {
            endDate = new Date(); // Up to today
        }

        // Use whichever date is earlier as the start date
        if (startDate > endDate) {
            const _startDate = startDate;
            startDate = endDate;
            endDate = _startDate;
        }
        // The spec calls for start date to be set to midnight
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        // If the end date is today, use the current time; otherwise use one second before midnight.
        // Safe to ignore milliseconds because the API date format doesn't include them.
        const now = new Date();
        if (endDate.getDate() !== now.getDate() || endDate.getMonth() !== now.getMonth() || endDate.getFullYear() !== now.getFullYear()) {
            endDate.setHours(23);
            endDate.setMinutes(59);
            endDate.setSeconds(59);
        }
        startDate = formatDate.forCustomReport(startDate);
        endDate = formatDate.forCustomReport(endDate);
        
        return {startDate, endDate};
    },

    doCustomExport({selectedFormat, transformationID, startDate, endDate}) {
        const dateResult = this.formatStartEndDates(startDate, endDate);
        startDate = dateResult.startDate;
        endDate = dateResult.endDate;  
        const token = authManager.getToken();
        const headers = {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        return fetch(constants.customExportUrl, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                accountId: store.account.accountId,
                projectId: appModel.project.projectId,
                userId: appModel.user.userId,
                transformationId: transformationID,
                startDate,
                endDate
            })
        }).then(result => {
            if (result.status !== 200) {
                console.error('Error preparing export, result status:', result.status);
                message.show('Something went wrong while preparing your export. Please try again or contact support.', 'error');    
                sideNavModel.exporting = false;
                return m.redraw();
            }
            if (sideNavModel.exporting) { 
                const mediaId = result.mediaId;
                publish.await({
                    changeType: 'modified',
                    recordType: 'media',
                    test: change => sideNavModel.exporting && change.mediaId === mediaId && change.status === 'active',
                    callback: async (mediaResponse) => {
                        const label = mediaResponse && mediaResponse.label ? mediaResponse.label : selectedFormat + '.xlsx';
                        await downloadMedia(mediaResponse.mediaId);
                        sideNavModel.createFileAsset(result.mediaId, label);
                        sideNavModel.exporting = false;
                        m.redraw();
                    }
                });
                sideNavModel.showExportedSuccessDialog();
            }
        }).catch(e => {
            console.error(e);
            message.show('Something went wrong while preparing your export. Please try again or contact support.', 'error');
        });
    },

    prepExport(selectedFormat, startDate, endDate) {
        panelModel.close();

        if (selectedFormat === 'PDF Map') {

            sideNavModel.close();

            exportPDFModel.open();

        } else {

            sideNavModel.exporting = true;

            const transformationID = appModel.toolbox.customExports[selectedFormat];

            const isCustomExport = sideNavModel.isCustomExport(selectedFormat);

            const date = new Date();

            const exportFilename = siteModel.name
                .replace(/\s+/g, '-')
                .replace(/[^a-zA-Z\d-]/g, '')
                + `-${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

            if (isCustomExport) {

                this.doCustomExport({selectedFormat, transformationID, startDate, endDate});
                
            } else {
                
                this.createExport(selectedFormat, exportFilename);
    
            }
            
        }

    },

    buyNow() {

        chargebee({accountId: store.account.accountId}).then(() => {
            m.redraw();
        });

    },

    setUpBuyNow() {

        const account = store.account;

        const dateExpires = new Date(account.trialExpiration);

        const dateInterval = formatDate.readableInterval(dateExpires, new Date());

        sideNavModel.showBuyNow = sideNavModel.isTrial = !isNaN(dateInterval.num);

        // Hide "buy now" button if signed up for trial vertical (UE-2754)
        if (!appModel.account.isFieldPlan) {
            sideNavModel.showBuyNow = false;
        }

        if (dateInterval.unit === 'min') {

            dateInterval.unit = dateInterval.num === 1 ? 'minute' : 'minutes';

        }

        if (dateInterval.unit === 'sec') {

            dateInterval.unit = dateInterval.num === 1 ? 'second' : 'seconds';

        }

        sideNavModel.dateInterval = dateInterval;
    },

    openMetaAsset(tab) {
        const metaProjectId = store.account.attributes.metaProjectId;
        const metaProjectAsset = appModel.project.getMetaProjectAsset();
        this.isOpeningMetaAsset = true;
        appModel.changeProject(metaProjectId, {routerParams: {assetId: metaProjectAsset.contentId, tab}});
    }
};

export default sideNavModel;
