import deleteContent from 'legacy/util/data/delete-content';
import FilteredAssetListModel from 'models/filtered-asset-list-model';
import formModel from 'models/form-model';
import constants from 'util/data/constants';
import store from 'util/data/store';
import api from 'legacy/util/api/api';
import router from 'uav-router';
import randomId from 'util/numbers/random-id';
import mediaViewerModel from './media-viewer-model';
import message from 'views/toast-message/toast-message';
import assetListManager from 'managers/asset-list-manager';
import publish from 'legacy/util/api/publish';
import helpers from 'legacy/util/api/helpers';
import oneUpModel from 'models/one-up-model';
import addIfNotInList from 'util/data/add-if-not-in-list';
import savedViewsModel from 'models/saved-views-model';
import ProjectViewModel from 'models/table/project-view-model';
import appModel from 'models/app-model';
import deepCloneObject from 'util/data/deep-clone-object';
import AssetModel from 'models/asset/asset-model';

const LIMIT_20 = 20;

class LinksModel extends FilteredAssetListModel {

    constructor() {
        super();

        this.namedLinks = {};
        this.hiddenNamedLinks = {};
        this.checkForLinkedMedia();
        this.checkForNamedLinks();

        this.onComplete = this._onComplete.bind(this);
    }

    async setProjectView() {
        const projectView = await savedViewsModel.getLinksTabSavedView();
        this.projectView = new ProjectViewModel(deepCloneObject(projectView));
        appModel.setProjectView(this.projectView);
        this.projectView.common.onFiltersChanged = this.filtersChanged.bind(this);
        this.projectView.filters.onFiltersChanged = this.filtersChanged.bind(this);
    }

    /*
     * Add an arg to only fetch contentIds belonging to assets in our linkIds array
     */
    getArgs() {
        const args = super.getArgs();
        args.contentIdIn = store.assets[formModel.assetId].linkIds || [];
        return args;
    }

    uploadOrSelectToLink() {
        this.linkFlowId = randomId();
        const asset = store.assets[this.assetId];

        const callbackReceived = {};

        return oneUpModel.addUploadFlow({
            flowId: this.linkFlowId,
            projectId: router.params.projectId,
            assetId: this.assetId,
            name: 'Links',
            isLink: true,
            canCreateNew: true,
            makeVisible: oneUpModel.cssClass === 'hidden',
            excludedFromResults: asset.linkIds,
            callbackOnEachItem: (flowId, media) => {
                if (media.status === 'active') { // ignore if cancelled by user or upload failed
                    callbackReceived[media.mediaId] = true;
                    this.linkSelections([media]);
                }
            }
        })
            .then((selections) => {
                const selectionsToLink = [];
                selections.forEach(selection => {
                    if (!selection.mediaId || !callbackReceived[selection.mediaId]) {
                        selectionsToLink.push(selection);
                    }
                });
                if (selectionsToLink.length) {
                    this.linkSelections(selectionsToLink);
                }

                if (selections.length && selections.length === 1 && selections[0].label) {
                    message.show(`${selections[0].label} uploaded.`);
                } else {         
                    message.show('Upload successful.');
                }
            });
    }

    resetLinkCount() {
        this.linkCount = store.assets[formModel.assetId].linkIds.length;
        m.redraw();
    }

    async linkSelections(selectedMediaOrAssets) {
        const asset = store.assets[this.assetId];
        const linkIds = asset.linkIds || [];

        formModel.saving.linksTab = true;
        m.redraw();
        for (let i = 0; i < selectedMediaOrAssets.length; i++) {
            const mediaOrAsset = selectedMediaOrAssets[i];

            let linkedAsset = mediaOrAsset;

            // Case: Media selected. We need to create an asset before we can link to it
            if (mediaOrAsset.recordType === 'media') {
                const newAsset = assetListManager.createAssetFromMediaRecord(mediaOrAsset);
                this.showTempAsset = true;
                newAsset.linkIds = [this.assetId];
                m.redraw();
                const apiAsset = await api.rpc.request([['createContent', api.apiSafeContent({...newAsset})]]);
                linkedAsset = {...apiAsset};
            } else {
                // Case: Existing asset selected. Fetch partner asset and update its link:
                const partnerAsset = await assetListManager.fetch(linkedAsset.contentId);
                const linksFromPartnerAsset = partnerAsset.linkIds || [];
                addIfNotInList(linksFromPartnerAsset, this.assetId, true);
                const apiAsset = await api.rpc.requests([['modifyContent', {contentId: partnerAsset.contentId, linkIds: linksFromPartnerAsset}]]);
                assetListManager.addToStore(apiAsset, true);
            }

            // Update this (primary) asset
            addIfNotInList(linkIds, linkedAsset.contentId); // links list in store
            addIfNotInList(this.linkIds, linkedAsset.contentId);  // links list in model
            m.redraw();
        }

        delete this.showTempAsset;
        m.redraw();
    }

    static safeDeleteLinkedAsset(linkId) {
        deleteContent(linkId).then(() => {

            const linksModel = formModel.linksModel;
            const namedLinks = LinksModel.getNamedLinks(formModel.assetId);

            if (namedLinks[linkId]) {
                namedLinks[linkId].forEach(controlName => formModel.removeNamedLink(linkId, controlName));
            }

            if (linksModel) {
                linksModel.assetIds = linksModel.assetIds.filter(id => id !== linkId);
                const parentAsset = store.assets[linksModel.assetId];
                parentAsset.linkIds = parentAsset.linkIds.filter(id => id !== linkId);
                linksModel.resetLinkCount();
            }
        });
        m.redraw();
    }


    /*
     * Checks if a generic unlinking action will result in data changes to a named link (ie, an asset property) value. If so, returns false.
     */
    static canUnlinkGeneric(assetId, linkId) {
        const namedLinksOfLinkedAsset = LinksModel.getNamedLinks(linkId);
        if (namedLinksOfLinkedAsset[assetId]) {
            return false;
        }
        return true;
    }

    /*
     * Removes the link relationship between the asset who's options are being selected and the passed assetId.
     * BE will update partner asset via DB trigger
     */
    static unlink(assetId, linkId) {
        const parentAsset = store.assets[assetId];
        // Remove from list in links model
        if (formModel.linksModel && formModel.linksModel.assetId === linkId) {
            formModel.linksModel.assetIds = formModel.linksModel.assetIds.filter(id => id !== assetId);
            m.redraw();
        }

        // Modify db record
        if (parentAsset.linkIds) {
            parentAsset.linkIds = parentAsset.linkIds.filter(id => id !== linkId);
            api.rpc.request([['modifyContent', {
                contentId: parentAsset.contentId,
                linkIds: parentAsset.linkIds
            }]]).then((assetReturned) => {
                const updatedAsset = store.assets[assetReturned.contentId];
                updatedAsset.linkIds = helpers.list(assetReturned.linkIds);
                store.assets[assetReturned.contentId] = new AssetModel(updatedAsset);
                if (this.hasLinkedMedia) {
                    this.checkForLinkedMedia();
                }
                const oldLinkedAsset = store.assets[linkId];
                if (oldLinkedAsset) {
                    oldLinkedAsset.linkIds = oldLinkedAsset.linkIds.filter(id => id !== assetId);
                    store.assets[linkId] = oldLinkedAsset;
                }
                m.redraw();
            });
        }
    }

    // Is the modified asset one of our links
    isForThisTab(asset) {
        return asset.linkIds && asset.linkIds.indexOf(this.assetId) !== -1 && constants.commentAssetTypeId !== asset.assetTypeId;
    }

    isThisAsset(asset) {
        return asset.contentId === this.assetId;
    }

    onResults(assetIds) {
        formModel.focusOnLinkFeatures(assetIds);
    }


    _onComplete() {
        // Check for hidden named links (they may not have been fetched because of TLP, so we will include them here and render them w/placeholders in the view). 
        Object.keys(this.namedLinks).forEach(linkId => {
            if (!this.assetIds.includes(linkId)) {
                this.assetIds.push(linkId);
                this.hiddenNamedLinks[linkId] = true;
            }
        });
    }

    /**
     * Called upon init of link tab: Checks if any linked assets contain media.
     */
    checkForLinkedMedia() {
        const asset = store.assets[this.assetId] || {};
        if (asset.linkIds && asset.linkIds.length) {
            api.rpc.count('Content', {
                mediaIdsNe: null,
                contentIdIn: store.assets[this.assetId].linkIds || []
            }).then(({count}) => {
                this.hasLinkedMedia = count > 0;
                m.redraw();
            });
        } else {
            this.hasLinkedMedia = false;
            m.redraw();
        }
    }

    static getNamedLinks(assetId) {
        const namedLinks = {};
        const asset = store.assets[assetId];
        let controls;

        // If this is the current asset, we have controls already loaded in formModel
        if (assetId === formModel.assetId) {
            controls = formModel.controls;
        } else {
            const assetFormId = store.assetTypeToFormId[asset.assetTypeId];
            const allControls = store.assetForms[assetFormId].controls;
            controls = allControls.filter(control => !control.attributes.hidden
                && !(control.controlTypeId === constants.controlTypeNameToId.comments)
                    && !(control.controlTypeId === constants.controlTypeNameToId.place));
        }

        controls.forEach(control => {
            if (control.controlTypeId === constants.controlTypeNameToId.asset) {
                const links = asset.properties[control.fieldName] || [];
                links.forEach(id => {
                    namedLinks[id] = namedLinks[id] || [];
                    namedLinks[id].push(control.fieldName);
                });
            }
        });

        return namedLinks;
    }

    checkForNamedLinks() {
        const namedLinks = LinksModel.getNamedLinks(this.assetId);
        this.namedLinks = namedLinks;
        m.redraw();
    }

    /**
     * Begins paginated fetches of all linked assets containing media and opens a gallery viewer with media displayed.
     */
    viewLinkedMedia() {
        this.linkedMediaIds = [];
        mediaViewerModel.wait();
        // Start with the existing assets loaded in the links tab before first fetch:
        const starterAssetIds = this.assetIds.filter(assetId => store.assets[assetId].mediaIds.length);
        if (starterAssetIds.length) {
            this.onLinkedMediaResults(starterAssetIds);
        }
        if (!this.loadedAll) {
            this.getNextPageLinkedMedia(starterAssetIds.length);
        }
    }

    /**
     * Given an array of assets that contain media, opens all the attachments in a gallery.
     */
    onLinkedMediaResults(assetIds = []) {
        assetIds.forEach(assetId => this.linkedMediaIds.push(...store.assets[assetId].mediaIds));
        if (this.linkedMediaIds.length) {
            // In case the user quit early, make sure we're still open or waiting for the first batch of media:
            if (mediaViewerModel.state.isWaiting || mediaViewerModel.state.isOpen) {
                mediaViewerModel.open(this.linkedMediaIds, mediaViewerModel.state.index);
            }
        } else {
            // We only display the view linked media button if linked media exists, but in case an asynchronous change occurred (or an error), have a fallback:
            mediaViewerModel.close();
            message.show('No linked media attachments found.', 'warning');
        }
    }

    /**
     * Retrieves a page of assets linked to the current asset held in state. If results are found,
     * calls function to handle. If limit not reached, calls for next page after offset.
     */
    getNextPageLinkedMedia(offset = 0) {
        const args = {
            limit: LIMIT_20,
            order: 'createdDateTime desc',
            mediaIdsNe: null,
            contentIdIn: store.assets[this.assetId].linkIds || [],
            offset
        };
        api.rpc.list('Content', args, true)
            .then(results => {
                this.onLinkedMediaResults(results.map(asset => {
                    assetListManager.addToStore(asset);
                    return asset.contentId;
                }));
                if (results.length === LIMIT_20) {
                    this.getNextPageLinkedMedia(offset + LIMIT_20);
                }
            });
    }

    syncCurrentAsset(asset) {
        assetListManager.addToStore(asset, true);
        asset.linkIds = helpers.list(asset.linkIds);
        store.assets[asset.contentId] = new AssetModel(asset);
    }

    /**
     * If changes are published to a linked asset, run necessary check to see if linked media existence has changed.
     */
    onContentChange(changeType, assetId) {
        const asset = store.assets[assetId] || {};

        switch (changeType) {
        case 'new':
            if (!this.hasLinkedMedia) {
                this.hasLinkedMedia = asset.mediaIds && asset.mediaIds.length;
            }
            break;
        case 'deleted':
            if (this.hasLinkedMedia) {
                this.checkForLinkedMedia();
            }
            this.checkForNamedLinks();
            break;
        default:
            this.checkForLinkedMedia();
        }

        m.redraw();
    }

    awaitChanges() {
        super.awaitChanges();

        publish.await({
            changeType: 'modified',
            recordType: 'content',
            test: asset => this.isThisAsset(asset) && publish.isValidChangedBy(asset),
            callback: () => {
                this.checkForLinkedMedia();
                this.checkForNamedLinks();
            },
            persist: true
        });

        publish.await({
            changeType: 'deleted',
            recordType: 'thread',
            callback: () => {
                this.assetIds.forEach(assetId => {
                    const asset = store.assets[assetId] || {};
                    if (!asset.contentId) {
                        const index = this.assetIds.indexOf(assetId);
                        if (index !== -1) {
                            this.assetIds.splice(index, 1);
                        }

                        // Check if existence of linked media is now changed.
                        if (this.hasLinkedMedia) {
                            this.checkForLinkedMedia();
                        }
                    }
                });
                m.redraw();
            },
            persist: true
        });
    }

}


export default LinksModel;
