const Radio = {

    oninit: ({attrs: {options, isSort = false, defaultOption, defaultIndex}, state}) => {
        if (isSort) { 
            state.cssClass = ' is-sort';
        }
        if (defaultOption) {
            state.selected = options.indexOf(defaultOption);
        } else if (defaultIndex !== undefined) {
            state.selected = defaultIndex;
        }
    },

    onupdate: ({attrs: {options, defaultOption, reset}, state}) => {
        if (reset) {
            state.selected = options.indexOf(defaultOption);
        }
    },

    view: ({attrs: {options, onchange, optionToCssClass}, state}) =>
        <div class={`radio ${options.length > 3 ? 'over3' : ''}`}>
            {options.map((option, index) => {
                const cssClass = optionToCssClass ? optionToCssClass[option] : '';
                return <div class={`radio-row${state.cssClass || ''}`}>
                    <div key={option} class={`radio-opt${index === state.selected ? ' active' : ''} ${cssClass || ''}`} onclick={() => {
                        state.selected = index;
                        if (onchange) {
                            onchange(option, index);
                        }
                    }}>
                        <span class={`radio-circle${state.cssClass || ''}`}></span>
                        <span class={`radio-opt-label${state.cssClass || ''}`}>{
                            typeof option === 'string'
                                ? option
                                : option.tag && option.attrs ? option : m(option)
                        }</span>
                    </div>
                </div>;
            })}
        </div>
};

export default Radio;
