import mediaListManager from 'managers/media-list-manager';
import ajax from 'legacy/util/api/ajax';

const INVALID_DATE_MESSAGE = 'Invalid Date';

export const parseExpiration = (urlString) => {
    // Expecting: "...&Expires=1666724720"
    try {
        const parts = urlString.split('&Expires=');
        const epochAsNumber = Number(parts[1]);
        const expiration = new Date(epochAsNumber * 1000);
        if (expiration.toString() === INVALID_DATE_MESSAGE) {
            return null;
        }
        return expiration;
    } catch {
        return null;
    }
};

/**
 * If src url & label are already known, download directly using this method.
 * Otherwise, use downloadMedia(mediaId).
 */
export const downloadFromSourceUrl = async (url, label) => {
    // Fetching data from src first lets us avoid this problem:
    // https://developer.chrome.com/blog/chrome-65-deprecations/#block_cross-origin_a_download
       

    return new Promise((resolve, reject) => ajax(url, {
        method: 'GET',
        responseType: 'blob',
        resolve: data => {
            try {
                const objUrl = URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = objUrl;
                link.download = label;
                link.click();
                setTimeout(() => {
                    link.remove();
                    URL.revokeObjectURL(objUrl);
                }, 5000);
                resolve();
            } catch (error) {
                console.error('1 error processing media after download. will attempt to open in new window', error);
                return window.open(url, '_blank');
            } 
        },
        reject: error => {
            console.error('2 error downloading media. will attempt to open in new window', error);
            window.open(url, '_blank');
            reject();
        }
    }));
};

/**
 * Fetching data from src first let's us view it using the pdf viewer library w/out cors blocking 
 */
export const preFetchUrl = async (url) => {
    return new Promise(resolve => ajax(url, {
        method: 'GET',
        responseType: 'blob',
        resolve: data => resolve(data),
        reject: error => {
            console.error(error);
        }
    }));
};


/**
 * Download by passing mediaId rather than srcUrl. 
 */
export const downloadMedia = async (mediaId) => {
    const media = await mediaListManager.getMediaAsync(mediaId);
   
    if (!media || !media.srcUrl) {
        console.error('media not found', mediaId, media);
        return;
    }
    
    return downloadFromSourceUrl(media.srcUrl, media.label);
};

