import store from 'util/data/store';
import {bounds} from 'util/geo';

function featureIsWithinBounds(feature, survey) {

    const tileset = store.tilesets[survey.elevationTilesetId];

    const coordinates = tileset && tileset.bounds && [
        [tileset.bounds[0][1], tileset.bounds[0][0]],
        [tileset.bounds[1][1], tileset.bounds[1][0]]
    ];

    if (coordinates) {

        return bounds({
            type: 'LineString',
            coordinates
        }).contains(feature);

    }

}

function hasElevationData(survey, feature) {

    return survey && survey.hasElevationData && !feature || featureIsWithinBounds(feature, survey);

}

export default hasElevationData;
