import appModel from 'models/app-model';
import sideNavModel from 'models/side-nav-model';
import tableModel from 'models/table/table-model';
import siteModel from 'models/site-model';
import accountManager from 'managers/account-manager';

const SideNav = {

    oninit() {

        const user = appModel.user;

        if (user.status === 'trial') {

            sideNavModel.setUpBuyNow();

        }
    },

    view: () => <div className='side-nav'>
        {appModel.project.isMetaProject ?
            <div> {accountManager.isWhiteLabelAccount ?
                <div className="nav-btn whitelabel-icon UFtracker-u-menu-portfolio" onclick={sideNavModel.toggle('account')}>
                    <img className="company-icon" src={accountManager.whiteLabelData.logos.icon}></img>
                    {sideNavModel.popupFor('account')}</div> :
                <a className="icon-unearth nav-btn UFtracker-u-menu-portfolio" onclick={sideNavModel.toggle('account')}>
                    {sideNavModel.popupFor('account')}</a>
            }
            </div>
            : <div> {accountManager.isWhiteLabelAccount ?
                <div className="nav-btn whitelabel-icon UFtracker-u-menu-portfolio" onclick={sideNavModel.toggle('project')}>
                    <img className="company-icon" src={accountManager.whiteLabelData.logos.icon}></img>
                    {sideNavModel.popupFor('project')}</div> :
                <a className="icon-unearth nav-btn UFtracker-u-menu-project"
                    onclick={sideNavModel.toggle('project')}>
                    {sideNavModel.popupFor('project')}
                </a>
            }
            </div>
        }
        <div class='side-nav-group'>
            {appModel.toolbox.hasAtLeastOneTool && <div class='icon-plus nav-btn UFtracker-plus-menu' onclick={sideNavModel.toggle('tools')}>
                {sideNavModel.popupFor('tools')}
            </div> }
            {appModel.toolbox.areExportsEnabled && <div class='icon-download nav-btn UFtracker-export-menu' onclick={sideNavModel.toggle('export')}>
                {sideNavModel.popupFor('export')}
            </div>}
            <div class={`icon-bookmark-stack nav-btn UFtracker-saved-views-menu saved-views-btn ${tableModel.sidebarButtonClass}`} onclick={sideNavModel.toggle('savedViews')}>
                {sideNavModel.popupFor('savedViews')}
            </div>
            {siteModel.shouldShowDash && <div className='icon-bar-chart nav-btn' onclick={sideNavModel.toggle('progressDashboard')}>
                {sideNavModel.popupFor('progressDashboard')}
            </div>}
        </div>
        {sideNavModel.showBuyNow &&
            <div class="trial-btn UFtracker-buy-now" onclick={sideNavModel.buyNow}>
                <span class="number-callout">{sideNavModel.dateInterval.num}</span>
                <span class="days-free">{sideNavModel.dateInterval.unit} free</span>
                <span class="buy-now">BUY<br />NOW</span>
            </div>}
        <div class="side-nav-bottom">
            <div class="nav-btn UFtracker-help-menu" onclick={sideNavModel.toggle('help')}>
                <div class="icon-qmark">?</div>
                {sideNavModel.popupFor('help')}
            </div>
            <div class="nav-btn UFtracker-me-menu" onclick={sideNavModel.toggle('menu')}>
                <div class="icon-initials">{appModel.user.initials()}</div>
                {sideNavModel.popupFor('menu')}
            </div>
        </div>
    </div>
};

export default SideNav;
