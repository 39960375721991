import drawPaletteModel from 'models/draw-palette-model';
import Toggle from 'views/toggle';

const RotationToggle = {

    view: ({ attrs: { propKey }}) => {
        const value = drawPaletteModel.activeFeature.properties[propKey]; 
        return <Toggle active={value} onchange={doFlip => {
            const newValue = doFlip ? 180 : 0;
            drawPaletteModel.handleInput(propKey, newValue);
        }}/>;
    }
};

export default RotationToggle;
