import mediaListManager from 'managers/media-list-manager';

const MAX_RETRIES = 50;

const tryAgain = (img, media) => {
    if (img && img.src) {
        setTimeout(() => {
            img.src = media.srcUrl + '&retry=' + new Date().getTime();
            m.redraw();
        }, 500);
    }
};

const MediaThumbnailAsync = {
    oninit: async ({state, attrs: {mediaId}}) => {
        state.showLoader = true;
        let retries = 0;
        const media = await mediaListManager.getMediaAsync(mediaId);
        if (!media || !media.mediaId) {
            state.isInvalid = true;
            state.media = undefined;
        } else {
            const testImg = document.createElement('img');
            testImg.onerror = function() {
                if (retries < MAX_RETRIES) {
                    retries += 1;
                    tryAgain(this, media);
                } else {
                    state.isInvalid = true;
                    state.media = undefined;
                    m.redraw();
                }
            };
            testImg.onload = () => {
                state.media = media;
                m.redraw();
            };
            testImg.src = media.srcUrl;
        }
        m.redraw();
    },

    view: ({state: {media, isInvalid}}) => {
        if (media) {
            return <div class="img-wrap">
                <img class="media-thumbnail page-thumb" src={media.srcUrl}/>
            </div>;
        }
        if (isInvalid) { // Edge case, if media fetch fails
            return <span class="media-thumbnail failed-media-thumbnail"><i class="icon-triangle-exclaim"/></span>;
        }
        return <div class="img-wrap"><span class="loader-v2 full-width"/></div>;
    }
};

export default MediaThumbnailAsync;
