import Radio from 'views/radio';
import tableModel from 'models/table/table-model';
import sideNavModel from 'models/side-nav-model';
import stopPropagation from 'util/events/stop-propagation';
import appModel from 'models/app-model';
import DatePicker from 'views/date-picker';
import store from 'util/data/store';
import constants from 'util/data/constants';

const FormatsTab = {
    oninit({state}) {
        state.selectedFormat = 'XLS';
    },
    view: ({state}) => <>
        Export the current dataset as:
        <div>
            <Radio defaultOption={state.selectedFormat} options={sideNavModel.exportFormats} optionToCssClass={sideNavModel.formatsToCssClass} onchange={opt => {
                state.selectedFormat = opt;
            }} />
        </div>
        <div class="center">
            {sideNavModel.exporting
                ? <div class="btn btn-pill btn-small btn-primary disabled">
                    <i class="teal spinner spinning" /> Exporting {tableModel.assetCount} record{tableModel.assetCount === 1 ? '' : 's'}
                </div>
                : <div class="btn btn-pill btn-small btn-primary" onclick={() => sideNavModel.prepExport(state.selectedFormat)}>
                    {state.selectedFormat === 'PDF Map' ? 'Select Options' : 'Export'}
                </div>}
            {sideNavModel.exporting && <a class="export-cancel" onclick={() => sideNavModel.cancelExport()}>Cancel</a>}
        </div>
    </>
};

const NoCustomExports = {
    view: () => <div class="center">
        <img src="/images/no-reports.png" />
        <h3>No Custom Reports.</h3>
        <p>Contact Customer Success to learn how we can help you with regulatory requirements or internal reporting.</p>
        <div class="btn btn-pill btn-small btn-primary" onclick={() => {
            window.open(`mailto:${constants.defaultSupportEmail}`);
            sideNavModel.close();
        }}>Contact CS</div>
    </div>
};

const CustomDateRange = {
    view: ({attrs: {state}}) => {
        const now = new Date();
        return <div>
            Date Range
            <div class={`custom-export-dates${state.dateRange === state.dateRangeOpts[1] ? ' dimmed' : ''}`}>
                <div class="custom-export-label">Start Date</div>
                <DatePicker required={true} defaultDate={state.startDate} max={now} onchange={date => {
                    state.startDate = date;
                }} />
                <div class="custom-export-label">End Date</div>
                <DatePicker required={true} defaultDate={state.endDate} max={now} onchange={date => {
                    state.endDate = date;
                }} />
            </div>
        </div>;
    }
};

const CustomExportTab = {
    oninit({state, attrs: {customExports}}) {
        const customDateRange = {view: () => <CustomDateRange state={state} />};
        state.selectedReport = customExports[0];
        state.dateRangeOpts = [customDateRange, 'All Available Data'];
        state.dateRange = state.dateRangeOpts[0];
        state.startDate = new Date(store.project.createdDateTime);
        state.endDate = new Date();
    },
    view: ({state, attrs: {customExports}}) => {
        return <>
            {customExports.length === 1
                ? <div class="available-report">
                    <div class="custom-export-label">Available Report</div>
                    {state.selectedReport}
                </div>
                : <div class="select-report">
                    <div class="custom-export-label">Select a Report</div>
                    <div class="select-wrapper">
                        <select onchange={e => {
                            state.selectedReport = e.target.value;
                        }}>
                            {customExports.map(exportName => <option value={exportName}>{exportName}</option>)}
                        </select>
                    </div>
                </div>
            }
            <Radio defaultOption={state.dateRange} options={state.dateRangeOpts} onchange={option => {
                state.dateRange = option;
            }} />
            <div class="center">
                {sideNavModel.exporting
                    ? <div class="btn btn-pill btn-small btn-primary disabled">
                        <i class="teal spinner spinning" /> Running Report
                    </div>
                    : <div class="btn btn-pill btn-small btn-primary" onclick={() => {
                        const useCustomDates = state.dateRange === state.dateRangeOpts[0];
                        if (useCustomDates) {
                            sideNavModel.prepExport(state.selectedReport, state.startDate, state.endDate);
                        } else {
                            sideNavModel.prepExport(state.selectedReport);
                        }
                    }}>
                        Run Report
                    </div>}
                {sideNavModel.exporting && <a class="export-cancel" onclick={() => sideNavModel.cancelExport()}>Cancel</a>}
            </div>
        </>;
    }
};

export default {
    oninit: ({state}) => {
        state.tab = 'formats';
        state.customExports = Object.keys(appModel.toolbox.customExports);
    },
    view: ({state}) => {
        return <div class="export-menu side-nav-popup" onclick={stopPropagation()}>
            <div class="side-nav-popup-title">
                Export
                <div class="tabset">
                    <div class={`UFtracker-export-formats-tab tab${state.tab === 'formats' ? ' active' : ''}`} onclick={() => {
                        state.tab = 'formats';
                    }}>File Formats</div>
                    <div class={`UFtracker-export-custom-tab tab${state.tab === 'custom' ? ' active' : ''}`} onclick={() => {
                        state.tab = 'custom';
                    }}>Custom Reports</div>
                </div>
            </div>
            {state.tab === 'custom'
                ? state.customExports.length > 0
                    ? <CustomExportTab customExports={state.customExports} />
                    : <NoCustomExports />
                : <FormatsTab />}
        </div>;
    }

};
