import store from 'util/data/store';
import formModel from 'models/form-model';
import constants from 'util/data/constants';
import featureListManager from 'managers/feature-list-manager';
import TrimbleTip from 'views/trimble-tip/trimble-tip';

const LNG_RANGE = 180;
const LAT_RANGE = 90;

const TrimbleCoordinatesControl = {
    oninit: ({state, attrs: {featureId, assetId, control}}) => {
        state.trimbleFeature = featureListManager.getById(featureId);

        const asset = store.assets[assetId];
        const properties = asset.properties;
        const point = properties[control.fieldName] = properties[control.fieldName] || {
            type: 'Point',
            coordinates: []
        };

        state.lng = point.coordinates[0];
        state.lat = point.coordinates[1];
    
        if (!state.lng && !state.lat && state.trimbleFeature) {
            if (state.trimbleFeature.properties._locations) {
                // In case the feature data wasn't synced with the asset properties, display it anyway: UE-6285
                const trimbleData = Object.values(state.trimbleFeature.properties._locations)[0];
                state.lng = trimbleData.longitude;
                state.lat = trimbleData.latitude;
            }
        }
    
    },

    view: ({state: {lat, lng, trimbleFeature}}) => {
        return <div class="coordinates-control disabled">
            <div class="latitude-row">
                <label class="sublabel">Latitude</label>
                <span class="disabled-text-input">{lat}</span>
            </div>
            <div class="longitude-row">
                <label class="sublabel">Longitude</label>
                <span class="disabled-text-input">{lng}</span>
            </div>
            <TrimbleTip feature={trimbleFeature}/>
        </div>;
    } 
};

export default function(control, assetId) {
    const asset = store.assets[assetId];
    const properties = asset.properties;
    const point = properties[control.fieldName] = properties[control.fieldName] || {
        type: 'Point',
        coordinates: []
    };
    const trimbleFeatureId = asset.featureIds && asset.featureIds
        .find(featureId => {
            const feature = featureListManager.getById(featureId);
            if (feature && feature.properties && feature.properties[constants.trimbleDataKey]) {
                const featureType = feature.featureType;
                const controlIsLinked = featureType.attributes && featureType.attributes.linkedControls && featureType.attributes.linkedControls.find(controlLabel => controlLabel === control.fieldName);
                return controlIsLinked;
            }
            return false;
        });
    const lng = point.coordinates[0];
    const lat = point.coordinates[1];

    return trimbleFeatureId ? <TrimbleCoordinatesControl featureId={trimbleFeatureId} assetId={assetId} control={control}/> : <div class="coordinates-control">
        <div class="latitude-row">
            <label class="sublabel">Latitude</label>
            <input type="text" value={lat} oninput={e => {
                formModel.handleCoordinate(e, 1, point, control);
            }}/>
            <div class="error-msg relative error-coord">Latitude must be between -{LAT_RANGE} and +{LAT_RANGE}.</div>
        </div>
        <div class="longitude-row">
            <label class="sublabel">Longitude</label>
            <input type="text" value={lng} oninput={e => {
                formModel.handleCoordinate(e, 0, point, control);
            }}/>
            <div class="error-msg relative error-coord">Longitude must be between -{LNG_RANGE} and +{LNG_RANGE}.</div>
        </div>
    </div>;
}
