import helpers from 'legacy/util/api/helpers';

export const canAutomateLayerCreation = (media) => {
    return media && !!media.attributes && !!media.attributes.layerMediaIds;
};

export const isSinglePage = (document) => {
    const pageIds = helpers.list(document.pageIds);
    return pageIds.length && pageIds.length === 1;
};
