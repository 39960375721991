const logConstants = {
    errorLogMessages: {
        BATCH_MODIFY_FAILURES: 'ue_web_logs batch modify failures' + new Date(),
        BATCH_DELETE_FAILURES: 'ue_web_logs batch delete failures' + new Date(),
        HUBSPOT_FAILURES: 'ue_web_logs hubspot upload failures' + new Date(),
        GRAPHQL_FETCH_FAILURES: 'ue_web_logs graphql 1 fetch failures ' + new Date(),
        GRAPHQL_RESPONSE_STATUS_ERROR: 'ue_web_logs graphql 2 response status error code' + new Date(),
        GRAPHQL_RESPONSE_ERROR: 'ue_web_logs graphql 3 response error' + new Date(),
        CREATE_DOCUMENT_FAILURE: 'ue_web_logs createDocument response error',
        CREATE_PLAN_FAILURE: 'ue_web_logs createPlan response error',
        UNFIXED_CONFLICTING_PARENT_ID: 'ue_web_logs unfixed conflicting parentId',
        ITEM_MISSING_FROM_FOLDER: 'ue_web_logs item missing from folder'
    }
};

export default logConstants;
