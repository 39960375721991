
export const supported = {
    extensions: {
        pdf: 1,
        odt: 1,
        ods: 1,
        odp: 1,
        odg: 1,
        odf: 1
    },
    mimeTypes: {
        'application/pdf': 1
    }
};

export const heicMimeType = 'image/heic';

export const imageMimeTypes = {
    'image/png': 1,
    'image/jpg': 1,
    'image/jpeg': 1,
    'image/bmp': 1,
    'image/gif': 1,
    'image/webp': 1,
    'image/heic': 1
};

export const fileTypeIconIds = {
    video: '8VgMxsdsLPsWGQjL8iYl',
    audio: 'pnCZ3GDXlIi-L5PCNTtx',
    image: 'E1wQRT_baM7Vj3hb3j3w'
};

