import toolboxManager from '../managers/toolbox-manager';
import api from '../legacy/util/api/api';
import {dashURL} from '../util/data/env';
// const SUPERSET_URL = 'http://super-loadb-ws13wkspoefg-72f719f174653358.elb.us-east-1.amazonaws.com:8088';
const SUPERSET_URL = dashURL;

class SupersetModel {

    constructor() {
        this.jwt = '';
        this.refreshToken = '';
    }

    setID() {
        this.dashboardId = toolboxManager.progressDashID;
        this.dashboardParam = 'dashboard_id=' + this.dashboardId;
    }

    get iframeUrl() {
        this.setID();
        return  SUPERSET_URL + '/login/?' + this.dashboardParam + '&token=' + this.jwt;
    }


    setJWT(jwt, refreshToken) {
        this.jwt = jwt;
        this.refreshToken = refreshToken;
        m.redraw();
    }

    login() {
        api.rpc.request([['getSupersetTokens', {}]]).then(response => {
            this.setJWT(response.access_token, response.refresh_token);
        });
    }
}

const supersetModel = new SupersetModel();

export default supersetModel;
