import popupModel from 'models/popover-model';
import activeCell from 'models/table/active-cell-model';
import formModel from 'models/form-model';
import store from 'util/data/store';
import peopleModel from 'models/people/people-model';
import tableModel from 'models/table/table-model';
import FormControl from 'views/form-control';
import placeModel from 'models/place-model';
import constants from 'util/data/constants';
import batchSelectModel from '../../models/batch-select-model';
import ControlLabel from 'views/control-label/control-label';

const derivedField = 'Derived value cannot be directly edited.';
const readonlyField = 'This is a read-only field and cannot be edited.';
const userPermissions = <><i class="icon-permission-no"/><span class="permissions-text">You do not have permission to edit this property.</span></>;
const idOnly = 'The ID for this asset type is not editable.';

const ErrorPopup = {
    view: ({attrs: {message}}) =>
        <div class="asset-form-popup form-control-popup-wrap">
            <div class="form-control-popup-body">
                <i onclick={() => {
                    popupModel.close();
                    formModel.viewAsset(activeCell.assetId, 'Properties');
                }} class="icon-asset-form"/>
                <span class="popup-message">{message}</span>
            </div>
        </div>
};

// Wrapper for editing a form control within table view popup.
const AssetEditorPopup = {

    oninit: ({state}) => {
        state.control = activeCell.cell.controlType;
        state.assetId = activeCell.assetId;
        state.asset = store.assets[state.assetId];
        state.editingControls = [];
        if (state.control !== 'places') {
            state.fieldName = state.control.fieldName;
            state.wrapClass = `form-control-popup-form ${constants.controlTypeIdToName[state.control.controlTypeId]}-form-control`;
        }
        if (batchSelectModel.assetsToModify[state.assetId] && Object.keys(batchSelectModel.assetsToModify).length > 1) {
            batchSelectModel.editAllMode(true);
        }
    },

    view: ({state}) => {
        if (!activeCell.state.isActive) {
            return;
        }
        const isInBatch = batchSelectModel.assetsToModify[state.assetId] && Object.keys(batchSelectModel.assetsToModify).length > 1;
        const asset = store.assets[state.assetId];
        const isSaving = state.control === 'places' ? placeModel.isLoading : formModel.saving[state.fieldName];
        const recentEditMessage = asset.recentlyEdited ? `${peopleModel.displayNameOrEmail(asset.changedBy.userId)} has recently edited this ${store.assetTypes[asset.assetTypeId].name}` : '';
        const recentBatchModifyMessage = tableModel.showBatchModifyNotice ? <span>A batch of asset edits was recently processed. <span class='linked-text' onclick={tableModel.reload}>Reload table data<i class='icon-undo'/></span></span> : '';
        return <div class={`asset-form-popup form-control-popup-wrap ${isSaving ? ' saving' : ''}`}>
            {!!recentEditMessage && <div class='recently-edited-wrap'><div class='recently-edited-message'>{recentEditMessage}</div></div>}
            {!!recentBatchModifyMessage && <div class='recently-edited-wrap recent-batch-modify-wrap'><div class='recently-edited-message'>{recentBatchModifyMessage}</div></div>}
            <div class={`${isInBatch ? ' is-batch-edit' : ''}`}>
                {isInBatch ?
                    <div className={`edit-scope-control ${batchSelectModel.editingAll ? ' edit-all-selections' : ' edit-this-one'}`}>
                        <div className="radio-row option-all-selections UFtracker-edit-all-selections" onclick={(e) => {
                            e.stopPropagation();
                            batchSelectModel.editAllMode(true);
                        }}
                        ><span class="radio-circle"></span><span class="radio-opt-label">{`Edit all ${tableModel.activeCell.isInCombinedColumn() ?  '' : store.assetTypes[asset.assetTypeId].name } selections  ${tableModel.activeCell.isInCombinedColumn() ?  'with this Property' : '' } (${Object.keys(batchSelectModel.assetsToModify).length})`}</span></div>
                        <div className="radio-row option-this-one UFtracker-edit-this-one" onclick={() => batchSelectModel.editAllMode(false)}>
                            <span class="radio-circle"></span><span class="radio-opt-label">Edit this {store.assetTypes[asset.assetTypeId].name} only</span></div>
                    </div> : <>
                    </>
                }
            </div>
            <div class="form-control-popup-label">   
                <ControlLabel control={state.control}/>
            </div>
            <div class={state.wrapClass}>
                <FormControl control={state.control} assetId={state.assetId} opts={{isPopup: true, reset: activeCell.changeUndone}}/>
            </div>
            <div class={'form-control-popup-footer'}>
                {isSaving ?
                    <div className={`${isSaving ? ' saving' : ''}`}>
                        <i className="spinner spinning"/><label class="has-spinner autosave-status-label">Saving</label>
                    </div> : <>
                        {activeCell.isUndoable ?
                            <div className="sublabel form-control-popup-revert UFtracker-revert-edit-in-popover" onclick={() => activeCell.undoAssetChange()}>Revert</div> :
                            <div className={'asset-form-popup form-control-popup-wrap saving'}>
                                <i/><label class="unchanged autosave-status-label">Unchanged</label>
                            </div>
                        }
                    </>
                }
                <div class="btn btn-primary btn-tiny btn-pill form-control-popup-done UFtracker-done-editing-in-popover" onclick={() => activeCell.assetRow.validateThenClose(state.assetId, state.control)}>Done</div>
            </div>
        </div>;
    }
};

export const popups = {
    derived: {view: () => m(ErrorPopup, {message: derivedField})},
    readonly: {view: () => m(ErrorPopup, {message: readonlyField})},
    disabled: {view: () => m(ErrorPopup, {message: userPermissions})},
    id: {view: () => m(ErrorPopup, {message: idOnly})},
    assetEditor: {view: () => m(AssetEditorPopup)}
};

class Popover {
    oncreate() {
        window.addEventListener('resize', popupModel.close);
    }

    onclose() {
        window.removeEventListener('resize', popupModel.close);
    }

    view() {
        if (!popupModel.isOpen) {
            return undefined;
        }
        const View = popupModel.view;
        const args = popupModel.args || {};
        const coordinates = `top:${popupModel.coordinates.y}px; left:${popupModel.coordinates.x}px;${popupModel.coordinates.width ? ` width:${popupModel.coordinates.width}px` : ''}`;
        return <div onclick={(e) => e.stopPropagation()}
            className={`table-popup ${popupModel.wrapClass}`}
            style={coordinates}>
            <View {...args}/>
        </div>;
    }
}

export default Popover;
