import router from 'uav-router';
import store from 'util/data/store';
import peopleModel from 'models/people/people-model';
import {ProfilePreview} from 'views/people/profile';
import appModel from 'models/app-model';
import popupModel from 'models/popover-model';
import capitalize from 'util/data/capitalize';
import {getAddRemovePeopleMessage} from 'util/permissions/restriction-message';

const ProjectPeopleTab = {

    oninit: ({state}) => {
        state.initProject(state);
        state.siteTermCapitalized = capitalize(appModel.toolbox.siteTermSingular);
    },

    onupdate: ({state}) => {
        if (state.assetId !== router.params.assetId) {
            state.initProject(state);
        }
    },

    initProject: (state) => {
        const asset = store.assets[router.params.assetId];
        state.projectType = store.assetTypes[asset.assetTypeId].name.toLowerCase();
        peopleModel.getPeopleForProjectId().then((projectPeople) => {
            state.projectPeople = projectPeople;
            state.userCompany = appModel.user.company || '';
            state.assetId = router.params.assetId;
            m.redraw();
        });
    },

    view({state}) {
        const projectPeople = state.projectPeople;
        const userCount = projectPeople ? projectPeople.list.length : '...';
        const userCountOtherCompany = projectPeople ? projectPeople.userAccessCount - projectPeople.companyCount : '...';
        return <div class="tab-content project-people-list" onscroll={popupModel.close}>
            {appModel.user.isAccountAdmin && <div class="people-list-header">
                <div class="people-list-title">People</div>
                <button class="btn btn-secondary btn-pill btn-smaller btn-add-person" onclick={() => peopleModel.openNewPerson()}><i class="icon-plus" /><i class="icon-user" /></button>
            </div>}
            {projectPeople ? <>
                {appModel.user.restrictPeopleListByCompany() ? <>
                    <span className="full-width-label">{userCountOtherCompany} {userCountOtherCompany === 1 ? 'Person from another Company has' : 'People from other Companies have'} access to this {state.siteTermCapitalized}.</span>
                    <span className="manager-access-messaging">{appModel.user.getPeopleAccessMessaging()}</span>
                    {!appModel.user.company ?
                        <div className="people-list-title">Please <a href="mailto:unearthsupport@procore.com">contact our support team</a> to update your Company so that you can view your team.</div> :
                        <span className="company-name-count"><span class="company-name">{state.userCompany}</span>({projectPeople.companyCount})</span>}
                </> : <span className="company-name-count">{projectPeople.userAccessCount} (of {userCount}) {userCount === 1 ? 'Person has' : 'People have'} access to this {state.siteTermCapitalized}.</span>}
                <div class="project-people-list-inner">
                    {projectPeople.list.map(userId => {
                        const person = peopleModel.getPerson(userId);
                        const personHasAccess = projectPeople.personHasAccess(userId);       
                        const editingLockedMessage = getAddRemovePeopleMessage(person);
                        const handleClick = () => peopleModel.toggleProjectAccess(userId, projectPeople.projectId); // Same fn for checkbox or profile card click 
                        if (appModel.user.restrictPeopleListByCompany()) {
                            if (person.company && person.company === state.userCompany) {
                                return <>
                                    <div class={`project-person ${personHasAccess ? 'has-access' : 'no-access'}`}>
                                        <ProfilePreview person={person} handleClick={handleClick} isPermissionToggled={personHasAccess} editingLockedMessage={editingLockedMessage}/>
                                    </div> </>;
                            }
                            if (!state.userCompany && person.isSelf) {
                                return <>
                                    <div class={`project-person ${personHasAccess ? 'has-access' : 'no-access'}`}>
                                        <ProfilePreview person={person} handleClick={handleClick} isPermissionToggled={personHasAccess} editingLockedMessage={editingLockedMessage}/>
                                    </div> </>;
                            }
                            return <></>;
                        }
                        return <div class={`project-person ${personHasAccess ? 'has-access' : 'no-access'}`}>
                            <ProfilePreview person={person} handleClick={handleClick} isPermissionToggled={personHasAccess} editingLockedMessage={editingLockedMessage}/>
                        </div>;

                    })}
                </div>
                {appModel.user.isAccountAdmin &&
                    <div class="add-person-input" onclick={() => peopleModel.openNewPerson()}>
                        <span class="add-person-tip">Add a new person to this account.</span>
                        <span class="btn btn-pill btn-secondary btn-smaller">
                            <span class="btn-label">
                                <i class="icon-plus"></i><i class="icon-user icon"></i></span>
                        </span>
                    </div>}
            </> : <> <span class="full-width-label">Loading People with access to this {state.siteTermCapitalized} ...</span>
                <span class="spinner spinning"></span> </>
            }
        </div>;
    }
};

export default ProjectPeopleTab;
