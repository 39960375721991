import batchModifyModel from 'models/batch-modify-model';
import tableModel from 'models/table/table-model';
import permissions from 'managers/permissions-manager';
import PermissionsIndicator from 'views/permissions/permissions-indicator';
import restrictions from 'util/permissions/restriction-message';
import appModel from 'models/app-model';

const AssetTypeMenu = {

    oninit: ({state}) => {
        state.selected = batchModifyModel.assetTypeId;
    },

    view({state}) {
        return <div class="asset-type-menu filter-menu" onclick={(e) => e.stopPropagation()}>
            <div class="asset-type-menu-title">Type</div>
            <span class="btn btn-circle btn-smallest btn-secondary" onclick={batchModifyModel.closeAssetMenu}><i className="icon-up" /></span>
            <div class="filter-menu-inner">
                <div class="filter-contents">
                    {Object.keys(batchModifyModel.assetTypesByToolGroup).map((toolGroupId) => {
                        const toolGroup = appModel.toolbox.toolGroups[toolGroupId];
                        const assetTypes = batchModifyModel.assetTypesByToolGroup[toolGroupId];
                        return assetTypes.length > 0 ? <div class="filter-section">
                            <div class="filter-section-title">{assetTypes.length > 0 && toolGroup.name}</div>
                            {assetTypes.map((assetType) => {
                                const assetTypeId = assetType.assetTypeId;
                                const assetCount = tableModel.assetTypeCounts[assetTypeId];
                                const isPermittedOption = permissions.canEditAssetType(assetTypeId);
                                return <div class="filter-option-row radio-row">
                                    <div onclick={isPermittedOption && assetCount > 0 ? () => {
                                        batchModifyModel.selectAssetType(assetTypeId);
                                        state.menuOpen = false;
                                    } : null} class={`filter-option radio-opt ${batchModifyModel.assetTypeId === assetTypeId ? 'active' : 'inactive'}${isPermittedOption ? '' : ' disabled'}${assetCount ? ' has-assets' : ' no-assets'}`}>
                                        <span class="radio-circle"></span>
                                        <span class="filter-option-label">{assetType.name}</span>
                                        <span class="filter-option-count">{assetCount || '0'}
                                            <PermissionsIndicator lockedMessage={isPermittedOption ? '' : restrictions.message(appModel.user, 'edit', assetTypeId)} />
                                        </span>
                                    </div>
                                </div>;
                            })}
                        </div> : '';
                    })}
                </div>
            </div>
        </div>;
    }
};

export default AssetTypeMenu;
