import popup from 'util/popup';
import oneUpModel from 'models/one-up-model';
import router from 'uav-router';
import constants from 'util/data/constants';
import store from 'util/data/store';
import featureToControl from 'util/interfaces/feature-to-control';
import controlToFeature from 'util/interfaces/control-to-feature';
import SymbolInterface from 'util/interfaces/symbol';
import appModel from 'models/app-model';
import featureListManager from 'managers/feature-list-manager';

class IModelInterface extends SymbolInterface {

    constructor(...args) {

        super(...args);

        this.type = 'imodel';

    }

    launch() {

        popup.remove();

        const asset = store.assets[this.assetId];
        const controlTypeId = constants.controlTypeNameToId.imodel;

        const acceptedAssetTypeIds = [];

        Object.values(appModel.toolbox.tools).forEach(tool => {
            if (tool.assetForm.controls.find(c => c.controlTypeId === controlTypeId)) {
                acceptedAssetTypeIds.push(tool.assetForm.assetType.assetTypeId);
            }
        });

        return oneUpModel.addUploadFlow({
            projectId: router.params.projectId,
            name: this.tool.name,
            close: () => this.close(),
            iModel: true,
            acceptedAssetTypeIds
        }).then((iModel) => {

            featureToControl.sync('imodel', iModel, this.assetId, this.featureType);

            const { project } = iModel;

            if (project.longitude && project.latitude) {

                asset.featureIds = [this.feature.id];

                this.feature.geometry.coordinates = [parseFloat(project.longitude), parseFloat(project.latitude)];

                controlToFeature.syncAllFeatureProperties(this.assetId, this.feature.featureId);

                return featureListManager.addFeatures([this.feature])
                    .then(() => [asset]);

            }

            asset.featureIds = [];

            featureListManager.removeFeature(this.feature);

            return [asset];

        });

    }
}

export default IModelInterface;
