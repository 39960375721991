import featureToControl from 'util/interfaces/feature-to-control';
import PolygonInterface from 'util/interfaces/polygon';
import ProjectPrompt from 'views/project-prompt';
import loadExternal from 'legacy/util/data/load-external';
import SearchBoxModel from 'models/search-box-model';
import store from 'util/data/store';
import api from 'legacy/util/api';
import {lngLatsToBounds, lngLatsToLatLngs} from 'util/geo';
import message from 'views/toast-message/toast-message';
import webOnly from 'util/web-only';
import constants from 'util/data/constants';
import controlToFeature from 'util/interfaces/control-to-feature';
import formModel from 'models/form-model';
import siteModel from 'models/site-model';
import userflow from 'util/userflow/userflow';
import appModel from 'models/app-model';
import randomId from 'util/numbers/random-id';
import FeatureModel from 'models/feature/feature-model';
import featureListManager from 'managers/feature-list-manager';
import helpers from 'legacy/util/api/helpers';
import uploadHelpFlow from 'flows/upload-help/upload-help-flow';
import addIfNotInList from 'util/data/add-if-not-in-list';

const SUGGEST = SearchBoxModel.SUGGEST;
class ProjectInterface extends PolygonInterface {

    constructor(...args) {
        super(...args);
        this.updatePrompt = null;
        this.type = 'project';
        // Prevents the "skip mapping" from rendering
        this.requireFeature = true;
    }

    initGoogle() {
        loadExternal.loadGoogle().then(() => {
            this.autocomplete = new window.google.maps.places.AutocompleteService();
            this.geocoder = new window.google.maps.Geocoder();
        });
    }

    suggest(query) {

        this.query = query;

        if (!this.autocomplete) {
            return Promise.resolve([]);
        }

        return new Promise(resolve => {

            if (query.indexOf(',') !== -1 && query.match(/^[\d., -]+$/)) {

                const coordinates = query.split(',');

                this.geocoder.geocode({
                    location: {
                        lat: Number(coordinates[0]),
                        lng: Number(coordinates[1])
                    }
                }, results => resolve(results.map(() => ({
                    text: `${coordinates[0]}, ${coordinates[1]}`,
                    type: SUGGEST.GO_TO_COORD,
                    iconClass: 'icon-center-on-map'
                }))));

            } else {

                this.autocomplete.getPlacePredictions({
                    input: query
                }, results => resolve(results.map(result => ({
                    type: SUGGEST.GO_TO_ADDR,
                    placeId: result.place_id,
                    text: result.structured_formatting.main_text,
                    secondLine: result.structured_formatting.secondary_text
                }))));

            }

        });
    }

    async edit(_feature) {
        const projectId = formModel.childProjectId;
        this.project = await api.rpc.request([['getProject', {projectId}]]);
        super.edit(_feature);
    }

    setToolboxId(toolboxId) {
        this.toolboxId = toolboxId;
        this.done();
    }

    setPrompt() {
        this.prompt = ProjectPrompt;
    }

    verifyDone() {
        const coords = this.feature.geometry.coordinates[0];
        const featureIsDone = coords && coords.length > 3;
        const hasPresetToolbox = this.featureType.attributes.toolbox;
        if (featureIsDone && hasPresetToolbox) {
            message.show(`Creating ${this.tool.name}...`);
        }
        return this.toolboxId && featureIsDone;
    }

    beforeDone() {
        return new Promise(resolve => {
            message.show(`Creating ${this.tool.name}...`);
            const coordinates = this.feature.geometry.coordinates[0];
            
            let bounds = lngLatsToBounds(coordinates);
            bounds = [
                [bounds.getNorth(), bounds.getWest()],
                [bounds.getSouth(), bounds.getEast()]
            ];
            const name = this.tool.name;
            Promise.all([
                api.post.project({
                    name,
                    accountId: store.account.accountId
                }),
                api.post.site({
                    name,
                    bounds
                })
            ]).then(([project, newSite]) => {

                const projectId = project.projectId;

                if (appModel.project.isMetaProject && appModel.user.projectIds && !appModel.user.isSuperAdmin) {
                    appModel.user.projectIds.push(projectId);
                }

                const newSiteBoundary = [lngLatsToLatLngs(coordinates)];
                const promises = [
                    api.post.projectSite({
                        projectId,
                        siteId: newSite.siteId,
                        name,
                        bounds,
                        boundary: {
                            type: 'Polygon',
                            coordinates: newSiteBoundary
                        }
                    })
                ];

                const toolboxId = this.toolboxId;
                // If it's a base toolbox, it must be provisioned for the account
                // before we can add it to the project
                if (Object.values(constants.baseToolboxes).find(t => t.toolboxId === toolboxId)) {

                    promises.push(
                        api.rpc.request([['provisionToolbox', {
                            targetAccountId: store.account.accountId,
                            baseToolboxId: toolboxId
                        }]]).then(toolbox => api.rpc.request([['addProjectToolbox', {
                            projectId,
                            toolboxId: toolbox.toolboxId
                        }]]))
                    );

                } else {

                    promises.push(
                        api.rpc.request([['addProjectToolbox', {
                            projectId,
                            toolboxId
                        }]])
                    );

                }

                featureToControl.sync(this.type, project, this.assetId, this.featureType);
                controlToFeature.syncAllFeatureProperties(this.assetId, this.feature.featureId || this.feature.id);
                addIfNotInList(store.account.projectIds, projectId);

                userflow.track('project_created');

                return Promise.all(promises).then(() => {
                    message.hide();
                    webOnly('createsite', resolve);
                });

            });
        });
    }

    setGeoJSONData(geoJson, projectName) {
        this.geoJson = geoJson;
        this.projectName = projectName;
        if (this.onProjectComplete) {
            this.onProjectComplete();
        }
    }


    async createProjectFromBounds() {

        const asset = store.assets[this.assetId];
        const feature = {
            type: 'Feature',
            id: randomId(),
            geometry: Object.assign({}, {type: 'Polygon'}, {
                coordinates: this.geoJson.coordinates
            }),
            properties: Object.assign({
                assetId: this.assetId,
                featureTypeId: this.featureType.featureTypeId
            }, this.featureType.getDefaultProperties())
        };
        this.feature = new FeatureModel(feature);
        
        return new Promise(resolve => {
            let bounds = lngLatsToBounds(this.geoJson.coordinates[0]);
            bounds = [
                [bounds.getNorth(), bounds.getWest()],
                [bounds.getSouth(), bounds.getEast()]
            ];
            const name = this.projectName || this.tool.name;
            Promise.all([
                api.post.project({
                    name,
                    accountId: store.account.accountId
                }),
                api.post.site({
                    name,
                    bounds
                })
            ]).then(([project, newSite]) => {

                const projectId = project.projectId;
                formModel.childProjectId = projectId;

                uploadHelpFlow.childProjectId = project.projectId;
                uploadHelpFlow.childProjectSiteId = newSite.siteId;

                if (appModel.project.isMetaProject && appModel.user.projectIds && !appModel.user.isSuperAdmin) {
                    appModel.user.projectIds.push(projectId);
                }

                const coords = [...this.feature.geometry.coordinates[0]]; 
                const newSiteBoundary = [lngLatsToLatLngs(coords)];
             
                const promises = [
                    api.post.projectSite({
                        projectId,
                        siteId: newSite.siteId,
                        name,
                        bounds,
                        boundary: {
                            type: 'Polygon',
                            coordinates: newSiteBoundary
                        }
                    })
                ];

                const toolboxId = this.toolboxId || appModel.toolbox.toolInterface.toolboxId;

                // If it's a base toolbox, it must be provisioned for the account
                // before we can add it to the project
                if (Object.values(constants.baseToolboxes).find(t => t.toolboxId === toolboxId)) {

                    promises.push(
                        api.rpc.request([['provisionToolbox', {
                            targetAccountId: store.account.accountId,
                            baseToolboxId: toolboxId
                        }]]).then(toolbox => api.rpc.request([['addProjectToolbox', {
                            projectId,
                            toolboxId: toolbox.toolboxId
                        }]]))
                    );

                } else {

                    promises.push(
                        api.rpc.request([['addProjectToolbox', {
                            projectId,
                            toolboxId
                        }]])
                    );

                }
                userflow.track('project_created');

                featureListManager.all[this.feature.featureId] = this.feature;
                asset.featureIds = helpers.list(asset.featureIds);
                asset.featureIds.push(this.feature.featureId);
                featureToControl.sync(this.type, project, this.assetId, this.featureType);
                controlToFeature.syncAllFeatureProperties(this.assetId, this.feature.featureId || this.feature.id);
                addIfNotInList(store.account.projectIds, projectId);
                return Promise.all(promises).then(() => {
                    message.hide();
                    webOnly('createsite', resolve);
                });

            });
        });
    }

    close() {
        super.close();
        if (siteModel.map.searchPopup) {
            siteModel.map.searchPopup.remove(); // Remove left over search popup and circle if it exists
        }
    }

}

export default ProjectInterface;
