const isElementVisible = (el, parent) => {
    const rect = el.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;
    return elemTop >= 0 && elemBottom <= parent.offsetHeight;
};

const findFirstVisibleIndex = (e) => {
    let i = 0;
    const parent = e.target.querySelector('.asset-rows');
    let el = parent && parent.firstElementChild;

    while (el && !isElementVisible(el, parent)) {
        i++;
        el = el.nextElementSibling;
    }

    return i;
};

export default {findFirstVisibleIndex, isElementVisible};
