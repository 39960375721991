import store from 'util/data/store';
import layerModel from 'models/layer-model';
import appModel from 'models/app-model';
import sideNavModel from 'models/side-nav-model';
import placesTabModel from 'models/places-tab-model';
import placeEditorModel from 'models/place-editor-model';

const initForPlacesTab = (state) => {
    const places = Object.values(placesTabModel.places);
    if (places.length) {
        state.sortedPlaces = places.sort((place1, place2) => place1.name.toLowerCase() > place2.name.toLowerCase() ? 1 : -1);
    }
    state.togglePlaces = placesTabModel.togglePlaces;
    state.togglePlace = placesTabModel.togglePlace;
    state.isPlaceToggledOn = placesTabModel.isPlaceToggledOn;
    state.arePlacesToggledOn = placesTabModel.arePlacesToggledOn;
    state.isLayerBeingEdited = (placeId) => placeEditorModel.placeId === placeId && placeEditorModel.showPlaceForm;
};

const initStandard = (state) => {
    const places = Object.values(store.places);
    if (places.length) {
        state.sortedPlaces = places.sort((place1, place2) => place1.name.toLowerCase() > place2.name.toLowerCase() ? 1 : -1);
    }
    state.togglePlaces = layerModel.togglePlaces;
    state.togglePlace = layerModel.togglePlace;
    state.isPlaceToggledOn = (placeId) => layerModel.state.placeIds.has(placeId);
    state.arePlacesToggledOn = () => layerModel.state.doShowPlaces;
    state.isLayerBeingEdited = () => false;
};

const PlaceLayer = {
    view: ({attrs: {place, togglePlace, isPlaceToggledOn, isLayerBeingEdited}}) =>
        <div class={`${isLayerBeingEdited(place.placeId) ? 'editing-layer' : ''} layer-picker-item`} onclick={() => isLayerBeingEdited(place.placeId) ? null : togglePlace(place)}>
            <i class={`toggle-switch toggle-is-${isLayerBeingEdited(place.placeId) || isPlaceToggledOn(place.placeId) ? 'on' : 'off'}`} />
            <span class="layer-picker-place-name">{place.name} {isLayerBeingEdited(place.placeId) ? '(Editing)' : ''}</span>
        </div>
};

const SiteBounds = {
    view: () =>
        <div>
            <div class={'layer-picker-item layer-picker-item-places'} onclick={() => layerModel.toggleSiteBounds()}>
                <i class={`toggle-switch toggle-is-${layerModel.isSiteBoundsToggledOn() ? 'on' : 'off'}`} />
                <span className="layer-picker-item-name"><i className="icon-project-cube"/> {appModel.toolbox.siteTermSingularCapitalized} Bounds</span>
            </div>
            <div className="layer-picker-info"> Show {appModel.toolbox.siteTermSingularCapitalized} Boundary on the map.</div>
        </div>
};

const PlacesLayerPicker = {
    oninit: ({state}) => {
        state.sortedPlaces = [];
        if (placesTabModel.showChildProjectPlansAndPlaces()) {
            initForPlacesTab(state);
        } else if (!appModel.project.isMetaProject) {
            initStandard(state);
        }
    },

    view: ({state: {sortedPlaces, togglePlaces, togglePlace, arePlacesToggledOn, isPlaceToggledOn, isLayerBeingEdited}}) => {
        return <>
            {!appModel.project.isMetaProject && Object.values(sortedPlaces).length ?
                <div class={layerModel.expanded.places ? 'expanded' : ''}>
                    <div class="layer-picker-item layer-picker-item-places" onclick={() => togglePlaces()}>
                        {sortedPlaces.length ? <i class={`toggle-switch toggle-is-${arePlacesToggledOn() ? 'on' : 'off'}`}/> :
                            <i className="toggle-switch toggle-is-off"/>}
                        <span class="layer-picker-item-name"><i class="icon-place"/> Place Boundaries</span>
                        <i class={'icon-down icon'} onclick={e => layerModel.expandContract(e, 'places')}/>
                    </div>
                    <div className="layer-picker-info">Hide/Show boundaries for Places here. Use the <i className="icon-map-square"/> Map Filter to find content within a Place. </div>
                    <div class="layer-picker-subitems">
                        {sortedPlaces.map(place => <PlaceLayer place={place} key={place.placeId} togglePlace={togglePlace}
                            isPlaceToggledOn={isPlaceToggledOn}
                            isLayerBeingEdited={isLayerBeingEdited}/>)}
                        {appModel.project.isMetaProject ? '' : <div className="layer-picker-manage-places"
                            onclick={() => sideNavModel.openMetaAsset('Places/Levels')}>
                            <i className='icon-add-place'/> Manage Places/Levels
                        </div>}
                    </div>
                </div> : <div></div>
            }
            {!appModel.project.isMetaProject ? <SiteBounds/> : <div></div>}
        </>;
    }
};

export default PlacesLayerPicker;
