import initializer from 'util/initializer';
import constants from 'util/data/constants';
import {datadogRum} from '@datadog/browser-rum';
import api from 'legacy/util/api';
import store from 'util/data/store';
import dialogModel from 'models/dialog-model';
import PersonModel from 'models/people/person-model';
import deepMerge from 'util/data/deep-merge';
import userflow from 'util/userflow/userflow';
import appModel from 'models/app-model';
import message from 'views/toast-message/toast-message';
import sideNavModel from 'models/side-nav-model';
import authModel from 'models/auth-model';
import router from 'uav-router';
import {permissionLevel} from 'util/users/permissions';
import permissionsManager from 'managers/permissions-manager';
import AssetOptionsModel from 'models/asset/asset-options-model';
import authManager from 'managers/auth-manager';

// Handles data and logic related to the logged-in user
class UserManager extends PersonModel {
    constructor() {
        super();
        this.preferences = {};
        initializer.addOnInit('user', () => this.reset());
    }

    reset() {
        Object.assign(this, {
            _isViewOnly: true, // default to true until we've fetched the user data
            userId: undefined,
            givenName: undefined,
            familyName: undefined,
            emailAddress: undefined,
            phoneNumber: undefined,
            role: undefined,
            status: undefined,
            preferences: undefined,
            isInvited: undefined,
            isSuperAdmin: undefined,
            isLimited: undefined,
            isNewUser: undefined,
            isVisible: true,
            rolesAssignable: undefined,
            projectIds: undefined,
            company: undefined,
            permissions: permissionsManager
        });
    }

    init(userId = this.userId) {
        initializer.initManager('user');
        return api.rpc.requests([
            ['getUser', {userId}]
        ], true)
            .then(async ([user]) => {
                this.setCurrent(user);
            });
    }

    get name() {
        return this.givenName;
    }

    get rolesUserCanAssign() {
        if (this.rolesAssignable) {
            return this.rolesAssignable;
        }
        const assignableRoles = [];
        constants.visibleRoles.forEach(key => assignableRoles.push(constants.accountUserRoles[key]));
        if (this.isSuperAdmin) {
            this.rolesAssignable = assignableRoles;
        } else {
            const rank = permissionLevel(this.role);
            this.rolesAssignable = assignableRoles.filter(role => permissionLevel(role.key) <= rank);
        }
        return this.rolesAssignable;
    }

    get isViewOnly() {
        return this._isViewOnly;
    }

    get isTrialUser() {
        return this.status === 'trial' || router.params.showTutorial || !!appModel.user.getViewAs();
    }

    isViewingAs(userType) {
        return router.params.viewAs === userType;
    }

    getViewAs() {
        return router.params.viewAs;
    }

    restrictPeopleListByCompany() {
        return !this.isSuperAdmin && permissionLevel(this.role) < permissionLevel('admin');
    }

    // Given an assetId, determines if the logged in user has ability to edit it
    isContentEditable(assetIdOrRecord) {
        return this.permissions.canEditContent(assetIdOrRecord);
    }

    getEditableClass(content) {
        if (content) {
            return this.isContentEditable(content.contentId) ? '' : 'limited-user';
        }
        return this.isLimited ? 'limited-user' : '';
    }

    getPeopleAccessMessaging() {
        const roleName = constants.accountUserRoles[this.role].display;
        if (AssetOptionsModel.hasPeopleEditAccess()) {
            return `As a ${roleName}, you can view and set Project Access for People in your Company.`;
        }
        return `As a ${roleName}, you can view People in your Company.`;
    }

    setCurrent(user) {
        // Set user data, roles, and permissions
        Object.assign(this, user, {
            isInvited: user.status === 'invited',
            _isViewOnly: user.role === 'viewer' || user.isInvited,
            isNewUser: false
        });

        // Set preferences
        this.preferences = this.preferences || {};
        const sitePreferences = this.preferences.sitePreferences || {};
        // This is a temporary measure to clean up sitePreferences > filters, which is no longer used.
        Object.keys(sitePreferences).forEach(siteId => {
            delete sitePreferences[siteId].filters;
        });

        this.isFirstVisit = !this.preferences.lastVisitedSite;
        this.measurementSystem = this.preferences.measurementSystem;
        // Set applicable body classes
        document.body.classList[this.isViewOnly ? 'add' : 'remove']('view-only');
        document.body.classList[this.status === 'trial' ? 'add' : 'remove']('trial');

        // Add datadog and hubspot
        if (constants.isDeployed) {
            datadogRum.addRumGlobalContext('usr', {
                id: this.userId,
                name: this.displayName(),
                email: this.emailAddress
            });
        }


        // Set cookie
        const date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        document.cookie = `user_id=${this.userId}; Domain=unearthlabs.com; expires=${date.toUTCString()}; Secure;`;
    }

    loadAccountUser() {
        // Set account user data, roles, and permissions
        const accountUser = store.account.users.find(u => u.userId === this.userId);

        if (accountUser) {
            this.role = accountUser.role;
            this.emailAddress = accountUser.emailAddress;
            this.phoneNumber = accountUser.phoneNumber;

            this.isAccountAdmin = {
                admin: 'admin',
                owner: 'owner'
            }[accountUser.role] || this.isSuperAdmin;
        } else {
            this.role = 'hidden';
            this.isAccountAdmin = this.isSuperAdmin;
        }
        this._isViewOnly = this.role === 'viewer';
        this.isLimited = this.role === 'limited';

        // Set applicable body classes
        document.body.classList[this.isViewOnly ? 'add' : 'remove']('view-only');

        // Add data dog
        if (constants.isDeployed) {
            datadogRum.addRumGlobalContext('acct', {
                id: store.account.accountId,
                name: store.account.name
            });
        }
        this.checkAccountStatus();

        userflow.loadUserFlow(this.isTrialUser);
        const planName = appModel.user.getViewAs() || (store.account ? store.account.planName : undefined);
        userflow.identifyUser(this.userId, planName);
    }

    setProjectAccessList() {
        if (appModel.project.isMetaProject && !this.projectIds && !this.isAccountAdmin) {
            // gets the list of projects that this user has access to
            return api.rpc.requests([['listProjects', {
                isVisible: true,
                limit: 0,
                accountId: store.account.accountId
            }]]).then(([projects]) => {
                this.projectIds = projects.map(p => p.projectId);
            });
        }
        return Promise.resolve();
    }

    setPreference(key, value) {
        this.preferences[key] = value;
        return api.patch.user({
            userId: this.userId,
            preferences: this.preferences
        });
    }

    getPreference(key) {
        return this.preferences[key];
    }

    mergePreferences(preferences) {
        deepMerge(preferences, this.preferences);
        return api.patch.user({
            userId: this.userId,
            preferences: this.preferences
        });
    }

    getUserSitePreferences({ siteId, preferenceKey }) {
        return this.preferences.sitePreferences
            && this.preferences.sitePreferences[siteId]
            && this.preferences.sitePreferences[siteId][preferenceKey]
            || null;
    }

    setLastVisitedSite() {
        // Only update userPreferences if no lastVisitedSite exists or if it's different than the current site
        if (!appModel.user.preferences.lastVisitedSite
            || appModel.user.preferences.lastVisitedSite.siteId !== router.params.siteId) {
            appModel.user.mergePreferences({
                lastVisitedSite: {
                    siteId: router.params.siteId,
                    projectId: router.params.projectId
                }
            });
        }
    }

    checkAccountStatus() {
        if (!store.accounts) {
            return;
        }
        const accounts = Object.values(store.accounts);
        if (accounts.length === 0) {
            // no accounts (owner kicked user off all accounts. This happens when account is null)
            this.showAccessDeniedMessage();
            return 'accessDenied';
        }

        let status = '';
        for (let i = 0; i < accounts.length; ++i) {
            switch (accounts[i].status) {
            case 'active':
                return 'active';
            case 'trial':
                return 'trial';
            case 'suspended':
            case 'expired':
                status = status === 'suspended' ? 'suspended' : accounts[i].status;
            }
        }

        // all accounts are suspended or expired
        if (status === 'suspended' || status === 'expired') {
            if (this.isSuperAdmin) {
                // Let superadmins continue
                message.show('You are viewing a suspended or expired account.', 'warning');
                console.log(`%cThis account (accountId: ${store.account.accountId}) is currently suspended or expired.`, 'font-weight: bold;');
                m.redraw();
                return status;
            }
            return this.showAccessDeniedMessage(status);
        }

        return status;
    }

    async toggleMeasurementSystem() {
        if (this.measurementSystem === 'imperial') {
            this.measurementSystem = 'metric';
        } else {
            this.measurementSystem = 'imperial';
        }
        this.isSavingMeasurementSystem = true;
        this.formState.isSaving = true;
        m.redraw();
        await this.setPreference('measurementSystem', this.measurementSystem);
        this.isSavingMeasurementSystem = false;
        this.formState.isSaving = false;
        this.didSaveValue = true;
        m.redraw();
    }

    showAccessDeniedMessage(status = 'denied') {
        authModel.isLoggingIn = false;
        appModel.account.isInvalid = true;
        authManager.signOut();
        m.redraw();

        switch (status) {
        case 'expired': // EXPIRED ACCOUNT
            const showBuyNow = appModel.account.isFieldPlan;

            return dialogModel.open({
                warning: false,
                cssClass: 'large block-signin info-block',
                text: <div>
                    <h2>Hi{appModel.user.name ? ', ' + appModel.user.name : ' there'}.</h2>
                    <h3>We hope you’ve enjoyed using OnePlace!</h3>
                    <p><span className="user-dialog-callout">Your free trial has ended.</span></p>
                    <p>If you have any questions, our support team would be happy to help: <a className="buy-email" href="mailto:unearthsupport@procore.com">unearthsupport@procore.com</a>.
                    </p></div>,
                onOkay: () => authManager.signOut(),
                okayText: 'Okay',
                noText: 'Close',
                onYes: !showBuyNow ? undefined : () => sideNavModel.buyNow(),
                yesText: !showBuyNow ? undefined : 'Subscribe'
            });
        case 'suspended': // SUSPENDED ACCOUNT
            return dialogModel.open({
                warning: true,
                cssClass: 'large block-signin info-block',
                text: <div>
                    <h2>Hi{appModel.user.name ? ', ' + appModel.user.name : ' there'}.</h2>
                    <p><span class="user-dialog-callout">Your account is currently suspended.</span></p>
                    <p>If you have any questions, our support team would be happy to help.</p>
                    <p>Give us a call at <a href="tel:12065351041">206.535.1041</a> or send an email to <a class="buy-email" href="mailto:unearthsupport@procore.com">unearthsupport@procore.com</a>.</p></div>,
                onOkay: () => authManager.signOut(),
                okayText: 'Log out'
            });
        default: // ACCOUNT NOT ASSIGNED
            return dialogModel.open({
                cssClass: 'large info-block',
                warning: true,
                text: <div>
                    <h2>Hi{appModel.user.name ? ', ' + appModel.user.name : ' there'}.</h2>
                    <p>You don’t have access to this account.</p>
                    <p>To receive access, you will need to contact your account administrator. </p>
                    <p>If you believe this is an error, please contact us at <a class="buy-email" href="mailto:unearthsupport@procore.com">unearthsupport@procore.com</a>.</p></div>,
                onOkay: () => authManager.signOut(),
                okayText: 'Okay'
            });
        }
    }

}

export default new UserManager();
