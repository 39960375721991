import importModel from 'models/import-model';

const CreateAssetsStep = {
    name: 'Process Import',
    oninit: () => importModel.import(),
    view: () => <div class="data-import-create">
        <div class="data-import-section">
            {importModel.isImporting
                ? <>
                    <h2>We're processing your import...</h2>
                    <p>This process can take a few minutes to a few hours, depending on the number of records and properties.</p>
                    <p>Click Done to continue with your work, and we'll notify you when your import is complete.</p>
                </>
                : <>
                    <h2>Success!</h2>
                    <p>Your import has finished. Click Done to continue.</p>
                </>}
            <div class="btn btn-pill btn-primary" onclick={() => importModel.clickDone()}>Done</div>
        </div>
    </div>
};

export default CreateAssetsStep;
