import store from 'util/data/store';
import tableModel from 'models/table/table-model';

const SharedColumnMessage = {
    oninit: ({state, attrs: {hideToggle}}) => {
        state.isOpen = hideToggle ? true : false;
    },

    view: ({state, attrs: {sharedColumn, hideToggle}}) => {
        if (!tableModel.projectView.filters.opts || !sharedColumn && !tableModel.projectView.filters.opts.sharedColumnName) {
            return '';
        }
        const assetTypeIds = sharedColumn ? sharedColumn.assetTypeIds : tableModel.getSharedColumn(tableModel.projectView.filters.opts.sharedColumnName).assetTypeIds;
        return <div class="shared-column-message">
            <div class="shared-column-header">
                <div class="shared-column-title">Combined column for {assetTypeIds.length} Type{assetTypeIds.length > 1 ? 's: ' : ': '}</div>
                {!hideToggle && <div class="config-arrow-wrap"><i className={`icon-chevron-${state.isOpen ? 'up' : 'down'}`} onclick={() => {
                    state.isOpen = !state.isOpen;
                }} /></div> }
            </div>
            {state.isOpen && <div class="shared-column-body">{assetTypeIds.map((id, index) => {
                if (index > 0) {
                    return ', ' + store.assetTypes[id].name;
                } 
                return store.assetTypes[id].name;
            })}
            </div>}
        </div>;
    }
};

export default SharedColumnMessage;
