import router from 'uav-router';
import store from 'util/data/store';
import api from 'legacy/util/api/api';
import tableConstants from 'constants/models/table/table-constants';
import filterConstants from 'constants/models/table/filter-constants';
import filterUtil from 'util/table/filter-util';
import siteModel from 'models/site-model';
import tableModel from 'models/table/table-model';
import CommonFilterModel from 'models/table/common-filter-model';
import FilterModel from 'models/table/filter-model';
import appModel from 'models/app-model';
import savedViewsModel from 'models/saved-views-model';
import debouncedAPICall from 'util/network/debounced-api-call';
import placeBoundaryFilterModel from '../place-boundary-filter-model';
import layerModel from 'models/layer-model';
import constants from 'util/data/constants';

/**
 * Holds data and logic relative to a ProjectView object. That is, given the raw data from a project_view from the api, models filter states and provides functions to be used with this data on the front end.
 *
 * Related:
 * table-model.js - To access the specific ProjectView instance currently applied, refer to the tableModel.projectView
 * saved-views-model.js - For the list of available ProjectViews, refer to savedViewsModel.projectViews
*/
class ProjectViewModel {
    constructor(_projectViewData) {
        this.filters = new FilterModel();
        this.common = new CommonFilterModel();
        this.layerState = {};
        // Which headers are "on" (ie, which columns are visible)
        // Can be read from anywhere, but should only be mutated from within the tableModel.
        this.tableState = {
            _visibleHeaders: {list: {}, table: {}},
            tableMode: 'table-bottom',
            inEditMode: false
        };

        this.projectViewId = undefined;
        this.name = undefined;
        this.type = undefined;
        this.platform = undefined;
        this.projectIds = undefined;
        this.toolboxId = undefined;
        this.savedProjectViewId = undefined;

        if (_projectViewData) {
            this.setData(_projectViewData);
        }
    }

    init() {
        this.initDefaultTableState();
        this.common.init();
    }

    cleanupSavedProjectViewId(projectViewId, attributes) {
        if (attributes.savedProjectViewId && attributes.savedProjectViewId !== projectViewId) {
            this.savedProjectViewId = attributes.savedProjectViewId;
        } else {
            this.savedProjectViewId = undefined;
        }
    }

    setData(_projectViewData) {
        const attributes = Object.assign({}, _projectViewData.attributes);
        delete _projectViewData.attributes;
        this.cleanupSavedProjectViewId(_projectViewData.projectViewId, attributes);
        Object.assign(this, _projectViewData);
        this.initFiltersFromAttributes(attributes);
    }

    initFiltersFromAttributes(attributes) {
        this.filters.setFilterAttributes(attributes);
        this.common.setState(attributes.commonFilters);
        this.setTableState(attributes.tableState);
        this.setLayerAttributes(attributes.layerState);
        this.common.addSearchArea();
        // Ensure the filter details display the latest info when opened next
        savedViewsModel.onSavedViewChange(this.projectViewId);
    }

    // Set default common filter visibility, providing set defaults even if new common columns are added after a project view is created
    initDefaultTableState() {
        this.tableState._visibleHeaders = {};
        Object.assign(this.tableState._visibleHeaders, tableConstants.defaultVisibility);
        this.tableState.editModeOn = false;
    }

    setTableState(stateObject) {
        if (!stateObject || !stateObject._visibleHeaders) {
            this.initDefaultTableState();
            return;
        }
        this.tableState.tableMode = stateObject.tableMode;
        this.tableState.editModeOn = stateObject.editModeOn;

        Object.assign(this.tableState._visibleHeaders.table, stateObject._visibleHeaders.table);
        Object.assign(this.tableState._visibleHeaders.list, stateObject._visibleHeaders.list);

        // FIXME this can be removed in the future. This is to avoid having to back fill.
        if (this.tableState._visibleHeaders.list.category === undefined) {
            this.initDefaultTableState();
        }

        // Hide places filter on meta projects
        if (appModel.project.isMetaProject) {
            this.tableState._visibleHeaders.list.places = false;
            this.tableState._visibleHeaders.table.places = false;
        }
    }

    get isSaved() {
        return this.type === 'saved' || !!this.savedProjectViewId;
    }

    getAttributes() {
        return {
            commonFilters: this.common.getState({normalizeBoundaryIndex: true}),
            filterStates: Object.assign({}, this.filters.filterStates),
            sortField: this.filters.sortField,
            searchString: this.filters.searchString,
            layerState: Object.assign({}, this.layerState),
            tableState: Object.assign({}, this.tableState),
            savedProjectViewId: this.savedProjectViewId
        };
    }

    getInUseLayers() {
        this.layerState = layerModel.safeState;
    }

    setLayerAttributes(attributes) {
        if (attributes && Object.keys(attributes).length) {
            Object.assign(this.layerState, {
                basemapId: attributes.basemapId || Object.keys(constants.basemaps)[0],
                surveyId: attributes.surveyId || undefined,
                doShowPlaces: attributes.doShowPlaces || false,
                doShowLinks: attributes.doShowLinks || false,
                planTilesetIds: attributes.planTilesetIds || [],
                placeIds: attributes.placeIds || []
            });
        }
    }

    getInUseFilters(assetTypeId) {
        const filterState = this.filters.filterStates[assetTypeId];
        const inUseFilters = [];
        if (!filterState) {
            return inUseFilters;
        }

        for (const controlTypeName in filterState) {
            if (this.filters._isFilterInUse(assetTypeId, controlTypeName)) {
                inUseFilters.push(filterState[controlTypeName]);
            }
        }
        return inUseFilters;
    }

    getArgs(offset) {
        const args = {
            limit: tableConstants.ITEMS_PER_PAGE,
            order: `${filterConstants.DEFAULT_SORT.value} ${filterConstants.DEFAULT_SORT.order}`,
            isVisible: true,
            projectId: appModel.project.projectId,
            siteId: siteModel.siteId,
            geometry: undefined,
            assetTypeIdIn: filterUtil.getAssetTypeIdInDefault(),
            query: this.filters.getSearchString(),
            include: ['media'],
            offset,
            authorIdIn: [],
            modifierIdIn: []
        };

        if (this.common.assetIdMatch) {
            args.contentId = this.common.assetIdMatch;
        }

        this.common.getArgs(args);

        const assetTypeIds = this.common.createAssetTypeIdFilter();

        if (this.filters.sortField) {
            this.filters.configureSortOrder(args);
        }

        const linkCountFilter = this.common.getLinkCountFilter();

        /* createPropertyFilters: Logic for building property specific filters. This is the OR section of the filter logic.
        *      For example if we want all mainlines with status === inspected, that logic lives here.
        *  Some filters can only be included at this level, rather than as a top-level query. In such a case, the filter
        * must be added to every OR filter you add, so that it's included effectively as an AND statement (linkIds, eg)
        */
        const filters = filterUtil.createPropertyFilters(this.filters.filterStates, assetTypeIds, linkCountFilter, this.common.nameQueryString);

        // If an asset name query provided, include as OR filters based on name control and asset type.
        if (this.common.nameQueryString) {
            this.common.setNameQueryFilters(filters);
        }

        filterUtil.configureProjectAuthFilters(args);

        args.filters = filters;

        return args;
    }

    isFilterInUse(assetTypeId, controlTypeName) {
        if (tableModel.loadingTable) {
            return false;
        }
        const result = this.filters._isFilterInUse(assetTypeId, controlTypeName);
        return result;
    }

    doesMatchFilters(assetId) {
        return this.common.doesMatchFilters(assetId) && this.filters.doesMatchFilters(assetId);
    }

    onNewContent(assetId) {
        return this.common.onNewContent(assetId);
    }

    onDeletedContent(assetId) {
        return this.common.onDeletedContent(assetId);
    }

    getAssetTypeCount(assetTypeId) {
        return tableModel.assetTypeCounts[assetTypeId];
    }

    resetAllFilters() {
        router.url.remove('projectViewId');
        this.savedProjectViewId = undefined;
        this.filters.resetAllFilters();
        placeBoundaryFilterModel.reset();
        return this.common.resetAllFilters();
    }

    saveToApi() {
        if (this.type === 'links-tab') {
            delete savedViewsModel._linksTabSavedView;
        }
        savedViewsModel.onSavedViewChange(this.projectViewId);
        m.redraw();
        const attributes = Object.assign({}, this.getAttributes());
        const projectViewId = this.projectViewId;
        if (projectViewId) {
            return debouncedAPICall('modifyProjectView' + projectViewId, ['modifyProjectView', {
                projectViewId,
                attributes
            }]);
        }
        return Promise.resolve();
    }

    autosave(opts = {}) {
        // Special case for non-table view related projectViews (like the links tab projectViews)
        if (this.type !== 'last-viewed' && this.type !== 'saved') {
            return this.saveToApi();
        }
        if (!opts.retainSavedProjectViewId) {
            this.savedProjectViewId = undefined;
            router.url.remove('projectViewId');
        }
        return this.saveToApi();
    }

    _createLastViewed() {
        return api.rpc.request([['createProjectView', {
            toolboxId: store.toolboxId,
            projectIds: [router.params.projectId],
            platform: 'web',
            type: 'last-viewed',
            name: 'table-filter',
            attributes: this.getAttributes()
        }]]);
    }


}

export default ProjectViewModel;
