import store from 'util/data/store';
import constants from 'util/data/constants';
import siteModel from 'models/site-model';
import initializer from 'util/initializer';
import publish from 'legacy/util/api/publish';
import deepMerge from 'util/data/deep-merge';
import formModel from 'models/form-model';
import appModel from 'models/app-model';
import tableModel from 'models/table/table-model';
import authManager from 'managers/auth-manager';
import bbox from '@turf/bbox';
import {length} from 'util/geo';
import difference from '@turf/difference/index.js';
import union from '@turf/union';
import validate from 'util/geo/validate';
import Plan from 'views/plan';
import Survey from 'views/survey';
import filterConstants from 'constants/models/table/filter-constants';
import FeatureModel from 'models/feature/feature-model';
import api from 'legacy/util/api/api';
import dialogModel from 'models/dialog-model';
import assetListManager from 'managers/asset-list-manager';
import FeatureStream from 'models/feature-stream-model';
import AssetModel from 'models/asset/asset-model';

const MAX_IMAGE_WIDTH = 256,
    TRIMBLE_DATA_KEY = constants.trimbleDataKey,
    {mapFilterOpts} = filterConstants;

class FeatureListManager {
    constructor() {
        this.expectedFeatureCount = undefined;
        this.streams = {};
        this.restrictedZoomStreams = {};
        this.isHidingFeatures = false;
        initializer.add(() => featureListManager.cleanup(), 'featureListManager');
    }

    get features() {
        return this.all;
    }

    getById(featureId) {
        return featureListManager.all[featureId];
    }

    reset() {
        featureListManager.all = {};
        featureListManager.imageWidths = {};
        featureListManager.streams = {};
        featureListManager.restrictedZoomStreams = {};
        featureListManager.args = undefined;
        featureListManager.minzoomByToolId = {};
        featureListManager.excludedBasedOnZoom = false;
        featureListManager.zoomBoundaryLoaded = undefined;
    }

    cancelAllStreams() {
        this.cancelAll('streams');
        this.cancelAll('restrictedZoomStreams');
    }

    cancelAll(streamKey) {
        const streamList = Object.values(featureListManager[streamKey]);
        streamList.forEach(stream => stream.cancelStream());
        featureListManager[streamKey] = {};
    }

    cleanup() {
        this.cancelAllStreams();
        featureListManager.reset();
    }

    redrawMapFeatures(featureTypeId) {
        return appModel.toolbox.featureTypes[featureTypeId].redrawMapFeatures();
    }

    async addFeatures(features, skipAwait) {
        const _featureTypes = {};
        const promises = features.map(async _apiFeature => {
            const featureId = _apiFeature.featureId || _apiFeature.id || _apiFeature.properties._id;

            if (featureListManager.all[featureId]) {
                return Promise.resolve();
            }
       
            const feature = new FeatureModel(_apiFeature, undefined, true);

            if (!skipAwait) {
                await feature.waitUntilFeatureReady(); // Resolves once feature images are loaded, eg. 
            }
            _featureTypes[feature.featureTypeId] = 1;
            featureListManager.all[featureId] = feature;
        });

        return Promise.all(promises).then(() => {
            Object.keys(_featureTypes).forEach(featureTypeId => this.sortFeaturesBySize(featureTypeId));
            if (this.isHidingFeatures) {
                featureListManager.removeAllFeatures(false);
            }
            return features;
        
        }).catch(err => console.error(err));

    }

    /*
    * Sorts features of a given feature type by their size
    */
    sortFeaturesBySize(featureTypeId) {
        const featureType = appModel.toolbox.featureTypes[featureTypeId];
        const source = featureType.source;

        // Sort features by size so that larger features don't obstruct smaller ones.
        // We're using the diagonal of the bounding box as a proxy for size
        // because it's faster to calculate than exact area.
        const unsortedFeatures = source._data.features;
        source._data.features = unsortedFeatures.map((feature, i) => {
            const box = bbox(feature);
            return {
                size: length([
                    [box[0], box[1]],
                    [box[2], box[3]]
                ]),
                i
            };

        }).sort((a, b) => b.size - a.size).map(result => unsortedFeatures[result.i]);
        featureType.redrawMapFeatures();

    }

    removeFeature(feature, source) {
        if (!feature) {
            return;
        }
        const featureId = feature.id || feature.featureId;
        source = source || siteModel.map.getSource(feature.properties.featureTypeId);
        const index = source._data.features.findIndex(f => f.id === featureId);
        if (index !== -1) {
            delete featureListManager.all[featureId];
            source._data.features.splice(index, 1);
        }
        return source;
    }

    removeFeatures(featureIds) {
        if (!featureIds || !featureIds.length > 0) {
            return;
        }
        const features = featureIds.map(featureId => featureListManager.getById(featureId));
        if (features.length) {
            const source = siteModel.map.getSource(features[0].properties.featureTypeId);
            for (const feature of features) {
                if (source) {

                    featureListManager.removeFeature(feature, source);
                }
            }
            source.setData(source._data);
        }
    }

    removeAllFeatures(andClearFromMemory = true) {
        const sourcesToClear = {};
        Object.values(featureListManager.all).forEach(feature => {
            sourcesToClear[feature.properties.featureTypeId] = true;

        });
        Object.keys(sourcesToClear).forEach(featureTypeId => {
            const source = siteModel.map.getSource(featureTypeId);
            if (source) { // source may be null if map was torn down (ie if switching projects or accounts)
                source._data.features = [];
                source.setData(source._data);
            }

        });
        if (andClearFromMemory) {
            featureListManager.all = {};
        }
    }

    removeFeaturesFromAsset(assetId) {
        dialogModel.open({
            headline: `Remove ${assetListManager.getAssetName(assetId)} from map?`,
            text: 'Please note that this operation cannot be undone.',
            onYes: () => featureListManager.doRemoveFeaturesFromAsset(assetId),
            yesText: 'Remove',
            noText: 'Cancel',
            yesClass: 'btn btn-pill btn-red',
            noClass: 'btn btn-pill btn-secondary'
        });
    }

    doRemoveFeaturesFromAsset(assetId) {
        if (formModel.toolInterface) {
            formModel.toolInterface.close();
        }
        formModel.getAssetFeatures(assetId).then(features => {
            const requests = [];
            let source;
            features.forEach((feature) => {
                requests.push(['deleteFeature', {featureId: feature.id}]);
                source = featureListManager.removeFeature(feature);
            });
            if (source) {
                source.setData(source._data);
            }
            api.rpc.requests(requests);
            store.assets[assetId].featureIds = [];
            m.redraw();
        });
    }

    addImage(id, src, feature, featureTypeId) {
        let sourceWidthPx = featureListManager.imageWidths[id];
        if (sourceWidthPx !== undefined) {
            if (feature && sourceWidthPx !== 'loading') {
                if (sourceWidthPx.then) {
                    sourceWidthPx = sourceWidthPx.then(() => {
                        feature.properties._sourceWidthPx = featureListManager.imageWidths[id];
                    });
                } else {
                    feature.properties._sourceWidthPx = sourceWidthPx;
                }
            }
            return sourceWidthPx.then ? sourceWidthPx : Promise.resolve();
        }
        featureListManager.imageWidths[id] = new Promise((resolve) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onerror = () => {
                img.onerror = () => resolve(); // Force a resolve so that it's not blocking
                img.src += '?d=' + new Date().getTime();
            };

            img.onload = () => {
                if (img.width > MAX_IMAGE_WIDTH) {
                    const aspectRatio = img.width / img.height;
                    img.width = MAX_IMAGE_WIDTH;
                    img.height = MAX_IMAGE_WIDTH / aspectRatio;
                }
                featureListManager.imageWidths[id] = img.width;
                if (feature) {
                    feature.properties._sourceWidthPx = img.width;
                }
                siteModel.handleLoadedMapImage(id, img, featureTypeId);
                resolve(true);
            };
            img.src = src;
        });

        return featureListManager.imageWidths[id];

    }

    // Want to load the features according to current boundary only if the user hasn't defined a boundary filter
    get shouldUseVisibleBoundary() {
        return tableModel.projectView.common.selectedBoundaryIndex !== mapFilterOpts.IN_VIEW
        && tableModel.projectView.common.selectedBoundaryIndex !== mapFilterOpts.DRAW_BOUNDARY
        && tableModel.projectView.common.selectedBoundaryIndex !== mapFilterOpts.UNMAPPED
        && tableModel.projectView.common.selectedBoundaryIndex !== mapFilterOpts.TAGGED_PLACE;
    }
    
    hideFeatures() {
        this.isHidingFeatures = true;
        this.removeAllFeatures(false);
        m.redraw();
    }

    showFeatures() {
        this.isHidingFeatures = false;
        const features = Object.values(this.all);
        this.all = {};
        this.addFeatures(features);
        this.streamFeatures();
        m.redraw();
    }

    streamFeatures() { 
        if (appModel.view === Survey || appModel.view === Plan || featureListManager.isHidingFeatures) {
            // Dont stream features when we're in a staking flow.
            return;
        }

        const args = Object.assign({}, featureListManager.args);

        if (featureListManager.shouldUseVisibleBoundary) {
            const visibleBoundary = featureListManager.getVisibleStreamBoundaryMinusLoadedBoundary(featureListManager.loadedBoundary);
            if (visibleBoundary) {
                args.geometry = {
                    intersects: visibleBoundary
                };
            }
        }

        const toolId = Object.keys(featureListManager.minzoomByToolId)[0];
        if (toolId) {
            const tool = appModel.toolbox.tools[toolId];
            const assetTypeId = tool.assetForm.assetType.assetTypeId;
            const assetTypeIdFiltered = [...args.assetTypeIdIn].filter(id => id !== assetTypeId);
            args.assetTypeIdIn = [...assetTypeIdFiltered];
        }

        featureListManager.expectedFeatureCount = undefined;
        featureListManager.startStream(args);
    }

    updateLoadedBoundary(existingLoadedBoundaryKey, newLoadedBoundary) {
        let existingLoadedBoundary = this[existingLoadedBoundaryKey];
        if (existingLoadedBoundary) {
            try {
                existingLoadedBoundary = union(newLoadedBoundary, existingLoadedBoundary).geometry;
            } catch (e) {
                return;
            }
        } else {
            existingLoadedBoundary = newLoadedBoundary;
        }

        this[existingLoadedBoundaryKey] = existingLoadedBoundary;
    }

    startStream(args = featureListManager.args, opts) {
        const stream = new FeatureStream(args, opts);
        this.streams[stream.streamId] = stream;
        stream.start();
    }

    onStreamComplete(streamId) {
        const stream = this.streams[streamId];
        const streamBoundary = this.streams[streamId].args.geometry.intersects;
        featureListManager.updateLoadedBoundary(stream.restrictedByZoom ? 'zoomLoadedBoundary' : 'loadedBoundary', streamBoundary);
        siteModel.removeLoadingClass();
        this.expectedFeatureCount = 0;
        delete this.streams[streamId];
    }

    search(leaveExistingFeatures) {
        featureListManager.cancelAllStreams();

        featureListManager.args = Object.assign({}, tableModel.projectView.getArgs(), {
            limit: undefined,
            include: undefined,
            offset: undefined
        });

        if (!featureListManager.args.geometry) {

            const mapBounds = siteModel.map.getBounds(),
                nw = mapBounds.getNorthWest().toArray();

            featureListManager.args.geometry = {
                intersects: {
                    type: 'Polygon',
                    coordinates: [[
                        nw,
                        mapBounds.getNorthEast().toArray(),
                        mapBounds.getSouthEast().toArray(),
                        mapBounds.getSouthWest().toArray(),
                        nw
                    ]]
                }
            };
        }
    
        featureListManager.loadedBoundary = null;
        featureListManager.zoomBoundaryLoaded = null;
        if (!leaveExistingFeatures) {
            featureListManager.removeAllFeatures();
        }

        featureListManager.streamFeatures();
        featureListManager.streamFeaturesWithMinzoom();
    }

    onMove() {
        featureListManager.cancelAllStreams();
    }

    onceIdle() {
        if (!featureListManager.args) {
            // This is the case where we haven't fully initted yet
            return;
        }
        featureListManager.cancelAllStreams();
        featureListManager.streamFeatures();
        featureListManager.streamFeaturesWithMinzoom();
    }

    getCurrentMapBoundary() {
        const mapBounds = siteModel.map.getBounds(),
            nw = mapBounds.getNorthWest().toArray();

        return  {
            type: 'Polygon',
            coordinates: [[
                nw,
                mapBounds.getNorthEast().toArray(),
                mapBounds.getSouthEast().toArray(),
                mapBounds.getSouthWest().toArray(),
                nw
            ]]
        };
    }

    getVisibleStreamBoundaryMinusLoadedBoundary(loadedBoundary) {
        const currentMapBoundary = featureListManager.getCurrentMapBoundary();
        let streamBoundary = currentMapBoundary;

        if (loadedBoundary) {
            try {
                streamBoundary = difference(currentMapBoundary, loadedBoundary);
            } catch (e) {
                return null;
            }
            streamBoundary = streamBoundary && streamBoundary.geometry;
        }

        if (!streamBoundary || !validate(streamBoundary)) {
            return null;
        }

        return streamBoundary;
    }

    getPlaceFeature(place) {
        return featureListManager.addLatitudeToFeature({
            type: 'Feature',
            id: place.placeId,
            geometry: {
                type: 'Polygon',
                coordinates: place.boundary.coordinates
            },
            properties: {
                _placeId: place.placeId
            }
        });
    }

    addLatitudeToFeature(feature) {
        const geometryType = feature.geometry.type,
            coords = feature.geometry.coordinates;

        if (coords && coords.length) {

            let latitude;

            if (geometryType === 'Polygon' || geometryType === 'MultiLineString') {
                latitude = coords[0][0][1];
            } else if (geometryType === 'LineString' || geometryType === 'MultiPoint') {
                latitude = coords[0][1];
            } else if (geometryType === 'Point') {
                latitude = coords[1];
            } else if (geometryType === 'MultiPolygon') {
                latitude = coords[0][0][0][1];
            }
            feature.properties._latitude = latitude * Math.PI / 180; // to radians
        }
        return feature;
    }

    streamFeaturesWithMinzoom() {
        if (appModel.view === Survey || appModel.view === Plan) {
            // Dont stream features when we're in a staking flow.
            return;
        }
        
        const toolId = Object.keys(featureListManager.minzoomByToolId)[0];
        if (!toolId) {
            return;
        }

        const args = Object.assign({}, featureListManager.args);
        const minzoom = featureListManager.minzoomByToolId[toolId];
        const tool = appModel.toolbox.tools[toolId];
        const assetTypeId = tool.assetForm.assetType.assetTypeId;

        args.assetTypeIdIn = [assetTypeId];

        // We're within the min zoom to stream:
        if (siteModel.map.getZoom() >= minzoom) {

            // Wasnt streaming w/minzoom restriction before, now start. Use current map boundary.
            if (featureListManager.excludedBasedOnZoom) {
                featureListManager.excludedBasedOnZoom = false;
            
            // Was already streaming w/minzoom restriction, add to loaded boundary with current map boundary difference:
            } else if (featureListManager.zoomBoundaryLoaded && featureListManager.shouldUseVisibleBoundary) {
                const visibleBoundaryDifference = featureListManager.getVisibleStreamBoundaryMinusLoadedBoundary(featureListManager.zoomBoundaryLoaded);
                if (visibleBoundaryDifference) {
                    args.geometry.intersects = visibleBoundaryDifference;
                }
            }

            featureListManager.startStream(args, {restrictedByZoom: true});
            
        } else if (!featureListManager.excludedBasedOnZoom) {
            // Cancel streams associated with restricted zoom feature types
            this.cancelAll('restrictedZoomStreams');

            // Remove features restricted by zoom
            //     TODO Add handling for multiple feature types
            const featureType = tool.featureTypes[0];
            const featureTypeId = featureType.featureTypeId; 
            Object.values(featureListManager.all).forEach(feature => {
                if (feature.featureTypeId === featureTypeId) {
                    delete featureListManager.all[feature.featureId];
                }
            });
            const source = siteModel.map.getSource(featureTypeId);
            source._data.features = [];
            source.setData(source._data);
            featureListManager.excludedBasedOnZoom = true;
        }
    }

    awaitChanges() {
        publish.await({
            changeType: 'new',
            recordType: 'feature',
            test: (change) => publish.isValidChangedBy(change),
            callback: feature => {
                const assetId = feature.assetId,
                    source = siteModel.map && siteModel.map.getSource(feature.properties.featureTypeId);

                let asset = store.assets[assetId];

                function maybeRender() {
                    if (appModel.user.permissions.hiddenAssetTypes[asset.assetTypeId]) {
                        return;
                    }
                    if (tableModel.projectView.doesMatchFilters(assetId)) {
                        featureListManager.addFeatures([feature]);
                    }
                }

                if (asset) {
                    if (!asset.featureIds || !asset.featureIds.find(id => id === feature.featureId)) {
                        asset.featureIds = asset.featureIds || [];
                        asset.featureIds.push(feature.featureId);
                        const assetData = deepMerge(store.assets[assetId], asset);
                        store.assets[assetId] = new AssetModel(assetData);
                        m.redraw();
                    }
                    maybeRender();

                } else {
                    publish.await({
                        changeType: 'new',
                        recordType: 'content',
                        test: content => content.contentId === assetId && publish.isValidChangedBy(content),
                        callback: content => {
                            asset = content;
                            maybeRender();
                        }
                    });

                }

                if (source) {
                    source.setData(source._data);
                }

            },
            persist: true
        });

        publish.await({
            changeType: 'deleted',
            recordType: 'feature',
            test: feature => featureListManager.getById(feature.featureId),
            callback: feature => {
                const assetId = feature.assetId ? feature.assetId : feature.properties.assetId;
                const featureTypeId = feature.featureTypeId  ? feature.featureTypeId : feature.properties.featureTypeId;
                if (formModel.editingFeatureId !== feature.id && formModel.assetId !== assetId) {
                    const source = siteModel.map && siteModel.map.getSource(featureTypeId);
                    if (source) {
                        featureListManager.removeFeature(feature, source);
                        source.setData(source._data);
                    }
                }

            },
            persist: true
        });

        publish.await({
            changeType: 'modified',
            recordType: 'feature',
            test: feature => featureListManager.getById(feature.featureId) && publish.isValidChangedBy(feature) && feature.changedBy.sessionId !== authManager.socket.sessionId,
            callback: f => {
                const featureId = f.featureId || f.id;
                const feature = featureListManager.getById(featureId);

                // handle any changes to the presence of
                // trimble location data
                if (f.properties[TRIMBLE_DATA_KEY]) {
                    f.properties[TRIMBLE_DATA_KEY] = true;
                } else if (feature.properties[TRIMBLE_DATA_KEY]) {
                    delete feature.properties[TRIMBLE_DATA_KEY];
                }

                if (formModel.editingFeatureId !== featureId) {

                    f.properties._sourceWidthPx = feature.properties._sourceWidthPx || f.properties._sourceWidthPx; // Retain sourceWidthPx on modified feature if they were handled by image data on initialization

                    feature.geometry = f.geometry;

                    Object.assign(feature.properties, f.properties);

                    featureListManager.addLatitudeToFeature(feature);

                    const source = siteModel.map && siteModel.map.getSource(feature.properties.featureTypeId);

                    if (source) {

                        source.setData(source._data);

                    }

                }
            },
            persist: true
        });

    }

}

const featureListManager = new FeatureListManager();

export default featureListManager;
