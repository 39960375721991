import appModel from 'models/app-model';
import peopleModel from 'models/people/people-model';
import formModel from 'models/form-model';
import tableModel from 'models/table/table-model';
import Cell from 'views/table/cells/cell';
import store from 'util/data/store';
import activeCell from 'models/table/active-cell-model';
import {copyToClipboard} from 'util/dom/clipboard';

const commonCells = {

    addedBy: {
        view: ({attrs: {assetRow}}) => {
            const author = assetRow.author;
            return tableModel.addedByCounts[author.userId]
                ? <div class="asset-author-inner is-clickable">
                    <span class="list-label">Added by:</span> <span class="shortcut-filter-link" onclick={function (e) {
                        if (tableModel.addedByCounts[author.userId]) {
                            tableModel.projectView.filters.open(this, {
                                viewKey: 'commonDefinedOptions',
                                label: peopleModel.displayName(author),
                                callback: () => appModel.projectView.common.turnOffAllAddedByExcept(author.userId),
                                isCellMenu: true
                            });
                            e.stopPropagation();
                        }
                    }}>{peopleModel.displayName(author.userId ? author : null)}</span>
                </div>
                : <span class="asset-author"><span class="list-label">Added by:</span> <span class="value-wrap">{peopleModel.displayName(author.userId ? author : null)}</span></span>;
        }
    },

    lastUpdatedBy: {
        view: ({attrs: {assetRow}}) => {
            const modifier = assetRow.modifier;
            return tableModel.updatedByCounts[modifier.userId]
                ? <div class="asset-modifier-inner is-clickable">
                    <span class="list-label">Last Updated by:</span> <span class="shortcut-filter-link" onclick={function (e) {
                        if (tableModel.updatedByCounts[modifier.userId]) {
                            tableModel.projectView.filters.open(this, {
                                viewKey: 'commonDefinedOptions',
                                label: peopleModel.displayName(modifier),
                                callback: () => appModel.projectView.common.turnOffAllUpdatedByExcept(modifier.userId),
                                isCellMenu: true
                            });
                            e.stopPropagation();
                        }
                    }}>{peopleModel.displayName(modifier.userId ? modifier : null)}</span>
                </div>
                : <span class="asset-modifier"><span class="list-label">Last Updated by:</span> <span class="value-wrap">{peopleModel.displayName(modifier.userId ? modifier : null)}</span></span>;
        }
    },

    addedDateTime: {
        view: ({attrs: {assetRow}}) => <span class="asset-timestamp"><span class="list-label">Added Date/Time:</span> <span class="value-wrap">{assetRow.addedDate}</span></span>
    },

    lastUpdated: {
        view: ({attrs: {assetRow}}) => <span class="asset-timestamp"><span class="list-label">Last Updated:</span> <span class="value-wrap">{assetRow.updatedDate}</span></span>
    },

    category: {
        view: ({attrs: {assetRow}}) =>
            <div class='asset-category-inner is-clickable'>
                <span class="list-label">Category:</span>
                <span class='shortcut-filter-link' onclick={function (e) {
                    tableModel.projectView.filters.open(this, {
                        viewKey: 'commonDefinedOptions',
                        label: assetRow.category,
                        callback: () => appModel.projectView.common.turnOffAllToolGroupsExcept(assetRow.toolGroupId),
                        isCellMenu: true
                    });
                    e.stopPropagation();
                }}>{assetRow.category}</span>
            </div>
    },

    places: {
        view: () => {
            return <span class="place-inner-wrap" />;
        }
    },

    type: {
        oninit: ({attrs: {assetRow}, state}) => {
            const asset = store.assets[assetRow.assetId];
            state.controls = tableModel.getControlTypes(asset.attributes.toolId) || [];
        },

        view: ({attrs: {assetRow}, state}) => {
            const assetType = store.assetTypes[assetRow.assetTypeId];
            return <>
                <span class="list-label">Type:</span>
                <span class="is-clickable shortcut-filter-link" onclick={function (e) {
                    tableModel.projectView.filters.open(this, {
                        viewKey: 'commonDefinedOptions',
                        label: assetType.name,
                        callback: () => appModel.projectView.common.turnOffAllAssetTypesExcept(assetType.assetTypeId),
                        isCellMenu: true
                    });
                    e.stopPropagation();
                }}>{assetType.name}</span>
                {state.controls.length ? <div class="type-columns-count" onclick={function (e) {
                    tableModel.projectView.filters.open(this.parentElement, {
                        assetType,
                        asset: store.assets[assetRow.assetId],
                        viewKey: 'assetTypeCell',
                        isCellMenu: true
                    });
                    e.stopPropagation();
                }}> {`${state.controls.length} ${state.controls.length === 1 ? 'property' : 'properties'}`}
                </div> : <div class="type-columns-count is-na"> 0 properties</div>
                }
            </>;
        }
    },

    unearthId: {
        view: ({attrs: {assetRow}}) => {
            const asset = store.assets[assetRow.assetId];
            return <div class='asset-unearth-id is-clickable'>
                <span className="list-label">Unearth ID: </span>
                <span className="value-wrap">{asset.contentId}</span>
                <span className="copy right type-columns-count" onclick={(e) => {
                    copyToClipboard(asset.contentId);
                    e.stopPropagation();
                }}> copy</span>
            </div>;
        }
    },

    links: {
        view: ({attrs: {assetRow}}) => {
            const asset = store.assets[assetRow.assetId];
            const count = asset.linkIds ? asset.linkIds.length : 0;
            const outerClass = `asset-links-inner ${count ? 'is-clickable' : ''}`;
            return <div class={outerClass}>
                <span className="list-label">Links: </span>
                <span className="links-count" onclick={count && !tableModel.inEditMode ? (e) => {
                    e.stopPropagation();
                    formModel.viewAsset(assetRow.assetId, 'Links');
                } : undefined}>
                    {count}</span>
            </div>;
        }
    }
};

class CellCommon extends Cell {

    view({state, attrs: {assetRow, cellKey, columnIndex}}) {
        const View = commonCells[cellKey];
        state.cssClasses = `asset-cell asset-${cellKey} ${state.wrapClass || ''} ${state.activeClass || ''}`;
        return <li tabindex={activeCell.cellTabindex} class={state.cssClasses} onfocus={(e) => tableModel.handleCellClick(e, assetRow.rowIndex, columnIndex)} onclick={(e) => tableModel.handleCellClick(e, assetRow.rowIndex, columnIndex)}>
            <View assetRow={assetRow} />
        </li>;
    }
}

export default CellCommon;
