import stakeableModel from 'models/stakeable-model';

const LNG_RANGE = 180;
const LAT_RANGE = 90;

const LNG_INDEX = 0;
const LAT_INDEX = 1;

const INDEX_OFFSET = 1;

/**
 * Generates popup for stake with only the pin number and delete button.
 */
const MinimalPopup = {
    view: ({attrs: {parentPair}}) => {
        return <div class="stake-info">
            <span class="stake-title">Match Point {parentPair.position + INDEX_OFFSET}</span>
            <i class="icon-trash right" onclick={() => stakeableModel.deleteStakePair(parentPair)}></i>
        </div>;
    }
};

/**
 * Generates popup for stake with pin number, delete button, latLng (and optionally, elevation) input,
 * and associated event handlers.
 */
const LatLngInputPopup = {
    view: ({attrs: {stake, parentPair, lngLat, elevation}}) => {
        return <div class="stake-info">
            <span class="stake-title">Match Point {parentPair.position + INDEX_OFFSET}</span>
            <i class="icon-trash right" onclick={() => stakeableModel.deleteStakePair(parentPair)}></i>
            <div class="point-info">
                <form class="stake-inputs">
                    <div class='latitude-row'>
                        <label class="sublabel">Latitude</label>
                        <input type="text" value={lngLat.lat}
                            oninput={e => stake.displayValidationMessage(e, LAT_RANGE)}
                            onchange={e => stake.handleLatLngInput(e, LAT_INDEX) }/>
                        <div class="error">Latitude must be between -{LAT_RANGE} and +{LAT_RANGE}.</div>
                    </div>
                    <div class='longitude-row'>
                        <label class="sublabel">Longitude</label>
                        <input type="text" value={lngLat.lng}
                            oninput={e => stake.displayValidationMessage(e, LNG_RANGE)}
                            onchange={e => stake.handleLatLngInput(e, LNG_INDEX) }/>
                        <div class="error">Longitude must be between -{LNG_RANGE} and +{LNG_RANGE}.</div>
                    </div>
                    {stakeableModel.withElevation &&
                   <div class='elevation-row elev-input'>
                       <label class="sublabel">Elevation</label>
                       <input type="text" value={elevation}
                           oninput={e => stake.displayValidationMessage(e)}
                           onchange={e => stake.handleElevationInput(e)} ></input>
                       <span class="elevation-unit">{!stakeableModel.isMetric ? 'ft' : 'm'}</span>
                       <div class="error">Elevation must be numerical.</div>
                   </div> }
                </form>
            </div>
        </div>;
    }
};

/**
 * Generates popup content for activated stakes.
 */
const StakePopup = {
    view: ({attrs: {parentPair, stake, lngLat, elevation}}) => {
        if (lngLat) {
            return <LatLngInputPopup parentPair={parentPair} stake={stake} lngLat={lngLat} elevation={elevation} />;
        }
        return <MinimalPopup parentPair={parentPair} />;
    }
};

export default StakePopup;
