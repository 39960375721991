import authManager from 'managers/auth-manager';
import appModel from 'models/app-model';
import validation from 'util/data/validation';
import message from 'views/toast-message/toast-message';
import modalModel from 'models/modal-model';

const ChangePassword = {

    oninit: ({ state }) => {
        state.isSubmitting = false;
        state.fields = {
            currentPassword: {
                value: '',
                show: false,
                isInvalid: false,
                isValidFn: (value) => value.match(validation.name.regex),
                invalidMessage: validation.name.errorMsg
            },
            newPassword: {
                value: '',
                show: false,
                isInvalid: false,
                isValidFn: (value) => value.match(validation.password.regex),
                invalidMessage: validation.password.errorMsg
            },
            newPasswordConfirm: {
                value: '',
                show: false,
                isInvalid: false,
                isValidFn: (value) => value === state.fields.newPassword.value,
                invalidMessage: validation.match.errorMsg
            }
        };
        m.redraw();
    },

    updateField: (field, value) => {
        if (field.isInvalid) {
            field.isInvalid = !field.isValidFn(value);
        }
        field.value = value;
        m.redraw();
    },

    submit: (fields, state) => {
        let foundInvalid = false;
        Object.values(fields).forEach(field => {
            field.isInvalid = !field.isValidFn(field.value);
            if (field.isInvalid) {
                foundInvalid = true;
            }
        });
        if (foundInvalid) {
            return m.redraw();
        }
        state.isSubmitting = true;
        m.redraw();
        authManager.socket.send('changePassword', {
            data: {
                username: appModel.user.userId,
                password: fields.currentPassword.value,
                newPassword: fields.newPassword.value
            },
            resolve: () => {
                modalModel.close();
                authManager.signOut();
            },
            reject: () => {
                state.isSubmitting = false;
                message.show('We were unable to process your request with the information provided.', 'error');
                m.redraw();
            }
        });
    },

    view: ({ state }) => {
        const fields = state.fields;
        return <div class={`user-profile person-module${state.isSubmitting ? ' submitting-password' : ''}`}>
            <input type="text" autocomplete="username" class="username-autofill-proxy" />
            <div class="modal-header-row">
                <span class="modal-title">Password</span>
            </div>
            <p class="change-password-tip">Please note that you will be prompted to sign in after setting your new password.</p>
            <div class="password-field profile-field profile-row">
                <span class="label">Current Password</span>
                <div class={`password-field-wrap ${state.fields.currentPassword.isInvalid ? 'error' : ''}`}>
                    <span class="password-visibility-toggle" onclick={() => {
                        state.fields.currentPassword.show = !state.fields.currentPassword.show;
                    }}>{fields.currentPassword.show ? 'Hide' : 'Show'}</span>
                    <input oninput={(e) => state.updateField(state.fields.currentPassword, e.target.value)} autocomplete="current-password" name="current-password" class="text-input" type={`${fields.currentPassword.show ? 'text' : 'password'}`} />
                    {fields.currentPassword.isInvalid && <span class="input-tip">{fields.currentPassword.invalidMessage}</span>}
                </div>
            </div>
            <div class="password-field profile-field profile-row">
                <span class="label">New Password</span>
                <div class={`password-field-wrap ${state.fields.newPassword.isInvalid ? 'error' : ''}`}>
                    <span class="password-visibility-toggle" onclick={() => {
                        state.fields.newPassword.show = !state.fields.newPassword.show;
                    }}>{fields.newPassword.show ? 'Hide' : 'Show'}</span>
                    <input autocomplete="new-password" name="new-password" oninput={(e) => state.updateField(state.fields.newPassword, e.target.value)} class="text-input" type={`${fields.newPassword.show ? 'text' : 'password'}`} />
                    <span class="input-tip">{fields.newPassword.isInvalid ? fields.newPassword.invalidMessage : 'Minimum of 8 characters, including 1 number, and 1 uppercase letter.'}</span>
                </div>
            </div>
            <div class="password-field profile-field profile-row">
                <span class="label">Confirm New Password</span>
                <div class={`password-field-wrap ${state.fields.newPasswordConfirm.isInvalid ? 'error' : ''}`}>
                    <span class="password-visibility-toggle" onclick={() => {
                        state.fields.newPasswordConfirm.show = !state.fields.newPasswordConfirm.show;
                    }}>{fields.newPasswordConfirm.show ? 'Hide' : 'Show'}</span>
                    <input autocomplete="new-password" name="new-password" oninput={(e) => state.updateField(state.fields.newPasswordConfirm, e.target.value)} class="text-input" type={`${fields.newPasswordConfirm.show ? 'text' : 'password'}`} />
                    {fields.newPasswordConfirm.isInvalid && <span class="input-tip">{fields.newPasswordConfirm.invalidMessage}</span>}
                </div>
            </div>
            <div class="profile-footer">
                <div class="btn btn-pill btn-primary btn-small" onclick={() => state.submit(state.fields, state)}>
                    <i class="spinner gray" /><span class="btn-label">{state.isSubmitting ? 'Submitting...' : 'Change Password'}</span>
                </div>
            </div>
        </div>;
    }
};

export default ChangePassword;
