import featureListManager from 'managers/feature-list-manager';

const apiSafeFeature = (feature, isModifyRequest) => ({
    featureId: feature.id || feature.featureId,
    featureTypeId: isModifyRequest ? undefined : feature.properties.featureTypeId,
    geometry: feature.geometry.coordinates && feature.geometry.coordinates.length ? feature.geometry : undefined,
    properties: Object.assign({}, feature.properties, {
        _id: undefined,
        assetId: undefined,
        creatorId: undefined,
        featureTypeId: undefined,
        _latitude: undefined,
        _sourceWidthPx: undefined
    })
});

const getFeatures = (featureIds = []) => {
    const features = [];
    featureIds.forEach(id => {
        const feature = featureListManager.getById(id);  
        if (feature) {
            features.push(apiSafeFeature(feature));
        }
    });
    return features.length ? features : undefined;
};

function apiSafeContent(asset) {

    // Remove all internal properties
    const assetContent = Object.assign({}, asset);
    Object.keys(assetContent).forEach(key => {
        if (key.startsWith('_')) {
            delete assetContent[key];
        }
    });

    const content = Object.assign({}, assetContent, {
        authorId: undefined,
        assetId: undefined,
        assetTypeId: undefined,
        createdDateTime: undefined,
        captureDateTime: undefined,
        recordType: undefined,
        name: undefined,
        contentType: undefined,
        isVisible: true,
        media: undefined,
        display: undefined,
        placeIds: undefined,
        levelIds: undefined
    });

    content.features = getFeatures(content.featureIds);
    delete content.featureIds;

    return content;

}

const list = _list => _list && _list.items ? _list.items : _list || [];

const URLTemplateFMTToPNG =  (url) => url.replace('{fmt}', 'png');

export default {
    apiSafeFeature,
    apiSafeContent,
    list,
    URLTemplateFMTToPNG
};
