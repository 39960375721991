import layerColorModel from 'models/layer-color-model';
import ColorPicker from 'views/color-picker/color-picker';
import planModel from '../../../models/plan-model';
import ToggleColorSwitch from '../../color-picker/toggle-color-switch';
import store from 'util/data/store';

// Navigate back to pinning view.
const ReviewEditPinsButton = {
    view: () => {
        const isDone = !layerColorModel.state.isLoading;
        return <button class={`btn btn-back btn-pill btn-small btn-not-primary ${!isDone ? ' btn-inactive' : ''}`} type='submit'
            onclick={isDone && (() => planModel.goToStakeStep())}><i
                class='icon-back'></i><span>Edit Match Points</span></button>;
    }
};


// Return to site view
const ReviewDoneButton = {
    view: () => {
        const isDone = !layerColorModel.state.isLoading;
        return <button class={`btn btn-primary btn-pill btn-small btn-done ${!isDone ? ' btn-inactive' : ''}`} type='submit'
            onclick={isDone && (() => planModel.exitFromReviewing())}>Done</button>;
    }
};

// Toggle controlled color picker
const ColorSelector = {
    view: () =>
        <div class='plan-review-color-palette'>
            <div class={`toggle-plan-color-picker ${layerColorModel.state.colorPanelOpen ? 'open' : 'closed'}`} onclick={() => layerColorModel.toggleColorPicker()}>
                <ToggleColorSwitch
                    tileset={store.tilesets[layerColorModel.tilesetId]}
                    colorOn={layerColorModel.state.layerColor !== 'None'}
                    toggleFn={() => layerColorModel.toggleColor(layerColorModel.state.layerColor, layerColorModel.tilesetId)}/>
            </div>
            {layerColorModel.state.colorPanelOpen && <div class={layerColorModel.state.layerColor !== 'None' ? 'color-on' : 'color-off'}><ColorPicker
                containerId="unearth-color-picker-plan-header"
                showOpacity={true}
                starterColor={layerColorModel.state.layerColor}
                starterOpacity={layerColorModel.state.layerOpacity}
                setColorFn={(hex, opacity) => layerColorModel.setColor(hex, opacity)}
            /></div>}
        </div>
};

// Displays the asset title and info popup message
const StakeablePlanTitle = {
    view: () =>
        <div class='stakeable-title-wrap'>
            <span class='stakeable-title-text'>{planModel.recordTitle}</span>
        </div>
};


class ReviewHeader {

    view() {
        return <div>
            <div class='stakeable-menu-controls layer-controls-layer-title'>
                <StakeablePlanTitle/>
                <div class='controls-center-color-container'>
                    {!layerColorModel.state.isLoading && <ColorSelector />}
                </div>
            </div>
            <div class='stakeable-menu-controls layer-controls-primary-ctas'>
                <ReviewEditPinsButton/>
                <ReviewDoneButton />
            </div>
        </div>;
    }

}

export default ReviewHeader;
