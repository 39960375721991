import {Marker} from 'mapbox-gl';
import randomId from 'util/numbers/random-id';
import turfLength from '@turf/length';
import turfLineString from 'turf-linestring';
import appModel from 'models/app-model';
import FeatureModel from 'models/feature/feature-model';
import drawPaletteModel from 'models/draw-palette-model';

class Draw {
    constructor(opts) {
        this.opts = opts;

        this.map = this.require(opts, 'map');
        this.source = this.require(opts, 'source');

        this.units = opts.metric ? 'meters' : 'feet';

        this.color = opts.color;

        this.shortUnits = {
            feet: 'ft',
            meters: 'm'
        }[this.units];

        this.vertices = [];

        this.addVertex = this._addVertex.bind(this);
    }

    create(properties) {
        this.isNewFeature = true;
        this.properties = properties || {};
        this.setCursor('crosshair');
        return this.newFeature();
    }

    newFeature() {
        const id = this.randomId();
        const initProperties = {
            type: 'Feature',
            id,
            geometry: Object.assign({}, this.initGeometry),
            properties: Object.assign(this.properties, {_id: id})
        };
        this.feature = initProperties;
        this.map.on('click', this.addVertex);
        return this;
    }

    randomId() {
        return randomId();
    }

    require(opts, key) {
        return opts[key] || console.error(`Option "${key}" is required.`);
    }

    setCursor(cursor) {
        this.map.getCanvasContainer().style.cursor = cursor;
    }

    length(coordinates) {
        return parseFloat(turfLength(turfLineString(coordinates), {units: this.units}).toFixed(2)) + this.shortUnits;
    }

    edit(feature) {

        if (feature.geometry.type.startsWith('Multi')) {            
            return this.split(feature);
        }

        const geomType = this.type;

        if (feature.geometry.type !== geomType) {
            return console.error(`The ${geomType} class should only be used to edit ${geomType}s or Multi${geomType}s. You provided a ${feature.geometry.type}.`);
        }

        appModel.startDrawing();
        this.isNewFeature = false;
        this.properties = feature.properties;
        this.feature = feature;
        this.feature.properties._id = feature.id;

        if (this.properties && (this.properties.featureTypeId && !this.properties._placeId)) { // Skip if drawing for places, boundary filter, etc.
            drawPaletteModel.initFeature(feature);
        }

        this.render();

        return this.editFeature();
    }

    _addVertex(e) {
        this.feature.geometry.coordinates = e.lngLat.toArray();
        this.source._data.features.push(this.feature);

        if (this.onVertexAdded) {
            this.onVertexAdded(this.feature);
        }

        this.render();
        this.stop();

        if (this.onComplete) {
            this.onComplete(this.feature);
        }
    }

    makeVertex(lngLat, index = this.vertices.length, opts) {
        const element = document.createElement('i');
        element.classList.add('icon-vertex');
        const pulse = document.createElement('div');
        pulse.classList.add('pulse');
        element.appendChild(pulse);

        const vertex = new Marker(Object.assign({
            draggable: !this.isNewFeature,
            element,
            anchor: 'center'
        }, opts))
            .setLngLat(lngLat)
            .addTo(this.map);

        this.setVertexColor(vertex);
        vertex.index = index;
        
        this.vertices.slice(index).forEach(v => {
            v.index++;
        });

        this.vertices.splice(index, 0, vertex);
        return vertex;
    }

    setVertexColor(vertex) {
        vertex._element.style.borderColor = this.feature.properties._lineColor || this.feature.properties._fillColor || this.color || '#000000';
    }

    reset() {
        const coordinates = this.getCoordinates();
        this.vertices.forEach((vertex, i) => {
            vertex.setLngLat(coordinates[i]);
            this.setVertexColor(vertex);
        });
        this.render();
    }

    removeVertices() {
        this.vertices.forEach(vertex => vertex.remove());
        this.vertices = [];
    }

    removePopup() {
        if (this.popup) {
            this.popup.remove();
        }
    }

    isAnIncompleteLineString() {
        return this.type === 'LineString' 
            && this.sourceId !== '_draw_poly_source' // polygon temp lineStrings have their own cleanup methods
            && !this.isComplete; // meaning the last vertex was never clicked, must have quit early
    }

    stop() {
        this.setCursor('');
        this.removeVertices();
        this.removeEventListeners();
        if (this.centerVertex) {
            this.centerVertex.remove();
            this.centerVertex = undefined;
        }

        if (this.isNewFeature && this.feature.properties.featureTypeId) {
            if (this.isAnIncompleteLineString()) {
                this.removeFeature();
                this.source.setData(this.source._data);
            } else {
                // Create a complete feature model from the geojson (map) feature:
                this.feature = new FeatureModel(this.feature, 'drawTool');
            }
        }

        if (this.onStop) {
            this.onStop(this.feature);
        }

        appModel.stopDrawing();
        
        return this;
    }

    editFeature() {
        const vertex = this.makeVertex(this.feature.geometry.coordinates, 0)
            .on('drag', () => {
                this.feature.geometry.coordinates = vertex.getLngLat().toArray();
                this.render();
            }).on('dragend', () => {
                if (this.onVertexChanged) {
                    this.onVertexChanged(this.feature);
                    this.render();
                }
            });
        return this;
    }

    render() {
        if (this.feature.geometry.coordinates.length) {
            this.source.setData(this.source._data);
        }

        if (this.feature.updateMapFeature) {
            this.feature.updateMapFeature();
        }
    }

    getCoordinates() {
        return [this.feature.geometry.coordinates];
    }

    removeFeature(feature = this.feature) {
        const featureIndex = this.source._data.features.findIndex(f => f.id === feature.id);
        this.source._data.features.splice(featureIndex, 1);
        return featureIndex;
    }

    removeEventListeners() {
        this.map.off('click', this.addVertex);
    }

    // Split a Multi- feature into multiple single features
    split(feature) {

        const featureIndex = this.removeFeature(feature);

        const delegates = [];

        feature.geometry.coordinates.forEach((coordinates, i) => {

            const id = feature.id + i;

            const tempFeature = {
                type: 'Feature',
                id,
                properties: Object.assign({}, feature.properties, {_id: id}),
                geometry: {
                    type: this.type,
                    coordinates
                }
            };

            this.source._data.features.splice(featureIndex, 0, tempFeature);

            const delegate = new this.constructor(this.opts).edit(tempFeature);

            function syncDelegate(handler, f) {

                feature.geometry.coordinates[i] = f.geometry.coordinates;

                return handler && handler(feature);

            }

            delegate.onVertexAdded = f => syncDelegate(this.onVertexAdded, f);
            delegate.onVertexChanged = f => syncDelegate(this.onVertexChanged, f);
            delegate.onComplete = f => syncDelegate(this.onComplete, f);
            delegate.onStop = f => syncDelegate(this.onStop, f);

            delegates.push(delegate);

        });

        this.reset = () => {

            delegates.forEach(delegate => {

                Object.assign(delegate.feature.properties, feature.properties, {
                    _id: delegate.feature.properties._id
                });

                delegate.reset();

            });

        };

        this.stop = () => {

            delegates.forEach(delegate => {

                delegate.stop();

                const index = this.source._data.features.findIndex(f => f.id === delegate.feature.id);

                this.source._data.features.splice(index, 1);

            });

            this.source._data.features.splice(featureIndex, 0, feature);

            this.source.setData(this.source._data);

        };

        return this;

    }

}

export default Draw;
