import constants from 'util/data/constants';

const STREET_ADDR_PATTERN = new RegExp('(?=\\d+\\w*)\\w+\\s\\w+\\.?,?\\s\\w+');
// matches: 10 park street; 10a park street; route 101 seattle wa; 4405 10th st; 10 park street, seattle
const ZIP_CODE_PATTERN = new RegExp('^([A-z,\\s]+)?[0-9]{5}(-([0-9]{4}))?$');
// matches: 98103; 98103-1004; seattle, wa 98103
const LAT_LNG_PATTERN = new RegExp('^[-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?),\\s*[-+]?(180(\\.0+)?|((1[0-7]\\d)|([1-9]?\\d))(\\.\\d+)?)$');
// matches: 10, 180; 10,180; 10.0, 180; 10,180.0

const geocode = {

    // Given a string query ("123 Main Street"), returns results from mapbox forward geocoding search.
    // Optionally, pass in lng/lat anchor point to use for proximity param in mapbox api request.
    // Mapbox geocode search api: https://docs.mapbox.com/api/search/#geocoding
    forward: (query, anchor) => {
        const proximity = anchor ? '&proximity=' + anchor.lng + ',' + anchor.lat : '';

        const url = 'https://api.mapbox.com/geocoding/v5/mapbox.places/' + encodeURIComponent(query)
            + '.json?&types=address,region,postcode,district,place,locality,neighborhood,poi&limit=5&access_token='
            + constants.mapboxAccessToken
            + proximity;
        return fetch(url).then(response => response.json());
    },

    // Helper function, returns true if a string is similar to a street address or zip code pattern.
    isAddressLike: (str) => {
        return STREET_ADDR_PATTERN.test(str) || ZIP_CODE_PATTERN.test(str);
    },

    // Helper function, returns true if valid lat, lng pattern.
    isValidLatLng: (str) => {
        return LAT_LNG_PATTERN.test(str);
    }

};

export default geocode;
