import store from 'util/data/store';
import controlToFeature from 'util/interfaces/control-to-feature';
import Toggle from 'views/toggle';

const updateAssetFeatures = (control, assetId) => controlToFeature.updateAssetFeatures(control, assetId);

export default function(control, assetId, opts = {}) {
    const reset = opts.reset;
    const properties = store.assets[assetId].properties;
    return <Toggle reset={reset} active={properties[control.fieldName]} onchange={trueOrFalse => {
        store.assets[assetId].properties[control.fieldName] = trueOrFalse;
        updateAssetFeatures(control, assetId);
    }}/>;
}
