import formModel from 'models/form-model';
import deleteContent from 'legacy/util/data/delete-content';
import siteModel from 'models/site-model';
import Table from 'views/table/table';
import appModel from 'models/app-model';
import assetListManager from 'managers/asset-list-manager';
import pointMenu from 'legacy/components/point-menu';
import popup from 'util/popup';
import restrictions from 'util/permissions/restriction-message';
import router from 'uav-router';
import {permissionLevel} from 'util/users/permissions';
import batchSelectModel from 'models/batch-select-model';
import featureListManager from 'managers/feature-list-manager';
import {assetIdToProjectId} from 'util/data/asset-id-to-project-id';
import {copyToClipboard} from 'util/dom/clipboard';
import { VECTOR_FORCE_MISSOURI_TOOLBOX_ID_DEV_CLONE, VECTOR_FORCE_MISSOURI_TOOLBOX_ID_PROD, VECTOR_FORCE_MISSOURI_TOOLBOX_ID_STAGING_CLONE } from 'managers/toolbox-manager';

const VECTOR_FORCE_MISSOURI_PARCEL_ASSET_TYPE_NAME = 'Parcel Point';

class AssetOptionsModel {

    static deleteAsset(assetId) {
        if (formModel.assetId === assetId && formModel.toolInterface && formModel.toolInterface.draw) {
            formModel.toolInterface.draw.onStop = null; // prevent autosaving feature
        }

        return deleteContent(assetId).then(() => {
            if (formModel.toolInterface) {
                formModel.toolInterface.close();
            }

            siteModel.sidebar = Table;
            m.redraw();
        });
    }

    static showAssetOptions(assetId) {
        const asset = assetListManager.getById(assetId);
        return AssetOptionsModel.showMediaViewOption(assetId)
            || AssetOptionsModel.showUnlinkOption(assetId)
            || !siteModel.isInfoPanelOpen()
            || AssetOptionsModel.showPlacesMenuItem()
            || AssetOptionsModel.showPeopleMenuItem()
            || AssetOptionsModel.hasFeatures(assetId)
            || AssetOptionsModel.showRemoveFromMapOption(asset)
            || AssetOptionsModel.canDeleteContent(assetId)
            || AssetOptionsModel.showSupportsCloningOption(assetId)
            || assetListManager.getCompleteLayerId(assetId);
    }

    static canDeleteContent(assetId) {
        return appModel.user.permissions.canDeleteContent(assetId);
    }

    static canDuplicateContent(assetId) {
        return appModel.user.permissions.canCreateContent(assetId);
    }

    static showSupportsCloningOption(assetId) {
        return assetListManager.supportsCloning(assetId);
    }

    static hasFeatures(assetId) {
        const asset = assetListManager.getById(assetId);
        return asset
            && asset.featureIds
            && asset.featureIds.length > 0;
    }

    static showMediaViewOption(assetId) {
        const asset = assetListManager.getById(assetId);
        return asset.mediaIds && asset.mediaIds.length > 0;
    }

    static showPlacesMenuItem() {
        return this.hasPlacesEditAccess();
    }

    static showPeopleMenuItem() {
        return appModel.project.isMetaProject;
    }

    static handleCopyProjectLink(assetId) {
        const projectId = assetIdToProjectId(assetId);
        const url = `${window.location.origin}/#projectId=${projectId}`;
        copyToClipboard(url);
    }

    static handleOpenProjectInNewTab(assetId) {
        const projectId = assetIdToProjectId(assetId);
        const url = `${window.location.origin}/#projectId=${projectId}`;
        window.open(url, '_blank');
    }

    static hasPeopleEditAccess() {
        return appModel.project.isMetaProject && (appModel.user.isSuperAdmin || permissionLevel(appModel.user.role) >= permissionLevel('manager'));
    }

    static hasPlacesEditAccess() {
        return appModel.project.isMetaProject
            && appModel.user.isAccountAdmin;
    }

    static showAddToMapOption(assetId) {
        return !assetListManager.isLayer(assetId) && !assetListManager.isLink(assetId) && assetListManager.supportsMapping(assetId) && !AssetOptionsModel.hasFeatures(assetId);
    }

    static showRemoveFromMapOption(asset,
        hasFeatures = AssetOptionsModel.hasFeatures(asset.assetId),
        tool = appModel.toolbox.tools[asset.attributes.toolId]) {
        return hasFeatures && !tool.featureTypes.find(ft => ft.attributes.interface === 'project');
    }

    static showUnlinkOption(assetId) {
        if (assetListManager.isLink(assetId)) {
            return appModel.user.isContentEditable(router.params.assetId);
        }
        return false;
    }

    static handleClickDuplicate(assetId) {
        if (AssetOptionsModel.canDuplicateContent(assetId)) {
            formModel.viewAsset(assetListManager.createClone(assetId).contentId, 'Properties', true);
            pointMenu.close();
        }
    }

    static handleClickAddToMap(assetId) {
        if (appModel.user.isContentEditable(assetId)) {
            formModel.viewAsset(assetId, 'Properties');
            formModel.addToMap();
            pointMenu.close();
        }
    }

    static handleClickEditFeature(assetId, feature, whileWaitingFunction) {
        if (appModel.user.permissions.canEditContent(assetId)) {
            if (whileWaitingFunction) {
                whileWaitingFunction(); // callers can pass in a function to trigger a wait state while fetching the asset
            }
            batchSelectModel.deselectAllAssets();
            return formModel.validateThenRun(async () => {
                await assetListManager.fetch(assetId); // make sure the asset is loaded before we proceed
                formModel.viewAsset(assetId, 'Properties');
                formModel.editFeature(feature);
            });
        }
    }

    static handleClickRemoveFromMap(assetId) {
        if (appModel.user.isContentEditable(assetId)) {
            featureListManager.removeFeaturesFromAsset(assetId);
            pointMenu.close();
            popup.remove();
            m.redraw();
        }
    }

    static handleClickDelete(assetId) {
        if (AssetOptionsModel.canDeleteContent(assetId)) {
            AssetOptionsModel.deleteAsset(assetId);
            pointMenu.close();
        }
    }

    static editContentMessage(assetId) {
        const asset = assetListManager.getById(assetId);
        const assetTypeId = asset ? asset.assetTypeId : '';
        return appModel.user.permissions.canEditContent(assetId) ? '' : restrictions.message(appModel.user, 'edit', assetTypeId);
    }

    static editFeatureMessage(assetId) {
        return AssetOptionsModel.editContentMessage(assetId);
    }

    static duplicateMessage(assetId) {
        return AssetOptionsModel.canDuplicateContent(assetId) ? '' : restrictions.message(appModel.user, 'duplicate');
    }

    static deleteMessage(assetId) {
        const asset = assetListManager.getById(assetId);
        const assetTypeId = asset ? asset.assetTypeId : '';
        return AssetOptionsModel.canDeleteContent(assetId) ? '' : restrictions.message(appModel.user, 'delete', assetTypeId);
    }

    static deleteBatchMessage() {
        return Object.keys(batchSelectModel.assetsToDelete).length === 0 ? restrictions.message(appModel.user, 'delete') : '';
    }

    static personViewContentMessage(assetId, userId) {
        return appModel.user.permissions.canReadContent(assetId, userId) ? '' : 'This Person\'s role prevents them from viewing instances of this Type.';
    }

    static editContentClass(assetId) {
        return appModel.user.permissions.canEditContent(assetId) ? '' : 'disabled';
    }

    static editFeatureClass(assetId) {
        return AssetOptionsModel.editContentClass(assetId);
    }

    static duplicateClass(assetId) {
        return AssetOptionsModel.canDuplicateContent(assetId) ? '' : 'disabled';
    }

    static deleteClass(assetId) {
        return AssetOptionsModel.canDeleteContent(assetId) ? '' : 'disabled';
    }

    static showExportContentPDFOption(assetId) {
        if (appModel.toolbox.baseToolboxId && appModel.toolbox.baseToolboxId === VECTOR_FORCE_MISSOURI_TOOLBOX_ID_PROD 
        || appModel.toolbox.toolboxId === VECTOR_FORCE_MISSOURI_TOOLBOX_ID_STAGING_CLONE
        || appModel.toolbox.toolboxId === VECTOR_FORCE_MISSOURI_TOOLBOX_ID_DEV_CLONE) {
            const assetType = assetListManager.getAssetType(assetId);
            if (assetType) {
                return assetType && assetType.name === VECTOR_FORCE_MISSOURI_PARCEL_ASSET_TYPE_NAME;
            }
        }
        return false;
    }
}

export default AssetOptionsModel;
