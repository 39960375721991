import FilterTypeModel from 'models/table/filter-types/filter-type-model';

/**
 * Determines handling of filter behaviors dependent upon data input type.
 */
class BooleanFilterModel extends FilterTypeModel {

    init() {
        super.init({type: 'boolean', specialProperties: ['selectedRadioIndex']});
    }
}

export default new BooleanFilterModel();
