import restrictions from 'util/permissions/restriction-message';
import appModel from 'models/app-model';

const TableRowRestricted = {
    view: ({attrs: {label}}) => <div class="asset-row restricted">
        {label || ''}
        <i class="icon-permission-no"/>
        <span class="restricted-message">{restrictions.message(appModel.user, 'read')}</span>
    </div>
};

export default TableRowRestricted;
