import tableModel from 'models/table/table-model';
import definedOptionsModel from 'models/table/filter-types/defined-options-model';
import constants from 'util/data/constants';
import popupModel from 'models/popover-model';
import peopleModel from 'models/people/people-model';
import placesFilterModel from 'models/table/filter-types/places-filter-model';
import store from 'util/data/store';
import ShortcutMenu from 'views/ShortcutMenu/shortcut-menu';
import AssetPropertyVisibility from 'views/table/filter-menu/asset-property-visibity';

const cellMenus = {
    definedOptions: {
        oninit: () => {
            definedOptionsModel.init();
        },
        view: () => {
            const selectedControlName = tableModel.projectView.filters.opts.selectedControlName;
            return <ShortcutMenu
                onClick={() => {
                    popupModel.close();
                    definedOptionsModel.turnOffAllExcept(selectedControlName);
                }}
                filterValue={definedOptionsModel.isUserObjType
                    ? peopleModel.displayNameOrEmail(selectedControlName)
                    : selectedControlName}
            />;
        }
    },

    multiselect: {
        oninit: () => {
            definedOptionsModel.init('multiselect');
        },
        view: () => {
            const selectedControlNames = tableModel.projectView.filters.opts.selectedControlName;
            const selectedControlArray = selectedControlNames.split(', ') || [];
            return <ShortcutMenu
                onClick={() => {
                    popupModel.close();
                    definedOptionsModel.turnOffAllExceptGroup(selectedControlArray);
                }}
                filterValue={selectedControlNames}
            />;
        }
    },

    placesFilter: {
        oninit: () => {
            placesFilterModel.init();
        },
        view: () => {
            const placeId = tableModel.projectView.filters.opts.clickData;
            return <ShortcutMenu
                onClick={() => {
                    popupModel.close();
                    placesFilterModel.turnOffAllExcept(placeId);
                }}
                filterValue={store.places[placeId].name}
            />;
        }
    },

    commonDefinedOptions: {
        view: () => {
            const label = tableModel.projectView.filters.opts.label;
            const callback = tableModel.projectView.filters.opts.callback;
            return <ShortcutMenu
                onClick={() => {
                    popupModel.close();
                    callback();
                }}
                filterValue={label}
            />;
        }
    },

    assetTypeCell: {
        view: () => {
            const asset = tableModel.projectView.filters.opts.asset;
            return <div class="type-columns-inner">
                <AssetPropertyVisibility toolId={asset.attributes.toolId} tip={'Turn on columns to view, filter, and sort by Type Properties.'}/>
            </div>;
        }
    }
};

const controlTypeNameToId = constants.controlTypeNameToId;

export default {
    [controlTypeNameToId.dropdown]: cellMenus.definedOptions,
    [controlTypeNameToId.radio]: cellMenus.definedOptions,
    [controlTypeNameToId.multiselect]: cellMenus.multiselect,
    [controlTypeNameToId.multitext]: cellMenus.definedOptions,
    [controlTypeNameToId.user]: cellMenus.definedOptions,
    [controlTypeNameToId.place]: cellMenus.placesFilter,
    commonDefinedOptions: cellMenus.commonDefinedOptions,
    assetTypeCell: cellMenus.assetTypeCell
};
