import prettyNumber from 'util/numbers/pretty-number';
import adaptiveRound from 'util/numbers/adaptive-round';

// data = [{
//     yLabel: String,
//     xLabel: String,
//     color: String,
//     points: [{
//         x: Number,
//         y: Number
//     }]
// }];
const SVGChart = {
    view: ({attrs: {data, width, height, xFormat = adaptiveRound, yFormat = adaptiveRound}}) => {
        const topMargin = 10,
            bottomMargin = 45,
            leftMargin = 30,
            rightMargin = 25,
            dotRadius = 3,
            minYPixelInterval = 20,
            minXPixelInterval = 35,
            gridColor = '#f0f2f5',
            yLabelX = leftMargin - 5,
            xLabelY = height - bottomMargin + 15,
            allXValues = data.map(series => series.points.map(point => point.x)).flat(),
            allYValues = data.map(series => series.points.map(point => point.y)).flat(),
            effectiveHeight = height - topMargin - bottomMargin,
            effectiveWidth = width - leftMargin - rightMargin,
            xMin = Math.min(...allXValues),
            xMax = Math.max(...allXValues),
            yMax = Math.max(...allYValues),
            yTickCount = Math.ceil(effectiveHeight / minYPixelInterval),
            yPixelInterval = effectiveHeight / yTickCount,
            yDataInterval = yMax / yTickCount,
            xTickCount = Math.ceil(effectiveWidth / minXPixelInterval),
            xPixelInterval = effectiveWidth / xTickCount,
            xDataInterval = (xMax - xMin) / xTickCount,
            yLabels = [],
            xLabels = [],
            dots = [];
        return <svg class="svg-chart" viewBox={`0 0 ${width} ${height}`}>
            {allYValues.length && new Array(yTickCount + 1).fill().map((_, i) => {
                const y = i * yPixelInterval + topMargin;
                yLabels.push(<text class="y-label" x={yLabelX} y={y} dy="4">{prettyNumber(yMax - i * yDataInterval)}</text>);
                return <path style={`stroke: ${gridColor}`} d={`M${leftMargin},${y}L${leftMargin + effectiveWidth},${y}`} />;
            })}
            {allXValues.length && new Array(xTickCount + 1).fill().map((_, i) => {
                const x = i * xPixelInterval + leftMargin;
                xLabels.push(<text class="x-label" x={x} y={xLabelY} dx="-4" transform={`rotate(55 ${x} ${xLabelY})`}>
                    {xFormat(xMin + i * xDataInterval)}
                </text>);
                return <path style={`stroke: ${gridColor}`} d={`M${x},${topMargin}L${x},${effectiveHeight + topMargin}`} />;
            })}
            {yLabels}
            {xLabels}
            {data.map(series => {
                const points = series.points.map(p => {
                    const x = Math.round((p.x - xMin) / (xMax - xMin) * effectiveWidth) + leftMargin,
                        y = Math.round(effectiveHeight - p.y / yMax * effectiveHeight) + topMargin;
                    dots.push(<circle cx={x} cy={y} r={dotRadius} style={`fill: ${series.color}`}>
                        <title>{`${series.xLabel}: ${xFormat(p.x)}\n${series.yLabel}: ${yFormat(p.y)}`}</title>
                    </circle>);
                    return `${x} ${y}`;
                });
                return <path style={`stroke: ${series.color}`} d={`M${points.shift()}L${points.join('L')}`} />;
            })}
            {dots}
        </svg>;
    }
};

export default SVGChart;
