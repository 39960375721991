import MediaOptions from 'views/media-options';
import 'pannellum';
import api from 'legacy/util/api';
import {preFetchUrl} from 'util/media/media-utils';
import formatDate from 'legacy/util/date/format-date';

const PhotoViewer = {

    destroyPannellum() {
        if (PhotoViewer.pannellum) {
            PhotoViewer.pannellum.destroy();
            PhotoViewer.pannellum = null;
        }
    },

    async show360Photo(media) {
        const mediaId = media.mediaId;

        if (PhotoViewer.mediaId !== mediaId) {
            return;
        }
        let panoramaSrc = media.panoramaSrc;
        
        if (!panoramaSrc) { // In cases of media uploaded before media service migration UE-5649
            const photoData = await preFetchUrl(media.mediaViewerSrcUrl);
            panoramaSrc =  URL.createObjectURL(photoData);
        }

        this.is360 = true;
        const container = document.getElementById('pannellum');

        PhotoViewer.pannellum = window.pannellum.viewer(container, {
            type: 'equirectangular',
            panorama: panoramaSrc,
            autoLoad: true,
            showFullscreenCtrl: false,
            showControls: false
        });

        m.redraw();

    },

    toggle360(media) {
        const attributes = {...media.attributes};

        if (this.is360) {
            this.is360 = false;
            delete attributes.is360;

            PhotoViewer.destroyPannellum();

        } else {
            this.is360 = attributes.is360 = true;

            PhotoViewer.show360Photo(media);

        }

        api.rpc.modify('Media', {
            mediaId: media.mediaId,
            attributes
        });

    },

    onremove() {

        PhotoViewer.destroyPannellum();

    },

    async oncreate({dom, attrs: {media}, state}) {

        PhotoViewer.destroyPannellum();

        PhotoViewer.mediaId = media.mediaId;
        const img = dom.firstElementChild;
        this.is360 = media.attributes && media.attributes.is360;

        const src = media.mediaViewerSrcUrl;
        
        // All 360 photos are JPEGs
        img.onload = () => {
            if (media.mimeType === 'image/jpg' || media.mimeType === 'image/jpeg') {
                // If the image aspect ratio is wide enough,
                // Show the 360 photo toggle
                if (img.width >= img.height * 1.75) {
                    state.show360 = true;
                    m.redraw();

                    if (this.is360) {
                        PhotoViewer.show360Photo(media);
                    // If the photo is wide and has exif data,
                    // we'll assume it's a 360 photo.
                    } else if (media.info) {
                        this.is360 = false;
                    }

                }
            }
            m.redraw();
        };

        img.src = src;
        m.redraw();

    },

    view: ({attrs: {media}, state}) =>
        <div class="photo-viewer">
            <img class="photo-viewer-img" key={media.mediaId} />
            <div id="pannellum" key="pannellum" />
            {state.show360
                ? <i class={`icon-360view ${media.attributes.is360 ? 'active' : ''}`}
                    onclick={() => PhotoViewer.toggle360(media)}
                    key="360icon" />
                : <div key="360icon" />}
            {media && media.captureDateTime ? <div class="capture-date-container" key="capture-date">
                <span key="capture-date-text">Capture Date: {formatDate.ddmmyyyy(media.captureDateTime)}</span>
            </div> : <span key="empty-capture-date"></span>}
            <MediaOptions mediaId={media.mediaId} isFullScreen={true} key="opts" />
        </div>
};

export default PhotoViewer;
