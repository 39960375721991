import mediaListManager from 'managers/media-list-manager';

const MediaName = {
    oninit: ({state, attrs: {mediaId}}) => {
        mediaListManager.getMedia(mediaId, {
            onSuccess: (media) => {
                state.media = media;
                m.redraw();
            }, 
            onInvalid: () => {
                state.isInvalid = true;
                m.redraw();
            }});
    },

    view: ({state: {media, isInvalid}, attrs: {mediaId, onClick}}) => {
        if (media) {
            return <div class="media-name" onclick={(e) => onClick ? onClick(e) : null}>{media.label}</div>;
        }
        if (!mediaId || isInvalid) {
            return <span class="media-name loading-media-name"><i class="icon-triangle-exclaim"/></span>;
        }
        return <span class="media-name loading-media-name"><i class="spinner spinning teal"/></span>;
    }
};

export default MediaName;
