import dialogModel from 'models/dialog-model';
import popup from 'util/popup';
import api from 'legacy/util/api';
import message from 'views/toast-message/toast-message';
import batchSelectModel from 'models/batch-select-model';
import logConstants from 'constants/managers/log-constants';
import {datadogRum} from '@datadog/browser-rum';

function deleteBatchContent(batch) {

    return new Promise(resolve => {

        dialogModel.open({
            headline: `Delete ${batch.length} selection(s)?`,
            text: 'Please note that this operation cannot be undone.',
            yesText: 'Delete',
            onYes: () => {
                deleteBatchContent.force(batch, batch.length).then(resolve);
            },
            yesClass: 'btn btn-pill btn-red',
            noText: 'Cancel',
            noClass: 'btn btn-pill btn-secondary'
        });

    });

}


deleteBatchContent.force = (batch, assetCount) => {
    return new Promise(resolve => {
        api.rpc.request([['batchDelete', {
            content: batch
        }]]).then((assetsFailed) => {
            if (assetsFailed) {
                message.show('We were unable to delete 1 or more selections.', 'error');
                datadogRum.addError(
                    new Error(logConstants.errorLogMessages.BATCH_DELETE_FAILURES),
                    assetsFailed
                );
            }
            batch.forEach(assetId => {
                if (assetsFailed && Object.keys(assetsFailed).find(id => id === assetId)) {
                    return;
                }
                batchSelectModel.deselectSingleAsset(assetId);
                delete batchSelectModel.assetsToDelete[assetId];
            });
            m.redraw();
        });

        message.show(`${assetCount} records deleted.`);

        popup.remove();

        resolve(true);

    });
};
export default deleteBatchContent;
