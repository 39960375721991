import constants from 'util/data/constants';
import store from 'util/data/store';
import formModel from 'models/form-model';
import { MapFeatureCard } from 'views/asset-card/asset-card';
import assetListManager from 'managers/asset-list-manager';
import { copyToClipboard } from 'util/dom/clipboard';
import isMetric from 'util/numbers/is-metric';
import measure from 'legacy/util/numbers/measure';
import appModel from 'models/app-model';
import placeModel from 'models/place-model';
import capitalize from 'util/data/capitalize';
import AssetOptionsModel from 'models/asset/asset-options-model';
import PermissionsIndicator from 'views/permissions/permissions-indicator';
import AssetSelection from 'views/asset-selection/asset-selection';
import PrimeSelector from 'views/asset-selection/prime-selector';
import batchSelectModel from 'models/batch-select-model';
import userManager from 'managers/user-manager';
import TrimbleTip from 'views/trimble-tip/trimble-tip';

const NoFeatureMenu = {
    view: ({ attrs: { lngLat, elevation } }) =>
        <div class="feature-menu">
            <div className="lat-lng-menu">
                <div className="lat-lng-title">Lat/Long</div>
                <span className="lat-lng-copy" onclick={() => copyToClipboard(`${lngLat.lat},${lngLat.lng}`)}>
                    <div className="copy-text">Copy</div>
                </span>
                <div>{lngLat.lat}</div>
                <div>{lngLat.lng}</div>
                {elevation &&
                    <div>
                        <div className='lat-lng-title'>Elevation</div>
                        {isMetric() ? elevation.toFixed(2) + 'm' : measure.metersToFeet(elevation).toFixed(2) + 'ft'}</div>}
            </div>
        </div>
};

const EditFeatureLineItem = {
    oninit: ({state}) => {
        state.isLoading = false;
    },

    view: ({ state, attrs: { assetId, feature } }) => {
        const isMulti = feature.geometry
            && feature.geometry.type.startsWith('Multi');
        if (feature.properties[constants.trimbleDataKey]) {
            return <li className={'menu-option disabled'}>
                <i className={'icon-locked'} /> Edit on Map
                <TrimbleTip feature={feature}/>
            </li>;
        } else if (isMulti) {
            return <></>;
        }
        return <li class={`edit-feature-menu-option menu-option ${AssetOptionsModel.editFeatureClass(assetId)}${state.isLoading ? ' loading' : ''}`}
            onclick={() => AssetOptionsModel.handleClickEditFeature(assetId, feature, () => {
                state.isLoading = true;
                m.redraw();
            })}>
            <PermissionsIndicator lockedMessage={AssetOptionsModel.editFeatureMessage(assetId)} />
            <span class="menu-option-inner"><i class="icon-edit" /> Edit on Map <i class={state.isLoading ? 'spinner spinning teal' : ''}/></span>
        </li>;

    }
};

const SingleAsset = {
    oninit: async ({state, attrs: {assetId, features}}) => {
        if (appModel.project.isMetaProject) {
            const asset = await assetListManager.fetch(assetId);
            const tool = appModel.toolbox.tools[asset.attributes.toolId];
            const projectControlTypeId = constants.controlTypeNameToId.project;
            state.isProjectFeature = features.find(f => {
                const featureType = appModel.toolbox.featureTypes[f.properties.featureTypeId];
                if (featureType.attributes.interface === 'project') {
                    const controlType = tool.assetForm.controls.find(control => control.controlTypeId === projectControlTypeId);
                    const fieldName = controlType.fieldName;
                    state.projectId = asset.properties[fieldName];
                    return true;
                }
                return false;
            });
            m.redraw();
        }
    },

    view: ({ state, attrs: { assetId, features } }) => {
        const featureOptions = <div class="link-list">
            <ul>
                {features.length === 1 && <EditFeatureLineItem assetId={assetId} feature={features[0]} />}
                {!!state.isProjectFeature && <li class="menu-option go-to-project-option" onclick={() => appModel.changeProject(state.projectId)}>
                    <span class="btn btn-pill btn-secondary btn-small"><span class="btn-label">Go to {capitalize(appModel.toolbox.siteTermSingular)}</span></span>
                </li>}

            </ul>
        </div>;
        return <div class="feature-menu">
            <MapFeatureCard assetId={assetId} featureOptions={featureOptions} />
        </div>;
    }
};

const MultiAssetRow = {
    view: ({attrs: {isLoadingAssetId, assetId, onClickHandler}}) => {
        const assetIcon = assetListManager.getIconUrl(assetId);
        const assetName = assetListManager.getAssetName(assetId);
        const isLoading = isLoadingAssetId === assetId;

        return <li key={assetId} class="menu-option" onclick={(e) => onClickHandler(e)}>
            {!userManager.isViewOnly &&
            <AssetSelection
                isSelected={batchSelectModel.areNoAssetsSelected() ? false : batchSelectModel.selectedAssets[assetId]}
                assetId={assetId}/>
            }
            {assetIcon ? <img class={`asset-icon ${userManager.isViewOnly ? 'view-only' : ''}`} src={assetIcon}/> : <span class="icon-loading loading"/>} <span
                class={`asset-title ${userManager.isViewOnly ? 'view-only' : ''}`}>{assetName || <span class="name-loading loading"/>}</span>
            {isLoading ? <i class="spinner spinning teal"/> : ''}
        </li>;
    }
};

const MultiAsset = {
    updateAllCheckboxes(toValue, assetIds) {
        if (toValue) {
            batchSelectModel.selectBatchAssets(assetIds);
        } else {
            batchSelectModel.deselectBatchAssets(assetIds);
        }
        m.redraw();
    },

    view: ({state, attrs: {assetIds = [], placeIds = [], lngLat}}) => {
        state.allAreSelected = assetIds.every(element => !!batchSelectModel.selectedAssets[element]);
        return <div class={`feature-menu select-feature ${state.isLoading ? 'is-loading' : ''}`}>
            {!userManager.isViewOnly ?
                <div class="select-header">
                    <PrimeSelector handleClick={function () {
                        state.allAreSelected = !state.allAreSelected;
                        m.redraw();
                        MultiAsset.updateAllCheckboxes(state.allAreSelected, assetIds);
                    }} assetIds={assetIds}
                    containsAll={state.allAreSelected}
                    />
                    <p>Multiple features at this location:</p>
                </div> :  <p>Multiple features at this location:</p>
            }
            <div class="link-list">
                <ul>
                    {[assetIds.map(assetId => <MultiAssetRow assetId={assetId} isLoadingAssetId={state.isLoading} onClickHandler={async (e) => {
                        e.stopPropagation();
                        if (!state.isLoading) {
                            state.isLoading = assetId;
                            const asset = await assetListManager.fetch(assetId);
                            if (appModel.project.isMetaProject) {
                                const tool = appModel.toolbox.tools[asset.attributes.toolId];
                                const projectControlTypeId = constants.controlTypeNameToId.project;
                                const projectControlType = tool.assetForm.controls.find(control => control.controlTypeId === projectControlTypeId);
                                const projectControlFieldName = projectControlType.fieldName;
                                const projectId = asset.properties[projectControlFieldName];
                                appModel.changeProject(projectId);   
                            } else {
                                formModel.validateThenRun(() => formModel.viewAsset(assetId, 'Properties'));
                            }   
                        }
                    }}/>),
                    placeIds.map((placeId) => {
                        const place = store.places[placeId];
                        return <li
                            class="menu-option"
                            onclick={() => placeModel.showPlacePopups([placeId], lngLat)}>
                            <i class="icon-place" /> <span
                                class="asset-title">{place.name}</span>
                        </li>;
                    })].flat()}
                </ul>
            </div>
        </div>;
    }
};

const FeatureMenu = {
    view: ({ attrs: { key, assetIds = [], placeIds = [], features, lngLat, elevation } }) => {
        switch (assetIds.length + placeIds.length) {
        case 0:
            return <NoFeatureMenu lngLat={lngLat} elevation={elevation} key={key} />;
        case 1:
            return <SingleAsset assetId={assetIds[0]} features={features.filter(f => f.properties.assetId === assetIds[0])} key={key}/>;
        default:
            return <MultiAsset assetIds={assetIds} placeIds={placeIds} features={features} lngLat={lngLat} key={key}/>;
        }
    }
};

export default FeatureMenu;
