import store from 'util/data/store';
import formatDate from 'legacy/util/date/format-date';
import measure from 'legacy/util/numbers/measure';
import isMetric from 'util/numbers/is-metric';
import pointInPoly from '@turf/boolean-point-in-polygon';
import filterConstants from 'constants/models/table/filter-constants';
import appModel from 'models/app-model';
import constants from 'util/data/constants';

/**
 * If the value should be passed as metric (and the site is not already in metric) return the metric conversion. Otherwise just return the value.
 */
const apiSafeNumber = (numberType, value) => {

    if (Number.isNaN(value)) {
        // Stops error from being thrown for empty number input
        return 'None';
    }
    if (isMetric()) {
        return value;
    }
    switch (numberType) {
    case 'length':
        return measure.feetToMeters(value);
    case 'area':
        return measure.squareFeetToSquareMeters(value);
    case 'volume':
        return measure.cubicFeetToCubicMeters(value);
    default:
        return value;
    }
};

const filterUtil = {

    getAssetTypeIdInDefault() {
        const assetTypeIdIn = [];
        Object.values(appModel.toolbox.tools).forEach(tool => {
            if (!tool.attributes.hidden && tool.toolId !== constants.commentToolId) {
                assetTypeIdIn.push(tool.assetForm.assetType.assetTypeId);
            }
        });
        return assetTypeIdIn;
    },

    /**
     * createPropertyFilters: Logic for building property specific filters. This is the OR section of the filter logic.
     *      For example if we want all mainlines with status === inspected, that logic lives here.
     *  Some filters can only be included at this level, rather than as a top-level query. In such a case, the filter
     * must be added to every OR filter you add, so that it's included effectively as an AND statement (linkIds, eg)
     */
    createPropertyFilters(filterStates, assetTypeIds, linkCountFilter, nameQueryString) {
        const filters = [];
        let isFilteredOnPlace = false;

        Object.keys(filterStates).forEach((assetTypeIdKey) => {
            const filterAssetTypes = filterStates[assetTypeIdKey];
            const index = assetTypeIds.assetTypeIdIn.indexOf(assetTypeIdKey);
            if (index === -1) {
                return;
            }
            assetTypeIds.assetTypeIdIn.splice(index, 1);
            const filter = {};
            filters.push(filter);
            filter.assetTypeId = assetTypeIdKey;
            Object.assign(filter, linkCountFilter);
            const emptyList = [];

            Object.keys(filterAssetTypes).forEach((filterControlTypeKeys) => {
                const filterControlTypes = filterAssetTypes[filterControlTypeKeys];
                const selectedRadioIndex = filterControlTypes.selectedRadioIndex;

                switch (filterControlTypes.type) {
                case 'numberRange': {
                    if (selectedRadioIndex === 1) {
                        filter[`properties.${filterControlTypes.fieldName}`] = {eq: null};
                    } else if (selectedRadioIndex === 2) {
                        const range = apiSafeNumber(filterControlTypes.numberType, filterControlTypes.range);
                        const lessOrGreater = {};
                        lessOrGreater[`${filterControlTypes.selectedSign}`] = range;
                        filter[`properties.${filterControlTypes.fieldName}`] = lessOrGreater;
                    } else if (selectedRadioIndex === 3) {
                        const rangeHigh = apiSafeNumber(filterControlTypes.numberType, filterControlTypes.rangeHigh);
                        const rangeLow = apiSafeNumber(filterControlTypes.numberType, filterControlTypes.rangeLow);
                        filter[`properties.${filterControlTypes.fieldName}`] = {lte: rangeHigh, gte: rangeLow};
                    }
                    break;
                }
                case 'definedOptions': {
                    const contains = [];
                    if (filterControlTypes.isEmptyChecked) {
                        contains.push(null);
                        emptyList.push({[`properties.${filterControlTypes.fieldName}`]: {eq: null}});
                    }
                    Object.keys(filterControlTypes.checkedOptions).forEach((checkedOptionsKeys) => {
                        const filterCheckedValues = filterControlTypes.checkedOptions;
                        if (filterCheckedValues[checkedOptionsKeys]) {
                            contains.push(checkedOptionsKeys);
                        }
                    });
                    if (filterControlTypes.multiselect) {
                        filter[`properties.${filterControlTypes.fieldName}`] = {'ov': contains};
                    } else {
                        filter[`properties.${filterControlTypes.fieldName}`] = {'in': contains};
                    }
                    break;
                }
                case 'text': {
                    const assetTypeField = store.assetTypeFields[assetTypeIdKey][filterControlTypes.fieldName];
                    const subnames = [];
                    if (typeof assetTypeField.type.properties === 'object') {
                        Object.keys(assetTypeField.type.properties).map(name => subnames.push(name));
                    }
                    if (selectedRadioIndex === 1) {
                        if (subnames.length) {
                            subnames.forEach(name => {
                                filter[`properties.${filterControlTypes.fieldName}.${name}`] = {eq: null};
                            });
                        } else {
                            filter[`properties.${filterControlTypes.fieldName}`] = {eq: null};
                        }
                    } else if (selectedRadioIndex === 2) {
                        if (subnames.length) {
                            subnames.forEach(name => {
                                filter[`properties.${filterControlTypes.fieldName}.${name}`] = {ne: null};
                            });
                        } else {
                            filter[`properties.${filterControlTypes.fieldName}`] = {ne: null};
                        }
                    } else if (selectedRadioIndex === 3) {
                        filter[`properties.${filterControlTypes.fieldName}`] = {ilike: `%${filterControlTypes.queryParam}%`};
                    }
                    break;
                }
                case 'dateRange': {
                    if (selectedRadioIndex < filterConstants.daysByIndex.length && selectedRadioIndex !== 0) {
                        if (filterControlTypeKeys === 'Capture Date') {
                            filter.captureDateTimeGte = formatDate.getDaysAgo(filterConstants.daysByIndex[selectedRadioIndex]);
                        } else {
                            const gte = {gte: formatDate.getDaysAgo(filterConstants.daysByIndex[selectedRadioIndex]).getTime()};
                            filter[`properties.${filterControlTypes.fieldName}`] = gte;
                        }
                    } else if (selectedRadioIndex !== 0) {
                        if (filterControlTypeKeys === 'Capture Date') {
                            filter.captureDateTimeLte = filterControlTypes.rangeModel.toDate;
                            filter.captureDateTimeGte = filterControlTypes.rangeModel.fromDate;
                        } else {
                            const ltGte = {lte: new Date(filterControlTypes.rangeModel.toDate).getTime(), gte: new Date(filterControlTypes.rangeModel.fromDate).getTime()};
                            filter[`properties.${filterControlTypes.fieldName}`] = ltGte;
                        }
                    }
                    break;
                }
                case 'boolean': {
                    if (selectedRadioIndex) {
                        filter[`properties.${filterControlTypes.fieldName}`] = selectedRadioIndex === 1 ? {eq: true} : {ne: true};
                    }
                    break;
                }
                case 'placesFilter': {
                    if (selectedRadioIndex === 1) {
                        filter[`properties.${filterControlTypeKeys}.placeIds`] = {eq: null};
                    } else if (selectedRadioIndex === 2) {
                        isFilteredOnPlace = true;
                        if (filterControlTypes.selectedItems.length) {
                            filter[`properties.${filterControlTypeKeys}.placeIds`] = {ov: filterControlTypes.selectedItems};
                        }
                    }
                    break;
                }
                case 'linkedAsset': {
                    if (selectedRadioIndex) {
                        filter[`properties.${filterControlTypes.fieldName}`] = selectedRadioIndex === 1 ? {eq: null} : {ne: null};
                    }
                }
                }
            });

            for (const emptyProp of emptyList) {
                const emptyFilter = Object.assign({}, filter, emptyProp);
                filters.push(emptyFilter);
            }
        });

        // Adds the assetTypeIds for all assets that do not have property specific filters.
        if (isFilteredOnPlace === false
            && !nameQueryString
            && (assetTypeIds.assetTypeIdIn.length
                || !filters.length)) {
            filters.push({...assetTypeIds, ...linkCountFilter});
        }
        return filters;
    },


    // This isn't a real intersects query because it only checks whether
    // vertices fall within the polygon (not edges), but the bugginess factor
    // of a false negative in this case is low, so it's not worth adding
    // a new dependency and doing more expensive intersects queries.
    // I'm leaving this method within filterModel instead of moving it to
    // util/geo, because that would open it up to new use cases where the
    // bugginess factor might be higher.
    intersects(geometry, polygon) {
        let coordinates = geometry.coordinates;
        let geomType = geometry.type;
        if (geomType.startsWith('Multi')) {
            coordinates = coordinates[0];
            geomType = geomType.replace('Multi', '');
        }
        if (geomType === 'Polygon') {
            coordinates = coordinates[0];
        } else if (geomType === 'Point') {
            coordinates = [coordinates];
        }
        return coordinates.find(ll => pointInPoly({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: ll
            }
        }, polygon));
    },

    // We need to filter out assets that represent projects
    // that the user is not authorized to view
    configureProjectAuthFilters(args) {
        if (appModel.project.isMetaProject && appModel.user.projectIds) {
            args['properties._projectId'] = {in: appModel.user.projectIds};
        }
        return args;
    },

    doesMatchDateFilter(date, selectedDateRangeIndex, rangeModel) {
        if (selectedDateRangeIndex !== 0) {
            if (selectedDateRangeIndex < filterConstants.daysByIndex.length) {
                const minDate = formatDate.getDaysAgo(filterConstants.daysByIndex[selectedDateRangeIndex]);
                if (date < minDate) {
                    return false;
                }
            } else if (date < rangeModel.fromDate || date > rangeModel.toDate) {
                return false;
            }
        }
        return true;
    }

};

export default filterUtil;
