import debounce from 'util/events/debounce';
import siteModel from 'models/site-model';
import message from 'views/toast-message/toast-message';
import geocode from 'util/geo/geocode';
import loadExternal from 'legacy/util/data/load-external';
import mapboxgl from 'mapbox-gl';
import appModel from 'models/app-model';
import uploadHelpFlow from 'flows/upload-help/upload-help-flow';
import { STEPS } from 'constants/flows/upload-help-constants';


const SUGGEST = {
    QUERY_TERM: 1,
    QUERY_ADDR: 2,
    GO_TO_ADDR: 3,
    GO_TO_COORD: 4
};

const EXACT_MATCH = 1; // Relevancy score included with mapbox geocode search result

class SearchBoxModel {

    constructor() {
        this.search = debounce(this._search.bind(this), 200);
        this.query = '';
        this.suggestions = [];
        this.isLoading = false;
        this.includeGeoSearch = false;
    }

    get isEmpty() {
        return !this.query || !this.query.length;
    }

    close(e) {

        e.target.parentNode.blur();

    }

    onDownArrow(e) {

        if (this.suggestions.length > 0) {

            if (this.focusedSuggestionIndex === undefined) {

                this.focusedSuggestionIndex = 0;

            } else if (this.focusedSuggestionIndex < this.suggestions.length - 1) {

                this.focusedSuggestionIndex++;

            }

            m.redraw();

        } else {

            return this.search({ which: 13, target: e.target });

        }


    }

    onUpArrow() {

        if (this.focusedSuggestionIndex >= 0) {

            this.focusedSuggestionIndex--;

            m.redraw();

        }

    }

    _search(e, onSearch, onSuggest, onKeyDown) {

        const isEnter = e.which === 13,
            isDownArrow = e.which === 40,
            isUpArrow = e.which === 38,
            isEscape = e.which === 27;

        if (!onSearch && this.focusedSuggestionIndex === -1) {
            this.focusedSuggestionIndex = 0;
        }

        if (isEnter && this.focusedSuggestionIndex >= 0) {

            const suggestion = this.suggestions[this.focusedSuggestionIndex];

            if (suggestion) {
                switch (suggestion.type) {
                case SUGGEST.GO_TO_COORD:
                    const latLng = this.query.split(/,\s*/);
                    return this.geoSearch([latLng[1], latLng[0]], [this.query]);

                case SUGGEST.GO_TO_ADDR:
                    if (e && e.target) {
                        e.target.value = suggestion.text + ' ' + suggestion.secondLine;
                    }
                    return this.geoSearch(suggestion.center, [suggestion.text, suggestion.secondLine], suggestion.placeId);

                case SUGGEST.QUERY_ADDR:
                    return this.searchAddress(e);

                default: // ie, SUGGEST.QUERY_TERM suggestion
                    if (e && e.target) {
                        e.target.value = suggestion.text;
                    }
                }
            }

            this.clear();

        }

        if (isEscape) {

            return this.clear();

        }

        if (isDownArrow) {

            return this.onDownArrow(e);

        }

        if (isUpArrow) {

            return this.onUpArrow();

        }

        const query = e.target.value;

        if (onKeyDown) {

            onKeyDown(query);

        }

        if (query === this.query && !isEnter) {

            return;

        }

        this.query = query;

        this.suggestions = [];

        this.focusedSuggestionIndex = -1;

        this.displayNoResultsFound = false;

        this.suggestionTip = '';

        if (query && query.length > 0) {

            if (isEnter) {

                if (onSearch) {

                    onSearch(query);

                }

            } else {

                if (this.includeGeoSearch) {

                    this.addGeoSuggestions(query);

                }

                if (onSuggest) {

                    // Since the search view passes in the onSuggest function, track the timeout here in the model
                    // (rather than using debounce util with a named function)
                    if (this.suggestionTimeout) {
                        clearTimeout(this.suggestionTimeout);
                    }

                    this.suggestionTimeout = setTimeout(() => onSuggest(query)
                        .then(suggestions => {

                            this.suggestions.push(...suggestions.map(suggestion => {
                                return suggestion.text ? suggestion : {
                                    text: suggestion,
                                    type: SUGGEST.QUERY_TERM
                                };
                            }));

                            m.redraw();

                        }), 100);
                }
            }


        } else {

            this.clear();

        }

        m.redraw();

    }

    useSuggestion(index, e, onSearch) {
        this.focusedSuggestionIndex = index;
        if (e) {
            e.stopPropagation();
        }
        this.search({
            which: 13,
            target: e.currentTarget.parentNode.previousElementSibling.previousElementSibling
        }, onSearch);

    }

    focus(e) {

        const input = e.currentTarget.previousElementSibling;

        input.focus();

    }

    clear(e, onSearch) {

        if (e) {

            const input = e.target.previousElementSibling.getElementsByTagName('input')[0];

            input.value = '';

            input.focus();

        }

        this.query = '';

        this.suggestionTip = '';

        this.suggestions = [];

        this.displayNoResultsFound = false;

        if (onSearch) {
            onSearch('');
        }

    }

    addGeoSuggestions(query) {
        const isAddressLike = geocode.isAddressLike(query);
        if (isAddressLike) {
            this.suggestions.push({
                type: SUGGEST.QUERY_ADDR,
                text: 'Center address on map: ' + query,
                iconClass: 'icon-center-on-map'
            });
            // If it's address-like, assume it's not also a coordinate.
        } else if (geocode.isValidLatLng(query)) {
            this.suggestions.push({
                type: SUGGEST.GO_TO_COORD,
                text: 'Center address on map: ' + query,
                iconClass: 'icon-center-on-map'
            });
        }
        m.redraw();
    }

    searchAddress(e) {
        this.isLoading = true;
        this.suggestions = [];
        geocode.forward(this.query, siteModel.center).then(res => {
            // All matches get added to fuzzyMatches
            const fuzzyMatches = res.features.map(location => {
                let text = location.place_name.replace(', United States', '');
                let secondLine = '';
                if (location.place_type.includes('address') || location.place_type.includes('poi')) {
                    const index = text.indexOf(', ') + 2;
                    secondLine = text.substring(index);
                    text = text.substring(0, index);
                }
                const match = {
                    type: SUGGEST.GO_TO_ADDR,
                    center: location.center,
                    text,
                    secondLine
                };
                // Only exact matches become suggestions:
                if (location.relevance === EXACT_MATCH) {
                    this.suggestions.push(match);
                }
                return match;
            });
            // Handle results based on number returned
            switch (this.suggestions.length) {
            case 0: // No exact matches, display fuzzy matches as suggestions instead.
                this.suggestions = fuzzyMatches;
                this.suggestions.length > 0
                    ? this.suggestionTip = 'No exact address matches found:'
                    : this.displayNoResultsFound = true;
                break;
            case 1: // Exactly 1 exact match, pan the map to it.
                const suggestion = this.suggestions[0];
                e.target.value = suggestion.text + ' ' + suggestion.secondLine;
                this.geoSearch(this.suggestions[0].center, [this.suggestions[0].text, this.suggestions[0].secondLine]);
                break;
            default: // More than 1 exact match, display them as suggestions.
                this.suggestionTip = 'Multiple address matches found:';
            }
            this.isLoading = false;
            this.focusedSuggestionIndex = -1;
            m.redraw();
        }).catch(() => {
            this.isLoading = false;
            m.redraw();
            message.show('Something went wrong. Please try a different search term or contact support.', 'error');
        });
    }

    goToLngLat(lngLat, popupText) {
        siteModel.map.panToAndHighlight(lngLat, {
            duration: 2000,
            animate: true,
            offset: siteModel.map.tableOffset
        }, popupText);
        siteModel.map.once('idle', () => {
            this.isLoading = false;
            m.redraw();
        });
    }

    geoSearch(lngLat, popupText, placeId) {
        this.isLoading = true;
        if (placeId) {
            loadExternal.loadGoogle().then(() => {
                this.placeService = this.placeService || new window.google.maps.places.PlacesService(document.createElement('i'));
                this.placeService.getDetails({
                    placeId: placeId,
                    fields: ['geometry']
                }, result => {
                    const location = result && result.geometry && result.geometry.location;
                    // Google search returns bounds as "viewport". This gives a better representation of the location than the center lat/lng.
                    const viewport = result && result.geometry && result.geometry.viewport;
                    if (viewport) {
                        const sw = viewport.getSouthWest();
                        const ne = viewport.getNorthEast();
                        const coords = new mapboxgl.LngLatBounds([sw.lng(), sw.lat()], [ne.lng(), ne.lat()]);
                        const coordsGeoJSON = siteModel.map.getProjectBoundsData(coords);
                        const projectName = popupText.join(' ');

                        if (uploadHelpFlow.isActive) {
                            if (uploadHelpFlow.onStep === STEPS.SELECT_ADDRESS) {
                                uploadHelpFlow.setProjectLocationFromSearch(coordsGeoJSON, projectName);
                                return;
                            }
                            uploadHelpFlow.keepActiveToolOpen = true;
                            uploadHelpFlow.createChildProjectFromSearch(coordsGeoJSON, projectName);
                        } else {
                            appModel.toolbox.toolInterface.setGeoJSONData(coordsGeoJSON, projectName);
                        }
                        siteModel.map.safeFitBounds([
                            coords.getSouthWest().toArray(),
                            coords.getNorthEast().toArray()
                        ], { padding: { top: 20, bottom: 20, left: 50, right: 400 }, offset: [181, 0] });

                    }
                    if (!location && !viewport) {
                        message.show('This place couldn\'t be found.', 'error');
                    }
                });
            });
        } else {
            this.goToLngLat(lngLat, popupText);
        }
        this.suggestionTip = '';
        this.suggestions = [];
        m.redraw();
    }
}

SearchBoxModel.SUGGEST = SUGGEST;

export default SearchBoxModel;
