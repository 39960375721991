import userflow from 'userflow.js';
import md5 from 'md5';
import constants from 'util/data/constants';
import appModel from 'models/app-model';
import store from 'util/data/store';

let initialized = false;
let isTrial = false;
let _userId = undefined;

// These are found in the Userflow app's environment settings.
const userflowEnvTokens = {
    development: 'ct_dcabimwmwvctbeqxjnygq7sype',
    staging: 'ct_r27f4evbkven7icq5e6xjdot2m',
    production: 'ct_5f3yw6sjezdhbp4kbnom5c76d4'
};

/**
 * Initializes the userflow javascript api.
 *      Once initialized, userflow will track routes.
 *
 *      isTrialUser: We currently only send userflow information on trial users. I expect this will change in the future.
 */
const loadUserFlow = (isTrialUser) => {
    if (constants.isDeployed && !initialized) {
        const envToken = userflowEnvTokens[constants.awsProfile];
        userflow.init(envToken);
        initialized = true;
        isTrial = !!isTrialUser;
    }
};

/**
 * trackUser updates user information in userflow. This resets the currentView.
 */
const identifyUser = (userId, chargebeeSku) => {
    if (constants.isDeployed) {
        loadUserFlow(isTrial);
        _userId = md5(userId);
        const attrs = {
            userId: _userId, 
            environment: md5(constants.awsProfile),
            currentView: 'None',
            email: appModel.user.emailAddress,
            isTrial,
            account: appModel.account.accountId,
            accountName: store.account.name
        };
        if (chargebeeSku) {
            attrs.chargebeeSku = md5(chargebeeSku);
        }
        userflow.identify(_userId, attrs);
    }
};

/**
 * updateView sets the most recent view the userflow user has entered.
 *          Note the view is not hashed
 */
const updateView = (view = 'None') => {
    if (constants.isDeployed) {
        loadUserFlow(isTrial);
        const attrs = {
            environment: md5(constants.awsProfile),
            currentView: view,
            isTrial
        };
        userflow.identify(_userId, attrs);
    }
};

/**
 * Sends a custom event to userflow. Useful due to this being an SPA.
 */
const track = (...args) => {
    if (constants.isDeployed) {
        loadUserFlow(isTrial);
        userflow.track(...args);
    }
};


export default {loadUserFlow, identifyUser, userflow: userflow, track, updateView};
