import api from 'legacy/util/api';
import throttle from 'util/events/throttle';
import publish from 'legacy/util/api/publish';
import router from 'uav-router';
import assetListManager from 'managers/asset-list-manager';
import store from 'util/data/store';
import formModel from 'models/form-model';
import ProjectViewModel from 'models/table/project-view-model';

const limit = 20;

// Extendable model for creating a paginated list of assets with common filtering options outside of table view
class FilteredAssetListModel {

    constructor() {
        this.assetId = formModel.assetId;
        this.assetIds = [];

        this.loading = true;
        this.projectView = null;
    
        this.getNextPage();
        this.awaitChanges();

        this.onscroll = throttle(this._onscroll.bind(this));
    }

    getArgs() {
        const args = this.projectView.getArgs();
        args.offset = this.assetIds.length;
        args.limit = limit;
        return args;
    }

    async setProjectView() {
        this.projectView = new ProjectViewModel();
        this.projectView.common.onFiltersChanged = this.filtersChanged.bind(this);
        this.projectView.filters.onFiltersChanged = this.filtersChanged.bind(this);
    }

    async getNextPage(opts = {}) {
        if (!this.projectView) {
            await this.setProjectView();
        }

        const pageSize = opts.limit || limit;
        const args = this.getArgs();
        api.rpc.list('Content', Object.assign({
            offset: this.assetIds.length,
            limit: pageSize,
            order: 'createdDateTime desc',
            include: ['media'],
            projectId: router.params.projectId
        }, args)).then(assets => {
            const assetIds = assets.forEach(asset => {
                if (asset.contentId !== this.assetId) {
                    assetListManager.addToStore(asset);
                    this.assetIds.push(asset.contentId);
                }
                return asset.contentId;

            });
            m.redraw();
            if (assets.length < pageSize) {
                if (this.onComplete) {
                    this.onComplete();
                }
                this.loadedAll = true;
                m.redraw();   
            }
            this.loading = false;

            if (this.onResults) {
                this.onResults(assetIds);
            }
        });
    }

    filtersChanged() {
        this.assetIds = [];
        this.loading = true;
        this.projectView.saveToApi();
        this.getNextPage();
    }
    
    _onscroll(e) {
        if (!this.loadedAll) {
            const scrollBox = e.target;
            if (scrollBox && scrollBox.scrollTop && scrollBox.scrollTop + scrollBox.offsetHeight > scrollBox.scrollHeight - 100) {
                this.getNextPage();
            }
        }
    }

    awaitChanges() {
        publish.await({
            changeType: 'new',
            recordType: 'content',
            test: asset => this.isForThisTab(asset) && publish.isValidChangedBy(asset),
            callback: asset => {
                assetListManager.addToStore(asset);

                this.assetIds.unshift(asset.contentId);

                this.onContentChange('new', asset.contentId);

                m.redraw();

            },
            persist: true
        });

        publish.await({
            changeType: 'modified',
            recordType: 'content',
            test: asset => this.isForThisTab(asset) && publish.isValidChangedBy(asset),
            callback: asset => {
                assetListManager.addToStore(asset, true);

                const index = this.assetIds.indexOf(asset.contentId);

                if (index === -1) {

                    this.assetIds.unshift(asset.contentId);

                }

                m.redraw();

                this.onContentChange('modified', asset.contentId);

            },
            persist: true
        });

        publish.await({
            changeType: 'deleted',
            recordType: 'content',
            test: asset => this.isForThisTab(asset),
            callback: asset => {
                const index = this.assetIds.indexOf(asset.contentId);

                delete store.assets[asset.contentId];

                if (index !== -1) {

                    this.assetIds.splice(index, 1);

                    m.redraw();

                }

                this.onContentChange('deleted', asset.contentId);
            },
            persist: true
        });

    }

    // If applicable, handle based on subclass method.
    onContentChange() {}

}

export default FilteredAssetListModel;
