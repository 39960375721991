import appModel from 'models/app-model';
import capitalize from 'util/data/capitalize';
import constants from 'util/data/constants';

const reasonByRole = {
    'owner': 'As an Owner',
    'admin': 'As an Administrator',
    'manager': 'As a Manager',
    'general': 'As a General User',
    'limited': 'As a Limited User',
    'viewer': 'As a Viewer',
    'hidden': 'As a Hidden User'
};

const actions = {
    'create': 'add',
    'read': 'view',
    'edit': 'edit',
    'delete': 'delete',
    'geocorrect': 'edit'
};

const specialRestrictions = {
    ADD: 'you did not add',
    ASSIGNED: 'it is assigned to you'
};

specialRestrictions.ADD_OR_ASSIGNED = specialRestrictions.ADD + ' unless ' + specialRestrictions.ASSIGNED;


export const getAddRemovePeopleMessage = (person, user = appModel.user) => {
    const hasEditAccess = person.userCanEdit('access');
    if (hasEditAccess) {
        return '';
    }
    // Generic message, no People editing capabilities
    if (!user.isAccountAdmin && user.role !== 'manager') {
        return `${reasonByRole[user.role]}, you cannot add or remove People from this ${capitalize(appModel.toolbox.siteTermSingular)}.`;
    }
    // Specific message, can't edit based on role of other user
    return `${reasonByRole[user.role]}, you cannot add or remove People with the role of ${constants.accountUserRoles[person.role].display}.`;
};

const formatAction = (action) => {
    return actions[action] || action;
};

const formatSpecialRestriction = (assetTypeId, action) => {
    // Include ASSIGNED if assetTypeId is determined to have user control
    if (assetTypeId) {
        if (appModel.toolbox.assetTypesWithUserControls[assetTypeId]) {
            if (action === 'edit') {
                return ` ${specialRestrictions.ADD_OR_ASSIGNED}`;
            }
        }
        return ` ${specialRestrictions.ADD}`;
    }
    return '';
};

const formatType = (type) => {
    if (type === 'survey') {
        return ' a Survey';
    }
    if (type === 'plan') {
        return ' a Layer';
    }
    if (type === 'folder') {
        return ' a Group';
    }
    return ' an instance of this Type';
};

function message(user, action, assetTypeId) {
    if (!action) {
        return `${reasonByRole[user.role]}, you cannot perform this action.`;
    }
    return `${reasonByRole[user.role]}, you cannot ${formatAction(action)}${formatType(assetTypeId)}${formatSpecialRestriction(assetTypeId, action)}.`;

}

export default {message};
