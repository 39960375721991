import Draw from './draw';

class Point extends Draw {

    constructor(opts) {
        super(opts);
        
        this.type = 'Point';
        this.initGeometry = {
            type: 'Point',
            coordinates: []
        };
    }
}

export default Point;
