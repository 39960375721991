import appModel from 'models/app-model';
import drawPaletteModel from 'models/draw-palette-model';
import siteModel from 'models/site-model';

class FeatureTypeModel {
    constructor(tool, _apiFeatureType) {
        this.toolId = tool.toolId;

        this.featureTypeId = undefined;
        this.name = undefined;
        this.createdDateTime = undefined;
        this.updatedDateTime = undefined;
        this.isVisible = true;


        Object.assign(this, _apiFeatureType);
        appModel.toolbox.featureTypes[_apiFeatureType.featureTypeId] = _apiFeatureType;

        this.attributes = this.attributes || {};
        this.geometry = this.geometry || {};
        this.creator = this.creator || {};
        this.featureStyles = this.featureStyles || {};
        this.properties = this.properties || undefined;

        this._queueToAddGeoJson();
        this._normalizeMeasurementUnits();
    }

    get source() {
        return siteModel.map.getSource(this.featureTypeId);
    }

    get tool() {
        return appModel.toolbox.tools[this.toolId] || appModel.toolbox.hiddenTools[this.toolId];
    }

    get assetTypeId() {
        return appModel.toolbox.tools[this.toolId].assetForm.assetType.assetTypeId;
    }

    getDefaultProperties() {
        if (drawPaletteModel.states[this.featureTypeId]) {
            return Object.assign({}, this.properties, drawPaletteModel.states[this.featureTypeId]); 
        }
        return this.properties;
    }

    redrawMapFeatures() {
        const source = this.source;
        source.setData(this.source._data);
    }

    _queueToAddGeoJson() {
        // If this is a geojson type, we're going to need to add a layer for it, but only once the map is loaded.
        // For now, add it to a queue.
        if (this.geometry) {
            const lineMetrics = this.geometry.type === 'LineString'
                    && this.tool.featureStyles.length > 1
                    && this.tool.featureStyles.find(f => f.style && f.style.layout && f.style.layout['text-field']);
            appModel.toolbox.geometryFeatures.push({featureTypeId: this.featureTypeId, lineMetrics: !!lineMetrics});
        }
    }

    _normalizeMeasurementUnits() {
        // Note features w/linked controls that need unit conversions for rendering
        this.attributes.linkedControls && this.attributes.linkedControls.forEach(linkedControl => {
            const control = this.tool.assetForm.controls.find(ctrl => ctrl.fieldName === linkedControl);
            if (control) {
                appModel.toolbox.setMetricConversionFn(control, this.featureTypeId);
            }
        });
    
        // Note features w/styled controls that need unit conversions for rendering
        this.attributes.styledControls && this.attributes.styledControls.forEach(styledControl => {
            const control = this.tool.assetForm.controls.find(ctrl => ctrl.fieldName === styledControl);
            if (control) {
                appModel.toolbox.setMetricConversionFn(control, this.featureTypeId);
            }
        });

    }
}

export default FeatureTypeModel;
