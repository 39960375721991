import transformScale from '@turf/transform-scale';
import transformRotate from '@turf/transform-rotate';
import bearing from '@turf/bearing';
import distance from '@turf/distance';
import destination from '@turf/destination';

export const polygon = (coordinates) => {
    return {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates
        }
    };
};

export const point = (coordinates) => {
    return {
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates
        }
    };
};


export const translatePolygonCoordinates = ([originalCoords], originalCenter, newCenter) => {
    if (originalCenter.lng === newCenter.lng && originalCenter.lat === newCenter.lat) {
        return [originalCoords];
    }

    const cornerDistancesFromCenter = [];
    const cornerBearingsFromCenter = [];

    const originalCenterPoint = point([originalCenter.lng, originalCenter.lat]);
    originalCoords.forEach(corner => cornerDistancesFromCenter.push(distance(originalCenterPoint, point([corner[0], corner[1]]))));
    originalCoords.forEach(corner => cornerBearingsFromCenter.push(bearing(originalCenterPoint, point([corner[0], corner[1]]))));
    
    const newCenterPoint = point([newCenter.lng, newCenter.lat]);
    const newCorners = [];
    cornerDistancesFromCenter.forEach((_distance, i) => {
        const newCornerPoint = destination(newCenterPoint, _distance, cornerBearingsFromCenter[i]);
        newCorners.push([newCornerPoint.geometry.coordinates[0], newCornerPoint.geometry.coordinates[1]]);
    });
    
    return [newCorners];
};

export const rotatePolygonCoordinates = (coordinates, degrees, centerPoint) => {
    if (!degrees) {
        return coordinates;
    }    
    return transformRotate(polygon(coordinates), degrees, {pivot: [centerPoint.lng, centerPoint.lat]}).geometry.coordinates;
};

export const degreesToRadians = (degrees) => {
    const pi = Math.PI;
    return degrees * (pi / 180);
};

export const scalePolygonCoordinates = (coordinates, factor, centerPoint) => {
    if (factor === 1 || !factor) {
        return coordinates;
    }

    return transformScale(polygon(coordinates), factor, {origin: [centerPoint.lng, centerPoint.lat]}).geometry.coordinates;
};

export const calculateDeltaLngLat = (oldLngLat, newLngLat) => {
    const newLng = oldLngLat.lng - newLngLat.lng;
    const newLat = oldLngLat.lat - newLngLat.lat;
    const delta = {
        lng: newLng, 
        lat: newLat
    };
    
    return delta;
};

export const getNewLngLatFromPixelChange = (originalLngLat, pxDelta = {x: 0, y: 0}, map) => {
    if (pxDelta.x === 0 && pxDelta.y === 0) {
        return {lng: 0, lat: 0};
    }

    const centerPointPx = map.project(originalLngLat);
    const newCenterPointPx = {x: centerPointPx.x + pxDelta.x, y: centerPointPx.y + pxDelta.y};
    const newLngLat = map.unproject(newCenterPointPx);
    
    return newLngLat;
    
};

export const lngLatsAreEqual = (lngLat1, lnglat2) => {
    return lngLat1.lng === lnglat2.lng && lngLat1.lat === lnglat2.lat; 
};

export const determineOrientation = (p1, p2, p3) => {
    const val = (p2.y - p1.y) * (p3.x - p2.x) -
    (p2.x - p1.x) * (p3.y - p2.y);
 
    if (val === 0) {
        return 0;
    } // collinear
 
    // clock or counterclock wise
    return val > 0 ? 'counter' : 'clock';
};

export const radiansToDegrees = (radians) => {
    const pi = Math.PI;
    return radians * (180 / pi);
};

/**
* Calculates the angle ABC (in radians) 
*
* A first point, ex: {x: 0, y: 0}
* C second point
* B center point
*/
export const calculateAngle = (A, B, C) => {
    const AB = Math.sqrt(Math.pow(B.x - A.x, 2) + Math.pow(B.y - A.y, 2));    
    const BC = Math.sqrt(Math.pow(B.x - C.x, 2) + Math.pow(B.y - C.y, 2)); 
    const AC = Math.sqrt(Math.pow(C.x - A.x, 2) + Math.pow(C.y - A.y, 2));
    return Math.acos((BC * BC + AB * AB - AC * AC) / (2 * BC * AB));
};

export const valueIsValid = (value, range) => {
    if (!range) {
        return !isNaN(value);
    }
    return !isNaN(value) && -range <= value && value <= range;
};
