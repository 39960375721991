import savedViewsModel from 'models/saved-views-model';
import popupModel from 'models/popover-model';
import savedViewsOptionsMenu from 'views/saved-views/saved-views-options-menu';

const SavedViewsListItem = {
    oninit: ({state, attrs: {projectViewId}}) => {
        state.popupOpen = false;
        state.cssClass = projectViewId === savedViewsModel.activeSavedViewId ? ' is-active' : '';
    },

    view: ({state, attrs: {projectViewId, hideProjectSwitcher, disableOnclickEvent}}) => {
        state.projectView = savedViewsModel.getProjectView(projectViewId);
        return state.projectView ? <div key={projectViewId} onclick={disableOnclickEvent ? null : () => savedViewsModel.applySavedViewId(state.projectView.projectViewId)} class={`UFtracker-saved-view-card saved-views-list-item${state.cssClass || ''}${state.popupOpen ? ' popup-menu-open' : ''}`}>
            <div className='list-card'>
                <i className={'icon-bookmark'}/>
                <span className = 'list-label'>{state.projectView.name}</span>
                <i className='icon-ellipsis' onclick={function (e) {
                    e.stopPropagation();
                    state.popupOpen = true;
                    popupModel.open(this, {
                        args: {projectViewId: state.projectView.projectViewId, hideProjectSwitcher},
                        wrapClass: 'saved-views-options-menu-wrap',
                        view: savedViewsOptionsMenu,
                        keepUIOpen: true,
                        onClose: () => {
                            state.popupOpen = false;
                            m.redraw();
                        }
                    });
                }}/>
            </div>
        </div> : <span key={projectViewId}/>;
    }
};

export default SavedViewsListItem;
