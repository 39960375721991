import initializer from 'util/initializer';
import appModel from 'models/app-model';
import api from 'legacy/util/api';
import isMetric from 'util/numbers/is-metric';
import {styleControls, unitsDisplay} from 'constants/models/draw-palette-constants';
import measure from 'legacy/util/numbers/measure';
import round from 'util/numbers/round';
import {getNormalizedKey} from 'util/events/get-normalized-key';
import formModel from 'models/form-model';

class DrawPaletteModel {

    constructor() {
        this.resetAll();
    }

    resetAll() {
        this.activeFeature = undefined;
        this.isSaving = false;
        this.hasSavedStylesEnabled = false;
        this.states = {};
        this.setToolStyleName(null);        
        this.toolStyleId = null;
        this.isAddingStyle = false;
        this.styleControlKeys = [];
        this.colorPickerOpenOn = null;
        this.colorPickerTopPosition = 0;
        this.units = isMetric() ? unitsDisplay.metersShort : unitsDisplay.yardsShort;
    }

    transformToDisplay(value, units, step = 1, factor = 1) {
        if (value && units === unitsDisplay.yardsShort) {
            return round(measure.metersToYards(value), 1 / Number(step)) * factor;
            
        }
        return value * factor;
    }     

    transformToSave(value, units, factor = 1) {
        if (value && units === unitsDisplay.yardsShort) {
            return measure.yardsToMeters(value) / factor;
        }
        return value / factor;
    }


    initFeature(feature) {
        this.activeFeature = feature;
        this.isSaving = false;
        this.toolStyleName = '';
        this.toolStyleId = null;
        this.colorPickerOpenOn = null;
        this.colorPickerTopPosition = 0;

        this.currentFeatureTypeId = this.activeFeature.properties.featureTypeId;
        this.states[this.currentFeatureTypeId] = this.states[this.currentFeatureTypeId] || {};
        const toolGroup = appModel.toolbox && appModel.toolbox.toolGroups && appModel.toolbox.toolGroups[this.currentToolGroupId];
        this.hasSavedStylesEnabled = toolGroup ? toolGroup.hasSavedStylesEnabled : false;
        const featureType = appModel.toolbox.featureTypes[drawPaletteModel.activeFeature.featureTypeId];
        this.styleControlKeys = Object.values(styleControls).filter(key => featureType.properties[key] !== undefined);

        if (this.hasSavedStylesEnabled && feature.properties.toolStyleId && appModel.toolbox.savedStyles[feature.properties.toolStyleId]) {
            const toolStyleId = this.activeFeature.properties.toolStyleId;
            this.toolStyleId = toolStyleId;
            this.toolStyleName = appModel.toolbox.savedStyles[toolStyleId].name;
        }
        m.redraw();
    }

    handleInput(propKey, newValue) {
        this.activeFeature.properties[propKey] = newValue;
        this.states[this.currentFeatureTypeId][propKey] = newValue;
        delete this.states[this.currentFeatureTypeId].toolStyleId; 
        delete this.activeFeature.properties.toolStyleId; // No longer using a saved style
        this.toolStyleId = null;
        this.toolInterface.render();
        m.redraw();
    }

    onToolClose() {
        this.activeFeature = undefined;
        this.currentFeatureTypeId = undefined;
        this.isSaving = false;
        this.hasSavedStylesEnabled = false;
        this.styleControlKeys = [];
        this.toolStyleId = null;
        this.setToolStyleName(null);        
        m.redraw();
    }

    get currentToolId() {
        return this.toolInterface.tool.toolId;
    }

    get currentToolGroupId() {
        return this.toolInterface.tool.toolGroupId;
    }

    get isUsingCustomStyle() {
        return !!this.toolStyleId;
    }

    get toolName() {
        return appModel.toolbox.tools[drawPaletteModel.currentToolId].name;
    }

    get toolInterface() {
        if (appModel && appModel.toolbox && appModel.toolbox.toolInterface) {
            return appModel.toolbox.toolInterface;
        }        
        return  formModel.toolInterface; 
    }

    getStylesList() {
        return appModel.toolbox.savedStylesByToolId[drawPaletteModel.currentToolId];
    }

    getStylesById(styleId) {
        return appModel.toolbox.savedStyles[styleId];
    }

    isColorPickerOpenOn(propKey) {
        return this.colorPickerOpenOn === propKey;
    }

    openColorPickerOn(propKey, element) {
        if (propKey !== this.colorPickerOpenOn) {
            if (element) {
                this.colorPickerTopPosition = element ? element.getBoundingClientRect().top : 0;
            }
            this.colorPickerOpenOn = propKey;
            return m.redraw();
        }
    }

    showReset() {
        if (!this.isUsingCustomStyle && (Object.keys(this.states).length === 0 || Object.keys(this.states[this.currentFeatureTypeId]).length === 0)) {
            return false;
        }
        return true;
    }

    defaultValue(propKey) {
        return appModel.toolbox.featureTypes[this.currentFeatureTypeId].properties[propKey];
    }

    resetStyles() {
        this.states[this.currentFeatureTypeId] = {};
        const featureType = appModel.toolbox.featureTypes[this.currentFeatureTypeId];
        for (const key in featureType.properties) {
            if (this.activeFeature.properties[key] !== undefined) {
                this.activeFeature.properties[key] = featureType.properties[key];
            }
        }
        delete this.activeFeature.properties.toolStyleId;
        this.toolStyleId = null;
        this.setToolStyleName(null);   
        this.activeFeature.updateMapFeature();
        this.toolInterface.render();
        m.redraw();
    }

    handleStyleNameInput(e) {
        this.setToolStyleName(e.target.value);
        const key = getNormalizedKey(e.key);
        if (key === 'Enter') {
            this.saveStyles();
        }
    }

    setToolStyleName(name) {
        this.toolStyleName = name;
    }

    useCustomStyle(toolStyleId) {
        const toolStyle = appModel.toolbox.savedStyles[toolStyleId];
        const featureTypeId = appModel.toolbox.tools[toolStyle.attributes.toolId].featureTypes[0].featureTypeId;
        this.toolStyleId = toolStyleId;
        this.setToolStyleName(toolStyle.name);        
        this.states[featureTypeId] = Object.assign({}, toolStyle.attributes.properties);
        this.states[featureTypeId].toolStyleId = toolStyleId;
        this.isAddingStyle = false;
        m.redraw();
    }

    handleCustomStyleSelect(toolStyleId) {
        this.useCustomStyle(toolStyleId);
        Object.keys(this.states[this.currentFeatureTypeId]).forEach(key => {
            this.activeFeature.properties[key] = this.states[this.currentFeatureTypeId][key];
        });
        this.toolInterface.render();
    }

    isFeatureAnIcon() {
        return drawPaletteModel.activeFeature && drawPaletteModel.activeFeature.properties._sourceWidthPx;
    }
    
    deleteAllSavedStyles() {
        const toolGroupId = this.currentToolGroupId;
        const toolId = this.currentToolId;
    
        const all = [...appModel.toolbox.savedStylesByToolId[toolId]];
        appModel.toolbox.savedStylesByToolGroupId[toolGroupId] = appModel.toolbox.savedStylesByToolGroupId[toolGroupId].filter(id => !all.find(_id => _id === id));
        appModel.toolbox.savedStylesByToolId[toolId] = [];
        this.toolStyleId = null;

        const rpc = [];
        all.map(id => rpc.push(['deleteToolStyle', {toolStyleId: id
        }])); // TODO there is a limit on how many rpcs we can send at once, need to account for that
        this.isSaving = true;
        m.redraw();
        return api.rpc.request(rpc).then(() => {
            this.isSaving = false;
            m.redraw();
        });
        
    }

    deleteSavedStyle(toolStyleId) {
        this.isSaving = true;

        const style = appModel.toolbox.savedStyles[toolStyleId];
        const toolGroupId = style.attributes.toolGroupId;
        const toolId = style.attributes.toolId;
        
        appModel.toolbox.savedStylesByToolGroupId[toolGroupId] = appModel.toolbox.savedStylesByToolGroupId[toolGroupId].filter(id => id !== toolStyleId);
        appModel.toolbox.savedStylesByToolId[toolId] = appModel.toolbox.savedStylesByToolId[toolId].filter(id => id !== toolStyleId);
        delete appModel.toolbox.savedStyles[toolStyleId];
        this.toolStyleId = null;

        m.redraw();
        return api.rpc.request([['deleteToolStyle', {toolStyleId: toolStyleId
        }]]).then(result => {
            this.isSaving = false;
            this.setToolStyleName(null);        
            m.redraw();
            return result;
        });
    }

    saveStyles() {
        const toolId = this.currentToolId;
        const toolGroupId = this.currentToolGroupId;
        const featureTypeId = this.currentFeatureTypeId;
        const properties = {};
        this.styleControlKeys.forEach(key => {
            properties[key] = this.activeFeature.properties[key];
        });
        const attributesToSave = {
            toolId,
            toolGroupId,
            featureTypeId,
            properties
        };
        this.isSaving = true;
        if (!this.toolStyleName) {
            const toolName = appModel.toolbox.tools[toolId].name;
            this.setToolStyleName(toolName + ' Style Shortcut');        
        }
        m.redraw();
        return this.createSavedStyle(this.toolStyleName, attributesToSave).then((response) => {
            appModel.toolbox.savedStyles[response.toolStyleId] = response;
            appModel.toolbox.savedStylesByToolGroupId[toolGroupId] = appModel.toolbox.savedStylesByToolGroupId[toolGroupId] || [];
            appModel.toolbox.savedStylesByToolGroupId[toolGroupId].unshift(response.toolStyleId);
            appModel.toolbox.savedStylesByToolId[toolId] = appModel.toolbox.savedStylesByToolId[toolId] || [];
            appModel.toolbox.savedStylesByToolId[toolId].unshift(response.toolStyleId);

            this.isSaving = false;
            m.redraw();
            this.useCustomStyle(response.toolStyleId);
        });
    }

    createSavedStyle(name, attributes) {
        return api.rpc.request([['createToolStyle', {
            toolboxId: appModel.toolbox.toolboxId,
            type: 'shortcut',
            platform: 'web',
            name,
            attributes
        }]]);
    }

    
}

const drawPaletteModel = new DrawPaletteModel();

initializer.add(() => drawPaletteModel.resetAll(), 'drawPaletteModel');

export default drawPaletteModel;
