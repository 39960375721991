import LineString from './linestring';
import Draw from './draw';
import Stack from 'util/data/stack';
import CropModel from 'models/crop-model';
import lngLatsToLatLngs from '../geo/lnglats-to-latlngs';

const CROP_TEMP_SOURCE_ID = '_draw_crop_temp_source';
const CROP_LINE_LAYER_ID = '_draw_crop_line_layer';
const CROP_FILL_LAYER_ID = '_draw_crop_fill_layer';
const BOUNDARY_COORDS = 1;
const MIN_VERTEX_COUNT_POLY = 3;
const MIN_VERTEX_COUNT_LINE = 2;

/**
 * Modification of the Polygon tool (./polygon.js) to draw a polygon shape for cropping.
 * After the polygon is created, inversion coordinates are added to the feature coord list
 * to render an inverted version of the shape drawn.
 * Added support for undo-ing and clearing.
 */
class CropTool extends LineString {
    constructor(opts) {
        super(opts);
        this.opts = opts;
        this.source = opts.source;
        this.steps = new Stack(); // For tracing history w/undo button.
        this.invertedBounds = this.calcPolygonInversionCoords(opts.map.getMaxBounds());
        this.createLineStringLayer();
    }

    /**
     * Saves the current state to the stack for undo history.
     * State data saved includes: Number of stakes, locations of stakes, and which StakePair was active.
     */
    saveStep() {
        if (this.type === 'LineString' && this.vertices.length) {
            this.steps.push({
                vertices: [...this.vertices],
                type: this.type
            });
        } else if (this.type === 'Polygon' && this.vertices.length) {
            this.steps.push({
                coords: [[...this.invertedBounds], [...this.feature.geometry.coordinates[BOUNDARY_COORDS]]],
                type: this.type
            });
        }
        CropModel.updateButtonStates();
        if (!this.vertices.length ) {
            this.map.off('mousemove', this.onMouseMove);
        }
    }

    /**
     * Rolls back the state to the most recently added step (top of stack).
     * State data saved includes: Number of stakes, locations of stakes, and which StakePair was active.
     */
    undoStep() {
        this.steps.pop();
        const step = this.steps.pop();
        if (this.type === 'LineString') {
            this.undoLineStep(step);
        } else if (this.type === 'Polygon' && step.type === 'LineString') {
            this.undoLineToPolygonStep(step);
        } else {
            this.undoPolygonStep(step);
        }
        this.saveStep();
    }

    /**
     * Undoes the step that completed the polygon. Reverts the shape back to a linestring.
     */
    undoLineToPolygonStep(step) {
        this.map.removeLayer(CROP_FILL_LAYER_ID);
        this.type = 'LineString';
        this.isNewFeature = true;
        this.source._data.features[0] = this.feature = this.lineFeature;
        this.lastVertexOffset = 1;
        this.minVertexCount = MIN_VERTEX_COUNT_LINE;
        this.removeEventListeners();
        this.removeVertices();

        step.vertices.forEach(vertex => this.makeVertex(vertex._lngLat, vertex.index));

        if (this.vertices.length >= MIN_VERTEX_COUNT_POLY) {
            this.setUpFirstVertex();
        }
        this.map.on('mousemove', this.onMouseMove);
        this.map.on('click', this.addVertex);
        this.map.off('click', this.checkLineClicked);
    }

    /**
     * Undoes a step that added a vertex to the line.
     */
    undoLineStep() {
        const vertexRemoved = this.vertices.pop();
        vertexRemoved.remove();
        this.feature.geometry.coordinates.pop();
        this.feature.geometry.coordinates[this.feature.geometry.coordinates.length - 1] = this.feature.geometry.coordinates[this.feature.geometry.coordinates.length - 2];
        if (this.feature.geometry.coordinates.length === 1) {
            this.feature.geometry.coordinates.pop();
            this.source._data.features.pop();
        }
        if (this.vertices.length === MIN_VERTEX_COUNT_LINE) {
            this.firstVertex._element.classList.remove('click-to-close');
        }
        this.source.setData(this.source._data);
    }

    /**
     * Undoes a step that changed/added/deleted a vertex while the shape was a polygon.
     */
    undoPolygonStep(step) {
        this.removePopup();
        const coords = step.coords;

        // Check if our total number of vertices changed:
        let vertexCountChange = this.vertices.length - coords[BOUNDARY_COORDS].length + 1; // +1 for offest of final polygon coord

        // In the case of undoing a deleted vertex:
        while (vertexCountChange < 0) {
            const vertex = this.makeVertex([0, 0]).on('dragend', () => {
                if (this.onVertexChanged) {
                    this.onVertexChanged(this.feature);
                }
            });
            this.updateFeatureOnVertexDrag(vertex);
            vertexCountChange++;
        }
        // In the case of undoing an added vertex:
        while (vertexCountChange > 0) {
            const vertexRemoved = this.vertices.pop();
            vertexRemoved.remove();
            vertexCountChange--;
        }

        // Once we have the correct number of vertices, sync the coords:
        this.feature.geometry.coordinates = [...coords];
        this.source.setData(this.source._data);
        for (let i = 0; i < this.vertices.length; i++) {
            this.vertices[i].setLngLat(this.feature.geometry.coordinates[BOUNDARY_COORDS][i]);
            this.vertices[i].index = i;
        }
    }

    /**
     * Creates a line string on a temporary source and layer.
     */
    createLineStringLayer() {
        // Start with just a LineString layer instead of a Polygon
        this.map.addSource(CROP_TEMP_SOURCE_ID, {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            }
        });
        this.source = this.map.getSource(CROP_TEMP_SOURCE_ID);
        this.map.addLayer({
            id: CROP_LINE_LAYER_ID,
            source: CROP_TEMP_SOURCE_ID,
            type: 'line',
            paint: {
                'line-width': 3,
                'line-color': '#70bfbf',
                'line-opacity': 1
            }
        });
    }

    /**
     * Creates a new polygon on a temporary source and layer.
     */
    createPolygonLayer() {
        // Create the polygon layer once the closing vertex is created.
        // The polygon and line will share the same data source.
        this.map.addLayer({
            id: CROP_FILL_LAYER_ID,
            source: CROP_TEMP_SOURCE_ID,
            type: 'fill',
            paint: {
                'fill-color': '#000',
                'fill-opacity': 0.5
            }
        }, CROP_LINE_LAYER_ID);
    }

    /**
     * Creates and returns a new polygon feature with the coords passed.
     */
    createPolygonFeature(coordinates) {
        const id = this.randomId();
        const feature = {
            type: 'Feature',
            id,
            geometry: {
                type: 'Polygon',
                coordinates: [[...this.invertedBounds], [...coordinates]]
            },
            properties: {_id: id}
        };

        return feature;
    }

    /**
     * Adds a vertex to the feature.
     */
    _addVertex(e, index) {
        const lngLat = e.lngLat.toArray();
        const vertex = this.makeVertex(lngLat, index).on('dragend', () => {
            if (this.onVertexChanged) {
                this.onVertexChanged(this.feature);
            }
        });
        this.updateFeatureOnVertexDrag(vertex);
        this.getCoordinates().push(lngLat);

        if (vertex.index === 0) {
            this.getCoordinates().push(lngLat);
            this.source._data.features.push(this.feature);
            this.map.on('mousemove', this.onMouseMove);
        } else if (vertex.index === MIN_VERTEX_COUNT_LINE) {
            this.setUpFirstVertex();
        }

        if (this.onVertexAdded) {
            this.onVertexAdded(this.feature);
        }

    }

    /**
     * Runs when the final vertex is selected to change our linestring to a polygon.
     */
    lastVertexClick(e) {
        e.stopPropagation();
        const coordinates = this.getCoordinates();

        // Remove line segment that follows the mouse cursor
        if (coordinates.length > this.vertices.length) {
            coordinates.pop();
        }
        coordinates.push(coordinates[0]);
        this.lineFeature = this.feature; // Store this in case of undo

        // Change from line to polygon:
        this.createPolygonLayer();
        this.polygonFeature = this.createPolygonFeature(coordinates);
        this.source._data.features[0] = this.feature = this.polygonFeature;
        this.removeEventListeners();
        this.removeVertices();
        this.firstVertex._element.onmousedown = null;
        this.firstVertex._element.classList.remove('click-to-close');

        this.render();
        this.edit(this.feature);
        this.saveStep();
    }

    /**
     * Saves a step on feature edit (unless it's already been saved via onVertexChanged/onVertexAdded).
     */
    editFeature() {
        super.editFeature();
        const lastStep = this.steps.peek();
        lastStep.coords && this.vertices.length === lastStep.coords[BOUNDARY_COORDS].length ? this.saveStep() : null;
        this.removePopup();
    }

    /**
     * After the polygon is completed, enable editing.
     */
    edit(feature) {
        this.type = 'Polygon';
        this.lastVertexOffset = 2;
        this.minVertexCount = MIN_VERTEX_COUNT_POLY;

        this.getCoordinates = includingLast => {
            if (this.type === 'LineString') {
                return this.feature.geometry.coordinates;
            }
            return includingLast ? this.feature.geometry.coordinates[BOUNDARY_COORDS] : this.feature.geometry.coordinates[BOUNDARY_COORDS].slice(0, -1);
        };

        return Draw.prototype.edit.call(this, feature);
    }

    /**
     * Assigns the first vertex, which, upon clicking, will complete the polygon.
     */
    setUpFirstVertex() {
        this.firstVertex = this.vertices[0];
        this.firstVertex._element.classList.add('click-to-close');
        this.firstVertex._element.onmousedown = this.lastVertexClick.bind(this);
    }

    /**
     * Sync the vertex coordinates with the user interaction.
     */
    syncVertexCoordinates(vertex) {
        const coordinates = this.isNewFeature ? this.getCoordinates() : this.feature.geometry.coordinates[BOUNDARY_COORDS];
        coordinates[vertex.index] = vertex.getLngLat().toArray();
        if (vertex.index === 0) {
            coordinates[coordinates.length - 1] = coordinates[0];
        }
    }

    /**
     * Remove a vertex.
     */
    removeVertex(vertex) {
        const coordinates = this.feature.geometry.coordinates[1];
        coordinates.splice(vertex.index, 1);
        if (vertex.index === 0) {
            coordinates.pop();
            coordinates.push(coordinates[0]);
        }
    }

    freeze() {
        this.removeEventListeners();
        this.vertices.forEach(vertex => vertex.setDraggable());
    }

    /**
     * Save the step (for undoing) upon vertex added.
     */
    onVertexAdded() {
        this.saveStep();
    }

    /**
     * Save the step (for undoing) upon vertex changed.
     */
    onVertexChanged() {
        this.saveStep();
    }

    /**
     * Render the current source data.
     */
    render() {
        if (this.getCoordinates().length > 1) {
            this.source.setData(this.source._data);
        }
    }

    /**
     * Collect the final polygon coordinates for cropping (not including the inversion coordinates).
     */
    getFinalBoundary() {
        return lngLatsToLatLngs(this.feature.geometry.coordinates[BOUNDARY_COORDS].slice(0, -1));
    }

    /**
     * Tear down resources that were created for drawing.
     */
    remove() {
        this.removeEventListeners();
        this.removeVertices();
        if (this.map.getLayer(CROP_FILL_LAYER_ID)) {
            this.map.removeLayer(CROP_FILL_LAYER_ID);
        }
        if (this.map.getLayer(CROP_LINE_LAYER_ID)) {
            this.map.removeLayer(CROP_LINE_LAYER_ID);
        }
        if (this.map.getSource(CROP_TEMP_SOURCE_ID)) {
            this.map.removeSource(CROP_TEMP_SOURCE_ID);
        }
    }

    /**
     * Calculate and return the inversion coordinates for our map's bounds.
     */
    calcPolygonInversionCoords(maxBounds) {
        // Given max bounds for a map, calculated the coordinates required
        // for use in the inversion coordinate array.
        const nw = maxBounds.getNorthWest().toArray();
        return [nw,
            maxBounds.getNorthEast().toArray(),
            maxBounds.getSouthEast().toArray(),
            maxBounds.getSouthWest().toArray(),
            nw];
    }


}

export default CropTool;
