import tableModel from 'models/table/table-model';
import Radio from 'views/radio/radio';
import tablePopupModel from 'models/popover-model';
import boundaryMenuModel from 'models/boundary-menu-model';
import filterConstants from 'constants/models/table/filter-constants';
import store from '../../util/data/store';
import PlaceSearch from 'views/place-search/place-search';
import placeBoundaryFilterModel from 'models/place-boundary-filter-model';

const {mapFilterOpts} = filterConstants;

const BoundaryMenu = {
    oncreate() {
        if (tableModel.projectView.common.selectedBoundaryIndex === mapFilterOpts.IN_VIEW) {
            boundaryMenuModel.addMapMoveHandler();
        }
    },

    onremove() {
        if (tableModel.projectView.common.selectedBoundaryIndex === mapFilterOpts.TAGGED_PLACE && placeBoundaryFilterModel.selectedItems.length === 0) {
            boundaryMenuModel.selectBoundary(0);
            tableModel.projectView.common.selectBoundaryRadio(0);
        }
        boundaryMenuModel.removeMapMoveHandler();
    },

    inView: () => ({
        view: () => {
            return <>
                in viewport {
                    tableModel.projectView.common.selectedBoundaryIndex === mapFilterOpts.IN_VIEW
                    && boundaryMenuModel.hasMoved
                    &&
                    <a onclick={() => tableModel.fetch()} className="btn btn-update btn-smallest btn-secondary btn-pill"><span className="btn-label">update</span></a>}
            </>;
        }
    }),

    placesSearchArea: () => ({
        view: () => <>
            within a Place boundary {tableModel.projectView.common.selectedBoundaryIndex === mapFilterOpts.TAGGED_PLACE
            && <PlaceSearch
                selectedItems={placeBoundaryFilterModel.selectedItems || []}
                onSelect={(place) => placeBoundaryFilterModel.onSelectPlace(place)}
                onDelete={(place) => placeBoundaryFilterModel.onDeletePlace(place)}
                boundaryFilter={true}
                openUp={tableModel.tableMode === 'table-bottom'}
            />}
        </>
    }),

    drawSearchArea: () => ({
        view: () => <>
            draw boundary {tableModel.projectView.common.selectedBoundaryIndex === mapFilterOpts.DRAW_BOUNDARY
            && <div className="btn btn-smallest btn-secondary btn-pill">
                <span className="btn-label">
                redraw
                </span>
            </div>}
        </>
    }),

    options: () => {
        const InView = BoundaryMenu.inView;
        if (Object.values(store.places).length) {
            return ['any', <InView/>, BoundaryMenu.drawSearchArea, 'mapped', 'unmapped', BoundaryMenu.placesSearchArea];
        }
        return ['any', <InView/>, BoundaryMenu.drawSearchArea, 'mapped', 'unmapped'];
    },

    view: () => {
        const options = BoundaryMenu.options(boundaryMenuModel);
        return <div class="filter-menu-inner places-filter boundary-menu">
            <div className="boundary-menu-header" onclick={() => tablePopupModel.close()}>
                <div className="boundary-menu-tab">
                    <span className="boundary-menu-tab-btn"><i className='icon-map-square'/></span>
                    <span className="boundary-menu-tab-label">Map Filter</span>
                </div>
            </div>
            <div class="filter-contents">
                <label class="boundary-tip">Map status/location is:</label>
                <Radio
                    defaultOption={options[tableModel.projectView.common.selectedBoundaryIndex]}
                    options={options}
                    onchange={(value, i) => {
                        boundaryMenuModel.selectBoundary(i);
                        tableModel.projectView.common.selectBoundaryRadio(i);
                    }}
                />
            </div>
        </div>;
    }
};

export default BoundaryMenu;
