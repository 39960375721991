import router from 'uav-router';
import MapModel from 'models/map-model';
import appModel from 'models/app-model';
import planModel from 'models/plan-model';
import siteModel from 'models/site-model';
import store from 'util/data/store';
import dialogModel from 'models/dialog-model';
import { LngLatBounds } from 'mapbox-gl';
import message from 'views/toast-message/toast-message';
import StakeList from 'components/stake/stake-list';
import LayerControl from 'views/layer-control';
import onBodyClick from 'legacy/util/dom/on-body-click';
import layerModel from 'models/layer-model';
import isMetric from 'util/numbers/is-metric';
import padBounds from 'util/geo/pad-bounds';
import restrictions from 'util/permissions/restriction-message';

const MIN_STAKES_REQ_TO_SAVE = 3;
const MIN_STEPS_REQ_TO_UNDO = 2;
const MIN_STAKES_REQ_TO_CLEAR = 1;

/**
 * Handles all logic and state for stakeable views
 * (e.g., survey detail and plan detail)
 */
class StakeableModel {

    constructor() {
        this.target = null; // Left side map
        this.origin = null; // Right side map

        this.recordTitle = null;
        this.recordType = null;
        this.isMetric = isMetric();

        this.stakes = null;
        this.stakesMenuList = [];

        this.state = {
            isLoading: true,
            isOutOfBounds: false,
            minStakesReached: false,
            isEditingTitle: false,
            isUndoable: false,
            isSaving: false,
            isClearable: false,
            isTranslating: false,
            isScaling: false
        };
    }

    toggleTranslate() {
        this.state.isTranslating = !this.state.isTranslating;
        this.state.isScaling = false;
        m.redraw();
    }

    async init(opts) {
        this.recordId = opts.recordId;
        this.storeKey = opts.storeKey; // e.g. 'surveys'
        this.recordType = opts.recordType; // e.g. 'survey'
        this.withElevation = opts.withElevation;
        this.header = opts.header;
        this.stakesMenu = new LayerControl(
            () => this.toggleStakesMenu(),
            'pins-control',
            'icon-marker2',
            true
        );
        await appModel.waitUntilInit();
        const record = store[this.storeKey][this.recordId];
        if (record && !appModel.user.permissions.canEditRecord(record)) {
            const messageText = restrictions.message(appModel.user, 'edit', this.recordType);
            stakeableModel.exit();
            return message.show(messageText, 'warning permission shield');
        }
        this.target = siteModel.mapModel;
        // Make sure layer menu is closed in case it was open on prior screen.
        layerModel.isOpen = false;
        layerModel.layerControl._container.classList.remove('active');
        this.origin = new MapModel({ container: 'mapboxRight' }, {
            layercontrolOff: true,
            basemapOff: true,
            geolocateOff: true
        });
        layerModel.focusOnAssetFeatures();
        this.awaitClicks({ lightOriginBg: opts.lightOriginBg });

        m.redraw();
    }

    // ------------------------- Loading saved stakes --------------------------

    /*
     * Preload stakes from store (eg. store.survey.surveyId.stakes), if any exist.
     * Bounds need to be updated to ensure survey and stakes are entirely in viewport to begin.
     * If no stakes exist, add one pair of starter stakes and message re: how to pin.
     */
    preloadSavedStakes(initialBounds) {
        const data = store[this.storeKey][this.recordId].stakes;

        // Set stakes from store:
        if (data && data.length > 1) {
            this.stakes = new StakeList();
            this.stakes.initStakes(data);

            // If no stakes, add four initial pairs in the corners.
        } else {
            this.stakes = new StakeList();
            const bounds = this.origin.tilesetBounds;

            const nwOrigin = bounds.getNorthWest();
            const nwOriginPoint = this.origin.project(nwOrigin);
            const nwTargetCoords = this.target.unproject(nwOriginPoint);

            const neOrigin = bounds.getNorthEast();
            const neOriginPoint = this.origin.project(neOrigin);
            const neTargetCoords = this.target.unproject(neOriginPoint);

            const seOrigin = bounds.getSouthEast();
            const seOriginPoint = this.origin.project(seOrigin);
            const seTargetCoords = this.target.unproject(seOriginPoint);

            const swOrigin = bounds.getSouthWest();
            const swOriginPoint = this.origin.project(swOrigin);
            const swTargetCoords = this.target.unproject(swOriginPoint);

            const initialCoords = [
                this.coordsToStakeApi(nwTargetCoords, nwOrigin),
                this.coordsToStakeApi(neTargetCoords, neOrigin),
                this.coordsToStakeApi(seTargetCoords, seOrigin),
                this.coordsToStakeApi(swTargetCoords, swOrigin)
            ];

            this.stakes.initStakes(initialCoords);
            this.stakes.saveStep();
            this.updateButtonStates();
        }

        this.setInitialBounds(initialBounds);
        this.loadStakesMenu();
    }

    /**
     * Sets up initial bounds for staking based on stakes, asset, and site bounds.
     * Right side map is restricted to padded bound area of asset.
     */
    setInitialBounds(initialBounds) {
        this.boundTargetByStakes(initialBounds);
        this.target.paddedBounds = padBounds(this.target, siteModel.bounds, 120);
        this.origin.paddedBounds = padBounds(this.origin, this.origin.getBounds(), 400);
        this.origin.setMaxBounds(this.origin.paddedBounds);
    }

    /**
     * Collects stakes from other assets and loads them into the menu on the origin map.
     */
    loadStakesMenu() {
        this.collectAllStakes();
        this.stakesMenu.isOpen = false;
        // Only add the control if there are other surveys to pull pins from.
        if (this.stakesMenuList.length > 0) {
            this.origin.addControl(this.stakesMenu, 'bottom-right');
        }
    }

    /**
     * Given an index from the stake menu list options, loads the pins from that dataset and adds them to the current asset.
     */
    addStakesFromMenuList(index) {
        const dataToUse = this.stakesMenuList[index].stakeData;
        this.stakes.bulkAddPairs(dataToUse);
        const title = this.stakesMenuList[index].title;
        m.redraw();
        message.show(`Pins added from ${title}.`, 'success');
    }

    /**
     * Runs through all the surveys available on this site and loads the stakes into the stakeMenuList.
     */
    collectAllStakes() {
        const listOfAssets = store[this.storeKey];
        for (const id in listOfAssets) {
            if (id !== this.recordId) {  // Don't add the current survey as an option.
                const stakes = listOfAssets[id].stakes;
                stakes ? this.stakesMenuList.push({
                    id: id,
                    title: listOfAssets[id].title,
                    stakeData: stakes
                }) : null;
            }
        }
    }

    /**
     * Shows the stake menu control on click and hides on body click.
     */
    toggleStakesMenu() {
        const stakesMenu = this.stakesMenu;
        stakesMenu.isOpen = !stakesMenu.isOpen;
        if (stakesMenu.isOpen) {
            onBodyClick.once(() => {
                stakesMenu.isOpen = false;
                stakesMenu._container.classList.remove('active');
                m.redraw();
            });
        }
        m.redraw();
    }

    // ------------------------- Repositioning map --------------------------

    /**
     * Starting with the original siteModel bounds, runs through stakes on the target (left) map
     * and extends the bounds to include each. Fits the target map to the new bounds, but keeps
     * the siteModel bounds object variable unchanged.
     */
    boundTargetByStakes(initialBounds) {
        this.state.isOutOfBounds = false;
        m.redraw();

        const siteBounds = initialBounds ? initialBounds : new LngLatBounds(siteModel.bounds.getSouthWest(), siteModel.bounds.getNorthEast());

        this.stakes.stakes.forEach((pair) => {
            siteBounds.extend(pair.targetStake.getLngLat());
        });

        this.target.fitBounds(siteBounds, { padding: { top: 20, bottom: 20, left: 20, right: 20 } });
        this.target.paddedBounds = padBounds(this.target, siteModel.bounds, 120);
    }

    boundOriginByStakes(initialBounds) {
        const bounds = initialBounds ? initialBounds : this.origin.getBounds();

        this.stakes.stakes.forEach((pair) => {
            bounds.extend(pair.originStake.getLngLat());
        });
        this.origin.fitBounds(bounds, {
            animate: false,
            padding: { top: 20, bottom: 20, left: 20, right: 20 }
        });
        this.origin.paddedBounds = padBounds(this.origin, bounds, 400);
        this.origin.setMaxBounds(this.origin.paddedBounds);

    }

    /**
     * Checks the current target (left) map bounds against the padded bounds, and sets the
     * out of bounds state and redraws as needed.
     */
    checkSiteBounds() {
        const originalState = this.state.isOutOfBounds;
        if (this.target.paddedBounds.contains(this.target.getCenter())) {
            this.state.isOutOfBounds = false;
        } else {
            this.state.isOutOfBounds = true;
        }
        // Only redraw if there was actually a state change.
        originalState !== this.state.isOutOfBounds ? m.redraw() : null;
    }

    // ------------------------- Click Handlers --------------------------

    /**
     * Sets event listeners on both maps to generate stakes on click and magnifier glass on drag.
     *
     * New stakes are added to map at point of click; partner stake is projected
     * to the equivalent point in the viewport of the alternate map.
     */
    awaitClicks(opts) {
        this.target.on('click', (e) => {
            this.respondToClick(e.lngLat, this.origin.unproject(e.point));
        });
        this.origin.on('click', (e) => {
            this.respondToClick(this.target.unproject(e.point), e.lngLat);
        });
        this.target.addMagnifier();
        this.origin.addMagnifier(opts.lightOriginBg);
        this.target.on('dragend', () => this.checkSiteBounds());
        this.target.on('dragend', () => this.checkSiteBounds());
    }

    /**
     * Clicking the map will either:
     *  1) Create a new stake pair at that coordinate, or
     *  2) De-activate (ie., de-highlight) the currently active stake, or
     *  3) Close the map menus if either are open, or
     *  4) Zoom in (if double-clicked)
     */
    respondToClick(targetCoords, originCoords) {
        // If a menu or the starter message is open, quit early.
        if (this.mapMenuOpen() || this.state.starterMessageOpen) {
            return;
        }
        // If there is a double click: Do not add a new stake, just zoom.
        // (Timeout checks for doubleclick event).
        if (!this.clicking) {
            this.clicking = true;
            setTimeout(() => {
                if (this.clicking && !this.stakes.activePair) {
                    this.addStakePair(targetCoords, originCoords);
                } else {
                    this.stakes.deactivatePair(this.stakes.activePair);
                }
                this.clicking = false;
            }, 500);
        } else {
            this.clicking = false;
        }

    }

    /**
     * Checks if either of the map (left or right) menus are open, returns true if so.
     */
    mapMenuOpen() {
        return layerModel.isOpen || this.stakesMenu.isOpen;
    }

    // ------------------------- Popups and Messaging --------------------------

    /*
     * Adds an info popup to the (starter) stake passed for assets that are not yet staked.
     * Callback included to hide the starter message after a timeout.
     */
    addMessageToStarterStake() {
        const starterStakePair = this.stakes.stakes[0];
        this.stakes.deactivatePair(starterStakePair);
        this.state.starterMessageOpen = true;
        this.showPopupMessage('how-to-pin-popup starter-popup', false, () => this.endStarterMessage());
        onBodyClick.once(() => this.hideStarterMessage());
    }

    /**
     * Opens the popup message about how to pin.
     */
    showPopupMessage(classToAdd, shouldPersist, callback) {
        const persist = shouldPersist ? shouldPersist : null;
        const cbFunction = callback ? callback : null;
        message.show(<div class="popup-wrap">
            <span class="small bold">Editing Match Points</span>
            <span>Tap and drag to move a Match Point. Tap anywhere to add a pair of Match Points.</span>
            <span class="x-small">A minimum of 3 Match Point pairs is required. Add more Match Points to improve alignment.</span>
            <span>For best result, avoid placing Match Points in a straight line.</span>
        </div>, classToAdd, persist, cbFunction);
    }

    /**
     * Activates the starter stake and calls method to hide the starter message popup.
     */
    endStarterMessage() {
        if (this.state.starterMessageOpen) {
            const starterStakePair = this.stakes.stakes[0];
            !this.stakes.activePair ? this.stakes.activatePair(starterStakePair) : null;
            this.hideStarterMessage();
        }
    }

    /**
     * Hides the starter message popup.
     */
    hideStarterMessage() {
        const starterPopup = document.getElementsByClassName('starter-popup')[0];
        starterPopup ? starterPopup.classList.remove('active') : null;
        this.state.starterMessageOpen = false;
    }

    /*
     * Generates dialog box confirming user wants to quit. If yes, continues routing back to site.
     * Only included if there are (unsaved) changes to the asset.
     */
    close() {
        //  Only display close warning if there are unsaved changes (ie., if it's undoable.)
        if (this.state.isUndoable) {
            dialogModel.open({
                headline: 'Quit and discard changes?',
                text: 'Please note that this operation cannot be undone.',
                yesClass: 'btn btn-pill btn-red',
                noText: 'Cancel',
                noClass: 'btn btn-pill btn-secondary',
                onYes: () => {
                    if (stakeableModel.recordType === 'Plan') {
                        planModel.exitFromStaking();
                    } else {
                        stakeableModel.exit();
                    }
                }
            });
        } else {
            if (stakeableModel.recordType === 'Plan') {
                planModel.exitFromStaking();
            } else {
                stakeableModel.exit();
            }
        }
    }

    /**
     * If there are current pins, calls method to confirm if they should be cleared, if there are none,
     * proceeds to add stakes as selected. Called from stakeable.js on pin menu selection.
     */
    handleAddingPreviousPins(index) {
        if (this.state.isClearable) {
            this.confirmClearOrMerge(index);
        } else {
            this.addStakesFromMenuList(index);
        }
    }

    /**
     * Opens dialog box checking if user wants to clear pins before adding new pins and completes the request.
     */
    confirmClearOrMerge(index) {
        dialogModel.open({
            headline: 'Replace or merge Match Points?',
            text: 'Replace the currently placed Match Points or merge Match Points.',
            cssClass: 'dialog-with-close',
            quitClass: 'icon-close',
            yesClass: 'btn btn-pill btn-secondary btn-merge-hover',
            yesText: 'Merge',
            noText: 'Replace',
            noClass: 'btn btn-pill btn-secondary',
            onNo: () => {
                this.stakes.removeAll(); // Remove all without saving the state, so deleting + adding = 1 step.
                this.addStakesFromMenuList(index);
            },
            onYes: () => {
                this.addStakesFromMenuList(index);
            }
        });
    }

    /**
     * Runs in case asset changes are processed while user has current asset open.
     * Called from LayerModel publish method.
     */
    handleUpdatedAsset() {
        dialogModel.open({
            headline: 'Your recent changes have finished processing.',
            text: 'Would you like to reload the updated ' + this.recordType + '?',
            yesClass: 'btn btn-pill btn-primary',
            noText: 'No',
            noClass: 'btn btn-pill btn-red',
            onYes: () => {
                this.stakes.bulkDeletePairs();
                this.preloadSavedStakes();
                this.origin.showSurveyForStaking(this.recordId);
            }
        });
    }

    /**
     * Runs upon clicking the "Done" button. After notification modal displays,
     * executes savePins callback from view (survey or plans)
     */
    handleDone() {
        if (!this.state.isUndoable) {
            // No changes to save
            this.savePins();
        } else {
            const data = this.stakes.getStakeData();
            dialogModel.open({
                headline: 'Ready to save?',
                text: 'Please note that your changes may not be immediately visible while we generate the updated Map Layer.',
                yesText: 'Save',
                yesClass: 'btn btn-pill btn-primary',
                noText: 'Cancel',
                noClass: 'btn btn-pill btn-red',
                onYes: () => {
                    this.savePins(data);
                }
            });
        }
    }


    // ------------------------- Event Handler Callbacks --------------------------

    /**
     * Event handler for undo button: Calls undoStep on StakeList.
     */
    undo() {
        this.stakes.undoStep();
    }

    /**
     * Event handler for delete button: Calls deletePair on StakeList.
     */
    deleteStakePair(pairToDelete) {
        this.stakes.deletePair(pairToDelete);
    }

    /**
     * Event handler for clear button: Calls bulkDeletePairs on StakeList.
     */
    clearAll() {
        this.stakes.bulkDeletePairs();
        this.stakes.activePair = null;
    }

    /**
     * Event handler for dragging a stake or updating lat/lng input menu: Calls updatePair on StakeList.
     */
    updateStakePair(pair) {
        this.stakes.updatePairData(pair);
        const originalState = this.state.isOutOfBounds;

        if (!siteModel.bounds.contains(pair.targetStake.getLngLat())) {
            this.state.isOutOfBounds = true;
            originalState !== this.state.isOutOfBounds ? m.redraw() : null;
        }
    }

    /**
     * Event handler for adding a new stake pair: Calls addPair on StakeList.
     */
    addStakePair(targetCoords, originCoords) {
        const formattedPair = this.coordsToStakeApi(targetCoords, originCoords);
        this.stakes.addPair(formattedPair);
    }

    exit() {
        // Leave staking flow and return to init
        siteModel.mapModel = undefined;
        router.set({ projectId: router.params.projectId, siteId: router.params.siteId });
    }

    // ------------------------- Checks for updates needed for view/css --------------------------


    /**
     * If there is an activePair, adds the active-pair class to both maps. If not removes it.
     */
    updateActivePairClass() {
        if (this.stakes.activePair) {
            this.target.getContainer().classList.add('active-pair');
            this.origin.getContainer().classList.add('active-pair');
        } else {
            this.target.getContainer().classList.remove('active-pair');
            this.origin.getContainer().classList.remove('active-pair');
        }
    }

    /*
     * Calls for buttons to update, and if any were updated, triggers redraw.
     */
    updateButtonStates() {
        const nextUpdated = this.nextButtonUpdated();
        const undoUpdated = this.undoButtonUpdated();
        const clearUpdated = this.clearButtonUpdated();
        if (nextUpdated || undoUpdated || clearUpdated) {
            m.redraw();
        }
    }

    /*
     * Checks if the next button requires a state change, and if so, changes it.
     * Returns boolean true if button was updated, false otherwise.
     */
    nextButtonUpdated() {
        // If it's disabled and it shouldn't be:
        if (!this.state.minStakesReached && this.stakes.stakes.length >= MIN_STAKES_REQ_TO_SAVE) {
            this.state.minStakesReached = true;
            return true;
            // If it's enabled and it shouldn't be:
        } else if (this.state.minStakesReached && this.stakes.stakes.length < MIN_STAKES_REQ_TO_SAVE) {
            this.state.minStakesReached = false;
            return true;
        }
        return false;
    }

    /*
     * Checks if the undo button requires a state change, and if so, changes it.
     * Returns boolean true if button was updated, false otherwise.
     */
    undoButtonUpdated() {
        // If it's disabled and it shouldn't be:
        if (!this.state.isUndoable && this.stakes.steps.items.length >= MIN_STEPS_REQ_TO_UNDO) {
            this.state.isUndoable = true;
            return true;
            // If it's enabled and it shouldn't be:
        } else if (this.state.isUndoable && this.stakes.steps.items.length < MIN_STEPS_REQ_TO_UNDO) {
            this.state.isUndoable = false;
            return true;
        }
        return false;
    }

    /*
     * Checks if the clear button requires a state change, and if so, changes it.
     * Returns boolean true if button was updated, false otherwise.
     */
    clearButtonUpdated() {
        // If it's disabled and it shouldn't be:
        if (!this.state.isClearable && this.stakes.stakes.length >= MIN_STAKES_REQ_TO_CLEAR) {
            this.state.isClearable = true;
            return true;
            // If it's enabled and it shouldn't be:
        } else if (this.state.isClearable && this.stakes.stakes.length < MIN_STAKES_REQ_TO_CLEAR) {
            this.state.isClearable = false;
            return true;
        }
        return false;
    }

    // ------------------------- Helpers --------------------------

    /**
     * Formats coordinates according to API.
     * (Swaps lat and lng and assigns to object with appropriate keys).
     */
    coordsToStakeApi(targetCoords, originCoords, elevation) {
        const targetData = elevation ?
            [targetCoords.lat, targetCoords.lng, elevation] :
            [targetCoords.lat, targetCoords.lng];

        return {
            origin: [originCoords.lat, originCoords.lng],
            target: targetData
        };
    }


}

const stakeableModel = new StakeableModel();

export default stakeableModel;

