
const ToggleColorSwitch = {

    view: ({attrs: {tileset, toggleFn, hideText, clickElementClass}}) => {
        return <div class="plan-details-header toggle-color-row">
            {hideText ? '' : <span class="btn-label">Plan Color</span>}
            <span class="toggle-text-wrap">
                <i class={`toggle-switch ${tileset.color !== 'None' ? 'toggle-is-on' : 'toggle-is-off'} ${clickElementClass || ''}`} onclick={() => {
                    toggleFn(tileset.color, tileset.tilesetId);
                }}/>
                {hideText ? '' : tileset.color !== 'None' ? <span>On</span> : <span>Off</span>}
            </span>
        </div>;
    }

};

export default ToggleColorSwitch;
