import FilepickerInterface from 'util/interfaces/filepicker';
import SymbolInterface from 'util/interfaces/symbol';
import PlanInterface from 'util/interfaces/plan';
import PolygonInterface from 'util/interfaces/polygon';
import PolylineInterface from 'util/interfaces/polyline';
import SurveyInterface from 'util/interfaces/survey';
import TextInterface from 'util/interfaces/text';
import ProjectInterface from 'util/interfaces/project';
import ActionInterface from 'util/interfaces/action';
import IModelInterface from 'util/interfaces/imodel';
import appModel from 'models/app-model';

function getToolInterface(tool, assetId, featureTypeId) {
    let featureType;
  
    if (featureTypeId) {
        featureType = appModel.toolbox.featureTypes[featureTypeId] || tool.featureTypes.find(f => f.featureTypeId === featureTypeId);
    } else {
        featureType = tool.featureTypes[0];
    }

    const Interface = {
        action: ActionInterface,
        filepicker: FilepickerInterface,
        symbol: SymbolInterface,
        plan: PlanInterface,
        polygon: PolygonInterface,
        polyline: PolylineInterface,
        survey: SurveyInterface,
        text: TextInterface,
        project: ProjectInterface,
        imodel: IModelInterface
    }[featureType.attributes.interface];

    return new Interface(tool, assetId, featureTypeId);

}

export default getToolInterface;
