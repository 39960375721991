import batchSelectModel from 'models/batch-select-model';

const AssetSelection = {
    view: ({attrs: {isSelected, assetId}}) => {
        const icon = isSelected ? 'icon-checkbox-on' : 'icon-checkbox-off';

        return <i class={icon} onclick={(e) => {
            e.stopPropagation();
            if (isSelected) {
                batchSelectModel.deselectSingleAsset(assetId);
            } else {
                batchSelectModel.selectSingleAsset(assetId);
            }
        }}/>;
    }
};
export default AssetSelection;
