import FilterTypeModel from 'models/table/filter-types/filter-type-model';
import findAndRemove from 'util/data/find-and-remove';
import store from 'util/data/store';
import placeModel from 'models/place-model';
import {combinedPlacesFilterType} from 'models/table/filter-types/helpers';

class PlacesFilterModel extends FilterTypeModel {

    init() {
        super.init({
            type: 'placesFilter',
            specialProperties: [
                'selectedRadioIndex',
                'selectedItems'
            ]
        });
    }

    defaultValueForProperty(property) {
        if (property === 'selectedItems') {
            return [];
        }
        return super.defaultValueForProperty(property);
    }

    onSelectPlace(place) {
        this.state.selectedItems.push(place.placeId);
        this.onFilterUpdate();
        placeModel.panToPlaces(this.state.selectedItems);
    }

    onDeletePlace(place) {
        this.state.selectedItems = findAndRemove(this.state.selectedItems, (placeInList) => {
            return placeInList === place.placeId;
        });
        this.onFilterUpdate();
        m.redraw();
        if (this.state.selectedItems.length) {
            placeModel.showPlacePopups(this.state.selectedItems);
        }
    }

    turnOffAllExcept(placeId) {
        this.selectRadio(2);
        this.state.selectedItems = [placeId];
        this.onFilterUpdate();
        placeModel.panToPlace(store.places[placeId]);
    }

    selectRadio(i) {
        const firstClick = this.state.selectedRadioIndex !== i;
        super.selectRadio(i);
        if (firstClick
            && i === 2
            && this.state.selectedItems.length > 0) {
            const placeId = this.state.selectedItems[0];
            placeModel.panToPlace(store.places[placeId]);
        }
    }

    getDefaultRadioIndex() {
        return this.sharedColumn ? combinedPlacesFilterType(this.state, this.sharedColumn) : 0;
    }
}

export default new PlacesFilterModel();
