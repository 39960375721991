import {
    query,
    GET_USER,
    LIST_PROJECT_TOOLBOXES,
    ADD_PROJECT_USER,
    REMOVE_PROJECT_USER,
    MODIFY_ACCOUNT_USER,
    GET_USER_BY_ID_OR_EMAIL,
    GET_PROJECT_AND_ACCOUNT_NAMES_BY_PROJECT_ID,
    LIST_LAYER_FOLDERS,
    GET_ROOT_FOLDER,
    CREATE_LAYER_FOLDER,
    MODIFY_LAYER_FOLDER,
    DELETE_LAYER_FOLDER,
    MODIFY_LAYER_FOLDER_ZORDER,
    MODIFY_LAYER_FOLDER_NAME
} from 'util/graphql/queries';
import authManager from 'managers/auth-manager';
import {apiUrl} from 'util/data/env';
import message from 'views/toast-message/toast-message';
import logConstants from 'constants/managers/log-constants';
import {datadogRum} from '@datadog/browser-rum';

const FETCH_URL = `${apiUrl}/api/v2/v2.0`;
const SUCCESS_CODE = 200;
const AUTH_ERROR = 401;

const send = (_query, withRetry = true) => {
    const token = authManager.getToken();
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };

    return new Promise((resolve, reject) => {
        fetch(FETCH_URL, {
            method: 'post',
            headers,
            body: JSON.stringify(_query)
        }).then(async (response) => {
            if (withRetry && response.status === AUTH_ERROR) {
                // Only retry once
                return authManager.handleExpiredToken(() => send(_query, false));
            }
            if (response.status !== SUCCESS_CODE) {
                datadogRum.addError(new Error(logConstants.errorLogMessages.GRAPHQL_RESPONSE_STATUS_ERROR), JSON.stringify(_query));
                message.show(<span>Something went wrong. Consider <a onclick={() => location.reload()}>refreshing</a> or contact support if the problem continues.</span>, 'error');
                console.error(response.statusText || response);
                return reject();
            }
            return response.text().then((responseBody) => {
                const responseMessage = JSON.parse(responseBody) || responseBody;
                if (responseMessage.errors) {
                    datadogRum.addError(new Error(logConstants.errorLogMessages.GRAPHQL_RESPONSE_ERROR), JSON.stringify(_query));
                    console.error(responseMessage.errors);
                    message.show(<span>Something went wrong. Please try again or contact support if the problem continues.</span>, 'error');
                    return reject();
                }
                return resolve(responseMessage);
            });
        }).catch((e) => {
            console.error(e);
            datadogRum.addError(new Error(logConstants.errorLogMessages.GRAPHQL_FETCH_FAILURES), JSON.stringify(_query));
            message.show(<span>Something went wrong. Consider <a onclick={() => location.reload()}>refreshing</a> or contact support if the problem continues.</span>, 'error');
            return reject(e);
        });
    });
};

export const gqlResponse = (graphqlResponse) => graphqlResponse && graphqlResponse.data ? graphqlResponse.data : {};

const graphql = {
    getUser: (userId) => send(query(GET_USER, {userId})),
    getRootFolder: (projectId) => send(query(GET_ROOT_FOLDER, {projectId})),
    listProjectToolboxes: () => send(query(LIST_PROJECT_TOOLBOXES)),
    listLayerFolders: (projectId, parentId) => send(query(LIST_LAYER_FOLDERS, {projectId, parentId})),
    createLayerFolder: (folderName, projectId, parentFolderId) => send(query(CREATE_LAYER_FOLDER, {folderName, projectId, parentFolderId})),
    modifyLayerFolder: (layerFolderId, folderName, parentFolderId, zOrder) => send(query(MODIFY_LAYER_FOLDER, {layerFolderId, folderName, parentFolderId, zOrder})), 
    modifyLayerFolderZorder: (layerFolderId, zOrder) => send(query(MODIFY_LAYER_FOLDER_ZORDER, {layerFolderId, zOrder})), 
    modifyLayerFolderName: (layerFolderId, folderName) => send(query(MODIFY_LAYER_FOLDER_NAME, {layerFolderId, folderName})), 
    deleteLayerFolder: (layerFolderId) => send(query(DELETE_LAYER_FOLDER, {layerFolderId})),
    addProjectUser: (projectId, roleName, userId) => send(query(ADD_PROJECT_USER, {projectId, roleName, userId})),
    removeProjectUser: (projectId, userId) => send(query(REMOVE_PROJECT_USER, {projectId, userId})),
    modifyAccountUser: (emailAddress, roleName, accountId) => send(query(MODIFY_ACCOUNT_USER, {emailAddress, roleName, accountId})),
    [GET_PROJECT_AND_ACCOUNT_NAMES_BY_PROJECT_ID]: ({ projectId }) => send(query(GET_PROJECT_AND_ACCOUNT_NAMES_BY_PROJECT_ID, { projectId })),
    [GET_USER_BY_ID_OR_EMAIL]: ({ userIdOrEmailStr }) => send(query(GET_USER_BY_ID_OR_EMAIL, { userIdOrEmailStr }))
};

export default graphql;
