import {FolderOptionsEllipsisMenu} from 'views/folder/folder-options-menu';

const FolderHeader = {
    view: ({attrs: {folder}}) => {
        const cssClasses = `folder-header${folder.state.isEditing ? ' is-editing ' : ''}${folder.state.isSaving ? ' saving' : ''}`;
        const iconCssClass = `toggle-switch ${folder.state.isToggledOn ? 'toggle-is-on' : 'toggle-is-off'}`;

        return <div class={cssClasses} ondragover={(e) => folder.onDragOverHeader(e)}>
            <i class={iconCssClass} onclick={(e) => folder.toggleVisibility(e)}/>
            {folder.root.editingNameOn === folder.urn
                ? <div class="title-button-wrap"><div class="input-button-wrap">
                    <input value={folder.name} type="text" onkeydown={(e) => folder.handleNameInputKeyDown(e)} onclick={(e) => e.stopPropagation()} oninput={(e) => folder.handleNameInput(e.target.value)}/>
                    <span class="btn btn-primary btn-pill btn-smallest" onclick={() => folder.saveNameIfChanged()}><i class="spinner"/>Done</span> 
                </div>
                <span class="cancel-text-button" onclick={(e) => folder.stopEditingName(e)}>cancel</span>
                </div>
                : <div class="title-button-wrap">
                    <span class="folder-name" onclick={(e) => folder.root.menuOpenOn || folder.root.colorPickerOn ? folder.root.closePopups() : folder.toggleVisibility(e)}>{folder.name}</span>
                    <i class="icon-down icon" onclick={e => folder.toggleState('isExpanded', e)} />
                    <FolderOptionsEllipsisMenu folder={folder}/>
                </div> }
        </div>;
    }
};

export default FolderHeader;
