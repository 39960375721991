import store from 'util/data/store';
import controlToFeature from 'util/interfaces/control-to-feature';
import Multiselect from 'views/multiselect';

const updateAssetFeatures = (control, assetId) => controlToFeature.updateAssetFeatures(control, assetId);

export default function(control, assetId) {
    const asset = store.assets[assetId],
        field = store.assetTypeFields[asset.assetTypeId][control.fieldName];
    return <Multiselect defaultOptions={asset.properties[control.fieldName]} onchange={options => {
        store.assets[assetId].properties[control.fieldName] = options;
        updateAssetFeatures(control, assetId);
    }} options={field.type.items.values}/>;
}
