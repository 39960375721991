import randomId from 'util/numbers/random-id';
import debounce from 'util/events/debounce';
import api from 'legacy/util/api';
import appModel from 'models/app-model';
import router from 'uav-router';
import constants from 'util/data/constants';
import DrawPalette from 'views/draw-palette/draw-palette';
import siteModel from 'models/site-model';
import ToolLauncher from 'views/tool-launcher';
import sideNavModel from 'models/side-nav-model';
import formModel from 'models/form-model';
import store from 'util/data/store';
import featureToControl from 'util/interfaces/feature-to-control';
import AssetForm from 'views/asset-form';
import formatDate from 'legacy/util/date/format-date';
import Table from 'views/table/table';
import validate from 'util/geo/validate';
import popup from 'util/popup';
import {evaluateControl} from 'util/evaluate';
import assetListManager from 'managers/asset-list-manager';
import deepCloneObject from 'util/data/deep-clone-object';
import tableModel from 'models/table/table-model';
import layerModel from 'models/layer-model';
import importModel from 'models/import-model';
import FeatureModel from 'models/feature/feature-model';
import drawPaletteModel from 'models/draw-palette-model';

class ToolInterface {

    constructor(tool, assetId, featureTypeId) {
        featureTypeId = featureTypeId || tool.featureTypes[0].featureTypeId;

        this.tool = tool;
        this.assetId = assetId;
        this.featureType = appModel.toolbox.featureTypes[featureTypeId] || tool.featureTypes.find(f => f.featureTypeId === featureTypeId);
        this.linkedControls = this.featureType.attributes.linkedControls;

        appModel.toolbox.toolInterface = this;

        appModel.toolbox.tool = this.tool;

        sideNavModel.close();

        this.autosave = new Function();

        this.hasUnsavedChanges = false;

        let asset;

        if (assetId) {

            asset = store.assets[assetId];

            if (asset.featureIds && asset.featureIds.length) {

                this.autosave = (f) => {
                    this.hasUnsavedChanges = true;
                    debounce(() => {
                        if (validate(f.geometry)) {
                            api.rpc.request([['modifyFeature', Object.assign(api.apiSafeFeature(f, true))]]).then(() => {
                                this.hasUnsavedChanges = false;
                            });
                        }

                    }, 500);
                };

            }

        } else {
            asset = this.blankAsset(tool);
            this.assetId = asset.contentId;
        }

        const geometry = this.featureType.geometry || {type: 'Point'};
        const defaultProperties = this.featureType.getDefaultProperties ? this.featureType.getDefaultProperties() : this.featureType.properties;

        const initProperties = {
            type: 'Feature',
            id: randomId(),
            geometry: Object.assign({}, geometry, {
                coordinates: []
            }),
            properties: Object.assign({
                assetId: this.assetId,
                featureTypeId
            }, defaultProperties)
        };

        const feature = new FeatureModel(initProperties, 'drawTool');
        tool.assetForm.controls.forEach(control => feature.syncFeatureToAssetControlProperty(control.fieldName, asset.properties));
        feature.properties.assetId = this.assetId;
        this.feature = feature;

        drawPaletteModel.initFeature(feature);

        formModel.UI = DrawPalette;
    }

    render() {            
        if (this.draw) {
            this.draw.reset();
        } else {
            this.feature.updateMapFeature();
        }
                       
        this.autosave(this.feature);
        featureToControl.sync(this.type, this.feature, this.assetId, this.featureType);
        m.redraw();
    }

    close() {

        if (this.draw) {

            this.draw.stop();

        }

        return this.onClose();

    }

    onClose() {
        if (this.uploadFlowId) {
            delete this.uploadFlowId;
        }

        // Check if any unsaved changes are waiting in the debounce timer
        if (this.hasUnsavedChanges) {
            if (validate(this.feature.geometry)) {
                api.rpc.request([['modifyFeature', Object.assign(api.apiSafeFeature(this.feature, true))]]);
                this.hasUnsavedChanges = false;
            }
        }

        appModel.toolbox.toolInterface = null;
        drawPaletteModel.onToolClose();
    
        importModel.importInProgress = false;

        if (router.params.assetId) {

            if (formModel.assetId !== router.params.assetId) {

                formModel.viewAsset(router.params.assetId, 'Properties');

            } else {

                siteModel.sidebar = AssetForm;

            }

        } else {

            siteModel.sidebar = Table;

            if (tableModel.isCollapsed) {

                tableModel.sideBarToggle();

            }

        }

        m.redraw();

    }

    blankAsset(tool) {

        const date = formatDate.forPython(new Date()),
            assetTypeId = tool.assetForm.assetType.assetTypeId;

        const asset = {
            contentId: randomId(),
            assetTypeId,
            contentType: tool.assetForm.assetType.name,
            threadId: randomId(),
            authorId: appModel.user.userId,
            recipientIds: [appModel.user.userId],
            createdDateTime: date,
            captureDateTime: date,
            toolId: tool.toolId,
            projectId: router.params.projectId,
            properties: {},
            attributes: {
                toolId: tool.toolId,
                toolGroupIds: appModel.toolbox.activeGroupId ? [appModel.toolbox.activeGroupId] : []
            },
            featureIds: []
        };
        assetListManager.addToStore(asset);

        const hasEval = [];

        // Set default values
        tool.assetForm.controls.forEach(control => {
            if (control.attributes.eval && control.controlTypeId !== constants.controlTypeNameToId.request) {

                hasEval.push(control);

            }

            if (control.attributes.default !== undefined) {

                const defaultValue = deepCloneObject(control.attributes.default);
                asset.properties[control.fieldName] = defaultValue;

                if (control.controlTypeId === constants.controlTypeNameToId.file) {
                    asset.mediaIds = (asset.mediaIds || []).concat(defaultValue);
                } 

            } else if (control.controlTypeId === constants.controlTypeNameToId.date
                && control.attributes.defaultToToday) {
                asset.properties[control.fieldName] = Date.now();
            }

        });

        if (hasEval.length > 0) {

            hasEval.forEach(control => {

                if (control.attributes.eval) {

                    evaluateControl(control, asset).then((evaluated) => {
                        asset.properties[control.fieldName] = evaluated.response;
                    });

                }

            });

        }
        return asset;

    }

    showToolLauncher() {

        layerModel.closeLayerPanel();

        popup.remove();

        siteModel.sidebar = ToolLauncher;

    }

}

export default ToolInterface;
