import MediaViewer from 'views/media-viewer/media-viewer';
import siteModel from 'models/site-model';
import mediaModel from 'models/media-model';
import {getNormalizedKey} from 'util/events/get-normalized-key';
import pointMenu from 'legacy/components/point-menu';
import initializer from 'util/initializer';

class MediaViewerModel {

    constructor() {
        this.cleanup();
        this.handleKeyDown = this._handleKeyDown.bind(this);
        initializer.add(this.close.bind(this), 'mediaViewerModel');
        this.pdfOpts = '';
        this.cssClass = '';
    }

    cleanup() {
        this.mediaIds = [];
        this.state = {
            index: 0,
            isWaiting: false,
            isOpen: false
        };
        this.pdfOpts = '';
        this.cssClass = '';
        this.hideMediaOptions = false;
        this.inStandaloneMode = false;
    }

    wait() {
        this.state.isWaiting = true;
        siteModel.view = MediaViewer;
        m.redraw();
    }

    async open(mediaIds, index, opts = {}) {
        // First ensure all mediaIds reference visible media items
        const openOnMediaId = mediaIds[index];
        mediaIds = await mediaModel.filterOutInvisibleMedia(mediaIds);
        // In case the index of the user-selected mediaId changed, make sure it's still active upon opening:
        index = mediaIds.indexOf(openOnMediaId);

        this.state.isWaiting = false;
        this.state.isOpen = true;
        this.mediaIds = mediaIds;
        if (index) {
            this.state.index = index === -1 ? 0 : index;
        }
        siteModel.view = MediaViewer;

        this.pdfOpts = opts.pdfOpts || '';
        this.cssClass = opts.cssClass || '';
        this.hideMediaOptions = opts.hideMediaOptions || false;

        m.redraw();
    }

    close() {
        if (this.state && this.state.isOpen) {
            siteModel.view = null;
            m.redraw();
        }
        this.cleanup();
    }

    hasPrev() {
        return this.state.index > 0;
    }

    hasNext() {
        return this.state.index < this.mediaIds.length - 1;
    }

    selectIndex(index) {
        this.state.index = index;
    }

    _handleKeyDown(e) {
        const key = getNormalizedKey(e.key);
        switch (key) {
        case 'Escape': 
            e.preventDefault();
            e.stopPropagation();
            pointMenu.close();
            return this.close();
        }
    }

}

export default new MediaViewerModel();
