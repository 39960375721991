import constants from 'util/data/constants';
import formModel from 'models/form-model';
import store from 'util/data/store';
import api from 'legacy/util/api';
import debounce from 'util/events/debounce';
import debouncedAPICall from 'util/network/debounced-api-call';
import assetListManager from 'managers/asset-list-manager';
import helpers from 'legacy/util/api/helpers';
import appModel from 'models/app-model';
import batchSelectModel from 'models/batch-select-model';
import featureListManager from 'managers/feature-list-manager';
import siteModel from 'models/site-model';
import mediaListManager from 'managers/media-list-manager';

const controlType = constants.controlTypeNameToId;

function saveFeature(feature) {
    feature.updateMapFeature();
    debouncedAPICall('modifyFeature' + feature.id, ['modifyFeature', api.apiSafeFeature(feature, true)]);
}

function coordinatesToFeature(featureType, control, assetId) {
    const asset = store.assets[assetId];
    const features = asset.featureIds
            .map(id => featureListManager.getById(id))
            .filter(f => f.properties.featureTypeId === featureType.featureTypeId),
        geometry = asset.properties[control.fieldName],
        draw = formModel.toolInterface && formModel.toolInterface.draw,
        drawFeature = draw && draw.feature;
    features.forEach(f => {
        if (f.geometry.type.startsWith('Multi')) {
            f.geometry.coordinates[0] = geometry.coordinates;
        } else {
            f.geometry.coordinates = geometry.coordinates;
        }
        if (drawFeature && drawFeature.id === f.id && draw.vertices && draw.vertices.length) {
            draw.vertices[0].setLngLat(geometry.coordinates);
        }
        saveFeature(f);
    });
    return true; // return true to render source
}

function textToMedia(featureType, control, assetId) {
    const asset = store.assets[assetId],
        linkedControls = featureType.attributes.linkedControls,
        fileControlTypeId = constants.controlTypeNameToId.file,
        text = asset.properties[control.fieldName],
        linkedFileControls = formModel.assetForm.controls.filter(
            c => c.controlTypeId === fileControlTypeId
                && linkedControls.indexOf(c.fieldName) !== -1
        );
    linkedFileControls.forEach(fileControl => {
        const mediaIds = asset.properties[fileControl.fieldName] || [];
        Promise.all(mediaIds.map(mediaListManager.getMediaAsync)).then(mediaRecords => mediaRecords.forEach(media => {
            media.label = text;
            m.redraw();
            debouncedAPICall('modifyMediaLabel', ['modifyMedia', {
                mediaId: media.mediaId,
                label: media.label
            }]);
        }));
    });
    return false;
}

function textToPlan(featureType, control, assetId) {
    const asset = store.assets[assetId],
        linkedControls = featureType.attributes.linkedControls,
        planControlTypeId = constants.controlTypeNameToId.plan,
        linkedPlanControls = formModel.assetForm.controls.filter(c =>
            c.controlTypeId === planControlTypeId
            && linkedControls.indexOf(c.fieldName) !== -1
        ),
        text = asset.properties[control.fieldName];
    linkedPlanControls.forEach(planControl => {

        let planId = asset.properties[planControl.fieldName];

        if (Array.isArray(planId)) {
            if (planId.length === 1) {
                planId = planId[0];
            } else {
                planId = undefined; // dont sync multi layer plan assets
            }
        }
        const plan = store.plans[planId];

        if (plan) {
            store.updateImmutableProp('plans', planId, 'title', text);
            m.redraw();
            debouncedAPICall('modifyPlan' + planId, ['modifyPlan', {
                planId,
                title: text
            }]);
        }

    });
    return false;
}

function textToProject(featureType, control, assetId) {
    const asset = store.assets[assetId],
        name = asset.properties[control.fieldName],
        linkedControls = featureType.attributes.linkedControls,
        projectControlTypeId = constants.controlTypeNameToId.project,
        projectControls = formModel.assetForm.controls.filter(
            c => c.controlTypeId === projectControlTypeId
                && linkedControls.indexOf(c.fieldName) !== -1
        );
    projectControls.forEach(projectControl => {

        const projectId = asset.properties[projectControl.fieldName];

        debouncedAPICall('modifyProject' + projectId, ['modifyProject', {
            projectId,
            name
        }]).then(project =>
            api.rpc.request([['modifyProjectSite', {
                siteId: helpers.list(project.sites)[0].siteId,
                projectId,
                name
            }]])
        );

    });
    return false;
}

function textToSurvey(featureType, control, assetId) {
    const asset = store.assets[assetId],
        linkedControls = featureType.attributes.linkedControls,
        surveyControlTypeId = constants.controlTypeNameToId.survey,
        linkedSurveyControls = formModel.assetForm.controls.filter(c =>
            c.controlTypeId === surveyControlTypeId
            && linkedControls.indexOf(c.fieldName) !== -1
        ),
        text = asset.properties[control.fieldName];
    linkedSurveyControls.forEach(surveyControl => {

        const surveyId = asset.properties[surveyControl.fieldName],
            survey = store.surveys[surveyId];

        if (survey) {

            store.updateImmutableProp('surveys', surveyId, 'title', text);

            m.redraw();

            debouncedAPICall('modify' + surveyId, ['modifySurvey', {
                surveyId,
                title: text
            }]);

        }

    });
    return false;
}

function textToText(featureType, control, assetId) {
    let doRender;
    const asset = store.assets[assetId],
        draw = formModel.toolInterface && formModel.toolInterface.draw,
        drawFeature = draw && draw.feature;
    asset.featureIds
        .map(id => featureListManager.getById(id))
        .filter(f => f.properties.featureTypeId === featureType.featureTypeId)
        .forEach(f => {
            doRender = true;
            f.properties._textField = asset.properties[control.fieldName];
            if (drawFeature && drawFeature.id === f.id) {
                drawFeature.properties._textField = asset.properties[control.fieldName];
                draw.resetTextboxSize();
            }
            saveFeature(f);
        });
    return doRender;
}

function updateFeatureType(featureType, control, assetId) {

    const interfaceType = featureType.attributes.interface,
        syncLinkedFeature = controlToFeature[control.controlTypeId][interfaceType];

    if (syncLinkedFeature) {
        const linkedControls = featureType.attributes.linkedControls;

        if (linkedControls && linkedControls.indexOf(control.fieldName) !== -1) {
            return syncLinkedFeature(featureType, control, assetId);
        }

    }

}

function syncAllFeatureProperties(assetId, featureId) {
    const feature = featureListManager.getById(featureId);
    const asset = store.assets[assetId];
    feature.syncAllFeatureAssetProperties(asset.properties);
}

const updateAssetFeatures = (control, assetId = formModel.assetId, skipTriggerEval = false) => new Promise((resolve) =>
    debounce(() => {
        const asset = store.assets[assetId];
        const newPropValue = asset.properties[control.fieldName];
        const editingAll = batchSelectModel.editingAll;
        const batchAssetsToEdit = Object.keys(batchSelectModel.assetsToModify);
        if (editingAll && siteModel.isTableActive()) {
            formModel.saving[control.fieldName] = true;
            batchAssetsToEdit.forEach(assetToEdit => {
                const modifiedAsset = store.assets[assetToEdit];
                modifiedAsset.properties[control.fieldName] = newPropValue;
                assetListManager.syncFeaturesToControl(modifiedAsset, control.fieldName);
            });
            m.redraw();
            return batchSelectModel.batchAutosave(control, newPropValue);
        } 
        asset.properties[control.fieldName] = newPropValue;
        // If something is marked as invalid,
        // revalidate on input.
        if (formModel.invalid[control.fieldName]) {

            formModel.validateControl(control);

        }

        formModel.saving[control.fieldName] = true;

        m.redraw();

        const modifiedFeatureTypes = [];

        asset.featureIds = helpers.list(asset.featureIds); // In case asset was never mapped

        if (asset.featureIds && asset.featureIds.length > 0) {
            asset.featureIds.forEach(featureId => {
                const feature = featureListManager.getById(featureId);
                if (feature && feature.syncFeatureToAssetControlProperty(control.fieldName, asset.properties)) {
                    feature.updateMapFeature(true);
                    modifiedFeatureTypes[feature.properties.featureTypeId] = true;
                }
            });
        }
        if (asset.attributes) { // this should never be falsy, but sentry says it has been
            const tool = appModel.toolbox.tools[asset.attributes.toolId];
            if (tool.featureTypes) {
                tool.featureTypes.forEach(featureType => {
                    if (updateFeatureType(featureType, control, asset.contentId)) {
                        modifiedFeatureTypes[featureType.featureTypeId] = true;
                    }
                });
            }
        }

        Object.keys(modifiedFeatureTypes).forEach(featureTypeId => {
            featureListManager.redrawMapFeatures(featureTypeId);
        });


        if (assetId === formModel.assetId && !skipTriggerEval) {  // skipTriggerEval flag is Hotfix for UE-2683
            formModel.debouncedTriggerEval(control.fieldName);
        }
        assetListManager.autosave(asset.contentId).then(() => resolve(asset.contentId));

        
    })()
);

const controlToFeature = {
    [controlType.area]: {
        text: textToText
    },
    [controlType.links]: {},
    [controlType.coordinates]: {
        filepicker: coordinatesToFeature,
        symbol: coordinatesToFeature,
        plan: coordinatesToFeature,
        survey: coordinatesToFeature,
        imodel: coordinatesToFeature
    },
    [controlType.dropdown]: {
        text: textToText
    },
    [controlType.radio]: {
        text: textToText
    },
    [controlType.file]: {},
    [controlType.length]: {
        text: textToText
    },
    [controlType.multiselect]: {
        text: textToText
    },
    [controlType.multitext]: {},
    [controlType.name]: {
        filepicker: textToMedia,
        plan: textToPlan,
        survey: textToSurvey,
        text: textToText,
        project: textToProject
    },
    [controlType.number]: {
        text: textToText
    },
    [controlType.paragraph]: {
        filepicker: textToMedia,
        plan: textToPlan,
        survey: textToSurvey,
        text: textToText,
        project: textToProject
    },
    [controlType.plan]: {},
    [controlType.survey]: {},
    [controlType.text]: {
        filepicker: textToMedia,
        plan: textToPlan,
        survey: textToSurvey,
        project: textToProject,
        text: textToText
    },
    [controlType.toggle]: {},
    [controlType.URL]: {
        text: textToText
    },
    [controlType.volume]: {
        text: textToText
    },
    [controlType.date]: {},
    [controlType.place]: {},
    [controlType.user]: {},
    [controlType.asset]: {},
    [controlType.imodel]: {},
    syncAllFeatureProperties,
    updateAssetFeatures
};

export default controlToFeature;
