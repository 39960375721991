
import savedViewsModel from 'models/saved-views-model';

const Footer = {

    oninit: ({state, attrs: {projectView}}) => {
        state.isActiveSavedView = projectView.isSaved && savedViewsModel.activeSavedViewId === projectView.projectViewId;
    },

    view: ({state, attrs: {formFields, projectView}}) => {
        if (state.isActiveSavedView) {
            return '';
        }
        return <div class="modal-footer">
            <div class="btn btn-small btn-pill btn-secondary" onclick={() => savedViewsModel.close()}>Cancel</div>
            {projectView.isSaved ?
                <div class="btn btn-small btn-pill btn-primary right" onclick={() => savedViewsModel.applySavedViewId(projectView.projectViewId)}>Apply</div>
                : <div class="btn btn-small btn-pill btn-primary right" onclick={() => savedViewsModel.saveNew(formFields)}>Save</div>
            }
        </div>;
    }
};

export default Footer;
