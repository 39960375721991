import siteModel from 'models/site-model';
import SideNav from 'views/side-nav';
import Sidebar from 'views/sidebar';
import layerModel from 'models/layer-model';
import LayerPicker from 'views/layer-picker';
import message from 'views/toast-message/toast-message';
import tableModel from 'models/table/table-model';
import VolumeLegend from 'views/volume-legend';
import MapPopUp from 'views/popups/map-popup';
import Popover from 'views/popover/popover';
import CloseButton from 'views/close-button';
import oneUpModel from 'models/one-up-model';
import Table from 'views/table';
import Panel from 'views/panel/panel';
import appModel from 'models/app-model';
import ProgressDashboard from 'views/progress-dashboard/progress-dashboard';
import sideNavModel from 'models/side-nav-model';
import dataCenterModel from 'models/data-center-model';
import DataCenter from 'views/data-center/data-center';
import DrawPalette from 'views/draw-palette';
import drawPaletteModel from 'models/draw-palette-model';

// Primary app view.
const Site = {
    oncreate: () => {
        sideNavModel.selected = null;
        if (siteModel.processingSurvey) {
            message.show('Your survey changes are processing.', 'success');
        }
    },

    onremove() {
        oneUpModel.clearActiveFlow();
    },

    view: () => {
        const View = siteModel.view,
            tableMode = siteModel.sidebar === Table ? tableModel.tableMode || '' : '';
        
        const outerClasses = `basemap-${layerModel.state && layerModel.state.basemapId || 'none'} ${tableMode}${tableModel.isCollapsed ? ' collapsed' : ''}${appModel.project.isMetaProject ? ' meta-project dark-theme' : ''}${tableModel.dismissedClass}${siteModel.dismissedClass}`;

        return <div class={outerClasses}>
            {tableModel.projectView.filters.draw
                ? <div class="side-nav"><CloseButton onclick={() => tableModel.projectView.filters.cancelDrawSearchArea()} /></div>
                : <SideNav/>}
            <Panel/>
            <Sidebar/>
            <MapPopUp/>
            <Popover/>
            {layerModel.isOpen ? <LayerPicker/> : null}
            {View && <View />}
            <div id="mapbox" class="mapbox"></div>
            {drawPaletteModel.activeFeature && <DrawPalette />}
            <VolumeLegend />
            {sideNavModel.isDashboardOpen && <ProgressDashboard
                onClose={() => sideNavModel.toggleProgressDashboard()}
            />}
            {dataCenterModel.isDataCenterOpen && <DataCenter />}
        </div>;
    }

};

export default Site;
