import api from 'legacy/util/api';
import randomId from 'util/numbers/random-id';
import {urnify} from 'util/network/urnify';
import {getNormalizedKey} from 'util/events/get-normalized-key';
import {didTextInputChange} from 'util/data/did-text-input-change';
import {date1IsNewer} from 'util/data/asset-1-is-newer';
import helpers from 'legacy/util/api/helpers';
import {normalizeUtcTimestamp} from 'util/data/helpers';
import restrictions from 'util/permissions/restriction-message';
import Cached from 'util/data/cached';
import FolderModel from 'models/folder/folder-model';
import store from 'util/data/store';
import appModel from 'models/app-model';
import layerModel from 'models/layer-model';
import layerColorModel from 'models/layer-color-model';
import dialogModel from 'models/dialog-model';
import planModel from 'models/plan-model';
import { datadogRum } from '@datadog/browser-rum';
import logConstants from 'constants/managers/log-constants';


/**
 * A terminating element in the nested folder structure. 
 * Currently only plans are added as folder items.
 */
class FolderItemModel {
    constructor(record = {}, root, recordType) {
        this.id = record.id || randomId();
        this.recordType = recordType;
        this.parentId = record.parentId || (root ? root.id : undefined);
        this.name = record.name;
        this.urn = urnify(this.recordType, this.id);
        this.updatedDateTime = record.updatedDateTime || record.updatedAt || new Date().toISOString();

        this.state = {
            isSaving: false,
            isToggledOn: false,
            isDraggingOverDropZone: false
        };
        
        this._root = root;
        this._isEditableByUser = null;
        this.cache = new Cached();

        this.handleNameInputKeyDown = this._handleNameInputKeyDown.bind(this);

        return this;
    }

    get isInRoot() {
        return this.parentId === this.root.id;
    }

    get root() {
        return this._root;
    }

    get isDraggingIntoItem() {
        return this.root && this.root.draggingOverItem && this.root.draggingOverItem.id === this.id && !this.root.isDraggingOverDropZone;
    }

    get isDraggingOverDropZone() {
        return this.root && this.root.draggingOverItem && this.root.draggingOverItem.id === this.id && this.root.isDraggingOverDropZone;
    }

    get draggingStateCssClasses() {
        return `draggable-item draggable-${this.recordType}${this.state.isBeingDragged ? ' being-dragged' : ''}${this.isDraggingIntoItem  ? ' dragging-over' : ''}`;
    }

    get contentsCssClasses() {
        return `folder-contents ${this.state.isExpanded || this.isDraggingIntoItem ? 'expanded' : 'collapsed'}`;
    }

    get isEditableByUser() {
        return appModel.user.permissions.canEditRecord(appModel.getByUrn(this.urn));
    }

    get parentUrn() {
        return this.parentId ? urnify('folder', this.parentId) : undefined;
    }

    get parent() {
        if (this.isInRoot) {
            return this.root;
        }
        return this.root && this.root.items ? this.root.items[this.parentUrn] : undefined;
    }

    get tileset() {
        if (this.recordType === 'plan') {
            const storePlan = store.plans[this.id];
            return storePlan ? store.tilesets[storePlan.tilesetId] : undefined;
        }
        return undefined;
    }

    get canEditColor() {
        if (this.recordType === 'plan') {
            const storePlan = store.plans[this.id];
            return storePlan ? appModel.user.permissions.canEditRecord(storePlan) : undefined;
        }
        return undefined;
    }

    getRestrictionMessage(action = 'edit') {
        if (this.isEditableByUser) {
            return '';
        }
        return restrictions.message(appModel.user, action, this.recordType);
    }

    onDragStart(e) {
        e.redraw = false;
        e.stopPropagation();
        this.root.closePopups();
        this.startDrag();
        this.awaitDrop();
        e.dataTransfer.setDragImage(this.root.dragImage, 0, 0);
        m.redraw();
    }

    bringIntoFocus() {
        const parent = this.parent;
        if (parent && !parent.state.isExpanded) {
            this.expandFolderFromChild(true);
        }
        this.state.highlight = true;
        m.redraw();
        setTimeout(() => {
            this.state.highlight = false;
            m.redraw();
        }, 2000);
    }

    /**
    * Since the item we're dragging over is not a folder, 
    * it must be a drop zone (that is, we can't drag into a plan record)
    */
    calculateIfInDropZone() {
        return true;
    }

    isDescendantOf(item) {
        if (this.isInRoot) {
            return !!item.isRoot;
        } 
        if (item.isRoot) {
            return true;
        }
        if (this.parentId === item.id)  {
            return true;
        }
        return this.parent ? this.parent.isDescendantOf(item) : false;
    }

    onDragEnter(e) {
        e.redraw = false;

        const draggingItem = this.root.draggingItem;
        const draggingOverItem = this.root.draggingOverItem;

        if (draggingItem.recordType === 'folder' && this.cache.get('descendantOf ' + draggingItem.id, () => this.isDescendantOf(draggingItem))) {
            return; // Invalid, can't move a parent folder into one of its children.
        }

        if (draggingOverItem && draggingOverItem.id !== this.id && draggingOverItem.cache.get('descendantOf ' + this.id, () => draggingOverItem.isDescendantOf(this))) {
            return; // Quit, prefer dragging over descendants rather than anscestors 
        }

        const elementX = e.target.getBoundingClientRect().x;
        
        // If we're dragging the item back its original spot, default to display it as a drop zone.
        const isDraggingOverDropZone = this.state.isBeingDragged ? true : this.calculateIfInDropZone(e);

        this.root.setDraggingOver(this, {
            isDraggingOverDropZone,
            elementX 
        });


        m.redraw();
    
    }

    /**
    * Prefer the item whose header we're dragging over (rather than dragging over the body of an item)
    */
    onDragOverHeader(e) {
        e.redraw = false;

        const draggingItem = this.root.draggingItem;
        const mouseOffsetX = e.offsetX;

        if (draggingItem.recordType === 'folder' && this.cache.get('descendantOf ' + draggingItem.id, () => this.isDescendantOf(draggingItem))) {
            return; // Invalid, can't move a parent folder into one of its children.
        }

        const isDraggingOverDropZone = this.state.isBeingDragged ? true : this.calculateIfInDropZone(e);
        this.root.setDraggingOver(this, {
            isDraggingOverDropZone, 
            mouseOffsetX
        });
        m.redraw();
    }

    awaitDrop() {
        window.ondrop = e => {
            e.preventDefault();
            this.dropItemIfValid();
            window.ondragover = null;
            window.ondragenter = null;
            window.ondrop = null;
        };
        window.ondragover = e => e.preventDefault();
        window.ondragenter = e => e.preventDefault();
    }

    getNextVisiblePlan() {
        if (this.recordType === 'plan') {
            const planZOrder = this.root.planZOrder;
            const reversedZOrder = Array.from(planZOrder).reverse();
            const planIndex = reversedZOrder.findIndex(u => u === this.id);

            if (planIndex < reversedZOrder.length - 1) {
                const nextVisiblePlanId = reversedZOrder
                    .slice(planIndex + 1).find(id => {
                        const p = store.plans[id];
                        return p && layerModel.state.planTilesetIds.has(p.tilesetId);
                    });
                return nextVisiblePlanId ? store.plans[nextVisiblePlanId] : undefined;
            }
        }
        return undefined;
    }

    dropItemIfValid() {       
        const droppedZone = this.root.draggingOverItem;

        if (!droppedZone || droppedZone.id === this.id) {
            return this.stopDrag();
        }

        const moveWithinParent = droppedZone.root.isDraggingOverDropZone && droppedZone.parentUrn === this.parentUrn; 
        if (moveWithinParent) {
            // Same parent, just reorder it:
            const dropZoneZindex = droppedZone.parent.zOrder.findIndex(item => item === droppedZone.urn); // place it where the current item is
            
            const movingIndex = droppedZone.parent.zOrder.findIndex(item => item === this.urn);
            let newZindex = movingIndex > dropZoneZindex ? dropZoneZindex : dropZoneZindex - 1;

            if (newZindex === -1) {
                newZindex = droppedZone.parent.zOrder.length + 1;
            }

            droppedZone.parent.zOrder.splice(movingIndex, 1);
            droppedZone.parent.zOrder.splice(newZindex, 0, this.urn);

            droppedZone.parent.saveToApiZorder();

        } else  {
            // Move to a different parent:
            let newParent, newZindex;
            const oldParent = this.parent;

            if (droppedZone.root.isDraggingOverDropZone) {
                newParent = droppedZone.parent; 
                if (!newParent) {
                    newParent = this.root;
                }
                newZindex = newParent.zOrder.findIndex(item => item === droppedZone.urn);
            } else {
                newParent = droppedZone;
                newZindex = newParent.zOrder.length;
            }

            if (newParent.recordType !== 'folder') {
                // Hopefully this never happens, but let's log it in case
                console.warn('something went wrong, invalid drop zone');
                return this.stopDrag();
            }

            this.parentId = newParent.id;
            this._parentUrn = null;

            oldParent.remove(this.urn);
            newParent.add(this.urn, this, newZindex);

            newParent.saveToApiZorder();
            oldParent.saveToApiZorder();
            
            newParent.state.isExpanded = true;
        }

        this.saveToApi(); // Save new parentId to api
        
        this.root.onOrderUpdate();
        if (this.recordType === 'plan') {
            layerModel.onUpdatedPlanOrder(this.id);
        } else if (this.descendantPlans.length) {
            layerModel.onUpdatedPlansOrder(this.descendantPlans);
        }
        this.stopDrag();
    }

    saveToApi() {
        if (this.recordType === 'plan') {
            // parentId will be maintained by API
            return;
        } else if (this.recordType === 'folder') {
            super.saveToApi(); // save new folder parentId
        }
    }

    expandFolderFromChild(recursive = false) {
        this.state.isExpanded = true;
        m.redraw();
        if (this.parent && !this.parent.state.isExpanded) {
            this.parent.expandFolderFromChild(recursive);
        }
    }
    
    turnVisibilityOnFromChild(recursive = false) {
        this.state.isToggledOn = true;
        m.redraw();
        if (this.parent && !this.parent.state.isToggledOn) {
            this.parent.turnVisibilityOnFromChild(recursive);
        }
    }

    startDrag() {
        this.root.setDraggingItem(this);
        this.state.isBeingDragged = true;
        m.redraw();  
    }

    stopDrag() {
        this.state.isBeingDragged = false;
        this.root.setDraggingItem(null);
        this.root.setDraggingOver(null);
        m.redraw();
    }

    startEditingColor(anchorElement) {
        this.root.toggleColorPickerOn(this.urn, anchorElement);
        m.redraw();
    }

    startEditingName() {
        this.originalName = this.name;
        this.root.toggleEditingNameOn(this.urn);
        m.redraw();
    }

    stopEditingName(e) {
        if (e) {
            e.stopPropagation();
        }
        this.root.toggleEditingNameOn(null);
        delete this.originalName;
        m.redraw();
    }

    handleNameInput(name) {
        this.name = name;
        m.redraw();
    }

    _handleNameInputKeyDown(e) {
        const key = getNormalizedKey(e.key);
        if (key === 'Enter') {
            this.saveNameIfChanged();
        }
    }

    setColor(hex, opacity) {
        this.currentHex = hex;
        this.currentOpacity = opacity;
        m.redraw();
        const item = appModel.getByUrn(this.urn);
        if (this.recordType === 'plan') {
            layerColorModel.setColor(hex, opacity, item.tilesetId);
        }
    }

    async delete(recursive = false) {
        if (this.recordType === 'plan') {
            this.isSaving = true;
            m.redraw();            
            const plan = appModel.getByUrn(this.urn);
            if (layerModel.state.planTilesetIds.has(plan.tilesetId)) {
                layerModel.hidePlan(plan);
            }
            
            const requests = [
                ['deletePlan', {planId: plan.planId}]
            ];
            if (plan.tilesetId) {
                requests.push(['deleteTileset', {tilesetId: plan.tilesetId}]);
            }

            if (!recursive) {
                this.parent.remove(this.urn);
                this.parent.saveToApiZorder();
            }

            return api.rpc.requests(requests).then(() => {
                this.isSaving = false;
                m.redraw();
            });
        }
    }

    handleDelete(descriptor) {
        this.root.closePopups();
        
        if (!this.recordType === 'folder' && !this.zOrder.length) {
            return this.delete(); // Empty folder, so just delete it w/out a warning
        }

        const headline = this.isRoot ? `Delete ${descriptor}?` : `Delete ${descriptor}: ${this.name}?`;

        let text;
        if (this.isRoot) {
            text =  `Please note that this action will delete ${descriptor} and cannot be undone.`;
        } else if (this.recordType === 'plan') {
            text = <span>Please note that this action will Plan Layer and cannot be undone.</span>;
        } else {
            text = <span>This action will delete the Group <strong>and all Layers and Groups within it</strong> and cannot be undone.</span>;
        }

        const recursive = this.recordType === 'folder';
        return dialogModel.open({
            headline,
            text,
            yesText: 'Delete',
            yesClass: 'btn btn-pill btn-red',
            noText: 'Cancel',
            noClass: 'btn btn-pill btn-secondary',
            cssClass: 'layer-menu-stop-prop',
            onYes: () => this.delete(recursive),
            onNo: (e) =>  e.stopPropagation() // Prevent layer menu from closing
        });
    }
    
    async saveNameIfChanged() {
        const textDidChange = didTextInputChange(this.originalName, this.name);
        if (!textDidChange) {
            this.name = this.originalName;
            this.stopEditingName();
            return Promise.resolve();
        }
        this.stopEditingName();
        this.state.isSaving = true;
        this.name = this.name.trim();
        m.redraw();
        await this.saveToApiName();
        delete this.originalName;
        this.state.isSaving = false;
        m.redraw();
        return Promise.resolve();
    }
    
    async saveToApiName() {
        return planModel.savePlanName(this.id, this.name);
    }

    toggleState(stateKey, e) {
        if (e) {
            e.stopPropagation();
        }
        this.root.closePopups();
        this.state[stateKey] = !this.state[stateKey];
        m.redraw();
    }

    handleDeleteFolderFromApi(apiFolderObject) {
        const urn = urnify('folder', apiFolderObject.folderId);
        const folder = this.root.getByUrn(urn);
        if (folder) {
            const parent = folder.parent;
            parent.remove(urn);
            m.redraw();
        }
    }

    handleUpdatedZOrderFromApi(urn, zOrder) {
        zOrder = helpers.list(zOrder);
        const folder = this.root.getByUrn(urn);
        if (folder) {
            folder.zOrder = Array.from(zOrder).reverse();
            m.redraw();
        }
    }

    handleModifyFolderItemFromApi(urn, apiFolderObject) {
        const folder = this.root.getByUrn(urn);
        if (folder) {
            if (apiFolderObject.changedBy && apiFolderObject.changedBy.userId === appModel.user.userId) {
                return;
            }
            apiFolderObject.updatedDateTime = normalizeUtcTimestamp(apiFolderObject.updatedDateTime);
            folder.updatedDateTime = normalizeUtcTimestamp(folder.updatedDateTime);

            const date1 = new Date(apiFolderObject.updatedDateTime);
            const date2 = new Date(folder.updatedDateTime);
            if (date1IsNewer(date1, date2)) {
                const originalParent = folder.parent;
                const originalState = Object.assign({}, folder.state);
                const childPlans = Object.assign({}, folder._childPlans);
                const isEditableByUser = folder._isEditableByUser;
                if (apiFolderObject.root) { // This means it is the root folder
                    this.root.handleUpdatedZOrderFromApi(this.root.urn, apiFolderObject.zOrder || apiFolderObject.z_order);
                } else {
                    const zOrder = apiFolderObject.zOrder ? helpers.list(apiFolderObject.zOrder) : apiFolderObject.z_order ? helpers.list(apiFolderObject.z_order) : [];
                    const updatedFolder = new FolderModel({
                        id: apiFolderObject.folderId, 
                        projectId: apiFolderObject.projectId,
                        parentId: apiFolderObject.parentId, 
                        name: apiFolderObject.name,
                        zOrder
                    }, this);

                    updatedFolder._childPlans = childPlans;
                    updatedFolder.state = originalState;
                    updatedFolder._isEditableByUser = isEditableByUser;
                    const parentChanged = originalParent && originalParent.id !== updatedFolder.parentId;
                    originalParent.remove(updatedFolder.urn, parentChanged);
                    updatedFolder.parent.add(updatedFolder.urn, updatedFolder);
                    
                }
                m.redraw();
            }
        } else {
            this.handleNewFolderFromApi(apiFolderObject);
        }
    }

    handleNewFolderFromApi(apiFolderObject) {
        const zOrder = helpers.list(apiFolderObject.zOrder);

        const folder = new FolderModel({
            id: apiFolderObject.folderId, 
            projectId: apiFolderObject.projectId,
            parentId: apiFolderObject.parentId, 
            name: apiFolderObject.name,
            zOrder: [...zOrder]
        }, this);
        const parentItem = this.root.items[folder.parentId] || this.root;
        parentItem.add(folder.urn, folder);
        folder.state.subFoldersLoaded = true;
        m.redraw();
    }

    // Handles inconsistencies between the parentId in the api and the parents' zOrders.
    // https://unearth.atlassian.net/browse/UE-7128
    // https://unearth.atlassian.net/browse/UE-6757
    handleConflictingParentId(conflictingParentId) {
        if (this.parent && this.parent.zOrder.find(item => item === this.urn)) {
            // The item matching this item's parentId does have this in its zOrder, so remove the item from the other's zOrder
            const wrongParent = this.root.items[urnify('folder', conflictingParentId)];
            if (wrongParent) {
                wrongParent.remove(this.urn);
                this.root.items[this.urn] = this;
                wrongParent.saveToApiZorder();
            } else {
                datadogRum.addError(logConstants.errorLogMessages.UNFIXED_CONFLICTING_PARENT_ID, {userId: appModel.user.userId, folderId: this.id, conflictingParentId, projectId: appModel.project.projectId});
                console.warn('unable to fix conflictingParentId', this.id, conflictingParentId);
            }
        } else {
            // The item matching this item's parentId does NOT have this in its zOrder, so update the parentId to match the one who does have it in the zOrder
            this.parentId = conflictingParentId;
            this.saveToApi();
            this.root.onOrderUpdate();
        }
    }
}

export default FolderItemModel;
