import exportPDFModel from 'models/export-pdf-model';
import siteModel from 'models/site-model';

const ExportPromptContentCard = {

    oncreate: () => {
        setTimeout(() => {
            exportPDFModel.selectOrientation(exportPDFModel.HORIZONTAL);
        }, 100);
    },

    onremove: () => {
        siteModel.view = null;
    },

    view: () => {
        return <div>
            <div class="tool-launcher export-prompt">
                <div class="asset-title-wrap">
                    <i class="icon-download icon asset-icon" />
                    <div class="asset-title">PDF Export</div>
                    {exportPDFModel.isLoading || exportPDFModel.isWaiting ? <i className="loader-v2"/> : null}
                    <div class="btn btn-smallest btn-secondary btn-pill asset-cancel" onclick={() => exportPDFModel.close()}>{exportPDFModel.isWaiting ? 'Close' : 'Cancel'}</div>
                </div>
                <div class="tool-launcher-prompt">
                    Position the map within the viewer and tap "Export" when ready to export your PDF.
                </div>
                <div>
                    <div class="prompt-container clear">
                        <div className={`btn btn-primary btn-pill btn-small right ${exportPDFModel.isLoading || exportPDFModel.isWaiting ? 'disabled' : ''}`}
                            onclick={() => exportPDFModel.send()}>
                            <span className="btn-label">Export</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
};

export default ExportPromptContentCard;
