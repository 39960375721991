import addCommas from 'util/numbers/add-commas';

const feetPerMeter = 3.280839895,
    squareFeetPerSquareMeter = 10.76391041671,
    cubicFeetPerCubicMeter = 35.314666721489,
    cubicYardsPerCubicMeter = 1.3079506193,
    yardsPerMeter = 1.0936132983;

function cubicMetersToCubicYards(cubicMeters) {

    return cubicMeters * cubicYardsPerCubicMeter;

}

function cubicYardsToCubicMeters(cubicYards) {

    return cubicYards / cubicYardsPerCubicMeter;

}

function feetToMeters(feet) {

    return feet / feetPerMeter;

}

function yardsToMeters(yards) {

    return yards / yardsPerMeter;

}

function squareFeetToSquareMeters(squareFeet) {

    return squareFeet / squareFeetPerSquareMeter;

}

function squareMetersToSquareFeet(squareMeters) {

    return squareMeters * squareFeetPerSquareMeter;

}

function metersToFeet(meters) {

    return meters * feetPerMeter;

}

function metersToYards(meters) {

    return meters * yardsPerMeter;

}

function cubicFeetToCubicMeters(cubicFeet) {

    return cubicFeet / cubicFeetPerCubicMeter;

}

function cubicMetersToCubicFeet(cubicMeters) {

    return cubicMeters * cubicFeetPerCubicMeter;

}

function metersPerPixel(map, ll = map.getCenter()) {

    return Math.abs(40075016.686 * Math.cos(ll.lat) / Math.pow(2, map.getZoom() + 8));

}

export default {
    addCommas,
    cubicMetersToCubicYards,
    cubicYardsToCubicMeters,
    feetToMeters,
    metersToFeet,
    yardsToMeters,
    metersToYards,
    metersPerPixel,
    squareFeetToSquareMeters,
    squareMetersToSquareFeet,
    cubicFeetToCubicMeters,
    cubicMetersToCubicFeet
};
