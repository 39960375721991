import featureListManager from 'managers/feature-list-manager';
import constants from 'util/data/constants';
import oneUpModel from 'models/one-up-model';
import drawPaletteModel from 'models/draw-palette-model';

const Icon = {
    view: ({ attrs: { propKey } }) => {
        const value = drawPaletteModel.activeFeature.properties[propKey];
        return <div class="control-group icon-control">
            {value && <img class="thumbnail" src={constants.staticMediaURL + value} />}
            <div class="upload-icon-image" onclick={() =>
                oneUpModel.addUploadFlow({
                    accept: ['image/*'],
                    toStatic: true,
                    maxFiles: 1,
                    name: 'Icon'
                }).then(([media]) => {
                    const mediaId = media.mediaId;
                    featureListManager.addImage(mediaId, constants.staticMediaURL + mediaId, drawPaletteModel.activeFeature);
                    drawPaletteModel.handleInput(propKey, mediaId);
                })
            }>Upload</div>
        </div>;
    }
};

export default Icon;
