import formModel from 'models/form-model';

const ControlLabel = {
    view: ({attrs: {noElementTag, control}}) => {
        if (control.attributes.hideLabel) {
            return '';
        }
        const text = `${control.label}${formModel.controlSuffix(control)}`;
        if (noElementTag) {
            return text;
        }
        return <label>{text}</label>;
    }
};

export default ControlLabel;
