import store from 'util/data/store';
import formModel from 'models/form-model';
import getToolInterface from 'util/interfaces/get-tool-interface';
import bentleyModel from 'models/bentley-model';
import appModel from 'models/app-model';
import constants from 'util/data/constants';

const Thumbnail = {
    oninit({attrs: {iModelId}, state}) {
        if (iModelId && bentleyModel.isAuthenticated()) {
            bentleyModel.getThumbnail(iModelId).then(thumbnail => {
                state.thumbnail = thumbnail;
                m.redraw();
            });
        }
    },
    view({attrs: {iModelId, bentleyProjectId}}) {
        return <div class="imodel-thumbnail"
            onclick={() => bentleyModel.viewIModel(iModelId, bentleyProjectId)}>
            <div class="btn btn-bentley btn-pill btn-small"><img src={`${constants.staticMediaURL}Do1zBuY14_pAYw2LpImM`} class="bentley-icon" />Open iModel Viewer</div>
        </div>;
    }
};

export default function (control, assetId) {
    const asset = store.assets[assetId];
    const iModel = asset.properties[control.fieldName] || {};
    const canUpload = !iModel.iModelId && !formModel.isReadOnlyControl(asset.assetTypeId, control);
    return <div class="imodel-control">
        <Thumbnail iModelId={iModel.iModelId} bentleyProjectId={iModel.bentleyProjectId} />
        <div class="imodel-source">Source: Bentley iTwin</div>
        {canUpload && <div class="file-control-btn">
            <div class="btn btn-secondary btn-pill btn-smallest" onclick={() => {
                const tool = appModel.toolbox.tools[asset.attributes.toolId];
                const featureTypeId = tool.featureTypes[0].featureTypeId;
                getToolInterface(tool, assetId, featureTypeId).launch();
            }}>Add {control.label}</div>
        </div>}
    </div>;
}
