import { STEPS } from 'constants/flows/upload-help-constants';
import appModel from 'models/app-model';
import uploadHelpFlow from 'flows/upload-help/upload-help-flow';
import modalModel from 'models/modal-model';
import SearchBox from 'views/search-box/search-box';
import DragAndDrop from 'views/drag-and-drop';
import message from 'views/toast-message/toast-message';
import createLayerFlow from 'flows/create-layer/create-layer-flow';
import {isSinglePage} from 'flows/create-layer/create-layer-utils';

const FileList = {
    view: ({ attrs: { files } }) => <div class="file-list">{files.map(file => <div>{file.name}</div>)}</div>
};

const UploadHelp = {
    onremove: () => uploadHelpFlow.reset(),
    view: () => {
        const CurrentStep = UploadHelp[uploadHelpFlow.onStep] || UploadHelp[STEPS.HUBSPOT_PROCESS];
        return <div class={`block-wrap dialog-wrap dark-theme-dialog${uploadHelpFlow.isDoneMapping ? ' fadeToLight' : ''}`}>
            <CurrentStep />
        </div>;
    },

    [STEPS.START]: {
        view: () => <div><i class="spinner spinning" /></div>
    },

    [STEPS.PROMPT]: {
        view: () => <div class="upload-help-step step-one-up">
            <div class="btn-dismiss-modal dismiss-button UFtracker-dismiss-upload" onclick={() => modalModel.close()}>
                <span class="icon-close">Dismiss</span>
            </div>
            <span class="upload-help-title">Kick-start your first {appModel.toolbox.siteTermSingularCapitalized} with<br />data you want to organize on a map.</span>

            <div class="upload-help-one-up-wrapper">
                <DragAndDrop
                    headline={<span>Drag file here or <span class="browse-link">browse</span> ...</span>}
                    description={<>We can auto map KML/Z, SHP Packages, GeoTIFF, JPG, and more ...</>}
                    handleDrop={e => {
                        uploadHelpFlow.handleFileInput(e.dataTransfer);
                    }}
                    handleClick={e => {
                        uploadHelpFlow.handleFileInput(e);
                    }} />
                <FileList files={uploadHelpFlow.allFiles} />
                <div class="btn btn-small btn-pill btn-primary btn-upload-files" onclick={uploadHelpFlow.allFiles.length ? () => uploadHelpFlow.nextStep() : () => message.show('Please select at least one file to continue.', 'warning')}>Upload</div>
            </div>

            <div className="project-prompt-divider"><span className="or-circle">OR</span></div>
            <p className="upload-help-footer-message">Other file formats? No data? No problem.</p>

            <div className="upload-help-search-address project-prompt-search">
                <SearchBox onSuggest={uploadHelpFlow.suggest.bind(uploadHelpFlow.suggest)}
                    placeholder="Enter an address, city, or lat/long …" />
            </div>
            <p className="project-prompt-tip upload-helper-tip">We’ll create a {appModel.toolbox.siteTermSingularCapitalized} for you in the vicinity to get you started.</p>
        </div>
    },

    [STEPS.SELECT_TOOL]: UploadHelp ? UploadHelp[STEPS.PROCESS] : '', // We opted to automate the tool selection rather than prompting the user to choose, so here we can just show the next step's screen w/the progress indicators

    [STEPS.PROCESS]: {
        view: () => {
            return <div class="upload-help-step step-processing">
                <div class="upload-complete-message">
                    <span class="upload-help-title">Mapping in progress ...</span>
                    {uploadHelpFlow.statusText.map((status, i) => <div class={`step-text ${uploadHelpFlow.statusText.length - 1 === i ? 'active-step' : 'completed-step'}`}><span class="step-text-inner">
                        <span class={`spinner-container ${status.cssClass || ''}`}>
                            <i class="spinner spinning" />
                        </span>
                        <span class="completed-step-icon">
                            <i class="icon-check" />
                        </span>
                    </span>
                    <span class="step-text-text">
                        {uploadHelpFlow.statusText.length - 1 === i ? status.inProgressText : status.doneText}
                    </span></div>)}
                </div>
            </div>;
        }
    },

    [STEPS.HUBSPOT_PROCESS]: {
        view: () => {
            return <div class="upload-help-step step-processing">
                <div class="upload-complete-message">
                    <span class="upload-help-title">Mapping in progress ...</span>
                    {uploadHelpFlow.statusText.map((status, i) => <div class={`step-text ${uploadHelpFlow.statusText.length - 1 === i ? 'active-step' : 'completed-step'}`}>
                        <span class="step-text-inner">
                            <span class={`spinner-container ${status.cssClass || ''}`}>
                                <i class="spinner spinning" />
                            </span>
                            <span class="completed-step-icon">
                                <i class="icon-check" />
                            </span>
                        </span>
                        <span class="step-text-text">
                            {uploadHelpFlow.statusText.length - 1 === i ? status.inProgressText : status.doneText}
                        </span>
                    </div>)}
                </div>
            </div>;
        }
    },

    [STEPS.HUBSPOT_DONE]: {
        view: () => <div class="hubspot-submitted">
            <div class="btn-dismiss-modal dismiss-button UFtracker-dismiss-upload" onclick={() => modalModel.close()}>
                <span class="icon-close">Dismiss</span>
            </div>
            <span class="upload-help-title">Our mapping experts are ready to assist you.</span>
            <div class="processing-steps">
                <p class="upload-complete-message-text">Our mapping engine was unable to automatically map your file(s), but our human mapping experts are here to help!</p>
                <p class="upload-complete-message-text"><a href="mailto:unearthsupport@procore.com">Please contact our support team</a> to learn more about currently supported file formats and formats we will support in the near future. </p>
            </div>
            <div class="upload-complete-footer">
                <p class="upload-complete-footer-text">In the meantime, create a {appModel.toolbox.siteTermSingularCapitalized} to see everything you can map on your own.</p>
                <span class="UFtracker-create-a-project btn btn-primary btn-pill btn-small" onclick={(e) => {
                    modalModel.close();
                    uploadHelpFlow.keepActiveToolOpen = true;
                    uploadHelpFlow.openProjectToolLauncher(e);
                }}>Create a {appModel.toolbox.siteTermSingularCapitalized}</span>
            </div>
        </div>
    },

    [STEPS.SELECT_ADDRESS]: {
        view: () => {
            const hasMultipleFiles = uploadHelpFlow.automatedFiles.length + uploadHelpFlow.manualFiles.length > 1;
            return <div class="upload-help-step step-select-address">
                <div class="btn-dismiss-modal dismiss-button UFtracker-dismiss-select-location" onclick={() => modalModel.close()}><span class="icon-close">Dismiss</span></div>
                <span class="upload-help-title">Select an address for your {appModel.toolbox.siteTermSingularCapitalized}.</span>
                <p class="upload-complete-message-text">Our mapping engine was unable to extract location data from your file{hasMultipleFiles ? 's' : ''}, but we can still map {hasMultipleFiles ? 'them' : 'it'}!</p>
                <div class="upload-complete-footer">
                    <p class="upload-complete-message-text">Enter a location in the vicinity to get started.</p>
                    <div className="upload-help-search-address project-prompt-search">
                        <SearchBox onSuggest={uploadHelpFlow.suggest.bind(uploadHelpFlow.suggest)}
                            placeholder="Enter an address, city, or lat/long …" />
                    </div>
                    <p className="project-prompt-tip upload-helper-tip">You can always edit this later.</p>
                </div>
            </div>;
        }
    },
    [STEPS.DONE]: { 
        view: () => <div class={`upload-help-step step-processing UFtracker-complete-after-upload-modal-${uploadHelpFlow.toolInterfaceString}${createLayerFlow.sourceDocument ? !isSinglePage(createLayerFlow.sourceDocument) ? ' multi-page' : ' single-page' : ''} ${uploadHelpFlow.primaryFileExtension}`}>
            <div class="btn-dismiss-modal dismiss-button UFtracker-dismiss-after-upload" onclick={() => modalModel.close()}><span class="icon-close">Dismiss</span></div>
            <span class="upload-help-title">Your {appModel.toolbox.siteTermSingularCapitalized} is ready!</span>
            <div class="processing-steps">
                {uploadHelpFlow.statusText.map((status, i) => <div class="step-text completed-step"><span class="step-text-inner"><span class="completed-step-icon"><i class="icon-check" /></span><span class="step-text-text">{status.doneText}</span></span></div>)}
            </div>
            <div class="btn btn-pill btn-explore-project" onclick={() => modalModel.close()}> {uploadHelpFlow.noAutomatedAddress === true ? 'Create Your Map Layer' : 'Explore Your Map'}</div>
            {uploadHelpFlow.statusFootnote ? <div class="processing-footnote">{uploadHelpFlow.statusFootnote}</div> : ''}
        </div>
    }
};

export default UploadHelp;
