import constants from 'util/data/constants';

function readCookie(key, remove) {

    const name = key + '=';

    const ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i++) {

        let c = ca[i];

        while (c.charAt(0) === ' ') {

            c = c.substring(1, c.length);

        }

        if (c.indexOf(name) === 0) {

            const cookie = c.substring(name.length, c.length);


            if (remove) {

                removeCookie(key);

            }

            return cookie;

        }
    }

    return null;
}

function removeCookie(name) {
    document.cookie = name + `=; ${constants.isDeployed ? 'domain=.unearthlabs.com; ' : ''}path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
}

export default {readCookie, removeCookie};
