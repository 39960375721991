import {metersToPixels} from 'util/geo';
import siteModel from 'models/site-model';

function setSizeAndColor(div, feature, map) {

    const properties = feature.properties,
        latitude = properties._latitude * (180 / Math.PI), // from radians
        renderZoom = map ? map.getZoom() : 20,
        textSizePixels = metersToPixels(properties._textSizeMeters, latitude, renderZoom);

    div.style.height = textSizePixels;
    div.style.fontSize = `${textSizePixels}px`;
    div.style.caretColor = properties._textColor || '#000000';

    if (properties._textHaloWidthMeters) {

        div.style.caretColor = properties._textHaloColor || '#000000';

    }

    div.parentNode.style.transform = `rotate(${(properties._rotation || 0) - siteModel.map.getBearing()}deg)`;

}

function getTextElement(text, feature, map) {

    const div = document.createElement('div'),
        wrap = document.createElement('div');

    div.classList.add('textbox-content');
    wrap.classList.add('textbox-wrap');

    wrap.appendChild(div);

    div.style.fontFamily = 'museo sans rounded';
    div.style.whiteSpace = 'pre';
    div.style.fontWeight = '300';
    div.style.textAlign = 'center';
    div.style.color = 'transparent';

    div.contentEditable = true;

    if (text) {

        div.textContent = text;

    }

    if (feature) {

        setSizeAndColor(div, feature, map);

    }

    return wrap;

}

getTextElement.setSizeAndColor = setSizeAndColor;

export default getTextElement;
