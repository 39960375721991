import appModel from 'models/app-model';
import screenHelper from 'legacy/util/device/screen-helper';
import stopPropagation from 'util/events/stop-propagation';
import ImportHelp from 'views/import-help/import-help.js';
import importModel from 'models/import-model';
import constants from 'util/data/constants';

const ToolLauncher = {

    oninit({state}) {
        state.isImporting = false;
        state.canImport = document.body.offsetWidth > 1024;
    },

    onremove({state}) {
        if (state.removeResize !== undefined) {
            state.removeResize();
        }
    },

    oncreate({state}) {
        state.removeResize = screenHelper.onResize(() => {
            const prevState = state.canImport;
            state.canImport = document.body.offsetWidth > 1024;
            if (state.canImport !== prevState) {
                m.redraw();
            }
        });
    },

    view: ({state}) => {
        const toolInterface = appModel.toolbox.toolInterface;
        if (!toolInterface) {
            return null;
        }

        const tool = appModel.toolbox.tool,
            coordinates = toolInterface.feature.geometry.coordinates,
            hasCoordinates = coordinates.length,
            isFeatureReady = screenHelper.small() && (toolInterface.type === 'polyline' && coordinates.length > 2 || toolInterface.type === 'polygon' && coordinates.length > 3),
            Prompt = toolInterface.prompt;
        return <div>
            <div class="tool-launcher">
                <div class="asset-title-wrap">
                    <img class="asset-icon" src={`${constants.staticMediaURL}${tool.assetForm.assetType.attributes.icon.mediaId}`} />
                    <div class="asset-title">{tool.name}</div>
                    <div class="btn btn-smallest btn-secondary btn-pill asset-cancel" onclick={() => toolInterface.close()}>
                        {importModel.importInProgress ?
                            <span className="btn-label">Close</span> :
                            <span className="btn-label">Cancel</span>
                        }
                    </div>
                </div>
                <div class="tool-launcher-prompt">
                    {appModel.toolbox.importMode
                        ? <ImportHelp tool={tool} />
                        : <>
                            <div class="prompt-text"><Prompt /></div>
                            {toolInterface.done && !hasCoordinates && !toolInterface.requireFeature &&
                                <div class="btn btn-smaller btn-secondary btn-pill" onclick={() => toolInterface.done()}>
                                    <span class="btn-label">Skip Mapping</span>
                                </div>}
                            {toolInterface.done && isFeatureReady &&
                                <div class="btn btn-smaller btn-secondary btn-pill" onclick={stopPropagation(() => toolInterface.completeFeature())}>
                                    <span class="btn-label">Done Drawing</span>
                                </div>}
                        </>}
                </div>
                {!appModel.toolbox.importMode && state.canImport && appModel.toolbox.showImportPrompt &&
                <div className="btn btn-smaller tool-import-btn" onclick={() => {
                    state.isImporting = !state.isImporting;
                }}>
                    <span className="btn-label">Or Add From Import</span>
                    <i className={`icon-${state.isImporting ? 'up' : 'down'} right`}/>
                </div>} 
                {state.isImporting &&
                    <div className= "import-tool-margin">
                        <ImportHelp tool={tool} />
                    </div>}
            </div>
        </div>;

    }

};

export default ToolLauncher;
