import planModel from 'models/plan-model';
import PlanHeader from './plan-header';
import Stakeable from 'views/stakeable/stakeable';
import Review from 'views/plan/review/review';
import Crop from 'views/plan/crop/crop';

/**
 * Plan staking view.
 */
const Plan = {

    oncreate: () => {
        planModel.init();
    },

    onremove: () => {
        planModel.onRemove();
    },

    view: () => {
        return <div id='stakeable-view' class='stakeable plan'>
            { planModel.isCropping && <Crop /> }
            { planModel.isReviewing && <Review /> }
            { !planModel.isCropping && !planModel.isReviewing && <PlanHeader /> }
            <Stakeable />
        </div>;
    }

};

export default Plan;
