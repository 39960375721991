import toolboxSelector from 'views/toolbox-selector';
import SearchBox from 'views/search-box/search-box';
import appModel from 'models/app-model';
import capitalize from 'util/data/capitalize';

const ProjectPrompt = {
    checkToolboxes(state, toolboxes) {
        const toolInterface = appModel.toolbox.toolInterface,
            toolName = toolInterface.tool && toolInterface.tool.name;
        let matchingToolbox;
        if (state.presetToolboxId) {
            matchingToolbox = toolboxes.find(toolbox => toolbox.base && toolbox.base.toolboxId === state.presetToolboxId || toolbox.toolboxId === state.presetToolboxId);
            if (matchingToolbox) {
                toolInterface.setToolboxId(matchingToolbox.toolboxId);
            }
        }
        if (!matchingToolbox) {
            matchingToolbox = toolboxes.find(toolbox => toolbox.name === toolName);
            if (matchingToolbox) {
                toolInterface.toolboxId = matchingToolbox.toolboxId;
            } else {
                toolInterface.toolboxId = toolboxes[0].toolboxId;
            }
            m.redraw();
        }
    },
    oninit: ({state}) => {
        state.siteTermCapitalized = capitalize(appModel.toolbox.siteTermSingular);

        state.presetToolboxId = appModel.toolbox.toolInterface.tool.featureTypes[0].attributes.toolbox;
        if (toolboxSelector.accountToolboxes) {
            ProjectPrompt.checkToolboxes(state, toolboxSelector.accountToolboxes);
        } else {
            toolboxSelector.fetchAccountToolboxes().then(() => ProjectPrompt.checkToolboxes(state, toolboxSelector.accountToolboxes));
        }
        appModel.toolbox.toolInterface.initGoogle();
    },

    view: ({state: {presetToolboxId, siteTermCapitalized}}) => {
        const toolboxes = toolboxSelector.accountToolboxes;
        const toolInterface = appModel.toolbox.toolInterface;
        return <>
            {!presetToolboxId && <>
                <div class="project-prompt-header">Select a Toolkit for this {siteTermCapitalized}</div>
                {toolboxes
                    ? <div class="select-wrapper project-prompt">
                        <select onchange={e => toolInterface.setToolboxId(e.target.value)} value={toolInterface.toolboxId}>
                            <option value="default" disabled>Select Toolkit</option>
                            {toolboxes.map(toolbox => <option value={toolbox.toolboxId}>{toolbox.name}</option>)}
                        </select>
                    </div>
                    : <div class="select-placeholder project-prompt"><i class="gray spinner spinning left" /> Loading Toolkits</div>
                }
            </>}
            <div class="project-prompt-header">Where's your new {siteTermCapitalized}?</div>
            <div class="project-prompt-search"><SearchBox onSuggest={toolInterface.suggest.bind(toolInterface)} placeholder="Enter an address, city, lat/long…" /></div>
            <div class="project-prompt-tip">We’ll create a polygon based on your input to get your {siteTermCapitalized} on the map. You can always edit it later.</div>
            <div class="project-prompt-divider"><span class="or-circle">OR</span></div>
            <div class="project-prompt-header">Locate manually</div>
            <div class="project-prompt-tip tip-has-icon"><i class="icon-add-to-map" />Click or tap on the map to define the bounds of your new {siteTermCapitalized}. Any size or shape works.</div>
        </>;
    }
};

export default ProjectPrompt;

