import TypeXids from 'util/network/request-batches/_lists/type-xids';
import initializer from 'util/initializer';
import filterUtil from 'util/table/filter-util';

const BATCH_WAIT_TIME = 500;
const MAX_BATCH_SIZE = 100;
const ON_PAUSE = true;

class RequestBatches {

    constructor() {
        initializer.add(() => requestBatches.init(), 'requestBatches');
    }

    init() {
        this.timers = {
            listContent: undefined,
            listMedia: undefined
        };
        this.batches = {
            listContent: new TypeXids('list', 'content'),
            listMedia: new TypeXids('list', 'media')
        };
        this._assetTypeIdIn = null;
    }

    get assetTypeIdIn() {
        if (this._assetTypeIdIn === null) {
            this._assetTypeIdIn = filterUtil.getAssetTypeIdInDefault();
        }
        return this._assetTypeIdIn;
    }

    clearFromBatch(type, xid, result) {
        this.batches[type].handleResponse(xid, result);        
    }

    setBatchWaitTimerFor(type) {
        if (!this.timers[type]) {
            this.timers[type] = setTimeout(() => {
                this.send(type);
            }, BATCH_WAIT_TIME);
        }
    }

    add(type, xid, onResponse, onInvalid) {
        this.setBatchWaitTimerFor(type);
        this.batches[type].add(xid, onResponse, onInvalid);

        if (Object.values(this.batches[type].xids).length >= MAX_BATCH_SIZE) {
            this.send(type);
        }
    }

    send(type) {
        clearTimeout(this.timers[type]);
        this.timers[type] = undefined;
        return this.batches[type].sendRequest();
    }

    pauseTimer(type) {
        if (this.timers[type]) {
            clearTimeout(this.timers[type]);
        }
        this.timers[type] = ON_PAUSE;
    }

    unpauseTimer(type) {
        this.timers[type] = undefined;
    }

}

const requestBatches = new RequestBatches();

export default requestBatches;
