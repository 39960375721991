import {datadogRum} from '@datadog/browser-rum';
import constants from 'util/data/constants';
import {datadogLogs} from '@datadog/browser-logs';

const clientToken = 'pub62e4f433fb198720fba480562ef91ac1';

class LogManager {

    init() {
        if (constants.isDeployed) {
            const env = {
                development: 'dev',
                staging: 'staging',
                production: 'prod'
            }[constants.awsProfile];

            datadogLogs.init({
                clientToken: clientToken,
                site: 'datadoghq.com',
                env: env.awsProfile,
                service: 'unearth_web', 
                forwardErrorsToLogs: false, // If this is set to true, all console errors will go to datadog
                sessionSampleRate: 100
            });

            datadogRum.init({
                applicationId: 'a883bf60-1f1f-49f0-bee9-f3a2e8f274b1',
                clientToken,
                site: 'datadoghq.com',
                service: 'OnePlace',
                env,
                version: constants.release,
                sampleRate: 100,
                trackInteractions: true,
                beforeSend: e => {
                    // Ignore all network errors related to loading tiles
                    const resource = e.resource || e.error && e.error.resource;
                    if (resource && resource.url.startsWith('https://api.mapbox.com/styles'
                    || resource.url.startsWith('https://tiles.unearthlabs.com/'))) {
                        return false;
                    }

                }
            });
        }
    }
}

export default new LogManager();
