import appModel from 'models/app-model';
import SearchBox from 'views/search-box/search-box';
import tableModel from 'models/table/table-model';
import siteModel from 'models/site-model';
import TableRow from 'views/table/table-row';
import pointMenu from 'legacy/components/point-menu/point-menu';
import SidebarToggle from 'views/sidebar-toggle/sidebar-toggle';
import addCommas from 'util/numbers/add-commas';
import popoverModel from 'models/popover-model';
import sideNavModel from 'models/side-nav-model';
import LayoutControl from 'views/table/layout-control';
import capitalize from 'util/data/capitalize';
import store from 'util/data/store';
import filterConstants from 'constants/models/table/filter-constants';
import tableConstants from 'constants/models/table/table-constants';
import BoundaryMenuButton from 'views/table/boundary-menu-button';
import SavedViewButton from 'views/table/saved-view-button';
import savedViewsModel from 'models/saved-views-model';
import batchSelectModel from 'models/batch-select-model';
import popup from 'util/popup';
import batchModifyModel from 'models/batch-modify-model';
import OptionsPopup from 'views/options-popup/options-popup';
import AssetOptionsModel from 'models/asset/asset-options-model';
import PermissionsIndicator from 'views/permissions/permissions-indicator/permissions-indicator';
import userManager from 'managers/user-manager';
import ControlLabel from 'views/control-label/control-label';

class Table {

    constructor() {
        this.searchBox = new SearchBox();
    }

    oninit() {
        appModel.setProjectView(tableModel.projectView);
        tableModel.initTableMode();
    }

    oncreate() {
        tableModel.rememberScroll();
        tableModel.editingAllowed = tableModel.tableMode === 'table-bottom' || tableModel.tableMode === 'table-full';
        tableModel.checkScrollArrows();
    }

    onremove() {
        savedViewsModel.close();
        popoverModel.close();
        sideNavModel.close();
        tableModel.editingAllowed = false;
        tableModel.activeCell.exitTable();
    }

    renderState(width) {
        const assetCount = tableModel.assetIds.length;
        if (assetCount) {
            return this.rowsState(width);
        } else if (tableModel.loadingTable) {
            return this.loadingState(width);
        } else if (tableModel.loadingFresh) {
            return this.filteredLoadingState(width);
        } else if (assetCount === 0 && tableModel.totalAssetCount === 0) {
            return appModel.project.isMetaProject ? this.emptyPortfolioState(width) : this.emptyState(width);
        } else if (assetCount === 0 && tableModel.totalAssetCount > 0) {
            return this.filteredState(width);
        }
        return this.filteredLoadingState(width);
    }

    /**
     *  This is the state when we have filters selected, loading is done and no assets.
     */
    filteredState(width) {
        return <div class="table-placeholder" style={tableModel.tableMode !== 'list-left' ? `width:${width}px;` : ''}>
            <div class="placeholder-message">
                <img src="/images/no-results.png" class="placeholder-image" />
                <div class="placeholder-headline">No Results</div>
                <div class="placeholder-subtext">Try picking a different filter, or <a onclick={() => tableModel.renderWithDefaultFilters()} class="no-results-reset">reset all filters</a> to start fresh.</div>
            </div>
        </div>;
    }

    /**
     *  This is the loading state without filters.
     *  This will be the initial load and if you deselect all filters.
     */
    loadingState(width) {
        return <div class="table-placeholder" style={tableModel.tableMode !== 'list-left' ? `width:${width}px;` : ''}><i class="loader-v2 block border" />
            <div class="placeholder-message">
                <img src="/images/loading-assets.png" class="placeholder-image" />
                <div class="placeholder-headline">Loading Data</div>
                <div class="placeholder-subtext">Hang tight - it should only take a moment.</div>
            </div>
        </div>;
    }

    /**
     *  This is the loading state with filters.
     */
    filteredLoadingState(width) {
        return <div class="table-placeholder" style={tableModel.tableMode !== 'list-left' ? `width:${width}px;` : ''}><i class="loader-v2 block border" />
            <div class="placeholder-message">
                <img src="/images/updating-filters.png" class="placeholder-image" />
                <div class="placeholder-headline">Updating Filters</div>
                <div class="placeholder-subtext">We'll be right back with your results.</div>
            </div>
        </div>;
    }

    /**
     *  This is the state when we have no filters selected and no assets.
     */
    emptyState(width) {
        return <div class="table-placeholder" style={tableModel.tableMode !== 'list-left' ? `width:${width}px;` : ''}>
            <div class="placeholder-message"><img src="/images/no-content-in-project.png" class="placeholder-image" />
                <div class="placeholder-headline">Nothing here just yet. </div>
                <div class="placeholder-subtext">To add to this {capitalize(appModel.toolbox.siteTermSingular)}, tap + in the navigation bar.</div>
            </div>
        </div>;
    }

    /**
     *  This is the first look at the portfolio level.
     */
    emptyPortfolioState(width) {
        return <div class="table-placeholder" style={tableModel.tableMode !== 'list-left' ? `width:${width}px;` : ''}>
            <div class="placeholder-message"><img src="/images/no-projects.png" class="placeholder-image"/>
                <div class="placeholder-headline">No {capitalize(appModel.toolbox.siteTermPlural)} in your Portfolio just yet.</div>
                <div class="placeholder-subtext">{capitalize(appModel.toolbox.siteTermPlural)} you create or you are added to will appear here.</div>
            </div>
        </div>;
    }

    /**
     * This is the state when the table has assets to render.
     */
    rowsState(width) {
        const className = `asset-rows${tableModel.loadingFresh ? ' outgoing-rows' : ''}`;
        return <div className={className} style={tableModel.tableMode !== 'list-left' ? `width:${width}px;` : ''}>
            {tableModel.assetIds.map((assetId, i) => {
                if (i >= tableModel.loadRange.startRow && i <= tableModel.loadRange.endRow) {
                    return <TableRow key={assetId + i} assetId={assetId} index={i} />;
                }
                return <div key={assetId + i} className='mock-table-row' />;
            })}
        </div>;
    }

    view() {
        const tableCssClass = `asset-table ${tableModel.tableMode}${tableModel.transition}${tableModel.isCollapsed ? ' collapsed' : ''}${tableModel.loadingTable ? ' loading' : ''}${tableModel.inEditMode ? ' edit-mode' : ' view-mode'}${tableModel.shouldDisplayCSVImport() ? ' show-table-footer' : ''}`;
        const tableHeaderSections = tableModel.getVisibleTableHeaderSections();
        const styles = tableModel.styles;
        const commonHeaderStyles = styles.allCommonColumnHeaderStyles;
        const assetCount = Math.max(0, Math.min(tableModel.assetCount, tableModel.totalAssetCount));
        const viewKeys = filterConstants.nameToViewKey;
        const AssetSearchBox = this.searchBox;
        const configToText = savedViewsModel.configToText(tableModel.projectView);
        const filterCount = configToText ? configToText.propsFilteredCount : 0;
        return <div>
            <div class={tableCssClass} onclick={() => {
                if (popup.isOpen()) {
                    popup.remove();
                    return;
                }
            }}>
                <SidebarToggle onclick={() => tableModel.sideBarToggle()} />
                <div className="asset-table-fill">
                    <div className="search-box-wrap">
                        <AssetSearchBox value={tableModel.projectView.filters.searchString} htmlId="table-asset-search"
                            loadingStateClass={tableModel.loadingTable ? 'disable loading-table' : ''}
                            label={appModel.project.isMetaProject ? 'Portfolio' : `Current ${capitalize(appModel.toolbox.siteTermSingular)}`}
                            placeholder={siteModel.name}
                            searchInputWrapCssClass={appModel.project.isMetaProject ? 'UFtracker-search-portfolio' : 'UFtracker-search-project'}
                            focusedPlaceholder='Term, Value, or Address'
                            focusedLabel={`Search ${siteModel.name} or Map`}
                            onSearch={q => tableModel.projectView.filters.search(q)} onSuggest={q => tableModel.suggest(q)} sync={tableModel.projectView.filters.isOpen || tableModel.loadingFresh} includeGeoSearch={true} />
                    </div>
                    <div class="table-config-btns">
                        <div className="config-menu-control">
                            <div onclick={function () {
                                tableModel.projectView.filters.open(this.parentElement, {viewKey: 'configMenu'});
                            }}>
                                <div class="config-menu-tab">
                                    <span class="config-menu-tab-btn UFtracker-properties-menu"><i className='icon-properties-table'/></span>
                                    <span className="config-menu-tab-label">Properties</span>
                                </div>
                            </div>
                        </div>
                        <BoundaryMenuButton/>
                        <SavedViewButton/>
                    </div>
                    {tableModel.loadingFresh ?
                        <div class="results-count"><i class="loader-v2 small" /></div> :
                        <div class="results-count"><span class="results-count-text">{`${addCommas(assetCount) || 0} of ${addCommas(tableModel.totalAssetCount) || 0}`}</span>
                            <div class="reset-link">
                                {assetCount !== tableModel.totalAssetCount && <span class="reset-link-inner UFtracker-clear-filters" onclick={() => tableModel.renderWithDefaultFilters()}><i class="icon-configure" /> <span class="btn-label">{`Clear ${filterCount} Filter(s)`}</span></span>}
                            </div>
                        </div>}
                    <LayoutControl/>
                    {!userManager.isViewOnly &&
                        <div className='selection-buttons'>
                            <div
                                class={`${batchSelectModel.areNoAssetsSelected() ? 'selection-summary none-selected-tip' : 'selection-summary deselect-all-button UFtracker-deselect-all'}`}
                                onclick={() => {
                                    batchSelectModel.deselectAllAssets();
                                }}>{batchSelectModel.areNoAssetsSelected() ? '0 selected' : `Deselect All (${Object.keys(batchSelectModel.selectedAssets).length})`}
                            </div>
                            <div className={`action-buttons ${tableModel.shouldDisplayCSVImport() ? 'csv-edit' : ''}`}>
                                {tableModel.tableMode !== 'list-left' &&
                                    <span
                                        className={`btn btn-pill edit-button UFtracker-edit-mode-in-table ${tableModel.inEditMode ? ' edit-mode btn-primary' : ' view-mode btn-secondary'}`}
                                        onclick={(e) => {
                                            e.stopPropagation();
                                            tableModel.toggleEditMode(!tableModel.inEditMode);
                                        }}>
                                        <i className={`${tableModel.inEditMode ? ' ' : 'icon-edit'}`}/> <span
                                            className={`btn-label ${tableModel.inEditMode ? 'exit-editing-button-label ' : ''}`}>{tableModel.inEditMode ? 'Exit Editing' : 'Edit'}</span>
                                    </span>
                                }
                                {Object.keys(batchSelectModel.selectedAssets).length === 0 ?
                                    <span className={'btn btn-pill delete-button btn-primary disabled'}> <i
                                        className='icon-trash trash-icon'/> Delete</span>
                                    : <>
                                        <span
                                            className={`btn btn-pill delete-button UFtracker-delete-selected ${Object.keys(batchSelectModel.assetsToDelete).length === 0 ? 'btn-primary disabled no-permission' : 'btn-secondary'}`}
                                            onclick={(e) => {
                                                e.stopPropagation();
                                                batchSelectModel.deleteAssets();
                                            }}><i className='icon-trash trash-icon'/> Delete</span>
                                        <PermissionsIndicator lockedMessage={AssetOptionsModel.deleteBatchMessage()}
                                            cssWrap={'no-permission'} iconCss={'button-permission'}/>
                                    </>
                                }

                                {tableModel.shouldDisplayCSVImport() &&
                                    <OptionsPopup options={[{
                                        label: 'Update from CSV',
                                        cssWrapClass: 'UFtracker-update-from-csv',
                                        icon: 'icon-file',
                                        callback: () => batchModifyModel.start()
                                    }]}
                                    isBatch={true}
                                    />}
                            </div>
                        </div>
                    }
                    <div className={`asset-table-header ${userManager.isViewOnly ? 'view-only' : ''}`}>
                        <div className={tableConstants.COLUMN_GROUP_HEADERS}>
                            <div className="column-group-span" style={`width: ${tableModel.getWidthCommon()}px`}><span>Common Properties</span></div>
                            {tableHeaderSections.map(({assetType, count}) => {
                                return <div className="column-group-span" style={`width:${tableConstants.COL_WIDTH * count}px`}><i className="toggle-switch toggle-is-on" onclick={function () {
                                    tableModel.hideAssetType(assetType, true);
                                }} /><span className="span-label">{assetType.name} Properties</span></div>;
                            })}
                        </div>
                        <div className="asset-rows-header" onscroll={e => popoverModel.close(e)}>
                            <div className={`${commonHeaderStyles.name} asset-primary-label`}>
                                <div className="primary-label-wrap" onclick={function () {
                                    tableModel.projectView.filters.open(this.parentElement, {viewKey: viewKeys.name});
                                }}>
                                    <span className="row-number" /><span className="property-label">Name</span><i className="icon-configure UFtracker-filterby-name" />
                                </div>

                                <span className="map-status">
                                    <i className="icon-map-square" />
                                    <span className="map-status-label">Map</span>
                                </span>
                                <span className="asset-options-menu">
                                    <i className="icon-ellipsis" />
                                </span>
                            </div>
                            <ul className="asset-properties properties-list">
                                {tableModel.visibleHeaders.unearthId && <li className={`${commonHeaderStyles.unearthId} asset-id UFtracker-filterby-UEID`} onclick={function () {
                                    tableModel.projectView.filters.open(this, {viewKey: viewKeys.unearthId});
                                }}> <span className="property-label">Unearth ID</span><i className="icon-configure" /></li>}
                                {tableModel.visibleHeaders.category && <li className={`${commonHeaderStyles.category} asset-category UFtracker-filterby-category`} onclick={function () {
                                    tableModel.projectView.filters.open(this, {viewKey: viewKeys.category});
                                }}><span className="property-label">Category</span><i className="icon-configure" /></li>}
                                {tableModel.visibleHeaders.type && <li className={`${commonHeaderStyles.type} asset-type UFtracker-filterby-type`} onclick={function () {
                                    tableModel.projectView.filters.open(this, {viewKey: viewKeys.contentType});
                                }}><span className='property-label'>Type</span><i className="icon-configure" /></li>}
                                {tableModel.visibleHeaders.addedDateTime && <li className={`${commonHeaderStyles.addedDateTime} asset-added UFtracker-filterby-addeddatetime`} onclick={function () {
                                    tableModel.projectView.filters.open(this, {viewKey: viewKeys.createdDateTime});
                                }}><span className="property-label">Added Date/Time</span><i className="icon-configure" /></li>}
                                {tableModel.visibleHeaders.addedBy && <li className={`${commonHeaderStyles.addedBy} asset-author UFtracker-filterby-addedby`} onclick={function () {
                                    tableModel.projectView.filters.open(this, {viewKey: viewKeys.addedBy});
                                }}><span className='property-label'>Added By</span><i className="icon-configure" /></li>}
                                {tableModel.visibleHeaders.lastUpdated && <li className={`${commonHeaderStyles.lastUpdated} asset-added UFtracker-filterby-lastupdatedatetime`} onclick={function () {
                                    tableModel.projectView.filters.open(this, {viewKey: viewKeys.updatedDateTime});
                                }}><span className="property-label">Last Updated</span><i className="icon-configure" /></li>}
                                {tableModel.visibleHeaders.lastUpdatedBy && <li className={`${commonHeaderStyles.lastUpdatedBy} asset-modifier UFtracker-filterby-lastupdatedby`} onclick={function () {
                                    tableModel.projectView.filters.open(this, {viewKey: viewKeys.lastUpdatedBy});
                                }}><span className='property-label'>Last Updated By</span><i className="icon-configure" /></li>}
                                {/* TODO: Activate the links count filter and column once stale DB links are cleaned up UE-2025 */}
                                {/* {tableModel.visibleHeaders.links && <li className={`${commonHeaderStyles.links} asset-links`}><span className="property-label">Links</span></li>} */}
                                {tableModel.getVisibleSharedColumnHeaders().map(sharedColumnName => {
                                    const sharedColumn = tableModel.getSharedColumn(sharedColumnName);
                                    const sharedHeaderStyles = styles.getSharedColumnHeaderStyles(sharedColumnName);
                                    if (tableModel.projectView.filters.hasFilterMenu(sharedColumn.controlType.controlTypeId)) {
                                        return <li className={`${sharedHeaderStyles} type-specific shared-property UFtracker-filterby-combined-property`} onclick={function () {
                                            tableModel.projectView.filters.open(this, {
                                                sharedColumnName,
                                                viewKey: sharedColumn.controlType.controlTypeId,
                                                assetTypeIds: sharedColumn.assetTypeIds,
                                                controlType: sharedColumn.controlType,
                                                assetType: store.assetTypes[sharedColumn.assetTypeIds[0]]
                                            });
                                        }}><span className="property-label">{sharedColumn.name}</span><i className="icon-configure" /></li>;
                                    }
                                    return <li className="type-specific">
                                        <span className="property-label">{sharedColumn.name}</span>
                                    </li>;
                                })}
                                {tableModel.getVisibleTableHeaders().map(({controlType, assetType}) => {
                                    const assetTypeId = assetType.assetTypeId;
                                    if (tableModel.projectView.filters.hasFilterMenu(controlType.controlTypeId)) {
                                        const typeSpecificStyles = styles.getTypeSpecificHeaderStyles(assetTypeId, controlType.fieldName);
                                        return <li className={`${typeSpecificStyles} asset-type type-specific UFtracker-filterby-typespecific-property`}
                                            onclick={function () {
                                                tableModel.projectView.filters.open(this, {
                                                    controlType,
                                                    assetType,
                                                    viewKey: controlType.controlTypeId
                                                });
                                            }}>
                                            <span className="property-label"><ControlLabel control={controlType} noElementTag={true}/></span>
                                            <i className="icon-configure" />
                                        </li>;
                                    }
                                    return <li className="asset-type type-specific">
                                        <span className="property-label"><ControlLabel control={controlType} noElementTag={true}/></span>
                                    </li>;
                                })}
                            </ul>
                        </div>
                    </div>
                    <div class={`y-scroll-container ${userManager.isViewOnly ? 'view-only' : ''}`} onscroll={(e) => {
                        popoverModel.close();
                        pointMenu.close();
                        tableModel.onVerticalScroll(e);
                    }}>
                        <div class={tableConstants.X_SCROLL} onscroll={(e) => {
                            popoverModel.close();
                            pointMenu.close();
                            tableModel.onHorizontalScroll(e);
                        }}>
                            <div class={`scroll-right UFtracker-scroll-table-left ${!tableModel.hideArrows && tableModel.horizontalScrollPercent >= 99 ? ' hidden' : ''}`} onclick={() => tableModel.scrollRight()}><i class="icon-arrow-right" /></div>
                            <div class={`scroll-left UFtracker-scroll-table-left ${!tableModel.hideArrows && tableModel.horizontalScrollPercent <= 0 ? ' hidden' : ''}`} onclick={() => tableModel.scrollLeft()}><i class="icon-arrow-left" /></div>
                            {this.renderState(tableModel.width)}
                        </div>
                        <div />
                        <div class="add-assets-prompt"><span class="plus-sign">+</span>Tap the + button to see the Types of data you can add here.</div>
                        <div class="add-assets-prompt is-portfolio"><span class="plus-sign">+</span>Tap the + button to see the Types of Projects you can add to your Portfolio.</div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default Table;
