const ToastMessage = {

    onclose: null,
    content: '',
    isOpen: false,

    show: (content, cssClass, persist, _onclose, timeoutTick = 8000) => { // eslint-disable-line
        onclose = _onclose;
        ToastMessage.isOpen = true;
        ToastMessage.content = content;
        ToastMessage.cssClass = cssClass || 'success';
    
        if (ToastMessage.timeout) {
            clearTimeout(ToastMessage.timeout);
            ToastMessage.timeout = null;
        }
    
        if (!persist) {
            ToastMessage.timeout = setTimeout(ToastMessage.close, timeoutTick);
        }

        m.redraw();
    },

    hide: () => {
        ToastMessage.isOpen = false;
        ToastMessage.content = '';
        if (ToastMessage.timeout) {
            clearTimeout(ToastMessage.timeout);
            ToastMessage.timeout = null;
        }
        ToastMessage.onclose = null;

        m.redraw();
    },

    close: () => {
        if (ToastMessage.timeout) {
            clearTimeout(ToastMessage.timeout);
            ToastMessage.timeout = null;
        }

        ToastMessage.isOpen = false;
        ToastMessage.content = '';
    
        if (ToastMessage.onclose) {
            ToastMessage.onclose();
        }
    
        ToastMessage.onclose = null;

        m.redraw();
    
    },
    
    view: () => {
        const style = ToastMessage.isOpen ? 'display: block' : 'display: none';
        return <div class={`message ${ToastMessage.cssClass}${ToastMessage.isOpen ? ' active ' : ' '}`} style={style}>
            <span>{ToastMessage.content}</span>
            <a class="icon-close" onclick={() => ToastMessage.close()}></a>
        </div>;
    }
};

export default ToastMessage;
