import onBodyClick from 'legacy/util/dom/on-body-click';
import tip from 'views/tip';

const LockableToggleSwitch = {                
    view: ({attrs: {isToggled, handleClick, cssWrapClass, lockedMessage}}) => {
        const outerWrapClasses = `permission-toggle ${isToggled ? 'toggled-on' : 'toggled-off'} ${lockedMessage ? 'editing-locked tool-tip' : 'editing-open'} ${cssWrapClass || ''}`;
        return <div onclick={lockedMessage ? null : () => handleClick()}
            class={outerWrapClasses}
            onmouseenter={lockedMessage ? function() {
                tip.show(this, {tipText: lockedMessage});
                onBodyClick.once(() => tip.hide());
            } : null}
            onmouseleave={() => tip.hide()}>
            <div class="toggle toggle-on">
                <i class={isToggled || !lockedMessage ? 'icon-permission-yes' : 'icon-locked'}/>
            </div>
            <div class="toggle toggle-off">
                <i class={!isToggled || !lockedMessage ? 'icon-permission-no' : 'icon-locked'}/>
            </div>
        </div>;
    }
};

export default LockableToggleSwitch;
