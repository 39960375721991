import modalModel from 'models/modal-model';
import importModel from 'models/import-model';

const CancelImport = {
    view: () => <div class="modal-wrap cancel-import">
        <div class="dialog-head">Exit Add from Import?</div>
        <div class="dialog-text">All work to create {importModel.tool.name} Assets from {importModel.media.label} will be lost and cannot be recovered.</div>
        <div class="dialog-btns">
            <div class="btn btn-pill btn-secondary" onclick={modalModel.close}>Cancel</div>
            <div class="btn btn-pill btn-red" onclick={importModel.done}>Exit</div>
        </div>
    </div>
};

export default CancelImport;
