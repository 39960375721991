import adaptiveRound from 'util/numbers/adaptive-round';

// data = [{
//     yLabel: String,
//     xLabel: String,
//     color: String,
//     points: [{
//         x: Number,
//         y: Number
//     }]
// }];
const DataTable = {
    view: ({attrs: {data, xFormat = adaptiveRound, yFormat = adaptiveRound}}) => {
        const firstSeries = data[0];
        return <table class="table">
            <thead>
                <tr>
                    <td class="td">{firstSeries && firstSeries.xLabel}</td>
                    {data.map(series => <td key={series.yLabel} class="td" style={`color: ${series.color}`}>{series.yLabel}</td>)}</tr>
            </thead>
            <tbody> {firstSeries && firstSeries.points.map((point, i) => <tr>
                <td class="td">{xFormat(point.x)}</td>
                {data.map(series => {
                    return <td class="td">
                        {yFormat(series.points[i].y)}
                    </td>;
                })}
            </tr>)
            }
            </tbody>
        </table>;
    }
};

export default DataTable;
