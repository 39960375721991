import assetListManager from 'managers/asset-list-manager';

const AssetIcon = {
    oninit: ({state}) => {
        state.reloadedAsStatic = false;
    
        state.onerror = () => {
            if (!state.reloadAsStatic) { // Only attempt to reload w/static url once.
                state.reloadAsStatic = true;
                m.redraw();
            }
        };
    },

    view: ({state, attrs: {assetId, isImage}}) => {
        const iconUrl = state.reloadAsStatic ? assetListManager.getStaticIconUrl(assetId) : assetListManager.getIconUrl(assetId);
        return <span class={`asset-icon-outer-wrap has-${isImage ? 'image' : 'icon'}`}>
            <img onerror={state.onerror} class={`asset-icon${isImage ? ' asset-thumbnail' : ''}`} src={iconUrl} />
        </span>;
    }
};

export default AssetIcon;
