import element from 'util/dom/element';
import {Marker} from 'mapbox-gl';

class StakeStatic extends Marker {

    /**
     * Generates the pin element via mapbox constructor
     * and sets up defaults via instance methods.
     */
    constructor(opts) {
        super({
            element: element(['<div class="stake"></div>']), 
            anchor: 'bottom'
        });
        this.setLngLat(opts.coords)
            .setDraggable(true)
            .addTo(opts.map);
        this.render(opts.stakeNumber);
        this.position = opts.stakeNumber;
        this.originCoords = opts.coords;
    }

    /**
     * Renders the stake with the number displaying per the index position of the parent.
     */
    render(stakeNumber) {
        this.getElement().innerHTML = 
            `<div class="stake-inner">
                <span class="stake-number">${stakeNumber}</span>
             </div>`;
    }


}       

export default StakeStatic;

