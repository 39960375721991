import store from 'util/data/store';
import controlToFeature from 'util/interfaces/control-to-feature';
import PlaceSearch from 'views/place-search/place-search';
import tableModel from 'models/table/table-model';
import placeModel from 'models/place-model';

const updateAssetFeatures = (control, assetId) => controlToFeature.updateAssetFeatures(control, assetId);

export default function(control, assetId, _opts = {}) {
    const isPopupMenu = _opts.isPopupMenu;
    return <div class="place-control">
        <PlaceSearch
            openUp={isPopupMenu && tableModel.tableMode === 'table-bottom'}
            selectedItems={store.assets[assetId].properties[control.fieldName]
            && store.assets[assetId].properties[control.fieldName].placeIds || []}
            control={control}
            onSelect={(place, opts) => {
                placeModel.addPlaceToFormControl(place, assetId, opts.control.fieldName);
                updateAssetFeatures(control, assetId).then(() => {
                    placeModel.showPlacePopups([place.placeId]);
                });
                placeModel.panToPlace(place, false);
            }}
            onDelete={(place, opts) => {
                placeModel.removePlaceToFormControl(place, assetId, opts.control.fieldName);
                updateAssetFeatures(control, assetId);
                m.redraw();
            }}
        />
    </div>;
}
