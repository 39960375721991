import validation from 'util/data/validation';

class AuthFieldModel {
    static init(type) {
        const state = {};
        state.validate = validation[type];
        state.isValidated = false;
        if (state.validate) {
            state.match = (value) => !value.match(state.validate.regex);
        }
        return state;
    }

    static blur(attrs, state, e) {
        if (attrs.onBlur) {
            attrs.onBlur(e);
        }
        return AuthFieldModel.checkMatch(attrs, state, e.target.value);
    }

    static checkMatch({matchValue, matchError}, {isValidated, match}, value) {
        let errorMsg;
        if (matchValue && matchValue !== value) {
            isValidated = false;
            errorMsg = matchError;
        } else if (matchValue && matchValue === value && isValidated === false) {
            isValidated = !match(value);
        }
        return {isValidated, errorMsg};
    }

    static input({onInput}, {validate, match, isValidated}, e) {
        if (validate) {
            isValidated = !match(e.target.value);
        }
        if (onInput) {
            onInput(e, isValidated);
        }
        return {isValidated, value: e.target.value};
    }
}

export default AuthFieldModel;
