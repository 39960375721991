import appModel from 'models/app-model';
import LinksModel from 'models/links-model';
import tableModel from 'models/table/table-model';
import pointMenu from 'legacy/components/point-menu';
import formModel from 'models/form-model';
import constants from 'util/data/constants';
import store from 'util/data/store';
import router from 'uav-router';
import TableRow from 'views/table/table-row';
import libraryModel from 'models/library-model';
import popupModel from 'models/popover-model';
import TableRowRestricted from 'views/table/table-row-restricted';
import FilterMenus from 'views/table/filter-menu/filter-menus';
import savedViewsModel from 'models/saved-views-model';

let linksModel;

// Displays the named control this link is associated with
const NamedLinkLabel = {
    view: ({attrs: {assetId}}) => <div class="link-labels">{linksModel.namedLinks[assetId].map(name => <span class="link-label"><i class="icon-asset-form" />{name}</span>)}</div>
};

const Links = {

    oninit({state}) {
        libraryModel.quit();

        linksModel = formModel.linksModel = new LinksModel();
        const asset = store.assets[formModel.assetId],
            assetFormId = store.assetTypeToFormId[asset.assetTypeId],
            assetForm = store.assetForms[assetFormId],
            linksControlTypeId = constants.controlTypeNameToId.links,
            linkControl = assetForm.controls.find(c => c.controlTypeId === linksControlTypeId);

        state.upload = () => linksModel.uploadOrSelectToLink(linkControl);

        if (appModel.user.isInvited) {

            state.actionUI = <p style="text-align: center;">
                <a class="btn btn-primary btn-pill btn-small" onclick={() => router.merge({signup: 'fromguest'})}>
                    Sign up to add links
                </a>
            </p>;

        } else if (!appModel.user.isContentEditable(formModel.assetId)) {
            state.actionUI = <div class="links-tab-title-read-only"></div>;
        } else {
            state.actionUI = <div class="links-control-input" onclick={state.upload}>
                <span class="link-control-tip">Link other assets or media to this {asset.contentType}</span> <span class="btn btn-pill btn-secondary btn-smaller"><span class="btn-label"><i class="icon-plus" /><i class="icon-link icon" /></span></span>
            </div>;
        }

        state.onScroll = e => {
            e.redraw = false;
            popupModel.close();
            pointMenu.onScroll();
            linksModel.onscroll(e);
        };

        tableModel.resetCache();
    },

    onremove() {
        formModel.linksModel = undefined;
        appModel.setProjectView(tableModel.projectView);
    },

    list() {
        const spinnerBlock = linksModel.showTempAsset ? <div class="temp-new-linked-asset"><i class="spinner spinning teal" /></div> : '';
        return linksModel.assetIds.length
            ? <>
                {linksModel.hasLinkedMedia &&
                    <div class="view-all-linked-media" onclick={() => linksModel.viewLinkedMedia()}>
                        <i class="icon-expand icon" /> View Media
                    </div>}
                {spinnerBlock}
                {linksModel.assetIds.map((assetId, i) => {
                    const label = linksModel.namedLinks[assetId] ? <NamedLinkLabel assetId={assetId} /> : '';
                    const key = assetId + i + 1;
                    const index = i + 1;
                    if (linksModel.hiddenNamedLinks[assetId]) {
                        return <TableRowRestricted key={key} index={index} label={label}/>;
                    }
                    return <TableRow assetId={assetId} index={index} key={key} label={label} />;
                })}
            </>
            : spinnerBlock || <div class="empty-state-message"><img src="/images/no-attachments.png" class="empty-state-image" /><span class="message-intro">No links just yet.</span>{appModel.user.isContentEditable(formModel.assetId) ? 'Tap above to add to this record or try adjusting your filters.' : 'Try adjusting your filters.'}</div>;
    },

    view: ({state}) => {
        const configToText = savedViewsModel.configToText(linksModel.projectView);
        const filterCount = configToText ? configToText.propsFilteredCount : 0;

        return <div className="tab-content link-content" onscroll={state.onScroll}>
            <div class="portable-wrap">{libraryModel.renderPort('Attachments')}</div>
            {state.actionUI}
            <div className="link-content-inner">
                <div className="config-menu-control links-config-menu" style="min-height: 10px;">
                    <div class="config-menu-tab" onclick={function () {
                        popupModel.open(this.parentElement, {view: FilterMenus.configMenu, wrapClass: 'filter-menu'});
                    }}>
                        <span class="config-menu-tab-btn"><i className='icon-properties-table' /></span>
                        <span className="config-menu-tab-label">Properties</span>
                    </div>
                    <div class={`config-menu-tab filter-config ${filterCount ? 'has-filters-set' : ''}`} onclick={function () {
                        popupModel.open(this.parentElement, {view: FilterMenus.commonFilters, wrapClass: 'filter-menu filter-menu-center'});
                    }}>
                        <span class="config-menu-tab-btn"> <i className="icon-configure" />
                        </span>
                    </div> 
                </div>
                {linksModel.loading
                    ? <i class="gray spinner spinning" />
                    : <div class="list-links">{Links.list()}</div>
                }
            </div>
        </div>;
    }
};

export default Links;
