import mediaViewerModel from 'models/media-viewer-model';
import {downloadMedia, preFetchUrl} from 'util/media/media-utils';
import MediaOptions from 'views/media-options';

const FileViewer = {

    oninit: async ({state, attrs: {media}}) => {
        const blob = await preFetchUrl(media.srcUrl || media.url);
        state.url = URL.createObjectURL(blob); // UE-5697 Let's us retrieve it with just a GET (no HEAD, which 403s)
        state.isFetched = true;
        m.redraw();
    },

    onremove: ({state}) => {
        URL.revokeObjectURL(state.url);
    },

    notSupported: media => <div class="not-supported">
        <MediaOptions mediaId={media.mediaId} isFullScreen={true}/>
        <div><strong>{media.label || 'This file'} cannot be previewed.</strong></div>
        <p class="btn btn-primary btn-pill" onclick={() => downloadMedia(media.mediaId)}>
            <i class="icon-download icon"/>
            Download
        </p>
    </div>,

    view: ({state, attrs: {media}}) => {
        return  <div class={`file-viewer ${mediaViewerModel.cssClass}`}>
            {mediaViewerModel.hideMediaOptions ? <span key="opts-skip"/> : <MediaOptions mediaId={media.mediaId} isFullScreen={true} key="opts"/>}
            <div id="file-viewer-stage" key="file">
                {state && state.isFetched ? <iframe src={state.url + mediaViewerModel.pdfOpts} /> : <i class="spinner spinning"/>}
            </div>
        </div>;
    }
};

export default FileViewer;
