import message from 'views/toast-message/toast-message';
class OfflineManager {
    constructor() {
        this.goOnline = this._goOnline.bind(this);
        this.goOffline = this._goOffline.bind(this);
        this.init();
        this.onceOnlineCallbacks = [];
    }

    init() {
        if (this._isOffline) {
            this.goOffline(true);
        } else {
            this.goOnline(true);
        }

        this.listenForConnectionChange();
    }
    
    onceOnline(callback) {
        offlineManager.onceOnlineCallbacks.push(callback);
    }

    runOnceOnlineCallbacks() {
        if (offlineManager && offlineManager.onceOnlineCallbacks) {
            offlineManager.onceOnlineCallbacks.forEach(callback => callback());
            offlineManager.onceOnlineCallbacks = [];
        }
    }

    _goOnline(forceOn = false) {
        if (!forceOn && !this.isOffline) {
            // Already online
            return;  
        }
        this.isOffline = false;
        document.body.classList.remove('offline');
        message.hide();
        this.runOnceOnlineCallbacks();

        window.addEventListener('offline', this.goOffline);
    }

    _goOffline(forceOff = false) {
        if (!forceOff && this.isOffline) {
            // Already offline
            return;  
        }

        this.isOffline = true;

        clearInterval(this.disconnectCheckInterval);
        document.body.classList.add('offline');
        message.show('No internet connection is available. We\'ll keep trying to reconnect.', 'error', true);

        window.addEventListener('online', this.goOnline);
    }

    listenForConnectionChange() {
        if (navigator.connection) {
            navigator.connection.onchange = () => {
                if (offlineManager.isOffline) {
                    return;
                }

                this.setConnectionQuality();
            };
        }
    }

    setConnectionQuality() {
        if (!offlineManager.ignoreSlowConnection
        && !offlineManager.connectionIsSlow
        && (['slow-2g', '2g'].indexOf(navigator.connection.effectiveType) !== -1
            || navigator.connection.rtt > 1000)) {
            message.show(<span>Poor internet connection detected. <a onclick={() => {
                message.hide();
                offlineManager.ignoreSlowConnection = true;
            }}>Ignore</a>.</span>, 'warning', true);

            offlineManager.connectionIsSlow = true;
        } else {
            offlineManager.connectionIsSlow = false;
            message.hide();
        }
    }

    get _isOffline() {
        return window.navigator.onLine === false;
    }

}

const offlineManager = new OfflineManager();

export default offlineManager;
