import store from 'util/data/store';
import peopleModel from 'models/people/people-model';
import controlToFeature from 'util/interfaces/control-to-feature';
import FilterSelect from 'views/filter-select/filter-select';
import appModel from 'models/app-model';
import AssetOptionsModel from 'models/asset/asset-options-model';
import AddPeoplePrompt from 'views/people/add-people-prompt';

const updateAssetFeatures = (control, assetId) => controlToFeature.updateAssetFeatures(control, assetId);

export default function (control, assetId) {
    const options = peopleModel.getControlOptions(assetId);
    const selected = store.assets[assetId].properties[control.fieldName] || '';
    return <div class="user-control">
        {options ? <div class="select-wrapper searchable-select">
            <FilterSelect
                types={['users']}
                selected={selected}
                displayOptionAs={() => peopleModel.displayUserControlOption(selected)}
                onselect={selection => {
                    store.assets[assetId].properties[control.fieldName] = selection || '';
                    updateAssetFeatures(control, assetId);
                }} /></div> : options === null ?
            <div class="no-options-exist">No people have been added to this {appModel.toolbox.siteTermSingular} yet.</div> :
            <div class="loading-options"><i class="spinner always-show spinning" /><input disabled type="text" class="loading-text" placeholder={'Loading...'} />
            </div>}
            
        {AssetOptionsModel.hasPeopleEditAccess() && <AddPeoplePrompt/>}
    </div>;
}
