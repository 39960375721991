import stopPropagation from 'util/events/stop-propagation';

const Toggle = {

    oninit({attrs: {active}, state}) {
        state.active = !!active;
    },

    onupdate({attrs: {active, reset}, state}) {
        if (reset) {
            state.active = !!active;
        }
    },

    view: ({attrs: {onchange, disabled}, state}) =>
        <div class={`toggle ${state.active ? 'active' : ''} ${disabled ? 'disabled-toggle' : ''}`} onclick={disabled ? null : stopPropagation(() => {
            state.active = !state.active;
            onchange(state.active);
        })}>
            <div class="icon-check"></div>
        </div>
};

export default Toggle;
