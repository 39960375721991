import initializer from 'util/initializer';
import router from 'uav-router';
import store from 'util/data/store';

let callbacks = {};

const config = {
    new: {
        survey: change => {
            store.surveys[change.surveyId] = change;
        },
        tileset: change => {
            store.tilesets[change.tilesetId] = change;
        },
        plan: change => {
            store.plans[change.planId] = change;
        }
    },
    modified: {
        tileset: change => {
            store.tilesets[change.tilesetId] = change;
        },
        survey: change => {

            if (change.status === 'ready') {

                const staked = change.stakes && change.stakes.length > 2;

                const survey = store.surveys[change.surveyId];

                if (survey) {

                    const changedSurvey = Object.assign({}, survey, change);

                    changedSurvey.staked = staked;

                    changedSurvey.processing = null;

                    store.setContainerValue(store.surveys, change.surveyId, changedSurvey);

                } else {

                    config.new.survey(change);

                }

            }

        },
        plan: change => {
            store.plans[change.planId] = change;
        }
    },
    deleted: {
        plan: change => {
            delete store.plans[change.planId];
        },
        tileset: change => {
            delete store.tilesets[change.tilesetId];
        },
        survey: change => {
            delete store.surveys[change.surveyId];
        }
    }
};

function isThisProject(change) {

    return (!change.projectId || change.projectId === router.params.projectId)
        && (!change.siteId || change.siteId === router.params.siteId)
        || (change.recordType === 'site' || change.recordType === 'project');

}

function getAction(change) {
    if (isThisProject(change)) {
        return config[change.changeType] && config[change.changeType][change.recordType];
    }
}

function publish(change) {
    if (change.documentId && !change.pageId) {
        change.recordType = 'document';
        if (change.error) {
            change.changeType = 'failed';
        }
    } else if (change.planId && change.error) {
        if (!change.errorType) {
            change.errorType = change.error;
        }
    }

    if (change.errorType) {

        const failureActions = callbacks.failed && callbacks.failed[change.errorType];

        if (failureActions) {

            failureActions.forEach(cb => cb(change));

        }

    } else {

        const action = getAction(change);

        if (action) {

            action(change);

        }

        const changeType = change.changeType;
        const recordType = change.recordType;

        const callbackActions = callbacks[changeType] && callbacks[changeType][recordType];

        if (callbackActions) {

            callbackActions.forEach(cb => cb(change));

        }

    }

}

Object.assign(publish, config);

publish.clearCallbacks = (changeType, recordTypes) => {

    if (!changeType) {

        callbacks = {};

    } else if (callbacks[changeType]) {

        if (recordTypes) {

            if (typeof recordTypes === 'string') {

                recordTypes = [recordTypes];

            }

            recordTypes.forEach(recordType => {

                callbacks[changeType][recordType] = null;

            });

        } else {

            callbacks[changeType] = null;

        }

    }

};

initializer.add(publish.clearCallbacks, 'publish');

/**
 * For content & features, we only want to respect notifications if they have a changedBy.sessionId
 */
publish.isValidChangedBy = (contentOrFeature) => {
    return !contentOrFeature.changedBy || !!contentOrFeature.changedBy.sessionId;
};

publish.await = opts => {

    // if (Array.isArray(opts.changeType)) {

    //     return opts.changeType.forEach(changeType => {

    //         publish.await(Object.assign(opts, {changeType}));

    //     });

    // }

    const {changeType, recordType} = opts;

    const callback = change => {

        if (isThisProject(change) && (!opts.test || opts.test(change))) {

            opts.callback(change);

            if (!opts.persist) {

                removeCallback();

            }

        }

    };

    function removeCallback() {

        const callbackList = callbacks[changeType] && callbacks[changeType][recordType];

        if (callbackList) {

            const index = callbackList.indexOf(callback);

            if (index !== -1) {

                callbackList.splice(index, 1);

            }

        }

    }

    callbacks[changeType] = callbacks[changeType] || {};

    callbacks[changeType][recordType] = callbacks[changeType][recordType] || [];

    callbacks[changeType][recordType].push(callback);

    return removeCallback;

};

publish.err = {
    createThumbnail: 'CreatePageThumbnailFailure',
    createPlanSection: 'CreatePlanSectionFailed',
    extractPages: 'ExtractDocumentPagesFailure',
    fileFormat: 'UnsupportedDocumentFileFormat',
    cropFailed: 'CropPlanSectionFailed',
    ExportFailure: 'ExportFailure',
    StakePlanFailure: 'StakePlanFailure'
};

export default publish;
