/* eslint-disable max-depth */
import 'babel-polyfill';
import 'util/polyfills';
import logManager from 'managers/log-manager';
import router from 'uav-router';
import App from 'views/app';
import cache from 'legacy/util/data/cache';
import Site from 'views/site';
import constants from 'util/data/constants';
import PlaceEditor from 'views/place-editor';
import dialogModel from 'models/dialog-model';
import mapboxgl from 'mapbox-gl';
import Survey from 'views/survey';
import Plan from 'views/plan';
import PDF from 'views/pdf';
import MediaViewerStandalone from 'views/media-viewer-standalone';
import oneUpModel from 'models/one-up-model';
import store from 'util/data/store';
import Login from 'views/auth/login';
import Offline from 'views/offline';
import ForgotPassword from 'views/auth/forgot-password/forgot-password';
import ChangePassword from 'views/auth/change-password/change-password';
import ResetPassword from 'views/auth/reset-password/reset-password';
import AuthorizationMessage from 'views/auth/authorization-message/authorization-message';
import mapboxSupport from 'util/mapbox-support';
import NewPassword from 'views/auth/new-password/new-password';
import appModel from 'models/app-model';
import {appUrl, whiteLabelAccount} from './util/data/env';
import accountManager from './managers/account-manager';
import authModel from 'models/auth-model';
import authManager from 'managers/auth-manager';
import offlineManager from 'managers/offline-manager';
import message from 'views/toast-message/toast-message';

const PROCORE_EMPTY_PROJECT_ID = '{{projectId}}';

logManager.init();

authModel.notifyParentIfInChildWindow();

const views = {
    layer: () => Plan,
    survey: () => Survey,
    pdf: () => PDF,
    editplace: () => PlaceEditor,
    forgotpassword: () => ForgotPassword,
    changepassword: () => ChangePassword,
    newpassword: () => NewPassword
};

if (process.env.NODE_ENV !== 'production') {
    require('../dev/debug');
}

if (offlineManager.isOffline) {
    offlineManager.onceOnline(() => location.reload());
    const $app = document.getElementById('app');
    m.mount($app, App);
    message.hide();
    appModel.renderNoInit(Offline);
} else if (!mapboxgl.supported()) {
    const error = `Mapbox Error: ${mapboxSupport.notSupportedReason() || 'The browser does not support mapboxgl. We were unable to determine the reason.'}`;
    console.error(error);
    dialogModel.create('app', {
        warning: true,
        text: 'Your browser is not supported. Please upgrade and try again.',
        onOkay: () => {
            location.href = 'https://bestvpn.org/outdatedbrowser/en';
        },
        okayText: 'Upgrade browser'
    });
} else {
    const $app = document.getElementById('app');
    m.mount($app, App);

    cache.prep();

    router.init(async params => {

        /**
         * When oauthing via iframe embed, the oauth method will always redirect back to OnePlace. 
         * This checks for the queryParam signifying that the user is in the embed, and if found, redirects there. 
         */
        const refreshToken = authManager.checkForOauthCookie();
        if (refreshToken) {
            cache.set('ue_refresh', refreshToken);
            if (params.isPromapper) {
                // Keep all the params that were passed in and redirect to the embed.
                const stringParams = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
                window.location.href = `${appUrl}/embed-js${stringParams ? `#${stringParams}` : ''}`;
            }
        }   
        
        
        if (whiteLabelAccount) {
            await accountManager.setWhiteLabelAccountData();
        }
        const tabName = document.getElementById('companyName');
        const tabIcon = document.getElementById('favicon');
        tabName.setAttribute('title', whiteLabelAccount || accountManager.isWhiteLabelAccount ? accountManager.whiteLabelData.companyName : 'Unearth' );
        tabIcon.setAttribute('href', whiteLabelAccount || accountManager.isWhiteLabelAccount ? accountManager.whiteLabelData.logos.favicon : 'images/favicon.ico');
        appModel.setDocumentTitle();
        
        if (params.access_token) {
            store.accessToken = params.access_token;
        }

        if (params.projectId === PROCORE_EMPTY_PROJECT_ID) {
            router.url.mergeReplace({projectId: ''});
        }

        /* UNAUTHENTICATED ROUTES */

        if (params.code && params.userId) {
            appModel.renderNoInit(ResetPassword);
        } else if (params.view && params.view === 'forgotpassword') {
            appModel.renderNoInit(ForgotPassword);
        } else if (params.view === 'changepassword') {
            appModel.renderNoInit(ChangePassword);
        } else if (params.view === 'newpassword') {
            appModel.renderNoInit(NewPassword);
        } else if (params.message) {
            appModel.renderNoInit(AuthorizationMessage);
        } else {            
            const reconnected = await authManager.reconnect();
            if (!reconnected) {
                // No user data provided from cache, show log in screen:
                appModel.renderNoInit(Login);
            } else {
                try {
                    if (params.mediaId) {
                        await appModel.user.init(authManager.userId);
                        appModel.renderNoInit(MediaViewerStandalone);
                        return; 
                    }
                    await appModel.init();
                    /* AUTHENTICATED ROUTES */
                    params = router.params;
                    if (views[params.view]) {
                        appModel.render(views[params.view]());
                    } else if (params.projectId) {
                        appModel.render(Site);
                    } else {
                        // eslint-disable-next-line max-depth
                        if (appModel.account.isInvalid) {
                            appModel.renderNoInit(Login);
                        } else {
                            dialogModel.create('app', {
                                warning: false,
                                text: 'There is a problem with this account. Please contact support for assistance.',
                                onYes: () => window.open(`mailto:${constants.defaultSupportEmail}`),
                                yesText: 'Support',
                                noText: 'Sign Out',
                                noClass: 'btn btn-pill btn-primary',
                                yesClass: 'btn btn-pill btn-secondary',
                                onNo: () => {
                                    dialogModel.close();
                                    authManager.signOut();
                                }
                            });
                        }
                    }

                } catch (e) {
                    authModel.onAuthFailure(true);
                    console.error(e);
                }
            }

        }
        
    });

    router.canNavigate = (retry) => oneUpModel.canNavigate(retry);

    window.addEventListener('beforeunload', (e) => {
        if (oneUpModel.showBeforeUnload()) {
            e.returnValue = '';
            return '';
        }
    });

}
