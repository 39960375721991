import router from 'uav-router';
import randomId from 'util/numbers/random-id';
import siteModel from 'models/site-model';
import formatDate from 'legacy/util/date/format-date';
import appModel from 'models/app-model';
import constants from 'util/data/constants';
import Filepicker from 'util/interfaces/filepicker';
import featureToControl from 'util/interfaces/feature-to-control';
import initializer from 'util/initializer';
import api from 'legacy/util/api';
import AssetForm from 'views/asset-form';
import getToolInterface from 'util/interfaces/get-tool-interface';
import assetListManager from 'managers/asset-list-manager';
import store from 'util/data/store';
import layerModel from 'models/layer-model';
import controlToFeature from 'util/interfaces/control-to-feature';
import popup from 'util/popup';
import mediaModel from 'models/media-model';
import mediaViewerModel from 'models/media-viewer-model';
import { bounds } from 'util/geo';
import message from 'views/toast-message/toast-message';
import screenHelper from 'legacy/util/device/screen-helper';
import Table from 'views/table/table';
import modalModel from 'models/modal-model';
import tableModel from 'models/table/table-model';
import { evaluateControl, evaluateExpression } from 'util/evaluate';
import publish from 'legacy/util/api/publish';
import debounce from 'util/events/debounce';
import dialogModel from 'models/dialog-model';
import pointMenu from 'legacy/components/point-menu';
import helpers from 'legacy/util/api/helpers';
import peopleModel from 'models/people/people-model';
import ajax from 'legacy/util/api/ajax';
import { appUrl } from 'util/data/env';
import proxyRequest from 'util/network/proxy-request';
import panelModel from 'models/panel-model';
import placesTabModel from 'models/places-tab-model';
import sideNavModel from './side-nav-model';
import measure from 'legacy/util/numbers/measure';
import isMetric from 'util/numbers/is-metric';
import { TinyCard } from 'views/asset-card/asset-card';
import { tinyAssetPopup } from 'views/asset-options';
import libraryModel from 'models/library-model';
import oneUpModel from 'models/one-up-model';
import { cellValueToText } from 'util/data/cell-value-to-text';
import AssetOptionsModel from 'models/asset/asset-options-model';
import featureListManager from 'managers/feature-list-manager';
import mediaListManager from 'managers/media-list-manager';

const { updateAssetFeatures } = controlToFeature;

const ASSET_FORM_WIDTH = 362;

class FormModel {

    init() {
        layerModel.resetToolLayers();

        if (this.editingFeatureId) {

            this.onEditStop();

        }

        Object.assign(this, {
            assetId: undefined,         // The id of the asset whose form is open
            hasCommentTab: false,       // Whether the form has a control of type comments
            hasLinksTab: false,         // Whether the form has a control of type links (link on client attachment in db)
            controls: [],               // The list of form controls
            styledControlDescriptors: {}, // The list of controls that are tied to a feature style & the associated descriptor (string) for displaying in the UI
            tabCount: 1,                // The number of available tabs
            visible: true,              // Whether the form is expanded or contracted
            toolInterface: null,        // The active feature creation tool interface
            isNew: false,               // Whether this asset was just created or not
            unmappedFeatureType: false, // Whether this asset could have a feature and doesn't
            focusedAssets: {},          // The assets whose features are not dimmed out on the map
            saving: {},                 // The controls currently being autosaved
            invalid: {},                // The controls with invalid data (ie, a field that is empty when marked as required)
            viewAsLimitedUser: null,    // If a user status is limited, we will set this variable to determine if the asset properties override that,
            addedDate: null,            // Cached formatted asset created date & time
            lastUpdatedDate: null,      // Cached formatted asset updated date & time
            childProjectId: false,      // The ID of the project that this asset represents (only used in meta projects)
            isNextEnabled: true,
            linksModel: undefined,
            assetFormClass: ''
        });

        this.clearAwait();

        this.debouncedTriggerEval = debounce(controlToSkip => this.triggerEval(controlToSkip));

        this.next = debounce((isUp) => this._next(isUp), 200);
    }

    handleLinkControlClick(control, oneUpArgs) {
        if (libraryModel.isPortedTo(control.fieldName)) {
            return libraryModel.quit();
        }
        libraryModel.portLibraryTo(control.fieldName);
        return this.selectFromLibrary(control, oneUpArgs);
    }

    selectFromLibrary(control, args = {}) {
        const assetId = this.assetId;
        const isNamedLinkControl = control.fieldName !== 'Attachments';

        Object.assign(args, {
            name: `Link: ${control.fieldName}`,
            projectId: args.projectId || appModel.editingProjectId || router.params.projectId,
            currentSelections: isNamedLinkControl ? store.assets[assetId].properties[control.fieldName] : store.assets[assetId].linkIds,
            isLink: true,
            canCreateNew: false,
            theme: 'inline',
            assetId
        });

        const onSelect = (contentId) => assetListManager.fetch(contentId).then(() => assetListManager.panToAsset(contentId));

        const onComplete = (records) => {
            const syncValueAndSave = featureToControl.filepicker[control.controlTypeId];
            return syncValueAndSave(records, control.fieldName, assetId);
        };

        libraryModel.select(args, onSelect, onComplete);
    }

    removeNamedLink(linkedAssetId, controlLabel) {
        formModel.saving[controlLabel] = true;

        const assetId = this.assetId;
        const asset = store.assets[assetId];

        const originalNamedLinksList = asset.properties[controlLabel] || [];
        const newNamedLinksList = originalNamedLinksList.filter(id => id !== linkedAssetId);

        store.assets[assetId].properties[controlLabel] = [...newNamedLinksList];
        pointMenu.close();
        m.redraw();

        assetListManager.autosave(assetId);
    }

    cleanup(isOpeningAnotherAsset = sideNavModel.isOpeningMetaAsset) {
        this.init();
        if (router.params.assetId && !isOpeningAnotherAsset) {
            sideNavModel.isOpeningMetaAsset = false;
            router.url.remove('assetId', 'tab');
        }

    }

    controlIsInvalid(control, assetId = this.assetId) {
        let isInvalid = false;
        const asset = store.assets[assetId];
        if (control.attributes.required && !formModel.isReadOnlyControl(asset.assetTypeId, control)) {
            switch (control.controlTypeId) {
            case constants.controlTypeNameToId.coordinates:
                // Valid if: Both coords exist
                isInvalid = !asset.properties.Coordinates
                        || !asset.properties.Coordinates.coordinates[0]
                        || !asset.properties.Coordinates.coordinates[1];
                break;
            case constants.controlTypeNameToId.links ||
                    constants.controlTypeNameToId.file:
                // Valid if: Any asset or media been linked
                // TODO: Validation method inaccurate if asset is deleted, per API-515
                isInvalid = !asset.linkIds || !asset.linkIds.length;
                break;
            default:
                // Valid if: The property exists on the asset and is not an empty string
                isInvalid = !asset.properties.hasOwnProperty(control.fieldName)
                        || asset.properties[control.fieldName] === '' || !asset.properties[control.fieldName];
                break;
            }
        }

        return isInvalid;
    }

    validateControl(control) {
        this.invalid[control.fieldName] = formModel.controlIsInvalid(control, this.assetId);
    }

    /**
     * Run through each field on the form and validate for completion.
     * If all required control fields are completed, run the callback function provided as arg.
     * If not, display a warning message to the user re missing fields.
     */
    validateThenRun(callbackFunction) {
        libraryModel.quit();

        this.invalid = {}; // Clear the validation first.

        this.controls.forEach(control => this.validateControl(control));

        const numInvalid = Object.keys(this.invalid).filter(control => this.invalid[control]).length;

        if (!numInvalid) {

            callbackFunction(); // Valid, so run callback function

        } else {

            pointMenu.close(); // Close menu in case it was left open.

            const numString = `${numInvalid} field${numInvalid > 1 ? 's' : ''} marked in red.`;

            dialogModel.open({
                headline: 'This form is incomplete.',
                text: <div>Please check <span>{numString}</span></div>,
                cssClass: 'incomplete-form-warning',
                yesText: 'Return to Form',
                yesClass: 'btn btn-pill btn-primary btn-return-to-form',
                noText: 'Continue',
                noClass: 'btn btn-pill btn-secondary',
                onYes: () => {
                    // Return to asset form.
                    this.selectTab({ name: 'Properties' });
                    this.visible = true;
                    popup.remove();
                    if (layerModel.isOpen) {
                        layerModel.togglePicker();
                    }
                    m.redraw();
                },
                onNo: () => {
                    // Run the callback function.
                    callbackFunction();
                    m.redraw();
                }
            });

        }

    }

    close(isOpeningAnotherAsset) {

        const asset = store.assets[this.assetId];

        if (assetListManager.hasUnsavedChanges[this.assetId]) {
            assetListManager.autosave(this.assetId);
        }

        this.clearAwait();

        popup.remove();

        if (this.toolInterface) {

            this.toolInterface.close();

        }

        if (this.isNew) {

            const assetTypeName = store.assetTypes[asset.assetTypeId].name;

            let messageElement;

            if (tableModel.assetIds.indexOf(this.assetId) === -1) {

                featureListManager.removeFeatures(asset.featureIds);

                messageElement = <div onclick={() => {
                    message.hide();
                    this.viewAsset(asset.contentId, 'Properties');
                }}>New <a>{assetTypeName}</a> added. May not be visible due to current filter conditions.</div>;

            } else {

                messageElement = <div onclick={() => {
                    message.hide();
                    this.viewAsset(asset.contentId, 'Properties');
                }}>New <a>{assetTypeName}</a> added.</div>;

            }

            message.show(messageElement);

        }

        siteModel.sidebar = Table;

        modalModel.close();

        if (!isOpeningAnotherAsset) {

            router.url.remove('assetId', 'tab');

        }

    }

    toggleVisibility() {

        this.visible = !this.visible;

        if (this.editingFeatureId) {

            this.toolInterface.close();

        }

    }


    mapClick(features, lngLat) {

        if (appModel.user.isViewOnly) {

            return;

        }

        if (this.editingFeatureId) {

            const draw = this.toolInterface.draw;

            if (draw && !draw.popup && !features.find(f => f.id.startsWith(this.editingFeatureId))) {

                this.toolInterface.close();

            }

        } else {

            const assetFeatures = features.filter(f => this.focusedAssets[f.properties.assetId]);

            siteModel.leftClick(assetFeatures.length ? assetFeatures : features, lngLat);

        }

    }

    addFeatureLocation(assetId, featureType, data) {

        const interfaceType = featureType.attributes.interface;
        const asset = store.assets[assetId];
        let coordinates;

        if (interfaceType === 'filepicker') {
            coordinates = Filepicker.getMediaCoordinates(data);
        }

        if (!coordinates) {

            const container = siteModel.map.getContainer(),
                sidebarWidth = window.innerWidth <= ASSET_FORM_WIDTH ? 0 : ASSET_FORM_WIDTH,
                midPoint = {
                    x: container.offsetWidth / 2 + sidebarWidth / 2 + 12,
                    y: container.offsetHeight / 2
                };

            coordinates = siteModel.map.unproject(midPoint).toArray();

        }

        const feature = {
            type: 'Feature',
            id: randomId(),
            geometry: {
                type: 'Point',
                coordinates
            },
            properties: Object.assign({}, featureType.properties, {
                assetId: assetId,
                mediaId: data.mediaId,
                featureTypeId: featureType.featureTypeId
            })
        };

        asset.featureIds = asset.featureIds || [];

        asset.featureIds.push(feature.id);

        this.unmappedFeatureType = false;

        featureListManager.addFeatures([feature]);

        const featureInput = interfaceType === 'filepicker'
            ? [data]
            : data;

        featureToControl.sync(interfaceType, featureInput, assetId, featureType);

        const apiSafeFeature = Object.assign({}, api.apiSafeFeature(feature), {
            projectId: router.params.projectId,
            assetId: asset.contentId
        });

        api.rpc.request([
            ['createFeature', apiSafeFeature]
        ]).then(() => this.editFeature(feature));

    }

    addMediaFeature(mediaId) {

        mediaListManager.getMediaAsync(mediaId).then(media => {

            assetListManager.fetch(media.contentId || this.assetId).then(asset => {

                const featureType = assetListManager.supportsFileFeatures(asset.contentId);

                if (featureType) {

                    return this.addFeatureLocation(asset.contentId, featureType, media);

                }

                console.error('Can\'t add media feature to asset type ', asset.contentType);

            });

        });

    }

    getAssetFeatures(assetId) {

        const features = [],
            requests = [];

        const asset = assetListManager.getById(assetId, true);

        if (asset && asset.featureIds && asset.featureIds.length > 0) {

            asset.featureIds.forEach(featureId => {

                if (featureListManager.getById(featureId)) {
                    features.push(featureListManager.getById(featureId).getGeoJsonFeature());
                } else {
                    requests.push(['listFeatures', {
                        featureId,
                        isVisible: true
                    }]);

                }

            });

        }

        if (requests.length) {
            return api.rpc.requests(requests)
                .then(([results]) => featureListManager.addFeatures(results)
                    .then(() => {
                        return features.concat(results.map(result => featureListManager.getById(result.featureId).getGeoJsonFeature()));
                    })
                );
        }

        return Promise.resolve(features);

    }

    focusOnLinkFeatures(linkedAssetIds = []) {

        linkedAssetIds.forEach(assetId => {

            this.focusedAssets[assetId] = true;

        });

        layerModel.resetToolLayers();

        layerModel.focusOnAssetFeatures(Object.keys(this.focusedAssets));

    }

    onEditStop() {

        formModel.editingFeatureId = null;

    }

    addToMap() {

        const asset = store.assets[this.assetId];

        const tool = appModel.toolbox.tools[asset.attributes.toolId],
            toolInterface = getToolInterface(tool, this.assetId, tool.featureTypes[0].featureTypeId),
            mediaId = asset.mediaId || asset.mediaIds && asset.mediaIds[0];

        if (mediaId) {

            if (toolInterface.type === 'filepicker') {

                return this.addMediaFeature(mediaId);

            } else if (toolInterface.type === 'plan') {

                if (!screenHelper.canEditLayers()) {

                    return; /* noop on small screens - should open asset form instead */

                }

                const plan = assetListManager.getLayers(this.assetId, 'plan');

                if (plan) {

                    return router.merge({ view: 'layer', planId: plan.planId });

                }

                return mediaModel.createLayer(asset.mediaId, this.assetId);

            } else if (toolInterface.type === 'survey') {

                if (!screenHelper.canEditLayers() || assetListManager.getLayers(this.assetId, 'survey')) {

                    return;

                }

            }

        } else if (toolInterface.type === 'imodel') {

            const featureType = tool.featureTypes[0];
            const iModelControlTypeId = constants.controlTypeNameToId.imodel;
            const iModelControl = tool.assetForm.controls.find(c => c.controlTypeId === iModelControlTypeId);
            const iModel = asset.properties[iModelControl.fieldName] || {};

            return this.addFeatureLocation(this.assetId, featureType, iModel);

        }

        toolInterface.launch()
            .then(() => {

                const feature = featureListManager.getById(asset.featureIds[0]);

                if (feature) {

                    const apiSafeFeature = Object.assign({}, api.apiSafeFeature(feature), {
                        projectId: router.params.projectId,
                        assetId: asset.contentId
                    });

                    api.rpc.request([
                        ['createFeature', apiSafeFeature]
                    ]).then(() => this.viewAsset(asset.contentId, 'Properties', true));

                } else {

                    this.viewAsset(asset.contentId, 'Properties');

                }

            });

    }

    editFeature(feature) {
        if (this.editingFeatureId) {

            if (this.editingFeatureId === feature.id) {

                return;

            }

            this.toolInterface.close();

        }

        if (feature.properties[constants.trimbleDataKey]) {
            console.error('Attempted to edit a trimble feature.');
            return;
        }

        this.editingFeatureId = feature.id;

        // Watch out! This feature's asset isn't necessarily this.assetId,
        // because it could belong to a linked asset.
        assetListManager.fetch(feature.properties.assetId).then(asset => {

            if (!appModel.user.isContentEditable(asset.contentId)) {

                return;

            }

            const tool = appModel.toolbox.tools[asset.attributes.toolId];

            if (tool && feature.geometry && feature.geometry.coordinates) {

                this.toolInterface = getToolInterface(tool, asset.contentId, feature.featureTypeId || feature.properties.featureTypeId);

                this.toolInterface.edit(feature);

                if (screenHelper.small()) {

                    this.visible = false;

                }

                if (!bounds(siteModel.map.getBounds().toArray()).contains(feature)) {
                    if (!this.isNew && !appModel.project.isMetaProject) {
                        siteModel.map.panToFeatures([feature]);
                    }

                }

                if (asset.contentId !== this.assetId) {

                    this.focusOnLinkFeatures([asset.contentId]);

                }

                m.redraw();

            }

        });

    }

    // Some imported assets have properties set to null
    // rather than omitting the property.
    removeNullProps() {

        if (this.assetId) {

            const asset = store.assets[this.assetId];

            Object.keys(asset.properties).forEach(key => {
                if (asset.properties[key] === null) {
                    delete asset.properties[key];
                }
            });

        }

    }

    /**
     * If a user tries to load a comment asset as the primary asset (via url), 
     * open the parent asset as the primary on the comments tab.
     */
    async redirectToAssetCommentTab(threadId) {
        const thread = await api.get.thread(threadId);
        const assets = helpers.list(thread.content);
        const mainAsset = assets.find(asset => asset.assetTypeId !== constants.commentAssetTypeId);
        if (mainAsset && mainAsset.contentId) {
            this.viewAsset(mainAsset.contentId, 'Comments');
        } else {
            this.close();
        }
    }

    _viewAsset(assetId, tab = router.params.tab, isNew) {
        if (this.assetId === assetId) {

            return;

        }
        if (!appModel.user.permissions.canReadContent(assetId)) {
            router.set({ projectId: router.params.projectId, siteId: router.params.siteId });
            return appModel.user.permissions.displayMessage({
                action: 'READ',
                recordType: 'content',
                xid: assetId,
                role: appModel.user.role
            });
        }

        const asset = store.assets[assetId];

        tab = tab || 'Properties';

        this.assetId = assetId;

        this.removeNullProps();

        const assetFormId = store.assetTypeToFormId[asset.assetTypeId];

        this.assetForm = store.assetForms[assetFormId];

        this.isNew = isNew;

        if (asset.assetTypeId === constants.commentAssetTypeId) {
            return this.redirectToAssetCommentTab(asset.threadId);
        }

        this.initAssetForm();

        siteModel.sidebar = AssetForm;

        this.selectTab({ name: tab });

        m.redraw();

        this.getAssetFeatures(assetId).then(features => {

            if (features.length) {

                // // Test code for Multi- features

                // const feature = features[0];

                // if (!feature.geometry.type.startsWith('Multi')) {

                //     if (feature.geometry.type === 'Polygon') {

                //         feature.geometry.coordinates = [feature.geometry.coordinates, [feature.geometry.coordinates[0].map(c => [c[0] + 0.0005, c[1] + 0.0005])]];

                //     } else if (feature.geometry.type === 'LineString') {

                //         feature.geometry.coordinates = [feature.geometry.coordinates, feature.geometry.coordinates.map(c => [c[0] + 0.0005, c[1] + 0.0005])];

                //     } else {

                //         feature.geometry.coordinates = [feature.geometry.coordinates, [feature.geometry.coordinates[0] + 0.0005, feature.geometry.coordinates[1] + 0.0005]];

                //     }

                //     feature.geometry.type = 'Multi' + feature.geometry.type;

                //     const source = siteModel.map.getSource(feature.properties.featureTypeId);

                //     source.setData(source._data);

                // }

                if (isNew) {
                    if (screenHelper.large()) {
                        this.editFeature(features.pop());

                    } else if (screenHelper.small() && appModel.toolbox && appModel.toolbox.toolInterface && appModel.toolbox.toolInterface.type === 'text') {

                        this.editFeature(features.pop());

                        this.visible = false;

                    } else if (appModel.toolbox.toolInterface) {

                        appModel.toolbox.toolInterface.close();

                    }
                } else if (tab === 'Properties') {

                    siteModel.map.panToFeatures(features);

                }

            } else {

                this.unmappedFeatureType = assetListManager.supportsGeometry(assetId);
                assetListManager.panToAsset(assetId);

            }

            this.focusedAssets[assetId] = true;

            layerModel.focusOnAssetFeatures([assetId]);

            this.awaitChanges();

        });

        popup.remove();

        this.turnOnLayer(assetId);
        this.setAssetPageTitle();
    }

    turnOnLayer(assetId) {

        const asset = store.assets[assetId],
            planControlTypeId = constants.controlTypeNameToId.plan,
            surveyControlTypeId = constants.controlTypeNameToId.survey,
            tool = appModel.toolbox.tools[asset.attributes.toolId];

        tool.assetForm.controls.find(control => {

            if (control.controlTypeId === planControlTypeId) {

                let planId = asset.properties[control.fieldName];

                if (Array.isArray(planId)) {
                    planId = planId[0];
                }

                const plan = store.plans[planId];

                if (plan && plan.status === 'complete') {

                    layerModel.onFolderInit(() => layerModel.showPlan(plan));

                    return true;

                }

            } else if (control.controlTypeId === surveyControlTypeId) {

                const survey = store.surveys[asset.properties[control.fieldName]];

                if (survey && survey.status === 'ready') {

                    layerModel.setSurvey(survey.surveyId);

                    return true;

                }

            }

            return false;

        });

    }

    viewAsset(assetId, tab, isNew) {

        if (this.assetId) {
            this.close(true);
            this.cleanup(true);
        }

        // Close open panels (eg the People panel)
        if (panelModel.isOpen) {
            panelModel.close();
        }

        pointMenu.close();

        const asset = store.assets[assetId];

        if (asset) {
            assetListManager.panToAsset(assetId);

            // This handles opening a comment's parents asset form.
            if (asset.linkIds && asset.linkIds.length && asset.assetTypeId === constants.commentAssetTypeId) {

                if (!tab) {

                    tab = 'Comments';

                }

                // If this is an attachment and its parent asset was deleted,
                // then just open this attachment's asset form.
                // Otherwise, open the parent asset's form.
                return assetListManager.fetch(asset.linkIds[0], true)
                    .then(parentAsset => {
                        if (parentAsset && parentAsset.isVisible) {
                            this.viewAsset(parentAsset.contentId, tab, isNew);
                        } else {
                            this._viewAsset(assetId, 'Properties', isNew);
                        }
                    });

            }

            this._viewAsset(assetId, tab, isNew);

            // After asset is opened and rendered from store, re-fetch it to make sure the latest data is synced
            assetListManager.fetch(assetId, true).then(() => {
                this.removeNullProps();
                m.redraw();
            });
        }

    }

    selectTab(tab) {
        if (!AssetOptionsModel.hasPlacesEditAccess() && tab.name === 'Places/Levels') {
            return router.url.merge({
                assetId: this.assetId,
                tab: 'Properties'
            });
        }

        router.url.merge({
            assetId: this.assetId,
            tab: tab.name === 'Loading' ? 'Properties' : tab.name
        });

        if (tab.name === 'Places/Levels') {
            placesTabModel.init();
        }
    }

    // Uploading attachments to media control types via the oneup filepicker (image, video, etc.)
    uploadFileAttachments(control) {
        const handleSelections = featureToControl.filepicker[control.controlTypeId];
        if (handleSelections) {
            formModel.linkFlowId = randomId();
            const asset = store.assets[this.assetId];
            const assetId = this.assetId;
            const mediaIds = asset.properties[control.fieldName] || [];
            let maxFiles;
            if (control.attributes.maxFiles !== undefined) {
                maxFiles = Math.max(control.attributes.maxFiles - mediaIds.length, 0);
            }
            return oneUpModel.addUploadFlow({
                flowId: formModel.linkFlowId,
                excludedFromResults: asset.linkIds,
                projectId: router.params.projectId,
                name: control.fieldName,
                isLink: false,
                canCreateNew: true,
                assetId: this.assetId,
                makeVisible: oneUpModel.cssClass === 'hidden',
                maxFiles
            }).then((mediaRecords) => {
                handleSelections(mediaRecords, control.fieldName, assetId).then(() => {
                    updateAssetFeatures(control);
                    m.redraw();
                });
            });
        }
    }

    initAssetForm() {
        const asset = store.assets[this.assetId];
        this.initEvalArgs();

        asset.featureIds = asset.featureIds || [];

        this.addedDate = formatDate.dayMonthAtTime(asset.createdDateTime);
        const updatedDateTime = asset.updatedDateTime || new Date();
        this.lastUpdatedDate = formatDate.dayMonthAtTime(updatedDateTime);

        this.tabCount = 1;

        this.controls = this.setAssetControls(asset, this.assetForm);
        const tool = appModel.toolbox.tools[asset.attributes.toolId];
        this.setStyleControlDescriptors(tool);

        this.assetFormClass = 'ue-aff-evaluating';
        m.redraw();
        this.triggerEval().then(() => {
            this.assetFormClass = 'ue-aff-evaluated';
            m.redraw();        
        });
    }


    resetControls() {
        if (this.assetId) {
            const asset = store.assets[this.assetId],
                assetForm = appModel.toolbox.tools[asset.attributes.toolId].assetForm;
            this.tabCount = 1;
            this.controls = this.setAssetControls(asset, assetForm);
            m.redraw();
        }
    }
    setAssetControls(asset, assetForm) {
        const controlTypeNameToId = constants.controlTypeNameToId,
            controls = [];
        const addControl = control => control.attributes.hidden || controls.push(control);
        assetForm.controls.forEach(control => {

            switch (control.controlTypeId) {
            case controlTypeNameToId.name:
                this.nameControl = control;
                addControl(control);
                break;

            case controlTypeNameToId.comments:
                this.hasCommentTab = true;
                this.tabCount++;
                break;

            case controlTypeNameToId.links:
                this.hasLinksTab = true;
                this.tabCount++;
                addControl(control);
                break;

            case controlTypeNameToId.project:
                this.childProjectId = asset.properties[control.fieldName];
                appModel.setState('editingProjectId', asset.properties[control.fieldName]);

                if (appModel.user.isAccountAdmin) {
                    this.tabCount += 2;
                }
                addControl(control);
                break;

            default:
                addControl(control);
            }

        });
        return controls;
    }

    setAssetPageTitle() {
        const asset = store.assets[this.assetId];
        assetListManager.setPageTitle(asset.contentId);
    }

    setStyleControlDescriptors(tool) {
        tool.featureTypes && tool.featureTypes.forEach(featureType => {
            const styledControls = featureType.attributes && featureType.attributes.styledControls ? featureType.attributes.styledControls : [];
            styledControls.forEach(controlLabel => {
                let descriptorsAsString = '';
                if (tool.styledControls[controlLabel]) {
                    const styledControlDescriptors = Object.values(tool.styledControls[controlLabel]);
                    if (styledControlDescriptors.length > 1) {
                        let lastItem = styledControlDescriptors[styledControlDescriptors.length - 1];
                        lastItem = `and ${lastItem}`;
                        styledControlDescriptors[styledControlDescriptors.length - 1] = lastItem;
                    }
                    if (styledControlDescriptors.length > 2) {
                        descriptorsAsString = styledControlDescriptors.join(', ');
                    } else {
                        descriptorsAsString = styledControlDescriptors.join(' ');
                    }
                    this.styledControlDescriptors[controlLabel] = descriptorsAsString;
                }
            });
        });

    }

    // If the user is typing a float with a trailing dot or zero,
    // then we can't save or redraw because the string will lose
    // characters when cast to a number.
    numIsUncastable(e) {
        let str = e.target.value;
        if (str[0] === '.') {
            str = '0' + str;
        }
        const num = Number(str);
        return e.data === '.' && !str.includes('.') || Number.isNaN(num) || str.length !== num.toString().length;
    }

    handleCoordinate(e, index, point, control) {

        const strValue = e.target.value.trim();

        if (this.numIsUncastable(e)) {
            e.redraw = false;
            return;
        }

        const value = Number(strValue);

        const isValid = !Number.isNaN(value) &&
            (index === 0 && value >= -180 && value <= 180 || index === 1 && value >= -90 && value <= 90);

        e.target.parentNode.classList[isValid ? 'remove' : 'add']('invalid-coord');

        if (isValid) {

            point.coordinates[index] = strValue === '' ? strValue : value; // To avoid casting empty strings to 0

            updateAssetFeatures(control);

        }

    }

    handleNumber(e, control, assetId) {

        const strValue = e.target.value;
        const properties = store.assets[assetId].properties;

        if (strValue === '') {
            delete properties[control.fieldName];
        } else if (this.numIsUncastable(e)) {
            e.redraw = false;
            return;
        } else {
            properties[control.fieldName] = Number(strValue);
        }

        updateAssetFeatures(control, assetId);

    }

    handleLength(e, control, assetId) {

        const strValue = e.target.value;
        const properties = store.assets[assetId].properties;

        if (strValue === '') {
            delete properties[control.fieldName];
        } else if (this.numIsUncastable(e)) {
            e.redraw = false;
            return;
        } else {
            const value = Number(strValue);
            properties[control.fieldName] = isMetric()
                ? value
                : measure.feetToMeters(value);
        }
        updateAssetFeatures(control, assetId);
    }

    handleArea(e, control, assetId) {

        const strValue = e.target.value;
        const properties = store.assets[assetId].properties;

        if (strValue === '') {
            delete properties[control.fieldName];
        } else if (this.numIsUncastable(e)) {
            e.redraw = false;
            return;
        } else {
            const value = Number(strValue);
            properties[control.fieldName] = isMetric()
                ? value
                : measure.squareFeetToSquareMeters(value);
        }
        updateAssetFeatures(control, assetId);
    }

    handleVolume(e, control, assetId) {

        const strValue = e.target.value;
        const properties = store.assets[assetId].properties;

        if (strValue === '') {
            delete properties[control.fieldName];
        } else if (this.numIsUncastable(e)) {
            e.redraw = false;
            return;
        } else {
            const value = Number(strValue);
            properties[control.fieldName] = isMetric()
                ? value
                : measure.cubicYardsToCubicMeters(value);
        }
        updateAssetFeatures(control, assetId);

    }

    recalculateVolume() {

        const survey = store.surveys[layerModel.state.surveyId],
            asset = store.assets[this.assetId];

        if (survey && survey.hasElevationData && asset && asset.featureIds && asset.featureIds.length > 0) {

            const assetFormId = store.assetTypeToFormId[asset.assetTypeId],
                assetForm = store.assetForms[assetFormId],
                volumeControlTypeId = constants.controlTypeNameToId.volume;

            asset.featureIds.forEach(featureId => {

                const feature = featureListManager.getById(featureId);

                if (feature.geometry.type === 'Polygon') {

                    const featureType = appModel.toolbox.featureTypes[feature.properties.featureTypeId],
                        linkedControls = featureType.attributes.linkedControls || [];

                    if (linkedControls.length) {

                        const linkedVolumeControl = assetForm.controls.find(control =>
                            control.controlTypeId === volumeControlTypeId && linkedControls.indexOf(control.fieldName) !== -1
                        );

                        if (linkedVolumeControl) {

                            featureToControl.polygon[volumeControlTypeId](feature, linkedVolumeControl.fieldName, this.assetId);

                        }

                    }

                }

            });

        }

    }

    controlSuffix(control) {
        if (control.controlTypeId === constants.controlTypeNameToId.volume && siteModel.hasElevationData()) {
            return <span class="volume-control-recalculate" style="margin-left: 5px; font-size: 11px;"><a onclick={() => formModel.recalculateVolume()}> (Recalculate)</a></span>;
        }
        return '';
    }

    viewSurveyFiles(surveyId) {

        mediaViewerModel.wait();

        api.rpc.get('Survey', surveyId, {
            include: ['dataUpload', 'imageryUpload']
        }).then(survey => {

            mediaViewerModel.open([
                ...helpers.list(survey.dataUpload.mediaIds),
                ...helpers.list(survey.imageryUpload.mediaIds)
            ]);

            m.redraw();

        });

    }

    _next(isUp) {
        const assetIds = tableModel.assetIds;
        const index = assetIds.findIndex((assetId) => assetId === this.assetId);
        let nextAssetId;
        if (isUp) {
            if (index !== -1 && index - 1 > 0) {
                nextAssetId = assetIds[index - 1];
                this.viewAsset(nextAssetId);
            } else {
                nextAssetId = assetIds[assetIds.length - 1];
                this.viewAsset(nextAssetId);
            }
        } else {
            if (index !== -1 && index + 1 < assetIds.length) {
                nextAssetId = assetIds[index + 1];
                this.viewAsset(nextAssetId);
            } else {
                nextAssetId = assetIds[0];
                this.viewAsset(nextAssetId);
            }
        }
        assetListManager.panToAsset(nextAssetId);
    }

    propertyIsEmptyCoordinate(control, property) {
        return control.controlTypeId === constants.controlTypeNameToId.coordinates && !property.coordinates.length;
    }

    propertyIsEmptyUrlControl(control, property) {
        return control.controlTypeId === constants.controlTypeNameToId.URL && (!property.URL || !property.URL.length);
    }

    getReadOnlyValue(value, control) {

        const controlTypeNameToId = constants.controlTypeNameToId;

        switch (control.controlTypeId) {
        case controlTypeNameToId.file:
        case controlTypeNameToId.plan:
        case controlTypeNameToId.survey:
        case controlTypeNameToId.URL:
            return undefined;
            // In this case, rendering a read-only version
            // of the control value will be handled by the
            // control itself in form-controls.js
        case controlTypeNameToId.length:
        case controlTypeNameToId.area:
        case controlTypeNameToId.volume:
        case controlTypeNameToId.place:
            return cellValueToText(value, control.controlTypeId);
        case controlTypeNameToId.date:
            return formatDate.dateAndTime(new Date(value));
        case controlTypeNameToId.coordinates:
            return Array.from(value.coordinates).reverse().join(', ');
        case controlTypeNameToId.toggle:
            return value ? 'On' : 'Off';
        case controlTypeNameToId.user:
            return peopleModel.displayNameOrEmail(value);
        case controlTypeNameToId.asset:
            return value.map(contentId => <TinyCard
                assetId={contentId}
                popupContent={() => tinyAssetPopup(contentId, control)} onClick={(e) => {
                    e.stopPropagation();
                    formModel.viewAsset(contentId);
                }} />);
        default:
            if (Array.isArray(value)) {
                // first clear empty values:
                const values = value.filter(_value => !!_value);
                return values.join(', ');
            }
            return value;
        }
    }


    // Returns true if the control is restricted and the user is not an account admin.
    controlIsRestricted(assetTypeId, control) {
        return !appModel.user.isAccountAdmin
            && store.assetTypeFields[assetTypeId]
            && store.assetTypeFields[assetTypeId][control.fieldName]
            && store.assetTypeFields[assetTypeId][control.fieldName].restricted;
    }

    // Returns true if the current user access is limited to the asset in the form (based on: 1) Role, 2) Authorship, and 3) Assignment)
    isLimitedToUser() {
        if (formModel.viewAsLimitedUser === null) {
            formModel.viewAsLimitedUser = !appModel.user.isContentEditable(formModel.assetId);
        }
        return formModel.viewAsLimitedUser;
    }

    // Checks various ways a control field can be readonly, returns true if so.
    isReadOnlyControl(assetTypeId, control) {
        return control.attributes.readOnly
            || formModel.controlIsRestricted(assetTypeId, control)
            || formModel.isLimitedToUser();
    }

    // Checks if a control type is an embed (displays the content with no input)
    isEmbedControlType(control) {
        return control.controlTypeId === constants.controlTypeNameToId.embed;
    }

    initEvalArgs() {
        this.evalArgs = {};
        this.controls.forEach((control) => {
            if (control.attributes.eval) {
                control.attributes.eval.args.forEach((arg) => {
                    this.evalArgs[arg] = true;
                });
            }
        });
    }

    awaitChanges() {

        this.clearAwait();

        if (this.evalArgs.account || this.evalArgs.accountUser) {
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'modified',
                recordType: 'account',
                test: change => change.accountId === siteModel.accountId,
                callback: this.debouncedTriggerEval,
                persist: true
            }));
        }
        if (this.evalArgs.accountUser || this.evalArgs.projectUser) {
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'new',
                recordType: 'user',
                test: change => change.userId === appModel.user.userId,
                callback: this.debouncedTriggerEval,
                persist: true
            }));
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'modified',
                recordType: 'user',
                test: change => change.userId === appModel.user.userId,
                callback: this.debouncedTriggerEval,
                persist: true
            }));
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'deleted',
                recordType: 'user',
                test: change => change.userId === appModel.user.userId,
                callback: this.debouncedTriggerEval,
                persist: true
            }));
        }
        if (this.evalArgs.accountAuthor || this.evalArgs.projectAuthor) {
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'new',
                recordType: 'user',
                test: change => change.userId === store.assets[this.assetId].authorId,
                callback: this.debouncedTriggerEval,
                persist: true
            }));
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'modified',
                recordType: 'user',
                test: change => change.userId === store.assets[this.assetId].authorId,
                callback: this.debouncedTriggerEval,
                persist: true
            }));
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'deleted',
                recordType: 'user',
                test: change => change.userId === store.assets[this.assetId].authorId,
                callback: this.debouncedTriggerEval,
                persist: true
            }));
        }
        if (this.evalArgs.project || this.evalArgs.site || this.evalArgs.sites || this.evalArgs.projectUser) {
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'modified',
                recordType: 'project',
                test: change => change.projectId === router.params.projectId,
                callback: this.debouncedTriggerEval,
                persist: true
            }));
        }
        if (this.evalArgs.asset || this.evalArgs.assetProperties) {
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'new',
                recordType: 'content',
                test: change => change.contentId === this.assetId,
                callback: this.debouncedTriggerEval,
                persist: true
            }));
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'modified',
                recordType: 'content',
                test: change => change.contentId === this.assetId && publish.isValidChangedBy(change),
                callback: this.debouncedTriggerEval,
                persist: true
            }));
        }
        if (this.evalArgs.feature || this.evalArgs.featureProperties || this.evalArgs.features) {
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'modified',
                recordType: 'feature',
                test: change => change.properties.assetId === this.assetId && publish.isValidChangedBy(change),
                callback: this.debouncedTriggerEval,
                persist: true
            }));
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'new',
                recordType: 'feature',
                test: change => change.properties.assetId === this.assetId && publish.isValidChangedBy(change),
                callback: this.debouncedTriggerEval,
                persist: true
            }));
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'deleted',
                recordType: 'feature',
                test: change => change.properties.assetId === this.assetId,
                callback: this.debouncedTriggerEval,
                persist: true
            }));
        }
    }

    clearAwait() {
        if (this.calculatedFieldAwaits && this.calculatedFieldAwaits.length > 0) {
            this.calculatedFieldAwaits.forEach((remove) => remove());
        }
        this.calculatedFieldAwaits = [];
    }

    triggerEval(controlToSkip, onlyRequestControl = false) {
        if (!this.assetId) {
            return;
        }
        const asset = store.assets[this.assetId];
        const promises = [];
        this.controls.forEach((control) => {
            if (control.fieldName !== controlToSkip) {
                if (control.controlTypeId === constants.controlTypeNameToId.request) {
                    promises.push(this.updateRequestControl(control));
                } else if (!onlyRequestControl && control.attributes.eval) {
                    const originalValue = asset.properties[control.fieldName];
                    promises.push(evaluateControl(control, asset).then((evaluated) => {
                        // Only proceed to update the asset features & autosave if the value actually changed
                        if (evaluated && originalValue !== evaluated.response) {
                            asset.properties[control.fieldName] = evaluated.response;
                            if (asset.featureIds) {
                                // passing in "true" to skip triggering eval on this next update (we just did that -> causes infinite loop): UE-2683
                                updateAssetFeatures(control, this.assetId, true);
                            }
                        }
                    }));
                }
            }
        });
        m.redraw();
        return Promise.all(promises);
    }

    async updateRequestControl(control) {
        const attributes = control.attributes;
        let asset = store.assets[this.assetId];

        const urlPromise = attributes.url ? Promise.resolve(attributes.url) : evaluateControl(control, asset);

        let url = await urlPromise;
        if (url.response) {
            url = url.response;
        }
        const headers = {};
        if (url[0] === '/' && url[1] !== '/') {
            url = appUrl + url;
        }
        if (url.startsWith(appUrl)) {
            headers.authorization = proxyRequest.authorization();
        }
        return new Promise(resolve => {
            ajax(url, {
                headers,
                resolve: async _response => {
                    const result = await evaluateExpression(attributes.parse, { response: _response }, this.assetId);
                    const response = result.response;
                    const contentId = result.contentId;

                    asset = store.assets[contentId];
                    // This can happen if user left project or asset before eval completed.
                    if (!asset || contentId !== this.assetId) {
                        return;
                    }
                    const previousValue = asset.properties[control.fieldName];
                    if (response) {
                        asset.properties[control.fieldName] = String(response);
                    } else {
                        delete asset.properties[control.fieldName];
                    }
                    if (asset.properties[control.fieldName] !== previousValue) {
                        await this.triggerEval(control.fieldName);
                        assetListManager.autosave(asset.contentId);
                    } 
                    m.redraw();
                    resolve();
                }    
            });
        });
    }

    stopEdit() {

        if (this.editingFeatureId) {

            this.onEditStop();

            this.toolInterface.close();

        }

    }

    getLinksDisplayName() {
        // getLinksDisplayName(asset) {
        // TODO Return count once UE-2025 is resolved
        // if (formModel.saving.linksTab) {
        //     return <span class="saving-links-tab-title">Links<i class="spinner teal"/></span>;
        // }
        // if (asset.linkIds && asset.linkIds.length) {
        //     return  `Links (${asset.linkIds.length})`;
        // }
        return 'Links';
    }
}

const formModel = new FormModel();

initializer.add(() => formModel.init(), 'formModel');

export default formModel;
