import initializer from 'util/initializer';
import store from 'util/data/store';
import api from 'legacy/util/api';
import helpers from 'legacy/util/api/helpers';
import router from 'uav-router';
import appModel from 'models/app-model';
import PaginatedFetch from 'util/network/paginated-fetch';
import constants from 'util/data/constants';
import toolboxSelector from 'views/toolbox-selector/toolbox-selector';
import publish from 'legacy/util/api/publish';
import MenuModel from 'models/menu-model';
import formModel from 'models/form-model';
import panelModel from 'models/panel-model';
import PeopleList from 'views/people/people-list';
import modalModel from 'models/modal-model';
import AccountInfo from 'views/account-info';
import debounce from 'util/events/debounce';
import tableModel from 'models/table/table-model';
import sideNavModel from 'models/side-nav-model';
import exceptions from 'constants/util/exceptions-constants';
import message from 'views/toast-message/toast-message';
import {FIELD_PLAN_ID, FIELD_PLAN_ID_ANNUAL, PROMAPPER_PLAN_ID} from 'constants/managers/account-constants.js';
import {appUrl, setWhiteLabelEnvTo} from 'util/data/env';
import ToastMessage from 'views/toast-message/toast-message';

const LIST_API_REQUEST = (args) => api.rpc.request([['listAccounts', args]], false, true);
const LIST_API_ARGS = {include: ['users']};

class AccountManager {

    constructor() {
        initializer.addOnInit('account', () => this.reset());
    }

    reset() {
        this.accounts = [];
        this.loadedAll = false;
        this.isSearching = false;
        this.isInvalid = false;
        this.searchQuery = '';
        this.whiteLabelData = {};
        this.isWhiteLabelAccount = false;
        this.paginator = new PaginatedFetch(LIST_API_REQUEST,
            {
                args: LIST_API_ARGS,
                onResults: this.handleResults.bind(this),
                onComplete: this.onComplete.bind(this)
            });
        this.menu = new MenuModel({
            onAllMenuClicks: () => m.redraw(),
            items: [{
                itemId: 'portfolio',
                text: 'Portfolio',
                onClick: this.showPortfolio.bind(this)
            }, {
                itemId: 'people',
                text: 'People',
                onClick: this.showPeoplePanel
            }, {
                itemId: 'account-info',
                text: 'Account Info',
                onClick: this.showAccountInfo
            }]
        });
        this.search = debounce(this.search.bind(this), 1000);
        this.awaitChanges.bind(this);
    }

    /**
     * If a projectId is provided, we'll load that project
     * If not, we'll load the account of the user's last visited project using the lastVisitedProjectId.
     */
    _determineProjectId() {
        const preferences = appModel.user.preferences;
        const lastVisitedProjectId = preferences.lastVisitedSite && preferences.lastVisitedSite.projectId;
        return router.params.projectId || lastVisitedProjectId;
    }

    _determineStatusesAvailable(accountId) {
        // If a superadmin, let them view all accounts — otherwise, only if marked active or trial.
        const statusesAvailable = appModel.user.isSuperAdmin ? undefined : ['active', 'trial'];
        return accountId || router.params.projectId ? undefined : statusesAvailable;
    }

    init(accountId = router.params.accountId) {
        initializer.initManager('account');
        const projectId = accountId ? undefined : this._determineProjectId();
        const statusesAvailable = this._determineStatusesAvailable(accountId);
        return api.rpc.requests([
            ['listAccounts', {
                limit: 1,
                accountId,
                projectId,
                statusIn: statusesAvailable,
                include: ['users']
            }]
        ], true).then(async ([[account]]) => {
            const accounts = {};
            if (account) {
                account.attributes = account.attributes || {};
                account.users = helpers.list(account.users);
                account.projectIds = helpers.list(account.projectIds);
                account.brandName = account.brandName || '';
                if (projectId && (!router.params || !router.params.projectId)) {
                    router.url.set({'projectId': projectId});
                }
                this.useAccount(account);
                //refetch data after confirming account has brandName property & is actually aligned with account user logged into not just router url
                if (account.brandName === 'BlueConduit') {
                    this.isWhiteLabelAccount = true;
                    await this.setWhiteLabelAccountData();
                } else {
                    setWhiteLabelEnvTo(false);
                }
            } else {
                return api.rpc.request([['listAccounts', {
                    order: 'createdDateTime desc',
                    limit: 1
                }]], true, true).then(([fallbackAccount]) => {

                    if (!fallbackAccount) {
                        return appModel.user.showAccessDeniedMessage();
                    }

                    fallbackAccount.attributes = fallbackAccount.attributes || {};
                    fallbackAccount.users = helpers.list(fallbackAccount.users);
                    fallbackAccount.projectIds = helpers.list(fallbackAccount.projectIds);
                    accounts[fallbackAccount.accountId] = fallbackAccount;
                    store.setObject('accounts', accounts);
                    store.setObject('account', fallbackAccount);

                    if (['active', 'trial'].includes(appModel.user.checkAccountStatus())) {
                        this.useAccount(fallbackAccount);
                    }

                });

            }

        });
    }

    get hasSeatsAvailable() {
        return this.usedLicenseCount < store.account.seats;
    }

    select(account) {
        sideNavModel.close();
        router.url.set({
            accountId: account.accountId,
            projectId: account.attributes.metaProjectId
        });
        requestAnimationFrame(() => {
            this.setAccount(account);
            router.load();
        });
    }

    setAccount(account) {
        if (account) {
            if (account.attributes.appVersion === 1) {
                if (!constants.isDeployed) {
                    console.error('You\'ve loaded a V1 account using the V2 codebase. If this weren\'t localhost, you would be redirected to /1/');
                } else {
                    location.pathname = '/1/';
                    return;
                }
            }
            if (account.planName === PROMAPPER_PLAN_ID) {
                if (appModel.user.isSuperAdmin || constants.awsProfile !== 'production') {
                    const additionalMessage = appModel.user.isSuperAdmin
                        ? 'as a SuperAdmin you are able to view it here.' 
                        : `in the ${constants.awsProfile} environment you are able to view it here.`;
                    ToastMessage.show('You are viewing a ProMapper account. Normally this project would be redirected, but ' + additionalMessage, 'warning', true);
                } else {
                    location.href = `${appUrl}/embed-js/#unearthAccountId=${account.accountId}`;
                }
            }
            if (!store.account || store.account.accountId !== account.accountId) {
                toolboxSelector.accountToolboxes = null;
            }
            this.usedLicenseCount = account.users.filter(u => u.role !== 'guest' && u.role !== 'viewer').length;
            store.setObject('account', account);
        }
    }

    // Alternative fetch without pagination
    loadAll() {
        return LIST_API_REQUEST(LIST_API_ARGS).then(results => {
            this.handleResults(results);
            this.loadedAll = true;
            this.paginator.isComplete = true;
        });
    }

    search(query) {
        if (!query) {
            if (this.isSearching) {
                return this.clearSearch();
            }
            // Empty query provided but we didnt have an existing query, so do nothing.
            return;
        }
        this.isSearching = true;
        this.loadedAll = false;
        this.accounts = [];
        m.redraw();
        // Restart paginator using a simple search with our passed args.
        this.paginator.apiRequest = (args) => api.simpleSearch(args);
        this.paginator.args = {
            type: 'account',
            searchType: 'search',
            query,
            include: ['users']
        };
        this.paginator.restart();
    }

    // Clears the current search results and resumes normal pagination of accounts
    clearSearch() {
        this.accounts = [];
        this.loadedAll = false;
        this.isSearching = false;
        this.paginator.apiRequest = LIST_API_REQUEST;
        this.paginator.args = LIST_API_ARGS;
        this.searchInput.value = '';
        m.redraw();
        this.paginator.restart();
    }

    handleResults(results) {
        const allAccounts = {...store.accounts};

        let gotUsableResults;
        results.forEach((accountTemp) => {
            accountTemp.users = helpers.list(accountTemp.users);
            accountTemp.projectIds = helpers.list(accountTemp.projectIds);
            allAccounts[accountTemp.accountId] = accountTemp;

            if (accountTemp.status !== 'expired' && accountTemp.status !== 'suspended') {
                gotUsableResults = true;
                this.accounts.push(accountTemp);
            }
        });

        store.setMutableContainer('accounts', allAccounts);
        if (!this.paginator.isComplete) {
            // Edge case: The user scroll event might not trigger this.next() if the usable results are too few (no overlow-y). In that case, force the retrieval of the next page rather than waiting for a scroll event.
            if (!gotUsableResults || this.accounts.length < this.paginator.pageLimit) {
                this.paginator.next();
            }
        }
        m.redraw();
    }

    onComplete() {
        this.loadedAll = true;
    }

    // ---- Account Menu Actions ------

    showPortfolio() {
        panelModel.close();
        this.menu.activeItemId = 'portfolio';
        if (router.params.assetId) {
            formModel.validateThenRun(() => formModel.close());
        } else {
            m.redraw();
        }
    }

    showPeoplePanel() {
        if (router.params.assetId) {
            formModel.validateThenRun(() => {
                formModel.close();
                panelModel.open({view: PeopleList, onclose: tableModel.recallTable});
            });
        } else {
            panelModel.open({view: PeopleList, onclose: tableModel.recallTable});
        }
        tableModel.dismissTable();
    }

    showAccountInfo() {
        panelModel.close();
        modalModel.open({view: AccountInfo});
    }

    // If nothing is active, default to portfolio as active — unless we're viewing an asset.
    updateActiveMenuItem() {
        if (!router.params.assetId) {
            if (!this.menu.activeItemId) {
                this.menu.activeItemId = 'portfolio';
            }
        } else if (this.menu.activeItemId === 'portfolio') {
            this.menu.resetActive();
        }
    }

    useAccount(account) {
        this.accountId = account.accountId;
        const metaProjectId = account.attributes.metaProjectId;

        if (metaProjectId && !router.params.projectId) {
            appModel.project.setIsMetaProject(true);
            m.redraw();
            router.url.set({projectId: metaProjectId});
        }

        if (!store.accounts) {
            store.setMutableContainer('accounts', {});
        }

        if (!store.accounts[account.accountId]) {
            const _accounts = {...store.accounts};
            _accounts[account.accountId] = account;
            store.setObject('accounts', _accounts);
        }

        if (!store.account || store.account.accountId !== account.accountId) {
            store.setObject('account', account);
        }

        this.setAccount(account);
    }

    // ---- Set up publish handling ----

    awaitChanges() {
        publish.await({
            changeType: 'modified',
            recordType: 'account',
            test: change => change.accountId === store.account.accountId,
            callback: account => {
                account.users = helpers.list(account.users).filter(user => user && (!user.hasOwnProperty('isVisible') || user.isVisible));
                account.projectIds = helpers.list(account.projectIds);
                this.setAccount(account);
            },
            persist: true
        });
    }


    handle(exceptionCode) {
        switch (exceptionCode) {
        case exceptions.INITTING_DELETED_PROJECT:
            // TOORGANIZE exception handling logic
            const metaProjectId = store.account.attributes.metaProjectId;

            if (metaProjectId !== appModel.project.projectId) {
                appModel.changeProject(metaProjectId);
                message.show('That Project was deleted. Returning to Portfolio.', 'warning');
            }

        }

    }

    get isFieldTrial() {
        return appModel.user.isTrialUser && this.isFieldPlan || appModel.user.isViewingAs(FIELD_PLAN_ID) || appModel.user.isViewingAs(FIELD_PLAN_ID_ANNUAL);
    }

    get isFieldPlan() {
        return store.account.planName === FIELD_PLAN_ID || store.account.planName === FIELD_PLAN_ID_ANNUAL;
    }

    get accountWiki() {
        if (this.isWhiteLabelAccount) {
            return this.whiteLabelData.supportOptions.link;
        }
        return constants.defaultSupportLink;
    }

    get accountPhone() {
        return 'tel+' + constants.defaultSupportPhoneReference;
    }

    get accountDisplayPhone() {
        return constants.defaultSupportPhoneDisplay;
    }

    get accountEmail() {
        if (this.isWhiteLabelAccount) {
            return this.whiteLabelData.supportOptions.email;
        }
        return constants.defaultSupportEmail;
    }

    get accountLogo() {
        return '';
    }

    get accountIcon() {
        return '';
    }

    async setWhiteLabelAccountData() {
        const response = await new Promise(resolve => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', 'https://static.unearthlabs.com/whitelabelConfig.json', true);
            xhr.onload = function() {
                resolve(xhr.response);
            };
            xhr.onerror = function () {
                resolve(undefined);
                console.error('An error occurred during the XMLHttpRequest to set Company White Label Data');
            };
            xhr.send();
        });
        this.whiteLabelData = JSON.parse(response);
    }
}


export default new AccountManager();
