/**
 * Example: urnify('toolbox', 123456) returns 'urn:ue:toolbox:123456'
 * @param {*} nid = namespace specific id, such as "toolbox" for a "toolboxId" or "content" for a "contentId"  
 * @param {*} nss = namespace specific string (ie, the actual toolboxId value if sending a toolboxId). 
*/
export const urnify = (nid, nss) => `urn:ue:${nid}:${nss}`;

/**
 * Example: deUrnify('urn:ue:toolbox:123456') returns '{nid: "toolbox", nss: "123456"}'
 * @param {*} urn = urn string from an api payload  
*/
export const deUrnify = (urn) => {
    const [nid, nss] = urn.replace('urn:ue:', '').replace('::', ':').split(':');
    return {nid, nss};
};
