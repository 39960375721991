import popup from 'util/popup';
import randomId from 'util/numbers/random-id';
import oneUpModel from 'models/one-up-model';
import onBodyClick from '../legacy/util/dom/on-body-click';
import {PortableOneUp} from 'views/one-up/one-up';
import siteModel from 'models/site-model';

class LibraryModel {

    constructor() {
        this.reset();
    }

    reset() {
        this.portKey = undefined; // Key that the view requiring the library should reference to when rendering
        this.isOpen = false;
        this.linkFlowId = undefined;
        this.savedCameraLocation = undefined;
    }

    get isPorted() {
        return !!this.portKey;
    }

    isPortedTo(key) {
        return this.portKey === key;
    }

    isOpenOn(key) {
        return this.isPortedTo(key) && this.isOpen;
    }

    getCssClassForKey(key) {
        const isPortClass = this.portKey === key ? ' library-port' : '';
        const isOpenClass = isPortClass ? this.getOpenCssClass() : '';

        return `${isPortClass}${isOpenClass}`;
    }

    getOpenCssClass() {
        return this.isOpen ? ' library-open' : '';
    }

    /*
    * Call from external file if need to quit at some point before flow completes, for example, onremove, on scroll, on body click, or opening another popup. Checks if this instance is open before c
    */
    quit() {
        if (libraryModel.portKey) {
            if (libraryModel.isOpen) {
                libraryModel._quit();
            }
        }
        libraryModel.reset();
    }

    /*
     * Internal quit only — doesn't check first that it is in fact open
     */
    _quit() {
        libraryModel.isOpen = false;
        oneUpModel.parentCancel(libraryModel.linkFlowId);
        libraryModel.cleanupAfterSelect();
        m.redraw();
    }

    /*
    * Case when close request is received from the one up window
    */
    handleOneUpClose() {
        libraryModel.isOpen = false;
        delete libraryModel.linkFlowId;
        libraryModel.cleanupAfterSelect();
    }

    /*
    * Opens OneUp with passed selection args.
    */
    select(args = {}, onSelect, onComplete) {
        libraryModel.isOpen = true;
        m.redraw();

        if (!libraryModel.linkFlowId) {
            libraryModel.linkFlowId = randomId();
        }

        libraryModel.savedCameraLocation = siteModel.map.getCamera();
        onBodyClick.once(libraryModel._quit);
        window.removeEventListener('resize', libraryModel._quit);

        oneUpModel.onAssetSelection = onSelect;
        oneUpModel.addLibraryFlow({
            flowId: libraryModel.linkFlowId,
            makeVisible: oneUpModel.cssClass === 'hidden',
            close: libraryModel.handleOneUpClose,
            ...args
        }).then((records) => {
            onComplete(records);
            libraryModel.isOpen = false;
            libraryModel.cleanupAfterSelect();
        });
    }

    /*
    * Run after a selection process to remove anything left on.
    */
    cleanupAfterSelect() {
        popup.remove();
        onBodyClick.clear();
        window.removeEventListener('resize', libraryModel._quit);
        if (libraryModel.savedCameraLocation) {
            siteModel.map.setCamera(libraryModel.savedCameraLocation);
        }
        oneUpModel.onAssetSelection = undefined;
        m.redraw();
    }

    portLibraryTo(key) {
        if (libraryModel.linkFlowId && oneUpModel.promises[libraryModel.linkFlowId]) {
            this._quit();
        }
        this.portKey = key;
        m.redraw();
    }

    /*
    * If the key passed id the active port, render the port div. Otherwise, render nothing.
    */
    renderPort(key) {
        return this.isPortedTo(key) ? <PortableOneUp/> : '';
    }

}


const libraryModel = new LibraryModel();

export default libraryModel;
