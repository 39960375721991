import importModel from 'models/import-model';

const AnalyzingStep = {
    name: 'Select Import File',
    view: () => importModel.failed
        ? <div class="data-import-analyze">
            <div class="data-import-section">
                <div class="import-failed">
                    <i class="icon-error" /> {importModel.maxExceeded
                        ? `This file can't be imported because it has more than ${importModel.maxAssets} rows`
                        : 'Something went wrong while attempting to process this file'}. <a onclick={() => importModel.upload()}>Choose another file</a>.
                </div>
            </div>
        </div>
        : importModel.backPressed ?
            <div class="data-import-analyze">
                <div class="data-import-section">
                    <div><h3>Import a Different File</h3></div>
                </div>
            </div> : <div className="data-import-analyze">
                <div className="data-import-section">
                    <i className="teal spinner spinning"/>
                    <div><h3>Analyzing Import</h3></div>
                </div>
            </div>
            
};

export default AnalyzingStep;
