import m from 'mithril';
import screenHelper from 'legacy/util/device/screen-helper';

window.m = m;

// WEB-2066 when an exception is thrown within a component's view method,
// mithril completely chokes and enters an unrecoverably broken state,
// often repeating elements in the UI. This code wraps mithril's m function
// so that exceptions in view methods are automatically caught. This way,
// the rest of the app can function as normal when one component fails.
// const mightBeView = {
//     object: true,
//     function: true
// };

// function wrapView(component) {

//     const view = component.view;

//     component.view = (...args) => {

//         try {

//             return view.apply(component, args);

//         } catch (err) {

//             console.error(err);

//             return null;

//         }

//     };

// }

// window.m = (component, ...args) => {

//     if (mightBeView[typeof component]) {

//         if (component.view) {

//             wrapView(component);

//         } else if (component.prototype && component.prototype.view) {

//             wrapView(component.prototype);

//         }

//     }

//     return m(component, ...args);

// };

// Object.assign(window.m, m);
// \WEB-2066

// Element.remove
if (!Element.prototype.hasOwnProperty('remove')) {

    Object.defineProperty(Element.prototype, 'remove', {
        configurable: true,
        enumerable: true,
        writable: true,
        value: function remove() {
            if (this.parentNode) {
                this.parentNode.removeChild(this);
            }
        }
    });

}


// babel-pollyfill's Array.from shim breaks IE
if (screenHelper.ie || !Array.from) {
    const ArrayFrom = Array.from;
    Array.from = object => object instanceof Set ? ArrayFrom(object) : [].slice.call(object || []);
}

// Element.matches
if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}
