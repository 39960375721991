import router from 'uav-router';
import oneUpModel from 'models/one-up-model';
import cache from 'legacy/util/data/cache';

class OneUp {
    oninit() {
        const isLoggedIn = cache.get('ueRefresh');
        if (!isLoggedIn) {
            // Must be logged in to init one-up.
            return;
        }

        oneUpModel.updateUrlIfLocalhost();
    }

    oncreate({dom}) {
        oneUpModel.setIframeWindow(dom.children[0].contentWindow);
    }

    view() {
        const {projectId} = router.params;
        const cssClass = projectId === undefined ? 'hidden' : oneUpModel.getCssClass();
        return <div id="one-up" class={`one-up ${cssClass}`} style={oneUpModel.cssPosition}>
            <iframe id="one-up-main" name="one-up-main" src={oneUpModel.src}/>
        </div>;
    }
}

export const PortableOneUp = {
    oncreate: ({dom, attrs}) => oneUpModel.setAnchorElement(dom, attrs.type),

    onremove: () => oneUpModel.setAnchorElement(null),

    view: () => {
        return <div class="portable-one-up-anchor"></div>;
    }
};


export default OneUp;
