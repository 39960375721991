import randomId from 'util/numbers/random-id';
import FolderItemModel from 'models/folder/folder-item-model';
import appModel from 'models/app-model';
import graphql, {gqlResponse} from 'util/graphql/graphql';
import {deUrnify, urnify} from 'util/network/urnify';
import {normalizeUtcTimestamp} from 'util/data/helpers';
import Cached from 'util/data/cached';
import layerModel from 'models/layer-model';
import store from 'util/data/store';
import { datadogRum } from '@datadog/browser-rum';
import logConstants from 'constants/managers/log-constants';

/**
 * Can contain FolderModels or FolderItemModels (recursive)
 * Contents is defined by this.zOrder, but items should be retrieved
 * from the root using this.root.getByUrn(someUrn);
 */
class FolderModel extends FolderItemModel {
    constructor(_apiFolderObject = {}, root) {
        super(_apiFolderObject, root);
        const isNew = !_apiFolderObject.id; // If not passed in with a id, we can assume it's new. 

        this.id = _apiFolderObject.id || randomId();
        this.name = _apiFolderObject.name || 'New Group';
        this.projectId = _apiFolderObject.project && _apiFolderObject.project.id || appModel.project.projectId;
        _apiFolderObject.parentId = _apiFolderObject.parentId || _apiFolderObject.parent && _apiFolderObject.parent.id;
        this.parentId = _apiFolderObject.parentId || (root ? root.id : undefined);
        // zOrder is stored in bottom-to-top order,
        // but it's much easier to operate on as top-to-bottom,
        // so we'll keep it reversed in memory & re-reverse it in calls to the api 
        this.zOrder = _apiFolderObject.zOrder ? Array.from(_apiFolderObject.zOrder).reverse() : [];

        this.recordType = 'folder';
        this._root = root;
        this._isEditableByUser = 0; // Will increment or decrement when adding or removing editable items to the folder.
        this._childPlans = {}; // Will maintain as adding or removing editable items to the folder.
        this.urn = urnify(this.recordType, this.id);

        this.state = {
            isNew,
            isSaving: false,
            isToggledOn: false,
            isExpanded: false,
            subFoldersLoaded: isNew ? true : false
        };

        this.currentHex = '#fffff1';
        this.currentOpacity = '0.999';

        return this;
    }

    get isEditableByUser() {
        return this.cache.get('isEditableByUser', () => {
            const isEditableByUser = this._isEditableByUser > 0;
            if (!isEditableByUser) {
                for (let i = 0; i < this.subfolders.length; i++) {
                    const subfolder = this.subfolders[i];
                    if (subfolder.isEditableByUser) {
                        return true; // just need a single editable descendant for this to display as editable
                    }
                }
                return false;
            }
            return true;
        });
    }

    get isRoot() {
        return this.id === this.parentId;
    }
    
    get childPlans() {
        return Object.keys(this._childPlans);
    }
    
    get hasPlansInDescendants() {
        return !!this.descendantPlans.length;
    }
    
    get descendantPlans() {
        return this.cache.get('descendantPlans', () => {
            const allPlansInDescendants = [...this.childPlans];
            this.subfolders.forEach(subfolder => allPlansInDescendants.push(...subfolder.descendantPlans));
            return allPlansInDescendants;
        });
    }
    
    get subfolders() {
        return this.cache.get('subfolders', () => {
            const subfolders = [];
            this.zOrder.forEach(urn => {
                const item = this.root.getByUrn(urn);
                if (item && item.recordType === 'folder') {
                    subfolders.push(item);
                }
            });
            return subfolders;
        });
    }

    get canEditColor() {
        const items = this.getItems();
        for (let i = 0; i++; i < items.length) {
            const item = items[i];
            if (this.root.editableMap.hasOwnProperty(item.urn)) {
                return this.root.editableMap[item.urn];
            }
            return item.canEditColor; 
        }
        return false;
    }

    get expandCollapseCssClasses() {
        return `${this.state.isExpanded ? 'expanded' : 'collapsed'}`;
    }

    get planZOrder() {
        return this.cache.get('planZOrder', () => {
            const zOrders = [];
            for (let i = 0; i < this.zOrder.length; i++) {
                const urn = this.zOrder[i];
                const {nid, nss} = deUrnify(urn);
                if (nid === 'plan') {
                    zOrders.push(nss);
                } else {
                    const item = this.getByUrn(urn);
                    const zOrderOfSubFolder = item ? item.planZOrder : undefined;
                    if (zOrderOfSubFolder) {
                        zOrders.push(...zOrderOfSubFolder);
                    }
                }
            }
            return zOrders;
        });
    }

    get cssWrapClass() {
        return this.root.editingNameOn === this.urn ? 'is-editing' : 'not-editing';
    }

    clearCache() {
        this.cache = new Cached();
        m.redraw();
    }

    async fetchFolders(recursive = true) {
        // Use the projectId from the root folder to make sure we edit the correct project (in case we are editing child project plans from the meta project view, eg)
        const {projects} = gqlResponse(await graphql.listLayerFolders(this.root.projectId, recursive ? undefined : this.id));
        const layerFolders = projects[0].layerFolders;
        if (layerFolders) {
            layerFolders.forEach(apiFolderObj => {  
                if (apiFolderObj.id !== this.id) {
                    const folder = new FolderModel(apiFolderObj, this.root);
                    this.root.items[folder.urn] = folder;
                } 
            });
        }

        this.loadSubfolders(recursive);
        this.validateContents(true);
    }

    loadSubfolders(recursive = false) {
        this.state.subFoldersLoaded = true;
    
        if (!this.zOrder.length) {
            return m.redraw();
        }

        // Add plans
        this.zOrder.forEach(urn => {
            const {nid, nss} = deUrnify(urn);
            if (nid === 'plan') {
                const planFromStore = store.plans[nss];
                if (planFromStore) {
                    const name = planFromStore ? planFromStore.title : undefined;
                    const plan = new FolderItemModel({id: nss, parentId: this.id, name, updatedDateTime: planFromStore.updatedDateTime}, this.root, 'plan');
                    this.add(plan.urn, plan);
                } else {
                    this.add(urn, new FolderItemModel({id: nss, parentId: this.id, name: 'Plan'}, this.root, 'plan'));
                }
            }
        });

        this.state.isExpanded = true; // Since this folder has items in it, default to showing it as expanded when we first load it.

        // Call to load subfolders
        if (recursive) {
            this.subfolders.forEach(folder => folder.loadSubfolders(recursive));
        }

        this.state.subFoldersLoaded = true;
        m.redraw();
    }

    add(urn, item, atIndex = undefined) {
        if (!this.state.isToggledOn && item.state.isToggledOn) {
            this.turnVisibilityOnFromChild(true);
        }
        if (atIndex !== undefined) {
            this.zOrder.splice(atIndex, 0, urn);
        } else if (!this.zOrder.find(u => u === urn)) {
            this.zOrder.push(urn);
        }
        if (item.recordType === 'plan') {
            if (item.isEditableByUser) {
                this._isEditableByUser += 1;
            }
            this._childPlans[item.urn] = true;
        }
        this.root.items[urn] = item;
        this.cache = new Cached();
    }

    remove(urn, updateZOrder = true) {
        const item = this.root.getByUrn(urn);
        if (item && item.recordType === 'plan') {
            if (item.isEditableByUser) {
                this._isEditableByUser -= 1;
            }
            delete this._childPlans[item.urn];
        }
        const index = this.zOrder.indexOf(urn);
        if (index > -1 && updateZOrder) {
            this.zOrder.splice(index, 1);
        }

        delete this.root.items[urn];
        this.cache = new Cached();
    }

    async createSubfolder(initialData = {}) {
        this.root.closePopups();
        this.state.creatingNewFolder = true;
        m.redraw();
        const {createLayerFolder} = gqlResponse(await graphql.createLayerFolder(initialData.name || 'New Group', this.root.projectId, this.id));
        const newFolder = new FolderModel(createLayerFolder, this.root);
        this.add(newFolder.urn, newFolder, 0);
        this.saveToApiZorder();
        newFolder.state.subFoldersLoaded = true;
        this.state.creatingNewFolder = false;
        m.redraw();
        return this;
    }


    onOrderUpdate() {
        this.cache = new Cached();
        this.subfolders.forEach(folder => folder.onOrderUpdate());
    }

    getByUrn(urn) {
        if (urn === this.urn) {
            return this;
        }
        return this.root.items[urn];
    }

    getItems() {
        return this.zOrder.map(urn => this.root.getByUrn(urn)).filter(item => item);
    }

    getRestrictionMessage(action = 'edit', property = 'color') {
        if (property === 'name') {
            return ''; // anyone can edit the name of a folder
        }
        if (action === 'delete' && !this.hasPlansInDescendants) {
            return ''; // anyone can delete an empty folder
        }
        return super.getRestrictionMessage(action);
    }

    setVisibility(toOn, recursive = false) {
        this.state.isToggledOn = toOn;
        m.redraw();

        this.childPlans.forEach(urn => {
            const item = this.root.getByUrn(urn);
            const planFromStore = store.plans[item.id];
            if (toOn) {
                layerModel.showPlan(planFromStore);
            } else {
                layerModel.hidePlan(planFromStore);
            }
        });
        this.subfolders.forEach(folder => folder.setVisibility(toOn, recursive));
    }

    toggleVisibility(e) {
        if (e) {
            e.stopPropagation();
        }
        this.root.toggleMenuOn(null);
        this.root.toggleColorPickerOn(null);
        const newState = !this.state.isToggledOn;
        this.setVisibility(newState, true);
    }

    /**
     * Determine if we're dragging above the folder (in the drop zone) or _into_ the folder.
     */
    calculateIfInDropZone(e) {
        const mouseTargetPosition = e.offsetY;
        const targetHeight = e.target.offsetHeight;
        return targetHeight / 2 > mouseTargetPosition;
    }

    saveToApiZorder() {
        this.state.isSaving = true;
        const zOrder = Array.from(this.zOrder).reverse();
        m.redraw();
        return graphql.modifyLayerFolderZorder(this.id, zOrder).then((response) => {
            this.setUpdatedDateTime(response);
            this.state.isSaving = false;
            m.redraw();
            return this;
        });
    }
    
    async saveToApiName() {
        return graphql.modifyLayerFolderName(this.id, this.name).then((response) => {
            this.setUpdatedDateTime(response);
            return this;
        });
    }

    async saveToApi() {
        const zOrder = Array.from(this.zOrder).reverse();
        return graphql.modifyLayerFolder(this.id, this.name, this.parentId, zOrder).then((response) => {
            this.setUpdatedDateTime(response);
            this.state.isSaving = false;
            this.root.toggleEditingNameOn(null);
            m.redraw();
            return this;
        });
    }

    setUpdatedDateTime(response) {
        if (response.data && response.data.modifyLayerFolder && response.data.modifyLayerFolder.updatedAt) {
            this.updatedDateTime = normalizeUtcTimestamp(response.data.modifyLayerFolder.updatedAt);
        }
    }
        
    setColor(hex, opacity) {
        const promises = [];
        this.zOrder.forEach(urn => {
            const item = this.root.getByUrn(urn);
            if (item) {
                promises.push(item.setColor(hex, opacity));
            }
        });
        return Promise.all(promises).then(() => {
            this.currentHex = hex; 
            this.currentOpacity = opacity;
            m.redraw();
        });
    }

    async deleteChildLayers(recursive = false) {
        const promises = [];
        this.zOrder.forEach(urn => {
            const item = this.root.getByUrn(urn);
            if (item) {
                promises.push(item.delete(recursive));
            }
        });
        this.zOrder = [];
        this.saveToApiZorder();
        return Promise.all(promises);
    }

    async delete(recursive = false) {
        this.state.isSaving = true;
        m.redraw();

        if (recursive) {
            await this.deleteChildLayers(true);
        }
        if (!this.isRoot) {
            await graphql.deleteLayerFolder(this.id);
            this.parent.remove(this.urn);
            if (!recursive) {
                this.parent.saveToApiZorder();
            }
        }

        this.state.isSaving = false;
        m.redraw();
    }

    /*
    * Folders and plans are maintained by the clients; there are cases where they may get out of sync
    * If a plan was deleted, but not removed from its folder zOrder, we'll clean that up here. 
    */
    validateContents(recursive = false) {
        this.zOrder.forEach(urn => {
            const item = appModel.getByUrn(urn);
            if (!item) {
                console.warn('item missing from folderId', this.id, urn);
                datadogRum.addError(logConstants.errorLogMessages.ITEM_MISSING_FROM_FOLDER, {userId: appModel.user.userId, folderId: this.id, urn, projectId: appModel.project.projectId});
                this.remove(urn);
                this.saveToApiZorder();
            } else if (item && item.parentId !== this.id) {
                item.handleConflictingParentId(this.id);
            }
        });
       
        // Call to load subfolders
        if (recursive) {
            this.subfolders.forEach(folder => folder.validateContents(recursive));
        }

    }

}

export default FolderModel;
