function selectRange(el) {

    const range = document.createRange();

    range.selectNodeContents(el);

    const selection = window.getSelection();

    selection.removeAllRanges();

    selection.addRange(range);

}

selectRange.clear = () => {

    let selection;

    if (window.getSelection) {
    
        selection = window.getSelection();
    
    } else if (document.selection) {
    
        selection = document.selection;
    
    }
    
    if (selection) {

        if (selection.empty) {

            selection.empty();

        }

        if (selection.removeAllRanges) {

            selection.removeAllRanges();

        }

    }

};

export default selectRange;
