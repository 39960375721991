import {whiteLabelAccount} from 'util/data/env';
import router from 'uav-router';
import accountManager from 'managers/account-manager';

const Offline = {
    view: () => {
        return <div class="auth offline">
            <header class="header">
                <div class="header-left" onclick={() => router.set()}>
                    {whiteLabelAccount ?
                        <img className="whitelabel" src={accountManager.whiteLabelData ? accountManager.whiteLabelData.logos.logo : ''}/> :
                        <a class="home"/>}
                </div>
            </header>
            <div class="offline-body">
                <div class="form-msg">
                    <h1>Device Offline</h1>
                </div>
                <div class="text-wrap">
                    <span>No internet connection detected.</span>
                    <span>We'll keep trying to reconnect.</span>
                </div>
            </div>
        </div>;
    }
};

export default Offline;

