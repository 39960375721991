import constants from 'util/data/constants';
import {imageMimeTypes, heicMimeType, fileTypeIconIds} from 'constants/managers/media-constants';
import {downloadFromSourceUrl} from 'util/media/media-utils';
import {parseExpiration} from 'util/media/media-utils';

class MediaRecordModel {
    constructor(_apiMediaRecord) {
        this._expiration = undefined;
        this._iconUrl = undefined;
        this._previewUrl = undefined;
        Object.assign(this, _apiMediaRecord);
        this.normalize(_apiMediaRecord);
        this.parseUrls(_apiMediaRecord);
    }

    normalize(apiMediaRecord = {}) {
        this.sizes = {};
        this.formats = {};
        this.attributes = apiMediaRecord.attributes || {};
    }

    get isImage() {
        return !!imageMimeTypes[this.mimeType];
    }

    get canCreateLayer() {
        return this.isImage && this.mimeType !== heicMimeType || this.mimeType === 'application/pdf' || this.mimeType === 'application/zip';
    }

    get isVideo() {
        return this.mimeType && this.mimeType.startsWith('video');
    }

    get srcUrl() {
        if (this.isExpired()) {
            return constants.mediaURL + this.mediaId;
        }
        return this.urls && (this.urls.originalMediaUrl || this.urls.publicBucket);
    }
    
    get previewSrcUrl() {
        if (this.isExpired()) {
            return constants.mediaURL + this.mediaId;
        }
        return this._previewUrl || (this.mediaType === 'photo' || this.mediaType === 'image' ? this.srcUrl : null);
    }

    get mediaViewerSrcUrl() {
        if (this.isExpired()) {
            return constants.mediaURL + this.mediaId;
        }
        return this._mediaViewerSrcUrl || this.previewSrcUrl;
    }

    get videoViewerSrcUrl() {
        if (this.isExpired()) {
            return constants.mediaURL + this.mediaId;
        }
        return this._mediaViewerSrcUrl || this.srcUrl;
    }

    get iconSrcUrl() {
        return this._iconUrl || this._previewUrl || (this.mediaType === 'photo' ? this.srcUrl : null);
    }

    getIconSourceUrl() {
        if (this.isExpired()) {
            return constants.mediaURL + this.mediaId;
        }
        return this.iconSrcUrl;
    }
    
    get thumbnailUrl() {
        const mimeType = this.mimeType;
        if (!mimeType) {
            return constants.staticMediaURL + fileTypeIconIds.image;
        }
        if (!this.status || !this.status === 'pending' || !this.mimeType) {
            return constants.staticMediaURL + fileTypeIconIds.image; // Fallback in cases of corrupt media that don't have a mimeType associated
        }
        if (this.isImage) {
            return this.previewSrcUrl;
        } else if (this.mimeType.startsWith('video')) {
            return this.previewSrcUrl || constants.staticMediaURL + fileTypeIconIds.video;
        } else if (this.mimeType.startsWith('audio')) {
            return constants.staticMediaURL + fileTypeIconIds.audio;
        }
        return constants.staticMediaURL + fileTypeIconIds.image; 
    }

    get thumbnailData() {
        return {
            mediaId: this.mediaId,
            src: this.thumbnailUrl,
            isImage: this.isImage,
            name: this.label
        };
    }

    get is360() {
        return this.attributes && this.attributes.is360;
    }

    get panoramaSrc() {
        return this._360Url;
    }

    parseUrls(apiMediaRecord = {}) {
        const urlArrays = [];
        if (apiMediaRecord.urls) {
            if (apiMediaRecord.urls.alternateSizes) {
                urlArrays.push(...apiMediaRecord.urls.alternateSizes);
            }
            if (apiMediaRecord.urls['360Images']) {
                urlArrays.push(...apiMediaRecord.urls['360Images']);
            }
            if (apiMediaRecord.urls.videos) {
                urlArrays.push(...apiMediaRecord.urls.videos);
            }
        }

        let smallest = undefined,
            largestKey = undefined,
            largestSize = undefined,
            preview = undefined;

        urlArrays.forEach(url => {
            try {
                let urlSuffix = url.split('?')[0];
                urlSuffix = urlSuffix.split('/');
                urlSuffix = urlSuffix[urlSuffix.length - 1];
                const [size, format] = urlSuffix.replace(apiMediaRecord.mediaId, '').replace('-', '').split('.');
                if (size) {
                    this.sizes[size] = url;
                    let width = size;
                    const sizes = size.split('x');

                    if (sizes.length && sizes.length === 2) {
                        width = Number(sizes[0]);
                    }
                    if (!smallest || width < smallest) {
                        smallest = Number(size);
                    }

                    if (!largestSize || width > largestSize) {
                        largestSize = width;
                        largestKey = size;
                    }

                } 
                if (format) {
                    this.formats[format] = url;
                    if (!preview && (format === 'png' || format.includes('thumbnail'))) {
                        preview = format;
                    }
                }
            } catch {
                console.warn('unable to parse', url);
            }
                
        });
        this._iconUrl = this.sizes[smallest];
        this._360Url = this.sizes[largestKey];
        this._previewUrl = preview ? this.formats[preview] : undefined;     
        // If it's viewable in the media viewer in its original state, use that for the preview:
        if (this.isImage && this.mimeType !== heicMimeType && apiMediaRecord.urls) {
            this._mediaViewerSrcUrl = apiMediaRecord.urls.originalMediaUrl;
        } else if (this.isVideo && this.formats.h264) {
            this._mediaViewerSrcUrl = this.formats.h264;
        }
    }
        
    isExpired() {
        if (!this.urls) {
            return true;
        }
        if (this._expiration === undefined) {
            this._expiration = parseExpiration(this.urls.originalMediaUrl || this.urls.publicBucket);
        }
        return this._expiration === null || this._expiration < new Date();
    }

    download() {
        return downloadFromSourceUrl(this.srcUrl, this.label);
    }

    openInNewTab() {
        const url = `${window.location.origin}/#mediaId=${this.mediaId}`;
        window.open(url, '_blank');
    }

    getExtension() {
        const labelSplit = this.label.split('.');
        if (labelSplit.length > 1) {
            return labelSplit.pop().toLowerCase();
        }
        return null;
    }
}

export default MediaRecordModel;
