import analytics from 'util/data/analytics';
import cache from 'legacy/util/data/cache';

const HIDE_LAUNCHER_KEY = 'hideMobileLauncher';

class MobileLauncherModel {

    constructor() {
        this.mobileWebOS = !cache.get(HIDE_LAUNCHER_KEY) && analytics.getMobileWebOS();
        this.dismiss = this.dismiss.bind(this);
        this.dismissAndCache = this.dismissAndCache.bind(this);
    }

    dismiss() {
        this.mobileWebOS = null;
    }

    dismissAndCache() {
        this.dismiss();
        cache.set(HIDE_LAUNCHER_KEY, true);
    }

}

export default new MobileLauncherModel();
