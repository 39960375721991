import isMetric from 'util/numbers/is-metric';
import store from 'util/data/store';
import adaptiveRound from 'util/numbers/adaptive-round';
import hasElevationData from 'legacy/util/data/has-elevation-data';
import layerModel from 'models/layer-model';
import * as geo from 'util/geo';
import api from 'legacy/util/api';
import siteModel from 'models/site-model';
import round from 'util/numbers/round';
import addCommas from 'util/numbers/add-commas';
import helpers from 'legacy/util/api/helpers';

const MS_PER_DAY = 1000 * 60 * 60 * 24,
    HEATMAP = 'heatmap';

class VolumeModel {

    cleanup() {
        this.removeHeatMap();
        this.feature = null;
        this.units = isMetric() ? 'm³' : 'yd³';
        this.showVolumeDiff = false;
        this.comparisonId = 'best-fit';
        this.isComparisonSurveySelected = false;
        this.progressData = [];
        this.productionData = [];
        this.productionMetric = 'fill';
        this.legendTickCount = 5; // must be odd
    }

    init(feature, hide) {

        this.cleanup();

        this.feature = feature;
        this.hide = hide;

        this.surveys = Object.values(store.surveys)
            .filter(survey => survey.hasElevationData && hasElevationData(survey, feature))
            .sort((a, b) => new Date(a.surveyDateTime).getTime() - new Date(b.surveyDateTime).getTime());

        this.setBaselineId(layerModel.state.surveyId || this.surveys[0].surveyId);

        this.updateMeasurement('Perimeter');

        this.updateMeasurement('Area');

    }

    updateMeasurement(type) {
        const {string, metric} = geo['getDisplay' + type](this.feature);
        this[type.toLowerCase()] = `${string} ${metric}`;
    }

    // enforce that the baseline survey is older than the comparison survey
    enforceSurveyOrder() {

        if (this.isComparisonSurveySelected) {

            const baselineId = this.baselineId,
                comparisonId = this.comparisonId,
                baselineDate = new Date(store.surveys[baselineId].surveyDateTime),
                comparisonDate = new Date(store.surveys[comparisonId].surveyDateTime);

            if (baselineDate > comparisonDate) {

                this.baselineId = comparisonId;
                this.comparisonId = baselineId;

            }

        }

    }

    setBaselineId(surveyId) {
        this.baselineId = surveyId;
        this.enforceSurveyOrder();
        layerModel.setSurvey(surveyId);
        this.updateVolume();
    }

    setComparisonId(surveyId) {
        this.isComparisonSurveySelected = surveyId !== 'best-fit' && surveyId !== 'lowest-point';
        this.comparisonId = surveyId;
        this.enforceSurveyOrder();
        this.updateVolume();
    }

    updateVolume() {

        if (this.baselineId === this.comparisonId) {
            this.cut = this.fill = this.net = 0;
            this.isComparisonSurveySelected = this.showVolumeDiff = false;
            this.removeHeatMap();
            return;
        }

        this.showVolumeDiff = this.showVolumeDiff && this.isComparisonSurveySelected;

        this.cut = this.fill = this.net = '...';

        const coordinates = geo.lngLatsToLatLngs(this.feature.geometry.coordinates[0]);

        api.get.volumeDiff(coordinates, this.baselineId, this.comparisonId)
            .then(volume => geo.volumeToDisplayVolume(volume))
            .then(volume => {

                if (volume.contourUrlTemplate) {
                    this.drawHeatMap(volume.contourUrlTemplate);
                } else {
                    this.removeHeatMap();
                }

                if (volume.error) {
                    this.cut = this.fill = this.net = 'N/A';
                } else {
                    this.legendInterval = adaptiveRound(Math.max(Math.abs(volume.max.float), Math.abs(volume.min.float)) / (this.legendTickCount - 1 / 2));
                    this.cut = `${volume.cut.string} ${volume.cut.metric}`;
                    this.fill = `${volume.fill.string} ${volume.fill.metric}`;
                    const cut = volume.cut.float,
                        fill = volume.fill.float;
                    this.netMetric = cut > fill ? 'cut' : 'fill';
                    const net = cut > fill ? cut - fill : fill - cut;
                    this.net = `${addCommas(round(net))} ${volume.cut.metric}`;
                }

                m.redraw();

            });

        if (this.showVolumeDiff) {

            const baselineIndex = this.surveys.findIndex(survey => survey.surveyId === this.baselineId),
                comparisonIndex = this.surveys.findIndex(survey => survey.surveyId === this.comparisonId),
                surveyRange = this.surveys.slice(baselineIndex + 1, comparisonIndex + 1);

            this.progressData = [];

            this.productionData = [];

            Promise.all([
                Promise.resolve({cut: 0, fill: 0}),
                ...surveyRange.map((survey, i) =>
                    api.get.volumeDiff(coordinates, i ? surveyRange[i - 1].surveyId : this.baselineId, survey.surveyId)
                )
            ]).then(volumes => {

                const fill = {
                        yLabel: 'Fill',
                        xLabel: 'Date',
                        color: '#059e42',
                        points: []
                    },
                    deltaFill = {
                        yLabel: 'Δ Fill',
                        xLabel: 'Date',
                        color: '#93dab0',
                        points: []
                    },
                    cut = {
                        yLabel: 'Cut',
                        xLabel: 'Date',
                        color: '#cc1e36',
                        points: []
                    },
                    deltaCut = {
                        yLabel: 'Δ Cut',
                        xLabel: 'Date',
                        color: '#f18d9b',
                        points: []
                    },
                    productivity = {
                        yLabel: this.units + '/day',
                        xLabel: 'Date',
                        color: this.productionMetric === 'cut' ? '#cc1e36' : '#059e42',
                        points: []
                    };

                const startingDate = new Date(store.surveys[this.baselineId].surveyDateTime).getTime();

                volumes
                    .filter(v => !v.error)
                    .map(v => geo.volumeToDisplayVolume(v))
                    .forEach((volume, i) => {

                        const k = i - 1;

                        const date = i
                            ? new Date(surveyRange[k].surveyDateTime).getTime()
                            : startingDate;

                        deltaFill.points.push({
                            x: date,
                            y: volume.fill.float
                        });

                        fill.points.push({
                            x: date,
                            y: volume.fill.float + (i ? fill.points[k].y : 0)
                        });

                        deltaCut.points.push({
                            x: date,
                            y: volume.cut.float
                        });

                        cut.points.push({
                            x: date,
                            y: volume.cut.float + (i ? cut.points[k].y : 0)
                        });

                        const days = (date - (i ? productivity.points[k].x : 0)) / MS_PER_DAY;

                        productivity.points.push({
                            x: date,
                            y: days ? volume[this.productionMetric].float / days : 0
                        });

                    });

                this.progressData = [deltaCut, deltaFill, cut, fill];

                this.productionData = [productivity];

                const productionDataPoints = (this.productionMetric === 'fill' ? fill : cut).points,
                    lastProductionPoint = productionDataPoints[productionDataPoints.length - 1];

                this.totalMoved = addCommas(adaptiveRound(lastProductionPoint.y));

                this.maxProduction = addCommas(adaptiveRound(Math.max(...productivity.points.map(p => p.y))));

                this.days = Math.round((lastProductionPoint.x - startingDate) / MS_PER_DAY);

                m.redraw();

            });

        }

    }

    vertexChanged() {
        if (this.feature) {
            this.updateVolume();
        }
    }

    removeHeatMap() {
        if (this.hasHeatMap) {
            layerModel.removeSource(HEATMAP);
        }
        this.hasHeatMap = false;
    }

    drawHeatMap(url) {
        const urlTemplate = helpers.URLTemplateFMTToPNG(url);
        if (url) {
            this.hasHeatMap = true;
            urlTemplate
                + '?interval-meters=0.01'
                + '&min-color=%23F31E3C'
                + '&sub-zero-color=%23DBC8F6'
                + '&supra-zero-color=%2381E9EA'
                + '&max-color=%2338FC6C';
            layerModel.createRasterSource(HEATMAP, urlTemplate);
            siteModel.map.addLayer({
                id: HEATMAP,
                source: HEATMAP,
                type: 'raster'
            });
        } else {
            this.removeHeatMap();
        }
    }

}

export default new VolumeModel();
