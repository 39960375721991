import debounce from 'util/events/debounce';
import api from 'legacy/util/api';

let queue = {};

const _debouncedAPICall = debounce(() => {

    const requests = Object.values(queue);

    api.rpc.requests(requests.map(r => r.rpc)).then(responses => {

        responses.forEach((response, i) => {
            requests[i].resolve(response);
        });

    });

    queue = {};

}, 1000);

function debouncedAPICall(id, rpc) {

    const promise = new Promise(resolve => {
        queue[id] = {
            rpc,
            resolve
        };
    });

    _debouncedAPICall();

    return promise;

}

export default debouncedAPICall;
