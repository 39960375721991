const FormButton = {
    view: ({attrs: {
        label,
        onClick,
        shouldSpin,
        isDisabled,
        spinning
    }, state, children}) =>
        <div class="form-bottom">
            <div class={`submit-button ${state.spinning || spinning ? 'spinning' : ''}`}>
                <button
                    type="submit"
                    class={`btn btn-primary btn-pill ${isDisabled ? 'disabled' : ''}`}
                    onclick={(e) => {
                        if (shouldSpin) {
                            state.spinning = true;
                        }
                        if (onClick) {
                            onClick(e);
                        }
                    }}>
                    <div class="spinner"/>
                    <span class="btn-label">
                        {label}
                    </span>
                </button>
            </div>
            {children}
        </div>
};

export default FormButton;
