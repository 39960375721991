import element from 'util/dom/element';
import convertMithril from 'util/dom/convert-mithril';
import {Popup, Marker} from 'mapbox-gl';
import stakeableModel from 'models/stakeable-model';
import StakePopup from 'views/stakeable/stake-popup';

const INDEX_OFFSET = 1; // Stakes are 1-indexed, so index position 0 stake should display as "Pin 1"

class Stake extends Marker {

    /**
     * Generates the pin element via mapbox constructor
     * and sets up defaults via instance methods.
     */
    constructor(opts) {
        super({
            element: element(['<div class="stake"></div>']),
            anchor: 'bottom'
        });
        this.setLngLat(opts.coords)
            .setDraggable(true)
            .addTo(stakeableModel.origin);
        this._parentPair = null;
        this.originCoords = opts.coords;
    }

    /**
     * Renders the stake with the number displaying per the index position of the parent.
     */
    render(parentPair) {
        parentPair ? this._parentPair = parentPair : null;
        this.getElement().innerHTML =
            `<div class="stake-inner">
                <span class="stake-number">${this._parentPair.position + INDEX_OFFSET}</span>
             </div>`;
        this.renderPopup();
    }

    /**
     * Renders the stake's popup with the number displaying per the index position of the parent
     * and the delete btn with the click function to delete entire pair.
     */
    renderPopup() {
        const padding = 10;
        const markerHeight = 43;
        const linearOffset = 20;
        const markerRadius = 20;

        this.setPopup(new Popup({
            closeButton: false,
            closeOnClick: false,
            maxWidth: 300,
            offset: {
                'top': [0, padding],
                'top-left': [padding, padding / 2],
                'top-right': [-padding, padding / 2],
                'bottom': [0, -markerHeight - padding],
                'bottom-left': [linearOffset + padding, (markerHeight - markerRadius + linearOffset) * -1],
                'bottom-right': [-linearOffset - padding, (markerHeight - markerRadius + linearOffset) * -1],
                'left': [markerRadius + padding, (markerHeight - markerRadius) * -1],
                'right': [-markerRadius - padding, (markerHeight - markerRadius) * -1]
            },
            className: 'stake-popup-outer'
            // offset: 0 // Set this manually via css so that we can control it based on current anchor positioning
        }));
        this.getPopup().setDOMContent(convertMithril.toDom(<StakePopup parentPair={this._parentPair} />));
    }

    /**
     * Highlights the stake via css class and opens popup.
     */
    doHighlight() {
        this.getElement().classList.add('stake-highlight');
        this.showPopup();
    }

    /**
     * Removes css class and closes popup.
     */
    stopHighlight() {
        this.getElement().classList.remove('stake-highlight');
        this.hidePopup();
    }

    /**
     * If the popup exists, removes it.
     */
    hidePopup() {
        this.getPopup() ? this.getPopup().remove() : null;
    }


    /**
     * If the popup is closed, opens it.
     */
    showPopup() {
        !this.getPopup().isOpen() ? this.togglePopup() : null;
    }

}

export default Stake;

