import constants from 'util/data/constants';
import formModel from 'models/form-model';
import asset from 'views/form-control/asset';
import name from 'views/form-control/name';
import file from 'views/form-control/file';
import text from 'views/form-control/text';
import paragraph from 'views/form-control/paragraph';
import coordinates from 'views/form-control/coordinates';
import dropdown from 'views/form-control/dropdown';
import radio from 'views/form-control/radio';
import toggle from 'views/form-control/toggle';
import plan from 'views/form-control/plan';
import survey from 'views/form-control/survey';
import length from 'views/form-control/length';
import area from 'views/form-control/area';
import volume from 'views/form-control/volume';
import number from 'views/form-control/number';
import date from 'views/form-control/date';
import multiselect from 'views/form-control/multiselect';
import multitext from 'views/form-control/multitext';
import user from 'views/form-control/user';
import request from 'views/form-control/request';
import place from 'views/form-control/place';
import imodel from 'views/form-control/imodel';
import URL from  'views/form-control/url';

const renderFnByControlType = {asset, name, file, text, paragraph, coordinates, dropdown, radio, toggle, plan, survey, length, area, number, date, multiselect, multitext, user, request, place, volume, imodel, URL};

const FormControl = {
    oninit: ({state, attrs: {control, assetId}}) => {
        state.assetId = assetId || formModel.assetId;
        const controlTypeName = constants.controlTypeIdToName[control.controlTypeId];
        state.render = renderFnByControlType[controlTypeName] || (() => '');
        control.attributes = control.attributes || {};
    },

    hasView: (controlTypeId) => {
        return !!renderFnByControlType[constants.controlTypeIdToName[controlTypeId]];
    },

    view: ({state, attrs: {control, opts}}) => state.render(control, state.assetId, opts)
   
};

export default FormControl;
