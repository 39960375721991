import appModel from 'models/app-model';
import accountManager from 'managers/account-manager';
import peopleModel from 'models/people/people-model';
import {ProfileCard} from 'views/people/profile';

const PeopleList = {
    oninit: () => {
        peopleModel.companyCount();
    },
    
    onremove: () => {
        accountManager.menu.resetActive();
    },

    view: () => {
        const length = peopleModel.list.length;
        const companyCount = peopleModel.companyMemberCount;
        return <>
            <div class="people-list-header">
                <div class="people-list-title">People</div>
                {appModel.user.isAccountAdmin && <button class="btn btn-secondary btn-pill btn-smaller btn-add-person" onclick={() => peopleModel.openNewPerson()}><i class="icon-plus"/><i class="icon-user"/></button>}
            </div>
            {appModel.user.role === 'manager' || appModel.user.role === 'general' || appModel.user.role === 'limited' || appModel.user.role === 'viewer' ?
                <>
                    <span className="full-width-label">There {companyCount !== 1 ? `are ${companyCount} People` : `is ${companyCount} Person`} from your Company on this Account.</span>
                    <div class="user-list">
                        {peopleModel.list.map(userId => {
                            const person = peopleModel.getPerson(userId);
                            const userCompany = appModel.user.company || '';
                            if (person.company && person.company === userCompany) {
                                return <ProfileCard person={peopleModel.getPerson(userId)}/>; 
                            }
                            if (!person.company && person.isSelf) {
                                return <>
                                    <div className="people-list-title">Please contact our support team to update your Company so that you can view your team.</div>
                                    <ProfileCard person={peopleModel.getPerson(userId)}/>
                                </>;
                            }
                            return <></>; //only show managers other employees in their company & themselves (if no company given)
                        })
                        }
                    </div> 
                </> : <>
                    <span className="full-width-label">There {length !== 1 ? `are ${length} people` : `is ${length} person`} on this Account.</span>
                    <div class="user-list">
                        {peopleModel.list.map(userId =>
                            <ProfileCard person={peopleModel.getPerson(userId)}/>)}
                    </div>
                </>}
        </>;
    }
};

export default PeopleList;
