import router from 'uav-router';
import mediaListManager from 'managers/media-list-manager';
import mediaViewerModel from 'models/media-viewer-model';
import MediaViewer from 'views/media-viewer/media-viewer';
import appModel from 'models/app-model';
import constants from 'util/data/constants';

const MediaViewerStandalone = {

    oninit: async ({state}) => {
        mediaViewerModel.inStandaloneMode = true;
        const media = await mediaListManager.openInMediaViewer(router.params.mediaId);
        const tabIcon = document.getElementById('favicon');
        if (media) {
            appModel.setPageTitle(media.label);
            tabIcon.setAttribute('href', media.thumbnailUrl);
        } else {
            appModel.setPageTitle('Media Not Found');
            state.mediaNotFound = true;
        }
    },

    view: ({state}) => {
        return <div class="media-viewer-standalone">
            {state.mediaNotFound && <div class="media-not-found-message">
                <h1><i class="icon-warning"/> Media Not Found</h1>
                <p>It's possible this link has expired or you do not have sufficient permissions to access it.</p>
                <p>If you believe this is an error, please <a class="support-link" href={`mailto:${constants.defaultSupportEmail}?subject=Unable to load mediaId ${router.params.mediaId}`}>contact our support team</a> for assistance.</p>
            </div>}
            <MediaViewer/>
        </div>;
    }
};

export default MediaViewerStandalone;
