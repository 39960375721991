// Returns the rounded number if the amount of digits after the decimal is greater than the decimalLen passed.
// eg, given 10.12345, 5, will return 10, because .12345 is greater than or equal to 5 digits.
// eg, given 10.123, 5, will return 10.123
function roundIfNLong(n, decimalLen) {
    const nString = n.toString();
    const decimal = nString.split('.')[1];
    if (decimal && decimal.length >= decimalLen) {
        return Math.round(n);
    } 
    return n;
}

export default roundIfNLong;
