import constants from 'util/data/constants';
import appModel from 'models/app-model';
import peopleModel from 'models/people/people-model';

export const doShowNotifications = (person) => {
    return (peopleModel.hasAdminPrivileges(appModel.user) || person.isSelf) && !person.isNewUser;
};

export const doShowChangePassword = (person) => {
    return person.isSelf;
};

export const permissionLevel = (role) => {
    return constants.permissionsRanks[role] || -1;
};
