// Given asset 1 and asset 2, returns true if asset 1 was updated more recently
export const asset1IsNewer = (asset1, asset2) => {
    const asset1Date = new Date(asset1.updatedDateTime);
    const asset2Date = new Date(asset2.updatedDateTime);
    return date1IsNewer(asset1Date, asset2Date);
};

// Given asset and a date, returns true if asset 1 was updated more recently
export const assetIsNewerThanDate = (asset, date) => {
    const assetDate = new Date(asset.updatedDateTime);
    return assetDate > date;
};

// Given date 1 and date 2, returns true if date 1 was more recently
export const date1IsNewer = (date1, date2) => {
    return date1 > date2;
};

