import initializer from 'util/initializer';
import router from 'uav-router';
import api from 'legacy/util/api';
import store from 'util/data/store';
import {latLngsToLngLats} from 'util/geo';
import helpers from 'legacy/util/api/helpers';
import appModel from 'models/app-model';
import loaderModel from 'models/loader-model';
import siteModel from 'models/site-model';
import peopleModel from 'models/people/people-model';
import authManager from 'managers/auth-manager';
import exceptions from 'constants/util/exceptions-constants';
import {datadogRum} from '@datadog/browser-rum';
import constants from 'util/data/constants';
import layerModel from 'models/layer-model';
class ProjectManager {

    constructor() {
        this.calledInit = false;
        this.isInitted = false;
        initializer.addOnInit('project', () => this.reset());
    }

    reset() {
        this._isMetaProject = null;
        this._childProject = null;
        this.metaProjectAsset = null;
        this.projectName = '';
    }

    noProjectFound() {
        console.error('initial projectId was not found');
        loaderModel.hide();
        authManager.showErrorToastMessage();
        return Promise.reject();
    }

    init(projectId = router.params.projectId) {
        initializer.initManager('project');
        this.calledInit = true;
        this.projectId = projectId;
        appModel.setState('editingProjectId', projectId);
        if (!projectId) {
            return this.noProjectFound();
        }
        const rpc = [
            ['getProject', {projectId}],
            ['listPlaces', {projectId, limit: 0}],
            ['listSurveys', {projectId, limit: 0}],
            ['listPlans', {projectId, limit: 0, include: ['document']}],
            ['listTilesets', {projectId, limit: 0}]
        ];
        return api.rpc.requests(rpc).then((projectData) => this._setProjectData(projectData))
            .catch(error => {
                console.error(error);
            });
    }

    get isMetaProject() {
        if (this._isMetaProject === null) {
            this._isMetaProject = store.account && store.account.attributes ? store.account.attributes.metaProjectId === this.projectId : false;
        }
        return this._isMetaProject;
    }

    get projectType() {
        if (this.metaProjectAsset) {
            return appModel.project.metaProjectAsset.contentType;
        }
        return '';
    }

    setIsMetaProject(toValue) {
        this._isMetaProject = toValue;
    }

    getMetaProjectAsset() {
        if (!this.metaProjectAsset) {
            return this._fetchMetaProjectAsset();
        }
        return this.metaProjectAsset;
    }

    _fetchMetaProjectAsset() {
        const metaProjectId = store.account.attributes.metaProjectId;
        return api.rpc.request([['listContent', {
            projectId: metaProjectId,
            'properties._projectId': {
                eq: this.projectId
            },
            limit: 1
        }]]).then(([asset]) => {
            this.metaProjectAsset = asset;
            return asset;
        });
    }

    /* ----- Internal methods ----- */

    async _setProjectData(projectData) {
        const [project, places, surveys, plans, tilesets] = projectData;
        const isPdfService = router.params && router.params.view && router.params.view === 'pdf';

        if (!project.isVisible && !appModel.inPreviewMode) {
            appModel.account.handle(exceptions.INITTING_DELETED_PROJECT);
        }

        this.setIsMetaProject(store.account && store.account.attributes ? store.account.attributes.metaProjectId === this.projectId : false);
       
        const projectName = this.projectName = this.isMetaProject ? store.account.name : project.name;
        appModel.setDocumentTitle();
        loaderModel.updateMessage(`Loading ${projectName || 'Unearth'}...`);

        this._normalizeProjectData(project);

        let site = project.sites[0];
        const siteId = router.params.siteId;
        if (siteId) {
            site = project.sites.find(s => s.siteId === siteId) || site;
        } else {
            router.url.mergeReplace({siteId: site.siteId});
        }

        if (!this.isMetaProject) {
            await this._fetchMetaProjectAsset();
        } else {
            await appModel.user.setProjectAccessList();
        }
        
        if (!isPdfService) {
            if (this.isMetaProject) {
                layerModel.initFoldersForMetaProject();
            }  else {
                layerModel.initFolders();
            }
        }
        
        this._setPlaces(places);
        this._setSurveys(surveys);
        this._setPlans(plans);
        this._setPeople(project.users);
        this._setTilesets(tilesets);
        this._setSite(site, surveys);

        store.setMutableContainer('project', project);

        this._addDataDog();

        this.isInitted = true;

    }

    /**
     * Keep (a max of) one child project cached if retrieved.
    */
    async getChildProject(childProjectId) {
        if (!this._childProject || this._childProject.projectId !== childProjectId) {
            const [[project]] = await api.rpc.requests([['listProjects', {projectId: appModel.state.editingProjectId, limit: 1}]]);
            this._childProject = project;
        }
        return this._childProject;
    }

    /**
     * If we're currently editing a (child) project asset, returns that object instead of the meta project
    */
    async getEditingProjectData() {
        let project = this;
        if (this.projectId !== appModel.state.editingProjectId) {
            project = await appModel.project.getChildProject(appModel.state.editingProjectId);
        }
        return project;
    }

    _normalizeProjectData(project) {
        project.attributes = project.attributes || {};
        project.sites = helpers.list(project.sites);
        project.users = helpers.list(project.users);
        project.attributes = project.attributes || {};
    }

    _setPlaces(places) {
        const storePlaces = {};
        helpers.list(places).forEach(place => {
            place.boundary.coordinates = [latLngsToLngLats(place.boundary.coordinates[0])];
            storePlaces[place.placeId] = place;
        });
        store.setMutableContainer('places', storePlaces);
    }

    _setSurveys(surveys) {
        const storeSurveys = {};
        surveys.forEach(survey => {
            storeSurveys[survey.surveyId] = survey;
        });
        store.setMutableContainer('surveys', storeSurveys);
    }

    async loadProjectPlans(projectId = appModel.project.projectId) {
        // Reset everything first
        store.setMutableContainer('plans', {});
        store.setMutableContainer('tilesets', {});
        m.redraw();

        let project = this;
        // If the project is the current project, we don't need to fetch it first.
        if (projectId !== appModel.project.projectId) {
            [[project]] = await api.rpc.requests([['listProjects', {projectId, limit: 1}]]);
            if (projectId !== appModel.project.projectId && project.accountAttributes.metaProjectId === appModel.project.projectId) {
                this._childProject = project; // It's a child project, cache it.
            }
        }
        const [plans] = await api.rpc.requests([['listPlans', {projectId, limit: 0}]]);
        const [tilesets] = await api.rpc.requests([['listTilesets', {projectId, limit: 0}]]);
    
        this._setPlans(plans, project);
        this._setTilesets(tilesets);
        m.redraw();
    }

    _setPlans(plans) {
        const storePlans = {};

        plans.forEach(plan => {
            storePlans[plan.planId] = plan;
        });

        store.setMutableContainer('plans', storePlans);
    }

    _setPeople(users) {
        peopleModel.loadProjectUsers(this.projectId, users);
    }

    _setTilesets(tilesets) {
        const storeTilesets = {};
        tilesets.forEach(tileset => {
            storeTilesets[tileset.tilesetId] = tileset;
        });
        store.setMutableContainer('tilesets', storeTilesets);
    }

    _setSite(site, surveys) {
        Object.assign(siteModel, site);
        siteModel.setElevationData(surveys);
    }

    _addDataDog() {
        // Add datadog and hubspot
        if (constants.isDeployed) {
            datadogRum.addRumGlobalContext('project', {
                id: store.project.projectId,
                name: store.project.name
            });
            datadogRum.addRumGlobalContext('metaproject', {
                id: store.account.attributes.metaProjectId
            });
        }
    }

}

export default new ProjectManager();
