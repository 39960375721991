import router from 'uav-router';
import { authUrl } from 'util/data/env';
import { initialize } from '@procore/procore-iframe-helpers';
import message from 'views/toast-message/toast-message';
import constants from 'util/data/constants';
import authManager from 'managers/auth-manager';
import cache from 'legacy/util/data/cache';
import cookie from 'util/data/cookie';

const inProcoreEmbeddedViewer = () => {
    return router.params && router.params.isProcore;
};

const OAuthOptions = {

    oninit: ({ state }) => {
        state.onProcoreOauth = () => {
            state.oauthSpinningProcore = true;
            if (inProcoreEmbeddedViewer()) {
                const context = initialize();
                state.oauthSpinningProcore = true;
                context.authentication.authenticate({
                    url: `${authUrl}/oauth/procore`,
                    onSuccess: async function (payload) {
                        if (payload && payload.ueRefresh) {
                            cache.set('ue_refresh', payload.ueRefresh);
                            const refreshTokenProxy = cookie.readCookie('procore_refresh_token', true);
                            sessionStorage.setItem('procore_refresh_token', refreshTokenProxy);
                            const accessTokenProxy = cookie.readCookie('procore_access_token', true);
                            sessionStorage.setItem('procore_access_token', accessTokenProxy);
                            await authManager.reconnect();
                            window.location.reload();
                        }
                    },
                    onFailure: function (error) {
                        message.show('Something went wrong. Please try again or contact support for assistance.', 'error');
                        console.error('error signing in:', error);
                        state.oauthSpinningProcore = false;
                        m.redraw();
                    }
                });
            } else {
                window.location.href = `${authUrl}/oauth/procore`;
            }
        };

    },

    view: ({ state }) => {
        return <div class="oauth-section">
            <span class="oauth-divider"><span class="oauth-divider-label">or</span></span>
            <div class="procore-oauth">
                <span class={`btn btn-primary btn-pill ${state.oauthSpinningProcore ? 'spinning' : ''}`}
                    onclick={() => state.onProcoreOauth()}><i class={`spinner ${state.oauthSpinningProcore ? 'spinning' : ''}`} /><span class="btn-label">Sign in with Procore</span></span>
            </div>
            {constants.awsProfile === 'development' && <div class="github-oauth" style="margin-top: 15px;">
                <span class={`btn btn-primary btn-pill ${state.oauthSpinningGithub ? 'spinning' : ''}`}
                    onclick={() => {
                        state.oauthSpinningGithub = true;
                        window.location.href = `${authUrl}/oauth/github`;
                    }}><i class={`spinner ${state.oauthSpinningGithub ? 'spinning' : ''}`} /><span class="btn-label">Sign in with Github</span></span>
            </div>}
        </div>;
    }
};

export default OAuthOptions;
