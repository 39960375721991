import api from 'legacy/util/api/api';
import appModel from 'models/app-model';
import siteModel from 'models/site-model';
import capitalize from 'util/data/capitalize';
import requestBatches from 'util/network/request-batches/request-batches';
import XidResponses from 'util/network/request-batches/_lists/xid-responses';
import deepCloneObject from 'util/data/deep-clone-object';

class TypeXids {
    constructor(apiVerb, type, args) {
        this.apiVerb = apiVerb;
        this.type = type;
        this.xids = {};
        this.invalidXids = {};
        this.args = args || {};
    }

    get xidList() {
        return Object.keys(this.xids);
    }

    get apiRequestString() {
        return this.apiVerb + capitalize(this.type);
    }

    get xidKey() {
        return this.type + 'Id';
    }

    get xidInString() {
        return this.type + 'IdIn';
    }

    add(xid, onResponse, onInvalid) {
        if (!this.xids[xid]) {
            this.xids[xid] = new XidResponses(xid);
        }
        this.xids[xid].addResponseFns(onResponse, onInvalid);
    }

    handleResponse(xid, result) {
        if (this.xids[xid]) {
            this.xids[xid].runOnResponseFns(result);
            delete this.xids[xid];
        }
    }

    getTypeSpecificArgs() {
        switch (this.type) {
        case 'content':
            const args = { 
                isVisible: true,
                projectId: appModel.project.projectId,
                siteId: siteModel.siteId,
                assetTypeIdIn: requestBatches.assetTypeIdIn
            };
            if (appModel.project.isMetaProject) {
                args['properties._projectId'] = {in: appModel.user.projectIds};
            }

            return args;
        default:
            return {
                isVisible: true,
                projectId: appModel.project.projectId
            };
        }
    }

    sendRequest() {
        if (!this.xidList.length) {
            return Promise.resolve();
        }

        const args = {...this.args};
        const typeSpecificArgs = this.getTypeSpecificArgs();

        const batch = deepCloneObject(this.xids);
        this.xids = {};
   
        return api.rpc.requests([[this.apiRequestString, {
            [this.xidInString]: Object.keys(batch),
            limit: Object.keys(batch).length,
            ...typeSpecificArgs,
            ...args // any optional args set on construction
        }]]).then(([results]) => {
            results.forEach(result => {
                const xid = result[this.xidKey];
                if (batch[xid]) {
                    batch[xid].runOnResponseFns(result);
                    delete batch[xid];
                }
            });
            // At this point, any xids still without response should be treated as invalid (bc nothing returned from api for it)
            Object.keys(batch).forEach(xid => batch[xid].markAsInvalid());
            m.redraw();
        });
    }
}

export default TypeXids;
