const initializer = {
    callbacks: [],
    siteCallbacks: [],

    run: () => initializer.callbacks.forEach(c => c.fn()),
    /*
    * fn - function to run when init.run() is called
    * name - (optional) string to identify this callback. Only used for dev purposes, ie when debugging using debug.js
    */
    add: (fn, name) => initializer.callbacks.push({name, fn}),

    siteInitialized: () => initializer.siteCallbacks.forEach(c => c.fn()),
    addSiteCallback: (fn, name) => initializer.siteCallbacks.push({name, fn}),

    /* ---- Manager-Specific Init Callbacks ---- */
    user: [],
    account: [],
    project: [],
    toolbox: [],

    addOnInit: (managerKey, fn, name) => initializer[managerKey].push({name, fn}),
    initManager: (managerKey) => initializer[managerKey].forEach(c => c.fn()),

    runAll: () => {
        initializer.run();
        initializer.initManager('user');
        initializer.initManager('account');
        initializer.initManager('project');
        initializer.initManager('toolbox');
    }
};

export default initializer;
