// Given a keydown event key, returns the normalized key property (ie11 and older versions of firefox use different naming conventions from most browsers: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values

const normalizedKey = {
    Left: 'ArrowLeft',
    Right: 'ArrowRight',
    Down: 'ArrowDown',
    Up: 'ArrowUp',
    Del: 'Delete',
    Backspace: 'Backspace',
    Crsel: 'CrSel',
    Exsel: 'ExSel',
    Esc: 'Escape',
    Apps: 'ContextMenu',
    Spacebar: ' '
};

// Example: const key = getNormalizedKey(e.key)
export function getNormalizedKey(key) {
    return normalizedKey[key] || key;
}
