import appModel from 'models/app-model';
import initializer from 'util/initializer';
import constants from 'util/data/constants';
import peopleModel from 'models/people/people-model';
import store from 'util/data/store';
import {CREATE, READ, EDIT, DELETE, CRED_ALL, CRE_ALL_DELETE_OWN, CRED_OWN, READ_ONLY, permissionSets} from 'constants/managers/permissions-constants';
import message from 'views/toast-message/toast-message';
import restrictions from 'util/permissions/restriction-message';

const roles = constants.accountUserRoles;

export const defaultPermissions = {
    [roles.owner.key]: permissionSets[CRED_ALL],
    [roles.admin.key]: permissionSets[CRED_ALL],
    [roles.manager.key]: permissionSets[CRE_ALL_DELETE_OWN],
    [roles.general.key]: permissionSets[CRE_ALL_DELETE_OWN],
    [roles.limited.key]: permissionSets[CRED_OWN],
    [roles.viewer.key]: permissionSets[READ_ONLY]
};

const commentPermissionOverrides = {
    [roles.manager.key]: permissionSets[CRED_OWN],
    [roles.general.key]: permissionSets[CRED_OWN],
    [roles.viewer.key]: permissionSets[CRED_OWN]
};

const projectPermissionOverrides = {
    [roles.limited.key]: permissionSets[READ_ONLY]
};

class PermissionsManager {

    constructor() {
        initializer.addOnInit('toolbox', () => this.reset());
    }

    reset() {
        this.assetTypes = {};
        this.controls = {};
        this.hiddenAssetTypes = {};
    }

    getHiddenAssetTypeIds() {
        return Object.keys(this.hiddenAssetTypes);
    }

    getRestrictionsByAssetTypeId(assetTypeId) {
        return this.assetTypes[assetTypeId];
    }

    getRestrictionsByRole(assetTypeId, role) {
        const permissionsByAssetTypeId = this.getRestrictionsByAssetTypeId(assetTypeId);
        if (permissionsByAssetTypeId && permissionsByAssetTypeId.hasOwnProperty(role)) {
            return permissionsByAssetTypeId[role];
        }
        return defaultPermissions[role];
    }

    getRestrictionByAction(assetTypeId, role, action) {
        const permissionsByRole = this.getRestrictionsByRole(assetTypeId, role);
        if (permissionsByRole && permissionsByRole.hasOwnProperty(action)) {
            return permissionsByRole[action];
        }
        return defaultPermissions[role] ? defaultPermissions[role][action] : true;
    }

    getFootnoteForAction(role, access, hasAccess) {
        let footnote = '';
        if (!hasAccess) {
            if (access === EDIT) {
                footnote = '*AddedBy or value of a UserControl = CurrentUser';
            } else if (access === DELETE) {
                footnote = '*AddedBy = CurrentUser'; 
            }
        }
        return footnote;
    }

    initToolPermissions() {
        // General and managers should be prevented from editing/deleting comments by others
        this.assetTypes[constants.commentAssetTypeId] = {};
        Object.keys(commentPermissionOverrides).forEach(role => {
            this.assetTypes[constants.commentAssetTypeId][role] = commentPermissionOverrides[role];
        });
    }

    setProjectToolPermissions(assetTypeId) {
        Object.keys(projectPermissionOverrides).forEach(role => {
            this.assetTypes[assetTypeId] = this.assetTypes[assetTypeId] || {};
            this.assetTypes[assetTypeId][role] = projectPermissionOverrides[role];
        });
    }

    setCustomToolPermissions(assetTypeId, _restrictions) {
        this.assetTypes[assetTypeId] = {};
        Object.keys(_restrictions).forEach(role => {
            this.assetTypes[assetTypeId][role] = {};
            Object.keys(_restrictions[role]).forEach(action => {
                const rule = _restrictions[role][action];
                if (rule !== defaultPermissions[role][action]) {
                    this.assetTypes[assetTypeId][role][action] = rule;
                }
            });
        }); 
    }

    canCreateContent(assetId, userId = appModel.user.userId) {
        return this._checkContent(assetId, userId, CREATE);
    }

    canDeleteContent(assetId, userId = appModel.user.userId) {
        return this._checkContent(assetId, userId, DELETE);
    }

    canEditContent(assetId, userId = appModel.user.userId) {
        return this._checkContent(assetId, userId, EDIT);
    }

    canEditRecord(record, userId = appModel.user.userId) {
        const user = this._getUser(userId);
        return !!user.isAccountAdmin || user.isRecordCreator(record);
    }

    canReadContent(assetId, userId = appModel.user.userId) {
        return this._checkContent(assetId, userId, READ);
    }

    canDeleteAssetType(assetTypeId, userId = appModel.user.userId) {
        return this._checkAssetType(assetTypeId, userId, DELETE);
    }

    canEditAssetType(assetTypeId, userId = appModel.user.userId) {
        return this._checkAssetType(assetTypeId, userId, EDIT);
    }

    canCreateAssetType(assetTypeId, userId = appModel.user.userId) {
        return this._checkAssetType(assetTypeId, userId, CREATE);
    }

    canReadAssetType(assetTypeId, userId = appModel.user.userId) {
        return this._checkAssetType(assetTypeId, userId, READ);
    }

    displayMessage() {
        const messageText = restrictions.message(appModel.user, 'read');
        message.show(messageText, 'warning permission shield');
    }

    canEditToolbox() {
        return appModel.user.isAccountAdmin && !appModel.project.isMetaProject && appModel.toolbox.isEditableToolbox();
    }

    // ----- Private Functions ----- //

    _checkContent(assetId, userId, actionToCheck) {
        const user = this._getUser(userId);
        if (!user) {
            // exceptional case
            console.error('couldnt find user with provided userId');
            return false;
        }

        if (user.isSuperadmin) {
            return true;
        }

        const assetTypeId = this._getAssetTypeId(assetId);
        const toolPermission = this._checkAssetType(assetTypeId, userId, actionToCheck);
        switch (actionToCheck) {
        case READ:
        case CREATE:
            return toolPermission;
        case EDIT:
        case DELETE:
            if (!toolPermission) {
                if (user.role === 'viewer') {
                    return false;
                }
                return this._checkContentForException(assetId, user, actionToCheck);
            }
            return true;
        }
    }

    _getUser(userId) {
        // If self, return full userManager object with add'l permission info attached
        if (userId === appModel.user.userId) {
            return appModel.user;
        } 
        return peopleModel.getPerson(userId);
    }

    getRestrictionTypeText(hasAccess, role, access) {
        let text = hasAccess ? 'all' : 'only theirs*';
        if (access === CREATE) {
            if (hasAccess) {
                text = 'yes';
            } else {
                text = 'no';
            }
        }
        if (access === READ) {
            if (!hasAccess) {
                text = 'none';
            }
        }
        if (role === constants.accountUserRoles.viewer.key) {
            if (access === EDIT || access === DELETE) {
                text = 'none';
            }
        }
        return text;
    }

    getRestrictionTypeCssClass(typeText) {
        if (typeText === 'only theirs*') {
            typeText = 'only-theirs';
        }
        return 'access-' + typeText;
    }

    _checkContentForException(assetId, user, actionToCheck) {
        if (actionToCheck === EDIT) {
            return user.isContentCreator(assetId) || user.isAssignedToAControl(assetId);
        }
        return user.isContentCreator(assetId);
    }

    _checkAssetType(assetTypeId, userId, actionToCheck) {
        const user = this._getUser(userId);
        if (!user) {
            // exceptional case
            console.error('couldnt find user with provided userId');
            return false;
        }

        if (user.isSuperAdmin) {
            return true;
        }

        return this.getRestrictionByAction(assetTypeId, user.role, actionToCheck);
    }

    _getAssetTypeId(assetId) {
        const asset = store.assets[assetId];
        if (asset) {
            return asset.assetTypeId;
        }
        return undefined;
    }

}

export default new PermissionsManager();
