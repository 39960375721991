import onBodyClick from 'legacy/util/dom/on-body-click';
import tip from 'views/tip';

const DEFAULT_LOCKED_CLASS = 'icon-permission-no';

const PermissionsIndicator = {

    oninit: ({state, attrs: {iconClass}}) => {
        state.icon = iconClass ? iconClass : DEFAULT_LOCKED_CLASS;
    },

    oncreate: ({dom, attrs: {lockedMessage, showByDefaultFor}}) => {
        if (showByDefaultFor) {
            tip.show(dom, {tipText: lockedMessage});
            onBodyClick.once(() => tip.hide());
            setTimeout(() => tip.hide(), showByDefaultFor);
        }
    },

    view: ({state, attrs: {lockedMessage, cssWrap, iconCss}}) => {
        return lockedMessage ? <div
            onmouseenter={function() {
                tip.show(this, {tipText: lockedMessage});
                onBodyClick.once(() => tip.hide());
            }}
            onmouseleave={() => tip.hide()}
            class={`permissions-indicator ${cssWrap || ''}`}>
            <i class={`${state.icon} ${iconCss || ''}`}/>
        </div> : '';
    }
    
};

export default PermissionsIndicator;
