import savedViewsModel from 'models/saved-views-model';
import SavedViewsProjectSelector from 'views/saved-views/saved-views-project-selector';

const SavedViewsOptionsMenu = {
    view: ({attrs: {projectViewId, hideProjectSwitcher, hideViewDetails}}) => {
        return <div class="saved-view-options-menu ellipsis-menu point-menu">
            {!hideProjectSwitcher && <SavedViewsProjectSelector projectViewId={projectViewId}/>}
            {projectViewId && !hideViewDetails && <div class="menu-option menu-view-details" onclick={(e) => {
                e.stopPropagation();
                savedViewsModel.openSavedViewDetails(projectViewId);
            }}><i class="icon-bookmark"/> View Details</div> }
            {projectViewId && projectViewId === savedViewsModel.activeSavedViewId && <div class="menu-option menu-view-details" onclick={(e) => {
                e.stopPropagation();
                savedViewsModel.disengageActiveView();
            }}><i class="icon-circle-slash"/> Disengage</div> }
            <div class="menu-option menu-delete remove-link" onclick={(e) => {
                e.stopPropagation();
                savedViewsModel.deleteSavedView(projectViewId);
            }}><i class="icon-trash"/> Delete</div>
        </div>;
    }
};

export default SavedViewsOptionsMenu;
