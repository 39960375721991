import formatDate from 'legacy/util/date/format-date';
import peopleModel from 'models/people/people-model';
import formModel from 'models/form-model';
import stopPropagation from 'util/events/stop-propagation';
import AssetOptions from 'views/asset-options';
import store from 'util/data/store';
import assetListManager from 'managers/asset-list-manager';
import requestBatches from 'util/network/request-batches/request-batches';
import siteModel from 'models/site-model';
import appModel from 'models/app-model';
import PermissionsIndicator from 'views/permissions/permissions-indicator';
import restrictions from 'util/permissions/restriction-message';
import oneUpModel from 'models/one-up-model';
import AssetSelection from 'views/asset-selection/asset-selection';
import AssetFormShortcuts from 'views/asset-options/asset-form-shortcuts';
import batchSelectModel from 'models/batch-select-model';
import userManager from 'managers/user-manager';
import DataCenterEntry from 'views/data-center/data-center-entry';
import AssetIcon from 'views/asset-icon/asset-icon';
import libraryModel from 'models/library-model';

/* Component views for displaying asset data */

export const AssetMetaRow = {
    view: ({attrs: {label, data, wrapClass}}) => <div class={`asset-meta-row two-col${wrapClass || ''}`}><span class="label col">{label}</span><span class="asset-meta-data col">{data}</span></div>
};

export const AssetMetaData = {
    oninit: ({state, attrs: {assetId, isCollapsible}}) => {
        state.isExpanded = !isCollapsible || siteModel.expandAssetMeta;

        const asset = store.assets[assetId];
        const assetType = store.assetTypes[asset.assetTypeId];
        const author = peopleModel.getPerson(asset.authorId);
        const modifier = peopleModel.getPerson(asset.modifierId);

        const toolGroupId = store.assetTypeIdToToolGroupId[asset.assetTypeId];
        const toolGroup = toolGroupId ? appModel.toolbox.toolGroups[toolGroupId] : null;

        state.asset = asset;
        state.type = assetType.name;
        state.category = toolGroup ? toolGroup.name : null;
        state.addedDate = formatDate.dayMonthAtTime(asset.createdDateTime);
        state.authorName = peopleModel.displayName(author);
        state.modifierName = peopleModel.displayName(modifier);
        state.lastUpdatedDate = formatDate.dayMonthAtTime(asset.updatedDateTime || new Date());
        const sitePerferences =  appModel.user.getPreference('sitePreferences');
        state.isDataCenterHidden = sitePerferences && sitePerferences[siteModel.siteId] && sitePerferences[siteModel.siteId].hideDataCenter ? sitePerferences[siteModel.siteId].hideDataCenter : false;
    },

    view: ({state, attrs: {assetId, isCollapsible}}) => {
        const asset = store.assets[assetId];
        const assetType = store.assetTypes[asset.assetTypeId];
        const baseAssetTypeId = assetType.base ? assetType.base.assetTypeId : assetType.assetTypeId;
        return <div class="card-meta-wrap">
            {!state.isDataCenterHidden && isCollapsible && appModel.user.permissions.canEditToolbox() &&
                <DataCenterEntry assetTypeId={baseAssetTypeId}/>
            }
            {!!isCollapsible && <div class="toggle-collapse-btn"><i class={`${state.isExpanded ? 'icon-chevron-up' : 'icon-chevron-down'}`} onclick={() => {
                state.isExpanded = !state.isExpanded;
                siteModel.setExpandAssetMeta(state.isExpanded);
            }}/></div>}
            {state.isExpanded ? <div class="card-meta-expanded">
                <AssetMetaRow label={'UnearthID'} data={assetId}/>
                <AssetMetaRow label={'Category'} data={state.category}/>
                {!!state.type && <AssetMetaRow label={'Type'} data={store.assetTypes[state.asset.assetTypeId].name}/>}
                <AssetMetaRow label={'Added Date/Time'} data={state.addedDate}/>
                <AssetMetaRow label={'Last Updated'} data={state.lastUpdatedDate}/>
                <AssetMetaRow label={'Added By'} data={state.authorName}/>
                <AssetMetaRow label={'Last Updated By'} data={state.modifierName}/>
            </div> : <div class="card-meta-collapsed">Common Properties</div>}
        </div>;
    }
};


class AssetCard {
    oninit({attrs: {assetId}, state}) {
        if (!assetId) {
            return;
        }
        const asset = store.assets[assetId];
        
        state.menuIsOpen = false;
        state.onOpen = () => {
            state.menuIsOpen = true;
            m.redraw();
        };
        state.onClose = () => {
            state.menuIsOpen = false;
            m.redraw();
        };

        if (asset) {
            this._oninit(assetId, state); // Allow for synchronous rendering in case required by the caller
        } else {
            requestBatches.add('listContent', assetId, (_asset) => {
                assetListManager.addToStore(_asset);
                this._oninit(assetId, state);
            }, () => {
                state.isInvalid = true;
            });
        } 
    }

    _oninit(assetId, state) {
        if (!assetId || !store.assets[assetId]) {
            return console.error('invalid asset:', assetId);
        }
        const asset = assetListManager.getById(assetId, true, () => m.redraw());
        state.timestamp = formatDate.timestamp(asset.updatedDateTime);
        m.redraw();
    }
    
}

export class TinyCard extends AssetCard {
    view({attrs: {assetId, wrapClass, onClick, popupContent, hideOptions}, state}) {
        const showThumbnailSizeIcon = assetListManager.showThumbnailSizeIcon(assetId);
        return state.isInvalid ? <span class="tiny-card restricted"><PermissionsIndicator lockedMessage={restrictions.message(appModel.user, 'read')}/></span> : state.timestamp ? <span class={`tiny-card ${wrapClass || ''} ${state.menuIsOpen ? 'is-open' : 'is-closed'}`} onclick={onClick ? (e) => onClick(e, assetId) : null}>
            <AssetIcon assetId={assetId} isImage={showThumbnailSizeIcon}/>
            <span class="asset-title">{assetListManager.getAssetName(assetId)}</span>
            {!hideOptions && <AssetOptions assetId={assetId} onOpen={state.onOpen} onClose={state.onClose} popupContent={popupContent}/>}
        </span> : <span class="tiny-card loading-asset-card"><i class="spinner teal"/></span>;
    }
}

export class CommonPropertyCard extends AssetCard {
    view({attrs: {assetId}}) {
        const isImage = assetListManager.showThumbnailSizeIcon(assetId);
        const asset = store.assets[assetId];
        if (!asset.assetTypeId) {
            return console.error('Invalid asset:', asset);
        }
        return <div class="has-full-meta">
            <div class={`feature-menu-inner ${isImage ? 'has-preview' : 'has-no-preview'}`}>
                <div class="feature-menu-title-wrap">
                    <span class="asset-title">{assetListManager.getAssetName(assetId)}</span>
                    <AssetIcon assetId={assetId} isImage={isImage}/>
                </div>
            </div>
            <AssetMetaData assetId={assetId}/>
        </div>;
    }
}

export class MapFeatureCard extends AssetCard {
    view({state, attrs: {assetId, featureOptions}}) {
        const showCommonPropCard = appModel.state.isActiveInOneUp && libraryModel.isOpen && oneUpModel.cssClass !== 'small';
        const asset = assetListManager.getById(assetId, true, () => m.redraw());
        const assetType = assetListManager.getAssetType(assetId);
        const assetName = assetListManager.getAssetName(assetId);
        const showThumbnailSizeIcon = assetListManager.showThumbnailSizeIcon(assetId);
        const updatedDateTime = asset && asset.updatedDateTime ? formatDate.timestamp(asset.updatedDateTime) : null;

        const onClickHandler = async (e, tab = 'Properties') => {
            e.stopPropagation();
            state.isLoading = true;
            m.redraw();
            await assetListManager.fetch(assetId);
            formModel.viewAsset(assetId, tab);
        };

        const cssClasses = `feature-menu-inner${showThumbnailSizeIcon ? ' has-preview' : ' has-no-preview'}${state.isLoading ? ' is-loading' : ''} ${appModel.user.isViewOnly ? 'view-only' : ''}`;
        return showCommonPropCard ? <CommonPropertyCard assetId={assetId}/> : <div class={cssClasses} key={assetId}>
            <div class="feature-menu-title-wrap" onclick={stopPropagation((e) => onClickHandler(e))}>
                {state.isLoading ? <i class="spinner spinning teal fetching-asset-spinner"/> : ''}
                {!userManager.isViewOnly &&
                    <AssetSelection
                        isSelected={batchSelectModel.areNoAssetsSelected() ? false : batchSelectModel.selectedAssets[assetId]}
                        assetId={assetId}/>
                }
                <div class={`asset-title ${userManager.isViewOnly ? 'view-only' : ''}`}>{assetName}</div>
                <AssetIcon assetId={assetId} isImage={showThumbnailSizeIcon}/>
            </div>
            {formModel.assetId !== assetId && <AssetFormShortcuts assetId={assetId} onClickHandler={onClickHandler}/>}
            <div class="feature-menu-info-wrap" onclick={stopPropagation((e) => onClickHandler(e))}>
                <div class="asset-property-row"><span class="list-label">Type:</span> {assetType.name || <span class="text-loading loading"/>}</div>
                <div class="asset-property-row"><span class="list-label">Last Updated:</span> {updatedDateTime || '...'}</div>
            </div>
            {featureOptions}
        </div>
        ;
    }
}

export default AssetCard;
