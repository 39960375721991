import panelModel from 'models/panel-model';
import appModel from 'models/app-model';
import BatchModify from 'views/batch-modify/batch-modify';
import store from 'util/data/store';
import constants from 'util/data/constants';
import oneUpModel from 'models/one-up-model';
import {assetIdToProjectId} from 'util/data/asset-id-to-project-id';
import router from 'uav-router';
import api from 'legacy/util/api/api';
import dialogModel from 'models/dialog-model';
import onBodyClick from 'legacy/util/dom/on-body-click';
import randomId from 'util/numbers/random-id';
import message from 'views/toast-message/toast-message';
import tableModel from 'models/table/table-model';

const controlTypeNameToId = constants.controlTypeNameToId;
let projectView;

const UNSUPPORTED_CONTROL_TYPES = {
    [controlTypeNameToId.comments]: 1,
    [controlTypeNameToId.links]: 1,
    [controlTypeNameToId.asset]: 1,
    [controlTypeNameToId.plan]: 1,
    [controlTypeNameToId.project]: 1,
    [controlTypeNameToId.survey]: 1,
    [controlTypeNameToId.file]: 1,
    [controlTypeNameToId.coordinates]: 1,
    [controlTypeNameToId.request]: 1,
    [controlTypeNameToId.embed]: 1,
    [controlTypeNameToId.place]: 1,
    [controlTypeNameToId.URL]: 1
};

const batchModifyModel = {

    // ------ Initialization / set up ------

    start() {
        projectView = tableModel.projectView;
        panelModel.openInModal({
            view: BatchModify,
            styleClass: 'form-style',
            stateClass: '', // For temporary state changes ie saving or invalid
            handleDone: () => batchModifyModel.saveModifications(),
            disableDoneBtn: true
        }, {styleClass: 'no-padding'});
        batchModifyModel.resetSelections();
        batchModifyModel.setDefaultSelections();
    },

    get assetTypesByToolGroup() {
        if (!batchModifyModel.cachedAssetTypesByToolGroup) {
            batchModifyModel.cachedAssetTypesByToolGroup = {};
            projectView.common.toolGroups.forEach((toolGroup) => {
                batchModifyModel.cachedAssetTypesByToolGroup[toolGroup.toolGroupId] = toolGroup.tools.map((tool) => tool.assetForm.assetType);
            });
        }
        return batchModifyModel.cachedAssetTypesByToolGroup;
    },

    resetSelections() {
        Object.assign(batchModifyModel, {
            modificationsList: {},
            emptyModifiers: {}, // Double-check that intended these to be empty before saving.
            importSource: {
                mediaId: null,
                label: ''
            },
            assetMenuOpen: false
        });
    },

    openAssetMenu() {
        if (!batchModifyModel.assetMenuOpen) {
            batchModifyModel.assetMenuOpen = true;
            onBodyClick.once((e) => batchModifyModel.closeAssetMenu(e));
            m.redraw();
        }
    },

    closeAssetMenu() {
        if (batchModifyModel.assetMenuOpen) {
            batchModifyModel.assetMenuOpen = false;
            onBodyClick.clear((e) => batchModifyModel.closeAssetMenu(e));
            m.redraw();
        }
    },

    setDefaultSelections() {
        // Set default asset type (choose first in asset type menu that has assets)
        let assetTypeId;
        let validAssetType;
        const toolGroupArray = Object.values(batchModifyModel.assetTypesByToolGroup);
        for (let i = 0; i < toolGroupArray.length; i++) {
            const assetTypes = toolGroupArray[i];
            validAssetType = assetTypes.find(assetType => tableModel.assetTypeCounts[assetType.assetTypeId] > 0);
            if (validAssetType) {
                assetTypeId = validAssetType.assetTypeId;
                break;
            }
        }

        // If no assets exist, then nothing available to batch modify
        if (!assetTypeId) {
            return batchModifyModel.handleNoBatchableAssets();
        }
        batchModifyModel.selectAssetType(assetTypeId);
    },

    // ------ Asset Type Selection ------

    selectAssetType(assetTypeId) {
        const toolGroupId = store.assetTypeIdToToolGroupId[assetTypeId];
        const toolGroup = appModel.toolbox.toolGroups[toolGroupId];
        batchModifyModel.assetTypeId = assetTypeId;

        // Set default match field
        const assetFormControls = store.assetForms[store.assetTypeToFormId[assetTypeId]].controls;
        const editableControls = assetFormControls.filter(ctrl => !UNSUPPORTED_CONTROL_TYPES[ctrl.controlTypeId]);
        const matchableFields = [{key: 'unearthAssetId', name: projectView.common.displayAs.unearthId}, ...editableControls];
        const matchField = matchableFields.find(ctrl => !ctrl.attributes || !ctrl.attributes.readonly);
        if (!matchField) {
            return batchModifyModel.handleNoBatchableAssets();
        }

        Object.assign(batchModifyModel,
            {
                assetTypeId,
                editableControls,
                matchableFields,
                matchField: matchField.key || matchField.name,
                assetTypeName: store.assetTypes[batchModifyModel.assetTypeId].name,
                toolGroupName: toolGroup.name,
                assetTypeCount: tableModel.assetTypeCounts[batchModifyModel.assetTypeId],
                modificationsList: {},
                emptyModifiers: {},
                invalidModifiers: {}
            });
        batchModifyModel.closeAssetMenu();
        m.redraw();
    },


    // ------ Matching Rules Selection ------

    selectMatchField(fieldName) {
        batchModifyModel.matchField = fieldName;
        batchModifyModel.checkIfFormIsComplete();
    },

    selectFileSource() {
        const projectId = appModel.project.isMetaProject ? assetIdToProjectId(router.params.projectId) : router.params.projectId;
        oneUpModel.addUploadFlow({
            projectId,
            name: `Update from CSV: ${batchModifyModel.assetTypeName}`,
            maxFiles: 1,
            accept: ['.csv', 'text/plain']
        }).then(([mediaRecord]) => {
            batchModifyModel.importSource = {
                mediaId: mediaRecord.mediaId,
                label: mediaRecord.label
            };
            batchModifyModel.checkIfFormIsComplete();
        });
    },

    // ----- Modification Selection/Configuration -----

    updateModifierField(control, value) {
        const fieldName = control.fieldName;
        batchModifyModel.modificationsList[fieldName] = value;
        // Update tracking of empty modifier fields.
        if (value) {
            delete batchModifyModel.emptyModifiers[fieldName];
            delete batchModifyModel.invalidModifiers[fieldName];
        } else {
            batchModifyModel.emptyModifiers[fieldName] = 1;
            batchModifyModel.invalidModifiers[fieldName] = 1;
        }
        m.redraw();
    },

    toggleModifierField(control, toggleOn) {
        const fieldName = control.fieldName;
        if (!toggleOn) {
            delete batchModifyModel.modificationsList[fieldName];
            delete batchModifyModel.emptyModifiers[fieldName];
            delete batchModifyModel.invalidModifiers[fieldName];
        } else {
            batchModifyModel.modificationsList[fieldName] = '';
            batchModifyModel.emptyModifiers[fieldName] = 1;
            if (control.attributes && control.attributes.required) {
                batchModifyModel.invalidModifiers[fieldName] = 1;
            }
            batchModifyModel.stateClass = '';
        }
        batchModifyModel.checkIfFormIsComplete();
    },

    getSelectedValue(fieldName) {
        return batchModifyModel.modificationsList[fieldName];
    },

    // ----- Sending to API -----

    /* Return count of modifiers that are undefined */
    get numEmptyModifierFields() {
        return Object.keys(batchModifyModel.emptyModifiers).length;
    },

    /* Return count of modifiers that are required and not provided */
    get numInvalidModifierFields() {
        return Object.keys(batchModifyModel.invalidModifiers).length;
    },

    /* Map the modification list to api request shape */
    get _apiModificationList() {
        const modifications = [];
        Object.keys(batchModifyModel.modificationsList).map(field =>
            modifications.push({'field': field, 'value': batchModifyModel.modificationsList[field] || ''}));
        return modifications;
    },

    /* Before saving, validate and display message if invalid */
    saveModifications() {
        // Validate that we have all the data required:
        const numEmptyModifierFields = batchModifyModel.numEmptyModifierFields;
        if (numEmptyModifierFields) {
            let headline = `${numEmptyModifierFields} modifier field${numEmptyModifierFields === 1 ? ' is' : 's are'} empty.`;
            const text = <div>The data for {numEmptyModifierFields === 1 ? 'this field' : 'these fields'} will be deleted for all matching records. <br/>Do you wish to proceed?</div>;

            // Check for missing required fields and adjust the messaging if found:
            const numRequired = batchModifyModel.numInvalidModifierFields;
            if (numRequired > 0) {
                headline = `${numEmptyModifierFields} modifier field${numEmptyModifierFields === 1 ? ' is' : 's are'} empty, including ${numRequired} required field${numRequired > 1 ? 's' : ''}.`;
            }

            batchModifyModel.stateClass = 'validating';
            dialogModel.append({
                headline,
                text,
                cssClass: 'incomplete-form-warning add-shim',
                yesText: 'Yes',
                yesClass: 'btn btn-pill btn-red',
                noText: 'Cancel',
                noClass: 'btn btn-pill btn-secondary',
                onYes: () => {
                    batchModifyModel._saveModifications();
                }
            });
        } else {
            batchModifyModel._saveModifications();
        }
    },

    /* Now that we've validated they can proceed, send the change to the back end */
    _saveModifications() {
        const projectId = appModel.project.isMetaProject ? assetIdToProjectId(router.params.assetId) : router.params.projectId;
        const jobId = randomId();
        batchModifyModel.awaitingJobId = jobId;
        batchModifyModel.stateClass = 'saving';
        m.redraw();
        return api.rpc.batchModify({
            assetTypeId: batchModifyModel.assetTypeId,
            projectId,
            jobId,
            matchField: batchModifyModel.matchField,
            mediaId: batchModifyModel.importSource.mediaId,
            modifications: batchModifyModel._apiModificationList
        }).then(() => dialogModel.append({
            headline: 'Updating from CSV ...',
            text: <i class="spinner spinning gray"/>,
            cssClass: 'add-shim no-actions saving'
        }));
    },

    handleApiResponse(response) {
        if (response.jobId === batchModifyModel.awaitingJobId) {
            batchModifyModel.saving = true;
            if (response.errorRecords) {
                dialogModel.pop();
                dialogModel.append({
                    headline: 'Unable to process batch.',
                    text: <div>Please check your data and try again or contact customer support for assistance. <span
                        class='dialog-asset-count-notice'>No records were updated.</span></div>,
                    cssClass: 'add-shim'
                });
            } else {
                panelModel.close();
                const numRecordsModified = response.numberOfRecordsModified || 0;
                const messageText = !numRecordsModified ? 'No matching records were found.' : `${numRecordsModified} matching record${numRecordsModified > 1 ? 's are' : ' is'} being updated.`;
                message.show(messageText, 'success');
            }
        }
    },

    // ---------- Helpers --------------

    /* Form is complete if each field has a value and at least one modifier is selected */
    checkIfFormIsComplete() {
        // Check that we have all the necessary components and update the state
        const formIsComplete = batchModifyModel.assetTypeId
            && batchModifyModel.matchField
            && batchModifyModel.importSource.mediaId
            && Object.values(batchModifyModel.modificationsList).length;

        // If we're not ready, keep the done btn disabled.
        panelModel.disableDoneBtn = !formIsComplete;
        m.redraw();
    },

    getDefinedOptionsForControl(control) {
        const assetTypeFields = store.assetTypes[batchModifyModel.assetTypeId].fields;
        const field = assetTypeFields.find(formField => formField.name === control.fieldName);
        if (field.type.items && field.type.items.values) {
            return field.type.items.values;
        }
        if (field.type.values) {
            return field.type.values;
        }
    },

    handleNoBatchableAssets() {
        dialogModel.open({
            headline: 'There are no assets available to batch modify.',
            text: <div>This may be because the assets that exist have restricted permissions. Please contact customer success if you need assistance.</div>
        });
    },

    close() {
        panelModel.close();
        oneUpModel.clearActiveFlow();
    }

};

export default batchModifyModel;
