function deepMerge(newData, oldData = {}) {

    Object.keys(newData).forEach(key => {

        const value = newData[key];

        if (value && Object.getPrototypeOf(value) === Object.prototype) {

            oldData[key] = deepMerge(value, oldData[key]);

        } else {

            oldData[key] = value;

        }

    });

    return oldData;

}

export default deepMerge;
