import constants from 'util/data/constants';
import featureListManager from 'managers/feature-list-manager';
import appModel from 'models/app-model';
import {mapboxStylePropertyToText} from 'constants/models/feature/feature-style-constants';

class FeatureStyleModel {
    constructor(tool, featureStyle) {
        this.featureTypeId = featureStyle.featureType.featureTypeId;
        delete featureStyle.featureType; // Prefer the getter + this.featureTypeId

        Object.assign(this, featureStyle);

        const styleZOrder = tool.attributes.styleZOrder;
        this.zOrder = styleZOrder.indexOf(featureStyle.name);

        this.featureType.featureStyles[featureStyle.featureStyleId] = this;
        appModel.toolbox.featureStyles[featureStyle.featureStyleId] = this;

        if (featureStyle.style) {
            featureStyle.style.id = featureStyle.featureStyleId;
            featureStyle.style.source = this.featureTypeId;
            this._loadStyleImages(featureStyle.style);
            this._setStyledControls(featureStyle.style, tool);
        }

    }

    get featureType() {
        return appModel.toolbox.featureTypes[this.featureTypeId];
    }

    _loadStyleImages(style) {
        const iconImage = style.layout && style.layout['icon-image'];
        if (iconImage) {
            const imageLoadingPromises = appModel.toolbox.imageLoadingPromises;
            style.layout['icon-rotation-alignment'] = 'map'; // UE-4001
            if (iconImage[0] === 'match') {
                const arrayLength = iconImage.length;
                iconImage.slice(3).forEach((item, k) => {
                    if (k % 2 === 0) {
                        imageLoadingPromises.push(
                            featureListManager.addImage(item, constants.staticMediaURL + item)
                        );
                        // Unsual case coming in from toolkits: 
                        // If this is the last image in the array, and the array length was an odd number, 
                        // that means the default image didn't have a match key included, just a value. 
                    } else if (k === arrayLength - 4 && arrayLength % 2 !== 0) {
                        imageLoadingPromises.push(
                            featureListManager.addImage(item, constants.staticMediaURL + item)
                        );
                    }
                });
                
            } else if (iconImage.stops) {
                iconImage.stops.forEach(stop => {
                    const mediaId = stop[1];
                    imageLoadingPromises.push(
                        featureListManager.addImage(mediaId, constants.staticMediaURL + mediaId)
                    );
                });

                if (iconImage.default) {
                    imageLoadingPromises.push(
                        featureListManager.addImage(iconImage.default, constants.staticMediaURL + iconImage.default)
                    );
                }

            } else if (typeof iconImage === 'string') {
                imageLoadingPromises.push(
                    featureListManager.addImage(iconImage, constants.staticMediaURL + iconImage)
                );
            }
            appModel.toolbox.imageLoadingPromises = imageLoadingPromises;
        }
    }

    _setStyledControls(style, tool) {
        const styledControls = this.featureType.attributes.styledControls;
        if (styledControls && styledControls.length) {       
            const layoutProperties = style.layout ? Object.keys(style.layout) : [];
            const paintProperties = style.paint ? Object.keys(style.paint) : [];

            this._findAndSetControlProperties(layoutProperties, style.layout, tool);
            this._findAndSetControlProperties(paintProperties, style.paint, tool);
        }
    }

    _findAndSetControlProperties(properties, valueMap, tool) {
        const styledControls = this.featureType.attributes.styledControls;
        properties.forEach(property => {
            const layoutValue = valueMap[property];
            if (Array.isArray(layoutValue)) {
                const values = layoutValue.flat(10);
                styledControls.forEach(controlLabel => {
                    if (values.find(value => value === controlLabel)) {
                        tool.styledControls[controlLabel] = tool.styledControls[controlLabel] || {};
                        tool.styledControls[controlLabel][property] = mapboxStylePropertyToText[property];
                    }
                });
            }
        });
    }

}

export default FeatureStyleModel;
