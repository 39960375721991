import constants from 'util/data/constants';

const tableConstants = {

    UNSUPPORTED_CONTROL_TYPES: {
        [constants.controlTypeNameToId.comments]: 1,
        [constants.controlTypeNameToId.links]: 1,
        [constants.controlTypeNameToId.name]: 1,
        [constants.controlTypeNameToId.coordinates]: 1,
        [constants.controlTypeNameToId.color]: 1,
        [constants.controlTypeNameToId.plan]: 1,
        [constants.controlTypeNameToId.project]: 1,
        [constants.controlTypeNameToId.survey]: 1,
        [constants.controlTypeNameToId.embed]: 1,
        [constants.controlTypeNameToId.imodel]: 1
    },

    COMMON_HEADERS: ['unearthId', 'category', 'type', 'addedDateTime', 'addedBy', 'lastUpdated', 'lastUpdatedBy'],
    COLUMN_GROUP_HEADERS: 'column-groups-headers',
    PROPERTY_LIST: 'properties-list',

    X_SCROLL: 'x-scroll-container',
    Y_SCROLL: 'y-scroll-container',

    COL_WIDTH: 240,
    WIDTH_CATEGORY: 180,
    WIDTH_LINKS: 120,
    WIDTH_PRIMARY: 360,

    LOAD_PAGE_AT: 4330,
    ROW_HEIGHT: 48,
    BUFFER_ROW_COUNT: 50,
    ITEMS_PER_PAGE: 400,

    defaultVisibility: {
        list: {
            category: true,
            addedBy: true,
            type: true,
            places: false,
            addedDateTime: true,
            lastUpdated: true,
            lastUpdatedBy: false,
            unearthId: false,
            links: false
        },
        table: {
            category: true,
            addedBy: true,
            type: true,
            places: false,
            addedDateTime: true,
            lastUpdated: true,
            lastUpdatedBy: true,
            unearthId: false,
            links: true                
        }
    }


};

export default tableConstants;
