import router from 'uav-router';
import peopleModel from 'models/people/people-model';
import appModel from 'models/app-model';

const propertyToLabel = {
    'givenName': 'First Name',
    'familyName': 'Last Name',
    'phoneNumber': 'Phone Number',
    'emailAddress': 'Email Address',
    'company': 'Company',
    'role': 'Role'
};

/* Functional components for displaying user profile data from PersonModel. */
export const Avatar = {
    view: ({attrs: {userId, person, size}}) => <div class={`user-avatar icon-initials ${size || ''}`}>{person ? person.initials() : peopleModel.getPerson(userId).initials()}</div>
};

export const ViewProfileButton = {
    view: ({attrs: {person}}) => <div class="menu-option menu-open" onclick={() => peopleModel.open('profile', person.userId || peopleModel.viewingUserId)}><i class="icon-user" /> View Profile</div>
};

export const ManageNotificationsButton = {
    view: ({attrs: {smallerView, person}}) => <div class="menu-option menu-open" onclick={() => peopleModel.open('notifications', person.userId || peopleModel.viewingUserId)}><i class="icon-circle-exclaim" />{smallerView ? '' : 'Manage '}Notifications</div>
};

export const RemoveAccessButton = {
    view: ({attrs: {person}}) => <div class="menu-option menu-delete" onclick={(e) => {
        e.stopPropagation();
        peopleModel.deletePerson(person.userId);
    }}><i class="icon-circle-slash" /> Remove Access</div>
};

export const PersonMenu = {
    view: () => {
        const person = peopleModel.viewingPerson;
        const viewingProjectAsset = !!router.params.assetId;
        const hasAdminPrivileges = peopleModel.hasAdminPrivileges(appModel.user);
        return <div class="user-options">
            <div class="person-menu">
                <ViewProfileButton person={person} />
                {hasAdminPrivileges && <ManageNotificationsButton smallerView={viewingProjectAsset} person={person} />}
                {appModel.user.isSuperAdmin && !viewingProjectAsset && <RemoveAccessButton person={person} />}
            </div>
        </div>;
    }
};

export const LockedField = {
    view: ({attrs: {person, property, editable, lockedMessage}}) => {
        return <> {editable ? <div class="profile-field profile-row">
            <span class="label">{propertyToLabel[property]}</span>
            <span class="user-data locked-from-editing">{person.displayProperty(property)}</span>
            {lockedMessage && <span class="profile-form-message">{lockedMessage}</span>} </div> :
            <div class="profile-field profile-row">
                <span class="label">{propertyToLabel[property]}</span>
                <span class="user-data locked-from-editing">{person.displayProperty(property)}</span>
            </div>} </>;
    }
};

export const TextField = {
    view: ({attrs: {person, property, editable, inputType, isRequired}}) => {
        const isMissing = isRequired && (!person[property] || !person[property].length);
        return <>
            {editable ?
                <div class={`profile-field ${property === 'company' ? 'profile-row' : 'profile-row'} `}>
                    <span class="label">{propertyToLabel[property]}{isRequired && <span class='teal'>*</span>}
                    </span>
                    <input type={inputType || 'text'} oninput={(e) => person.autosave(e.target.value, property)}
                        className={`text-input ${person.formState.highlightMissing && isMissing ? 'missing-field' : ''}`}
                        defaultValue={person[property]}/>
                </div>
                : <div class="profile-field profile-row">
                    <span class="label">{propertyToLabel[property]}</span>
                    <span class="user-data">{person.displayProperty(property)}</span>
                </div>}
        </>;
    }
};

export const ContactInfo = {
    view: ({attrs: {person, isEditable}}) => {
        return <div class="contact-info">
            {person.isNewUser ? <div class='profile-fields-new-user'>
                <TextField person={person} isRequired={true} type='email' property={'emailAddress'} editable={true} />
                <div class="profile-row">
                    <TextField type={'tel'} isRequired={true} person={person} property={'phoneNumber'} editable={true} />
                </div>
                <TextField type={'text'} isRequired={true} person={person} property={'company'} editable={true}/>
            </div> : <div class='profile-fields-existing-user'>
                {isEditable && person.userCanEdit() && <div class="profile-field profile-row has-text-only"><span class="profile-form-message">Please <a href="mailto:unearthsupport@procore.com">contact our support team</a> {person.userCanEdit('company') ? 'if you need to update your Email Address or Phone Number.' : 'if you need to update your Email Address, Phone Number, or Company. An Administrator of this account can update your Company or Role.'}</span></div>}
                <LockedField person={person} property={'emailAddress'}
                    editable={isEditable && person.userCanEdit()} />
                <LockedField person={person} property={'phoneNumber'}
                    editable={isEditable && person.userCanEdit()} />
                {isEditable && person.userCanEdit('company') ?
                    <TextField type={'text'} isRequired={false} person={person} property={'company'} editable={true}/> :
                    <LockedField person={person} property={'company'}
                        editable={isEditable && person.userCanEdit()}/>
                }
            </div>}
        </div>;
    }
};
