import supersetModel from 'models/superset-model';

const ProgressDashboard = {
    view: () => {
        return  <div>
            <div class="progress-dashboard">
                <div>
                    <iframe
                        src={supersetModel.iframeUrl}
                        marginHeight="0"
                        marginWidth="0"
                        frameBorder="0"
                    />
                </div>
            </div>
        </div>;
    }

};

export default ProgressDashboard;
