// Stack class 
const INDEX_OFFSET = 1;

class Stack { 
  
    // Array is used to implement stack 
    constructor() { 
        this.items = [];
    } 

    
    // Adds an item to the top of the stack.
    push(element) { 
        this.items.push(element); 
    }

    // Removes and returns the top item from the stack. If empty, returns null.
    pop()  { 
        if (this.isEmpty())  {
            return null;
        }
        return this.items.pop();
    }

    // Return the next item to be popped (top of the stack) without removing it. 
    peek() {
        return this.items[this.items.length - INDEX_OFFSET]; 
    }

    // Return boolean if stack is empty
    isEmpty() { 
        return !this.items.length;
    } 

} 

export default Stack;
