import store from 'util/data/store';
import appModel from 'models/app-model';
import popupModel from 'models/popover-model';
import tableModel from 'models/table/table-model';
import filterUtil from 'util/table/filter-util';
import filterConstants from 'constants/models/table/filter-constants';
import definedOptionsModel from 'models/table/filter-types/defined-options-model';
import deepCloneObject from 'util/data/deep-clone-object';
import cellMenus from 'views/table/filter-menu/cell-menus';
import FilterMenus from 'views/table/filter-menu/filter-menus';
import {assetIsImageType} from 'util/data/helpers';

class FilterModel {

    constructor() {
        this.coordinates = {x: 0, y: 0};
        this.isOpen = false;
        this.isDirty = false;
        this.resetAllFilters();
    }

    filtersChanged() {
        if (this.onFiltersChanged) {
            return this.onFiltersChanged();
        }
        return tableModel.filtersChanged();
    }

    resetAllFilters() {
        this.filterStates = {};
        this.sharedFilterStates = {};
        this.searchString = undefined;
        this.sortField = filterConstants.DEFAULT_SORT;
    }

    setFilterAttributes(attributes) {
        this.filterStates = Object.assign({}, attributes.filterStates);
        this.sortField = attributes.sortField;
        if (attributes.sortField === undefined) {
            this.sortField = filterConstants.DEFAULT_SORT;
        }
        this.searchString = attributes.searchString;
    }

    initCurrentState() {
        if (!this.opts.assetType || !this.opts.controlType) {
            console.error(`Cannot get the filter state of: ${this.opts.assetType} and ${this.opts.controlType}`);
            return;
        }
        if (!this.filterStates[this.opts.assetType.assetTypeId]) {
            this.filterStates[this.opts.assetType.assetTypeId] = {};
        }
    }

    getState(sharedColumnName) {
        this.initCurrentState();
        if (sharedColumnName) {
            return this.sharedFilterStates[sharedColumnName];
        }
        return this.filterStates[this.opts.assetType.assetTypeId][this.opts.controlType.fieldName];
    }

    setState(state, sharedColumnName) {
        if (sharedColumnName) {
            this.sharedFilterStates[sharedColumnName] = state;
        } else {
            this.initCurrentState();
            this.filterStates[this.opts.assetType.assetTypeId][this.opts.controlType.fieldName] = state;
        }
    }

    _isFilterInUse(assetTypeId, controlTypeName) {
        if (!this.filterStates[assetTypeId] || !this.filterStates[assetTypeId][controlTypeName]) {
            return false;
        }

        const filterState = this.filterStates[assetTypeId][controlTypeName];

        let isFiltered = false;
        // Clean up any empty / redundant filters
        if (filterState) {

            if (filterState.type === 'definedOptions') {
                isFiltered = !definedOptionsModel.isAllSelected(filterState).isAllSelected;
            } else if (filterState.type) { // Checks that the filter has been initted (may be added as empty object for sorting request only)
                isFiltered = filterState.selectedRadioIndex !== 0;
            }
            if (!isFiltered) {
                delete this.filterStates[assetTypeId][controlTypeName];
                if (!Object.keys(this.filterStates[assetTypeId]).length) {
                    delete this.filterStates[assetTypeId];
                }
            }
        }
        return isFiltered;
    }


    isCommonColumnSorted(viewKey) {
        return this.sortField && this.sortField.type === viewKey;
    }

    doesMatchFilters(assetId) {
        const asset = store.assets[assetId];

        // Query filter
        // This is a case-insensitive exact match, so there will be some inconsistency
        // between the results of this check and the fuzzy back end text search.
        if (this.searchString) {
            const query = this.searchString.toLowerCase();
            const fields = store.assetTypeFields[asset.assetTypeId];
            const match = Object.keys(fields).find(label => {
                const value = asset.properties[label];
                return fields[label].index && typeof value === 'string' && value.toLowerCase().indexOf(query) !== -1;
            });
            if (!match) {
                return false;
            }
        }
        // Filter out meta project assets that this user is not authorized to view
        if (appModel.project.isMetaProject && appModel.user.projectIds) {
            const projectId = asset.properties._projectId;
            if (projectId && appModel.user.projectIds.indexOf(projectId) === -1) {
                return false;
            }
        }

        // Asset property filters
        const filtersForThisAssetType = this.filterStates[asset.assetTypeId];
        const isFilteredOut = filtersForThisAssetType && Object.values(filtersForThisAssetType).find(filter => {
            const index = filter.selectedRadioIndex,
                fieldValue = asset.properties[filter.fieldName];
            switch (filter.type) {
            case 'text':
                if (index === 1 && fieldValue !== undefined && fieldValue !== '') {
                    return true;
                } else if (index === 2 && (fieldValue === undefined || fieldValue === '')) {
                    return true;
                } else if (index === 3 && (!fieldValue || fieldValue.indexOf(filter.queryParm) === -1)) {
                    return true;
                }
                break;
            case 'numberRange':
                if (index === 1 && fieldValue !== undefined) {
                    return true;
                } else if (index === 2) {
                    if (filter.selectedSign === 'lte' && fieldValue > filter.range
                            || filter.selectedSign === 'eq' && fieldValue !== filter.range
                            || filter.selectedSign === 'gte' && fieldValue < filter.range) {
                        return true;
                    }
                } else if (index === 3 && (fieldValue < filter.rangeLow || fieldValue > filter.rangeHigh)) {
                    return true;
                }
                break;
            case 'definedOptions':
                const checkedOptions = Object.keys(filter.checkedOptions).filter(key => filter.checkedOptions[key]);
                if (!checkedOptions.find(key => fieldValue === key)) {
                    if (fieldValue !== undefined) {
                        return true;
                    } else if (!filter.isEmptyChecked) {
                        return true;
                    }
                }
                break;
            case 'dateRange':
                const date = new Date(asset.createdDateTime);
                if (!filterUtil.doesMatchDateFilter(date, filter.selectedRadioIndex, filter.rangeModel)) {
                    return true;
                }
                break;
            case 'boolean':
                if (index === 1 && !fieldValue || index === 2 && fieldValue) {
                    return true;
                }
                break;
            case 'placesFilter':
                if (index === 0) {
                    return false;
                } else if (index === 1) {
                    return fieldValue && fieldValue.placeIds;
                }
                if (!fieldValue) {
                    return false;
                }
                if (fieldValue.placeIds) {
                    const selected = {};
                    filter.selectedItems.forEach((id) => {
                        selected[id] = true;
                    });
                    for (let i = 0; i < fieldValue.placeIds.length; i++) {
                        const placeId = fieldValue.placeIds[i];
                        if (selected[placeId]) {
                            return false;
                        }
                    }
                    return true;
                }
            }

            return false;
        });

        return !isFilteredOut;

    }

    search(searchString) {
        this.searchString = searchString;
        this.filtersChanged();
    }

    _ascDesc(order) {
        switch (order) {
        case filterConstants.ASC:
            this.sortField.order = filterConstants.DESC;
            return;
        case filterConstants.DESC:
            this.sortField = undefined;
            return;
        default:
            this.sortField.order = filterConstants.ASC;
            return;
        }
    }

    toggleCommonSortField(value, type) {
        if (type === 'unearthIdFilter') {
            value = 'contentId';
        }
        const sortField = this.sortField;
        if (sortField && sortField.value === value) {
            this._ascDesc(sortField.order);
            this.filtersChanged();
            return;
        }
        this.sortField = {order: filterConstants.ASC, isCommon: true, value, type, sharedColumnName: this.opts.sharedColumnName};
        this.filtersChanged();
    }

    toggleSortField() {
        const assetTypeId = this.opts.assetType.assetTypeId;
        const controlName = this.opts.controlType.fieldName;
        const sortField = this.sortField;

        // if there is an asset type when attempting to sort turn off all other asset types
        if (assetTypeId) {
            appModel.projectView.common.turnOffAllAssetTypesExcept(assetTypeId, true);
        }

        if (sortField && !this.sortField.isCommon && this.sortField.assetTypeId === assetTypeId && this.sortField.controlName === this.opts.controlType.fieldName) {
            const sortedColumnName = this.sortField.sharedColumnName;
            const sharedColumnName = this.opts ? this.opts.sharedColumnName : null;
            // Check for special case of tag column with matching assetTypeId of a regular column
            if (sortedColumnName === sharedColumnName) {
                this._ascDesc(sortField.order);
                this.filtersChanged();
                return;
            }
        }
        this.sortField = {assetTypeId, controlName, order: filterConstants.ASC, isCommon: false, sharedColumnName: this.opts.sharedColumnName};
        this.filtersChanged();
    }

    getSearchString() {
        if (this.searchString) {
            return this.searchString.replace(/\s+/g, '%');
        }
    }

    /* --- Get Args Sub-Methods ---- */

    /**
     * Sort order: This is where the table ordering lives:
     * In the case where we are requesting to sort a custom field that is NOT filtered, we must manually include
     * that assetTypeId as a separate filter item for the rpc request to work (so sorting should be checked
     * before filtering):
     */
    configureSortOrder(args) {
        if (this.sortField.isCommon) {
            args.order = `${this.sortField.value} ${this.sortField.order}`;
        } else if (this.sortField.controlName === 'Capture Date' && assetIsImageType({assetTypeId: this.sortField.assetTypeId})) {
            args.order = `captureDateTime ${this.sortField.order}`;
        } else {
            const state = this.filterStates[this.sortField.assetTypeId] ? this.filterStates[this.sortField.assetTypeId][this.sortField.controlName] : '';
            if (!state) {
                this.filterStates[this.sortField.assetTypeId] =  this.filterStates[this.sortField.assetTypeId] || {};
                this.filterStates[this.sortField.assetTypeId][this.sortField.controlName] = {};
            }
            args.order = `properties.${this.sortField.controlName} ${this.sortField.order}`;
        }

        return args;
    }

    /* ---- Helpers ---- */

    /**
     * Returns unique string with assetTypeId, controlType name, and (if applicable) share tag.
     * Used to ensure view is not recycled for like filter menus.
     **/
    get filterColumnKey() {
        if (!this.opts) {
            return '';
        }
        return this.opts.assetType.assetTypeId + this.opts.controlType.fieldName + this.opts.sharedColumnName;
    }

    get currentFilterDisplayName() {
        if (!this.opts) {
            return '';
        }
        return this.opts.controlType.label;
    }

    get toolGroups() {
        return Object.values(appModel.toolbox.toolGroups);
    }

    /* ---- Filter Popup Menus ---- */
    open(element, opts) {
        this.opts = opts;
        const shouldForceInit = popupModel.isOpen;
        const view = this.opts.isCellMenu
            ? cellMenus[opts.viewKey]
            : FilterMenus[opts.viewKey];
        if (shouldForceInit) {
            if (view && view.oninit) {
                view.oninit();
            }
        }
        const wrapClass = `filter-menu${opts.wrapClass ? ` ${opts.wrapClass}` : ''}`;
        popupModel.open(element, {view, wrapClass});
    }

    hasCellMenu(controlTypeId) {
        return !!cellMenus[controlTypeId];
    }

    hasFilterMenu(controlTypeId) {
        return !!FilterMenus[controlTypeId];
    }

    /* --- Shared Column Syncing Filter Settings ---- */


    /**
     * Sync the passed assetTypeIds filter states to match the passed state object.
     */
    syncFilterStates(sharedFilter) {
        const state = sharedFilter.state;
        const sharedColumn = sharedFilter.sharedColumn;
        const name = sharedColumn.controlType.fieldName;
        const normalizedState = deepCloneObject(state);
        if (normalizedState.checkedOptions) {
            Object.keys(normalizedState.checkedOptions).forEach(opt => {
                const val = normalizedState.checkedOptions[opt];
                // Make sure the values are boolean, exclude "mixed" option from standard filter
                normalizedState.checkedOptions[opt] = val ? true : false;
            });

            normalizedState.isEmptyChecked = state.isEmptyChecked ? true : false;
        }

        sharedColumn.assetTypeIds.forEach(id => {
            this.filterStates[id] = this.filterStates[id] || {}; // If no filter applied yet, create it
            this.filterStates[id][name] = deepCloneObject(normalizedState); // Copy all filter settings
            this.filterStates[id][name].fieldName = name; // Don't overwrite the fieldName, it's used for API filter request
        });

        this.filtersChanged();
    }

    /**
    * Runs after shared column filter is applied: Updates all the other properties matching that tag to select the same *single option*
    * (Does not sync entire state of filter — for example, only will sync the checkedOption passed)
    */
    syncTaggedStatesSingleOption(option, sharedFilter) {
        const state = sharedFilter.state;
        const sharedColumn = sharedFilter.sharedColumn;
        const name = sharedColumn.controlType.fieldName;

        sharedColumn.assetTypeIds.forEach(id => {
            this.filterStates[id] = this.filterStates[id] || {};

            // If the filter doesn't exist yet, duplicate the shared filter
            if (!this.filterStates[id][name]) {
                this.filterStates[id][name] = deepCloneObject(state);
                Object.keys(state.checkedOptions).forEach(opt => {
                    const val = state.checkedOptions[opt];
                    // Make sure the values are boolean, exclude "mixed" option from standard filter
                    this.filterStates[id][name].checkedOptions[opt] = val ? true : false;
                });
                this.filterStates[id][name].fieldName = name;
            }
            // Update the value for the option passed:
            const value = state.checkedOptions[option];
            if (option !== 'isEmptyCheckedOption') {
                this.filterStates[id][name].checkedOptions[option] = value ? true : false;
            } else {
                this.filterStates[id][name].isEmptyChecked = state.isEmptyChecked ? true : false;
            }

            if (!appModel.projectView.common.checkedAssetTypes[id]) {
                appModel.projectView.common.toggleAssetType(id, true);
            }
        });
        if (tableModel.projectView.filters.opts.assetType.assetTypeId && appModel.projectView.common.areAllAssetTypesChecked().isAllSelected) {
            appModel.projectView.common.turnOffAllAssetTypesExceptMultiple(sharedColumn.assetTypeIds, true);
        }

        this.filtersChanged();
    }

}

export default FilterModel;
