import exportPDFModel from 'models/export-pdf-model';
import siteModel from 'models/site-model';

const ExportPrompt = {
    onremove: () => {
        siteModel.view = null;
    },

    view: () =>
        <div>
            <div class="tool-launcher export-prompt">
                <div class="asset-title-wrap">
                    <i class="icon-download icon asset-icon" />
                    <div class="asset-title">PDF Export</div>
                    {exportPDFModel.isLoading || exportPDFModel.isWaiting ? <i className="loader-v2"/> : null}
                    <div class="btn btn-smallest btn-secondary btn-pill asset-cancel" onclick={() => exportPDFModel.close()}>{exportPDFModel.isWaiting ? 'Close' : 'Cancel'}</div>
                </div>
                <div class="tool-launcher-prompt">
                    Select a paper size and position the map. Tap "Export" when ready to export your PDF.
                </div>
                <div>
                    <div class="prompt-container clear">
                        <div className="left paper-size-selector">
                            <label>Paper Size</label>
                            <div className="select-wrapper">
                                <select onchange={(e) => {
                                    exportPDFModel.selectPaperSize(exportPDFModel.options[e.target.value]);
                                }}>
                                    {exportPDFModel.options.map((o, i) =>
                                        <option selected={exportPDFModel.option === exportPDFModel.options[i]}
                                            value={i}>
                                            {`${o.width}'' x ${o.height}''`}
                                        </option>)
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="right">
                            <label>Paper Layout</label>
                            <div>
                                <div className={`paper-container left ${exportPDFModel.orientation === exportPDFModel.HORIZONTAL ? 'active' : ''}`}
                                    onclick={() => exportPDFModel.selectOrientation(exportPDFModel.HORIZONTAL)}>
                                    <span className="page"/>
                                </div>
                                <div className={`paper-container right vertical ${exportPDFModel.orientation === exportPDFModel.VERTICAL ? 'active' : ''}`}
                                    onclick={() => exportPDFModel.selectOrientation(exportPDFModel.VERTICAL)}>
                                    <span className="page"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="prompt-container clear">
                        <label>Title & Legend Location</label>
                        <div class="select-wrapper">
                            <select onchange={(e) => exportPDFModel.selectLegendPosition(e.target.value)}
                                className="select-wrapper" >
                                {exportPDFModel.positionOptions.map((position) =>
                                    <option selected={exportPDFModel.position === position}>{position}</option>)}
                            </select>
                        </div>
                        <div className={`btn btn-primary btn-pill btn-small right ${exportPDFModel.isLoading || exportPDFModel.isWaiting ? 'disabled' : ''}`}
                            onclick={() => exportPDFModel.send()}>
                            <span className="btn-label">Export</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
};

export default ExportPrompt;
