import api from 'legacy/util/api';

let _cache = {};

let loaded = {};

const doesNotSupportVisible = {
    User: true
};

function argsToKey(recordType, args = {}) {

    delete args.offset;
    delete args.limit;

    if (!doesNotSupportVisible[recordType]) {

        args.isVisible = args.isVisible !== false;

    }

    let key = '';

    Object.keys(args).sort((a, b) => a > b ? 1 : -1).forEach(k => {

        key += k + args[k];

    });

    return key;

}

function setUpCacheKey(recordType, key) {

    _cache[recordType] = _cache[recordType] || {};

    _cache[recordType][key] = _cache[recordType][key] || {};

}

function rpcCache(pluralRecordType, args) {

    const {offset = 0, limit} = args;

    const recordType = pluralRecordType.replace(/s$/, '');

    const key = argsToKey(recordType, args);

    setUpCacheKey(recordType, key);

    const data = _cache[recordType][key];

    const dataList = Object.values(data);

    const isLoaded = loaded[recordType] && loaded[recordType][key];

    if (limit) {

        if (isLoaded || dataList.length >= offset + limit) {

            return new Promise(resolve => requestAnimationFrame(() => 
                resolve(dataList.slice(offset, offset + limit))
            ));

        }

    } else if (isLoaded) {

        // return Promise.resolve(data);

        return new Promise(resolve => requestAnimationFrame(() => resolve(dataList)));

    }

    return api.rpc.request([['list' + pluralRecordType, Object.assign(args, {
        limit,
        offset: dataList.length
    })]]).then(results => {

        const idKey = recordType.toLowerCase() + 'Id';

        results.forEach(result => {

            data[result[idKey]] = result;

        });

        if (!limit || results.length < limit) {

            loaded[recordType] = loaded[recordType] && loaded[recordType] || {};

            loaded[recordType][key] = true;

        }

        return results;

    });

}

rpcCache.clear = () => {

    _cache = {};

    loaded = {};

};

rpcCache.get = (recordType, id, args) => {

    const key = argsToKey(recordType, args);

    setUpCacheKey(recordType, key);

    const record = _cache[recordType][key][id];

    return record ? Promise.resolve(record) : api.rpc.get(recordType, id).then(result => {

        _cache[recordType][key][id] = result;

        return result;

    });

};

rpcCache.replace = (recordType, record) => {

    let found;

    const recordCache = _cache[recordType];

    if (recordCache) {

        const idKey = recordType.toLowerCase() + 'Id';

        const id = record[idKey];

        Object.values(recordCache).forEach(records => {

            for (const cachedRecord of Object.values(records)) {

                if (cachedRecord[idKey] === id) {

                    Object.assign(cachedRecord, record);

                    found = true;

                    break;

                }

            }

        });

    }

    return found;

};

rpcCache.add = (recordType, record, args) => {

    const key = argsToKey(recordType, args);

    const idKey = recordType.toLowerCase() + 'Id';

    const id = record[idKey];

    setUpCacheKey(recordType, key);

    _cache[recordType][key][id] = record;

};

rpcCache.remove = (recordType, id) => {

    if (_cache[recordType]) {

        Object.values(_cache[recordType]).forEach(records => {

            delete records[id];

        });

    }

};

export default rpcCache;
