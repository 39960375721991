import exportPDFModel from 'models/export-pdf-model';
import swap from 'legacy/util/swap';
import store from 'util/data/store';
import router from 'uav-router';
import MapScale from './map-scale';
import siteModel from 'models/site-model';
import constants from 'util/data/constants';

const LeftRight = {
    oncreate: () => {
        exportPDFModel.postRenderLeftRight();
    },

    onupdate: () => {
        exportPDFModel.postRenderLeftRight();
    },

    view: () => {
        const assetTypes = exportPDFModel.assetTypeMap ? Object.values(exportPDFModel.assetTypeMap) : false;
        const showLegend = assetTypes && assetTypes.length > 0;
        const headerValues = exportPDFModel.getHeaderValues();
        const showLegendMeta = !exportPDFModel.isExportingContentCardFor;
        return <div className={`legend ${showLegendMeta ? 'show-legend-meta' : 'hide-legend-meta'}`} style={exportPDFModel.legendPositionStyle} id={exportPDFModel.legendPositionId}>
            {showLegendMeta && <div id={exportPDFModel.legendTitleId} className="legend-title" style={exportPDFModel.legendTitleStyle}>
                {store.project.name}
            </div>}
            {showLegendMeta && <div id={exportPDFModel.legendProjectContainerId}>
                {headerValues && headerValues.length > 0 && headerValues.map((key) =>{
                    return <div className="legend-value-container" style={exportPDFModel.legendProjectValueStyle}>
                        <label className="legend-value-label" style={exportPDFModel.legendFieldLabelStyle}>{key}</label>
                        <span className="legend-field-value" style={exportPDFModel.legendFieldValueStyle}>{exportPDFModel.pdfHeaderValues[key] || ''}</span>
                    </div>;
                })}
            </div> }
            {showLegend && <div className="legend-asset-types" style={exportPDFModel.legendAssetTypesStyle}>
                {<div style={exportPDFModel.legendSectionTitleStyle}>Legend</div>}
                {assetTypes.reverse().map((assetType) =>{
                    return <div style={exportPDFModel.assetTypeContainerStyle} className="asset-type-container">
                        <img className="asset-type-icon-pdf" style={exportPDFModel.assetTypeIconStyle} src={`${constants.staticMediaURL}${assetType.attributes.icon.mediaId}`}/>
                        <div style="display: inline-block">
                            <span style={exportPDFModel.assetTypeValueStyle}>{assetType.name}</span>
                        </div>
                    </div>;
                })}
            </div>}
        </div>;
    }
};

const TopBottom = {

    renderColumns: (assetTypes) => {
        const {columnLengths} = exportPDFModel;
        const columns = [];
        let i = assetTypes.length - 1;
        columnLengths.forEach((length, index) => {
            const columnDivs = [];
            for (let j = 0; j < length; j++) {
                const assetType = assetTypes[i];
                i--;
                columnDivs.push(<div style={exportPDFModel.assetTypeContainerStyle} className="asset-type-container">
                    <img className="asset-type-icon-pdf" style={exportPDFModel.assetTypeIconStyle} src={`${constants.staticMediaURL}${assetType.attributes.icon.mediaId}`}/>
                    <div style="display: inline-block"><span style={exportPDFModel.assetTypeValueStyle}>{assetType.name}</span></div>
                </div>);
            }
            columns.push(<div id={`column-${index}`} style={exportPDFModel.legendAssetTypesStyle}>
                {columnDivs}
            </div>);
        });

        return columns;
    },

    oncreate: () => {
        if (!exportPDFModel.isLoading) {
            exportPDFModel.postRenderTopBottom();
        }
    },

    onupdate: () => {
        if (!exportPDFModel.isLoading) {
            exportPDFModel.postRenderTopBottom();
        }
    },

    view: () => {
        if (!exportPDFModel.assetTypeMap || exportPDFModel.isLoading) {
            return <></>;
        }
        const assetTypes = Object.values(exportPDFModel.assetTypeMap);
        const headerValues = exportPDFModel.getHeaderValues();

        const showLegendMeta = !exportPDFModel.isExportingContentCardFor;
        return <div id={exportPDFModel.legendPositionId} className={`legend ${showLegendMeta ? 'show-legend-meta' : 'hide-legend-meta'}`}
            style={exportPDFModel.legendPositionStyle}>
            <div id={exportPDFModel.legendProjectContainerId} class={assetTypes.length > 0 ? 'header-horizontal' : 'header-horizontal empty'}
                style={exportPDFModel.legendProjectContainerStyle}>
                {showLegendMeta && <div id={exportPDFModel.legendTitleId} className="legend-title" style={exportPDFModel.legendTitleStyle}>
                    {store.project.name}
                </div>}
                {showLegendMeta && headerValues && headerValues.length > 0 ? headerValues.map((key) => {
                    return <div className="legend-value-container"
                        style={exportPDFModel.legendProjectValueStyle}>
                        <label className="legend-value-label"
                            style={exportPDFModel.legendFieldLabelStyle}>{key}</label>
                        <span className="legend-field-value"
                            style={exportPDFModel.legendFieldValueStyle}>{exportPDFModel.pdfHeaderValues[key] || ''}</span>
                    </div>;
                }) : <div className="legend-value-container" style={exportPDFModel.legendProjectValueStyle}/>}
            </div>
            <div id={exportPDFModel.legendAssetTypesContainerId}>
                {assetTypes.length > 0 && <div id={exportPDFModel.legendContainerId} className="legend-container" style={exportPDFModel.legendSectionTitleStyle}>
                    <div className="legend-title">Legend</div>
                </div>}
                <div className="columns-container">
                    {assetTypes.length > 0 && TopBottom.renderColumns(assetTypes)}
                </div>
            </div>
        </div>;
    }
};

const PDFExporter = {

    onremove: () => exportPDFModel.cleanup(),

    oninit: () => {
        if (router.params.view !== 'pdf') {
            exportPDFModel.init();
        }
    },

    view: () =>
        <div class="exporter">
            <div class="pdf-selector">
                <div className="pdf-box" style={exportPDFModel.PDFPositionStyle}>
                    <div className="pdf-box-inner">
                        <div class={`pdf-inner-components ${exportPDFModel.position}`}>
                            <div class="legend-wrapper">
                                {swap(exportPDFModel.position, {
                                    top: () => <TopBottom/>,
                                    bottom: () => <TopBottom/>,
                                    left: () => <LeftRight/>,
                                    right: () => <LeftRight/>,
                                    default: () => <LeftRight/>
                                })}
                            </div>
                            <div className={`scale-and-compass-wrapper ${exportPDFModel.position}`}>
                                <div
                                    id={exportPDFModel.compassId}
                                    className="compass"
                                    style={exportPDFModel.compassStyle + `transform:rotate(${siteModel.map.rotation * -1}deg)`}
                                >
                                    N
                                    <i className="icon-location-filled" style={exportPDFModel.compassIconStyle}/>
                                </div>
                            </div>
                        </div>
                        <div class="pdf-inner-scale-wrapper">
                            <MapScale newWrapperClass="pdf-inner-scale-wrapper" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
};

export default PDFExporter;
