import placesTabModel from 'models/places-tab-model';
import popoverModel from 'models/popover-model';
class AddLevelPopup {

    onremove() {
        delete placesTabModel.activeLevelId;
    }

    view() {
        return <div className="places-tab-popup table-popup form-control-popup align-top align-right">
            <div className="form-control-popup-header">
                <div className="form-popup-title-wrap"/>
                <div className="form-control-popup-form">
                    <div className="level-input-wrap left">
                        <div className="input-title">Level Name</div>
                        <input
                            type="text"
                            placeholder="Level Name"
                            className="level-name"
                            value={placesTabModel.editingLevel.name}
                            oninput={e => {
                                placesTabModel.editingLevel.name = e.target.value;
                            }}/>
                    </div>
                    <div className="level-input-wrap right">
                        <div className="input-title">Elevation (ft ASL)</div>
                        <input
                            type="number"
                            className="level-elev"
                            value={placesTabModel.editingLevel.elevation}
                            oninput={e => {
                                placesTabModel.editingLevel.elevation = e.target.value;
                            }}/>
                    </div>
                </div>
                <div className="form-control-popup-footer">
                    <div className="btn btn-secondary btn-tiny btn-pill" onclick={() => popoverModel.close()}>Cancel</div>
                    <div className="btn btn-primary btn-tiny btn-pill form-control-popup-done" onclick={() => {
                        if (placesTabModel.editingLevel.isNew) {
                            placesTabModel.saveNewLevel();
                        } else {
                            placesTabModel.saveModifyLevel();
                        }
                        popoverModel.close();
                    }}>Done</div>
                </div>
            </div>
        </div>;
    }
}

export default AddLevelPopup;
