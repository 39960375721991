import AutoComplete from 'views/auto-complete/auto-complete';


export const RichTextSegment = {
    view: ({attrs: {segment}}) => 
        <span class={segment.styleClass}>{segment.innerText}</span>
};

const TextEditor = {

    oncreate: ({state, dom, attrs: {model}}) => {
        model.setDom(dom.firstElementChild);
        state.model = model;
        if (model.autoFocus) {
            model.state.isEditing = true;
            m.redraw();
        }
    },

    onupdate: (vnode) => {
        const model = vnode.state.model;
        if (model && model.syncHtml && model.onNextRender) {
            model.onNextRender(vnode.dom.firstElementChild);
            model.syncHtml = false;
            model.onNextRender = null;
        }
    },

    view: ({state, attrs: {wrapClass}}) => {
        const model = state.model;
        const isAutoCompleting = model ? model.state.isAutoCompleting : false;
        const isInsertingMention = model ? model.state.insertingMention : false;
        const isEditing = model ? model.state.isEditing : false;
        return <div class={`text-editor-wrap ${isInsertingMention ? 'inserting-mention' : ''}`}> 
            {model ? <div contenteditable="true" class={`text-editor ${model.focusClass} ${wrapClass || ''} ${isEditing ? 'focused editing' : ''}`} tabindex="-1"
                onblur={(e) =>  model.handleBlur(e)}
                onfocus={(e) => model.handleFocus(e)}
                oninput={(e) => model.handleInput(e)}
                onkeyup={(e) => model.handleKeyUp(e)}
                onkeydown={(e) => model.handleKeyDown(e)}
                onclick={(e) => model.handleClick(e)}
                onpaste={(e) => model.handlePaste(e)}
                onscroll={(e) => model.handleScroll(e)}> 
            </div> : <div class='text-editor'></div> }
            {model && isAutoCompleting && <AutoComplete model={model.autocompleter}/>} </div>;
    }
};

export default TextEditor;
