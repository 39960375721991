function point(coordinates) {
    if (!Array.isArray(coordinates)
        || coordinates.length < 2
        || coordinates.find(coord => typeof coord !== 'number')) {
        return false;
    }
    return true;
}

const areEqualPoints = (a, b) =>  a[0] === b[0] && a[1] === b[1];

function linestring(coordinates) {
    if (!Array.isArray(coordinates)
        || coordinates.length < 2
        || coordinates.find(coord => !point(coord))) {
        return false;
    }
    return true;
}

function linearRing(coordinates) {
    if (!linestring(coordinates)
        || coordinates.length < 4
        || !areEqualPoints(coordinates[0], coordinates[coordinates.length - 1])) {
        return false;
    }
    return true;
}

function polygon(coordinates) {
    if (!Array.isArray(coordinates)
        || !coordinates.length
        || coordinates.find(coord => !linearRing(coord))) {
        return false;
    }
    return true;
}

function validate(geom) {

    if (!geom.type || !geom.coordinates) {
        return false;
    }

    let type = geom.type.toLowerCase(),
        coordinates;

    if (type.startsWith('multi')) {

        type = type.replace('multi', '');

        coordinates = geom.coordinates;

    } else {

        coordinates = [geom.coordinates];

    }

    if (!validate[type]) {

        return false;

    }

    return !coordinates.find(coords => !validate[type](coords));

}

Object.assign(validate, {
    point,
    linestring,
    polygon
});

export default validate;
