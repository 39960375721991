export default {

    // Accepts a div or legacy component and returns a mithril component.
    fromDom: component => ({
        oncreate: ({dom}) => dom.parentNode.replaceChild(component._el || component, dom),
        view: () => <div />
    }),

    // Accepts JSX and returns a stateless div.
    toDom: jsx => {

        const div = document.createElement('div');

        m.render(div, jsx);

        return div.firstElementChild;

    },

    // Use this method if you need to be able to
    // re-render the mithril component after a state change.
    // This accepts an object with a view() method, and
    // returns a div with a redraw() method.
    withRedraw: component => {

        const div = document.createElement('div');

        m.render(div, m(component));

        div.redraw = () => {
            m.render(div, m(component));
        };

        return div;

    }

};

