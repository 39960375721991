import constants from 'util/data/constants';
import mobileLauncherModel from 'models/mobile-launcher-model';

const MobileLauncher = {
    view: () => {
        const {mobileWebOS, dismiss, dismissAndCache} = mobileLauncherModel;
        return !!mobileWebOS &&
            <div class="mobile-launcher">
                <a class="btn btn-small btn-secondary btn-pill" href={constants.appURLs[mobileWebOS]} target="_blank" rel="noopener noreferrer" onclick={dismiss}>Go to native app</a>
                <a class="btn btn-small btn-primary btn-pill" onclick={dismissAndCache}>Continue with web app</a>
            </div>;
    }
};

export default MobileLauncher;
