import {AUTOMATED_EXTENSIONS, INTERFACES_BY_EXTENSION} from 'constants/flows/upload-help-constants';
import mapboxgl, { LngLat } from 'mapbox-gl';
import {latLngsToLngLats} from 'util/geo';
import constants from 'util/data/constants';
import store from 'util/data/store';
import api from 'legacy/util/api';
import siteModel from 'models/site-model';

export const canAutomateExtension = (extension) => {
    return AUTOMATED_EXTENSIONS[extension];
};

export const canAcceptExtension = (extension) => {
    return AUTOMATED_EXTENSIONS.hasOwnProperty(extension);
};

export const getExtensionForFileObject = (file) => {
    const fileNameSplit = file.name.split('.');
    if (fileNameSplit.length > 1) {
        return fileNameSplit.pop();
    }
    return null;
};

export const getLocationFromMedia = (media) => {
    const centerCoords = media.info && media.info.GPS && media.info.GPS.GPSLongitude && media.info.GPS.GPSLatitude ? [media.info.GPS.GPSLongitude, media.info.GPS.GPSLatitude] : null;

    let locationData;

    if (centerCoords && centerCoords.length) {
        const bounds = new LngLat(centerCoords[0], centerCoords[1]).toBounds(20);
        const nw = bounds.getNorthWest();
        const ne = bounds.getNorthEast();
        const se = bounds.getSouthEast();
        const sw = bounds.getSouthWest();

        locationData = [
            [nw.lng, nw.lat], 
            [ne.lng, ne.lat], 
            [se.lng, se.lat],         
            [sw.lng, sw.lat],
            [nw.lng, nw.lat]
        ];
    } else if (media.info 
        && media.info.wgs84Extent 
        && media.info.wgs84Extent.coordinates) {
        locationData = media.info.wgs84Extent.coordinates[0];
    }
    return locationData ? {
        type: 'Polygon',
        coordinates: [locationData]
    } : null;
};

export const getLocationFromPlanLayer = async (planLayer) => {
    if (!planLayer) {
        return;
    }

    const newTilesetId = planLayer.tilesetId;
    let tileset = store.assets[newTilesetId];
    if (!tileset) {
        tileset = await api.rpc.get('Tileset', newTilesetId);
    }

    const tilesetBounds = new mapboxgl.LngLatBounds(latLngsToLngLats(tileset.bounds));
 
    return siteModel.map.getBoundsPolygon(tilesetBounds);
};

export const getToolsByInterface = (tools, toolInterface) => {
    return tools.filter(tool => tool.featureTypes.find(ft => ft.attributes && ft.attributes.interface === toolInterface));
};

export const getToolsPerMimeType = (allToolOptions, file) => {
    if (!file) {
        return null;
    }

    const extension = getExtensionForFileObject(file);
    const interfaces = INTERFACES_BY_EXTENSION[extension] || ['filepicker'];   
    if (!interfaces) {
        return null;
    }

    const allTools = [];
    interfaces.forEach(_interface => {
        let toolsForInterface = getToolsByInterface(allToolOptions, _interface);
        if (_interface === 'filepicker') {
            toolsForInterface = toolsForInterface.filter(tool => {
                const findFileControlThatAccepts = (control) => control.attributes.accept.find(type => file.type.startsWith(type.split('/')[0]));
                return tool.assetForm.controls.find(control => control.controlTypeId === constants.controlTypeNameToId.file && (!control.attributes 
                    || !control.attributes.accept 
                    || findFileControlThatAccepts(control)
                ));
            });

            // For now, default to "Image" unless Image isn't available.
            if (toolsForInterface.length > 1) {
                toolsForInterface = toolsForInterface.filter(tool => tool.name === 'Image');
            }
        }
        allTools.push(...toolsForInterface);
    });

    return allTools;
};

