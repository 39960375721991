const timeCachedValues = {};
export default (fn, key, resetTime = 3000) => {
    if (timeCachedValues[key] === undefined) {
        timeCachedValues[key] = fn();
        setTimeout(() => {
            delete timeCachedValues[key];
        }, resetTime);
        return timeCachedValues[key];
    }
    return timeCachedValues[key];
};
