import boundaryMenuModel from 'models/boundary-menu-model';
import tableModel from 'models/table/table-model';
import siteModel from 'models/site-model';
import message from 'views/toast-message/toast-message';

const BoundaryPopup = {
    view: ({attrs: {popupText}}) => {
        return <div class="address-search-popup">
            <div className="search-popup-inner">
                <div className="search-popup-text"><span className="first-line">{popupText[0] || ''}
                </span><div><span className="second-line">{popupText[1] || ''}</span></div>
                </div>
            </div>
            <div class="search-popup-footer"><div className="filter-btn" onclick={(e) => {
                e.stopPropagation();
                boundaryMenuModel.selectBoundary(1);
                tableModel.projectView.common.selectBoundaryRadio(1, true);
                siteModel.map.searchPopup.remove();
                message.show('Applied Map Filter: in viewport.', 'success');
                
            }}><i class="icon-map-square"/>Apply Map Filter: in viewport</div>
            </div>
        </div>;
    }
};

export default BoundaryPopup;
