import store from 'util/data/store';
import appModel from 'models/app-model';

// Names
export const GET_USER = 'GetUser';
export const GET_ROOT_FOLDER = 'GetRootFolder2';
export const LIST_PROJECT_TOOLBOXES = 'ListProjectToolboxes';
export const ADD_PROJECT_USER = 'AddProjectUser';
export const REMOVE_PROJECT_USER = 'RemoveProjectUser';
export const MODIFY_ACCOUNT_USER = 'ModifyAccountUser';
export const GET_USER_BY_ID_OR_EMAIL = 'GetUsersByIdOrEmail';
export const GET_PROJECT_AND_ACCOUNT_NAMES_BY_PROJECT_ID = 'GetProjectAndAccountByProjectId';
export const LIST_LAYER_FOLDERS = 'ListLayerFolders';
export const CREATE_LAYER_FOLDER = 'CreateLayerFolder';
export const MODIFY_LAYER_FOLDER = 'ModifyLayerFolder';
export const MODIFY_LAYER_FOLDER_PARENT = 'ModifyLayerFolderParent';
export const MODIFY_LAYER_FOLDER_NAME = 'ModifylayerFolderName';
export const MODIFY_LAYER_FOLDER_ZORDER = 'ModifyLayerFolderZOrder';
export const DELETE_LAYER_FOLDER = 'DeleteLayerFolder';

const variableQueries = {
    [GET_ROOT_FOLDER]: ({projectId}) => `query ${GET_ROOT_FOLDER} {
        projects(id: "${projectId || appModel.project.projectId}") {
            id
            layerFolders(filter: [eq "$.root" true]) {
                id
                name
                folders {
                    id
                }
                zOrder
                updatedAt
            }
        }
    }`,
    [LIST_LAYER_FOLDERS]: ({projectId, parentId}) => `query ${LIST_LAYER_FOLDERS} {
        projects(id: "${projectId || appModel.project.projectId}") {
            id
            layerFolders${parentId ? `(filter [eq "$.parent.id" "${parentId}"])` : ''}  {
                id
                name
                folders {
                    id
                }
                parent {
                    id
                }
                zOrder
                updatedAt
            }
        }
    }`,
    [LIST_PROJECT_TOOLBOXES]: () => `query ${LIST_PROJECT_TOOLBOXES} {
        projects(filter: [eq "$.account.id" "${store.account.accountId}"]) {
            id
            toolkit {
                id
                name
            }
        }
    }`,
    [GET_USER_BY_ID_OR_EMAIL]: ({ userIdOrEmailStr }) => `query ${GET_USER_BY_ID_OR_EMAIL} {
        users(filter: [
            or,
            [eq, "$.id", "${userIdOrEmailStr}"],
            [eq, "$.emailAddress", "${userIdOrEmailStr}"]
        ]) {
            emailAddress
            id
            accounts {
                name
                id
                status
                roleType
            }
            familyName
            givenName
            phoneNumber
            status
            createdAt
            updatedAt
        }
    }
    `
};

// Queries
const staticQueries = {
    [GET_USER]: `query ${GET_USER}($userId: ID) {
        users(id: $userId) {
            givenName
            familyName
            updatedAt
        }
    }`,
    [GET_PROJECT_AND_ACCOUNT_NAMES_BY_PROJECT_ID]: `query ${GET_PROJECT_AND_ACCOUNT_NAMES_BY_PROJECT_ID}($projectId: ID) {
        projects(id: $projectId) {
          name
          account {
            id
            name
          }
          id
        }
    }`,
    [ADD_PROJECT_USER]: `mutation ${ADD_PROJECT_USER}($projectId: String!, $roleName: String!, $userId: String!) {
        addProjectUser(projectId: $projectId, roleName: $roleName, userId: $userId) {
            id
            updatedAt
        }
    }`,
    [REMOVE_PROJECT_USER]: `mutation ${REMOVE_PROJECT_USER}($projectId: String!, $userId: String!) {
        removeProjectUser(projectId: $projectId, userId: $userId) {
            id
            updatedAt
        }
    }`,
    [MODIFY_ACCOUNT_USER]: `mutation ${MODIFY_ACCOUNT_USER}($accountId: String!, $roleName: String!, $emailAddress: String!) {
        modifyAccountUser(accountId: $accountId, roleName: $roleName, emailAddress: $emailAddress) {
            id
            updatedAt
        }
    }`,
    [CREATE_LAYER_FOLDER]: `mutation ${CREATE_LAYER_FOLDER}($folderName: String!, $projectId: String!, $parentFolderId: String!) {
        createLayerFolder(folderName: $folderName, projectId: $projectId, parentFolderId: $parentFolderId) {
            id
            updatedAt
            name
            parent {
                id
                name
            }
        }
    }`,
    [MODIFY_LAYER_FOLDER]: `mutation ${MODIFY_LAYER_FOLDER}($layerFolderId: String!, $folderName: String!, $parentFolderId: String!, $zOrder: [String!]) {
        modifyLayerFolder(layerFolderId: $layerFolderId, folderName: $folderName, parentFolderId: $parentFolderId, zOrder: $zOrder) {
            id
            updatedAt
        }
    }`,
    [MODIFY_LAYER_FOLDER_ZORDER]: `mutation ${MODIFY_LAYER_FOLDER_ZORDER}($layerFolderId: String!, $zOrder: [String!]) {
        modifyLayerFolder(layerFolderId: $layerFolderId, zOrder: $zOrder) {
            id
            updatedAt
        }
    }`,
    [MODIFY_LAYER_FOLDER_NAME]: `mutation ${MODIFY_LAYER_FOLDER_NAME}($layerFolderId: String!, $folderName: String!) {
        modifyLayerFolder(layerFolderId: $layerFolderId, folderName: $folderName) {
            id
            updatedAt
        }
    }`,
    [DELETE_LAYER_FOLDER]: `mutation ${DELETE_LAYER_FOLDER}($layerFolderId: String!) {
        deleteLayerFolder(layerFolderId: $layerFolderId) {
            id
        }
    }`
};

// Final structure, query w/variables & name
export const query = (name, variables) => {
    return {
        'query': staticQueries[name] || variableQueries[name](variables),
        'variables': variables ? {...variables} : null,
        'operationName': name
    };
};
