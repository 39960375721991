import store from 'util/data/store';
import constants from 'util/data/constants';
import appModel from 'models/app-model';
import FeatureTypeModel from 'models/toolbox/tool/feature-type/feature-type-model';
import FeatureStyleModel from 'models/toolbox/tool/feature-style/feature-style-model';
import featureListManager from 'managers/feature-list-manager';

class ToolModel {

    constructor(_apiTool, toolGroup) {
        Object.assign(this, _apiTool);
        this.toolGroupId = toolGroup.toolGroupId;
        this.styledControls = {};
        this.init(_apiTool, toolGroup);
    }

    get toolGroup() {
        return appModel.toolbox.toolGroups[this.toolGroupId];
    }

    init(tool, toolGroup) {
        appModel.toolbox.tools[tool.toolId] = this;
    
        const lowerToolName = tool.name.toLowerCase();
        if (appModel.toolbox.linkTools[lowerToolName]) {
            appModel.toolbox.linkTools[lowerToolName] = this;
        }

        this.handleSpecialAttributes(tool); 
        this.readyAssetForm(toolGroup, tool);

        this.featureTypes = [];
        tool.featureTypes.forEach(featureType => {
            const newFeatureType = new FeatureTypeModel(this, featureType);
            this.featureTypes.push(newFeatureType);
            appModel.toolbox.featureTypes[featureType.featureTypeId] = newFeatureType;
        });

        this.minzoom = tool.attributes && tool.attributes.minzoom ? tool.attributes.minzoom : null;
        if (this.minzoom) {
            featureListManager.minzoomByToolId[tool.toolId] = this.minzoom;
        }

        this.featureStyles = [];
        tool.featureStyles.forEach(featureStyle => {
            const newFeatureStyle = new FeatureStyleModel(this, featureStyle);
            this.featureStyles.push(newFeatureStyle);
        });
    }

    hasPlanControl() {
        const controls = this.assetForm.controls;
        return controls.find(c => c.controlTypeId === constants.controlTypeNameToId.plan);
    }

    hasSurveyControl() {
        const controls = this.assetForm.controls;
        return controls.find(c => c.controlTypeId === constants.controlTypeNameToId.survey);
    }

    handleSpecialAttributes(tool) {
        if (tool.attributes.hidden) {
            if (tool.name === 'Comment') {
                constants.commentAssetTypeId = tool.assetForm.assetType.assetTypeId;
                constants.commentToolId = tool.toolId;
            }
            this.isHidden = true;
        } else if (tool.attributes.permissions) {
            const _assetTypeId = tool.assetForm.assetType.assetTypeId;
            appModel.user.permissions.setCustomToolPermissions(_assetTypeId, tool.attributes.permissions);
            const canReadAssetType = appModel.user.permissions.canReadAssetType(_assetTypeId);
            if (!canReadAssetType) {
                appModel.user.permissions.hiddenAssetTypes[_assetTypeId] = true;
                delete appModel.toolbox.tools[tool.toolId];
                appModel.toolbox.hiddenTools[tool.toolId] = this;
                this.isHidden = true;
            }
        } else if (appModel.project.isMetaProject && appModel.toolbox.isProjectTool(tool)) {
            const assetTypeId = tool.assetForm.assetType.assetTypeId;
            appModel.toolbox.projectTools[tool.toolId] = this;
            appModel.user.permissions.setProjectToolPermissions(assetTypeId);
            if (!appModel.user.permissions.canReadAssetType(assetTypeId)) {
                this.isHidden = true;
            }
        }
        if (!appModel.toolbox.hasAtLeastOneTool && !tool.attributes.hidden) {
            appModel.toolbox.hasAtLeastOneTool = appModel.user.permissions.canCreateAssetType(tool.assetForm.assetType.assetTypeId);
        }
    }

    readyAssetForm(toolGroup, tool) {
        const assetForm = tool.assetForm,
            assetTypeId = assetForm.assetType.assetTypeId,
            controlTypeNameToId = constants.controlTypeNameToId;

        const assetFormId = assetForm.assetFormId;
        const nameControl = assetForm.controls.find(c => c.controlTypeId === controlTypeNameToId.name);
        store.assetForms[assetFormId] = assetForm;
        store.assetTypeIdToNameControl[assetTypeId] = nameControl && nameControl.fieldName;
        store.assetTypeIdToToolGroupId[assetTypeId] = toolGroup.toolGroupId;
        store.assetTypeIdToToolId[assetTypeId] = tool.toolId;
        store.assetTypes[assetTypeId] = assetForm.assetType;
        store.assetTypeToFormId[assetTypeId] = assetFormId;
        store.assetTypeFields[assetTypeId] = {};

        // Check if any control is an "assignment" (ie user) control
        if (assetForm.controls.find(c => c.controlTypeId === controlTypeNameToId.user)) {
            appModel.toolbox.assetTypesWithUserControls[assetTypeId] = true;
        }

        // Check if any control is an "asset" (ie link) control
        if (assetForm.controls.find(c => c.controlTypeId === controlTypeNameToId.user)) {
            appModel.toolbox.assetTypesWithLinkControls.push(assetTypeId);
        }
        
        // Check if any control is a "survey" control
        if (assetForm.controls.find(c => c.controlTypeId === controlTypeNameToId.survey)) {
            appModel.toolbox.assetTypesWithSurveyControls[assetTypeId] = true;
        }
        
        // Check if any control is a "plan" control
        if (assetForm.controls.find(c => c.controlTypeId === controlTypeNameToId.plan)) {
            appModel.toolbox.assetTypesWithPlanControls[assetTypeId] = true;
        }
                
        assetForm.assetType.fields.forEach(field => {
            store.assetTypeFields[assetTypeId][field.name] = field;
        });

    }
}

export default ToolModel;
