import dataCenterModel from '../../models/data-center-model';
import appModel from '../../models/app-model';
import siteModel from '../../models/site-model';

const DataCenterEntry = {
    oninit: ({state}) => {
        const sitePreferences =  appModel.user.getPreference('sitePreferences');
        state.isDataCenterHidden = sitePreferences && sitePreferences[siteModel.siteId] && sitePreferences[siteModel.siteId].hideDataCenter ? sitePreferences[siteModel.siteId].hideDataCenter : false;
    },

    view: ({state, attrs: {assetTypeId}}) => {
        if (state.isDataCenterHidden) {
            return;
        } 
        return <div className="data-center-cta-wrap-outer">
            <div className="data-center-cta-wrap">
                <div className="data-center-cta-message">It’s easy to fit our Data Templates to your specs.</div>
                <span className={'btn btn-smallest btn-pill btn-primary UFtracker-enter-DC-from-form'}
                    onclick={() => {
                        dataCenterModel.editAssetTypeTool(assetTypeId);
                    }}><span className="button-beta-flag">BETA</span><span
                        className="btn-label">Try it out!</span></span>
                <div className="data-center-dismiss-link dismiss-always UFtracker-dc-cta-dimiss-always"
                    onclick={() => {
                        appModel.user.mergePreferences({
                            sitePreferences: {
                                [siteModel.siteId]: {hideDataCenter: true}
                            }
                        });
                        state.isDataCenterHidden = true;
                    }
                    }>Don't show again
                </div>
                <div className="data-center-dismiss-link dismiss-now UFtracker-dc-cta-dismiss-now" onclick={() => {
                    state.isDataCenterHidden = true;
                }}>Not now
                </div>
            </div>
        </div>;
        
    }
};

export default DataCenterEntry;
