const POPUP_WIDTH = 220;
const POPUP_BUFFER_HEIGHT = 110; 
const OFFSET_X = 25;

const Tip = {

    show: (element, opts = {}) => {
        const rect = element.getBoundingClientRect();
        let alignCssClass = 'align-left';
        const x = rect.left - OFFSET_X;
        const y = rect.top - 10;
        const coordinates = {x, y, width: rect.width};
        
        const difference = window.innerWidth - x;

        if (difference < POPUP_WIDTH) {
            coordinates.x = rect.right - POPUP_WIDTH + OFFSET_X;
            alignCssClass = 'align-right';
        }

        if (rect.top < POPUP_BUFFER_HEIGHT) {
            coordinates.y = rect.top + POPUP_BUFFER_HEIGHT;
            alignCssClass = alignCssClass += ' align-bottom';
        }

        Tip.cssClasses = `tip visible ${alignCssClass} ${opts.cssClasses || ''}`;
        Tip.styles = `top:${coordinates.y}px; left:${coordinates.x}px;`;
        Tip.tipText = opts.tipText || '';
        Tip.icon = opts.icon || 'icon-shield';
        Tip.isOpen = true;
        m.redraw();
    },

    hide: () => {
        Tip.tipText = '';
        Tip.isOpen = false;
        m.redraw();
    },

    view: () => {
        if (Tip.tipText) {
            return <div class={Tip.cssClasses} style={Tip.styles}>
                <div class="tip-icon"><i class={Tip.icon}/></div>
                <div class="tip-message">{Tip.tipText}</div>
            </div>;
        }
        return '';
    }
};
export default Tip;
