import ajax from 'legacy/util/api/ajax';
import deepMerge from 'util/data/deep-merge';
// import {appUrl} from 'util/data/env';
import authManager from 'managers/auth-manager';

const authorization = () => 'Bearer ' + authManager.getToken();

const proxyRequest = (url, opts = {}) => new Promise((resolve, reject) =>
    ajax(url, deepMerge(opts, {
        headers: {
            authorization: authorization()
        },
        resolve,
        reject
    }))
);

proxyRequest.authorization = authorization;

export default proxyRequest;
