const mapConstants = {
    BOUNDARIES: {
        // Not precise - Northern bias to account for table view on bottom of screen
        NORTH_AMERICA: [
            [-170.22902913214472, -16.687624128355154], // SW: [lng, lat]
            [-29.470760671386557, 65.0726260283588] // NE: [lng, lat]
        ]
    },
    SITE_BOUNDS_ID: 'siteBounds'
};

export default mapConstants;
