import router from 'uav-router';
import store from 'util/data/store';
import api from 'legacy/util/api';
import debounce from 'util/events/debounce';
import initializer from 'util/initializer';
import appModel from 'models/app-model';
import peopleModel from './people/people-model';
import {urnify, deUrnify} from 'util/network/urnify';
import {isMetaToolbox, sortAndCleanToolbox} from 'util/data/toolbox-helpers';
import graphql from 'util/graphql/graphql';

const MUTE_ALL = 0;
const NOT_MUTE_ALL = 1;

const defaultOptionIdsToLabel = {
    editingUserName: '',
    subscriptionsDescription: 'For all projects that I am added to:',
    assigned: 'to anything assigned to me',
    mentioned: 'if I am @mentioned',
    authored: 'to anything I add',
    updated: 'to anything I update',
    commented: 'to anything I comment on',
    assetTypeActivity: 'to all activity on selected Types ...'
};

const DEFAULT_SETTINGS = {
    assigned: true,
    mentioned: true,
    authored: false,
    updated: false,
    commented: false
};

class NotificationsModel {

    constructor() {
        this.quickOpts = ['Mute all notifications', 'Subscribe...'];
        this.triggerOpts = ['assigned', 'mentioned', 'authored', 'updated', 'commented', 'assetTypeActivity'];

        this.saveToApi = debounce(this._saveToApi.bind(this));
        initializer.add(this.reset.bind(this), 'notifications');
    }

    onRemove() {
        if (router.params.manage) {
            router.url.remove('manage');
        }
    }

    reset() {
        this.resetState();
        this.toolboxes = []; // Full list of toolboxes on acct
        this.toolboxesFetched = false;
    }

    resetState() {
        this.state = {
            saving: {},
            settingsFetched: false
        };

        this.selections = {
            switches: {...DEFAULT_SETTINGS, assetTypeActivity: false},
            toolboxes: {},
            quickRadio: this.quickOpts[MUTE_ALL], // Strictly a UI option to either mute all or not.
            categories: {} // The "category" checkbox is strictly a UI option to select/deselect all tools within the category.
        };

        this.protectedToolboxSettings = {};
    }

    /* ----- Init and set-up ----- */

    async init(person) {
        if (this.userId !== person.userId) {
            this.resetState();
        }
        this.userId = person.userId || peopleModel.viewingUserId; // If userId wasn't set, default to self
        this.setLabels(); // Phrasing is dependent upon which user is being edited

        if (!this.state.settingsFetched) {
            await this.fetchUserNotificationSettings().then(settings => {
                this.loadUserNotificationSettings(settings);
                this.state.settingsFetched = true;
                m.redraw();
            });
        }

        if (!this.toolboxesFetched) {
            await this.fetchToolboxes();
        }

        this.initSelectAllStates();
    }

    setLabels() {
        if (this.userId !== appModel.user.userId) {
            const name = peopleModel.displayNameOrEmail(this.userId);
            const referenceName = peopleModel.getPerson(this.userId).givenName || 'this person';
            this.optionIdToLabel = {
                editingUserName: name,
                subscriptionsDescription: `For all projects that ${referenceName} is added to...`,
                assigned: `to anything assigned to ${referenceName}`,
                mentioned: `if ${referenceName} is @mentioned`,
                authored: `to anything ${referenceName} adds`,
                updated: `to anything ${referenceName} updates`,
                commented: `to anything ${referenceName} comments on`,
                assetTypeActivity: 'to all activity on...'
            };
            this.quickOpts[NOT_MUTE_ALL] = `Subscribe ${referenceName}...`;
        } else {
            this.optionIdToLabel = {...defaultOptionIdsToLabel};
            this.quickOpts[NOT_MUTE_ALL] = 'Subscribe me ...';
        }
    }

    /*
     * Set aside any settings for a toolbox not on this acct to prevent changes. We'll add them back in before saving.
     */
    protectAccountLevelSettings() {
        Object.keys(this.selections.toolboxes).forEach(toolboxUrn => {
            const toolboxId = deUrnify(toolboxUrn).nss;
            const toolbox = this.toolboxes.find(tbox => tbox.toolboxId === toolboxId);
            if (!toolbox) {
                this.protectedToolboxSettings[toolboxUrn] = {...this.selections.toolboxes[toolboxUrn]};
                delete this.selections.toolboxes[toolboxUrn];
            }
        });
    }

    /**
     * One time init of all select all states to run after settings & toolboxes are fetched from api
     */
    initSelectAllStates() {
        Object.keys(this.selections.toolboxes).forEach(toolboxUrn => {
            const toolboxId = deUrnify(toolboxUrn).nss;
            const toolbox = this.toolboxes.find(tbox => tbox.toolboxId === toolboxId);
            if (toolbox) {
                Object.keys(this.selections.toolboxes[toolboxUrn]).forEach(toolGroupUrn => {
                    const toolGroupId = deUrnify(toolGroupUrn).nss;
                    const toolGroup = toolbox.group.groups.find(tgroup => tgroup.toolGroupId === toolGroupId);
                    if (toolGroup && this.isAllOfCategorySelected(toolbox, toolGroup)) {
                        this.selections.categories[toolboxId] = this.selections.categories[toolboxId] || {};
                        this.selections.categories[toolboxId][toolGroupId] = true;
                    }
                });
            }
        });
    }

    /* ---- Retrieving Current State ---- */

    allAreMuted() {
        return this.selections.quickRadio === MUTE_ALL;
    }

    isSavingTool(toolboxId, toolGroupId, toolId) {
        return this.isSaving('tool', toolId) || this.isSaving('toolGroup', toolGroupId) || this.isSaving('toolbox', toolboxId);
    }

    isSaving(propertyType, id) {
        return this.state.saving[propertyType] ? this.state.saving[propertyType][id] : false;
    }

    getToolGroupSetting(toolboxId, toolGroupId) {
        const toolboxSetting = this.selections.toolboxes[urnify('toolbox', toolboxId)];
        const toolgroupUrn = urnify('toolgroup', toolGroupId);
        return toolboxSetting && toolboxSetting[toolgroupUrn] ? toolboxSetting[toolgroupUrn] : {};
    }

    getToolSetting(toolboxId, toolGroupId, toolId) {
        const toolGroupSetting = this.getToolGroupSetting(toolboxId, toolGroupId);
        return toolGroupSetting[urnify('tool', toolId)] || false;
    }

    getCategoryClass(toolboxId, toolGroupId) {
        const toolboxState = this.selections.toolboxes[urnify('toolbox', toolboxId)];
        if (toolboxState) {
            // Only show 'on' checkbox if all tools in category are on
            if (this.selections.categories[toolboxId] && this.selections.categories[toolboxId][toolGroupId]) {
                return 'on';
            }
            // Otherwise, if at least one tool in the category is true, show as 'mixed'
            const toolGroupSetting = this.getToolGroupSetting(toolboxId, toolGroupId);
            if (toolGroupSetting && Object.values(toolGroupSetting).find(setting => setting === true)) {
                return 'mixed';
            }
        }
        return 'off';
    }

    /**
     * If true, "select all" displays as an option
     */
    isAllOfToolboxSelected(toolbox) {
        if (this.selections.categories[toolbox.toolboxId]) {
            const toolGroupSelections = Object.values(this.selections.categories[toolbox.toolboxId]);
            const sameLengthSelected = toolGroupSelections.length >= toolbox.group.groups.length;
            return sameLengthSelected && !toolGroupSelections.find(selection => selection !== true);
        }
        return false;
    }

    /**
     * If true, "deselect all" displays as an option
     */
    isAnyOfToolboxSelected(toolboxId) {
        const toolboxState = this.selections.toolboxes[urnify('toolbox', toolboxId)];
        return !!toolboxState && !!Object.values(toolboxState).find(toolGroup => Object.values(toolGroup).find(tool => tool === true));
    }

    /**
     * If true, category checkbox displays as "on" (rather than "mixed")
     */
    isAllOfCategorySelected(toolbox, toolGroup) {
        const toolboxUrn = urnify('toolbox', toolbox.toolboxId);
        const toolGroupUrn = urnify('toolgroup', toolGroup.toolGroupId);
        if (this.selections.toolboxes[toolboxUrn] && this.selections.toolboxes[toolboxUrn][toolGroupUrn]) {
            const currentToolSelections = this.selections.toolboxes[toolboxUrn][toolGroupUrn] ? Object.values(this.selections.toolboxes[toolboxUrn][toolGroupUrn]) : false;
            // Use greater than or equal to in case there are leftover data on the preferences from old tools.
            const sameLengthSelected = currentToolSelections && currentToolSelections.length >= toolGroup.tools.length;
            return sameLengthSelected && !currentToolSelections.find(selection => selection !== true);
        }
        return false;
    }

    /* ---- User Actions ---- */

    selectOption(property, toValue) {
        this.selections[property] = toValue;
        this.autosave(property, toValue);
    }

    toggleSwitch(optionId) {
        this.selections.switches[optionId] = !this.selections.switches[optionId];
        this.autosave('switches', optionId);
    }

    selectAllToolbox(toolbox) {
        this.initSelection('categories', toolbox.toolboxId);
        toolbox.group.groups.forEach(toolGroup => {
            this.selections.categories[toolbox.toolboxId][toolGroup.toolGroupId] = true;
            toolGroup.tools.forEach(tool => {
                this.selectTool(toolbox.toolboxId, toolGroup.toolGroupId, tool.toolId);
            });

        });
        this.autosave('toolbox', toolbox.toolboxId);
    }

    deSelectAllToolbox(toolbox) {
        this.initSelection('categories', toolbox.toolboxId);
        toolbox.group.groups.forEach(toolGroup => {
            if (this.selections.categories[toolbox.toolboxId][toolGroup.toolGroupId]) {
                delete this.selections.categories[toolbox.toolboxId][toolGroup.toolGroupId];
            }
            const toolboxSelections = this.selections.toolboxes[urnify('toolbox', toolbox.toolboxId)];
            if (toolboxSelections && toolboxSelections[urnify('toolgroup', toolGroup.toolGroupId)]) {
                this.deselect(toolbox.toolboxId, toolbox.toolGroupId);
            }
        });
        delete this.selections.categories[toolbox.toolboxId];
        this.deselect(toolbox.toolboxId);
        this.autosave('toolbox', toolbox.toolboxId);
    }

    toggleTool(toolbox, toolGroup, toolId) {
        const toolboxId = toolbox.toolboxId,
            toolGroupId = toolGroup.toolGroupId,
            toolGroupSetting = this.getToolGroupSetting(toolboxId, toolGroupId);
        if (toolGroupSetting[urnify('tool', toolId)]) {
            this.deselect(toolboxId, toolGroupId, toolId);
            // Also check for impacts to select all Category checkbox
            if (this.selections.categories[toolboxId] && this.selections.categories[toolboxId][toolGroupId]) {
                delete this.selections.categories[toolboxId][toolGroupId];
            }
        } else {
            this.selectTool(toolboxId, toolGroupId, toolId);
            if (this.isAllOfCategorySelected(toolbox, toolGroup)) {
                this.initSelection('categories', toolboxId);
                this.selections.categories[toolboxId][toolGroupId] = true;
            }
        }
        this.autosave('tool', toolId);
    }

    toggleCategory(toolbox, toolGroup) {
        const toolboxId = toolbox.toolboxId;
        const toolGroupId = toolGroup.toolGroupId;

        // Update both the category checkbox as well as all tools within it
        this.initSelection('categories', toolboxId);
        const newValue = !this.selections.categories[toolboxId][toolGroupId];
        this.selections.categories[toolboxId][toolGroupId] = newValue;

        const toolboxUrn = urnify('toolbox', toolboxId);
        const toolgroupUrn = urnify('toolgroup', toolGroupId);
        this.initSelection('toolboxes', toolboxUrn, toolgroupUrn);
        if (newValue) {
            toolGroup.tools.forEach(tool => {
                const toolUrn = urnify('tool', tool.toolId);
                this.selections.toolboxes[toolboxUrn][toolgroupUrn][toolUrn] = newValue;
            });
        } else {
            delete this.selections.toolboxes[toolboxUrn][toolgroupUrn];
        }

        this.autosave('toolGroup', toolGroupId);
    }

    deselect(toolboxId, toolGroupId, toolId) {
        if (toolboxId && toolGroupId && toolId) {
            delete this.selections.toolboxes[urnify('toolbox', toolboxId)][urnify('toolgroup', toolGroupId)][urnify('tool', toolId)];
        } else if (toolboxId && toolGroupId) {
            delete this.selections.toolboxes[urnify('toolbox', toolboxId)][urnify('toolgroup', toolGroupId)];
        } else if (toolboxId) {
            delete this.selections.toolboxes[urnify('toolbox', toolboxId)];
        }
    }

    selectTool(toolboxId, toolGroupId, toolId) {
        const toolboxUrn = urnify('toolbox', toolboxId);
        const toolgroupUrn = urnify('toolgroup', toolGroupId);
        const toolUrn = urnify('tool', toolId);
        this.initSelection('toolboxes', toolboxUrn, toolgroupUrn);
        this.selections.toolboxes[toolboxUrn][toolgroupUrn][toolUrn] = true;
    }

    /* ---- API requests / getting and saving settings ---- */

    async fetchUserNotificationSettings() {
        let userPreferences;
        // Ensure we load the freshest preferences, since changes to these aren't published via the websocket
        await api.rpc.get('User', this.userId).then(user => {
            userPreferences = user.preferences;
        });
        const settings = userPreferences && userPreferences.notification ? userPreferences.notification : DEFAULT_SETTINGS;
        return Promise.resolve(settings);
    }

    loadUserNotificationSettings(settings) {
        const atLeastOneNotificationIsOn = Object.values(settings).find(value => value === true);
        let atLeastOneToolIsOn = false;
        Object.assign(this.selections.switches, settings);
        // We're going to break out the toolbox selections from the other settings for now for easier UI/form handling.
        delete this.selections.switches.toolboxSpecific;
        const toolboxSpecific = settings.toolboxSpecific;
        if (toolboxSpecific) {
            Object.keys(toolboxSpecific).forEach(toolboxId => {
                if (!atLeastOneToolIsOn) {
                    atLeastOneToolIsOn = Object.values(toolboxSpecific[toolboxId])
                        .find(toolGroup => Object.values(toolGroup)
                            .find(tool => tool === true));
                }
            });
            Object.assign(this.selections.toolboxes, settings.toolboxSpecific);
        }
        this.selections.switches.assetTypeActivity = !!atLeastOneToolIsOn;
        this.selections.quickRadio = atLeastOneNotificationIsOn || atLeastOneToolIsOn ? NOT_MUTE_ALL : MUTE_ALL;
        m.redraw();
    }

    fetchToolboxes() {
        this.toolboxes = [];
        return graphql.listProjectToolboxes().then(response => {
            const projects = response && response.data && response.data.projects ? response.data.projects : [];
            const toolkitIds = {};
            projects.forEach(project => {
                if (project.toolkit) {
                    toolkitIds[project.toolkit.id] = toolkitIds[project.toolkit.id] || [];
                    toolkitIds[project.toolkit.id].push(project.id);
                }
            });
            const justToolkitIds = Object.keys(toolkitIds);

            return api.rpc.request([['listToolboxes', {
                accountId: store.project.accountId,
                toolboxIdIn: [...justToolkitIds],
                isVisible: true,
                baseId: {ne: null}
            }]]).then(toolboxes => {
                toolboxes.forEach(toolbox => {
                    // Only include non-meta toolboxes
                    if (!isMetaToolbox(toolbox)) {
                        sortAndCleanToolbox(toolbox);
                        this.toolboxes.push(toolbox);
                    }
                });
                this.toolboxesFetched = true;
                this.protectAccountLevelSettings();
                m.redraw();
            });
        });
    }

    _saveToApi() {
        const settings = this.getApiReadySettings();
        return api.rpc.updateUserNotificationPreferences({userId: this.userId, notificationPreferences: settings}).then(() => {
            this.isSavingValue = false;
            this.didSaveValue = true;
            this.state.saving = {};
            if (this.userId === appModel.user.userId) {
                appModel.user.preferences.notification = settings;
            }
            m.redraw();
        });
    }

    /*
     * Takes current notification selections from the form and returns a payload for the api.
     * If a UI-only higher-level option was selected (ie, switching off all notifications), we won't remove
     * the lower-level preferences from state in case the user wants to toggle them back on in this session)
    */
    getApiReadySettings() {
        const payload = {toolboxSpecific: {}};
        Object.assign(payload, this.selections.switches);
        delete payload.assetTypeActivity; // Not a real API data point, just a UI option.
        if (!this.allAreMuted()) {
            if (this.selections.switches.assetTypeActivity) {
                payload.toolboxSpecific = Object.assign(this.selections.toolboxes);
            }
        } else {
            Object.keys(payload).forEach(key => {
                payload[key] = false;
            });
            payload.toolboxSpecific = {};
        }
        // In case of admins editing another user's settings — we protected settings not related to this mutual account:
        Object.assign(payload.toolboxSpecific, this.protectedToolboxSettings);
        return payload;
    }

    /* ---- Little helpers ---- */

    /*
     * Ensure a selection exists as at least an empty object
     */
    initSelection(containerKey, parent, child) {
        this.selections[containerKey][parent] = this.selections[containerKey][parent] || {};
        if (child) {
            this.selections[containerKey][parent][child] = this.selections[containerKey][parent][child] || {};
        }
    }

    getLabelFor(id) {
        return this.optionIdToLabel[id];
    }

    autosave(propertyType, id) {
        this.isSavingValue = true;
        this.state.saving[propertyType] = this.state.saving[propertyType] || {};
        this.state.saving[propertyType][id] = true;
        m.redraw();
        this.saveToApi();
    }

}

export default new NotificationsModel();
