// guaranteed to hit each item in the list,
// even if you remove items while iterating.
function reverseLoop(list, forEach) {

    for (let i = list.length - 1; i >= 0; i--) {

        forEach(list[i], i);

    }

}

export default reverseLoop;
